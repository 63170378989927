import { FlyToInterpolator, LinearInterpolator } from '@deck.gl/core';
import { MapViewState } from 'deck.gl';
import { MultiPolygon, Polygon } from 'geojson';

// Default view state
export const INITIAL_VIEW_STATE: MapViewState = {
    longitude: -122.41669,
    latitude: 37.7853,
    zoom: 13,
    pitch: 0,
    bearing: 0,
};

// Consumers Map
export const INITIAL_VIEW_STATE_CONSUMER: MapViewState = {
    longitude: -100.41669,
    latitude: 37.7853,
    zoom: 3.5,
    minZoom: 2.5,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};

// Promos Map
export const INITIAL_VIEW_STATE_PROMOS = {
    latitude: -15.749997,
    longitude: -47.9499962,
    zoom: 3.5,
    minZoom: 2.5,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};

// Context map
export const INITIAL_VIEW_STATE_CONTEXT: MapViewState = {
    longitude: -95,
    latitude: 37.7853,
    zoom: 3.75,
    minZoom: 2.5,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};

export const INITIAL_VIEW_STATE_MARKET_RECAP: MapViewState = {
    longitude: -97,
    latitude: 39,
    zoom: 3.2,
    minZoom: 2.5,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};

export const INITIAL_VIEW_STATE_MARKET_RECAP_MOBILE: MapViewState = {
    longitude: -97,
    latitude: 39,
    zoom: 2.0,
    minZoom: 1.5,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};

export const INITIAL_VIEW_STATE_STORE_ALLOCATIONS: MapViewState = {
    longitude: -100.41669,
    latitude: 37.7853,
    zoom: 1.5,
    minZoom: 2.5,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};

// Product Performance Map
export const INITIAL_VIEW_METRICS: MapViewState = {
    longitude: -97.41669,
    latitude: 37.7853,
    zoom: 3,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};

export const INITIAL_VIEW_METRICS_BR: MapViewState = {
    longitude: -49.197840176503725,
    latitude: -9.543651224332576,
    zoom: 3,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};

export const INITIAL_VIEW_STATE_SKU_ALLOCATION: MapViewState = {
    longitude: -73.98119961567146,
    latitude: 40.76062875259026,
    zoom: 14,
    minZoom: 2.5,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
    pitch: 75,
};

export const INITIAL_VIEW_DASHBOARD = {
    longitude: -20.41669,
    latitude: 37.7853,
    zoom: 1.8,
    pitch: 0,
    bearing: 0,
    transitionDuration: 700,
    transitionInterpolator: new LinearInterpolator(),
};

export const INITIAL_VIEW_PRODUCT = {
    longitude: -74,
    latitude: 40.75,
    zoom: 9.3,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};

export const INITIAL_VIEW_PHOBOS = {
    longitude: -97.41669,
    latitude: 37.7853,
    zoom: 3,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};

export const INITIAL_VIEW_CPG_NY = {
    longitude: -73.535,
    latitude: 40.7891,
    zoom: 9,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};

export const INITIAL_VIEW_PHOBOS_NY = {
    longitude: -74.006,
    latitude: 40.7128,
    zoom: 9,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};
export const INITIAL_VIEW_PHOBOS_FL = {
    longitude: -81.5158,
    latitude: 27.6648,
    zoom: 6,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};

export const INITIAL_VIEW_PHOBOS_TX = {
    longitude: -96.808891,
    latitude: 32.779167,
    zoom: 6,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};

export const INITIAL_VIEW_COMMERCIAL = {
    longitude: 123.02064333085153,
    latitude: 11.863578887647495,
    zoom: 5,
    pitch: 0,
    bearing: 0,
    transitionDuration: 700,
    transitionInterpolator: new LinearInterpolator(),
};

export const GEOJSON_URL =
    'https://cdn.jsdelivr.net/gh/loganpowell/census-geojson@master/GeoJSON/20m/2021/metropolitan-statistical-area!micropolitan-statistical-area.json';
export const ID_GEOJSON_PROPERTY = 'GEOID';

export const LOADER_POSITIONS = { left: 1 / 4, center: 1 / 2, right: 3 / 4 };

export const GEOJSON_MSA_DATA: {
    type: string;
    geometry: {
        bbox: number[];
    } & (Polygon | MultiPolygon);
    properties: {
        CSAFP: string;
        CBSAFP: string;
        AFFGEOID: string;
        GEOID: string;
        NAME: string;
        NAMELSAD: string;
        LSAD: string;
        ALAND: number;
        AWATER: number;
    };
}[] = [
    {
        type: 'Feature',
        geometry: {
            bbox: [-99.757621, 29.781398, -98.917725, 30.290698],
            type: 'Polygon',
            coordinates: [
                [
                    [-99.754142, 30.290698],
                    [-99.301718, 30.286653],
                    [-99.300988, 30.134298],
                    [-98.920147, 30.13829],
                    [-98.917725, 29.781398],
                    [-99.176988, 29.895063],
                    [-99.602776, 29.907679000000005],
                    [-99.691215, 29.908582],
                    [-99.68987900000002, 30.073948],
                    [-99.757621, 30.074132000000002],
                    [-99.754142, 30.290698],
                ],
            ],
        },
        properties: {
            CSAFP: '314',
            CBSAFP: '28500',
            AFFGEOID: '310M600US28500',
            GEOID: '28500',
            NAME: 'Kerrville, TX',
            NAMELSAD: 'Kerrville, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2857617603,
            AWATER: 10231764,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.462884, 38.20358, -86.679511, 38.54493],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.462884, 38.532727],
                    [-87.351833, 38.542247],
                    [-87.288884, 38.51156],
                    [-87.242097, 38.54493],
                    [-87.107653, 38.537705],
                    [-87.072369, 38.5147],
                    [-86.947663, 38.490453],
                    [-86.924186, 38.505358],
                    [-86.885716, 38.494866],
                    [-86.837462, 38.52646800000001],
                    [-86.682359, 38.526378],
                    [-86.681424, 38.394767],
                    [-86.679511, 38.263086],
                    [-86.792152, 38.2491],
                    [-86.791497, 38.20513],
                    [-87.01749, 38.20358],
                    [-87.073067, 38.232596],
                    [-87.298388, 38.231671],
                    [-87.316893, 38.246077],
                    [-87.316219, 38.381474],
                    [-87.407583, 38.375501],
                    [-87.462486, 38.46561],
                    [-87.462884, 38.532727],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '27540',
            AFFGEOID: '310M600US27540',
            GEOID: '27540',
            NAME: 'Jasper, IN',
            NAMELSAD: 'Jasper, IN Micro Area',
            LSAD: 'M2',
            ALAND: 1972458000,
            AWATER: 38481506,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.074245, 42.421428, -83.922516, 43.132782],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.074245, 42.770784],
                    [-84.837085, 42.770479],
                    [-84.836889, 43.118851],
                    [-84.36776, 43.117942],
                    [-84.367891, 43.12845200000001],
                    [-83.929079, 43.132782],
                    [-83.922516, 42.780821],
                    [-84.158189, 42.776639],
                    [-84.140713, 42.424611],
                    [-84.600403, 42.421984],
                    [-84.718493, 42.42152],
                    [-85.071609, 42.421428],
                    [-85.074245, 42.770784],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '29620',
            AFFGEOID: '310M600US29620',
            GEOID: '29620',
            NAME: 'Lansing-East Lansing, MI',
            NAMELSAD: 'Lansing-East Lansing, MI Metro Area',
            LSAD: 'M1',
            ALAND: 5772069690,
            AWATER: 70045237,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-66.649467, 18.152057, -66.531023, 18.298549],
            type: 'Polygon',
            coordinates: [
                [
                    [-66.597532, 18.283943],
                    [-66.56662, 18.298549],
                    [-66.531023, 18.180137],
                    [-66.543133, 18.163098],
                    [-66.54722, 18.15313],
                    [-66.552766, 18.152057],
                    [-66.591331, 18.172425],
                    [-66.649467, 18.158857],
                    [-66.597532, 18.283943],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '27580',
            AFFGEOID: '310M600US27580',
            GEOID: '27580',
            NAME: 'Jayuya, PR',
            NAMELSAD: 'Jayuya, PR Micro Area',
            LSAD: 'M2',
            ALAND: 115340542,
            AWATER: 3091,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.5224145523987, 36.9993197867829, -95.0735039666303, 37.38399],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.52113, 37.38399],
                    [-95.088189, 37.38383700000001],
                    [-95.075166, 37.339937],
                    [-95.0735039666303, 36.9994881346791],
                    [-95.322565, 36.999358],
                    [-95.407733005873, 36.9993417150323],
                    [-95.5224145523987, 36.9993197867829],
                    [-95.52113, 37.38399],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '37660',
            AFFGEOID: '310M600US37660',
            GEOID: '37660',
            NAME: 'Parsons, KS',
            NAMELSAD: 'Parsons, KS Micro Area',
            LSAD: 'M2',
            ALAND: 1671560715,
            AWATER: 20006201,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.118951, 33.269468, -92.313493, 33.82164],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.116328, 33.452922],
                    [-93.104405, 33.777093],
                    [-93.031537, 33.769801],
                    [-92.917878, 33.82164],
                    [-92.893216, 33.810069],
                    [-92.583027, 33.802299],
                    [-92.334249, 33.795065],
                    [-92.341936, 33.742983],
                    [-92.330918, 33.707745],
                    [-92.313493, 33.492946],
                    [-92.378679, 33.314836],
                    [-92.35375200000001, 33.305142],
                    [-92.350277, 33.298842],
                    [-92.43138600000002, 33.269468],
                    [-92.567156, 33.3672],
                    [-92.66084000000001, 33.355985],
                    [-92.978483, 33.377142],
                    [-93.118951, 33.380298],
                    [-93.116328, 33.452922],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '15780',
            AFFGEOID: '310M600US15780',
            GEOID: '15780',
            NAME: 'Camden, AR',
            NAMELSAD: 'Camden, AR Micro Area',
            LSAD: 'M2',
            ALAND: 3525806335,
            AWATER: 28071065,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.01816800000002, 32.288556, -83.497923, 32.692622],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.00849, 32.521769],
                    [-84.003363, 32.529927],
                    [-83.766787, 32.692622],
                    [-83.70109, 32.691598],
                    [-83.701152, 32.692169],
                    [-83.666134, 32.674518],
                    [-83.597656, 32.664338],
                    [-83.561058, 32.658275],
                    [-83.497923, 32.452198],
                    [-83.498039, 32.401715],
                    [-83.523458, 32.402288000000006],
                    [-83.615579, 32.288556],
                    [-83.68513, 32.289892],
                    [-83.718434, 32.290162],
                    [-83.848379, 32.29097],
                    [-83.85654, 32.382399],
                    [-83.836994, 32.388793],
                    [-83.846676, 32.468514],
                    [-84.01816800000002, 32.506498],
                    [-84.00849, 32.521769],
                ],
            ],
        },
        properties: {
            CSAFP: '356',
            CBSAFP: '47580',
            AFFGEOID: '310M600US47580',
            GEOID: '47580',
            NAME: 'Warner Robins, GA',
            NAMELSAD: 'Warner Robins, GA Metro Area',
            LSAD: 'M1',
            ALAND: 1363188902,
            AWATER: 12668688,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-77.700692, 35.339533, -77.08586, 35.833062],
            type: 'Polygon',
            coordinates: [
                [
                    [-77.665131, 35.674935],
                    [-77.531262, 35.753528],
                    [-77.390154, 35.833062],
                    [-77.350369, 35.819228],
                    [-77.2552, 35.786352],
                    [-77.174, 35.732831],
                    [-77.195772, 35.699779],
                    [-77.172766, 35.6391],
                    [-77.08586, 35.553317],
                    [-77.100803, 35.551455],
                    [-77.176178, 35.518812],
                    [-77.189115, 35.418362],
                    [-77.247896, 35.354415],
                    [-77.29434, 35.37913700000001],
                    [-77.391026, 35.339533],
                    [-77.426191, 35.349524],
                    [-77.475515, 35.426655],
                    [-77.503408, 35.517975],
                    [-77.61037, 35.567552],
                    [-77.700692, 35.65229500000001],
                    [-77.665131, 35.674935],
                ],
            ],
        },
        properties: {
            CSAFP: '272',
            CBSAFP: '24780',
            AFFGEOID: '310M600US24780',
            GEOID: '24780',
            NAME: 'Greenville, NC',
            NAMELSAD: 'Greenville, NC Metro Area',
            LSAD: 'M1',
            ALAND: 1689620170,
            AWATER: 8247778,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-101.542757, 37.736275, -100.22661, 38.264557],
            type: 'Polygon',
            coordinates: [
                [
                    [-101.542312, 38.263207],
                    [-101.125438, 38.264509],
                    [-101.103268, 38.264557],
                    [-100.68469900000001, 38.264137],
                    [-100.244393, 38.26229],
                    [-100.227137, 38.262237],
                    [-100.22661, 38.001015],
                    [-100.664166, 38.00253],
                    [-100.652251, 37.736275],
                    [-101.089667, 37.736337],
                    [-101.527063, 37.736314],
                    [-101.542757, 37.827594],
                    [-101.542312, 38.263207],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '23780',
            AFFGEOID: '310M600US23780',
            GEOID: '23780',
            NAME: 'Garden City, KS',
            NAMELSAD: 'Garden City, KS Micro Area',
            LSAD: 'M2',
            ALAND: 5626854513,
            AWATER: 2849972,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-103.042617950565, 34.747417, -101.085935, 35.627515],
            type: 'Polygon',
            coordinates: [
                [
                    [-103.041554, 35.622487],
                    [-102.162809, 35.627515],
                    [-102.162752, 35.620035],
                    [-101.6228, 35.620196],
                    [-101.62283100000002, 35.624057],
                    [-101.086068, 35.625267],
                    [-101.085935, 35.619102],
                    [-101.086281, 35.18214],
                    [-101.090749, 34.748246],
                    [-101.471562, 34.747462],
                    [-101.629257, 34.747649],
                    [-101.998493, 34.74819],
                    [-102.168839, 34.747417],
                    [-102.167471, 35.183233],
                    [-103.042617950565, 35.1831572590084],
                    [-103.041554, 35.622487],
                ],
            ],
        },
        properties: {
            CSAFP: '108',
            CBSAFP: '11100',
            AFFGEOID: '310M600US11100',
            GEOID: '11100',
            NAME: 'Amarillo, TX',
            NAMELSAD: 'Amarillo, TX Metro Area',
            LSAD: 'M1',
            ALAND: 13340979939,
            AWATER: 84943239,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.034123, 36.6679212723442, -94.617964, 36.999529],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.0076200044721, 36.9995225591258],
                    [-94.995293, 36.999529],
                    [-94.71277, 36.998794],
                    [-94.617964, 36.998905],
                    [-94.618307, 36.76656],
                    [-94.6179917784172, 36.6679212723442],
                    [-94.999403, 36.670631],
                    [-94.999151, 36.943101],
                    [-95.034123, 36.994225],
                    [-95.0076200044721, 36.9995225591258],
                ],
            ],
        },
        properties: {
            CSAFP: '309',
            CBSAFP: '33060',
            AFFGEOID: '310M600US33060',
            GEOID: '33060',
            NAME: 'Miami, OK',
            NAMELSAD: 'Miami, OK Micro Area',
            LSAD: 'M2',
            ALAND: 1219410534,
            AWATER: 35714103,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.728611, 39.839104, -81.225924, 40.221308],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.71627600000001, 40.15216600000001],
                    [-81.670899, 40.150976],
                    [-81.622683, 40.221308],
                    [-81.338057, 40.214253],
                    [-81.339565, 40.171999],
                    [-81.225924, 40.170075],
                    [-81.234053, 39.95127],
                    [-81.386006, 39.950694],
                    [-81.464108, 39.89455],
                    [-81.57859, 39.897683],
                    [-81.580313, 39.839104],
                    [-81.694146, 39.842636],
                    [-81.691104, 39.930756],
                    [-81.728611, 39.931728],
                    [-81.71627600000001, 40.15216600000001],
                ],
            ],
        },
        properties: {
            CSAFP: '198',
            CBSAFP: '15740',
            AFFGEOID: '310M600US15740',
            GEOID: '15740',
            NAME: 'Cambridge, OH',
            NAMELSAD: 'Cambridge, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1352631217,
            AWATER: 15666081,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-102.28734500000002, 31.651276, -101.68874, 32.525222],
            type: 'Polygon',
            coordinates: [
                [
                    [-102.287048, 32.086991],
                    [-102.211249, 32.0868],
                    [-102.211037, 32.523244],
                    [-102.202703, 32.523271],
                    [-101.68874, 32.525222],
                    [-101.695011, 32.087532],
                    [-101.776085, 32.086925],
                    [-101.775802, 31.651319],
                    [-102.28734500000002, 31.651276],
                    [-102.287048, 32.086991],
                ],
            ],
        },
        properties: {
            CSAFP: '372',
            CBSAFP: '33260',
            AFFGEOID: '310M600US33260',
            GEOID: '33260',
            NAME: 'Midland, TX',
            NAMELSAD: 'Midland, TX Metro Area',
            LSAD: 'M1',
            ALAND: 4701639949,
            AWATER: 6184783,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.707367, 39.246454, -92.300871, 39.610265],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.69722800000001, 39.597699],
                    [-92.692149, 39.610265],
                    [-92.300871, 39.605348],
                    [-92.310028, 39.347795],
                    [-92.314471, 39.246454],
                    [-92.430229, 39.248795],
                    [-92.707367, 39.321614],
                    [-92.69722800000001, 39.597699],
                ],
            ],
        },
        properties: {
            CSAFP: '190',
            CBSAFP: '33620',
            AFFGEOID: '310M600US33620',
            GEOID: '33620',
            NAME: 'Moberly, MO',
            NAMELSAD: 'Moberly, MO Micro Area',
            LSAD: 'M2',
            ALAND: 1250217879,
            AWATER: 13254192,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.37293100000001, 33.176944, -80.222267, 33.707131],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.37293100000001, 33.490417],
                    [-81.187271, 33.652937],
                    [-81.08663300000002, 33.695409],
                    [-81.042854, 33.707131],
                    [-80.98421, 33.644797],
                    [-80.93979, 33.608798],
                    [-80.80763100000001, 33.604081],
                    [-80.61657500000001, 33.466006],
                    [-80.552272, 33.565414],
                    [-80.496273, 33.55848100000001],
                    [-80.393145, 33.445339],
                    [-80.222267, 33.443716],
                    [-80.253836, 33.29926],
                    [-80.361851, 33.257443],
                    [-80.484578, 33.280034],
                    [-80.50279, 33.334496],
                    [-80.790296, 33.18084],
                    [-80.797912, 33.176944],
                    [-80.846151, 33.238328],
                    [-80.887109, 33.264183],
                    [-80.889545, 33.261285],
                    [-80.892257, 33.268391],
                    [-80.918425, 33.28337200000001],
                    [-80.940103, 33.30356],
                    [-81.222673, 33.44],
                    [-81.301445, 33.481218],
                    [-81.37293100000001, 33.490417],
                ],
            ],
        },
        properties: {
            CSAFP: '192',
            CBSAFP: '36700',
            AFFGEOID: '310M600US36700',
            GEOID: '36700',
            NAME: 'Orangeburg, SC',
            NAMELSAD: 'Orangeburg, SC Micro Area',
            LSAD: 'M2',
            ALAND: 2865498667,
            AWATER: 55708305,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.231858, 41.862373, -92.765999, 42.21014],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.231722, 42.208886],
                    [-93.001674, 42.209267],
                    [-92.767463, 42.21014],
                    [-92.765999, 41.862373],
                    [-93.231858, 41.862711],
                    [-93.231722, 42.208886],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '32260',
            AFFGEOID: '310M600US32260',
            GEOID: '32260',
            NAME: 'Marshalltown, IA',
            NAMELSAD: 'Marshalltown, IA Micro Area',
            LSAD: 'M2',
            ALAND: 1482776070,
            AWATER: 1803080,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.2022660008303, 40.126127, -94.598487, 40.578375628996],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.2022660008303, 40.578375628996],
                    [-95.06892100000002, 40.57688],
                    [-94.9148978673616, 40.5749211691113],
                    [-94.819978, 40.573714],
                    [-94.6320259176948, 40.5717595874],
                    [-94.631889, 40.472513],
                    [-94.608429, 40.463658],
                    [-94.598487, 40.386714],
                    [-94.605942, 40.126127],
                    [-95.04308, 40.130096],
                    [-95.064972, 40.246589],
                    [-95.180614, 40.261706],
                    [-95.177708, 40.478903],
                    [-95.2022660008303, 40.578375628996],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '32340',
            AFFGEOID: '310M600US32340',
            GEOID: '32340',
            NAME: 'Maryville, MO',
            NAMELSAD: 'Maryville, MO Micro Area',
            LSAD: 'M2',
            ALAND: 2271326613,
            AWATER: 2361588,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-73.929626, 41.97798, -73.352527, 42.510002],
            type: 'Polygon',
            coordinates: [
                [
                    [-73.910675, 42.127293],
                    [-73.786832, 42.273007],
                    [-73.783721, 42.464231],
                    [-73.352527, 42.510002],
                    [-73.4106444074273, 42.3517462996479],
                    [-73.508142, 42.086257],
                    [-73.4896799420321, 42.0537977095867],
                    [-73.527072, 41.97798],
                    [-73.71093, 42.005488],
                    [-73.929626, 42.078778],
                    [-73.910675, 42.127293],
                ],
            ],
        },
        properties: {
            CSAFP: '104',
            CBSAFP: '26460',
            AFFGEOID: '310M600US26460',
            GEOID: '26460',
            NAME: 'Hudson, NY',
            NAMELSAD: 'Hudson, NY Micro Area',
            LSAD: 'M2',
            ALAND: 1643770717,
            AWATER: 35071400,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.636118, 33.512929, -86.953664, 34.005204],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.636118, 34.002203],
                    [-87.216735, 33.993907],
                    [-87.170054, 34.005204],
                    [-87.151036, 33.993225],
                    [-87.091836, 33.890093],
                    [-86.963358, 33.858221],
                    [-86.953664, 33.815297],
                    [-87.005965, 33.787237],
                    [-87.05775700000001, 33.656363],
                    [-87.17943, 33.613794],
                    [-87.185794, 33.555882],
                    [-87.271586, 33.52942],
                    [-87.26692300000002, 33.512929],
                    [-87.318237, 33.514166],
                    [-87.318532, 33.587393],
                    [-87.37104000000001, 33.587065],
                    [-87.423701, 33.602096],
                    [-87.42384300000002, 33.689112],
                    [-87.528338, 33.692049],
                    [-87.531602, 33.867618],
                    [-87.63604, 33.871999],
                    [-87.635932, 33.915251],
                    [-87.636118, 34.002203],
                ],
            ],
        },
        properties: {
            CSAFP: '142',
            CBSAFP: '27530',
            AFFGEOID: '310M600US27530',
            GEOID: '27530',
            NAME: 'Jasper, AL',
            NAMELSAD: 'Jasper, AL Micro Area',
            LSAD: 'M2',
            ALAND: 2048757699,
            AWATER: 36683003,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-101.695011, 32.087136, -101.174571, 32.525222],
            type: 'Polygon',
            coordinates: [
                [
                    [-101.68874, 32.525222],
                    [-101.174571, 32.524112],
                    [-101.183997, 32.087208],
                    [-101.264216, 32.087136],
                    [-101.695011, 32.087532],
                    [-101.68874, 32.525222],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '13700',
            AFFGEOID: '310M600US13700',
            GEOID: '13700',
            NAME: 'Big Spring, TX',
            NAMELSAD: 'Big Spring, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2333039143,
            AWATER: 8841781,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.857738, 33.380662, -95.308637, 33.927195],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.85505, 33.827939],
                    [-95.8448785340707, 33.860421904719],
                    [-95.820596, 33.858465],
                    [-95.7896393177523, 33.8724373846723],
                    [-95.737508, 33.895967],
                    [-95.669978, 33.905844],
                    [-95.603657, 33.927195],
                    [-95.556915, 33.92702],
                    [-95.525322, 33.885487],
                    [-95.44737, 33.86885],
                    [-95.352338, 33.867789],
                    [-95.3104497954491, 33.8738430381855],
                    [-95.308637, 33.380662],
                    [-95.416898, 33.412194],
                    [-95.491897, 33.455337],
                    [-95.603675, 33.484721],
                    [-95.671634, 33.472636],
                    [-95.761076, 33.495387],
                    [-95.826979, 33.458968],
                    [-95.857738, 33.461722],
                    [-95.85505, 33.827939],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '37580',
            AFFGEOID: '310M600US37580',
            GEOID: '37580',
            NAME: 'Paris, TX',
            NAMELSAD: 'Paris, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2349976646,
            AWATER: 66748710,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.057506, 27.032119, -81.562624, 27.340641],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.05649700000001, 27.207769000000003],
                    [-82.055753, 27.338264],
                    [-81.564062, 27.340641],
                    [-81.562624, 27.033835],
                    [-81.983242, 27.03525],
                    [-82.057506, 27.032119],
                    [-82.05649700000001, 27.207769000000003],
                ],
            ],
        },
        properties: {
            CSAFP: '412',
            CBSAFP: '11580',
            AFFGEOID: '310M600US11580',
            GEOID: '11580',
            NAME: 'Arcadia, FL',
            NAMELSAD: 'Arcadia, FL Micro Area',
            LSAD: 'M2',
            ALAND: 1649031791,
            AWATER: 6243548,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.162274, 43.542361, -87.700251, 43.8922088874568],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.162274, 43.891511],
                    [-88.041794, 43.891692],
                    [-87.7285795423089, 43.8922088874568],
                    [-87.736017, 43.873721],
                    [-87.726407, 43.810445],
                    [-87.700251, 43.76735],
                    [-87.708185, 43.722895],
                    [-87.706204, 43.679542],
                    [-87.790135, 43.563054],
                    [-87.7910201400285, 43.543015444928],
                    [-88.040528, 43.542361],
                    [-88.160873, 43.542943],
                    [-88.162274, 43.891511],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '43100',
            AFFGEOID: '310M600US43100',
            GEOID: '43100',
            NAME: 'Sheboygan, WI',
            NAMELSAD: 'Sheboygan, WI Metro Area',
            LSAD: 'M1',
            ALAND: 1324851053,
            AWATER: 1967647657,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.142349, 34.899958, -96.623699, 35.463977],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.14211400000002, 35.37686],
                    [-97.14149, 35.463977],
                    [-96.624681, 35.462707],
                    [-96.623699, 35.400722],
                    [-96.776707, 35.411372],
                    [-96.775681, 34.899958],
                    [-96.813452, 34.944649],
                    [-96.8854, 34.926251],
                    [-96.930586, 34.964364],
                    [-97.01593, 34.907348],
                    [-97.142349, 34.92817600000001],
                    [-97.14211400000002, 35.37686],
                ],
            ],
        },
        properties: {
            CSAFP: '416',
            CBSAFP: '43060',
            AFFGEOID: '310M600US43060',
            GEOID: '43060',
            NAME: 'Shawnee, OK',
            NAMELSAD: 'Shawnee, OK Micro Area',
            LSAD: 'M2',
            ALAND: 2040375639,
            AWATER: 14845673,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-105.05672, 40.00026, -103.57377400619501, 41.001805],
            type: 'Polygon',
            coordinates: [
                [
                    [-105.05672, 40.349281],
                    [-104.94555100000001, 40.349099],
                    [-104.943371, 40.9980741037298],
                    [-104.855273, 40.998048],
                    [-104.497058, 41.001805],
                    [-104.053249, 41.00140600000001],
                    [-103.574522, 41.001721],
                    [-103.57377400619501, 41.0017217990841],
                    [-103.581844, 40.523383],
                    [-104.147714, 40.524389],
                    [-104.150494, 40.35024500000001],
                    [-104.150332, 40.000856],
                    [-104.489757, 40.000613],
                    [-104.96140500000001, 40.000337],
                    [-104.96140500000001, 40.000419],
                    [-104.961538, 40.000404],
                    [-104.96132, 40.014982],
                    [-104.961071, 40.044144],
                    [-105.052823, 40.00026],
                    [-105.055408, 40.018176],
                    [-105.055393, 40.138263],
                    [-105.055089, 40.261793],
                    [-105.05672, 40.349281],
                ],
            ],
        },
        properties: {
            CSAFP: '216',
            CBSAFP: '24540',
            AFFGEOID: '310M600US24540',
            GEOID: '24540',
            NAME: 'Greeley, CO',
            NAMELSAD: 'Greeley, CO Metro Area',
            LSAD: 'M1',
            ALAND: 10320973907,
            AWATER: 82348720,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.3153233371857, 36.497851, -92.764869, 36.816866],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.304359, 36.816866],
                    [-92.909336, 36.809178],
                    [-92.764869, 36.806097],
                    [-92.7723338933721, 36.4980831540794],
                    [-92.838876, 36.498033],
                    [-92.8540081073941, 36.4980234071379],
                    [-93.125969, 36.497851],
                    [-93.2930675065906, 36.4982584490195],
                    [-93.3153233371857, 36.4983127171069],
                    [-93.304359, 36.816866],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '14700',
            AFFGEOID: '310M600US14700',
            GEOID: '14700',
            NAME: 'Branson, MO',
            NAMELSAD: 'Branson, MO Micro Area',
            LSAD: 'M2',
            ALAND: 1637451070,
            AWATER: 50126669,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.169012, 40.562649, -85.863641, 40.999195],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.168459, 40.996097],
                    [-85.946436, 40.999195],
                    [-85.939525, 40.650991],
                    [-85.864791, 40.65169300000001],
                    [-85.863641, 40.565525],
                    [-86.013325, 40.564128],
                    [-86.165024, 40.562649],
                    [-86.169012, 40.909836],
                    [-86.168459, 40.996097],
                ],
            ],
        },
        properties: {
            CSAFP: '316',
            CBSAFP: '37940',
            AFFGEOID: '310M600US37940',
            GEOID: '37940',
            NAME: 'Peru, IN',
            NAMELSAD: 'Peru, IN Micro Area',
            LSAD: 'M2',
            ALAND: 968246953,
            AWATER: 9184624,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.946436, 40.650991, -85.638587, 41.046716],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.945561, 41.042876],
                    [-85.795814, 41.043588],
                    [-85.684181, 41.046716],
                    [-85.643841, 41.002305],
                    [-85.638587, 40.653129],
                    [-85.864791, 40.65169300000001],
                    [-85.939525, 40.650991],
                    [-85.946436, 40.999195],
                    [-85.945561, 41.042876],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '47340',
            AFFGEOID: '310M600US47340',
            GEOID: '47340',
            NAME: 'Wabash, IN',
            NAMELSAD: 'Wabash, IN Micro Area',
            LSAD: 'M2',
            ALAND: 1068471112,
            AWATER: 21851091,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.551158, 31.917511000000005, -97.864864, 32.513039],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.474685, 32.299084],
                    [-98.475177, 32.513039],
                    [-98.068545, 32.511626],
                    [-97.950265, 32.244263],
                    [-97.945625, 32.233468],
                    [-97.864864, 32.087328],
                    [-98.005462, 32.017897],
                    [-98.208386, 31.917511000000005],
                    [-98.250318, 31.979003],
                    [-98.271184, 31.969949],
                    [-98.327499, 31.941261000000004],
                    [-98.551158, 32.261436],
                    [-98.474685, 32.299084],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '44500',
            AFFGEOID: '310M600US44500',
            GEOID: '44500',
            NAME: 'Stephenville, TX',
            NAMELSAD: 'Stephenville, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2805419712,
            AWATER: 17166727,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.927398038772, 34.297994, -79.4502772890434, 34.807862590631],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.9246842531364, 34.8078290197515],
                    [-79.6929463837093, 34.8049623069232],
                    [-79.675299, 34.804744],
                    [-79.4617910310013, 34.6300306785915],
                    [-79.4502772890434, 34.6206089970087],
                    [-79.633413, 34.297994],
                    [-79.65776400000001, 34.30519],
                    [-79.750566, 34.436797],
                    [-79.752769, 34.514059],
                    [-79.829981, 34.531925],
                    [-79.777456, 34.61287],
                    [-79.871959, 34.693841],
                    [-79.927398038772, 34.807862590631],
                    [-79.9246842531364, 34.8078290197515],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '13500',
            AFFGEOID: '310M600US13500',
            GEOID: '13500',
            NAME: 'Bennettsville, SC',
            NAMELSAD: 'Bennettsville, SC Micro Area',
            LSAD: 'M2',
            ALAND: 1242867261,
            AWATER: 14489019,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.036069, 40.010188, -83.494498, 40.273459],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.022919, 40.183945],
                    [-84.014763, 40.273459],
                    [-83.784196, 40.260046],
                    [-83.551338, 40.22937],
                    [-83.494498, 40.225467],
                    [-83.503714, 40.111468],
                    [-83.516155, 40.010188],
                    [-84.036069, 40.040182],
                    [-84.022919, 40.183945],
                ],
            ],
        },
        properties: {
            CSAFP: '212',
            CBSAFP: '46500',
            AFFGEOID: '310M600US46500',
            GEOID: '46500',
            NAME: 'Urbana, OH',
            NAMELSAD: 'Urbana, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1111077538,
            AWATER: 2173115,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-110.460752, 31.332501999999998, -109.04761200000002, 32.427572],
            type: 'Polygon',
            coordinates: [
                [
                    [-110.460752, 31.613883000000005],
                    [-110.4523, 31.731219000000003],
                    [-110.451002, 32.42754600000001],
                    [-109.432075, 32.427572],
                    [-109.22774800000002, 32.424755],
                    [-109.213077, 32.426361],
                    [-109.11418, 32.426353],
                    [-109.04761200000002, 32.426377],
                    [-109.048296, 32.084093],
                    [-109.049195, 31.796551],
                    [-109.050044, 31.332501999999998],
                    [-109.829689, 31.334067000000005],
                    [-110.45975162749302, 31.333141997042],
                    [-110.460752, 31.613883000000005],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '43420',
            AFFGEOID: '310M600US43420',
            GEOID: '43420',
            NAME: 'Sierra Vista-Douglas, AZ',
            NAMELSAD: 'Sierra Vista-Douglas, AZ Metro Area',
            LSAD: 'M1',
            ALAND: 16083178109,
            AWATER: 17664572,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-99.41388, 28.640566000000003, -98.800841, 29.091335],
            type: 'Polygon',
            coordinates: [
                [
                    [-99.41388, 29.091335],
                    [-98.80490000000002, 29.090434000000002],
                    [-98.800841, 28.647487],
                    [-99.395736, 28.640784],
                    [-99.409021, 28.640566000000003],
                    [-99.41388, 29.091335],
                ],
            ],
        },
        properties: {
            CSAFP: '484',
            CBSAFP: '37770',
            AFFGEOID: '310M600US37770',
            GEOID: '37770',
            NAME: 'Pearsall, TX',
            NAMELSAD: 'Pearsall, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2935753504,
            AWATER: 2193621,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.280563, 35.1797077063839, -81.691989, 35.613588],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.265795, 35.467818],
                    [-82.233203, 35.519402],
                    [-82.16904900000002, 35.527811],
                    [-81.967619, 35.52600700000001],
                    [-81.842468, 35.542263],
                    [-81.824122, 35.574983],
                    [-81.753407, 35.613588],
                    [-81.691989, 35.580041],
                    [-81.702853, 35.357304],
                    [-81.7681344077739, 35.1797077063839],
                    [-81.8744102050921, 35.183520599502],
                    [-81.9693584012576, 35.1869270884005],
                    [-81.968788, 35.257764],
                    [-82.151916, 35.407719],
                    [-82.261305, 35.393198],
                    [-82.280563, 35.443069],
                    [-82.265795, 35.467818],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '22580',
            AFFGEOID: '310M600US22580',
            GEOID: '22580',
            NAME: 'Forest City, NC',
            NAMELSAD: 'Forest City, NC Micro Area',
            LSAD: 'M2',
            ALAND: 1464476090,
            AWATER: 4692959,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.129671, 44.195705000000004, -96.452213, 44.543773],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.128024, 44.543126],
                    [-96.885504, 44.543773],
                    [-96.4532913135987, 44.543636801818],
                    [-96.452213, 44.360149],
                    [-96.4524358245955, 44.196801586665],
                    [-96.4524358546695, 44.1967795400951],
                    [-96.889216, 44.195705000000004],
                    [-97.129671, 44.195933],
                    [-97.128024, 44.543126],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '15100',
            AFFGEOID: '310M600US15100',
            GEOID: '15100',
            NAME: 'Brookings, SD',
            NAMELSAD: 'Brookings, SD Micro Area',
            LSAD: 'M2',
            ALAND: 2051833296,
            AWATER: 32942236,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.301584, 39.525476, -85.034575, 39.788449],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.301584, 39.787579],
                    [-85.221118, 39.788449],
                    [-85.185089, 39.715515],
                    [-85.034575, 39.71476400000001],
                    [-85.036087, 39.526213],
                    [-85.29811, 39.525476],
                    [-85.301584, 39.787579],
                ],
            ],
        },
        properties: {
            CSAFP: '458',
            CBSAFP: '18220',
            AFFGEOID: '310M600US18220',
            GEOID: '18220',
            NAME: 'Connersville, IN',
            NAMELSAD: 'Connersville, IN Micro Area',
            LSAD: 'M2',
            ALAND: 556901577,
            AWATER: 366408,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.999779, 41.171861, -79.477822, 41.624559],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.999779, 41.490049],
                    [-79.82911100000001, 41.62207000000001],
                    [-79.613147, 41.623863],
                    [-79.51207, 41.624559],
                    [-79.512276, 41.46524],
                    [-79.478163, 41.440134],
                    [-79.477822, 41.386479],
                    [-79.47814, 41.335708],
                    [-79.604161, 41.321478],
                    [-79.694984, 41.172865],
                    [-79.885546, 41.172463],
                    [-79.999779, 41.171861],
                    [-79.999779, 41.490049],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '36340',
            AFFGEOID: '310M600US36340',
            GEOID: '36340',
            NAME: 'Oil City, PA',
            NAMELSAD: 'Oil City, PA Micro Area',
            LSAD: 'M2',
            ALAND: 1746337768,
            AWATER: 22149577,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-114.814185, 32.0402490801033, -113.333709, 33.464413],
            type: 'Polygon',
            coordinates: [
                [
                    [-114.799683, 32.593621],
                    [-114.809393, 32.617119],
                    [-114.76494999999998, 32.649391],
                    [-114.71963300000002, 32.718763],
                    [-114.705717, 32.741581],
                    [-114.66749300000001, 32.734226],
                    [-114.617386523962, 32.7410527731766],
                    [-114.570675, 32.747417],
                    [-114.531746, 32.782503],
                    [-114.468971, 32.845155],
                    [-114.463127, 32.901884],
                    [-114.47664, 32.923628],
                    [-114.481315, 32.972064],
                    [-114.511343, 33.023455],
                    [-114.517492069319, 33.0247159744928],
                    [-114.2688, 33.029961],
                    [-114.268808, 33.464402],
                    [-113.957514, 33.464413],
                    [-113.957489, 33.377362],
                    [-113.335048, 33.377469],
                    [-113.333709, 32.767851],
                    [-113.333894, 32.505188],
                    [-113.333767973058, 32.0402490801033],
                    [-113.750756, 32.169005],
                    [-114.81361299999999, 32.494277],
                    [-114.811536, 32.522834],
                    [-114.795635, 32.550956],
                    [-114.814185, 32.564788],
                    [-114.799683, 32.593621],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '49740',
            AFFGEOID: '310M600US49740',
            GEOID: '49740',
            NAME: 'Yuma, AZ',
            NAMELSAD: 'Yuma, AZ Metro Area',
            LSAD: 'M1',
            ALAND: 14280774791,
            AWATER: 13253159,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-103.667073, 46.629764, -102.095904, 47.32925600000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-103.666675, 47.32925600000001],
                    [-103.099197, 47.328714],
                    [-103.032366, 47.328686],
                    [-103.032681, 46.98058000000001],
                    [-102.525364, 46.981288],
                    [-102.144771, 47.010307],
                    [-102.095904, 46.981329],
                    [-102.09624, 46.717772],
                    [-102.096744, 46.631026],
                    [-102.927451, 46.63002],
                    [-103.231656, 46.629764],
                    [-103.609273, 46.629822],
                    [-103.609592, 46.979854],
                    [-103.667073, 46.979775],
                    [-103.666675, 47.32925600000001],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '19860',
            AFFGEOID: '310M600US19860',
            GEOID: '19860',
            NAME: 'Dickinson, ND',
            NAMELSAD: 'Dickinson, ND Micro Area',
            LSAD: 'M2',
            ALAND: 6431980205,
            AWATER: 26010402,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-94.418543, 47.026406, -93.056761, 47.89895],
            type: 'Polygon',
            coordinates: [
                [
                    [-94.418543, 47.845816],
                    [-93.776173, 47.846404],
                    [-93.775772, 47.89895],
                    [-93.08090600000001, 47.891864],
                    [-93.061497, 47.720406],
                    [-93.06963600000002, 47.400174],
                    [-93.056761, 47.026406],
                    [-93.775257, 47.030418],
                    [-93.77393, 47.211335],
                    [-93.80882, 47.232622],
                    [-93.77401, 47.30506],
                    [-93.888213, 47.318856],
                    [-93.911505, 47.297868],
                    [-94.042287, 47.373112],
                    [-94.027858, 47.408633],
                    [-94.095115, 47.477972],
                    [-94.327531, 47.415844],
                    [-94.416279, 47.444828],
                    [-94.418543, 47.845816],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '24330',
            AFFGEOID: '310M600US24330',
            GEOID: '24330',
            NAME: 'Grand Rapids, MN',
            NAMELSAD: 'Grand Rapids, MN Micro Area',
            LSAD: 'M2',
            ALAND: 6909414890,
            AWATER: 673890473,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-101.086281, 35.18214, -100.538978, 36.057572],
            type: 'Polygon',
            coordinates: [
                [
                    [-101.085935, 35.619102],
                    [-101.086068, 35.625267],
                    [-101.085735, 36.055276],
                    [-101.085716, 36.057572],
                    [-100.546724, 36.056536],
                    [-100.540221, 36.056491],
                    [-100.540158, 35.619296],
                    [-100.538978, 35.183144],
                    [-101.086281, 35.18214],
                    [-101.085935, 35.619102],
                ],
            ],
        },
        properties: {
            CSAFP: '108',
            CBSAFP: '37420',
            AFFGEOID: '310M600US37420',
            GEOID: '37420',
            NAME: 'Pampa, TX',
            NAMELSAD: 'Pampa, TX Micro Area',
            LSAD: 'M2',
            ALAND: 4791560121,
            AWATER: 9047636,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-100.003761993368, 35.842089, -98.955849, 36.819566],
            type: 'Polygon',
            coordinates: [
                [
                    [-100.00316, 36.59325],
                    [-99.605634, 36.592754],
                    [-99.295193, 36.595031],
                    [-99.295186, 36.819566],
                    [-99.250383, 36.785015],
                    [-99.139855, 36.78304],
                    [-98.959973, 36.598991],
                    [-98.960334, 36.506914],
                    [-98.955849, 36.161577],
                    [-99.382074, 36.16430100000001],
                    [-99.38102200000002, 36.01292500000001],
                    [-99.495812, 35.978732],
                    [-99.558486, 35.865988],
                    [-99.619813, 35.842089],
                    [-99.717101, 35.859769],
                    [-99.801389, 35.910645],
                    [-99.809444, 36.000044],
                    [-99.888309, 35.987847],
                    [-99.925147, 35.913664],
                    [-100.000396202899, 35.8812329999967],
                    [-100.000399, 36.055677],
                    [-100.000406, 36.499702],
                    [-100.003761993368, 36.4997018487376],
                    [-100.00316, 36.59325],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '49260',
            AFFGEOID: '310M600US49260',
            GEOID: '49260',
            NAME: 'Woodward, OK',
            NAMELSAD: 'Woodward, OK Micro Area',
            LSAD: 'M2',
            ALAND: 6408311170,
            AWATER: 10645808,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.317818, 44.247678, -89.724746, 44.685154],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.316055, 44.424502],
                    [-90.31625300000002, 44.685154],
                    [-89.964013, 44.684829],
                    [-89.844931, 44.684945],
                    [-89.844546, 44.511503],
                    [-89.726725, 44.511192],
                    [-89.724746, 44.247678],
                    [-89.902783, 44.249473],
                    [-90.080822, 44.249011],
                    [-90.31268, 44.24875],
                    [-90.317818, 44.26595],
                    [-90.316055, 44.424502],
                ],
            ],
        },
        properties: {
            CSAFP: '554',
            CBSAFP: '49220',
            AFFGEOID: '310M600US49220',
            GEOID: '49220',
            NAME: 'Wisconsin Rapids-Marshfield, WI',
            NAMELSAD: 'Wisconsin Rapids-Marshfield, WI Micro Area',
            LSAD: 'M2',
            ALAND: 2053959984,
            AWATER: 41993013,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.149841, 38.124767000000006, -88.698961, 38.474914000000005],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.147597, 38.212895],
                    [-89.144388, 38.473878],
                    [-88.698961, 38.474914000000005],
                    [-88.702391, 38.256661],
                    [-88.704606, 38.125195],
                    [-89.129632, 38.124767000000006],
                    [-89.149841, 38.125054],
                    [-89.147597, 38.212895],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '34500',
            AFFGEOID: '310M600US34500',
            GEOID: '34500',
            NAME: 'Mount Vernon, IL',
            NAMELSAD: 'Mount Vernon, IL Micro Area',
            LSAD: 'M2',
            ALAND: 1479490496,
            AWATER: 32550293,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.973643, 36.236305, -80.4403427430004, 36.562319],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.973643, 36.453474],
                    [-80.901661118921, 36.561751187168],
                    [-80.840213077246, 36.5619284516373],
                    [-80.704831, 36.562319],
                    [-80.6121887881246, 36.558216276380406],
                    [-80.4403427430004, 36.5506059569159],
                    [-80.451925, 36.261515],
                    [-80.452656, 36.241651],
                    [-80.547885, 36.285419],
                    [-80.624245, 36.28682200000001],
                    [-80.873205, 36.236305],
                    [-80.868746, 36.326447],
                    [-80.96715300000001, 36.40202],
                    [-80.973643, 36.453474],
                ],
            ],
        },
        properties: {
            CSAFP: '268',
            CBSAFP: '34340',
            AFFGEOID: '310M600US34340',
            GEOID: '34340',
            NAME: 'Mount Airy, NC',
            NAMELSAD: 'Mount Airy, NC Micro Area',
            LSAD: 'M2',
            ALAND: 1379543351,
            AWATER: 10391163,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.531021612428, 40.127406, -86.373521, 40.7368850814229],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.530054, 40.250671],
                    [-87.5270652633992, 40.476882292461504],
                    [-87.5268757732538, 40.4912244096904],
                    [-87.526292, 40.535409],
                    [-87.5261364904237, 40.7368850814229],
                    [-87.267298, 40.736582],
                    [-87.09839, 40.736541],
                    [-87.097069, 40.714401],
                    [-87.09535700000002, 40.562895],
                    [-86.77129300000001, 40.562082],
                    [-86.750011, 40.736027],
                    [-86.581739, 40.735765],
                    [-86.525164, 40.69241900000001],
                    [-86.373521, 40.69246],
                    [-86.374417, 40.561361],
                    [-86.375762, 40.431851],
                    [-86.694665, 40.432156],
                    [-86.695637, 40.214367],
                    [-87.092563, 40.214806],
                    [-87.092148, 40.366612],
                    [-87.244898, 40.309252],
                    [-87.438755, 40.165694],
                    [-87.406667, 40.127508],
                    [-87.49041, 40.127406],
                    [-87.531021612428, 40.1480353967457],
                    [-87.530054, 40.250671],
                ],
            ],
        },
        properties: {
            CSAFP: '320',
            CBSAFP: '29200',
            AFFGEOID: '310M600US29200',
            GEOID: '29200',
            NAME: 'Lafayette-West Lafayette, IN',
            NAMELSAD: 'Lafayette-West Lafayette, IN Metro Area',
            LSAD: 'M1',
            ALAND: 4253381259,
            AWATER: 23145985,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.926997, 43.935143, -97.853028, 44.634159],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.925953, 44.196575],
                    [-98.700453, 44.196727],
                    [-98.705762, 44.634159],
                    [-97.977791, 44.631602],
                    [-97.856226, 44.631178],
                    [-97.853028, 44.544398],
                    [-97.85366, 44.195233],
                    [-98.33191, 44.196687],
                    [-98.331508, 43.937708],
                    [-98.807771, 43.935223],
                    [-98.926997, 43.935143],
                    [-98.925953, 44.196575],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '26700',
            AFFGEOID: '310M600US26700',
            GEOID: '26700',
            NAME: 'Huron, SD',
            NAMELSAD: 'Huron, SD Micro Area',
            LSAD: 'M2',
            ALAND: 4622689252,
            AWATER: 32562203,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.508328, 38.389945, -95.056412, 38.738808],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.508267, 38.433933],
                    [-95.508042, 38.695268],
                    [-95.50073800000001, 38.738808],
                    [-95.056412, 38.738587],
                    [-95.065772, 38.694903],
                    [-95.065831, 38.389945],
                    [-95.508328, 38.390278],
                    [-95.508267, 38.433933],
                ],
            ],
        },
        properties: {
            CSAFP: '312',
            CBSAFP: '36840',
            AFFGEOID: '310M600US36840',
            GEOID: '36840',
            NAME: 'Ottawa, KS',
            NAMELSAD: 'Ottawa, KS Micro Area',
            LSAD: 'M2',
            ALAND: 1480882204,
            AWATER: 13893465,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-66.44456, 18.015891, -66.257527, 18.176674],
            type: 'Polygon',
            coordinates: [
                [
                    [-66.42346, 18.172071000000003],
                    [-66.410062, 18.16299],
                    [-66.373768, 18.173694],
                    [-66.323333, 18.165272],
                    [-66.314848, 18.152088],
                    [-66.320913, 18.126005],
                    [-66.257527, 18.075884],
                    [-66.310623, 18.046967],
                    [-66.331244, 18.015891],
                    [-66.356779, 18.036942],
                    [-66.426989, 18.042627],
                    [-66.430314, 18.082053],
                    [-66.419368, 18.145304],
                    [-66.44456, 18.176674],
                    [-66.42346, 18.172071000000003],
                ],
            ],
        },
        properties: {
            CSAFP: '434',
            CBSAFP: '17620',
            AFFGEOID: '310M600US17620',
            GEOID: '17620',
            NAME: 'Coamo, PR',
            NAMELSAD: 'Coamo, PR Micro Area',
            LSAD: 'M2',
            ALAND: 202052321,
            AWATER: 38061,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.311697, 28.308925, -81.95419, 28.960390999999998],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.311697, 28.960390999999998],
                    [-81.95419, 28.960053],
                    [-81.954247, 28.55169],
                    [-81.957641, 28.34518],
                    [-81.958029, 28.308925],
                    [-82.055819, 28.312794],
                    [-82.054694, 28.478397],
                    [-82.054469, 28.521367],
                    [-82.208184, 28.572054],
                    [-82.274823, 28.656198000000003],
                    [-82.263052, 28.667634],
                    [-82.16914100000001, 28.792954000000005],
                    [-82.311697, 28.960390999999998],
                ],
            ],
        },
        properties: {
            CSAFP: '422',
            CBSAFP: '45540',
            AFFGEOID: '310M600US45540',
            GEOID: '45540',
            NAME: 'The Villages, FL',
            NAMELSAD: 'The Villages, FL Metro Area',
            LSAD: 'M1',
            ALAND: 1442978468,
            AWATER: 58762672,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.1650703923738, 42.700678985373, -96.805682, 43.083733],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.16036, 42.811795],
                    [-97.160543, 43.083145],
                    [-96.924142, 43.083733],
                    [-96.805682, 43.08367],
                    [-96.8073706000982, 42.700678985373],
                    [-96.906797, 42.7338],
                    [-96.965679, 42.724532],
                    [-97.0156311127472, 42.7565254624882],
                    [-97.02485, 42.76243],
                    [-97.131331, 42.771929],
                    [-97.1650703923738, 42.7916166414677],
                    [-97.16036, 42.811795],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '46820',
            AFFGEOID: '310M600US46820',
            GEOID: '46820',
            NAME: 'Vermillion, SD',
            NAMELSAD: 'Vermillion, SD Micro Area',
            LSAD: 'M2',
            ALAND: 1067154437,
            AWATER: 13955918,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.47308, 32.047514, -86.810313, 32.729532],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.47308, 32.307614],
                    [-87.421744, 32.30810100000001],
                    [-87.42315300000001, 32.482965],
                    [-87.110817, 32.489948],
                    [-87.01776200000002, 32.729532],
                    [-87.01766, 32.663269],
                    [-86.917595, 32.664169],
                    [-86.906403, 32.536712],
                    [-86.814912, 32.340803],
                    [-86.864367, 32.274918],
                    [-86.810313, 32.224747],
                    [-86.908302, 32.225028],
                    [-86.906956, 32.04797],
                    [-87.17786, 32.047514],
                    [-87.262853, 32.147007],
                    [-87.472206, 32.264919],
                    [-87.47308, 32.307614],
                ],
            ],
        },
        properties: {
            CSAFP: '388',
            CBSAFP: '42820',
            AFFGEOID: '310M600US42820',
            GEOID: '42820',
            NAME: 'Selma, AL',
            NAMELSAD: 'Selma, AL Micro Area',
            LSAD: 'M2',
            ALAND: 2534919608,
            AWATER: 39124778,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.0612581876299, 41.9988381187249, -78.3081443402607, 42.537853],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.060777, 42.537853],
                    [-78.991702, 42.529249],
                    [-78.919766, 42.442386],
                    [-78.695937, 42.47194],
                    [-78.46394, 42.536332],
                    [-78.45044700000001, 42.519209],
                    [-78.308839, 42.521217],
                    [-78.3081443402607, 41.99907117769861],
                    [-78.59665, 41.999877],
                    [-78.9188983633788, 41.9991031002259],
                    [-78.983065, 41.998949],
                    [-79.0612581876299, 41.9988381187249],
                    [-79.060777, 42.537853],
                ],
            ],
        },
        properties: {
            CSAFP: '160',
            CBSAFP: '36460',
            AFFGEOID: '310M600US36460',
            GEOID: '36460',
            NAME: 'Olean, NY',
            NAMELSAD: 'Olean, NY Micro Area',
            LSAD: 'M2',
            ALAND: 3388125876,
            AWATER: 36783591,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.278553, 38.490453, -86.904252, 38.904204],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.278553, 38.691343],
                    [-87.238551, 38.743235],
                    [-87.241463, 38.817476],
                    [-87.213267, 38.807977],
                    [-87.098771, 38.903974],
                    [-87.03542, 38.90246],
                    [-86.904252, 38.904204],
                    [-86.922943, 38.817238],
                    [-86.924186, 38.505358],
                    [-86.947663, 38.490453],
                    [-87.072369, 38.5147],
                    [-87.107653, 38.537705],
                    [-87.242097, 38.54493],
                    [-87.278553, 38.691343],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '47780',
            AFFGEOID: '310M600US47780',
            GEOID: '47780',
            NAME: 'Washington, IN',
            NAMELSAD: 'Washington, IN Micro Area',
            LSAD: 'M2',
            ALAND: 1112396615,
            AWATER: 19102189,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.293626, 41.75975527470181, -84.8251282838768, 42.07246800000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.293626, 42.071553],
                    [-84.826491, 42.07246800000001],
                    [-84.8251282838768, 41.760199006351904],
                    [-85.196774200302, 41.759870841803],
                    [-85.232835, 41.759839],
                    [-85.2921788113335, 41.75975527470181],
                    [-85.293626, 42.071553],
                ],
            ],
        },
        properties: {
            CSAFP: '310',
            CBSAFP: '17740',
            AFFGEOID: '310M600US17740',
            GEOID: '17740',
            NAME: 'Coldwater, MI',
            NAMELSAD: 'Coldwater, MI Micro Area',
            LSAD: 'M2',
            ALAND: 1311597696,
            AWATER: 34428004,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-67.21197300000001, 17.931135, -66.884653, 18.174053],
            type: 'Polygon',
            coordinates: [
                [
                    [-67.209887, 18.035439],
                    [-67.199314, 18.091135],
                    [-67.180822, 18.168055],
                    [-67.180754718857, 18.1681984717822],
                    [-67.159733, 18.153227],
                    [-67.126157, 18.115143000000003],
                    [-67.100492, 18.106395],
                    [-67.08105, 18.106715000000005],
                    [-67.086359, 18.149829],
                    [-67.051461, 18.174053],
                    [-67.013844, 18.165874],
                    [-66.977855, 18.143799],
                    [-66.926517, 18.151267],
                    [-66.901033, 18.039854],
                    [-66.884653, 18.024816],
                    [-66.910887, 18.023883],
                    [-66.958748, 18.032477],
                    [-66.961935, 17.991592],
                    [-66.9787006424388, 17.9572909420809],
                    [-66.982206, 17.961192],
                    [-67.014744, 17.968468],
                    [-67.054455, 17.973165],
                    [-67.089827, 17.951418],
                    [-67.1078094342763, 17.9516231929024],
                    [-67.133733, 17.951919000000004],
                    [-67.183457, 17.931135],
                    [-67.21197300000001, 17.992993],
                    [-67.209887, 18.035439],
                ],
            ],
        },
        properties: {
            CSAFP: '364',
            CBSAFP: '41900',
            AFFGEOID: '310M600US41900',
            GEOID: '41900',
            NAME: 'San Germán, PR',
            NAMELSAD: 'San Germán, PR Metro Area',
            LSAD: 'M1',
            ALAND: 572730873,
            AWATER: 383943230,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-66.9787006424388, 17.926875, -66.678087, 18.171242],
            type: 'Polygon',
            coordinates: [
                [
                    [-66.961935, 17.991592],
                    [-66.958748, 18.032477],
                    [-66.910887, 18.023883],
                    [-66.884653, 18.024816],
                    [-66.901033, 18.039854],
                    [-66.926517, 18.151267],
                    [-66.85678, 18.13859],
                    [-66.83676, 18.170553000000005],
                    [-66.827327, 18.171242],
                    [-66.797557, 18.133445],
                    [-66.769049, 18.134983],
                    [-66.749618, 18.113664],
                    [-66.69407, 18.130534],
                    [-66.678087, 18.1007],
                    [-66.6970344469585, 17.9819737685972],
                    [-66.716957, 17.990344],
                    [-66.746248, 17.990349],
                    [-66.758467, 17.995181],
                    [-66.7753589020879, 17.9844314338257],
                    [-66.784953, 17.978326],
                    [-66.838584, 17.949931],
                    [-66.8579139400858, 17.951049271681],
                    [-66.8583208621404, 17.9510728128512],
                    [-66.88344, 17.952526000000002],
                    [-66.927261, 17.926875],
                    [-66.95557700000002, 17.931557],
                    [-66.9787006424388, 17.9572909420809],
                    [-66.961935, 17.991592],
                ],
            ],
        },
        properties: {
            CSAFP: '434',
            CBSAFP: '49500',
            AFFGEOID: '310M600US49500',
            GEOID: '49500',
            NAME: 'Yauco, PR',
            NAMELSAD: 'Yauco, PR Metro Area',
            LSAD: 'M1',
            ALAND: 496370944,
            AWATER: 228599868,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.388078, 43.255054, -94.913723, 43.500885],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.3877875940219, 43.5004787289074],
                    [-95.214938, 43.500885],
                    [-94.9146134847136, 43.5005958729861],
                    [-94.913723, 43.255054],
                    [-95.388078, 43.255221000000006],
                    [-95.3877875940219, 43.5004787289074],
                ],
            ],
        },
        properties: {
            CSAFP: '517',
            CBSAFP: '44020',
            AFFGEOID: '310M600US44020',
            GEOID: '44020',
            NAME: 'Spirit Lake, IA',
            NAMELSAD: 'Spirit Lake, IA Micro Area',
            LSAD: 'M2',
            ALAND: 985574547,
            AWATER: 60680641,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.855384, 37.472944, -92.24886500000001, 37.895736],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.855384, 37.895736],
                    [-92.620181, 37.892521],
                    [-92.549074, 37.800539],
                    [-92.409231, 37.85918],
                    [-92.41366900000001, 37.713941],
                    [-92.24886500000001, 37.648442],
                    [-92.24946300000002, 37.604543],
                    [-92.252261, 37.472944],
                    [-92.686671, 37.481545],
                    [-92.853481, 37.48397],
                    [-92.846281, 37.721039],
                    [-92.855384, 37.895736],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '30060',
            AFFGEOID: '310M600US30060',
            GEOID: '30060',
            NAME: 'Lebanon, MO',
            NAMELSAD: 'Lebanon, MO Micro Area',
            LSAD: 'M2',
            ALAND: 1980386265,
            AWATER: 8708349,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.004189, 26.312702, -97.2272884780129, 26.611769],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.957405, 26.611769],
                    [-97.861688, 26.597995],
                    [-97.2875411166858, 26.6003398814733],
                    [-97.253801, 26.503164000000005],
                    [-97.2272884780129, 26.411177679957504],
                    [-97.386505, 26.410437],
                    [-97.446987, 26.324947],
                    [-97.506936, 26.312702],
                    [-97.861875, 26.348156],
                    [-97.86192, 26.433579],
                    [-98.004189, 26.448784],
                    [-97.957405, 26.611769],
                ],
            ],
        },
        properties: {
            CSAFP: '154',
            CBSAFP: '39700',
            AFFGEOID: '310M600US39700',
            GEOID: '39700',
            NAME: 'Raymondville, TX',
            NAMELSAD: 'Raymondville, TX Micro Area',
            LSAD: 'M2',
            ALAND: 1529652214,
            AWATER: 502884780,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.088189, 37.338418, -94.617511, 37.674519],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.088012, 37.674519],
                    [-94.6178724508981, 37.6731107003321],
                    [-94.6178455243542, 37.6535777966344],
                    [-94.617511, 37.410909],
                    [-94.6176676047008, 37.3641703270387],
                    [-94.6177538916066, 37.338418],
                    [-95.075166, 37.339937],
                    [-95.088189, 37.38383700000001],
                    [-95.088012, 37.674519],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '38260',
            AFFGEOID: '310M600US38260',
            GEOID: '38260',
            NAME: 'Pittsburg, KS',
            NAMELSAD: 'Pittsburg, KS Micro Area',
            LSAD: 'M2',
            ALAND: 1527499258,
            AWATER: 13573629,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.145562, 33.55622, -85.53009400000002, 33.96846],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.145562, 33.679098],
                    [-86.043993, 33.763595],
                    [-86.065272, 33.842198],
                    [-85.96917, 33.914598],
                    [-85.846174, 33.95726],
                    [-85.740968, 33.935301],
                    [-85.738975, 33.96846],
                    [-85.542214, 33.956732],
                    [-85.53009400000002, 33.94142300000001],
                    [-85.638049, 33.773339],
                    [-85.65581, 33.627166],
                    [-85.796054, 33.55622],
                    [-85.794559, 33.585565],
                    [-85.994935, 33.586475],
                    [-86.050669, 33.67459],
                    [-86.145562, 33.679098],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '11500',
            AFFGEOID: '310M600US11500',
            GEOID: '11500',
            NAME: 'Anniston-Oxford, AL',
            NAMELSAD: 'Anniston-Oxford, AL Metro Area',
            LSAD: 'M1',
            ALAND: 1569246126,
            AWATER: 16536293,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.526894818161, 34.286097, -85.107748, 34.5886856927335],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.526894818161, 34.5886856927335],
                    [-85.360191, 34.581799],
                    [-85.107748, 34.587483],
                    [-85.180548, 34.435996],
                    [-85.334881, 34.369748],
                    [-85.38735100000001, 34.286097],
                    [-85.4631408247758, 34.2861909885055],
                    [-85.4751472811043, 34.3436846578762],
                    [-85.502471, 34.474526],
                    [-85.5130442292733, 34.5239464713237],
                    [-85.526894818161, 34.5886856927335],
                ],
            ],
        },
        properties: {
            CSAFP: '174',
            CBSAFP: '44900',
            AFFGEOID: '310M600US44900',
            GEOID: '44900',
            NAME: 'Summerville, GA',
            NAMELSAD: 'Summerville, GA Micro Area',
            LSAD: 'M2',
            ALAND: 811512398,
            AWATER: 570806,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.467108, 41.171442, -86.054116, 41.479154],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.467108, 41.476459000000006],
                    [-86.059451, 41.479154],
                    [-86.05923, 41.435812],
                    [-86.054116, 41.176368],
                    [-86.077551, 41.172868],
                    [-86.467046, 41.171442],
                    [-86.466603, 41.432968],
                    [-86.467108, 41.476459000000006],
                ],
            ],
        },
        properties: {
            CSAFP: '515',
            CBSAFP: '38500',
            AFFGEOID: '310M600US38500',
            GEOID: '38500',
            NAME: 'Plymouth, IN',
            NAMELSAD: 'Plymouth, IN Micro Area',
            LSAD: 'M2',
            ALAND: 1148969991,
            AWATER: 15858580,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-100.665353, 32.082372, -100.146543, 32.525312],
            type: 'Polygon',
            coordinates: [
                [
                    [-100.660626, 32.525312],
                    [-100.146543, 32.522793],
                    [-100.151911, 32.082638],
                    [-100.235137, 32.082372],
                    [-100.665353, 32.085407],
                    [-100.660626, 32.525312],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '45020',
            AFFGEOID: '310M600US45020',
            GEOID: '45020',
            NAME: 'Sweetwater, TX',
            NAMELSAD: 'Sweetwater, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2362061742,
            AWATER: 5052159,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.697325, 37.10945, -85.073772, 37.482951],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.697325, 37.301838],
                    [-85.657332, 37.421996],
                    [-85.634193, 37.471785],
                    [-85.583497, 37.469917],
                    [-85.498842, 37.482951],
                    [-85.46625, 37.465595],
                    [-85.340446, 37.469512],
                    [-85.246759, 37.420205],
                    [-85.128685, 37.453191],
                    [-85.073772, 37.413669],
                    [-85.131162, 37.384265],
                    [-85.165174, 37.310396],
                    [-85.192542, 37.272139],
                    [-85.312291, 37.246405],
                    [-85.35277100000002, 37.192432000000004],
                    [-85.380258, 37.168314],
                    [-85.526881, 37.10945],
                    [-85.632313, 37.125056],
                    [-85.686482, 37.182427],
                    [-85.697325, 37.301838],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '15820',
            AFFGEOID: '310M600US15820',
            GEOID: '15820',
            NAME: 'Campbellsville, KY',
            NAMELSAD: 'Campbellsville, KY Micro Area',
            LSAD: 'M2',
            ALAND: 1430763088,
            AWATER: 34166527,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-109.060062, 37.89241, -107.50002, 38.668553],
            type: 'Polygon',
            coordinates: [
                [
                    [-109.059962, 38.499987],
                    [-108.379136, 38.499987],
                    [-108.378698, 38.668097],
                    [-107.500655, 38.668553],
                    [-107.50002, 38.301926],
                    [-107.63504, 38.301896],
                    [-107.580243, 38.230824],
                    [-107.56862, 38.147267],
                    [-107.51088, 38.060877],
                    [-107.568875, 37.965015],
                    [-107.641588, 37.965009],
                    [-107.695665, 37.89241],
                    [-107.738283, 37.905432],
                    [-107.795469, 37.98852],
                    [-107.889716, 38.011615],
                    [-107.891469, 38.115957],
                    [-107.965789, 38.152328],
                    [-109.04179661485502, 38.1530323049685],
                    [-109.041762, 38.16469],
                    [-109.060062, 38.275489],
                    [-109.059962, 38.499987],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '33940',
            AFFGEOID: '310M600US33940',
            GEOID: '33940',
            NAME: 'Montrose, CO',
            NAMELSAD: 'Montrose, CO Micro Area',
            LSAD: 'M2',
            ALAND: 7204356178,
            AWATER: 8909552,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-99.708987, 45.240415, -97.9787778096234, 45.93867473326151],
            type: 'Polygon',
            coordinates: [
                [
                    [-99.707705, 45.593391],
                    [-98.725002, 45.591252],
                    [-98.7243744138881, 45.93867473326151],
                    [-98.625379, 45.938228],
                    [-98.414518, 45.936504],
                    [-98.070515, 45.93618],
                    [-98.0081017982778, 45.9360126290493],
                    [-97.9787778096234, 45.9359339920987],
                    [-97.979592, 45.588483],
                    [-97.981457, 45.240415],
                    [-98.717759, 45.243732],
                    [-98.722481, 45.243764],
                    [-99.57333, 45.245364],
                    [-99.708987, 45.245866],
                    [-99.707705, 45.593391],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '10100',
            AFFGEOID: '310M600US10100',
            GEOID: '10100',
            NAME: 'Aberdeen, SD',
            NAMELSAD: 'Aberdeen, SD Micro Area',
            LSAD: 'M2',
            ALAND: 7353004128,
            AWATER: 111384253,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.856191, 40.03721, -92.345064, 40.5972572763761],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.855629, 40.342736],
                    [-92.684167, 40.343466],
                    [-92.70932, 40.445893],
                    [-92.683162, 40.560663],
                    [-92.714597263045, 40.5895828338363],
                    [-92.68669300000002, 40.589809],
                    [-92.6379032935995, 40.5909565470979],
                    [-92.453745, 40.595288],
                    [-92.3508041867264, 40.5972572763761],
                    [-92.349975, 40.346505],
                    [-92.347801, 40.302756],
                    [-92.345064, 40.03721],
                    [-92.847477, 40.037301],
                    [-92.856191, 40.037253],
                    [-92.855629, 40.342736],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '28860',
            AFFGEOID: '310M600US28860',
            GEOID: '28860',
            NAME: 'Kirksville, MO',
            NAMELSAD: 'Kirksville, MO Micro Area',
            LSAD: 'M2',
            ALAND: 2265278463,
            AWATER: 7684518,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.981334, 42.541537, -76.585989, 43.024035],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.981334, 42.861213],
                    [-76.963926, 43.013157],
                    [-76.713806, 43.024035],
                    [-76.73674, 42.970286],
                    [-76.731636, 42.72074],
                    [-76.666543, 42.623457],
                    [-76.585989, 42.54991],
                    [-76.696655, 42.54679],
                    [-76.895596, 42.541537],
                    [-76.895349, 42.656255],
                    [-76.947429, 42.76443],
                    [-76.971392, 42.764223],
                    [-76.981334, 42.861213],
                ],
            ],
        },
        properties: {
            CSAFP: '464',
            CBSAFP: '42900',
            AFFGEOID: '310M600US42900',
            GEOID: '42900',
            NAME: 'Seneca Falls, NY',
            NAMELSAD: 'Seneca Falls, NY Micro Area',
            LSAD: 'M2',
            ALAND: 838372770,
            AWATER: 172818530,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.465505, 42.862754, -77.904121, 43.133561],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.465505, 43.128619],
                    [-77.99729, 43.132981],
                    [-77.905934, 43.133561],
                    [-77.904121, 43.037056],
                    [-77.909832, 42.987762],
                    [-77.95553, 42.905861],
                    [-77.954964, 42.862754],
                    [-78.074171, 42.870188],
                    [-78.464381, 42.867461],
                    [-78.464449, 43.088703],
                    [-78.465505, 43.128619],
                ],
            ],
        },
        properties: {
            CSAFP: '464',
            CBSAFP: '12860',
            AFFGEOID: '310M600US12860',
            GEOID: '12860',
            NAME: 'Batavia, NY',
            NAMELSAD: 'Batavia, NY Micro Area',
            LSAD: 'M2',
            ALAND: 1276705561,
            AWATER: 6137411,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-103.581844, 40.436346, -102.65131000000001, 41.002253],
            type: 'Polygon',
            coordinates: [
                [
                    [-103.57377400619501, 41.0017217990841],
                    [-103.38249165189, 41.001926146621],
                    [-103.076536, 41.002253],
                    [-102.653464137269, 41.0022253241436],
                    [-102.65131000000001, 40.749402],
                    [-102.664689, 40.705189],
                    [-102.664804, 40.438473],
                    [-102.77996, 40.438446],
                    [-103.465377, 40.436346],
                    [-103.4672, 40.523253],
                    [-103.581844, 40.523383],
                    [-103.57377400619501, 41.0017217990841],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '44540',
            AFFGEOID: '310M600US44540',
            GEOID: '44540',
            NAME: 'Sterling, CO',
            NAMELSAD: 'Sterling, CO Micro Area',
            LSAD: 'M2',
            ALAND: 4761947975,
            AWATER: 16363553,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.318133, 38.726171, -85.795357, 39.068536],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.318133, 39.04881],
                    [-86.080272, 39.05027],
                    [-85.860573, 39.04091],
                    [-85.798804, 39.068536],
                    [-85.795357, 38.807508],
                    [-85.804554, 38.779906],
                    [-85.869559, 38.726171],
                    [-85.888334, 38.734443],
                    [-85.909677, 38.757112],
                    [-86.091705, 38.783399],
                    [-86.275281, 38.763795],
                    [-86.280389, 38.991028],
                    [-86.31747400000002, 38.99087],
                    [-86.318133, 39.04881],
                ],
            ],
        },
        properties: {
            CSAFP: '294',
            CBSAFP: '42980',
            AFFGEOID: '310M600US42980',
            GEOID: '42980',
            NAME: 'Seymour, IN',
            NAMELSAD: 'Seymour, IN Micro Area',
            LSAD: 'M2',
            ALAND: 1320836469,
            AWATER: 10174044,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-74.763614, 42.982934, -74.097467, 43.286143],
            type: 'Polygon',
            coordinates: [
                [
                    [-74.763614, 43.05796200000001],
                    [-74.695985, 43.174177],
                    [-74.712615, 43.286143],
                    [-74.534657, 43.228115],
                    [-74.326378, 43.241635],
                    [-74.220902, 43.221403],
                    [-74.140147, 43.253979],
                    [-74.097467, 42.982934],
                    [-74.488844, 42.985118],
                    [-74.542367, 42.985530000000004],
                    [-74.759895, 43.047423],
                    [-74.763614, 43.05796200000001],
                ],
            ],
        },
        properties: {
            CSAFP: '104',
            CBSAFP: '24100',
            AFFGEOID: '310M600US24100',
            GEOID: '24100',
            NAME: 'Gloversville, NY',
            NAMELSAD: 'Gloversville, NY Micro Area',
            LSAD: 'M2',
            ALAND: 1283234687,
            AWATER: 96916403,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.1590843059475, 42.507147, -90.42637696073612, 43.211455],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.1590843059475, 42.9874777913228],
                    [-91.06221100000002, 42.992552],
                    [-90.940087, 43.061882],
                    [-90.845746, 43.077625],
                    [-90.666786, 43.171777],
                    [-90.545408, 43.211455],
                    [-90.429825, 43.20094],
                    [-90.426902, 42.812862],
                    [-90.42637696073612, 42.507177735223706],
                    [-90.437011, 42.507147],
                    [-90.642843, 42.50848100000001],
                    [-90.672727, 42.576599],
                    [-90.700856, 42.626445],
                    [-90.743677, 42.64556000000001],
                    [-90.852497, 42.664822],
                    [-90.8969620271584, 42.6743180564343],
                    [-90.941567, 42.683844],
                    [-91.01723900000002, 42.719566],
                    [-91.070716, 42.775502],
                    [-91.09882000000002, 42.864421],
                    [-91.138, 42.903772],
                    [-91.155519, 42.975774],
                    [-91.1590843059475, 42.9874777913228],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '38420',
            AFFGEOID: '310M600US38420',
            GEOID: '38420',
            NAME: 'Platteville, WI',
            NAMELSAD: 'Platteville, WI Micro Area',
            LSAD: 'M2',
            ALAND: 2970402286,
            AWATER: 94521527,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-77.36418, 40.807334, -76.800242, 41.148801],
            type: 'Polygon',
            coordinates: [
                [
                    [-77.36418, 40.846937],
                    [-77.279236, 40.90971],
                    [-77.14416, 41.044338],
                    [-77.144111, 41.06884],
                    [-76.97793900000002, 41.087883],
                    [-76.960229, 41.148801],
                    [-76.896114, 41.13907],
                    [-76.856424, 41.047412],
                    [-76.873964, 40.946752],
                    [-76.800242, 40.881994],
                    [-76.941788, 40.88806],
                    [-77.356628, 40.807334],
                    [-77.36418, 40.846937],
                ],
            ],
        },
        properties: {
            CSAFP: '146',
            CBSAFP: '30260',
            AFFGEOID: '310M600US30260',
            GEOID: '30260',
            NAME: 'Lewisburg, PA',
            NAMELSAD: 'Lewisburg, PA Micro Area',
            LSAD: 'M2',
            ALAND: 818313044,
            AWATER: 4622034,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.511224, 36.164309, -78.277165, 36.5424213915586],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.497783, 36.514477],
                    [-78.4572778962559, 36.5414487083995],
                    [-78.323718509559, 36.5424213915586],
                    [-78.277165, 36.306132],
                    [-78.306965, 36.266191],
                    [-78.37814, 36.235065],
                    [-78.42188, 36.164309],
                    [-78.496614, 36.175199],
                    [-78.511224, 36.454808],
                    [-78.497783, 36.514477],
                ],
            ],
        },
        properties: {
            CSAFP: '450',
            CBSAFP: '25780',
            AFFGEOID: '310M600US25780',
            GEOID: '25780',
            NAME: 'Henderson, NC',
            NAMELSAD: 'Henderson, NC Micro Area',
            LSAD: 'M2',
            ALAND: 653701481,
            AWATER: 42190675,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.924795, 38.173673, -97.37175, 38.60988],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.924795, 38.60988],
                    [-97.371911, 38.609353],
                    [-97.37175, 38.173673],
                    [-97.701841, 38.173814],
                    [-97.922136, 38.173713],
                    [-97.924269, 38.522755],
                    [-97.924795, 38.60988],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '32700',
            AFFGEOID: '310M600US32700',
            GEOID: '32700',
            NAME: 'McPherson, KS',
            NAMELSAD: 'McPherson, KS Micro Area',
            LSAD: 'M2',
            ALAND: 2326547430,
            AWATER: 6003328,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.039674, 37.238328, -84.446719, 37.717249],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.030172, 37.63127],
                    [-85.026526, 37.664427],
                    [-85.025021, 37.678854],
                    [-84.793574, 37.717249],
                    [-84.74488800000002, 37.713075],
                    [-84.652294, 37.650892],
                    [-84.658296, 37.634501],
                    [-84.648559, 37.609897],
                    [-84.446719, 37.485893],
                    [-84.502932, 37.328801],
                    [-84.556461, 37.355729],
                    [-84.72071200000002, 37.238328],
                    [-84.706402, 37.29954],
                    [-84.722826, 37.360043],
                    [-84.850816, 37.427143],
                    [-84.847434, 37.548343],
                    [-84.8981, 37.532113],
                    [-85.039674, 37.545232],
                    [-85.030172, 37.63127],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '19220',
            AFFGEOID: '310M600US19220',
            GEOID: '19220',
            NAME: 'Danville, KY',
            NAMELSAD: 'Danville, KY Micro Area',
            LSAD: 'M2',
            ALAND: 1329164597,
            AWATER: 15642809,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.088811, 43.466006, -84.60603700000001, 43.815365],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.088811, 43.813676],
                    [-84.60603700000001, 43.815365],
                    [-84.60754, 43.466006],
                    [-84.845962, 43.466158],
                    [-85.084996, 43.46619],
                    [-85.088811, 43.813676],
                ],
            ],
        },
        properties: {
            CSAFP: '394',
            CBSAFP: '34380',
            AFFGEOID: '310M600US34380',
            GEOID: '34380',
            NAME: 'Mount Pleasant, MI',
            NAMELSAD: 'Mount Pleasant, MI Micro Area',
            LSAD: 'M2',
            ALAND: 1483285544,
            AWATER: 12945211,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.009139, 43.194697000000005, -88.400426, 43.63354],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.009139, 43.28483],
                    [-89.006117, 43.458759],
                    [-89.006848, 43.633045],
                    [-88.886052, 43.63354],
                    [-88.401041, 43.630933],
                    [-88.400426, 43.543532],
                    [-88.418439, 43.370175],
                    [-88.417987, 43.194697000000005],
                    [-88.535843, 43.196048],
                    [-89.008832, 43.197724],
                    [-89.009139, 43.28483],
                ],
            ],
        },
        properties: {
            CSAFP: '376',
            CBSAFP: '13180',
            AFFGEOID: '310M600US13180',
            GEOID: '13180',
            NAME: 'Beaver Dam, WI',
            NAMELSAD: 'Beaver Dam, WI Micro Area',
            LSAD: 'M2',
            ALAND: 2268049732,
            AWATER: 81331401,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-114.052472, 37.474474, -112.468177, 38.1487630266706],
            type: 'Polygon',
            coordinates: [
                [
                    [-114.051728, 37.745997],
                    [-114.04965800000001, 37.881368],
                    [-114.049903, 38.148601],
                    [-114.049903375658, 38.1487630266706],
                    [-113.471336, 38.148665],
                    [-112.47868, 38.147419],
                    [-112.468177, 37.890464],
                    [-112.588402, 37.890423],
                    [-112.57855, 37.80454],
                    [-112.68922100000002, 37.80559],
                    [-112.68375, 37.543692],
                    [-112.901162, 37.543486],
                    [-112.90099300000001, 37.500021],
                    [-113.037088, 37.499748],
                    [-113.037048, 37.474474],
                    [-113.25349, 37.482167],
                    [-113.25334, 37.528983],
                    [-113.474029, 37.529085],
                    [-113.474485, 37.618289],
                    [-113.587797, 37.604611],
                    [-114.052472, 37.604776],
                    [-114.051728, 37.745997],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '16260',
            AFFGEOID: '310M600US16260',
            GEOID: '16260',
            NAME: 'Cedar City, UT',
            NAMELSAD: 'Cedar City, UT Micro Area',
            LSAD: 'M2',
            ALAND: 8537474384,
            AWATER: 11390956,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.226416, 34.580211, -81.714094, 35.1932531051427],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.215996376597, 35.1932531051427],
                    [-82.0483912296479, 35.1896375433755],
                    [-82.039651, 35.189449],
                    [-81.9693584012576, 35.1869270884005],
                    [-81.8744102050921, 35.183520599502],
                    [-81.806252, 35.055505],
                    [-81.755098, 34.930323],
                    [-81.714094, 34.912865],
                    [-81.782791, 34.837211],
                    [-81.851865, 34.594775],
                    [-81.863039, 34.580211],
                    [-82.004644, 34.662349],
                    [-82.145587, 34.785208000000004],
                    [-82.165175, 34.796537],
                    [-82.226416, 34.850439],
                    [-82.215996376597, 35.1932531051427],
                ],
            ],
        },
        properties: {
            CSAFP: '273',
            CBSAFP: '43900',
            AFFGEOID: '310M600US43900',
            GEOID: '43900',
            NAME: 'Spartanburg, SC',
            NAMELSAD: 'Spartanburg, SC Metro Area',
            LSAD: 'M1',
            ALAND: 2093687933,
            AWATER: 29513744,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-117.041634, 47.366028886698, -116.329406, 47.991386],
            type: 'Polygon',
            coordinates: [
                [
                    [-117.04130644645902, 47.9774559200268],
                    [-116.63411400000001, 47.977073],
                    [-116.504329, 47.991386],
                    [-116.505104, 47.890142],
                    [-116.329538, 47.890393],
                    [-116.329406, 47.414011],
                    [-116.58654900000002, 47.414695],
                    [-116.629059, 47.371438],
                    [-117.04049037556798, 47.366028886698],
                    [-117.041634, 47.7353],
                    [-117.04130644645902, 47.9774559200268],
                ],
            ],
        },
        properties: {
            CSAFP: '518',
            CBSAFP: '17660',
            AFFGEOID: '310M600US17660',
            GEOID: '17660',
            NAME: "Coeur d'Alene, ID",
            NAMELSAD: "Coeur d'Alene, ID Metro Area",
            LSAD: 'M1',
            ALAND: 3205837532,
            AWATER: 184443859,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.9184444729048, 36.111511, -81.455792, 36.3897385587113],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.908137, 36.302013],
                    [-81.833202, 36.347339],
                    [-81.768977, 36.341042],
                    [-81.705966, 36.338496],
                    [-81.72537280289171, 36.3897385587113],
                    [-81.638186, 36.349606],
                    [-81.566349, 36.272202],
                    [-81.477516, 36.24025],
                    [-81.455792, 36.200441],
                    [-81.545488, 36.117475],
                    [-81.674833, 36.122619],
                    [-81.810516, 36.111511],
                    [-81.82923, 36.168791],
                    [-81.907698, 36.214163],
                    [-81.9184444729048, 36.2873579173821],
                    [-81.908137, 36.302013],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '14380',
            AFFGEOID: '310M600US14380',
            GEOID: '14380',
            NAME: 'Boone, NC',
            NAMELSAD: 'Boone, NC Micro Area',
            LSAD: 'M2',
            ALAND: 809214848,
            AWATER: 2271720,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.142148, 34.289183, -97.562323, 34.68134],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.141799, 34.681158],
                    [-98.089097, 34.681163],
                    [-97.668512, 34.68134],
                    [-97.563004, 34.681185000000006],
                    [-97.562323, 34.507036],
                    [-97.562512, 34.289183],
                    [-98.139227, 34.289824],
                    [-98.139293, 34.489279],
                    [-98.142148, 34.506994],
                    [-98.141799, 34.681158],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '20340',
            AFFGEOID: '310M600US20340',
            GEOID: '20340',
            NAME: 'Duncan, OK',
            NAMELSAD: 'Duncan, OK Micro Area',
            LSAD: 'M2',
            ALAND: 2253830200,
            AWATER: 54833890,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.09295, 44.195530000000005, -95.59357, 44.630486],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.09295, 44.630486],
                    [-95.604001, 44.629945],
                    [-95.594817, 44.542305],
                    [-95.59357, 44.195530000000005],
                    [-96.06377800000001, 44.196425],
                    [-96.07858, 44.19662],
                    [-96.08041000000001, 44.54313],
                    [-96.09295, 44.630486],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '32140',
            AFFGEOID: '310M600US32140',
            GEOID: '32140',
            NAME: 'Marshall, MN',
            NAMELSAD: 'Marshall, MN Micro Area',
            LSAD: 'M2',
            ALAND: 1850315109,
            AWATER: 18794794,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.066842, 35.505661, -79.5136476625086, 36.54249538561421],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.046869, 35.920693],
                    [-80.043238, 36.010758],
                    [-80.038554, 36.146463],
                    [-80.035119, 36.257184],
                    [-80.0273389151412, 36.54249538561421],
                    [-80.027269, 36.542495],
                    [-79.8916701669539, 36.5420338366162],
                    [-79.714850811052, 36.5414324847413],
                    [-79.5136476625086, 36.5407482050707],
                    [-79.531865, 36.249673],
                    [-79.53241, 36.241463],
                    [-79.541728, 35.899851],
                    [-79.542428, 35.843303],
                    [-79.555804, 35.515039],
                    [-79.767957, 35.511484],
                    [-80.066842, 35.505661],
                    [-80.046869, 35.920693],
                ],
            ],
        },
        properties: {
            CSAFP: '268',
            CBSAFP: '24660',
            AFFGEOID: '310M600US24660',
            GEOID: '24660',
            NAME: 'Greensboro-High Point, NC',
            NAMELSAD: 'Greensboro-High Point, NC Metro Area',
            LSAD: 'M1',
            ALAND: 5164175266,
            AWATER: 68531353,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.806375, 40.723443, -78.038203, 41.253778],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.805167, 40.90598],
                    [-78.804532, 41.132186],
                    [-78.766377, 41.202759],
                    [-78.710248, 41.201859],
                    [-78.64666600000001, 41.253778],
                    [-78.236817, 41.230475],
                    [-78.093381, 41.216931],
                    [-78.061007, 41.174539],
                    [-78.038203, 41.153633],
                    [-78.112239, 41.114217],
                    [-78.056276, 41.00417],
                    [-78.07774, 40.958824],
                    [-78.168128, 40.960865],
                    [-78.24922, 40.855383],
                    [-78.288441, 40.849747],
                    [-78.376518, 40.754417],
                    [-78.359912, 40.732592],
                    [-78.348668, 40.723443],
                    [-78.402899, 40.725239],
                    [-78.801726, 40.724618],
                    [-78.806375, 40.74316],
                    [-78.805167, 40.90598],
                ],
            ],
        },
        properties: {
            CSAFP: '524',
            CBSAFP: '20180',
            AFFGEOID: '310M600US20180',
            GEOID: '20180',
            NAME: 'DuBois, PA',
            NAMELSAD: 'DuBois, PA Micro Area',
            LSAD: 'M2',
            ALAND: 2966379119,
            AWATER: 23934013,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-99.426551, 40.350391, -98.72143, 41.047003],
            type: 'Polygon',
            coordinates: [
                [
                    [-99.425986, 41.046471],
                    [-99.207564, 41.047003],
                    [-98.748526, 41.046640000000004],
                    [-98.721975, 41.046674],
                    [-98.72143, 40.698903],
                    [-98.724387, 40.689772],
                    [-98.723948, 40.350391],
                    [-98.726827, 40.350398],
                    [-99.179403, 40.350681],
                    [-99.179248, 40.660541],
                    [-99.380544, 40.66486],
                    [-99.417316, 40.670825],
                    [-99.426551, 40.69977],
                    [-99.425986, 41.046471],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '28260',
            AFFGEOID: '310M600US28260',
            GEOID: '28260',
            NAME: 'Kearney, NE',
            NAMELSAD: 'Kearney, NE Micro Area',
            LSAD: 'M2',
            ALAND: 3844742661,
            AWATER: 18867967,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.952726, 39.7572, -90.911969, 40.25721],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.947241, 40.213799],
                    [-91.950812, 40.25721],
                    [-91.4969574903395, 40.2487038763728],
                    [-91.5062615151283, 40.2001550699717],
                    [-90.911969, 40.193088],
                    [-90.913469, 40.104455],
                    [-90.916669, 39.844927],
                    [-90.915961, 39.7572],
                    [-91.3646166033233, 39.7587182263223],
                    [-91.361571, 39.787548],
                    [-91.397853, 39.821122],
                    [-91.436051, 39.84551],
                    [-91.428956, 39.90772900000001],
                    [-91.4368432685991, 39.9452434636785],
                    [-91.840353, 39.948235],
                    [-91.952726, 39.949426],
                    [-91.947241, 40.213799],
                ],
            ],
        },
        properties: {
            CSAFP: '448',
            CBSAFP: '39500',
            AFFGEOID: '310M600US39500',
            GEOID: '39500',
            NAME: 'Quincy, IL-MO',
            NAMELSAD: 'Quincy, IL-MO Micro Area',
            LSAD: 'M2',
            ALAND: 3522814806,
            AWATER: 56719924,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.00117100000001, 36.42358, -95.7867618707782, 36.999270917592],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.0008102061996, 36.9992010529279],
                    [-95.9642712321022, 36.9992231442824],
                    [-95.928122, 36.99924500000001],
                    [-95.7867618707782, 36.999270917592],
                    [-95.80982, 36.941930000000006],
                    [-95.80954, 36.59754],
                    [-95.81234, 36.576134],
                    [-95.812342, 36.42358],
                    [-96.00117100000001, 36.423686],
                    [-96.0008102061996, 36.9992010529279],
                ],
            ],
        },
        properties: {
            CSAFP: '538',
            CBSAFP: '12780',
            AFFGEOID: '310M600US12780',
            GEOID: '12780',
            NAME: 'Bartlesville, OK',
            NAMELSAD: 'Bartlesville, OK Micro Area',
            LSAD: 'M2',
            ALAND: 1076201529,
            AWATER: 22871165,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.7681344077739, 35.1622859155574, -81.321729, 35.580041],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.702853, 35.357304],
                    [-81.691989, 35.580041],
                    [-81.56725, 35.558201],
                    [-81.537599, 35.564228],
                    [-81.515009, 35.557782],
                    [-81.4556, 35.419811],
                    [-81.356682, 35.330604],
                    [-81.321729, 35.260449],
                    [-81.3280909892918, 35.1622859155574],
                    [-81.3676041320777, 35.1640921256134],
                    [-81.494265, 35.169882],
                    [-81.7681344077739, 35.1797077063839],
                    [-81.702853, 35.357304],
                ],
            ],
        },
        properties: {
            CSAFP: '172',
            CBSAFP: '43140',
            AFFGEOID: '310M600US43140',
            GEOID: '43140',
            NAME: 'Shelby, NC',
            NAMELSAD: 'Shelby, NC Micro Area',
            LSAD: 'M2',
            ALAND: 1202400603,
            AWATER: 10177696,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.523646, 35.724635, -92.8540081073941, 36.4982584490195],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.523646, 35.881514],
                    [-93.450788, 35.967497],
                    [-93.44574, 36.080787],
                    [-93.479521, 36.125054],
                    [-93.30082, 36.12155],
                    [-93.3023, 36.482437],
                    [-93.2930675065906, 36.4982584490195],
                    [-93.125969, 36.497851],
                    [-92.8540081073941, 36.4980234071379],
                    [-92.884495, 36.490742],
                    [-92.890725, 36.114245],
                    [-92.94463, 36.115253],
                    [-92.938469, 36.071665],
                    [-92.94083500000002, 35.990045],
                    [-92.951373, 35.724635],
                    [-93.164451, 35.728873],
                    [-93.519643, 35.734464],
                    [-93.519185, 35.763487],
                    [-93.523646, 35.881514],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '25460',
            AFFGEOID: '310M600US25460',
            GEOID: '25460',
            NAME: 'Harrison, AR',
            NAMELSAD: 'Harrison, AR Micro Area',
            LSAD: 'M2',
            ALAND: 3653941625,
            AWATER: 37733937,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-124.041983, 41.995137, -123.229545, 42.784061],
            type: 'Polygon',
            coordinates: [
                [
                    [-124.00839699999999, 42.49635200000001],
                    [-123.890657, 42.500538],
                    [-123.83126400000002, 42.632313],
                    [-123.71649200000002, 42.784061],
                    [-123.71650500000001, 42.739658],
                    [-123.581699, 42.73992],
                    [-123.44545600000001, 42.703025],
                    [-123.27428300000001, 42.73203],
                    [-123.22961900000001, 42.70261],
                    [-123.229545, 42.495623],
                    [-123.22990100000001, 42.290816],
                    [-123.231001260578, 42.004970062256],
                    [-123.347562, 41.999108],
                    [-123.43477000000001, 42.001641],
                    [-123.51911283680398, 41.9991725180329],
                    [-123.656998, 41.995137],
                    [-123.822037598588, 41.99562081694701],
                    [-123.82213900000002, 42.087522],
                    [-123.858549, 42.135116],
                    [-123.79220000000001, 42.237623],
                    [-123.888411, 42.351627],
                    [-124.041983, 42.387656],
                    [-124.00839699999999, 42.49635200000001],
                ],
            ],
        },
        properties: {
            CSAFP: '366',
            CBSAFP: '24420',
            AFFGEOID: '310M600US24420',
            GEOID: '24420',
            NAME: 'Grants Pass, OR',
            NAMELSAD: 'Grants Pass, OR Metro Area',
            LSAD: 'M1',
            ALAND: 4244080762,
            AWATER: 5631773,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.480176, 31.654203, -82.995698, 31.849495000000005],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.480176, 31.847312],
                    [-83.190034, 31.849495000000005],
                    [-83.177469, 31.847857000000005],
                    [-83.138659, 31.814446000000004],
                    [-82.995698, 31.780983000000003],
                    [-82.99836, 31.673164],
                    [-83.176376, 31.678381999999996],
                    [-83.266363, 31.654203],
                    [-83.325617, 31.680603],
                    [-83.325296, 31.756584],
                    [-83.453634, 31.757861],
                    [-83.45887, 31.793612000000003],
                    [-83.480176, 31.847312],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '22340',
            AFFGEOID: '310M600US22340',
            GEOID: '22340',
            NAME: 'Fitzgerald, GA',
            NAMELSAD: 'Fitzgerald, GA Micro Area',
            LSAD: 'M2',
            ALAND: 647802123,
            AWATER: 9814374,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.0989706961667, 39.720229, -77.454109, 40.289825],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.0989706961667, 39.7224661944281],
                    [-78.0027, 39.826599],
                    [-77.920952, 40.00045],
                    [-77.864489, 40.061568],
                    [-77.737933, 40.237079],
                    [-77.70303, 40.263576],
                    [-77.671761, 40.289825],
                    [-77.602717, 40.22820000000001],
                    [-77.614665, 40.198549],
                    [-77.532481, 40.048749],
                    [-77.454109, 39.972432],
                    [-77.471085, 39.944077],
                    [-77.4591384979573, 39.7202291767892],
                    [-77.4691450036804, 39.72022900225],
                    [-77.469274, 39.720229],
                    [-77.768644, 39.721538],
                    [-78.075861, 39.722448],
                    [-78.0989706961667, 39.7224661944281],
                ],
            ],
        },
        properties: {
            CSAFP: '548',
            CBSAFP: '16540',
            AFFGEOID: '310M600US16540',
            GEOID: '16540',
            NAME: 'Chambersburg-Waynesboro, PA',
            NAMELSAD: 'Chambersburg-Waynesboro, PA Metro Area',
            LSAD: 'M1',
            ALAND: 2000245527,
            AWATER: 1547615,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.221118, 39.71476400000001, -84.8101612384745, 40.0050675438482],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.200945, 39.873799],
                    [-85.201473, 40.004521000000004],
                    [-84.8101612384745, 40.0050675438482],
                    [-84.8114171063805, 39.9169136295798],
                    [-84.8141281379328, 39.7266165116193],
                    [-85.022528, 39.729179],
                    [-85.034575, 39.71476400000001],
                    [-85.185089, 39.715515],
                    [-85.221118, 39.788449],
                    [-85.200945, 39.873799],
                ],
            ],
        },
        properties: {
            CSAFP: '458',
            CBSAFP: '39980',
            AFFGEOID: '310M600US39980',
            GEOID: '39980',
            NAME: 'Richmond, IN',
            NAMELSAD: 'Richmond, IN Micro Area',
            LSAD: 'M2',
            ALAND: 1040539270,
            AWATER: 6701974,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.686574, 40.917082, -84.8026702061534, 41.294776],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.686574, 41.178376],
                    [-85.652715, 41.178489],
                    [-85.65192800000001, 41.294776],
                    [-85.53718, 41.266157],
                    [-85.307781, 41.264158],
                    [-85.192094, 41.264209],
                    [-84.8037035726169, 41.2712583305706],
                    [-84.8036449822556, 41.2525616835482],
                    [-84.803234, 41.12141400000001],
                    [-84.8028596219514, 40.9893740520086],
                    [-84.8026702061534, 40.922568713921],
                    [-85.073861, 40.917823],
                    [-85.33603, 40.917082],
                    [-85.335643, 41.00525],
                    [-85.643841, 41.002305],
                    [-85.684181, 41.046716],
                    [-85.686574, 41.178376],
                ],
            ],
        },
        properties: {
            CSAFP: '258',
            CBSAFP: '23060',
            AFFGEOID: '310M600US23060',
            GEOID: '23060',
            NAME: 'Fort Wayne, IN',
            NAMELSAD: 'Fort Wayne, IN Metro Area',
            LSAD: 'M1',
            ALAND: 2571518283,
            AWATER: 13130160,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.179974, 40.89972, -91.716042, 41.163578],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.179974, 41.162662000000005],
                    [-91.945571, 41.163578],
                    [-91.716042, 41.162808],
                    [-91.71818000000002, 40.901087],
                    [-92.179072, 40.89972],
                    [-92.179974, 41.162662000000005],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '21840',
            AFFGEOID: '310M600US21840',
            GEOID: '21840',
            NAME: 'Fairfield, IA',
            NAMELSAD: 'Fairfield, IA Micro Area',
            LSAD: 'M2',
            ALAND: 1128002393,
            AWATER: 3522238,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.129334, 40.667774, -81.64768900000001, 40.991807],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.129334, 40.991807],
                    [-81.977381, 40.989961],
                    [-81.688491, 40.98859],
                    [-81.64768900000001, 40.988563],
                    [-81.647735, 40.914016],
                    [-81.647991, 40.825776],
                    [-81.650026, 40.667774],
                    [-82.126196, 40.668357],
                    [-82.129334, 40.991807],
                ],
            ],
        },
        properties: {
            CSAFP: '184',
            CBSAFP: '49300',
            AFFGEOID: '310M600US49300',
            GEOID: '49300',
            NAME: 'Wooster, OH',
            NAMELSAD: 'Wooster, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1436897055,
            AWATER: 5040384,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-70.948431, 41.251014, -70.448262, 41.5432369319176],
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [-70.833802, 41.353386],
                        [-70.757797, 41.365702],
                        [-70.686881, 41.441334],
                        [-70.603555, 41.482384],
                        [-70.553277, 41.452955],
                        [-70.496197, 41.424908],
                        [-70.463833, 41.419145],
                        [-70.448262, 41.353651],
                        [-70.577454, 41.349163],
                        [-70.693635, 41.342833],
                        [-70.768687, 41.303702],
                        [-70.821284, 41.251014],
                        [-70.833397, 41.316778],
                        [-70.833802, 41.353386],
                    ],
                ],
                [
                    [
                        [-70.934986, 41.454699],
                        [-70.806861, 41.497583],
                        [-70.726088944807, 41.5432369319176],
                        [-70.697498, 41.527158],
                        [-70.6690583666005, 41.5129296626746],
                        [-70.734306, 41.486335],
                        [-70.79027, 41.446339],
                        [-70.857528, 41.425767],
                        [-70.948431, 41.409193],
                        [-70.934986, 41.454699],
                    ],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '47240',
            AFFGEOID: '310M600US47240',
            GEOID: '47240',
            NAME: 'Vineyard Haven, MA',
            NAMELSAD: 'Vineyard Haven, MA Micro Area',
            LSAD: 'M2',
            ALAND: 267292993,
            AWATER: 1004291414,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.767957, 35.043639, -79.095808, 35.518002],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.767957, 35.511484],
                    [-79.555804, 35.515039],
                    [-79.350073, 35.518002],
                    [-79.359765, 35.469675],
                    [-79.273565, 35.353369],
                    [-79.183556, 35.307161],
                    [-79.223021, 35.268133],
                    [-79.095808, 35.192068],
                    [-79.097551, 35.174233],
                    [-79.24389, 35.213666],
                    [-79.33547700000001, 35.161588],
                    [-79.458751, 35.043639],
                    [-79.575931, 35.069904],
                    [-79.614304, 35.163679],
                    [-79.70268, 35.255907],
                    [-79.767957, 35.511484],
                ],
            ],
        },
        properties: {
            CSAFP: '246',
            CBSAFP: '38240',
            AFFGEOID: '310M600US38240',
            GEOID: '38240',
            NAME: 'Pinehurst-Southern Pines, NC',
            NAMELSAD: 'Pinehurst-Southern Pines, NC Micro Area',
            LSAD: 'M2',
            ALAND: 1806983006,
            AWATER: 20712954,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.83049300000002, 41.333119, -97.252425, 41.743207000000005],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.83049300000002, 41.742238],
                    [-97.36807, 41.743207000000005],
                    [-97.252425, 41.743085],
                    [-97.253519, 41.384212],
                    [-97.287761, 41.400638],
                    [-97.36818600000001, 41.396416],
                    [-97.52241200000002, 41.361934],
                    [-97.598253, 41.333119],
                    [-97.598461, 41.395068],
                    [-97.703765, 41.394876],
                    [-97.703627, 41.526796],
                    [-97.829846, 41.526174],
                    [-97.83049300000002, 41.742238],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '18100',
            AFFGEOID: '310M600US18100',
            GEOID: '18100',
            NAME: 'Columbus, NE',
            NAMELSAD: 'Columbus, NE Micro Area',
            LSAD: 'M2',
            ALAND: 1745780718,
            AWATER: 27297317,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.0625630290106, 41.435812, -85.653507, 41.759653033158],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.0625630290106, 41.759653033158],
                    [-85.791363, 41.759051],
                    [-85.79133496196282, 41.7590510395575],
                    [-85.65975023799092, 41.759236686047],
                    [-85.654747, 41.523347],
                    [-85.653507, 41.436856],
                    [-86.05923, 41.435812],
                    [-86.059451, 41.479154],
                    [-86.0625630290106, 41.759653033158],
                ],
            ],
        },
        properties: {
            CSAFP: '515',
            CBSAFP: '21140',
            AFFGEOID: '310M600US21140',
            GEOID: '21140',
            NAME: 'Elkhart-Goshen, IN',
            NAMELSAD: 'Elkhart-Goshen, IN Metro Area',
            LSAD: 'M1',
            ALAND: 1199580231,
            AWATER: 12457801,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.49425400000001, 44.543126, -96.882345, 45.15182600000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.49425400000001, 45.151631],
                    [-97.226281, 45.15182600000001],
                    [-96.883948, 45.150224],
                    [-96.882345, 44.97687],
                    [-96.88457, 44.804436],
                    [-96.885504, 44.543773],
                    [-97.128024, 44.543126],
                    [-97.492266, 44.543884],
                    [-97.491346, 44.804035],
                    [-97.49425400000001, 45.151631],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '47980',
            AFFGEOID: '310M600US47980',
            GEOID: '47980',
            NAME: 'Watertown, SD',
            NAMELSAD: 'Watertown, SD Micro Area',
            LSAD: 'M2',
            ALAND: 3094009195,
            AWATER: 157035299,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.821186, 44.161375, -84.850929, 44.512969],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.817802, 44.512969],
                    [-85.334, 44.512303],
                    [-84.850929, 44.511069],
                    [-84.851705, 44.161375],
                    [-85.087403, 44.164242],
                    [-85.334777, 44.165118],
                    [-85.564554, 44.164861],
                    [-85.821186, 44.164197],
                    [-85.817802, 44.512969],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '15620',
            AFFGEOID: '310M600US15620',
            GEOID: '15620',
            NAME: 'Cadillac, MI',
            NAMELSAD: 'Cadillac, MI Micro Area',
            LSAD: 'M2',
            ALAND: 2925942347,
            AWATER: 50770824,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.504985, 35.143081, -80.051225, 35.504151],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.49751, 35.201839],
                    [-80.295421, 35.50292],
                    [-80.183264, 35.504141],
                    [-80.182559, 35.504151],
                    [-80.108495, 35.459451],
                    [-80.051225, 35.371537],
                    [-80.098185, 35.255173],
                    [-80.075365, 35.143081],
                    [-80.112644, 35.19388200000001],
                    [-80.172819, 35.147847],
                    [-80.276829, 35.195722],
                    [-80.356982, 35.16494],
                    [-80.504985, 35.184998],
                    [-80.49751, 35.201839],
                ],
            ],
        },
        properties: {
            CSAFP: '172',
            CBSAFP: '10620',
            AFFGEOID: '310M600US10620',
            GEOID: '10620',
            NAME: 'Albemarle, NC',
            NAMELSAD: 'Albemarle, NC Micro Area',
            LSAD: 'M2',
            ALAND: 1023250043,
            AWATER: 25187035,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.654747, 41.264158, -85.192094, 41.526437],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.654747, 41.523347],
                    [-85.194084, 41.526437],
                    [-85.192094, 41.264209],
                    [-85.307781, 41.264158],
                    [-85.53718, 41.266157],
                    [-85.65192800000001, 41.294776],
                    [-85.653227, 41.351195],
                    [-85.653507, 41.436856],
                    [-85.654747, 41.523347],
                ],
            ],
        },
        properties: {
            CSAFP: '258',
            CBSAFP: '28340',
            AFFGEOID: '310M600US28340',
            GEOID: '28340',
            NAME: 'Kendallville, IN',
            NAMELSAD: 'Kendallville, IN Micro Area',
            LSAD: 'M2',
            ALAND: 1064085740,
            AWATER: 17060239,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.602979, 39.91699, -89.143451, 40.325344],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.602979, 40.320129],
                    [-89.26374, 40.325344],
                    [-89.26265, 40.280919],
                    [-89.148772, 40.28252],
                    [-89.148764, 40.282038],
                    [-89.144764, 40.048853],
                    [-89.143451, 39.917647],
                    [-89.217846, 39.91699],
                    [-89.404984, 39.918187],
                    [-89.483826, 39.976733],
                    [-89.578289, 39.976127],
                    [-89.579128, 40.092415],
                    [-89.601688, 40.122378],
                    [-89.602979, 40.320129],
                ],
            ],
        },
        properties: {
            CSAFP: '522',
            CBSAFP: '30660',
            AFFGEOID: '310M600US30660',
            GEOID: '30660',
            NAME: 'Lincoln, IL',
            NAMELSAD: 'Lincoln, IL Micro Area',
            LSAD: 'M2',
            ALAND: 1600748259,
            AWATER: 2262329,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.8634638443825, 30.688827197045903, -84.376612, 31.079504],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.8634638443825, 30.7114970146544],
                    [-84.78682, 30.801489000000004],
                    [-84.730855, 31.06919],
                    [-84.644678, 31.079504],
                    [-84.542653, 31.079029],
                    [-84.508078, 31.0784],
                    [-84.376612, 31.078882999999998],
                    [-84.38075418355251, 30.688827197045903],
                    [-84.474519, 30.692783],
                    [-84.812997, 30.70965],
                    [-84.8634638443825, 30.7114970146544],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '12460',
            AFFGEOID: '310M600US12460',
            GEOID: '12460',
            NAME: 'Bainbridge, GA',
            NAMELSAD: 'Bainbridge, GA Micro Area',
            LSAD: 'M2',
            ALAND: 1546842840,
            AWATER: 67154651,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.841475, 40.992944, -82.336496, 41.290023],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.841475, 41.290023],
                    [-82.342337, 41.284244],
                    [-82.340324, 41.211282],
                    [-82.34038, 41.20074100000001],
                    [-82.336496, 41.065761],
                    [-82.436916, 41.065379],
                    [-82.43285200000001, 40.992944],
                    [-82.724792, 40.995638],
                    [-82.829512, 40.996624],
                    [-82.83853800000001, 41.216999],
                    [-82.840087, 41.255337],
                    [-82.841475, 41.290023],
                ],
            ],
        },
        properties: {
            CSAFP: '184',
            CBSAFP: '35940',
            AFFGEOID: '310M600US35940',
            GEOID: '35940',
            NAME: 'Norwalk, OH',
            NAMELSAD: 'Norwalk, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1274903645,
            AWATER: 8628942,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.2623921171761, 39.237624, -78.313325, 39.723122],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.166497, 39.400888],
                    [-79.091329, 39.472407],
                    [-79.06744740807241, 39.4728086991282],
                    [-79.066892, 39.480623],
                    [-78.9284161298889, 39.7229985874409],
                    [-78.8082995296216, 39.7230709562769],
                    [-78.723578, 39.723122],
                    [-78.380477064564, 39.72270413884],
                    [-78.3428335185744, 39.7226582929265],
                    [-78.313325, 39.692474],
                    [-78.33278802149911, 39.6285275964988],
                    [-78.382959, 39.622246],
                    [-78.438179, 39.563524],
                    [-78.460951, 39.525987],
                    [-78.4682695950029, 39.5262242704717],
                    [-78.590654, 39.530192],
                    [-78.6550361715467, 39.5443822410837],
                    [-78.767447, 39.447238],
                    [-78.850906, 39.330866],
                    [-78.979898, 39.237624],
                    [-79.134456, 39.312501000000005],
                    [-79.2623921171761, 39.3262438284674],
                    [-79.166497, 39.400888],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '19060',
            AFFGEOID: '310M600US19060',
            GEOID: '19060',
            NAME: 'Cumberland, MD-WV',
            NAMELSAD: 'Cumberland, MD-WV Metro Area',
            LSAD: 'M1',
            ALAND: 1942699598,
            AWATER: 18177244,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.38703, 37.040557, -86.894067, 37.416042],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.38703, 37.273606],
                    [-87.295965, 37.391819],
                    [-87.210958, 37.38093],
                    [-87.099803, 37.416042],
                    [-87.136983, 37.335524],
                    [-87.07133, 37.370298],
                    [-86.985957, 37.316566],
                    [-86.98267, 37.26963200000001],
                    [-86.899268, 37.212313],
                    [-86.924273, 37.144623],
                    [-86.894067, 37.088349],
                    [-86.94139100000001, 37.068981],
                    [-86.976399, 37.073588],
                    [-87.053164, 37.061019],
                    [-87.250256, 37.040557],
                    [-87.259371, 37.072401],
                    [-87.334277, 37.13181800000001],
                    [-87.333741, 37.157186],
                    [-87.38703, 37.273606],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '16420',
            AFFGEOID: '310M600US16420',
            GEOID: '16420',
            NAME: 'Central City, KY',
            NAMELSAD: 'Central City, KY Micro Area',
            LSAD: 'M2',
            ALAND: 1210451479,
            AWATER: 31188648,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.255266, 44.89131, -94.758081, 45.412861],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.255266, 45.412738],
                    [-95.13173, 45.412402],
                    [-94.762934, 45.412861],
                    [-94.76308, 45.3261],
                    [-94.758081, 44.89209700000001],
                    [-95.24852, 44.89131],
                    [-95.246489, 45.15243100000001],
                    [-95.255196, 45.238712],
                    [-95.255266, 45.412738],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '48820',
            AFFGEOID: '310M600US48820',
            GEOID: '48820',
            NAME: 'Willmar, MN',
            NAMELSAD: 'Willmar, MN Micro Area',
            LSAD: 'M2',
            ALAND: 2065191962,
            AWATER: 168286642,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.40818, 34.149645, -92.668654, 34.506132],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.407203, 34.397447],
                    [-92.948085, 34.389172],
                    [-92.915064, 34.506132],
                    [-92.79318900000001, 34.503573],
                    [-92.685066, 34.473102],
                    [-92.668654, 34.414504],
                    [-92.678382, 34.149645],
                    [-92.886136, 34.155699],
                    [-93.038473, 34.15515],
                    [-93.034967, 34.236605],
                    [-93.19828, 34.277301],
                    [-93.356877, 34.280308],
                    [-93.40818, 34.339467],
                    [-93.407203, 34.397447],
                ],
            ],
        },
        properties: {
            CSAFP: '284',
            CBSAFP: '31680',
            AFFGEOID: '310M600US31680',
            GEOID: '31680',
            NAME: 'Malvern, AR',
            NAMELSAD: 'Malvern, AR Micro Area',
            LSAD: 'M2',
            ALAND: 1592697317,
            AWATER: 18725599,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.709985, 34.743393, -92.809745, 35.728873],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.704962, 35.019716],
                    [-93.706843, 35.134886],
                    [-93.529736, 35.145502],
                    [-93.456977, 35.215908],
                    [-93.279929, 35.212556000000006],
                    [-93.278215, 35.312436],
                    [-93.298903, 35.327125],
                    [-93.295873, 35.470049],
                    [-93.22368, 35.468635],
                    [-93.221295, 35.599058],
                    [-93.167825, 35.597822],
                    [-93.164451, 35.728873],
                    [-92.951373, 35.724635],
                    [-92.809745, 35.722417],
                    [-92.813462, 35.548917],
                    [-92.850951, 35.462959],
                    [-92.85509600000002, 35.173839],
                    [-92.900911, 35.169993],
                    [-93.040598, 35.108791],
                    [-93.040561, 35.07688],
                    [-93.305682, 34.875463],
                    [-93.287785, 34.773242],
                    [-93.394487, 34.77242],
                    [-93.394402, 34.743393],
                    [-93.709985, 34.745978],
                    [-93.704962, 35.019716],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '40780',
            AFFGEOID: '310M600US40780',
            GEOID: '40780',
            NAME: 'Russellville, AR',
            NAMELSAD: 'Russellville, AR Micro Area',
            LSAD: 'M2',
            ALAND: 4510045167,
            AWATER: 97766346,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.93139, 40.61655, -88.23495, 41.114218],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.930881, 41.1059],
                    [-88.58624, 41.108293],
                    [-88.251501, 41.114218],
                    [-88.2473, 40.99456],
                    [-88.23495, 40.618208],
                    [-88.459475, 40.617345],
                    [-88.574502, 40.61655],
                    [-88.584272, 40.757608],
                    [-88.929331, 40.753337],
                    [-88.93139, 40.92774],
                    [-88.930881, 41.1059],
                ],
            ],
        },
        properties: {
            CSAFP: '145',
            CBSAFP: '38700',
            AFFGEOID: '310M600US38700',
            GEOID: '38700',
            NAME: 'Pontiac, IL',
            NAMELSAD: 'Pontiac, IL Micro Area',
            LSAD: 'M2',
            ALAND: 2702983878,
            AWATER: 5975815,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.4014168110406, 42.150417, -88.705585, 42.5004419310737],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.4014168110406, 42.5004419310737],
                    [-89.3657986076635, 42.5000259669515],
                    [-89.042898, 42.496255],
                    [-88.9925602268173, 42.4958547198992],
                    [-88.94038486037802, 42.4954398274712],
                    [-88.7765924457953, 42.4941373692957],
                    [-88.7074208350474, 42.4935873247155],
                    [-88.705585, 42.153537],
                    [-88.939732, 42.15232],
                    [-89.091831, 42.150681],
                    [-89.172803, 42.150417],
                    [-89.173, 42.204241],
                    [-89.396192, 42.201916],
                    [-89.397809, 42.307171],
                    [-89.4014168110406, 42.5004419310737],
                ],
            ],
        },
        properties: {
            CSAFP: '466',
            CBSAFP: '40420',
            AFFGEOID: '310M600US40420',
            GEOID: '40420',
            NAME: 'Rockford, IL',
            NAMELSAD: 'Rockford, IL Metro Area',
            LSAD: 'M1',
            ALAND: 2055907176,
            AWATER: 19234619,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.562433, 43.46619, -85.084996, 43.815483],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.562433, 43.815483],
                    [-85.088811, 43.813676],
                    [-85.084996, 43.46619],
                    [-85.562348, 43.46813],
                    [-85.562433, 43.815483],
                ],
            ],
        },
        properties: {
            CSAFP: '266',
            CBSAFP: '13660',
            AFFGEOID: '310M600US13660',
            GEOID: '13660',
            NAME: 'Big Rapids, MI',
            NAMELSAD: 'Big Rapids, MI Micro Area',
            LSAD: 'M2',
            ALAND: 1437843578,
            AWATER: 41665709,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-103.042769, 34.746931, -102.167471, 35.183233],
            type: 'Polygon',
            coordinates: [
                [
                    [-103.042738818608, 34.9541420403614],
                    [-103.042711, 35.144735],
                    [-103.042617950565, 35.1831572590084],
                    [-102.167471, 35.183233],
                    [-102.168839, 34.747417],
                    [-102.525184, 34.746931],
                    [-103.042769, 34.747361],
                    [-103.042738818608, 34.9541420403614],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '25820',
            AFFGEOID: '310M600US25820',
            GEOID: '25820',
            NAME: 'Hereford, TX',
            NAMELSAD: 'Hereford, TX Micro Area',
            LSAD: 'M2',
            ALAND: 3876750570,
            AWATER: 3926557,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.905922, 41.393136, -96.329012, 41.742763],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.905922, 41.742763],
                    [-96.555172, 41.742018],
                    [-96.444217, 41.741775],
                    [-96.444785, 41.68363300000001],
                    [-96.434792, 41.494928],
                    [-96.329012, 41.393136],
                    [-96.47072, 41.393239],
                    [-96.568677, 41.432986],
                    [-96.905862, 41.453389],
                    [-96.90591000000002, 41.456436],
                    [-96.905922, 41.742763],
                ],
            ],
        },
        properties: {
            CSAFP: '420',
            CBSAFP: '23340',
            AFFGEOID: '310M600US23340',
            GEOID: '23340',
            NAME: 'Fremont, NE',
            NAMELSAD: 'Fremont, NE Micro Area',
            LSAD: 'M2',
            ALAND: 1370277207,
            AWATER: 36091675,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.388182, 42.9097, -94.913723, 43.255221000000006],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.388078, 43.255221000000006],
                    [-94.913723, 43.255054],
                    [-94.91389, 42.9097],
                    [-95.388182, 42.909897],
                    [-95.388078, 43.255221000000006],
                ],
            ],
        },
        properties: {
            CSAFP: '517',
            CBSAFP: '43980',
            AFFGEOID: '310M600US43980',
            GEOID: '43980',
            NAME: 'Spencer, IA',
            NAMELSAD: 'Spencer, IA Micro Area',
            LSAD: 'M2',
            ALAND: 1469139465,
            AWATER: 13866648,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.2423682473972, 41.584005, -89.62933, 41.93034],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.2423682473972, 41.7827687259846],
                    [-90.180643, 41.811979],
                    [-90.181401, 41.844647],
                    [-90.165065, 41.883777],
                    [-90.1581530337095, 41.9298432273761],
                    [-90.128204, 41.92897700000001],
                    [-89.685366, 41.93034],
                    [-89.62933, 41.901617],
                    [-89.631494, 41.584949],
                    [-89.862351, 41.584005],
                    [-90.185536, 41.584574],
                    [-90.161233, 41.643927],
                    [-90.229749, 41.675375],
                    [-90.2423682473972, 41.7827687259846],
                ],
            ],
        },
        properties: {
            CSAFP: '221',
            CBSAFP: '44580',
            AFFGEOID: '310M600US44580',
            GEOID: '44580',
            NAME: 'Sterling, IL',
            NAMELSAD: 'Sterling, IL Micro Area',
            LSAD: 'M2',
            ALAND: 1771808919,
            AWATER: 31883751,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.881172, 27.5573400246076, -80.3166866116204, 27.860513513361],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.881172, 27.80917],
                    [-80.868881, 27.822522],
                    [-80.509075, 27.822058],
                    [-80.4476769492176, 27.860513513361],
                    [-80.383695, 27.740045],
                    [-80.330956, 27.597541],
                    [-80.3166866116204, 27.5573400246076],
                    [-80.67982100000002, 27.55847],
                    [-80.777166, 27.558733],
                    [-80.778047, 27.643195],
                    [-80.87315, 27.642288000000004],
                    [-80.881172, 27.80917],
                ],
            ],
        },
        properties: {
            CSAFP: '370',
            CBSAFP: '42680',
            AFFGEOID: '310M600US42680',
            GEOID: '42680',
            NAME: 'Sebastian-Vero Beach, FL',
            NAMELSAD: 'Sebastian-Vero Beach, FL Metro Area',
            LSAD: 'M1',
            ALAND: 1302272811,
            AWATER: 295509933,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.401227, 31.433458, -88.910459, 32.224186],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.401227, 31.796863000000002],
                    [-89.31656, 31.80209],
                    [-89.322692, 32.223472],
                    [-88.91384700000002, 32.224186],
                    [-88.910459, 31.826649],
                    [-88.943355, 31.824565000000003],
                    [-88.943468, 31.433458],
                    [-89.146092, 31.434052],
                    [-89.399178, 31.434029],
                    [-89.401227, 31.796863000000002],
                ],
            ],
        },
        properties: {
            CSAFP: '279',
            CBSAFP: '29860',
            AFFGEOID: '310M600US29860',
            GEOID: '29860',
            NAME: 'Laurel, MS',
            NAMELSAD: 'Laurel, MS Micro Area',
            LSAD: 'M2',
            ALAND: 3551275209,
            AWATER: 15812537,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-94.502395, 44.629921, -94.010494, 44.979784],
            type: 'Polygon',
            coordinates: [
                [
                    [-94.501885, 44.979784],
                    [-94.256074, 44.979465],
                    [-94.012236, 44.978712],
                    [-94.010494, 44.717464],
                    [-94.254076, 44.717855],
                    [-94.254719, 44.630311],
                    [-94.497835, 44.629921],
                    [-94.498074, 44.717171],
                    [-94.498028, 44.872516000000005],
                    [-94.502395, 44.892295],
                    [-94.501885, 44.979784],
                ],
            ],
        },
        properties: {
            CSAFP: '378',
            CBSAFP: '26780',
            AFFGEOID: '310M600US26780',
            GEOID: '26780',
            NAME: 'Hutchinson, MN',
            NAMELSAD: 'Hutchinson, MN Micro Area',
            LSAD: 'M2',
            ALAND: 1273004835,
            AWATER: 36432583,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-102.085733, 32.959702, -101.03878800000001, 33.833625],
            type: 'Polygon',
            coordinates: [
                [
                    [-102.085733, 33.824675],
                    [-101.56358, 33.830451],
                    [-101.041158, 33.833625],
                    [-101.03878800000001, 33.397214],
                    [-101.556884, 33.394760000000005],
                    [-101.557434, 32.961025],
                    [-101.691284, 32.961838],
                    [-102.076214, 32.959702],
                    [-102.075929, 33.389586],
                    [-102.085733, 33.824675],
                ],
            ],
        },
        properties: {
            CSAFP: '352',
            CBSAFP: '31180',
            AFFGEOID: '310M600US31180',
            GEOID: '31180',
            NAME: 'Lubbock, TX',
            NAMELSAD: 'Lubbock, TX Metro Area',
            LSAD: 'M1',
            ALAND: 6961026025,
            AWATER: 21254072,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.226536, 32.147274, -82.38194, 32.822356],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.226536, 32.584201],
                    [-82.957066, 32.708318],
                    [-82.946966, 32.759358],
                    [-82.802456, 32.809756],
                    [-82.768365, 32.769108],
                    [-82.521052, 32.822356],
                    [-82.458249, 32.768223],
                    [-82.434149, 32.762258],
                    [-82.499431, 32.68634],
                    [-82.465065, 32.646833],
                    [-82.594439, 32.579158],
                    [-82.564378, 32.53249],
                    [-82.647733, 32.512507],
                    [-82.488941, 32.49183],
                    [-82.38194, 32.440596],
                    [-82.409118, 32.35383],
                    [-82.40913, 32.353738],
                    [-82.649104, 32.311496],
                    [-82.655486, 32.297561],
                    [-82.67377, 32.293595],
                    [-82.721964, 32.309283],
                    [-82.755158, 32.26969],
                    [-82.858308, 32.194386],
                    [-82.884803, 32.196072],
                    [-82.990967, 32.147274],
                    [-83.13899100000002, 32.423069],
                    [-83.226536, 32.584201],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '20140',
            AFFGEOID: '310M600US20140',
            GEOID: '20140',
            NAME: 'Dublin, GA',
            NAMELSAD: 'Dublin, GA Micro Area',
            LSAD: 'M2',
            ALAND: 3392329366,
            AWATER: 45906009,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.493259, 30.298817000000003, -91.678896, 30.849453],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.42078, 30.538819999999998],
                    [-92.262719, 30.538738],
                    [-92.211504, 30.568129999999996],
                    [-92.17248400000001, 30.673829],
                    [-92.172314, 30.767914000000005],
                    [-92.212593, 30.848609],
                    [-92.02887, 30.849453],
                    [-91.815258, 30.848107],
                    [-91.733673, 30.70173],
                    [-91.75698, 30.562551],
                    [-91.731432, 30.512119],
                    [-91.756209, 30.498133000000003],
                    [-91.678896, 30.394731000000004],
                    [-91.978184, 30.407403000000002],
                    [-91.988718, 30.368598],
                    [-92.04943, 30.381307000000003],
                    [-92.089446, 30.330166],
                    [-92.142184, 30.298817000000003],
                    [-92.158554, 30.342445],
                    [-92.158605, 30.349908999999997],
                    [-92.17577800000001, 30.3497],
                    [-92.176059, 30.400471000000003],
                    [-92.24479500000001, 30.480193],
                    [-92.493259, 30.480499000000002],
                    [-92.42078, 30.538819999999998],
                ],
            ],
        },
        properties: {
            CSAFP: '318',
            CBSAFP: '36660',
            AFFGEOID: '310M600US36660',
            GEOID: '36660',
            NAME: 'Opelousas, LA',
            NAMELSAD: 'Opelousas, LA Micro Area',
            LSAD: 'M2',
            ALAND: 2393224007,
            AWATER: 39013551,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.274673, 42.407568, -75.86402, 42.790964],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.274673, 42.771257],
                    [-75.896079, 42.790964],
                    [-75.889832, 42.723844],
                    [-75.86402, 42.415702],
                    [-76.021374, 42.409008],
                    [-76.13018100000001, 42.410337],
                    [-76.253359, 42.407568],
                    [-76.265584, 42.623588],
                    [-76.274673, 42.771257],
                ],
            ],
        },
        properties: {
            CSAFP: '296',
            CBSAFP: '18660',
            AFFGEOID: '310M600US18660',
            GEOID: '18660',
            NAME: 'Cortland, NY',
            NAMELSAD: 'Cortland, NY Micro Area',
            LSAD: 'M2',
            ALAND: 1291817953,
            AWATER: 7110638,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.281196, 46.106761, -95.145791, 46.717854],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.281196, 46.630779],
                    [-96.172405, 46.630649],
                    [-96.174587, 46.717854],
                    [-95.247999, 46.716255],
                    [-95.16371, 46.71764],
                    [-95.15554, 46.62994],
                    [-95.15557, 46.36888],
                    [-95.145791, 46.282368],
                    [-95.14588, 46.106761],
                    [-95.76975, 46.10745],
                    [-96.265366, 46.10861],
                    [-96.28, 46.28222],
                    [-96.281196, 46.630779],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '22260',
            AFFGEOID: '310M600US22260',
            GEOID: '22260',
            NAME: 'Fergus Falls, MN',
            NAMELSAD: 'Fergus Falls, MN Micro Area',
            LSAD: 'M2',
            ALAND: 5106508611,
            AWATER: 654472630,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.45843, 34.460998, -83.1028738260357, 34.6863277104121],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.45843, 34.483933],
                    [-83.365044, 34.675308],
                    [-83.3400391067846, 34.6863277104121],
                    [-83.33869, 34.682002],
                    [-83.27796, 34.644853],
                    [-83.221402, 34.609947],
                    [-83.154577, 34.588198],
                    [-83.1028738260357, 34.5374309097654],
                    [-83.177838, 34.477017],
                    [-83.398049, 34.460998],
                    [-83.45843, 34.483933],
                ],
            ],
        },
        properties: {
            CSAFP: '122',
            CBSAFP: '45740',
            AFFGEOID: '310M600US45740',
            GEOID: '45740',
            NAME: 'Toccoa, GA',
            NAMELSAD: 'Toccoa, GA Micro Area',
            LSAD: 'M2',
            ALAND: 463254407,
            AWATER: 13035162,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-109.045871168205, 36.000288, -107.420913, 37.000005],
            type: 'Polygon',
            coordinates: [
                [
                    [-109.045729, 36.117028],
                    [-109.045433, 36.874589],
                    [-109.045223, 36.999084],
                    [-108.620309, 36.999287],
                    [-108.379302950094, 36.9995646863115],
                    [-108.000623, 37.000001],
                    [-107.481736999155, 37.0000045803143],
                    [-107.420913, 37.000005],
                    [-107.457159, 36.954964],
                    [-107.451445, 36.904036],
                    [-107.597983, 36.834302],
                    [-107.618181, 36.804267],
                    [-107.624283, 36.219809],
                    [-107.626511, 36.000288],
                    [-109.045871168205, 36.002701630519],
                    [-109.045729, 36.117028],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '22140',
            AFFGEOID: '310M600US22140',
            GEOID: '22140',
            NAME: 'Farmington, NM',
            NAMELSAD: 'Farmington, NM Metro Area',
            LSAD: 'M1',
            ALAND: 14289563220,
            AWATER: 54895407,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.375762, 40.373661, -85.862117, 40.565525],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.374417, 40.561361],
                    [-86.165024, 40.562649],
                    [-86.013325, 40.564128],
                    [-85.863641, 40.565525],
                    [-85.862117, 40.406889],
                    [-86.060645, 40.405198],
                    [-86.242812, 40.402914],
                    [-86.242992, 40.373661],
                    [-86.309249, 40.431712],
                    [-86.375762, 40.431851],
                    [-86.374417, 40.561361],
                ],
            ],
        },
        properties: {
            CSAFP: '316',
            CBSAFP: '29020',
            AFFGEOID: '310M600US29020',
            GEOID: '29020',
            NAME: 'Kokomo, IN',
            NAMELSAD: 'Kokomo, IN Metro Area',
            LSAD: 'M1',
            ALAND: 759058596,
            AWATER: 2194576,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.444339, 40.712361, -89.984559, 41.151463],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.439423, 41.063995],
                    [-90.437652, 41.151463],
                    [-89.984559, 41.149394],
                    [-89.985479, 40.974495],
                    [-89.986064, 40.712361],
                    [-90.444339, 40.714868],
                    [-90.439423, 41.063995],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '23660',
            AFFGEOID: '310M600US23660',
            GEOID: '23660',
            NAME: 'Galesburg, IL',
            NAMELSAD: 'Galesburg, IL Micro Area',
            LSAD: 'M2',
            ALAND: 1855428897,
            AWATER: 8837382,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.434631, 40.183945, -84.002372, 40.483115],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.43438700000002, 40.35453400000001],
                    [-84.33860000000001, 40.379199],
                    [-84.339137, 40.481076],
                    [-84.002372, 40.483115],
                    [-84.0037, 40.312927],
                    [-84.014763, 40.273459],
                    [-84.022919, 40.183945],
                    [-84.218658, 40.200004],
                    [-84.432575, 40.197037],
                    [-84.434631, 40.354259],
                    [-84.43438700000002, 40.35453400000001],
                ],
            ],
        },
        properties: {
            CSAFP: '212',
            CBSAFP: '43380',
            AFFGEOID: '310M600US43380',
            GEOID: '43380',
            NAME: 'Sidney, OH',
            NAMELSAD: 'Sidney, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1055922238,
            AWATER: 7802978,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.094389, 36.5414324847413, -79.640652, 36.856139],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.094389, 36.779552],
                    [-80.041493, 36.794365],
                    [-79.999437, 36.830618],
                    [-79.741417, 36.806806],
                    [-79.669153, 36.827838],
                    [-79.640652, 36.856139],
                    [-79.714850811052, 36.5414324847413],
                    [-79.8916701669539, 36.5420338366162],
                    [-80.027269, 36.542495],
                    [-80.0273389151412, 36.54249538561421],
                    [-80.0534550433501, 36.5426394280866],
                    [-80.094389, 36.779552],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '32300',
            AFFGEOID: '310M600US32300',
            GEOID: '32300',
            NAME: 'Martinsville, VA',
            NAMELSAD: 'Martinsville, VA Micro Area',
            LSAD: 'M2',
            ALAND: 1018659273,
            AWATER: 5405363,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.721341, 34.161186, -89.245485, 34.554367],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.721341, 34.554274],
                    [-89.668448, 34.554367],
                    [-89.476642, 34.554168],
                    [-89.424459, 34.519129],
                    [-89.32582, 34.525974],
                    [-89.246133, 34.495633],
                    [-89.246339, 34.379344],
                    [-89.245485, 34.161186],
                    [-89.509748, 34.162082],
                    [-89.50944, 34.19096],
                    [-89.72105, 34.192545],
                    [-89.721341, 34.554274],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '37060',
            AFFGEOID: '310M600US37060',
            GEOID: '37060',
            NAME: 'Oxford, MS',
            NAMELSAD: 'Oxford, MS Micro Area',
            LSAD: 'M2',
            ALAND: 1636088509,
            AWATER: 123105438,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.98146, 30.891503, -92.072937, 31.797288],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.98146, 31.346864],
                    [-92.947159, 31.346750999999998],
                    [-92.718899, 31.517476],
                    [-92.806206, 31.598548000000005],
                    [-92.908313, 31.625169],
                    [-92.964615, 31.680720999999995],
                    [-92.973529, 31.708921999999998],
                    [-92.62086, 31.709617999999995],
                    [-92.619309, 31.797288],
                    [-92.362608, 31.796633],
                    [-92.35127, 31.624550999999997],
                    [-92.275088, 31.536087000000002],
                    [-92.277615, 31.496236000000003],
                    [-92.196131, 31.477876999999996],
                    [-92.072937, 31.345407],
                    [-92.086718, 31.335374],
                    [-92.126063, 31.306045000000005],
                    [-92.23323, 31.321545],
                    [-92.234258, 31.131466],
                    [-92.208299, 30.962430000000005],
                    [-92.280738, 30.965070999999995],
                    [-92.383033, 31.001454],
                    [-92.48033, 30.952271],
                    [-92.50885400000001, 30.896003000000004],
                    [-92.597798, 30.896001000000002],
                    [-92.622883, 30.898760999999997],
                    [-92.740222, 30.891503],
                    [-92.82387, 30.892071],
                    [-92.829525, 31.252055],
                    [-92.879715, 31.317439],
                    [-92.964258, 31.332388],
                    [-92.98146, 31.346864],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '10780',
            AFFGEOID: '310M600US10780',
            GEOID: '10780',
            NAME: 'Alexandria, LA',
            NAMELSAD: 'Alexandria, LA Metro Area',
            LSAD: 'M1',
            ALAND: 5086784347,
            AWATER: 161391319,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.545488, 35.996583, -80.868746, 36.440773],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.455792, 36.200441],
                    [-81.477516, 36.24025],
                    [-81.366667, 36.285884],
                    [-81.323639, 36.376822],
                    [-81.253552, 36.366622],
                    [-81.162956, 36.432515],
                    [-81.095112, 36.440773],
                    [-81.026688, 36.373195],
                    [-80.96715300000001, 36.40202],
                    [-80.868746, 36.326447],
                    [-80.873205, 36.236305],
                    [-80.881591, 36.055718],
                    [-80.975675, 36.05881],
                    [-81.028833, 36.045652],
                    [-81.263596, 36.025818],
                    [-81.328324, 35.996583],
                    [-81.397817, 36.066061],
                    [-81.545488, 36.117475],
                    [-81.455792, 36.200441],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '35900',
            AFFGEOID: '310M600US35900',
            GEOID: '35900',
            NAME: 'North Wilkesboro, NC',
            NAMELSAD: 'North Wilkesboro, NC Micro Area',
            LSAD: 'M2',
            ALAND: 1952020335,
            AWATER: 6854041,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.4905581209888, 33.0172511697504, -92.978483, 33.452922],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.481748, 33.260065000000004],
                    [-93.442029, 33.258275],
                    [-93.365644, 33.444491],
                    [-93.22134, 33.440533],
                    [-93.116328, 33.452922],
                    [-93.118951, 33.380298],
                    [-92.978483, 33.377142],
                    [-92.9887760560898, 33.0172511697504],
                    [-93.197402, 33.017951],
                    [-93.2386468140122, 33.0180159427056],
                    [-93.377134, 33.018234],
                    [-93.4905581209888, 33.0186347572102],
                    [-93.481748, 33.260065000000004],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '31620',
            AFFGEOID: '310M600US31620',
            GEOID: '31620',
            NAME: 'Magnolia, AR',
            NAMELSAD: 'Magnolia, AR Micro Area',
            LSAD: 'M2',
            ALAND: 1984098878,
            AWATER: 1985830,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.8114171063805, 39.9169136295798, -84.425902, 40.354259],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.8101612384745, 40.0050675438482],
                    [-84.808706, 40.107216],
                    [-84.8049173025784, 40.3100959150341],
                    [-84.8041205270149, 40.3527622302971],
                    [-84.434631, 40.354259],
                    [-84.432575, 40.197037],
                    [-84.425902, 39.919622],
                    [-84.485367, 39.918491],
                    [-84.8114171063805, 39.9169136295798],
                    [-84.8101612384745, 40.0050675438482],
                ],
            ],
        },
        properties: {
            CSAFP: '212',
            CBSAFP: '24820',
            AFFGEOID: '310M600US24820',
            GEOID: '24820',
            NAME: 'Greenville, OH',
            NAMELSAD: 'Greenville, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1549077538,
            AWATER: 4244898,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.819387, 36.5012837190757, -88.484103, 36.946864],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.819387, 36.749969],
                    [-88.813229, 36.773115],
                    [-88.812897, 36.946864],
                    [-88.484103, 36.942096],
                    [-88.485697, 36.750328],
                    [-88.4890767387132, 36.5012837190757],
                    [-88.51192, 36.501457],
                    [-88.5163580599794, 36.5014641981633],
                    [-88.8167641573481, 36.5019514318132],
                    [-88.819387, 36.749969],
                ],
            ],
        },
        properties: {
            CSAFP: '424',
            CBSAFP: '32460',
            AFFGEOID: '310M600US32460',
            GEOID: '32460',
            NAME: 'Mayfield, KY',
            NAMELSAD: 'Mayfield, KY Micro Area',
            LSAD: 'M2',
            ALAND: 1429035006,
            AWATER: 13037984,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-102.20852, 32.523271, -101.68874, 32.961838],
            type: 'Polygon',
            coordinates: [
                [
                    [-102.20852, 32.958956],
                    [-102.076214, 32.959702],
                    [-101.691284, 32.961838],
                    [-101.68874, 32.525222],
                    [-102.202703, 32.523271],
                    [-102.20852, 32.958956],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '29500',
            AFFGEOID: '310M600US29500',
            GEOID: '29500',
            NAME: 'Lamesa, TX',
            NAMELSAD: 'Lamesa, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2331781561,
            AWATER: 4720730,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-100.22709, 37.467722, -99.556119, 37.914018],
            type: 'Polygon',
            coordinates: [
                [
                    [-100.22709, 37.914018],
                    [-99.569879, 37.913016],
                    [-99.570576, 37.825634],
                    [-99.55958, 37.734888],
                    [-99.556119, 37.467722],
                    [-100.107248, 37.474836],
                    [-100.216229, 37.47519],
                    [-100.214637, 37.826808],
                    [-100.22709, 37.914018],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '19980',
            AFFGEOID: '310M600US19980',
            GEOID: '19980',
            NAME: 'Dodge City, KS',
            NAMELSAD: 'Dodge City, KS Micro Area',
            LSAD: 'M2',
            ALAND: 2844508242,
            AWATER: 2097356,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-114.052701, 37.000025, -112.899190897616, 37.618289],
            type: 'Polygon',
            coordinates: [
                [
                    [-114.052472, 37.604776],
                    [-113.587797, 37.604611],
                    [-113.474485, 37.618289],
                    [-113.474029, 37.529085],
                    [-113.25334, 37.528983],
                    [-113.25349, 37.482167],
                    [-113.037048, 37.474474],
                    [-113.037088, 37.499748],
                    [-112.90099300000001, 37.500021],
                    [-112.899190897616, 37.00030122862841],
                    [-112.966471, 37.000219],
                    [-113.96590700000002, 37.000025],
                    [-114.05060000000002, 37.000396],
                    [-114.051749, 37.088434],
                    [-114.051974, 37.284511],
                    [-114.052701, 37.492014],
                    [-114.052472, 37.604776],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '41100',
            AFFGEOID: '310M600US41100',
            GEOID: '41100',
            NAME: 'St. George, UT',
            NAMELSAD: 'St. George, UT Metro Area',
            LSAD: 'M1',
            ALAND: 6287037041,
            AWATER: 7781671,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-100.151911, 32.079235, -99.114293, 32.960121],
            type: 'Polygon',
            coordinates: [
                [
                    [-100.146543, 32.522793],
                    [-100.144224, 32.959978],
                    [-99.988827, 32.960121],
                    [-99.612001, 32.956962],
                    [-99.612026, 32.514654],
                    [-99.114293, 32.514811],
                    [-99.118665, 32.079585],
                    [-99.195871, 32.079235],
                    [-99.631413, 32.081274],
                    [-99.71397100000002, 32.082089],
                    [-100.151911, 32.082638],
                    [-100.146543, 32.522793],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '10180',
            AFFGEOID: '310M600US10180',
            GEOID: '10180',
            NAME: 'Abilene, TX',
            NAMELSAD: 'Abilene, TX Metro Area',
            LSAD: 'M1',
            ALAND: 7105668292,
            AWATER: 36756436,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.013582, 42.842634, -88.535843, 43.197724],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.008832, 43.197724],
                    [-88.535843, 43.196048],
                    [-88.541579, 43.019387],
                    [-88.541535, 42.842996],
                    [-88.69812, 42.842634],
                    [-88.777076, 42.842694],
                    [-88.918672, 42.846121],
                    [-89.013582, 42.84763],
                    [-89.008832, 43.197724],
                ],
            ],
        },
        properties: {
            CSAFP: '376',
            CBSAFP: '48020',
            AFFGEOID: '310M600US48020',
            GEOID: '48020',
            NAME: 'Watertown-Fort Atkinson, WI',
            NAMELSAD: 'Watertown-Fort Atkinson, WI Micro Area',
            LSAD: 'M2',
            ALAND: 1441308422,
            AWATER: 67985729,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.36987600000002, 43.12845200000001, -83.69562100000002, 43.568899],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.36987600000002, 43.466044],
                    [-84.170576, 43.481969],
                    [-84.168127, 43.568899],
                    [-84.05, 43.567324],
                    [-84.050987, 43.524064],
                    [-83.916091, 43.522629],
                    [-83.817228, 43.522345],
                    [-83.817678, 43.479052],
                    [-83.698816, 43.478957],
                    [-83.698509, 43.392711],
                    [-83.69562100000002, 43.221422],
                    [-83.932071, 43.220377],
                    [-83.929079, 43.132782],
                    [-84.367891, 43.12845200000001],
                    [-84.36987600000002, 43.466044],
                ],
            ],
        },
        properties: {
            CSAFP: '474',
            CBSAFP: '40980',
            AFFGEOID: '310M600US40980',
            GEOID: '40980',
            NAME: 'Saginaw, MI',
            NAMELSAD: 'Saginaw, MI Metro Area',
            LSAD: 'M1',
            ALAND: 2074053636,
            AWATER: 39287690,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-123.37095000000001, 46.383458, -121.353562, 46.79350900000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-123.37095000000001, 46.792129],
                    [-123.35139100000002, 46.79350900000001],
                    [-123.160588, 46.793383],
                    [-123.160909, 46.76433],
                    [-122.20311500000001, 46.763061],
                    [-121.84188999999999, 46.728455],
                    [-121.75859300000002, 46.783791],
                    [-121.455218, 46.783797],
                    [-121.353562, 46.711506],
                    [-121.45013999999999, 46.503526],
                    [-121.393759, 46.39004],
                    [-121.522324, 46.388224],
                    [-121.522324, 46.388627],
                    [-122.03214000000001, 46.38781],
                    [-122.11429100000001, 46.386480000000006],
                    [-122.236759, 46.385893],
                    [-122.240966, 46.385361],
                    [-123.21795, 46.385617],
                    [-123.259068, 46.383458],
                    [-123.35833400000001, 46.384025],
                    [-123.37095000000001, 46.792129],
                ],
            ],
        },
        properties: {
            CSAFP: '500',
            CBSAFP: '16500',
            AFFGEOID: '310M600US16500',
            GEOID: '16500',
            NAME: 'Centralia, WA',
            NAMELSAD: 'Centralia, WA Micro Area',
            LSAD: 'M2',
            ALAND: 6223175362,
            AWATER: 86929744,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.914516, 31.826649, -88.3474902478349, 32.9290347868805],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.914413, 32.9266],
                    [-88.812782, 32.925973],
                    [-88.3474902478349, 32.9290347868805],
                    [-88.373338, 32.711825],
                    [-88.3892485641243, 32.5781223959586],
                    [-88.4213122213943, 32.3086791230113],
                    [-88.428278, 32.250143],
                    [-88.4311453634628, 32.2276362346889],
                    [-88.4533877631093, 32.0530492054197],
                    [-88.46866, 31.933173],
                    [-88.4686624840513, 31.8938557116156],
                    [-88.625036, 31.856689000000003],
                    [-88.809174, 31.832896999999996],
                    [-88.910459, 31.826649],
                    [-88.91384700000002, 32.224186],
                    [-88.914516, 32.576955],
                    [-88.914413, 32.9266],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '32940',
            AFFGEOID: '310M600US32940',
            GEOID: '32940',
            NAME: 'Meridian, MS',
            NAMELSAD: 'Meridian, MS Micro Area',
            LSAD: 'M2',
            ALAND: 5598084432,
            AWATER: 36986802,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.498617, 42.907549, -93.024092, 43.499567],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.497635, 43.255468],
                    [-93.4973513252204, 43.4995307245117],
                    [-93.22886100000001, 43.499567],
                    [-93.0491920078165, 43.4995574800246],
                    [-93.0243479005419, 43.49955616363],
                    [-93.024143, 43.255538],
                    [-93.024092, 43.212812],
                    [-93.025144, 42.907549],
                    [-93.498617, 42.908512],
                    [-93.497635, 43.255468],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '32380',
            AFFGEOID: '310M600US32380',
            GEOID: '32380',
            NAME: 'Mason City, IA',
            NAMELSAD: 'Mason City, IA Micro Area',
            LSAD: 'M2',
            ALAND: 2508204878,
            AWATER: 22368796,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.458751, 34.833717, -78.494705, 35.583682],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.458751, 35.043639],
                    [-79.33547700000001, 35.161588],
                    [-79.24389, 35.213666],
                    [-79.097551, 35.174233],
                    [-79.095808, 35.192068],
                    [-79.223021, 35.268133],
                    [-79.183556, 35.307161],
                    [-78.96964, 35.521669],
                    [-78.914732, 35.583682],
                    [-78.708902, 35.519458],
                    [-78.660555, 35.496245],
                    [-78.529851, 35.334935],
                    [-78.538349, 35.31641],
                    [-78.583451, 35.296779],
                    [-78.61711400000002, 35.245718],
                    [-78.639478, 35.115774],
                    [-78.672292, 35.085281],
                    [-78.647033, 34.992254],
                    [-78.494705, 34.856182],
                    [-78.516123, 34.845919],
                    [-78.827397, 34.850693],
                    [-78.901998, 34.835268],
                    [-78.940844, 34.903368],
                    [-79.038747, 34.952715],
                    [-79.191391, 34.833717],
                    [-79.347868, 34.838553],
                    [-79.353124, 34.944242],
                    [-79.458751, 35.043639],
                ],
            ],
        },
        properties: {
            CSAFP: '246',
            CBSAFP: '22180',
            AFFGEOID: '310M600US22180',
            GEOID: '22180',
            NAME: 'Fayetteville, NC',
            NAMELSAD: 'Fayetteville, NC Metro Area',
            LSAD: 'M1',
            ALAND: 4241473864,
            AWATER: 35581071,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.6060978676533, 34.9992307419721, -87.20657800000001, 35.457844],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.57275000000001, 35.049051000000006],
                    [-87.57535200000001, 35.398454],
                    [-87.44882, 35.420622],
                    [-87.448485, 35.457844],
                    [-87.294534, 35.444676],
                    [-87.227932, 35.429332],
                    [-87.20657800000001, 35.433207],
                    [-87.232054, 35.292704],
                    [-87.2153, 35.286625],
                    [-87.2240536418757, 34.9992307419721],
                    [-87.6060978676533, 35.0035195262043],
                    [-87.57275000000001, 35.049051000000006],
                ],
            ],
        },
        properties: {
            CSAFP: '400',
            CBSAFP: '29980',
            AFFGEOID: '310M600US29980',
            GEOID: '29980',
            NAME: 'Lawrenceburg, TN',
            NAMELSAD: 'Lawrenceburg, TN Micro Area',
            LSAD: 'M2',
            ALAND: 1598359119,
            AWATER: 2218403,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.73733, 31.349306000000002, -90.24389, 31.717523999999997],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.736824, 31.698491],
                    [-90.414198, 31.700455999999996],
                    [-90.379409, 31.685194],
                    [-90.245191, 31.717523999999997],
                    [-90.24389, 31.350274],
                    [-90.260391, 31.350274],
                    [-90.548199, 31.349574000000004],
                    [-90.633302, 31.349306000000002],
                    [-90.633231, 31.611409],
                    [-90.73733, 31.611124000000004],
                    [-90.736824, 31.698491],
                ],
            ],
        },
        properties: {
            CSAFP: '298',
            CBSAFP: '15020',
            AFFGEOID: '310M600US15020',
            GEOID: '15020',
            NAME: 'Brookhaven, MS',
            NAMELSAD: 'Brookhaven, MS Micro Area',
            LSAD: 'M2',
            ALAND: 1518269755,
            AWATER: 5291052,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.696655, 42.262976, -76.250149, 42.623588],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.696655, 42.54679],
                    [-76.585989, 42.54991],
                    [-76.666543, 42.623457],
                    [-76.4584, 42.618615000000005],
                    [-76.265584, 42.623588],
                    [-76.253359, 42.407568],
                    [-76.250149, 42.296676],
                    [-76.415305, 42.318368],
                    [-76.41619900000002, 42.262976],
                    [-76.538349, 42.281755],
                    [-76.61930300000002, 42.282853],
                    [-76.691406, 42.284307],
                    [-76.696655, 42.54679],
                ],
            ],
        },
        properties: {
            CSAFP: '296',
            CBSAFP: '27060',
            AFFGEOID: '310M600US27060',
            GEOID: '27060',
            NAME: 'Ithaca, NY',
            NAMELSAD: 'Ithaca, NY Metro Area',
            LSAD: 'M1',
            ALAND: 1229316849,
            AWATER: 43785776,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-106.010751, 38.257973, -104.940366, 38.697558],
            type: 'Polygon',
            coordinates: [
                [
                    [-106.010751, 38.446566],
                    [-105.908717, 38.505631],
                    [-105.878122, 38.60212],
                    [-105.96975, 38.693551],
                    [-105.328962, 38.697558],
                    [-105.237043, 38.697267],
                    [-105.239927, 38.647464],
                    [-104.942205, 38.650133],
                    [-104.941533, 38.519569],
                    [-104.940366, 38.258275],
                    [-105.049215, 38.257973],
                    [-105.796897, 38.265047],
                    [-105.999788, 38.423622],
                    [-106.010751, 38.446566],
                ],
            ],
        },
        properties: {
            CSAFP: '444',
            CBSAFP: '15860',
            AFFGEOID: '310M600US15860',
            GEOID: '15860',
            NAME: 'Cañon City, CO',
            NAMELSAD: 'Cañon City, CO Micro Area',
            LSAD: 'M2',
            ALAND: 3972613670,
            AWATER: 2235542,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-109.047236980528, 31.863614, -107.608485, 33.2088950007083],
            type: 'Polygon',
            coordinates: [
                [
                    [-109.047236980528, 33.2088950007083],
                    [-108.543178, 33.207955],
                    [-108.449988, 33.200997],
                    [-108.000598, 33.201253],
                    [-107.858644, 33.200864],
                    [-107.836192, 33.067763],
                    [-107.856672, 33.002539],
                    [-107.780548, 32.952776],
                    [-107.773088, 32.853775],
                    [-107.721387, 32.778077],
                    [-107.736949, 32.629494],
                    [-107.608485, 32.605449],
                    [-107.92399700000001, 32.604379],
                    [-107.923766, 32.517416],
                    [-108.22934300000001, 32.516837],
                    [-108.216964, 32.079863],
                    [-108.217143, 31.864139],
                    [-108.524538, 31.863614],
                    [-108.537676, 32.079832],
                    [-108.537011, 32.516618],
                    [-108.64677000000002, 32.516521],
                    [-108.646523, 32.60373],
                    [-108.852919, 32.603374],
                    [-108.854581, 32.777276],
                    [-109.04711706231, 32.7777940009477],
                    [-109.047236980528, 33.2088950007083],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '43500',
            AFFGEOID: '310M600US43500',
            GEOID: '43500',
            NAME: 'Silver City, NM',
            NAMELSAD: 'Silver City, NM Micro Area',
            LSAD: 'M2',
            ALAND: 10259478771,
            AWATER: 15294669,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-99.032971, 38.26079, -98.47984100000001, 38.696878],
            type: 'Polygon',
            coordinates: [
                [
                    [-99.032971, 38.696759],
                    [-98.486108, 38.696878],
                    [-98.479927, 38.676075],
                    [-98.480377, 38.521841],
                    [-98.47984100000001, 38.26079],
                    [-98.912583, 38.261088],
                    [-99.03230900000001, 38.261227],
                    [-99.032408, 38.348334],
                    [-99.032971, 38.696759],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '24460',
            AFFGEOID: '310M600US24460',
            GEOID: '24460',
            NAME: 'Great Bend, KS',
            NAMELSAD: 'Great Bend, KS Micro Area',
            LSAD: 'M2',
            ALAND: 2318705423,
            AWATER: 13926862,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.328614, 41.160659, -92.869771, 41.508522],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.328614, 41.50782400000001],
                    [-92.871421, 41.508522],
                    [-92.869771, 41.161066],
                    [-93.099217, 41.160867],
                    [-93.327886, 41.160659],
                    [-93.328407, 41.490794],
                    [-93.328614, 41.50782400000001],
                ],
            ],
        },
        properties: {
            CSAFP: '218',
            CBSAFP: '37800',
            AFFGEOID: '310M600US37800',
            GEOID: '37800',
            NAME: 'Pella, IA',
            NAMELSAD: 'Pella, IA Micro Area',
            LSAD: 'M2',
            ALAND: 1436123094,
            AWATER: 41484609,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.514702, 43.815611, -86.037884, 44.178694],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.514702, 44.058119],
                    [-86.4580652757377, 44.0992877982953],
                    [-86.429871, 44.119782],
                    [-86.3913991469087, 44.173701998925],
                    [-86.3878373602652, 44.178694],
                    [-86.043507, 44.167088],
                    [-86.040106, 43.989671],
                    [-86.037884, 43.815611],
                    [-86.4354850382583, 43.8194291980042],
                    [-86.431198, 43.84072],
                    [-86.447915, 43.918089],
                    [-86.463136, 43.970976],
                    [-86.4632031152485, 43.9710645597197],
                    [-86.501738, 44.021912],
                    [-86.514702, 44.058119],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '31220',
            AFFGEOID: '310M600US31220',
            GEOID: '31220',
            NAME: 'Ludington, MI',
            NAMELSAD: 'Ludington, MI Micro Area',
            LSAD: 'M2',
            ALAND: 1281963206,
            AWATER: 1935616622,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-66.22053069398, 17.9178103809448, -65.9149360834884, 18.109878],
            type: 'Polygon',
            coordinates: [
                [
                    [-66.205592, 18.01298],
                    [-66.166145, 18.050063],
                    [-66.138987, 18.036917],
                    [-66.129031, 18.088637],
                    [-66.053146, 18.107293],
                    [-66.051788, 18.109878],
                    [-66.010245, 18.075565000000005],
                    [-65.988523, 18.058941],
                    [-65.98912, 18.056918],
                    [-65.985187, 18.050462],
                    [-65.985578, 18.04521],
                    [-65.979951, 18.038897],
                    [-65.9819, 18.03484],
                    [-65.9149360834884, 17.982765902919702],
                    [-65.98455, 17.969411],
                    [-66.0179548341394, 17.9749022636095],
                    [-66.024, 17.975896],
                    [-66.041704, 17.934941],
                    [-66.0681034015261, 17.9456048296446],
                    [-66.098628, 17.957935],
                    [-66.155387, 17.929406],
                    [-66.22053069398, 17.9178103809448],
                    [-66.205592, 18.01298],
                ],
            ],
        },
        properties: {
            CSAFP: '490',
            CBSAFP: '25020',
            AFFGEOID: '310M600US25020',
            GEOID: '25020',
            NAME: 'Guayama, PR',
            NAMELSAD: 'Guayama, PR Metro Area',
            LSAD: 'M1',
            ALAND: 328013742,
            AWATER: 235741634,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.791402, 31.195173000000004, -85.416437, 31.620565999999997],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.789142, 31.617964],
                    [-85.748251, 31.618048],
                    [-85.467038, 31.620565999999997],
                    [-85.416437, 31.619466],
                    [-85.417434, 31.314973],
                    [-85.485854, 31.246096000000005],
                    [-85.665775, 31.267591],
                    [-85.710333, 31.195173000000004],
                    [-85.791402, 31.196349],
                    [-85.789142, 31.617964],
                ],
            ],
        },
        properties: {
            CSAFP: '222',
            CBSAFP: '37120',
            AFFGEOID: '310M600US37120',
            GEOID: '37120',
            NAME: 'Ozark, AL',
            NAMELSAD: 'Ozark, AL Micro Area',
            LSAD: 'M2',
            ALAND: 1453276164,
            AWATER: 4051341,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.409453, 40.63575000000001, -90.785194, 41.074112],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.372095, 40.812631],
                    [-91.37071, 41.074112],
                    [-90.9522654164822, 41.07273174967],
                    [-90.9518941426813, 41.0698727358664],
                    [-90.785194, 41.068749],
                    [-90.789652, 40.63575000000001],
                    [-90.904216, 40.639201],
                    [-91.1854606087043, 40.6381118126235],
                    [-91.12082, 40.672777],
                    [-91.1182229383975, 40.6995346508191],
                    [-91.208185, 40.71358],
                    [-91.409453, 40.812905],
                    [-91.372095, 40.812631],
                ],
            ],
        },
        properties: {
            CSAFP: '161',
            CBSAFP: '15460',
            AFFGEOID: '310M600US15460',
            GEOID: '15460',
            NAME: 'Burlington, IA-IL',
            NAMELSAD: 'Burlington, IA-IL Micro Area',
            LSAD: 'M2',
            ALAND: 2058838029,
            AWATER: 77907870,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.087289, 40.582496, -80.518991, 40.930187],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.086686, 40.898858],
                    [-81.086679, 40.901609],
                    [-80.896032, 40.900995],
                    [-80.895813, 40.930187],
                    [-80.821923, 40.900935],
                    [-80.5198697076862, 40.9003239342489],
                    [-80.5197051125992, 40.8513367786854],
                    [-80.518991, 40.638801],
                    [-80.583633, 40.61552],
                    [-80.627171, 40.61993600000001],
                    [-80.667957, 40.582496],
                    [-80.747112, 40.596991],
                    [-80.861994, 40.599404],
                    [-80.918125, 40.643464],
                    [-80.917172, 40.726934],
                    [-81.087289, 40.727816],
                    [-81.086686, 40.898858],
                ],
            ],
        },
        properties: {
            CSAFP: '566',
            CBSAFP: '41400',
            AFFGEOID: '310M600US41400',
            GEOID: '41400',
            NAME: 'Salem, OH',
            NAMELSAD: 'Salem, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1377658471,
            AWATER: 7277033,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.647874, 37.641618, -90.110537, 38.076548],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.645135, 37.734813],
                    [-90.63998, 38.076548],
                    [-90.628084, 38.007743],
                    [-90.416022, 38.042315],
                    [-90.32287, 37.981731],
                    [-90.459133, 37.878924],
                    [-90.200208, 37.669628],
                    [-90.110537, 37.67134],
                    [-90.146778, 37.641618],
                    [-90.535016, 37.643641],
                    [-90.647874, 37.642809],
                    [-90.645135, 37.734813],
                ],
            ],
        },
        properties: {
            CSAFP: '476',
            CBSAFP: '22100',
            AFFGEOID: '310M600US22100',
            GEOID: '22100',
            NAME: 'Farmington, MO',
            NAMELSAD: 'Farmington, MO Micro Area',
            LSAD: 'M2',
            ALAND: 1170380017,
            AWATER: 7218075,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-102.615447, 33.388489, -102.075929, 33.825121],
            type: 'Polygon',
            coordinates: [
                [
                    [-102.615447, 33.825121],
                    [-102.085733, 33.824675],
                    [-102.075929, 33.389586],
                    [-102.594836, 33.388489],
                    [-102.615447, 33.825121],
                ],
            ],
        },
        properties: {
            CSAFP: '352',
            CBSAFP: '30220',
            AFFGEOID: '310M600US30220',
            GEOID: '30220',
            NAME: 'Levelland, TX',
            NAMELSAD: 'Levelland, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2352723961,
            AWATER: 446119,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.881149, 40.818134, -83.420319, 41.167824],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.881149, 41.167824],
                    [-83.421054, 41.16678],
                    [-83.420319, 40.991889],
                    [-83.45785, 40.991671],
                    [-83.514728, 40.905115],
                    [-83.515883, 40.818134],
                    [-83.880063, 40.819919],
                    [-83.880423, 40.920429],
                    [-83.881149, 41.167824],
                ],
            ],
        },
        properties: {
            CSAFP: '534',
            CBSAFP: '22300',
            AFFGEOID: '310M600US22300',
            GEOID: '22300',
            NAME: 'Findlay, OH',
            NAMELSAD: 'Findlay, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1376124980,
            AWATER: 6021322,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.6485330311736, 43.49952, -93.0491920078165, 43.848443],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.64829, 43.848139],
                    [-93.406642, 43.84812],
                    [-93.049524, 43.848443],
                    [-93.0491920078165, 43.4995574800246],
                    [-93.22886100000001, 43.499567],
                    [-93.4973513252204, 43.4995307245117],
                    [-93.576728, 43.49952],
                    [-93.6485330311736, 43.4995354896751],
                    [-93.64829, 43.848139],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '10660',
            AFFGEOID: '310M600US10660',
            GEOID: '10660',
            NAME: 'Albert Lea, MN',
            NAMELSAD: 'Albert Lea, MN Micro Area',
            LSAD: 'M2',
            ALAND: 1831756446,
            AWATER: 39305226,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.82347300000002, 34.755604, -88.3635302685392, 34.996033],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.82305050163201, 34.995207045695],
                    [-88.786612, 34.995252],
                    [-88.469877, 34.996033],
                    [-88.3804925115221, 34.9957924082221],
                    [-88.3635302685392, 34.9957467517971],
                    [-88.365472, 34.755604],
                    [-88.718508, 34.756781],
                    [-88.753065, 34.75696000000001],
                    [-88.752956, 34.858775],
                    [-88.82347300000002, 34.858629],
                    [-88.82305050163201, 34.995207045695],
                ],
            ],
        },
        properties: {
            CSAFP: '539',
            CBSAFP: '18420',
            AFFGEOID: '310M600US18420',
            GEOID: '18420',
            NAME: 'Corinth, MS',
            NAMELSAD: 'Corinth, MS Micro Area',
            LSAD: 'M2',
            ALAND: 1036110409,
            AWATER: 3408033,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-106.434508, 39.361713, -105.77601, 39.924914],
            type: 'Polygon',
            coordinates: [
                [
                    [-106.434508, 39.924914],
                    [-106.25202700000001, 39.914712],
                    [-106.083711, 39.805964],
                    [-106.012987, 39.686341],
                    [-105.924618, 39.698972],
                    [-105.77601, 39.609267],
                    [-105.82966200000001, 39.564865000000005],
                    [-105.817631, 39.53831],
                    [-105.966786, 39.438035],
                    [-106.021966, 39.361713],
                    [-106.135529, 39.379546],
                    [-106.206732, 39.379636],
                    [-106.25163800000001, 39.465039],
                    [-106.176464, 39.635722],
                    [-106.385478, 39.767164],
                    [-106.434508, 39.924914],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '14720',
            AFFGEOID: '310M600US14720',
            GEOID: '14720',
            NAME: 'Breckenridge, CO',
            NAMELSAD: 'Breckenridge, CO Micro Area',
            LSAD: 'M2',
            ALAND: 1575563227,
            AWATER: 28310936,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.8028596219514, 40.684862, -84.340531, 40.99031500000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.8028596219514, 40.9893740520086],
                    [-84.399486, 40.99031500000001],
                    [-84.398407, 40.903265],
                    [-84.340531, 40.859099],
                    [-84.397374, 40.815941],
                    [-84.396778, 40.684926000000004],
                    [-84.45618, 40.684862],
                    [-84.456172, 40.728306],
                    [-84.8021190322638, 40.7281459368726],
                    [-84.80211900000002, 40.728163],
                    [-84.8026702061534, 40.922568713921],
                    [-84.8028596219514, 40.9893740520086],
                ],
            ],
        },
        properties: {
            CSAFP: '338',
            CBSAFP: '46780',
            AFFGEOID: '310M600US46780',
            GEOID: '46780',
            NAME: 'Van Wert, OH',
            NAMELSAD: 'Van Wert, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1059692855,
            AWATER: 3287921,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-109.046138935363, 34.578132, -107.06656200000002, 35.348314],
            type: 'Polygon',
            coordinates: [
                [
                    [-109.045851, 34.959718],
                    [-109.045851235183, 34.9598185158367],
                    [-108.46940200000002, 34.959081],
                    [-108.468683, 35.299243],
                    [-108.468715, 35.306658000000006],
                    [-107.734224, 35.304987],
                    [-107.73424100000001, 35.348145],
                    [-107.628313, 35.348314],
                    [-107.628788, 35.304333],
                    [-107.309386, 35.305687],
                    [-107.23889, 35.305507],
                    [-107.197244, 35.219459],
                    [-107.06656200000002, 34.95719],
                    [-107.202882, 34.957572],
                    [-107.201743, 34.578757],
                    [-107.691565, 34.579425],
                    [-107.724794, 34.578132],
                    [-109.046138935363, 34.5792910128179],
                    [-109.045851, 34.959718],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '24380',
            AFFGEOID: '310M600US24380',
            GEOID: '24380',
            NAME: 'Grants, NM',
            NAMELSAD: 'Grants, NM Micro Area',
            LSAD: 'M2',
            ALAND: 11758505167,
            AWATER: 5554598,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.443807, 31.873591000000005, -83.920102, 32.428047],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.433014, 32.04196],
                    [-84.431214, 32.134058],
                    [-84.430218, 32.166257],
                    [-84.429451, 32.232151],
                    [-84.389927, 32.297237],
                    [-84.392316, 32.414046],
                    [-84.381817, 32.428047],
                    [-84.254613, 32.372053],
                    [-84.255912, 32.296156],
                    [-84.22014400000002, 32.23115],
                    [-84.181657, 32.229703],
                    [-84.134736, 32.185392],
                    [-84.0276, 32.171165],
                    [-84.033494, 32.153078],
                    [-83.961278, 32.030595],
                    [-83.920102, 31.927242000000003],
                    [-83.922494, 31.909653000000002],
                    [-84.338143, 31.916190000000004],
                    [-84.338245, 31.873591000000005],
                    [-84.357615, 31.885156000000002],
                    [-84.443807, 31.967456000000002],
                    [-84.433014, 32.04196],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '11140',
            AFFGEOID: '310M600US11140',
            GEOID: '11140',
            NAME: 'Americus, GA',
            NAMELSAD: 'Americus, GA Micro Area',
            LSAD: 'M2',
            ALAND: 1682937488,
            AWATER: 27581090,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-106.41877800000002, 35.755818, -106.171852, 35.966327],
            type: 'Polygon',
            coordinates: [
                [
                    [-106.399469, 35.960092],
                    [-106.248161, 35.966327],
                    [-106.245649, 35.930742],
                    [-106.248257, 35.839178],
                    [-106.171852, 35.82776],
                    [-106.250499, 35.755818],
                    [-106.41877800000002, 35.819986],
                    [-106.399469, 35.960092],
                ],
            ],
        },
        properties: {
            CSAFP: '106',
            CBSAFP: '31060',
            AFFGEOID: '310M600US31060',
            GEOID: '31060',
            NAME: 'Los Alamos, NM',
            NAMELSAD: 'Los Alamos, NM Micro Area',
            LSAD: 'M2',
            ALAND: 282626748,
            AWATER: 208105,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.826491, 41.696089, -84.3604190413973, 42.073456],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.826491, 42.07246800000001],
                    [-84.709556, 42.070366],
                    [-84.363297, 42.073456],
                    [-84.3604190413973, 41.7069558826335],
                    [-84.3995487854742, 41.7059213574056],
                    [-84.438067, 41.704903],
                    [-84.806082, 41.696089],
                    [-84.805883, 41.760216],
                    [-84.8251282838768, 41.760199006351904],
                    [-84.826491, 42.07246800000001],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '25880',
            AFFGEOID: '310M600US25880',
            GEOID: '25880',
            NAME: 'Hillsdale, MI',
            NAMELSAD: 'Hillsdale, MI Micro Area',
            LSAD: 'M2',
            ALAND: 1549207166,
            AWATER: 22939100,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.45618, 40.35453400000001, -83.880039, 40.685957],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.45618, 40.684862],
                    [-84.396778, 40.684926000000004],
                    [-84.222799, 40.685957],
                    [-84.107787, 40.643069],
                    [-83.880194, 40.64469],
                    [-83.880039, 40.538694],
                    [-83.993867, 40.535174],
                    [-84.002372, 40.483115],
                    [-84.339137, 40.481076],
                    [-84.33860000000001, 40.379199],
                    [-84.43438700000002, 40.35453400000001],
                    [-84.455532, 40.363963],
                    [-84.455029, 40.451305],
                    [-84.455713, 40.567888],
                    [-84.45618, 40.684862],
                ],
            ],
        },
        properties: {
            CSAFP: '338',
            CBSAFP: '47540',
            AFFGEOID: '310M600US47540',
            GEOID: '47540',
            NAME: 'Wapakoneta, OH',
            NAMELSAD: 'Wapakoneta, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1039590217,
            AWATER: 1339586,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.120391964917, 36.4981931896223, -91.655607, 37.056248],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.11234600000002, 36.794231],
                    [-92.095841, 36.798439],
                    [-92.088708, 37.056248],
                    [-91.655607, 37.048925],
                    [-91.658111, 36.88872400000001],
                    [-91.674237, 36.787832],
                    [-91.6723336435146, 36.4992566570116],
                    [-91.985802, 36.498431],
                    [-92.120391964917, 36.4981931896223],
                    [-92.11234600000002, 36.794231],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '48460',
            AFFGEOID: '310M600US48460',
            GEOID: '48460',
            NAME: 'West Plains, MO',
            NAMELSAD: 'West Plains, MO Micro Area',
            LSAD: 'M2',
            ALAND: 2401376472,
            AWATER: 2920041,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.196091, 31.182056999999997, -85.789142, 31.617964],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.193951, 31.440072],
                    [-86.194784, 31.529949],
                    [-86.14395, 31.537675000000004],
                    [-86.145895, 31.617741],
                    [-85.789142, 31.617964],
                    [-85.791402, 31.196349],
                    [-86.125405, 31.182056999999997],
                    [-86.19347600000002, 31.192213],
                    [-86.196091, 31.410348000000003],
                    [-86.193951, 31.440072],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '21460',
            AFFGEOID: '310M600US21460',
            GEOID: '21460',
            NAME: 'Enterprise, AL',
            NAMELSAD: 'Enterprise, AL Micro Area',
            LSAD: 'M2',
            ALAND: 1758565928,
            AWATER: 3907189,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.428102, 44.9627521717603, -87.2534496287802, 45.986014],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.425302, 45.722426],
                    [-88.059246, 45.713063],
                    [-88.057010465949, 45.7849770301437],
                    [-88.048514, 45.782549],
                    [-87.995876, 45.795435],
                    [-87.96697, 45.764021],
                    [-87.879812, 45.754843],
                    [-87.8330494680353, 45.7227525753469],
                    [-87.697159, 45.72296],
                    [-87.696946, 45.898842],
                    [-87.617396, 45.898846],
                    [-87.617091, 45.986014],
                    [-87.367849, 45.985321],
                    [-87.325391, 45.898665],
                    [-87.327502, 45.55125],
                    [-87.2534496287802, 45.5501155908891],
                    [-87.288726, 45.501606],
                    [-87.350852, 45.407743],
                    [-87.465201, 45.273351000000005],
                    [-87.548964, 45.191591],
                    [-87.590208, 45.095264],
                    [-87.625748, 45.04515700000001],
                    [-87.630298, 44.976865],
                    [-87.696492, 44.974233],
                    [-87.7626384909711, 44.9627521717603],
                    [-87.944436, 44.993057],
                    [-88.12115200000001, 45.023581],
                    [-88.118879, 45.110197],
                    [-88.189247, 45.111844],
                    [-88.186355, 45.197974],
                    [-88.309063, 45.20158],
                    [-88.305811, 45.37461],
                    [-88.428102, 45.37701],
                    [-88.425302, 45.722426],
                ],
            ],
        },
        properties: {
            CSAFP: '361',
            CBSAFP: '31940',
            AFFGEOID: '310M600US31940',
            GEOID: '31940',
            NAME: 'Marinette, WI-MI',
            NAMELSAD: 'Marinette, WI-MI Micro Area',
            LSAD: 'M2',
            ALAND: 6328652698,
            AWATER: 1151675129,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-108.379302950094, 36.9995646863115, -107.481736999155, 37.639587],
            type: 'Polygon',
            coordinates: [
                [
                    [-108.290459, 37.145975],
                    [-108.290423, 37.223584],
                    [-108.219688, 37.287415],
                    [-108.197776, 37.354939],
                    [-108.105857, 37.382068],
                    [-108.038409, 37.451931],
                    [-108.022895, 37.590587],
                    [-107.970086, 37.639587],
                    [-107.48217900000002, 37.639501],
                    [-107.482131, 37.422673],
                    [-107.481736999155, 37.0000045803143],
                    [-108.000623, 37.000001],
                    [-108.379302950094, 36.9995646863115],
                    [-108.290459, 37.145975],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '20420',
            AFFGEOID: '310M600US20420',
            GEOID: '20420',
            NAME: 'Durango, CO',
            NAMELSAD: 'Durango, CO Micro Area',
            LSAD: 'M2',
            ALAND: 4376255277,
            AWATER: 25642579,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.4635168866811, 43.1183679687078, -85.790448, 43.4723296995067],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.4635168866811, 43.4723296995067],
                    [-86.039491, 43.467447],
                    [-86.038953, 43.293519],
                    [-85.790448, 43.293003],
                    [-85.790662, 43.205167],
                    [-85.908388, 43.20592],
                    [-85.907435, 43.118911],
                    [-86.2739282786714, 43.1183679687078],
                    [-86.316259, 43.195114],
                    [-86.407832, 43.338436],
                    [-86.448743, 43.432013],
                    [-86.4635168866811, 43.4723296995067],
                ],
            ],
        },
        properties: {
            CSAFP: '266',
            CBSAFP: '34740',
            AFFGEOID: '310M600US34740',
            GEOID: '34740',
            NAME: 'Muskegon, MI',
            NAMELSAD: 'Muskegon, MI Metro Area',
            LSAD: 'M1',
            ALAND: 1305130153,
            AWATER: 2476314283,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.683102, 38.686561, -86.275281, 38.992404],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.682853, 38.904697],
                    [-86.682277, 38.992404],
                    [-86.31747400000002, 38.99087],
                    [-86.280389, 38.991028],
                    [-86.275281, 38.763795],
                    [-86.310058, 38.733131],
                    [-86.308701, 38.688101],
                    [-86.683102, 38.686561],
                    [-86.682853, 38.904697],
                ],
            ],
        },
        properties: {
            CSAFP: '144',
            CBSAFP: '13260',
            AFFGEOID: '310M600US13260',
            GEOID: '13260',
            NAME: 'Bedford, IN',
            NAMELSAD: 'Bedford, IN Micro Area',
            LSAD: 'M2',
            ALAND: 1163282503,
            AWATER: 7207557,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-74.71158, 42.355017, -73.264957, 43.398041],
            type: 'Polygon',
            coordinates: [
                [
                    [-74.630631, 42.626674],
                    [-74.667512, 42.750710000000005],
                    [-74.648298, 42.829558],
                    [-74.454911, 42.77297900000001],
                    [-74.411384, 42.77649],
                    [-74.289446, 42.783805],
                    [-74.263314, 42.796534],
                    [-74.083883, 42.897354],
                    [-74.09298, 42.955868],
                    [-74.096897, 42.979378000000004],
                    [-74.097467, 42.982934],
                    [-74.140147, 43.253979],
                    [-74.1601, 43.371532],
                    [-73.884139, 43.398041],
                    [-73.825828, 43.305348],
                    [-73.835811, 43.253756],
                    [-73.767498, 43.222123],
                    [-73.739824, 43.265559],
                    [-73.594713, 43.305928],
                    [-73.577876, 43.056888],
                    [-73.635463, 42.94129],
                    [-73.430623, 42.95865],
                    [-73.273832807021, 42.9436317475265],
                    [-73.278673, 42.83341],
                    [-73.290944, 42.80192],
                    [-73.264957, 42.74594],
                    [-73.3070040761806, 42.6326534514115],
                    [-73.352527, 42.510002],
                    [-73.783721, 42.464231],
                    [-74.254303, 42.408207],
                    [-74.244692, 42.377159],
                    [-74.443506, 42.355017],
                    [-74.618895, 42.424389],
                    [-74.71158, 42.517799],
                    [-74.630631, 42.626674],
                ],
            ],
        },
        properties: {
            CSAFP: '104',
            CBSAFP: '10580',
            AFFGEOID: '310M600US10580',
            GEOID: '10580',
            NAME: 'Albany-Schenectady-Troy, NY',
            NAMELSAD: 'Albany-Schenectady-Troy, NY Metro Area',
            LSAD: 'M1',
            ALAND: 7282538753,
            AWATER: 172029594,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.05316283451, 43.499966, -95.452502, 43.84907],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.05232, 43.84907],
                    [-95.462427, 43.847911],
                    [-95.452502, 43.847953],
                    [-95.4544322386606, 43.50032208525],
                    [-95.486803, 43.500246],
                    [-95.834421, 43.499966],
                    [-95.8609462015723, 43.4999928849056],
                    [-96.05316283451, 43.5001877081575],
                    [-96.05232, 43.84907],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '49380',
            AFFGEOID: '310M600US49380',
            GEOID: '49380',
            NAME: 'Worthington, MN',
            NAMELSAD: 'Worthington, MN Micro Area',
            LSAD: 'M2',
            ALAND: 1852143783,
            AWATER: 19253011,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.532118, 32.690018, -84.122361, 32.993729],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.52702, 32.970548],
                    [-84.489708, 32.993729],
                    [-84.27014, 32.991011],
                    [-84.122361, 32.989576],
                    [-84.12334, 32.932184],
                    [-84.12427500000001, 32.849562],
                    [-84.124332, 32.800935],
                    [-84.202628, 32.690018],
                    [-84.235803, 32.738284],
                    [-84.286246, 32.747626],
                    [-84.380398, 32.779176],
                    [-84.506888, 32.881788],
                    [-84.526902, 32.914098],
                    [-84.532118, 32.960729],
                    [-84.52702, 32.970548],
                ],
            ],
        },
        properties: {
            CSAFP: '122',
            CBSAFP: '45580',
            AFFGEOID: '310M600US45580',
            GEOID: '45580',
            NAME: 'Thomaston, GA',
            NAMELSAD: 'Thomaston, GA Micro Area',
            LSAD: 'M2',
            ALAND: 837774876,
            AWATER: 10585407,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.485367, 39.550254, -83.647169, 40.200004],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.485367, 39.918491],
                    [-84.425902, 39.919622],
                    [-84.432575, 40.197037],
                    [-84.218658, 40.200004],
                    [-84.022919, 40.183945],
                    [-84.036069, 40.040182],
                    [-84.05101200000001, 39.87981],
                    [-84.053736, 39.850458],
                    [-83.939583, 39.843797],
                    [-83.829374, 39.797766],
                    [-83.647169, 39.773029],
                    [-83.65333, 39.716876],
                    [-83.670196, 39.550254],
                    [-83.976987, 39.569402],
                    [-84.11417400000002, 39.57823],
                    [-84.286152, 39.589581],
                    [-84.365232, 39.589493],
                    [-84.479213, 39.591024],
                    [-84.485367, 39.918491],
                ],
            ],
        },
        properties: {
            CSAFP: '212',
            CBSAFP: '19430',
            AFFGEOID: '310M600US19430',
            GEOID: '19430',
            NAME: 'Dayton-Kettering, OH',
            NAMELSAD: 'Dayton-Kettering, OH Metro Area',
            LSAD: 'M1',
            ALAND: 3318889605,
            AWATER: 22221073,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.251501, 40.99456, -87.526463234368, 41.298051602479205],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.251501, 41.114218],
                    [-88.2414, 41.128981],
                    [-88.244144, 41.20156600000001],
                    [-88.01181, 41.20550800000001],
                    [-88.01391900000002, 41.292447],
                    [-87.5267678862275, 41.298051602479205],
                    [-87.5266481580824, 41.1660899445116],
                    [-87.52652, 41.024837],
                    [-87.526463234368, 41.0103365222041],
                    [-88.13193500000001, 40.997777],
                    [-88.2473, 40.99456],
                    [-88.251501, 41.114218],
                ],
            ],
        },
        properties: {
            CSAFP: '176',
            CBSAFP: '28100',
            AFFGEOID: '310M600US28100',
            GEOID: '28100',
            NAME: 'Kankakee, IL',
            NAMELSAD: 'Kankakee, IL Metro Area',
            LSAD: 'M1',
            ALAND: 1752101352,
            AWATER: 12458978,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-75.997636, 40.418451, -74.769425, 41.131257],
            type: 'Polygon',
            coordinates: [
                [
                    [-75.985994, 40.917439],
                    [-75.732183, 41.008124],
                    [-75.767284, 41.093113],
                    [-75.692669, 41.131257],
                    [-75.649412, 41.122304],
                    [-75.49599, 40.987181],
                    [-75.57459, 40.95132300000001],
                    [-75.517953, 40.878192],
                    [-75.474193, 40.814746],
                    [-75.284039, 40.86604],
                    [-75.1232539442085, 40.9653055343877],
                    [-75.133086, 40.98017900000001],
                    [-75.070532, 41.01862],
                    [-75.0523586008006, 41.0326279973019],
                    [-75.01527100000001, 41.061215],
                    [-74.9923859951822, 41.0930282469934],
                    [-74.9830420742478, 41.1060175588568],
                    [-74.962665, 41.090527],
                    [-74.791392, 40.931349],
                    [-74.769425, 40.910934],
                    [-74.850513, 40.803956],
                    [-74.889819, 40.787729],
                    [-74.96804700000001, 40.711324],
                    [-74.983388, 40.705372],
                    [-75.145908, 40.62866],
                    [-75.1881998781252, 40.5926133666486],
                    [-75.1892364013559, 40.6090569611221],
                    [-75.258151, 40.582009],
                    [-75.333514, 40.537057],
                    [-75.409733, 40.487984],
                    [-75.484057, 40.418451],
                    [-75.529694, 40.446995],
                    [-75.891473, 40.67727],
                    [-75.807789, 40.707492],
                    [-75.757807, 40.735414],
                    [-75.886037, 40.816267],
                    [-75.997636, 40.913259],
                    [-75.985994, 40.917439],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '10900',
            AFFGEOID: '310M600US10900',
            GEOID: '10900',
            NAME: 'Allentown-Bethlehem-Easton, PA-NJ',
            NAMELSAD: 'Allentown-Bethlehem-Easton, PA-NJ Metro Area',
            LSAD: 'M1',
            ALAND: 3763207864,
            AWATER: 58188722,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.932071, 42.780821, -83.453364, 43.223131],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.932071, 43.220377],
                    [-83.69562100000002, 43.221422],
                    [-83.460733, 43.223131],
                    [-83.453364, 42.880432],
                    [-83.689384, 42.871263],
                    [-83.686493, 42.783263],
                    [-83.922516, 42.780821],
                    [-83.929079, 43.132782],
                    [-83.932071, 43.220377],
                ],
            ],
        },
        properties: {
            CSAFP: '220',
            CBSAFP: '22420',
            AFFGEOID: '310M600US22420',
            GEOID: '22420',
            NAME: 'Flint, MI',
            NAMELSAD: 'Flint, MI Metro Area',
            LSAD: 'M1',
            ALAND: 1649674148,
            AWATER: 32748548,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.8464852479618, 25.803330250874602, -80.872932, 26.517069],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.8464852479618, 26.3303720485028],
                    [-81.819019, 26.316245000000002],
                    [-81.746167, 26.316896],
                    [-81.6579, 26.317563],
                    [-81.659506, 26.421077],
                    [-81.56218, 26.422625000000004],
                    [-81.563763, 26.513331],
                    [-81.27176800000001, 26.517069],
                    [-81.268236, 26.253069],
                    [-80.87971, 26.259308],
                    [-80.872932, 25.979434],
                    [-80.873096, 25.805377],
                    [-81.4423076374621, 25.803330250874602],
                    [-81.472239, 25.81693],
                    [-81.614735, 25.893977],
                    [-81.6402369580568, 25.8775375792851],
                    [-81.672633, 25.856654],
                    [-81.727086, 25.907207],
                    [-81.757463, 26.000374],
                    [-81.808833, 26.152246],
                    [-81.84455500000001, 26.327712],
                    [-81.8464852479618, 26.3303720485028],
                ],
            ],
        },
        properties: {
            CSAFP: '163',
            CBSAFP: '34940',
            AFFGEOID: '310M600US34940',
            GEOID: '34940',
            NAME: 'Naples-Marco Island, FL',
            NAMELSAD: 'Naples-Marco Island, FL Metro Area',
            LSAD: 'M1',
            ALAND: 5171818340,
            AWATER: 1575203628,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.535028, 39.346115, -89.02545300000001, 39.826085],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.535028, 39.641138],
                    [-89.478473, 39.640842],
                    [-89.478923, 39.683964],
                    [-89.425051, 39.683696],
                    [-89.425535, 39.747676],
                    [-89.303122, 39.775813],
                    [-89.246532, 39.826085],
                    [-89.217473, 39.813666],
                    [-89.141967, 39.801273],
                    [-89.139125, 39.655131],
                    [-89.02568, 39.654183],
                    [-89.02545300000001, 39.346115],
                    [-89.139807, 39.348888],
                    [-89.53083600000001, 39.348864],
                    [-89.533655, 39.524592],
                    [-89.534998, 39.612226],
                    [-89.535028, 39.641138],
                ],
            ],
        },
        properties: {
            CSAFP: '522',
            CBSAFP: '45380',
            AFFGEOID: '310M600US45380',
            GEOID: '45380',
            NAME: 'Taylorville, IL',
            NAMELSAD: 'Taylorville, IL Micro Area',
            LSAD: 'M2',
            ALAND: 1837569688,
            AWATER: 16227346,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.202959, 34.567081, -87.2107585799823, 35.008028],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.202959, 35.008028],
                    [-88.000032, 35.005939],
                    [-87.9849160436989, 35.0059101243258],
                    [-87.851886, 35.005656],
                    [-87.625025, 35.003732],
                    [-87.6060978676533, 35.0035195262043],
                    [-87.2240536418757, 34.9992307419721],
                    [-87.216683, 34.999148],
                    [-87.2107585799823, 34.9990491809997],
                    [-87.214914, 34.816011],
                    [-87.278302, 34.773563],
                    [-87.260676, 34.758626],
                    [-87.297905, 34.750812],
                    [-87.344224, 34.796493],
                    [-87.42651, 34.800022],
                    [-87.44577, 34.650968],
                    [-87.529667, 34.567081],
                    [-88.1395594412474, 34.5816967458839],
                    [-88.134263, 34.62266],
                    [-88.097888, 34.892202],
                    [-88.154617, 34.922392],
                    [-88.200064, 34.995634],
                    [-88.202959, 35.008028],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '22520',
            AFFGEOID: '310M600US22520',
            GEOID: '22520',
            NAME: 'Florence-Muscle Shoals, AL',
            NAMELSAD: 'Florence-Muscle Shoals, AL Metro Area',
            LSAD: 'M1',
            ALAND: 3265892717,
            AWATER: 212764976,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.79133496196282, 41.7590510395575, -85.2921788113335, 42.071553],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.760186, 41.798814],
                    [-85.762943, 42.069327],
                    [-85.293626, 42.071553],
                    [-85.2921788113335, 41.75975527470181],
                    [-85.65975023799092, 41.759236686047],
                    [-85.79133496196282, 41.7590510395575],
                    [-85.760186, 41.798814],
                ],
            ],
        },
        properties: {
            CSAFP: '310',
            CBSAFP: '44780',
            AFFGEOID: '310M600US44780',
            GEOID: '44780',
            NAME: 'Sturgis, MI',
            NAMELSAD: 'Sturgis, MI Micro Area',
            LSAD: 'M2',
            ALAND: 1296650215,
            AWATER: 52801735,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.512743, 38.506321, -93.050453, 38.942837],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.51103, 38.55621],
                    [-93.49311, 38.899016],
                    [-93.497278, 38.92843],
                    [-93.496377, 38.942837],
                    [-93.050453, 38.928244],
                    [-93.059974, 38.693077],
                    [-93.067291, 38.529995],
                    [-93.290454, 38.535388],
                    [-93.291851, 38.506321],
                    [-93.512743, 38.51247600000001],
                    [-93.51103, 38.55621],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '42740',
            AFFGEOID: '310M600US42740',
            GEOID: '42740',
            NAME: 'Sedalia, MO',
            NAMELSAD: 'Sedalia, MO Micro Area',
            LSAD: 'M2',
            ALAND: 1766982902,
            AWATER: 10471760,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-77.671761, 39.944077, -76.535414, 40.658082],
            type: 'Polygon',
            coordinates: [
                [
                    [-77.671761, 40.289825],
                    [-77.540141, 40.399221],
                    [-77.182789, 40.582211],
                    [-76.949148, 40.628167],
                    [-76.919272, 40.603544],
                    [-76.701624, 40.658082],
                    [-76.535414, 40.555157],
                    [-76.67804, 40.474717000000005],
                    [-76.566339, 40.196644],
                    [-76.697664, 40.156342],
                    [-76.72162, 40.12007],
                    [-76.752683, 40.175803],
                    [-76.800585, 40.200111],
                    [-76.824696, 40.202319],
                    [-76.839468, 40.22161500000001],
                    [-76.859023, 40.226328],
                    [-76.902886, 40.22460300000001],
                    [-76.905875, 40.165723],
                    [-77.028784, 40.147797],
                    [-77.137425, 40.069945],
                    [-77.177403, 40.037434],
                    [-77.40360600000001, 39.994478],
                    [-77.471085, 39.944077],
                    [-77.454109, 39.972432],
                    [-77.532481, 40.048749],
                    [-77.614665, 40.198549],
                    [-77.602717, 40.22820000000001],
                    [-77.671761, 40.289825],
                ],
            ],
        },
        properties: {
            CSAFP: '276',
            CBSAFP: '25420',
            AFFGEOID: '310M600US25420',
            GEOID: '25420',
            NAME: 'Harrisburg-Carlisle, PA',
            NAMELSAD: 'Harrisburg-Carlisle, PA Metro Area',
            LSAD: 'M1',
            ALAND: 4200440520,
            AWATER: 109311464,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.664164, 35.318846, -86.234575, 35.697617],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.639436, 35.6859],
                    [-86.543116, 35.697617],
                    [-86.402726, 35.618571],
                    [-86.34194, 35.664259],
                    [-86.24516500000001, 35.631913],
                    [-86.234575, 35.45276],
                    [-86.25759, 35.412476],
                    [-86.422338, 35.318846],
                    [-86.525306, 35.354718],
                    [-86.599478, 35.36495],
                    [-86.618572, 35.367401],
                    [-86.664164, 35.491771],
                    [-86.639436, 35.6859],
                ],
            ],
        },
        properties: {
            CSAFP: '400',
            CBSAFP: '43180',
            AFFGEOID: '310M600US43180',
            GEOID: '43180',
            NAME: 'Shelbyville, TN',
            NAMELSAD: 'Shelbyville, TN Micro Area',
            LSAD: 'M2',
            ALAND: 1226719748,
            AWATER: 2980759,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.76626500000002, 35.26231, -95.049933, 35.856644],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.766114, 35.856284],
                    [-95.65041900000001, 35.856644],
                    [-95.589736, 35.76516],
                    [-95.360363, 35.822411],
                    [-95.265679, 35.813266],
                    [-95.127163, 35.812755],
                    [-95.127213, 35.638788],
                    [-95.132271, 35.526055],
                    [-95.085635, 35.461396],
                    [-95.049933, 35.458894],
                    [-95.170462, 35.310106],
                    [-95.240718, 35.26231],
                    [-95.34476600000002, 35.293035],
                    [-95.344799, 35.551751],
                    [-95.712953, 35.551738],
                    [-95.713081, 35.725807],
                    [-95.76626500000002, 35.72575],
                    [-95.766114, 35.856284],
                ],
            ],
        },
        properties: {
            CSAFP: '538',
            CBSAFP: '34780',
            AFFGEOID: '310M600US34780',
            GEOID: '34780',
            NAME: 'Muskogee, OK',
            NAMELSAD: 'Muskogee, OK Micro Area',
            LSAD: 'M2',
            ALAND: 2099505291,
            AWATER: 76120773,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.153331, 36.9986779687248, -96.524873, 37.476405],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.153331, 37.475544],
                    [-96.52569, 37.476405],
                    [-96.524873, 37.30273],
                    [-96.5255820442829, 36.9986779687248],
                    [-96.749838, 36.998988],
                    [-97.100652, 36.998998],
                    [-97.1477209669227, 36.9989723356215],
                    [-97.153331, 37.475544],
                ],
            ],
        },
        properties: {
            CSAFP: '556',
            CBSAFP: '49060',
            AFFGEOID: '310M600US49060',
            GEOID: '49060',
            NAME: 'Winfield, KS',
            NAMELSAD: 'Winfield, KS Micro Area',
            LSAD: 'M2',
            ALAND: 2915648171,
            AWATER: 17322935,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.665777, 31.326262999999997, -83.338728, 31.596466000000003],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.665777, 31.485135],
                    [-83.650566, 31.490338],
                    [-83.649384, 31.567977],
                    [-83.648972, 31.596466000000003],
                    [-83.50070200000002, 31.59399],
                    [-83.500951, 31.570117],
                    [-83.42084, 31.553564999999995],
                    [-83.338728, 31.475991],
                    [-83.369514, 31.465055000000003],
                    [-83.370888, 31.401142],
                    [-83.434492, 31.350361],
                    [-83.460644, 31.326262999999997],
                    [-83.512607, 31.327405],
                    [-83.65409, 31.330660999999996],
                    [-83.651764, 31.433918000000002],
                    [-83.665777, 31.485135],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '45700',
            AFFGEOID: '310M600US45700',
            GEOID: '45700',
            NAME: 'Tifton, GA',
            NAMELSAD: 'Tifton, GA Micro Area',
            LSAD: 'M2',
            ALAND: 675702302,
            AWATER: 20458311,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-120.00261484291302, 38.5355095158185, -119.31048499999999, 39.114009],
            type: 'Polygon',
            coordinates: [
                [
                    [-120.00261484291302, 39.11268937409581],
                    [-119.761213, 39.114009],
                    [-119.55038599999999, 39.086259],
                    [-119.310841, 39.084681],
                    [-119.31048499999999, 39.019389],
                    [-119.40185199999999, 38.983651],
                    [-119.43980500000002, 38.882088],
                    [-119.405969, 38.733543],
                    [-119.349893, 38.736227],
                    [-119.330366469799, 38.5355095158185],
                    [-119.585406144839, 38.7131509006712],
                    [-119.587679, 38.714734],
                    [-119.90431500000001, 38.933324],
                    [-120.00101400000001, 38.999574],
                    [-120.001975250867, 39.0674958744683],
                    [-120.00261484291302, 39.11268937409581],
                ],
            ],
        },
        properties: {
            CSAFP: '456',
            CBSAFP: '23820',
            AFFGEOID: '310M600US23820',
            GEOID: '23820',
            NAME: 'Gardnerville Ranchos, NV',
            NAMELSAD: 'Gardnerville Ranchos, NV Micro Area',
            LSAD: 'M2',
            ALAND: 1840147662,
            AWATER: 72317573,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-72.09014600000002, 43.009309, -71.236011, 43.598063],
            type: 'Polygon',
            coordinates: [
                [
                    [-72.053752, 43.441097],
                    [-72.011363, 43.436871],
                    [-71.93531, 43.52888],
                    [-71.892112, 43.574618],
                    [-71.864599, 43.598063],
                    [-71.81757, 43.544459],
                    [-71.727573, 43.563411],
                    [-71.60121, 43.435139],
                    [-71.549423, 43.452389],
                    [-71.471454, 43.411298],
                    [-71.349051, 43.346578],
                    [-71.236011, 43.284994],
                    [-71.247336, 43.275232],
                    [-71.262068, 43.250937],
                    [-71.280796, 43.218603],
                    [-71.317709, 43.162399],
                    [-71.356117, 43.102043],
                    [-71.402962, 43.041054],
                    [-71.392388, 43.009309],
                    [-71.512767, 43.049615],
                    [-71.517467, 43.068909],
                    [-71.641291, 43.054168],
                    [-71.667771, 43.152534],
                    [-71.73256, 43.139786],
                    [-71.810277, 43.124271],
                    [-71.8561, 43.115135],
                    [-71.913215, 43.207099],
                    [-72.009074, 43.187882],
                    [-72.09014600000002, 43.322553],
                    [-72.053752, 43.441097],
                ],
            ],
        },
        properties: {
            CSAFP: '148',
            CBSAFP: '18180',
            AFFGEOID: '310M600US18180',
            GEOID: '18180',
            NAME: 'Concord, NH',
            NAMELSAD: 'Concord, NH Micro Area',
            LSAD: 'M2',
            ALAND: 2416197730,
            AWATER: 57178203,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.068545, 32.233468, -97.615557, 32.558822],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.066836, 32.558822],
                    [-97.617066, 32.555484],
                    [-97.615557, 32.318617],
                    [-97.78276, 32.316493],
                    [-97.945625, 32.233468],
                    [-97.950265, 32.244263],
                    [-98.068545, 32.511626],
                    [-98.066836, 32.558822],
                ],
            ],
        },
        properties: {
            CSAFP: '206',
            CBSAFP: '24180',
            AFFGEOID: '310M600US24180',
            GEOID: '24180',
            NAME: 'Granbury, TX',
            NAMELSAD: 'Granbury, TX Micro Area',
            LSAD: 'M2',
            ALAND: 1089582268,
            AWATER: 41775455,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-121.279533, 39.005371, -120.00302891965701, 39.52692],
            type: 'Polygon',
            coordinates: [
                [
                    [-121.26613200000001, 39.272717],
                    [-121.12748100000002, 39.380237],
                    [-121.022085, 39.391558],
                    [-120.992703, 39.414392],
                    [-120.755729, 39.452663],
                    [-120.654561, 39.52692],
                    [-120.575594, 39.522291],
                    [-120.505445, 39.446117],
                    [-120.00302891965701, 39.4450457761605],
                    [-120.004795512304, 39.3164750119585],
                    [-120.036657, 39.316334],
                    [-120.32237800000001, 39.316427],
                    [-120.55460000000001, 39.315596],
                    [-120.72789299999998, 39.288081],
                    [-120.90899600000002, 39.170173],
                    [-121.067546, 39.005371],
                    [-121.137979, 39.03791100000001],
                    [-121.279533, 39.03461800000001],
                    [-121.26613200000001, 39.272717],
                ],
            ],
        },
        properties: {
            CSAFP: '472',
            CBSAFP: '46020',
            AFFGEOID: '310M600US46020',
            GEOID: '46020',
            NAME: 'Truckee-Grass Valley, CA',
            NAMELSAD: 'Truckee-Grass Valley, CA Micro Area',
            LSAD: 'M2',
            ALAND: 2480587301,
            AWATER: 41531993,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.79983400000002, 38.807508, -85.440054, 39.195617],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.79983400000002, 39.128897],
                    [-85.686783, 39.130859],
                    [-85.56632, 39.132761],
                    [-85.440054, 39.195617],
                    [-85.444897, 38.912998],
                    [-85.5382, 38.912217],
                    [-85.537431, 38.88278],
                    [-85.628855, 38.817583],
                    [-85.683839, 38.815406],
                    [-85.733191, 38.831812],
                    [-85.795357, 38.807508],
                    [-85.798804, 39.068536],
                    [-85.79983400000002, 39.128897],
                ],
            ],
        },
        properties: {
            CSAFP: '294',
            CBSAFP: '35860',
            AFFGEOID: '310M600US35860',
            GEOID: '35860',
            NAME: 'North Vernon, IN',
            NAMELSAD: 'North Vernon, IN Micro Area',
            LSAD: 'M2',
            ALAND: 975382324,
            AWATER: 4528274,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.640361, 40.898886, -92.179072, 41.162662000000005],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.640361, 41.161298],
                    [-92.410233, 41.161942],
                    [-92.179974, 41.162662000000005],
                    [-92.179072, 40.89972],
                    [-92.639091, 40.898886],
                    [-92.640361, 41.161298],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '36900',
            AFFGEOID: '310M600US36900',
            GEOID: '36900',
            NAME: 'Ottumwa, IA',
            NAMELSAD: 'Ottumwa, IA Micro Area',
            LSAD: 'M2',
            ALAND: 1118466714,
            AWATER: 10822775,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.056934, 35.0269348608162, -82.5777151484413, 35.422967],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.056934, 35.053864],
                    [-82.983216, 35.13192],
                    [-82.920881, 35.292037],
                    [-82.831628, 35.31859],
                    [-82.745139, 35.422967],
                    [-82.619248, 35.303708],
                    [-82.5777151484413, 35.1464753478475],
                    [-82.68604, 35.124545],
                    [-82.746431, 35.079131],
                    [-82.7617976610408, 35.0818284636457],
                    [-82.783283, 35.0856],
                    [-82.897499, 35.056021],
                    [-82.897559231248, 35.0560052045865],
                    [-83.0084105925124, 35.0269348608162],
                    [-83.056934, 35.053864],
                ],
            ],
        },
        properties: {
            CSAFP: '120',
            CBSAFP: '14820',
            AFFGEOID: '310M600US14820',
            GEOID: '14820',
            NAME: 'Brevard, NC',
            NAMELSAD: 'Brevard, NC Micro Area',
            LSAD: 'M2',
            ALAND: 979943598,
            AWATER: 5091023,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-70.485288, 43.90671, -69.993792, 44.487217],
            type: 'Polygon',
            coordinates: [
                [
                    [-70.485288, 44.060398],
                    [-70.391735, 44.153562],
                    [-70.325218, 44.208399],
                    [-70.238904, 44.458942],
                    [-70.199758, 44.47826200000001],
                    [-70.12939, 44.487217],
                    [-70.100491, 44.383854],
                    [-70.132595, 44.371382],
                    [-70.100255, 44.33462],
                    [-70.07426, 44.290154],
                    [-70.06049800000001, 44.191775],
                    [-69.993792, 44.180594],
                    [-70.026319, 44.133838000000004],
                    [-70.005471, 44.124519],
                    [-70.033264, 44.001344],
                    [-70.051824, 43.990982],
                    [-70.034238, 43.975661],
                    [-70.080839, 43.926458],
                    [-70.11586, 43.90671],
                    [-70.229244, 43.974064],
                    [-70.315374, 44.037807],
                    [-70.363874, 43.98644500000001],
                    [-70.480078, 44.032078],
                    [-70.485288, 44.060398],
                ],
            ],
        },
        properties: {
            CSAFP: '438',
            CBSAFP: '30340',
            AFFGEOID: '310M600US30340',
            GEOID: '30340',
            NAME: 'Lewiston-Auburn, ME',
            NAMELSAD: 'Lewiston-Auburn, ME Metro Area',
            LSAD: 'M1',
            ALAND: 1211993472,
            AWATER: 75543630,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.96347, 38.869704, -96.03557300000001, 39.566432],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.963177, 39.1323],
                    [-96.961219, 39.15112],
                    [-96.961693, 39.220076],
                    [-96.958719, 39.566432],
                    [-96.806544, 39.56642300000001],
                    [-96.580459, 39.56621],
                    [-96.238969, 39.56622],
                    [-96.035952, 39.5661],
                    [-96.03557300000001, 39.21652],
                    [-96.03566500000001, 39.144083],
                    [-96.03906, 39.126527],
                    [-96.083557, 39.193504],
                    [-96.233671, 39.212279],
                    [-96.331396, 39.158885],
                    [-96.389046, 39.172878],
                    [-96.390796, 39.043257],
                    [-96.501166, 39.043666],
                    [-96.501556, 38.869704],
                    [-96.890245, 38.870067],
                    [-96.92671000000001, 38.87035],
                    [-96.926209, 38.978941],
                    [-96.96347, 38.964901],
                    [-96.963177, 39.1323],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '31740',
            AFFGEOID: '310M600US31740',
            GEOID: '31740',
            NAME: 'Manhattan, KS',
            NAMELSAD: 'Manhattan, KS Metro Area',
            LSAD: 'M1',
            ALAND: 4752846851,
            AWATER: 137799136,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-94.61835100000002, 36.747818, -94.052313, 37.3641703270387],
            type: 'Polygon',
            coordinates: [
                [
                    [-94.61835100000002, 37.160211],
                    [-94.6177538916066, 37.338418],
                    [-94.6176676047008, 37.3641703270387],
                    [-94.08208300000001, 37.349291],
                    [-94.083833, 37.290851],
                    [-94.052313, 37.290078],
                    [-94.059211, 37.048127],
                    [-94.06274, 36.931775],
                    [-94.068898, 36.747818],
                    [-94.618307, 36.76656],
                    [-94.617964, 36.998905],
                    [-94.618102891103, 37.0567963908729],
                    [-94.61835100000002, 37.160211],
                ],
            ],
        },
        properties: {
            CSAFP: '309',
            CBSAFP: '27900',
            AFFGEOID: '310M600US27900',
            GEOID: '27900',
            NAME: 'Joplin, MO',
            NAMELSAD: 'Joplin, MO Metro Area',
            LSAD: 'M1',
            ALAND: 3271829831,
            AWATER: 11878888,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.637496, 42.7916166414677, -97.16036, 43.169978],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.637496, 43.16877],
                    [-97.399191, 43.169419],
                    [-97.160544, 43.169978],
                    [-97.160543, 43.083145],
                    [-97.16036, 42.811795],
                    [-97.1650703923738, 42.7916166414677],
                    [-97.213957, 42.820143],
                    [-97.237868, 42.853139],
                    [-97.302075, 42.86566],
                    [-97.341181, 42.855882],
                    [-97.417066, 42.865918],
                    [-97.452177, 42.846048],
                    [-97.484916876342, 42.8500032148679],
                    [-97.515948, 42.853752],
                    [-97.59926, 42.856229],
                    [-97.6354420904415, 42.8518090751855],
                    [-97.637496, 43.16877],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '49460',
            AFFGEOID: '310M600US49460',
            GEOID: '49460',
            NAME: 'Yankton, SD',
            NAMELSAD: 'Yankton, SD Micro Area',
            LSAD: 'M2',
            ALAND: 1349873577,
            AWATER: 28676615,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-77.834249, 35.008138, -77.391026, 35.426655],
            type: 'Polygon',
            coordinates: [
                [
                    [-77.818068, 35.357366],
                    [-77.806248, 35.368754],
                    [-77.697516, 35.377097],
                    [-77.676719, 35.347344],
                    [-77.475515, 35.426655],
                    [-77.426191, 35.349524],
                    [-77.391026, 35.339533],
                    [-77.431253, 35.32983],
                    [-77.473692, 35.228985],
                    [-77.527182, 35.243001],
                    [-77.507463, 35.172814],
                    [-77.601282, 35.071368],
                    [-77.731026, 35.008138],
                    [-77.746354, 35.030096],
                    [-77.769177, 35.145576],
                    [-77.834249, 35.177846],
                    [-77.818068, 35.357366],
                ],
            ],
        },
        properties: {
            CSAFP: '272',
            CBSAFP: '28820',
            AFFGEOID: '310M600US28820',
            GEOID: '28820',
            NAME: 'Kinston, NC',
            NAMELSAD: 'Kinston, NC Micro Area',
            LSAD: 'M2',
            ALAND: 1033630014,
            AWATER: 5900299,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-124.11519999999999, 44.276194, -123.59767699999999, 45.0449989594412],
            type: 'Polygon',
            coordinates: [
                [
                    [-124.11519999999999, 44.286486],
                    [-124.08440099999999, 44.415611],
                    [-124.083601, 44.501123],
                    [-124.06500799999999, 44.632504],
                    [-124.063406, 44.703177],
                    [-124.07406600000002, 44.798107],
                    [-124.063155, 44.835333],
                    [-124.023834, 44.949825],
                    [-124.010097209885, 45.0449989594412],
                    [-123.724663, 45.044432],
                    [-123.72500100000002, 44.739046],
                    [-123.704467, 44.721154],
                    [-123.602609, 44.721154],
                    [-123.59767699999999, 44.433109],
                    [-123.72007100000002, 44.433374],
                    [-123.71553800000001, 44.360458],
                    [-123.81652500000001, 44.315303],
                    [-123.775598, 44.283547],
                    [-123.941075, 44.282951],
                    [-123.961647, 44.277376],
                    [-124.114370074258, 44.276194],
                    [-124.11519999999999, 44.286486],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '35440',
            AFFGEOID: '310M600US35440',
            GEOID: '35440',
            NAME: 'Newport, OR',
            NAMELSAD: 'Newport, OR Micro Area',
            LSAD: 'M2',
            ALAND: 2540733361,
            AWATER: 553145239,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-104.102351, 30.766469999999998, -103.01104, 32.00037],
            type: 'Polygon',
            coordinates: [
                [
                    [-104.024521, 32.00001],
                    [-103.980213477381, 32.0000328513626],
                    [-103.722881979148, 32.0001655686764],
                    [-103.326501, 32.00037],
                    [-103.327538, 31.65142],
                    [-103.610887, 31.651801999999996],
                    [-103.509379, 31.625934],
                    [-103.44241000000001, 31.433752],
                    [-103.329941, 31.411997999999997],
                    [-103.261677, 31.427570000000003],
                    [-103.181225, 31.370245000000004],
                    [-103.01104, 31.371306],
                    [-103.585084, 30.766469999999998],
                    [-104.102351, 31.105203],
                    [-104.024521, 32.00001],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '37780',
            AFFGEOID: '310M600US37780',
            GEOID: '37780',
            NAME: 'Pecos, TX',
            NAMELSAD: 'Pecos, TX Micro Area',
            LSAD: 'M2',
            ALAND: 8557764372,
            AWATER: 37377839,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.291026, 35.52600700000001, -81.824122, 35.96005],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.275399, 35.704438],
                    [-82.257842, 35.698852],
                    [-82.193402, 35.735314],
                    [-82.13348400000001, 35.823309],
                    [-81.980337, 35.884716],
                    [-81.981954, 35.911361],
                    [-81.943188, 35.96005],
                    [-81.906652, 35.883376],
                    [-81.986885, 35.807066],
                    [-81.869388, 35.719624],
                    [-81.824122, 35.574983],
                    [-81.842468, 35.542263],
                    [-81.967619, 35.52600700000001],
                    [-82.16904900000002, 35.527811],
                    [-82.291026, 35.591322],
                    [-82.275399, 35.704438],
                ],
            ],
        },
        properties: {
            CSAFP: '120',
            CBSAFP: '32000',
            AFFGEOID: '310M600US32000',
            GEOID: '32000',
            NAME: 'Marion, NC',
            NAMELSAD: 'Marion, NC Micro Area',
            LSAD: 'M2',
            ALAND: 1139476688,
            AWATER: 13977729,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-121.47264800000002, 37.134774, -120.38761299999999, 38.077421],
            type: 'Polygon',
            coordinates: [
                [
                    [-121.471925, 37.481783],
                    [-121.241219, 37.664009],
                    [-121.11004900000002, 37.742128],
                    [-120.99569600000001, 37.760178],
                    [-120.920993, 37.737947],
                    [-120.92644900000002, 38.077421],
                    [-120.653274, 37.831858],
                    [-120.38761299999999, 37.633704],
                    [-120.38766999999999, 37.633364],
                    [-120.989811, 37.395871],
                    [-120.96381200000002, 37.346144],
                    [-121.226804, 37.134774],
                    [-121.28227099999998, 37.183675],
                    [-121.404636, 37.155989],
                    [-121.45905100000002, 37.282739],
                    [-121.40969300000002, 37.382013],
                    [-121.47264800000002, 37.48217],
                    [-121.471925, 37.481783],
                ],
            ],
        },
        properties: {
            CSAFP: '488',
            CBSAFP: '33700',
            AFFGEOID: '310M600US33700',
            GEOID: '33700',
            NAME: 'Modesto, CA',
            NAMELSAD: 'Modesto, CA Metro Area',
            LSAD: 'M1',
            ALAND: 3874667297,
            AWATER: 46351770,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.152206, 32.902641, -94.71994200000002, 33.389454],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.152206, 33.01345],
                    [-95.12613, 33.034581],
                    [-95.125451, 33.389454],
                    [-94.81271, 33.375577],
                    [-94.808783, 33.363636],
                    [-94.819828, 32.982886],
                    [-94.76885500000002, 32.925834],
                    [-94.71994200000002, 32.90450200000001],
                    [-95.15211, 32.902641],
                    [-95.150126, 33.003225],
                    [-95.152206, 33.01345],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '34420',
            AFFGEOID: '310M600US34420',
            GEOID: '34420',
            NAME: 'Mount Pleasant, TX',
            NAMELSAD: 'Mount Pleasant, TX Micro Area',
            LSAD: 'M2',
            ALAND: 1558942630,
            AWATER: 69840554,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-106.250499, 35.040003, -105.714419, 36.00232],
            type: 'Polygon',
            coordinates: [
                [
                    [-106.250499, 35.755818],
                    [-106.171852, 35.82776],
                    [-106.248257, 35.839178],
                    [-106.245649, 35.930742],
                    [-106.05434600000001, 35.930783],
                    [-106.071153, 36.001573],
                    [-105.716808, 36.00232],
                    [-105.717477, 35.978741],
                    [-105.72052900000001, 35.871185],
                    [-105.714419, 35.041605],
                    [-106.194713, 35.04025600000001],
                    [-106.243874, 35.040003],
                    [-106.244216, 35.215657],
                    [-106.245162, 35.238661],
                    [-106.248848, 35.443282],
                    [-106.250499, 35.755818],
                ],
            ],
        },
        properties: {
            CSAFP: '106',
            CBSAFP: '42140',
            AFFGEOID: '310M600US42140',
            GEOID: '42140',
            NAME: 'Santa Fe, NM',
            NAMELSAD: 'Santa Fe, NM Metro Area',
            LSAD: 'M1',
            ALAND: 4947861287,
            AWATER: 2643021,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.334234, 33.95321, -81.870084, 34.409676],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.334234, 34.34263],
                    [-82.246337, 34.409676],
                    [-82.168857, 34.330151],
                    [-82.08367, 34.299240000000005],
                    [-81.945019, 34.203148],
                    [-81.897154, 34.146307],
                    [-81.870084, 34.135357],
                    [-81.98991000000001, 33.997101],
                    [-82.008298, 33.961646],
                    [-82.046974, 33.95321],
                    [-82.04513, 33.983396],
                    [-82.20028600000002, 33.978597],
                    [-82.243651, 34.017886],
                    [-82.306243, 33.971584],
                    [-82.326947, 34.06412],
                    [-82.268843, 34.107127],
                    [-82.248241, 34.220551],
                    [-82.334234, 34.34263],
                ],
            ],
        },
        properties: {
            CSAFP: '273',
            CBSAFP: '24940',
            AFFGEOID: '310M600US24940',
            GEOID: '24940',
            NAME: 'Greenwood, SC',
            NAMELSAD: 'Greenwood, SC Micro Area',
            LSAD: 'M2',
            ALAND: 1180009081,
            AWATER: 21260069,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.039251, 35.438439, -90.286789, 35.9965128689775],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.037012, 35.708419],
                    [-91.032562, 35.885028],
                    [-90.872852, 35.880872],
                    [-90.851989, 35.967879],
                    [-90.387324, 35.964691],
                    [-90.36871800000002, 35.995812],
                    [-90.2890769140827, 35.9965128689775],
                    [-90.286789, 35.69991],
                    [-90.286926, 35.438439],
                    [-90.502392, 35.441647],
                    [-90.610796, 35.448796],
                    [-91.039251, 35.44295],
                    [-91.037012, 35.708419],
                ],
            ],
        },
        properties: {
            CSAFP: '308',
            CBSAFP: '27860',
            AFFGEOID: '310M600US27860',
            GEOID: '27860',
            NAME: 'Jonesboro, AR',
            NAMELSAD: 'Jonesboro, AR Metro Area',
            LSAD: 'M1',
            ALAND: 3795512495,
            AWATER: 28217367,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.840772, 38.085622, -95.945924, 38.739112],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.837654, 38.348636],
                    [-96.819509, 38.522449],
                    [-96.35378, 38.521657],
                    [-96.35261300000002, 38.739021],
                    [-95.945924, 38.739112],
                    [-95.949654, 38.695385],
                    [-95.950282, 38.434105],
                    [-95.949994, 38.259706],
                    [-95.95861, 38.246302],
                    [-95.958862, 38.170939],
                    [-96.357277, 38.17266],
                    [-96.358099, 38.085817],
                    [-96.522782, 38.08637],
                    [-96.840772, 38.085622],
                    [-96.837654, 38.348636],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '21380',
            AFFGEOID: '310M600US21380',
            GEOID: '21380',
            NAME: 'Emporia, KS',
            NAMELSAD: 'Emporia, KS Micro Area',
            LSAD: 'M2',
            ALAND: 4197156992,
            AWATER: 32652918,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.688486, 41.885549, -88.939732, 42.204241],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.688486, 42.199112],
                    [-89.396192, 42.201916],
                    [-89.173, 42.204241],
                    [-89.172803, 42.150417],
                    [-89.091831, 42.150681],
                    [-88.939732, 42.15232],
                    [-88.941279, 41.891752],
                    [-89.41454, 41.885549],
                    [-89.421901, 41.90493200000001],
                    [-89.62933, 41.901617],
                    [-89.685366, 41.93034],
                    [-89.688486, 42.199112],
                ],
            ],
        },
        properties: {
            CSAFP: '466',
            CBSAFP: '40300',
            AFFGEOID: '310M600US40300',
            GEOID: '40300',
            NAME: 'Rochelle, IL',
            NAMELSAD: 'Rochelle, IL Micro Area',
            LSAD: 'M2',
            ALAND: 1964903700,
            AWATER: 12378959,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.42909, 32.926141, -82.748311, 33.469178],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.42909, 33.185352],
                    [-83.363543, 33.168998],
                    [-83.274108, 33.187238],
                    [-83.253461, 33.25929],
                    [-83.145856, 33.310045],
                    [-83.16420700000002, 33.35503],
                    [-83.012853, 33.469178],
                    [-82.983657, 33.447677],
                    [-82.851954, 33.443543000000005],
                    [-82.824105, 33.427627],
                    [-82.755846, 33.25344],
                    [-82.748311, 33.238348],
                    [-82.855046, 33.196427],
                    [-82.888866, 33.138603],
                    [-83.00874, 33.080849],
                    [-83.052197, 33.080682],
                    [-83.049081, 32.98560500000001],
                    [-83.073648, 32.946562],
                    [-83.133847, 33.007248],
                    [-83.357685, 32.926141],
                    [-83.414997, 33.112833],
                    [-83.42909, 33.185352],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '33300',
            AFFGEOID: '310M600US33300',
            GEOID: '33300',
            NAME: 'Milledgeville, GA',
            NAMELSAD: 'Milledgeville, GA Micro Area',
            LSAD: 'M2',
            ALAND: 1890082630,
            AWATER: 42285655,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.131963, 42.071577, -83.539396, 42.435166],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.131136, 42.424567],
                    [-83.94465, 42.424149],
                    [-83.664808, 42.431179],
                    [-83.551907, 42.435166],
                    [-83.547548, 42.349217],
                    [-83.543728, 42.262462],
                    [-83.542102, 42.174344],
                    [-83.539396, 42.085598000000005],
                    [-83.773922, 42.08243],
                    [-84.131963, 42.071577],
                    [-84.131136, 42.424567],
                ],
            ],
        },
        properties: {
            CSAFP: '220',
            CBSAFP: '11460',
            AFFGEOID: '310M600US11460',
            GEOID: '11460',
            NAME: 'Ann Arbor, MI',
            NAMELSAD: 'Ann Arbor, MI Metro Area',
            LSAD: 'M1',
            ALAND: 1828539718,
            AWATER: 42523319,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.133013, 31.330048, -81.612054, 31.827393999999998],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.133013, 31.773404000000003],
                    [-82.08015, 31.827393999999998],
                    [-82.048582, 31.827075000000004],
                    [-81.982941, 31.784904000000004],
                    [-81.97554, 31.782707],
                    [-81.969052, 31.789324],
                    [-81.850624, 31.667671],
                    [-81.701774, 31.584922],
                    [-81.681892, 31.541810000000005],
                    [-81.663206, 31.538667],
                    [-81.612054, 31.469404999999995],
                    [-81.62429, 31.452982],
                    [-81.691977, 31.400545000000005],
                    [-81.731694, 31.330048],
                    [-81.801052, 31.363737],
                    [-81.923238, 31.345875000000003],
                    [-82.041129, 31.373721],
                    [-82.063387, 31.466362],
                    [-82.132794, 31.471262],
                    [-82.133013, 31.773404000000003],
                ],
            ],
        },
        properties: {
            CSAFP: '496',
            CBSAFP: '27700',
            AFFGEOID: '310M600US27700',
            GEOID: '27700',
            NAME: 'Jesup, GA',
            NAMELSAD: 'Jesup, GA Micro Area',
            LSAD: 'M2',
            ALAND: 1662287070,
            AWATER: 17768149,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.9657915095295, 42.0001496652537, -76.538349, 42.29372],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.965028, 42.278495],
                    [-76.733912, 42.29372],
                    [-76.732677, 42.248052],
                    [-76.642256, 42.23372100000001],
                    [-76.61930300000002, 42.282853],
                    [-76.538349, 42.281755],
                    [-76.563589, 42.152463],
                    [-76.5576987221493, 42.0001496652537],
                    [-76.558118, 42.000155],
                    [-76.9269253205306, 42.0007235735426],
                    [-76.9657915095295, 42.0007834917859],
                    [-76.965028, 42.278495],
                ],
            ],
        },
        properties: {
            CSAFP: '236',
            CBSAFP: '21300',
            AFFGEOID: '310M600US21300',
            GEOID: '21300',
            NAME: 'Elmira, NY',
            NAMELSAD: 'Elmira, NY Metro Area',
            LSAD: 'M1',
            ALAND: 1054988440,
            AWATER: 8875327,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.055753, 27.338264, -81.563533, 27.646645],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.054349, 27.646382],
                    [-81.563533, 27.646645],
                    [-81.564062, 27.340641],
                    [-82.055753, 27.338264],
                    [-82.054349, 27.646382],
                ],
            ],
        },
        properties: {
            CSAFP: '422',
            CBSAFP: '48100',
            AFFGEOID: '310M600US48100',
            GEOID: '48100',
            NAME: 'Wauchula, FL',
            NAMELSAD: 'Wauchula, FL Micro Area',
            LSAD: 'M2',
            ALAND: 1651297014,
            AWATER: 1472455,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.750747, 40.239071, -82.17854000000001, 40.573102],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.744931, 40.349603],
                    [-82.646503, 40.345076],
                    [-82.62360900000002, 40.549879],
                    [-82.374898, 40.550871],
                    [-82.336962, 40.555001],
                    [-82.220268, 40.568187],
                    [-82.17854000000001, 40.573102],
                    [-82.184277, 40.456382],
                    [-82.195911, 40.239071],
                    [-82.477273, 40.24581],
                    [-82.476114, 40.264703],
                    [-82.750747, 40.276996],
                    [-82.744931, 40.349603],
                ],
            ],
        },
        properties: {
            CSAFP: '198',
            CBSAFP: '34540',
            AFFGEOID: '310M600US34540',
            GEOID: '34540',
            NAME: 'Mount Vernon, OH',
            NAMELSAD: 'Mount Vernon, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1361036418,
            AWATER: 10721050,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.104427, 36.164462, -97.460792, 36.593637],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.104286, 36.593578],
                    [-97.462459, 36.593627],
                    [-97.461603, 36.593637],
                    [-97.460792, 36.164462],
                    [-97.675617, 36.164663],
                    [-98.103904, 36.164877],
                    [-98.104427, 36.463105],
                    [-98.104286, 36.593578],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '21420',
            AFFGEOID: '310M600US21420',
            GEOID: '21420',
            NAME: 'Enid, OK',
            NAMELSAD: 'Enid, OK Metro Area',
            LSAD: 'M1',
            ALAND: 2741521323,
            AWATER: 4075930,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.730424, 43.50055, -90.910653, 44.079665],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.730424, 43.846935],
                    [-91.2876556273671, 43.847065],
                    [-91.29100200000002, 43.852733],
                    [-91.357426, 43.917231],
                    [-91.4235686998173, 43.9842968803226],
                    [-91.322143, 43.984462],
                    [-91.315957, 44.060961],
                    [-91.151932, 44.079665],
                    [-91.136059, 44.07102],
                    [-90.973107, 44.070882],
                    [-90.912918, 44.071522],
                    [-90.910653, 43.725334000000004],
                    [-91.2570001560057, 43.7256587372224],
                    [-91.273252, 43.666623],
                    [-91.252926, 43.600363],
                    [-91.232812, 43.564842],
                    [-91.217706, 43.50055],
                    [-91.491042, 43.50069],
                    [-91.6108350005544, 43.5006878467793],
                    [-91.7302169978377, 43.5006857009461],
                    [-91.730424, 43.846935],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '29100',
            AFFGEOID: '310M600US29100',
            GEOID: '29100',
            NAME: 'La Crosse-Onalaska, WI-MN',
            NAMELSAD: 'La Crosse-Onalaska, WI-MN Metro Area',
            LSAD: 'M1',
            ALAND: 2600141798,
            AWATER: 116464391,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.898484, 41.7285328921536, -90.140613, 42.033548],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.898373, 42.033548],
                    [-90.1596824511227, 42.0330872575859],
                    [-90.140613, 41.995999],
                    [-90.156902, 41.938181],
                    [-90.1581530337095, 41.9298432273761],
                    [-90.165065, 41.883777],
                    [-90.181401, 41.844647],
                    [-90.180643, 41.811979],
                    [-90.2423682473972, 41.7827687259846],
                    [-90.248631, 41.779805],
                    [-90.310708, 41.742214],
                    [-90.3118568504162, 41.7285328921536],
                    [-90.471723, 41.773603],
                    [-90.676373, 41.761023],
                    [-90.898373, 41.771392],
                    [-90.898484, 41.946245],
                    [-90.898373, 42.033548],
                ],
            ],
        },
        properties: {
            CSAFP: '209',
            CBSAFP: '17540',
            AFFGEOID: '310M600US17540',
            GEOID: '17540',
            NAME: 'Clinton, IA',
            NAMELSAD: 'Clinton, IA Micro Area',
            LSAD: 'M2',
            ALAND: 1799934695,
            AWATER: 39410955,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.686783, 39.130859, -85.29654, 39.453275],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.684515, 39.350049],
                    [-85.63063, 39.350205],
                    [-85.629327, 39.45275],
                    [-85.297575, 39.453275],
                    [-85.29654, 39.268291],
                    [-85.440054, 39.195617],
                    [-85.56632, 39.132761],
                    [-85.686783, 39.130859],
                    [-85.684515, 39.350049],
                ],
            ],
        },
        properties: {
            CSAFP: '294',
            CBSAFP: '24700',
            AFFGEOID: '310M600US24700',
            GEOID: '24700',
            NAME: 'Greensburg, IN',
            NAMELSAD: 'Greensburg, IN Micro Area',
            LSAD: 'M2',
            ALAND: 964946132,
            AWATER: 1955432,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-105.049917, 37.734704, -104.053921, 38.522393],
            type: 'Polygon',
            coordinates: [
                [
                    [-105.049215, 38.257973],
                    [-104.940366, 38.258275],
                    [-104.941533, 38.519569],
                    [-104.05426, 38.522392],
                    [-104.053921, 38.522393],
                    [-104.058242, 38.146492],
                    [-104.061132, 37.734704],
                    [-104.351109, 37.817488],
                    [-104.646383, 37.900527],
                    [-105.013729, 37.881271],
                    [-105.049917, 37.915479],
                    [-105.049215, 38.257973],
                ],
            ],
        },
        properties: {
            CSAFP: '444',
            CBSAFP: '39380',
            AFFGEOID: '310M600US39380',
            GEOID: '39380',
            NAME: 'Pueblo, CO',
            NAMELSAD: 'Pueblo, CO Metro Area',
            LSAD: 'M1',
            ALAND: 6181242835,
            AWATER: 28941249,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.73674, 42.618615000000005, -76.265584, 43.4201755544656],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.73674, 42.970286],
                    [-76.713806, 43.024035],
                    [-76.702324, 43.080159],
                    [-76.7220025657128, 43.3375801959814],
                    [-76.68485600000001, 43.352691],
                    [-76.630774, 43.413356],
                    [-76.6172137325776, 43.4201755544656],
                    [-76.605012, 43.25357],
                    [-76.479224, 43.227519],
                    [-76.491941, 43.004964],
                    [-76.450738, 42.84576],
                    [-76.356974, 42.84945],
                    [-76.274673, 42.771257],
                    [-76.265584, 42.623588],
                    [-76.4584, 42.618615000000005],
                    [-76.666543, 42.623457],
                    [-76.731636, 42.72074],
                    [-76.73674, 42.970286],
                ],
            ],
        },
        properties: {
            CSAFP: '532',
            CBSAFP: '12180',
            AFFGEOID: '310M600US12180',
            GEOID: '12180',
            NAME: 'Auburn, NY',
            NAMELSAD: 'Auburn, NY Micro Area',
            LSAD: 'M2',
            ALAND: 1791201989,
            AWATER: 445696708,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.701624, 40.495952, -75.757807, 40.94974],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.691533, 40.66408400000001],
                    [-76.562175, 40.709007],
                    [-76.380334, 40.775445],
                    [-76.30717, 40.801809],
                    [-76.284611, 40.883588],
                    [-76.207827, 40.94974],
                    [-76.028006, 40.902313],
                    [-75.997636, 40.913259],
                    [-75.886037, 40.816267],
                    [-75.757807, 40.735414],
                    [-75.807789, 40.707492],
                    [-75.891473, 40.67727],
                    [-75.993212, 40.639712],
                    [-76.017294, 40.57424],
                    [-76.171624, 40.534947],
                    [-76.440181, 40.495952],
                    [-76.535414, 40.555157],
                    [-76.701624, 40.658082],
                    [-76.691533, 40.66408400000001],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '39060',
            AFFGEOID: '310M600US39060',
            GEOID: '39060',
            NAME: 'Pottsville, PA',
            NAMELSAD: 'Pottsville, PA Micro Area',
            LSAD: 'M2',
            ALAND: 2016509909,
            AWATER: 10820294,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-94.16470400000001, 41.862711, -93.231722, 42.20992],
            type: 'Polygon',
            coordinates: [
                [
                    [-94.16470400000001, 42.20992],
                    [-93.93158, 42.209908],
                    [-93.69839, 42.209339],
                    [-93.463043, 42.209302],
                    [-93.231722, 42.208886],
                    [-93.231858, 41.862711],
                    [-93.347933, 41.863104],
                    [-93.698032, 41.86337],
                    [-93.815721, 41.863419],
                    [-94.164428, 41.863243],
                    [-94.16470400000001, 42.20992],
                ],
            ],
        },
        properties: {
            CSAFP: '218',
            CBSAFP: '11180',
            AFFGEOID: '310M600US11180',
            GEOID: '11180',
            NAME: 'Ames, IA',
            NAMELSAD: 'Ames, IA Metro Area',
            LSAD: 'M1',
            ALAND: 2960433300,
            AWATER: 11006754,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.029258, 37.598863, -91.525245, 38.153519],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.021165, 38.010639],
                    [-91.895347, 38.051199],
                    [-91.632431, 38.050951],
                    [-91.630554, 38.153519],
                    [-91.534384, 38.152593],
                    [-91.525245, 38.135628],
                    [-91.528797, 37.788989],
                    [-91.806219, 37.791286],
                    [-91.809105, 37.598863],
                    [-92.029258, 37.602542],
                    [-92.021165, 38.010639],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '40620',
            AFFGEOID: '310M600US40620',
            GEOID: '40620',
            NAME: 'Rolla, MO',
            NAMELSAD: 'Rolla, MO Micro Area',
            LSAD: 'M2',
            ALAND: 1739919006,
            AWATER: 6567069,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-102.799086, 31.651276, -102.287048, 32.086991],
            type: 'Polygon',
            coordinates: [
                [
                    [-102.799086, 32.085795],
                    [-102.287048, 32.086991],
                    [-102.28734500000002, 31.651276],
                    [-102.31805, 31.651326999999995],
                    [-102.76724600000001, 31.651714],
                    [-102.798939, 31.651783],
                    [-102.799086, 32.085795],
                ],
            ],
        },
        properties: {
            CSAFP: '372',
            CBSAFP: '36220',
            AFFGEOID: '310M600US36220',
            GEOID: '36220',
            NAME: 'Odessa, TX',
            NAMELSAD: 'Odessa, TX Metro Area',
            LSAD: 'M1',
            ALAND: 2325442220,
            AWATER: 10255211,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.60754, 43.466006, -84.167318, 43.825902],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.60603700000001, 43.815365],
                    [-84.366676, 43.81356],
                    [-84.167318, 43.825902],
                    [-84.168127, 43.568899],
                    [-84.170576, 43.481969],
                    [-84.36987600000002, 43.466044],
                    [-84.60754, 43.466006],
                    [-84.60603700000001, 43.815365],
                ],
            ],
        },
        properties: {
            CSAFP: '474',
            CBSAFP: '33220',
            AFFGEOID: '310M600US33220',
            GEOID: '33220',
            NAME: 'Midland, MI',
            NAMELSAD: 'Midland, MI Metro Area',
            LSAD: 'M1',
            ALAND: 1340188403,
            AWATER: 27503345,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-122.68912100000001, 48.296009, -120.68851500000001, 48.657003],
            type: 'Polygon',
            coordinates: [
                [
                    [-122.68912100000001, 48.476849],
                    [-122.650307, 48.530156],
                    [-122.642597, 48.588339],
                    [-122.671345425667, 48.6452989985202],
                    [-122.607778, 48.626074],
                    [-122.56591500000002, 48.629913],
                    [-122.50148899999999, 48.64532],
                    [-122.21583600000001, 48.64569],
                    [-121.564067, 48.642332],
                    [-120.908358, 48.640901],
                    [-120.90835700000001, 48.657003],
                    [-120.751904, 48.657002],
                    [-120.78830700000002, 48.625115],
                    [-120.68851500000001, 48.575705],
                    [-120.70206899999998, 48.531589],
                    [-120.823947, 48.54503],
                    [-121.049714, 48.485399],
                    [-121.071589, 48.317802],
                    [-121.00165799999999, 48.296009],
                    [-121.57750100000001, 48.298967],
                    [-122.20127400000001, 48.297634],
                    [-122.45859600000001, 48.297703],
                    [-122.58717000000001, 48.416853],
                    [-122.666982528216, 48.4124669920443],
                    [-122.66533800000002, 48.416453],
                    [-122.68912100000001, 48.476849],
                ],
            ],
        },
        properties: {
            CSAFP: '500',
            CBSAFP: '34580',
            AFFGEOID: '310M600US34580',
            GEOID: '34580',
            NAME: 'Mount Vernon-Anacortes, WA',
            NAMELSAD: 'Mount Vernon-Anacortes, WA Metro Area',
            LSAD: 'M1',
            ALAND: 4481338199,
            AWATER: 483760601,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.4631408247758, 34.0808126526072, -85.005775, 34.587483],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.4631408247758, 34.2861909885055],
                    [-85.38735100000001, 34.286097],
                    [-85.334881, 34.369748],
                    [-85.180548, 34.435996],
                    [-85.107748, 34.587483],
                    [-85.069159, 34.587184],
                    [-85.104444, 34.404278],
                    [-85.005775, 34.392446],
                    [-85.023102, 34.347112],
                    [-85.046871, 34.096412],
                    [-85.257624, 34.100668],
                    [-85.4210731752288, 34.0808126526072],
                    [-85.429499, 34.125095],
                    [-85.4631408247758, 34.2861909885055],
                ],
            ],
        },
        properties: {
            CSAFP: '122',
            CBSAFP: '40660',
            AFFGEOID: '310M600US40660',
            GEOID: '40660',
            NAME: 'Rome, GA',
            NAMELSAD: 'Rome, GA Metro Area',
            LSAD: 'M1',
            ALAND: 1320404594,
            AWATER: 22414013,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.4210731752288, 33.899212, -84.922742, 34.100668],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.4210731752288, 34.0808126526072],
                    [-85.257624, 34.100668],
                    [-85.046871, 34.096412],
                    [-85.047046, 34.082881],
                    [-84.922742, 34.082497],
                    [-84.978683, 33.951393],
                    [-85.049833, 33.952635],
                    [-85.05031, 33.904488],
                    [-85.212791, 33.899212],
                    [-85.3866705737728, 33.9017014940033],
                    [-85.39887100000001, 33.964128],
                    [-85.4210731752288, 34.0808126526072],
                ],
            ],
        },
        properties: {
            CSAFP: '122',
            CBSAFP: '16340',
            AFFGEOID: '310M600US16340',
            GEOID: '16340',
            NAME: 'Cedartown, GA',
            NAMELSAD: 'Cedartown, GA Micro Area',
            LSAD: 'M2',
            ALAND: 803767094,
            AWATER: 4673256,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-99.598323, 38.696514, -99.0375, 39.133121],
            type: 'Polygon',
            coordinates: [
                [
                    [-99.591776, 39.132357],
                    [-99.047687, 39.133014],
                    [-99.0375, 39.133121],
                    [-99.042626, 38.696807],
                    [-99.585087, 38.696537],
                    [-99.598323, 38.696514],
                    [-99.591776, 39.132357],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '25700',
            AFFGEOID: '310M600US25700',
            GEOID: '25700',
            NAME: 'Hays, KS',
            NAMELSAD: 'Hays, KS Micro Area',
            LSAD: 'M2',
            ALAND: 2330762295,
            AWATER: 1197085,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.472073, 39.171118, -87.960179, 39.685973],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.472073, 39.651588],
                    [-88.063518, 39.652657],
                    [-87.966347, 39.685973],
                    [-87.960179, 39.481309],
                    [-88.013944, 39.48079],
                    [-88.012121, 39.378968],
                    [-88.007766, 39.173925],
                    [-88.360654, 39.171118],
                    [-88.470865, 39.171463],
                    [-88.470906, 39.215029],
                    [-88.47083, 39.374515],
                    [-88.470505, 39.447041],
                    [-88.472073, 39.651588],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '16660',
            AFFGEOID: '310M600US16660',
            GEOID: '16660',
            NAME: 'Charleston-Mattoon, IL',
            NAMELSAD: 'Charleston-Mattoon, IL Micro Area',
            LSAD: 'M2',
            ALAND: 2212408003,
            AWATER: 7188080,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-120.31506800000001, 35.789276, -119.47460699999999, 36.488835],
            type: 'Polygon',
            coordinates: [
                [
                    [-120.31506800000001, 35.907186],
                    [-119.95905800000001, 36.181747],
                    [-119.95922699999998, 36.400975],
                    [-119.754213, 36.40202300000001],
                    [-119.6664, 36.41888],
                    [-119.573194, 36.488835],
                    [-119.52828599999998, 36.487545],
                    [-119.528792, 36.401465],
                    [-119.474892, 36.400953],
                    [-119.47460699999999, 36.269025],
                    [-119.529358, 36.26985],
                    [-119.536161, 36.050782],
                    [-119.53812000000002, 35.789898],
                    [-120.19436900000001, 35.789363],
                    [-120.21397899999998, 35.789276],
                    [-120.27576, 35.905881],
                    [-120.31506800000001, 35.907186],
                ],
            ],
        },
        properties: {
            CSAFP: '260',
            CBSAFP: '25260',
            AFFGEOID: '310M600US25260',
            GEOID: '25260',
            NAME: 'Hanford-Corcoran, CA',
            NAMELSAD: 'Hanford-Corcoran, CA Metro Area',
            LSAD: 'M1',
            ALAND: 3602601923,
            AWATER: 3164290,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.836331, 30.454036880786003, -89.340716, 31.016026000000004],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.836331, 30.727197],
                    [-89.791745, 30.820387],
                    [-89.750073, 30.91293],
                    [-89.7281756901606, 31.002313887984105],
                    [-89.654038, 31.002502],
                    [-89.50134, 31.016026000000004],
                    [-89.42533700000001, 30.982452000000002],
                    [-89.34895, 31.010370000000005],
                    [-89.340716, 30.995366000000004],
                    [-89.340806, 30.909912000000002],
                    [-89.341446, 30.64772],
                    [-89.544232, 30.648367999999998],
                    [-89.54451, 30.516862],
                    [-89.647072, 30.502738000000004],
                    [-89.6999320828408, 30.454036880786003],
                    [-89.71249300000001, 30.477510000000002],
                    [-89.791664, 30.551524000000004],
                    [-89.821868, 30.644024000000005],
                    [-89.8261803591152, 30.668823270185204],
                    [-89.836331, 30.727197],
                ],
            ],
        },
        properties: {
            CSAFP: '406',
            CBSAFP: '38100',
            AFFGEOID: '310M600US38100',
            GEOID: '38100',
            NAME: 'Picayune, MS',
            NAMELSAD: 'Picayune, MS Micro Area',
            LSAD: 'M2',
            ALAND: 2101307249,
            AWATER: 20783013,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.190658, 37.644201, -81.607532, 38.025356],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.190658, 37.97477800000001],
                    [-81.98877600000002, 38.024884],
                    [-81.932507, 38.025356],
                    [-81.980248, 37.9865],
                    [-81.785641, 37.936404],
                    [-81.722136, 37.809507],
                    [-81.607532, 37.788709],
                    [-81.762137, 37.756308],
                    [-81.701734, 37.701409],
                    [-81.802937, 37.660508],
                    [-81.930238, 37.682011],
                    [-81.999828, 37.644201],
                    [-82.082648, 37.701565],
                    [-82.112411, 37.854039],
                    [-82.190658, 37.97477800000001],
                ],
            ],
        },
        properties: {
            CSAFP: '170',
            CBSAFP: '34350',
            AFFGEOID: '310M600US34350',
            GEOID: '34350',
            NAME: 'Mount Gay-Shamrock, WV',
            NAMELSAD: 'Mount Gay-Shamrock, WV Micro Area',
            LSAD: 'M2',
            ALAND: 1175120719,
            AWATER: 4748336,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.117918, 34.1164215264479, -90.549244, 34.645717],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.078621, 34.143942],
                    [-91.056337, 34.336652],
                    [-91.002721, 34.336362],
                    [-91.000316, 34.469386],
                    [-91.054775, 34.470829],
                    [-91.052011, 34.645717],
                    [-90.597057, 34.641339],
                    [-90.5748549582232, 34.6316539794501],
                    [-90.587224, 34.615732],
                    [-90.549244, 34.568101],
                    [-90.569347, 34.524867],
                    [-90.5694607451505, 34.5243442788972],
                    [-90.583717, 34.458829],
                    [-90.575336, 34.415152],
                    [-90.641398, 34.383869],
                    [-90.660404, 34.33576000000001],
                    [-90.729131, 34.364206],
                    [-90.765174, 34.342818],
                    [-90.752681, 34.289266],
                    [-90.812829, 34.279438],
                    [-90.839981, 34.23611400000001],
                    [-90.89456, 34.22438],
                    [-90.882701, 34.184364],
                    [-90.894385, 34.160953],
                    [-90.938064, 34.148754],
                    [-90.94408076019381, 34.1200652947774],
                    [-90.94484495223631, 34.1164215264479],
                    [-90.966069, 34.118606],
                    [-91.117918, 34.119039],
                    [-91.078621, 34.143942],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '25760',
            AFFGEOID: '310M600US25760',
            GEOID: '25760',
            NAME: 'Helena-West Helena, AR',
            NAMELSAD: 'Helena-West Helena, AR Micro Area',
            LSAD: 'M2',
            ALAND: 1788024160,
            AWATER: 95966884,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.026643, 34.9872198373549, -84.2865983787409, 35.353231],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.026643, 35.136171],
                    [-84.946339, 35.287717],
                    [-84.879507, 35.353231],
                    [-84.860164, 35.350074],
                    [-84.72314, 35.285471],
                    [-84.744441, 35.264223],
                    [-84.69783800000002, 35.253173],
                    [-84.703607, 35.241043],
                    [-84.667398, 35.268041],
                    [-84.495529, 35.285472000000006],
                    [-84.356923, 35.233676],
                    [-84.325722, 35.219377],
                    [-84.2865983787409, 35.2057582184959],
                    [-84.321869, 34.988408],
                    [-84.509052, 34.988033],
                    [-84.621483, 34.988329],
                    [-84.727434, 34.98802],
                    [-84.7758376712973, 34.9879372061493],
                    [-84.8104774668864, 34.9878779552292],
                    [-84.861314, 34.987791],
                    [-84.9769738065923, 34.9872198373549],
                    [-84.961146, 35.015175],
                    [-85.00847800000001, 35.09269],
                    [-85.026643, 35.136171],
                ],
            ],
        },
        properties: {
            CSAFP: '174',
            CBSAFP: '17420',
            AFFGEOID: '310M600US17420',
            GEOID: '17420',
            NAME: 'Cleveland, TN',
            NAMELSAD: 'Cleveland, TN Metro Area',
            LSAD: 'M1',
            ALAND: 1977111859,
            AWATER: 26866020,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.964535, 36.9992231442824, -95.52113, 37.386636],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.964535, 37.363579],
                    [-95.961605, 37.386636],
                    [-95.52556, 37.383979],
                    [-95.52113, 37.38399],
                    [-95.5224145523987, 36.9993197867829],
                    [-95.573598, 36.99931],
                    [-95.7867618707782, 36.999270917592],
                    [-95.928122, 36.99924500000001],
                    [-95.9642712321022, 36.9992231442824],
                    [-95.964399, 37.299232],
                    [-95.964535, 37.363579],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '17700',
            AFFGEOID: '310M600US17700',
            GEOID: '17700',
            NAME: 'Coffeyville, KS',
            NAMELSAD: 'Coffeyville, KS Micro Area',
            LSAD: 'M2',
            ALAND: 1666900085,
            AWATER: 20430012,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-101.623466, 35.624057, -101.085735, 36.055405],
            type: 'Polygon',
            coordinates: [
                [
                    [-101.623466, 36.055405],
                    [-101.085735, 36.055276],
                    [-101.086068, 35.625267],
                    [-101.62283100000002, 35.624057],
                    [-101.623466, 36.055405],
                ],
            ],
        },
        properties: {
            CSAFP: '108',
            CBSAFP: '14420',
            AFFGEOID: '310M600US14420',
            GEOID: '14420',
            NAME: 'Borger, TX',
            NAMELSAD: 'Borger, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2298408153,
            AWATER: 19507502,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.888334, 38.561269, -85.56998, 38.831812],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.888334, 38.734443],
                    [-85.869559, 38.726171],
                    [-85.804554, 38.779906],
                    [-85.795357, 38.807508],
                    [-85.733191, 38.831812],
                    [-85.683839, 38.815406],
                    [-85.683338, 38.736731],
                    [-85.57093, 38.678706],
                    [-85.56998, 38.606155],
                    [-85.719718, 38.60554],
                    [-85.793584, 38.604814],
                    [-85.847893, 38.561269],
                    [-85.860996, 38.575907],
                    [-85.885643, 38.575412],
                    [-85.888334, 38.734443],
                ],
            ],
        },
        properties: {
            CSAFP: '350',
            CBSAFP: '42500',
            AFFGEOID: '310M600US42500',
            GEOID: '42500',
            NAME: 'Scottsburg, IN',
            NAMELSAD: 'Scottsburg, IN Micro Area',
            LSAD: 'M2',
            ALAND: 493140245,
            AWATER: 6074951,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.794552, 30.044283000000004, -96.093165, 30.394718000000005],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.734469, 30.241229],
                    [-96.640883, 30.296786],
                    [-96.357407, 30.373318000000005],
                    [-96.299173, 30.379151999999998],
                    [-96.178661, 30.394718000000005],
                    [-96.15463, 30.330288000000003],
                    [-96.094404, 30.309371],
                    [-96.093165, 30.225187],
                    [-96.15901000000001, 30.206613],
                    [-96.191782, 30.13842],
                    [-96.146052, 30.070224],
                    [-96.160472, 30.053409000000002],
                    [-96.292849, 30.096150000000005],
                    [-96.62198, 30.044283000000004],
                    [-96.64722300000001, 30.145174],
                    [-96.794552, 30.160545],
                    [-96.734469, 30.241229],
                ],
            ],
        },
        properties: {
            CSAFP: '288',
            CBSAFP: '14780',
            AFFGEOID: '310M600US14780',
            GEOID: '14780',
            NAME: 'Brenham, TX',
            NAMELSAD: 'Brenham, TX Micro Area',
            LSAD: 'M2',
            ALAND: 1564855487,
            AWATER: 45471320,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.237316, 39.7192518022001, -80.5190843704566, 40.1870179733507],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.234053, 39.95127],
                    [-81.225924, 40.170075],
                    [-81.195889, 40.172138],
                    [-81.089702, 40.166998],
                    [-80.882892, 40.159495],
                    [-80.766269, 40.155111],
                    [-80.7025753036226, 40.1571413980097],
                    [-80.684173210708, 40.1870179733507],
                    [-80.664006, 40.178947],
                    [-80.5190843704566, 40.1596719951181],
                    [-80.51912, 40.01641],
                    [-80.5191607943145, 39.9622000525219],
                    [-80.519342, 39.721403],
                    [-80.8355216930075, 39.7192518022001],
                    [-80.869933, 39.763555],
                    [-80.824969, 39.801092],
                    [-80.824276, 39.847159],
                    [-80.8234381047859, 39.8500320873964],
                    [-81.237316, 39.86782800000001],
                    [-81.234053, 39.95127],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '48540',
            AFFGEOID: '310M600US48540',
            GEOID: '48540',
            NAME: 'Wheeling, WV-OH',
            NAMELSAD: 'Wheeling, WV-OH Metro Area',
            LSAD: 'M1',
            ALAND: 2443364018,
            AWATER: 49379187,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-123.26015100000001, 44.200071, -121.79389500000002, 44.794346],
            type: 'Polygon',
            coordinates: [
                [
                    [-123.26015100000001, 44.555148],
                    [-123.17088, 44.634245],
                    [-123.073574, 44.650495],
                    [-123.14902499999998, 44.720225],
                    [-123.143843, 44.748914],
                    [-123.04200899999998, 44.737719],
                    [-123.00684700000001, 44.686898],
                    [-122.956458, 44.721122],
                    [-122.782608, 44.794346],
                    [-122.690864, 44.775417],
                    [-122.620463, 44.789213],
                    [-122.56372700000001, 44.767975],
                    [-122.27667500000001, 44.74613500000001],
                    [-122.230559, 44.697867],
                    [-122.138702, 44.725179],
                    [-122.033006, 44.685691],
                    [-121.80001500000002, 44.683425],
                    [-121.79389500000002, 44.594056],
                    [-121.84688000000001, 44.49109],
                    [-121.843138, 44.39263],
                    [-121.841346, 44.285652],
                    [-121.799426, 44.25828],
                    [-122.32715, 44.252841],
                    [-122.38456100000002, 44.217063],
                    [-122.577019, 44.227881],
                    [-122.646812, 44.266618],
                    [-122.76273499999999, 44.290537],
                    [-122.865465, 44.287317],
                    [-122.903999, 44.25912900000001],
                    [-122.905755, 44.200736],
                    [-123.165428, 44.200071],
                    [-123.18038800000001, 44.283725],
                    [-123.245678, 44.332099],
                    [-123.214683, 44.507681000000005],
                    [-123.26015100000001, 44.555148],
                ],
            ],
        },
        properties: {
            CSAFP: '440',
            CBSAFP: '10540',
            AFFGEOID: '310M600US10540',
            GEOID: '10540',
            NAME: 'Albany-Lebanon, OR',
            NAMELSAD: 'Albany-Lebanon, OR Metro Area',
            LSAD: 'M1',
            ALAND: 5929198837,
            AWATER: 50052280,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.871421, 41.161066, -92.410233, 41.509548],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.871421, 41.508522],
                    [-92.756508, 41.508794],
                    [-92.411995, 41.509548],
                    [-92.410233, 41.161942],
                    [-92.640361, 41.161298],
                    [-92.869771, 41.161066],
                    [-92.871421, 41.508522],
                ],
            ],
        },
        properties: {
            CSAFP: '218',
            CBSAFP: '36820',
            AFFGEOID: '310M600US36820',
            GEOID: '36820',
            NAME: 'Oskaloosa, IA',
            NAMELSAD: 'Oskaloosa, IA Micro Area',
            LSAD: 'M2',
            ALAND: 1478498325,
            AWATER: 6594320,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.863059, 30.504369000000004, -95.32748, 31.058098],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.863059, 30.864125],
                    [-95.716508, 30.911405000000002],
                    [-95.616233, 30.910195999999996],
                    [-95.617872, 30.930418000000003],
                    [-95.434781, 31.058098],
                    [-95.434335, 30.937404999999995],
                    [-95.396697, 30.857706],
                    [-95.32748, 30.859547],
                    [-95.359156, 30.504369000000004],
                    [-95.598971, 30.509002000000002],
                    [-95.83024, 30.630284],
                    [-95.863059, 30.864125],
                ],
            ],
        },
        properties: {
            CSAFP: '288',
            CBSAFP: '26660',
            AFFGEOID: '310M600US26660',
            GEOID: '26660',
            NAME: 'Huntsville, TX',
            NAMELSAD: 'Huntsville, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2031126325,
            AWATER: 44745757,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.519425, 41.8495629905593, -79.6108357749867, 42.26986],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.519425, 41.977523],
                    [-80.329976, 42.036168],
                    [-80.154084, 42.114757],
                    [-80.136213, 42.149937],
                    [-80.088512, 42.173184],
                    [-80.020323, 42.163116],
                    [-79.923924, 42.207546],
                    [-79.844661, 42.235486],
                    [-79.761951, 42.26986],
                    [-79.762122, 42.131246],
                    [-79.761313, 41.998808],
                    [-79.6108357749867, 41.9985199041084],
                    [-79.612071, 41.850328],
                    [-80.5193967266905, 41.8495629905593],
                    [-80.519425, 41.977523],
                ],
            ],
        },
        properties: {
            CSAFP: '240',
            CBSAFP: '21500',
            AFFGEOID: '310M600US21500',
            GEOID: '21500',
            NAME: 'Erie, PA',
            NAMELSAD: 'Erie, PA Metro Area',
            LSAD: 'M1',
            ALAND: 2069174742,
            AWATER: 1966242081,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-73.5179187906382, 41.468815, -72.88706, 42.049638],
            type: 'Polygon',
            coordinates: [
                [
                    [-73.5179187906382, 41.66672053071],
                    [-73.505008, 41.823773],
                    [-73.487314, 42.049638],
                    [-73.231056, 42.044945],
                    [-73.1272291708784, 42.0421231483548],
                    [-73.053397149764, 42.0401165089147],
                    [-73.0087627816307, 42.0389034162467],
                    [-73.029537, 41.966606],
                    [-72.88706, 41.973121],
                    [-72.905945, 41.921696],
                    [-72.949024, 41.806431],
                    [-73.016936, 41.79818300000001],
                    [-72.99895, 41.714398],
                    [-72.99855, 41.712498],
                    [-72.98325, 41.639598],
                    [-73.058024, 41.60674],
                    [-73.05801, 41.588647],
                    [-73.145036, 41.557822],
                    [-73.15573, 41.515279],
                    [-73.204197, 41.508527],
                    [-73.252265, 41.512134],
                    [-73.320519, 41.503981],
                    [-73.310473, 41.468815],
                    [-73.386964, 41.514794],
                    [-73.441466, 41.491294],
                    [-73.506923, 41.665802],
                    [-73.5179187906382, 41.66672053071],
                ],
            ],
        },
        properties: {
            CSAFP: '408',
            CBSAFP: '45860',
            AFFGEOID: '310M600US45860',
            GEOID: '45860',
            NAME: 'Torrington, CT',
            NAMELSAD: 'Torrington, CT Micro Area',
            LSAD: 'M2',
            ALAND: 2384123146,
            AWATER: 62350378,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-115.038109752434, 41.9942138857579, -113.931687, 42.914925],
            type: 'Polygon',
            coordinates: [
                [
                    [-115.037697, 42.768414],
                    [-115.037774, 42.911864],
                    [-114.960322, 42.914925],
                    [-114.90163600000001, 42.859108000000006],
                    [-114.933622, 42.766931],
                    [-114.85780699999998, 42.75530100000001],
                    [-114.812167, 42.664415],
                    [-114.61648699999999, 42.649298],
                    [-114.617293, 42.839761],
                    [-114.594375, 42.850901],
                    [-114.365366, 42.85079],
                    [-114.345659, 42.809055],
                    [-114.049849, 42.808444],
                    [-114.04996100000001, 42.76500600000001],
                    [-113.931687, 42.765026],
                    [-113.931799, 42.535243],
                    [-114.000388, 42.526373],
                    [-114.067073, 42.514453],
                    [-114.069162, 42.417124],
                    [-114.285448, 42.417333],
                    [-114.28179946722, 41.9942138857579],
                    [-114.281855, 41.994214],
                    [-114.598267, 41.994511],
                    [-114.89921000000001, 41.999909],
                    [-115.038109752434, 41.9986341156765],
                    [-115.037697, 42.768414],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '46300',
            AFFGEOID: '310M600US46300',
            GEOID: '46300',
            NAME: 'Twin Falls, ID',
            NAMELSAD: 'Twin Falls, ID Metro Area',
            LSAD: 'M1',
            ALAND: 6524845859,
            AWATER: 30969556,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.777143, 42.493587, -88.304692, 42.842996],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.777076, 42.842694],
                    [-88.69812, 42.842634],
                    [-88.541535, 42.842996],
                    [-88.306384, 42.842095],
                    [-88.305891, 42.610817000000004],
                    [-88.304692, 42.4956081727515],
                    [-88.506912, 42.494883],
                    [-88.70738, 42.493587],
                    [-88.7074208350474, 42.4935873247155],
                    [-88.7765924457953, 42.4941373692957],
                    [-88.777143, 42.833856],
                    [-88.777076, 42.842694],
                ],
            ],
        },
        properties: {
            CSAFP: '376',
            CBSAFP: '48580',
            AFFGEOID: '310M600US48580',
            GEOID: '48580',
            NAME: 'Whitewater, WI',
            NAMELSAD: 'Whitewater, WI Micro Area',
            LSAD: 'M2',
            ALAND: 1438448435,
            AWATER: 54928965,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.112737, 40.702915, -82.724792, 40.996624],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.112737, 40.993447],
                    [-82.855614, 40.993666],
                    [-82.829512, 40.996624],
                    [-82.724792, 40.995638],
                    [-82.727164, 40.711203],
                    [-82.838905, 40.7126],
                    [-82.858302, 40.705019],
                    [-83.11136, 40.702915],
                    [-83.112737, 40.993447],
                ],
            ],
        },
        properties: {
            CSAFP: '360',
            CBSAFP: '15340',
            AFFGEOID: '310M600US15340',
            GEOID: '15340',
            NAME: 'Bucyrus-Galion, OH',
            NAMELSAD: 'Bucyrus-Galion, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1040619651,
            AWATER: 2359514,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.462459, 36.593627, -96.749838, 36.998998],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.4623460298641, 36.9988242387969],
                    [-97.384925, 36.998843],
                    [-97.1477209669227, 36.9989723356215],
                    [-97.100652, 36.998998],
                    [-96.749838, 36.998988],
                    [-96.752389, 36.782091],
                    [-96.889475, 36.75123],
                    [-96.931943, 36.686097],
                    [-97.064107, 36.684207],
                    [-97.057556, 36.593872],
                    [-97.461603, 36.593637],
                    [-97.462459, 36.593627],
                    [-97.4623460298641, 36.9988242387969],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '38620',
            AFFGEOID: '310M600US38620',
            GEOID: '38620',
            NAME: 'Ponca City, OK',
            NAMELSAD: 'Ponca City, OK Micro Area',
            LSAD: 'M2',
            ALAND: 2381745586,
            AWATER: 65578499,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.056053, 40.243235000000006, -78.348668, 40.725239],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.998614, 40.355878],
                    [-78.974025, 40.394902],
                    [-78.973814, 40.395421],
                    [-78.816853, 40.703458],
                    [-78.801726, 40.724618],
                    [-78.402899, 40.725239],
                    [-78.348668, 40.723443],
                    [-78.439584, 40.645034],
                    [-78.441208, 40.597239],
                    [-78.539819, 40.506293],
                    [-78.561466, 40.36967],
                    [-78.620383, 40.327025000000006],
                    [-78.651743, 40.281524],
                    [-78.658305, 40.243235000000006],
                    [-78.884669, 40.244575],
                    [-78.903879, 40.275246],
                    [-78.927109, 40.284733],
                    [-79.056053, 40.284861],
                    [-78.998614, 40.355878],
                ],
            ],
        },
        properties: {
            CSAFP: '306',
            CBSAFP: '27780',
            AFFGEOID: '310M600US27780',
            GEOID: '27780',
            NAME: 'Johnstown, PA',
            NAMELSAD: 'Johnstown, PA Metro Area',
            LSAD: 'M1',
            ALAND: 1780643391,
            AWATER: 13523237,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.896209, 31.796191000000004, -96.05478, 32.328929],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.896209, 32.073977000000006],
                    [-96.38291, 32.328929],
                    [-96.196965, 32.206942],
                    [-96.146523, 32.211715],
                    [-96.105364, 32.075337],
                    [-96.05478, 32.012526],
                    [-96.49671300000001, 31.796191000000004],
                    [-96.719114, 31.814887],
                    [-96.896209, 32.073977000000006],
                ],
            ],
        },
        properties: {
            CSAFP: '206',
            CBSAFP: '18620',
            AFFGEOID: '310M600US18620',
            GEOID: '18620',
            NAME: 'Corsicana, TX',
            NAMELSAD: 'Corsicana, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2615116305,
            AWATER: 197615409,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.007454, 39.223044, -83.578194, 39.569402],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.007454, 39.255112],
                    [-83.976987, 39.569402],
                    [-83.670196, 39.550254],
                    [-83.585523, 39.545109],
                    [-83.578194, 39.524033],
                    [-83.590878, 39.378736],
                    [-83.784793, 39.262888],
                    [-83.813873, 39.223044],
                    [-83.865679, 39.247333],
                    [-83.989921, 39.254096],
                    [-84.007454, 39.255112],
                ],
            ],
        },
        properties: {
            CSAFP: '178',
            CBSAFP: '48940',
            AFFGEOID: '310M600US48940',
            GEOID: '48940',
            NAME: 'Wilmington, OH',
            NAMELSAD: 'Wilmington, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1058609496,
            AWATER: 9329331,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.28966300000002, 39.1810347474059, -81.7214681391725, 39.556574],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.28041, 39.472906],
                    [-82.167379, 39.466307],
                    [-82.159161, 39.556574],
                    [-82.04752800000001, 39.55082500000001],
                    [-82.055779, 39.460053],
                    [-81.844863, 39.450216],
                    [-81.853668, 39.318165],
                    [-81.818361, 39.272581],
                    [-81.721808, 39.269597],
                    [-81.7214681391725, 39.2109608092037],
                    [-81.752754, 39.184676],
                    [-81.7522970367401, 39.1810347474059],
                    [-81.960882, 39.188744],
                    [-82.268583, 39.20376],
                    [-82.260243, 39.292915],
                    [-82.28966300000002, 39.384195],
                    [-82.28041, 39.472906],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '11900',
            AFFGEOID: '310M600US11900',
            GEOID: '11900',
            NAME: 'Athens, OH',
            NAMELSAD: 'Athens, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1304431981,
            AWATER: 12361693,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.764697, 42.069327, -85.293626, 42.421433],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.764697, 42.420715],
                    [-85.543191, 42.421433],
                    [-85.29887900000001, 42.419849],
                    [-85.293626, 42.071553],
                    [-85.762943, 42.069327],
                    [-85.764697, 42.420715],
                ],
            ],
        },
        properties: {
            CSAFP: '310',
            CBSAFP: '28020',
            AFFGEOID: '310M600US28020',
            GEOID: '28020',
            NAME: 'Kalamazoo-Portage, MI',
            NAMELSAD: 'Kalamazoo-Portage, MI Metro Area',
            LSAD: 'M1',
            ALAND: 1455456980,
            AWATER: 47508422,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-99.47565, 33.834005, -98.9523248875282, 34.442099],
            type: 'Polygon',
            coordinates: [
                [
                    [-99.47529400000002, 34.085618],
                    [-99.4750221370384, 34.3968709897905],
                    [-99.470969, 34.396471],
                    [-99.420432, 34.380464],
                    [-99.394956, 34.442099],
                    [-99.350407, 34.437083],
                    [-99.27534, 34.386599],
                    [-99.2216079008455, 34.3253735743726],
                    [-99.2116, 34.313970000000005],
                    [-99.189511, 34.214312],
                    [-99.131553, 34.209352],
                    [-99.0588, 34.201256],
                    [-99.002916, 34.208782],
                    [-98.9523248875282, 34.2046698986035],
                    [-98.953202, 33.834025000000004],
                    [-99.47565, 33.834005],
                    [-99.47529400000002, 34.085618],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '46900',
            AFFGEOID: '310M600US46900',
            GEOID: '46900',
            NAME: 'Vernon, TX',
            NAMELSAD: 'Vernon, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2514738100,
            AWATER: 18202665,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.406642, 43.84812, -93.04591, 44.196716],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.406565, 44.196377],
                    [-93.162285, 44.196568000000006],
                    [-93.04591, 44.196716],
                    [-93.045966, 43.848467],
                    [-93.049524, 43.848443],
                    [-93.406642, 43.84812],
                    [-93.406565, 44.196377],
                ],
            ],
        },
        properties: {
            CSAFP: '378',
            CBSAFP: '36940',
            AFFGEOID: '310M600US36940',
            GEOID: '36940',
            NAME: 'Owatonna, MN',
            NAMELSAD: 'Owatonna, MN Micro Area',
            LSAD: 'M2',
            ALAND: 1112775704,
            AWATER: 6976791,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.864908, 37.72855, -83.96631, 38.492917],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.864804, 38.141373],
                    [-84.725168, 38.195426],
                    [-84.73098900000001, 38.216338],
                    [-84.740594, 38.352415],
                    [-84.705988, 38.370102],
                    [-84.580539, 38.473039],
                    [-84.557375, 38.492917],
                    [-84.474265, 38.400169],
                    [-84.432841, 38.299569],
                    [-84.442661, 38.283236],
                    [-84.333846, 38.288751000000005],
                    [-84.306169, 38.3131],
                    [-84.281844, 38.312461],
                    [-84.19405, 38.37175],
                    [-84.095324, 38.259014],
                    [-83.969281, 38.20933],
                    [-83.977924, 38.192118],
                    [-83.992845, 38.173364],
                    [-84.080527, 38.115118],
                    [-83.96631, 37.930768],
                    [-84.02737, 37.869454],
                    [-84.001967, 37.837592],
                    [-84.075107, 37.82327200000001],
                    [-84.080337, 37.853066],
                    [-84.261183, 37.875986],
                    [-84.272406, 37.91754600000001],
                    [-84.337393, 37.892007],
                    [-84.386165, 37.84549],
                    [-84.43572, 37.847202],
                    [-84.476283, 37.853131],
                    [-84.46203100000001, 37.803431],
                    [-84.525643, 37.769497],
                    [-84.571158, 37.72855],
                    [-84.656608, 37.809908],
                    [-84.65690300000001, 37.783921],
                    [-84.716925, 37.815389],
                    [-84.689021, 37.851091],
                    [-84.709918, 37.861952],
                    [-84.765204, 37.84783500000001],
                    [-84.826454, 37.916458],
                    [-84.796327, 37.970088],
                    [-84.84977900000001, 38.047985],
                    [-84.830915, 38.093605],
                    [-84.864908, 38.11692600000001],
                    [-84.864804, 38.141373],
                ],
            ],
        },
        properties: {
            CSAFP: '336',
            CBSAFP: '30460',
            AFFGEOID: '310M600US30460',
            GEOID: '30460',
            NAME: 'Lexington-Fayette, KY',
            NAMELSAD: 'Lexington-Fayette, KY Metro Area',
            LSAD: 'M1',
            ALAND: 3807095598,
            AWATER: 37135548,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.453233, 33.214478, -89.64528, 33.81139],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.451643, 33.81139],
                    [-90.182584, 33.809322],
                    [-90.185599, 33.765514],
                    [-90.294502, 33.750981],
                    [-90.299057, 33.697589],
                    [-90.239603, 33.737246],
                    [-90.136787, 33.721648],
                    [-90.127775, 33.676956],
                    [-89.78689, 33.676727],
                    [-89.785227, 33.416275],
                    [-89.646314, 33.402544],
                    [-89.64528, 33.285515],
                    [-89.701514, 33.285359],
                    [-89.749441, 33.214478],
                    [-90.174614, 33.333378],
                    [-90.321513, 33.375121],
                    [-90.279627, 33.339509],
                    [-90.331589, 33.302825],
                    [-90.413236, 33.23532],
                    [-90.453233, 33.329484],
                    [-90.451643, 33.81139],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '24900',
            AFFGEOID: '310M600US24900',
            GEOID: '24900',
            NAME: 'Greenwood, MS',
            NAMELSAD: 'Greenwood, MS Micro Area',
            LSAD: 'M2',
            ALAND: 3166162826,
            AWATER: 47918753,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.517344, 32.493053, -85.593151, 33.10734],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.517344, 33.02056600000001],
                    [-86.511142, 33.088431],
                    [-86.491029, 33.102944],
                    [-86.17437, 33.104394],
                    [-86.010919, 33.104636],
                    [-86.00917, 33.09026],
                    [-85.974715, 33.105286],
                    [-85.653654, 33.106634],
                    [-85.593177, 33.10734],
                    [-85.593151, 32.72853],
                    [-85.679854, 32.71253],
                    [-85.696755, 32.697429],
                    [-85.695854, 32.595933],
                    [-85.798585, 32.581089],
                    [-85.797156, 32.494236],
                    [-85.886148, 32.493053],
                    [-85.87986, 32.754528],
                    [-86.007187, 32.754984],
                    [-86.31948, 32.753698],
                    [-86.336694, 32.76813],
                    [-86.374974, 32.75358],
                    [-86.457015, 32.813899],
                    [-86.451524, 32.863692],
                    [-86.515959, 32.929361],
                    [-86.517344, 33.02056600000001],
                ],
            ],
        },
        properties: {
            CSAFP: '388',
            CBSAFP: '10760',
            AFFGEOID: '310M600US10760',
            GEOID: '10760',
            NAME: 'Alexander City, AL',
            NAMELSAD: 'Alexander City, AL Micro Area',
            LSAD: 'M2',
            ALAND: 3541644965,
            AWATER: 168782127,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.666402, 44.596197, -90.922247, 45.29222],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.665647, 45.207991],
                    [-91.542234, 45.206538],
                    [-91.541322, 45.29222],
                    [-90.925222, 45.292061],
                    [-90.92336200000001, 45.031143],
                    [-90.922669, 44.944428],
                    [-90.922247, 44.857309],
                    [-90.92235, 44.596293],
                    [-91.043815, 44.59664],
                    [-91.165619, 44.596987],
                    [-91.529101, 44.596197],
                    [-91.65024800000002, 44.596649],
                    [-91.650361, 44.683633],
                    [-91.650455, 44.855951],
                    [-91.65139, 45.02978100000001],
                    [-91.666402, 45.120823],
                    [-91.665647, 45.207991],
                ],
            ],
        },
        properties: {
            CSAFP: '232',
            CBSAFP: '20740',
            AFFGEOID: '310M600US20740',
            GEOID: '20740',
            NAME: 'Eau Claire, WI',
            NAMELSAD: 'Eau Claire, WI Metro Area',
            LSAD: 'M1',
            ALAND: 4263839734,
            AWATER: 104081854,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.845962, 43.117942, -84.36776, 43.466158],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.845962, 43.466158],
                    [-84.60754, 43.466006],
                    [-84.36987600000002, 43.466044],
                    [-84.367891, 43.12845200000001],
                    [-84.36776, 43.117942],
                    [-84.836889, 43.118851],
                    [-84.845962, 43.466158],
                ],
            ],
        },
        properties: {
            CSAFP: '394',
            CBSAFP: '10940',
            AFFGEOID: '310M600US10940',
            GEOID: '10940',
            NAME: 'Alma, MI',
            NAMELSAD: 'Alma, MI Micro Area',
            LSAD: 'M2',
            ALAND: 1472098896,
            AWATER: 8369164,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.049524, 43.499548, -92.4489479514378, 43.84857],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.049524, 43.848443],
                    [-93.045966, 43.848467],
                    [-92.689391, 43.84857],
                    [-92.674231, 43.83390300000001],
                    [-92.448965, 43.834105],
                    [-92.4489479514378, 43.5004149885627],
                    [-92.5531280905188, 43.5003000363123],
                    [-92.553161, 43.5003],
                    [-92.870277, 43.499548],
                    [-93.0243479005419, 43.49955616363],
                    [-93.0491920078165, 43.4995574800246],
                    [-93.049524, 43.848443],
                ],
            ],
        },
        properties: {
            CSAFP: '462',
            CBSAFP: '12380',
            AFFGEOID: '310M600US12380',
            GEOID: '12380',
            NAME: 'Austin, MN',
            NAMELSAD: 'Austin, MN Micro Area',
            LSAD: 'M2',
            ALAND: 1842187921,
            AWATER: 617235,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-99.202768, 31.448470000000004, -98.668441, 32.079585],
            type: 'Polygon',
            coordinates: [
                [
                    [-99.195871, 32.079235],
                    [-99.118665, 32.079585],
                    [-98.924401, 32.078018],
                    [-98.668441, 31.700514],
                    [-98.76005700000002, 31.687896000000002],
                    [-98.779951, 31.610735],
                    [-98.991608, 31.484070999999997],
                    [-99.041741, 31.490302],
                    [-99.090622, 31.460927000000005],
                    [-99.098794, 31.448470000000004],
                    [-99.202768, 31.467234],
                    [-99.195871, 32.079235],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '15220',
            AFFGEOID: '310M600US15220',
            GEOID: '15220',
            NAME: 'Brownwood, TX',
            NAMELSAD: 'Brownwood, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2446120247,
            AWATER: 32375524,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-106.322885, 40.997734, -105.271809, 42.433236],
            type: 'Polygon',
            coordinates: [
                [
                    [-106.322885, 41.395141],
                    [-106.068406, 41.395028],
                    [-106.074919, 42.086649],
                    [-106.073403, 42.433236],
                    [-105.556042, 42.430583],
                    [-105.575642, 42.377314],
                    [-105.613865, 42.304455],
                    [-105.526399, 42.288859],
                    [-105.375541, 42.290139],
                    [-105.332638, 42.43110600000001],
                    [-105.28391, 42.431402],
                    [-105.271809, 41.742029],
                    [-105.278236, 41.656655],
                    [-105.276859994035, 40.9981729176259],
                    [-105.277138, 40.998173],
                    [-106.190554078709, 40.9977466125744],
                    [-106.217573, 40.997734],
                    [-106.321169215914, 40.998215011068],
                    [-106.322885, 41.395141],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '29660',
            AFFGEOID: '310M600US29660',
            GEOID: '29660',
            NAME: 'Laramie, WY',
            NAMELSAD: 'Laramie, WY Micro Area',
            LSAD: 'M2',
            ALAND: 11070455491,
            AWATER: 89161376,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.90975600000002, 40.276335, -90.445627, 40.639201],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.904216, 40.639201],
                    [-90.789652, 40.63575000000001],
                    [-90.445627, 40.627638],
                    [-90.450227, 40.276335],
                    [-90.90975600000002, 40.284394000000006],
                    [-90.904216, 40.639201],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '31380',
            AFFGEOID: '310M600US31380',
            GEOID: '31380',
            NAME: 'Macomb, IL',
            NAMELSAD: 'Macomb, IL Micro Area',
            LSAD: 'M2',
            ALAND: 1526339605,
            AWATER: 2012552,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.152457, 34.867127, -90.40043800000001, 35.148231],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.14975, 35.002469],
                    [-91.045445, 35.001048],
                    [-91.042566, 35.148188],
                    [-90.503031, 35.144215],
                    [-90.40043800000001, 35.148231],
                    [-90.408539, 34.904021],
                    [-91.09921, 34.911635],
                    [-91.100029, 34.867127],
                    [-91.152457, 34.911847],
                    [-91.14975, 35.002469],
                ],
            ],
        },
        properties: {
            CSAFP: '368',
            CBSAFP: '22620',
            AFFGEOID: '310M600US22620',
            GEOID: '22620',
            NAME: 'Forrest City, AR',
            NAMELSAD: 'Forrest City, AR Micro Area',
            LSAD: 'M2',
            ALAND: 1644082981,
            AWATER: 20017606,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-102.032339018963, 36.499244000000004, -100.94546679307801, 36.9982468287288],
            type: 'Polygon',
            coordinates: [
                [
                    [-102.028204191045, 36.9931451471083],
                    [-101.90244, 36.993702000000006],
                    [-101.555258848691, 36.9952909392776],
                    [-101.485326, 36.995611],
                    [-101.211486, 36.99712400000001],
                    [-101.06645021566, 36.9977361751404],
                    [-100.94546679307801, 36.9982468287288],
                    [-100.954152157693, 36.4995294966262],
                    [-101.085156, 36.499244000000004],
                    [-101.623915, 36.499528],
                    [-101.826565, 36.499654],
                    [-102.032339018963, 36.5000656730101],
                    [-102.028204191045, 36.9931451471083],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '25100',
            AFFGEOID: '310M600US25100',
            GEOID: '25100',
            NAME: 'Guymon, OK',
            NAMELSAD: 'Guymon, OK Micro Area',
            LSAD: 'M2',
            ALAND: 5286873570,
            AWATER: 19218939,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.748251, 31.618048, -84.907006, 32.14793100000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.735732, 31.624493000000005],
                    [-85.66623, 31.772877000000005],
                    [-85.657668, 31.880275],
                    [-85.587344, 31.997355],
                    [-85.428476, 32.014951],
                    [-85.410241, 32.146651],
                    [-85.257834, 32.14793100000001],
                    [-85.185067, 32.061708],
                    [-85.0514111279128, 32.062256083622],
                    [-85.0635905429847, 31.991857000000003],
                    [-85.042029, 31.975658000000003],
                    [-84.974067, 31.950209000000005],
                    [-84.907006, 31.924465],
                    [-84.910338, 31.776841000000005],
                    [-84.95864, 31.777854],
                    [-85.073941, 31.771441],
                    [-85.1291593155832, 31.7802782680766],
                    [-85.1254405773425, 31.762968703418302],
                    [-85.216076, 31.702409000000003],
                    [-85.416038, 31.706664000000004],
                    [-85.416437, 31.619466],
                    [-85.467038, 31.620565999999997],
                    [-85.748251, 31.618048],
                    [-85.735732, 31.624493000000005],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '21640',
            AFFGEOID: '310M600US21640',
            GEOID: '21640',
            NAME: 'Eufaula, AL-GA',
            NAMELSAD: 'Eufaula, AL-GA Micro Area',
            LSAD: 'M2',
            ALAND: 2683863225,
            AWATER: 74681508,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.27870100000001, 35.638546, -94.797409, 36.161763],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.27870100000001, 35.964102],
                    [-95.207943, 36.074772],
                    [-95.118377, 36.074544],
                    [-95.1185, 36.16163],
                    [-95.011328, 36.161763],
                    [-94.797409, 36.161652],
                    [-94.807757, 35.638546],
                    [-95.127213, 35.638788],
                    [-95.127163, 35.812755],
                    [-95.265679, 35.813266],
                    [-95.231459, 35.851195],
                    [-95.27870100000001, 35.964102],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '45140',
            AFFGEOID: '310M600US45140',
            GEOID: '45140',
            NAME: 'Tahlequah, OK',
            NAMELSAD: 'Tahlequah, OK Micro Area',
            LSAD: 'M2',
            ALAND: 1940228933,
            AWATER: 69661251,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-120.003364028833, 39.086259, -119.55038599999999, 39.251046],
            type: 'Polygon',
            coordinates: [
                [
                    [-120.003364028833, 39.1656267640688],
                    [-119.88078700000001, 39.165509],
                    [-119.843933, 39.200776],
                    [-119.77087100000001, 39.215285],
                    [-119.71264500000001, 39.250871],
                    [-119.71211200000002, 39.251046],
                    [-119.647945, 39.19506],
                    [-119.56201200000001, 39.194847],
                    [-119.55038599999999, 39.086259],
                    [-119.761213, 39.114009],
                    [-120.00261484291302, 39.11268937409581],
                    [-120.003364028833, 39.1656267640688],
                ],
            ],
        },
        properties: {
            CSAFP: '456',
            CBSAFP: '16180',
            AFFGEOID: '310M600US16180',
            GEOID: '16180',
            NAME: 'Carson City, NV',
            NAMELSAD: 'Carson City, NV Metro Area',
            LSAD: 'M1',
            ALAND: 374341372,
            AWATER: 32594082,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-123.231001260578, 42.003049, -122.28273, 42.996632],
            type: 'Polygon',
            coordinates: [
                [
                    [-123.22990100000001, 42.290816],
                    [-123.229545, 42.495623],
                    [-123.22961900000001, 42.70261],
                    [-123.152233, 42.755835],
                    [-123.00976499999999, 42.752144],
                    [-122.95119899999999, 42.774773],
                    [-122.794776, 42.778221],
                    [-122.677181, 42.88108],
                    [-122.560299, 42.92469],
                    [-122.46022400000001, 42.939873],
                    [-122.401025, 42.996632],
                    [-122.28273, 42.996499],
                    [-122.284689, 42.4745],
                    [-122.28776, 42.440287],
                    [-122.289749096157, 42.0077647304966],
                    [-122.50113499999999, 42.00846],
                    [-122.80008000000001, 42.004071],
                    [-123.04525399999999, 42.003049],
                    [-123.145959, 42.009247],
                    [-123.231001260578, 42.004970062256],
                    [-123.22990100000001, 42.290816],
                ],
            ],
        },
        properties: {
            CSAFP: '366',
            CBSAFP: '32780',
            AFFGEOID: '310M600US32780',
            GEOID: '32780',
            NAME: 'Medford, OR',
            NAMELSAD: 'Medford, OR Metro Area',
            LSAD: 'M1',
            ALAND: 7208589509,
            AWATER: 46774848,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.421054, 40.991889, -82.829512, 41.255337],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.421054, 41.16678],
                    [-83.419843, 41.254003],
                    [-82.840087, 41.255337],
                    [-82.83853800000001, 41.216999],
                    [-82.829512, 40.996624],
                    [-82.855614, 40.993666],
                    [-83.112737, 40.993447],
                    [-83.420319, 40.991889],
                    [-83.421054, 41.16678],
                ],
            ],
        },
        properties: {
            CSAFP: '534',
            CBSAFP: '45660',
            AFFGEOID: '310M600US45660',
            GEOID: '45660',
            NAME: 'Tiffin, OH',
            NAMELSAD: 'Tiffin, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1427151729,
            AWATER: 4576816,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-100.224235, 40.351200000000006, -99.417316, 41.046597000000006],
            type: 'Polygon',
            coordinates: [
                [
                    [-100.224235, 41.046597000000006],
                    [-99.425986, 41.046471],
                    [-99.426551, 40.69977],
                    [-99.417316, 40.670825],
                    [-99.444401, 40.677892],
                    [-99.643462, 40.684911],
                    [-99.642209, 40.351200000000006],
                    [-100.094798, 40.351245],
                    [-100.095095, 40.438656],
                    [-99.98119300000002, 40.438291],
                    [-99.981743, 40.700003],
                    [-100.223311, 40.700254],
                    [-100.224235, 41.046597000000006],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '30420',
            AFFGEOID: '310M600US30420',
            GEOID: '30420',
            NAME: 'Lexington, NE',
            NAMELSAD: 'Lexington, NE Micro Area',
            LSAD: 'M2',
            ALAND: 3810426007,
            AWATER: 28341025,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-100.00038884414302, 35.029889, -99.360226, 35.508864],
            type: 'Polygon',
            coordinates: [
                [
                    [-100.00038884414302, 35.4223639883094],
                    [-99.57582, 35.421655],
                    [-99.575723, 35.508864],
                    [-99.364141, 35.508793],
                    [-99.364217, 35.465328],
                    [-99.360226, 35.116738],
                    [-99.404312, 35.116898],
                    [-99.409291, 35.114509],
                    [-99.782727, 35.11694000000001],
                    [-99.782949, 35.030111],
                    [-99.888444, 35.029889],
                    [-100.000384017848, 35.0299299999999],
                    [-100.000385, 35.182702],
                    [-100.00038884414302, 35.4223639883094],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '21120',
            AFFGEOID: '310M600US21120',
            GEOID: '21120',
            NAME: 'Elk City, OK',
            NAMELSAD: 'Elk City, OK Micro Area',
            LSAD: 'M2',
            ALAND: 2335328724,
            AWATER: 5521744,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.880926, 32.453068, -92.415169, 32.759443],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.880926, 32.585265],
                    [-92.827976, 32.759432],
                    [-92.725407, 32.759443],
                    [-92.571295, 32.74857900000001],
                    [-92.518999, 32.670807],
                    [-92.415169, 32.670254],
                    [-92.415228, 32.582854],
                    [-92.415515, 32.495609],
                    [-92.622402, 32.496408],
                    [-92.621681, 32.453068],
                    [-92.776982, 32.453611],
                    [-92.879461, 32.45401700000001],
                    [-92.880926, 32.585265],
                ],
            ],
        },
        properties: {
            CSAFP: '384',
            CBSAFP: '40820',
            AFFGEOID: '310M600US40820',
            GEOID: '40820',
            NAME: 'Ruston, LA',
            NAMELSAD: 'Ruston, LA Micro Area',
            LSAD: 'M2',
            ALAND: 1221532395,
            AWATER: 1897706,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.80709, 38.844478, -82.424677, 39.207967],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.785891, 39.168769],
                    [-82.762892, 39.207967],
                    [-82.536306, 39.180757],
                    [-82.540282, 39.144471],
                    [-82.424677, 39.137972],
                    [-82.435309, 39.035079],
                    [-82.455179, 38.844577],
                    [-82.575583, 38.844478],
                    [-82.650039, 38.849073],
                    [-82.76069, 38.854875],
                    [-82.753391, 38.945373000000004],
                    [-82.80709, 38.948073],
                    [-82.785891, 39.168769],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '27160',
            AFFGEOID: '310M600US27160',
            GEOID: '27160',
            NAME: 'Jackson, OH',
            NAMELSAD: 'Jackson, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1088598926,
            AWATER: 3167846,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-99.481095, 46.630211, -98.439056, 47.327027],
            type: 'Polygon',
            coordinates: [
                [
                    [-99.481095, 47.327027],
                    [-99.266215, 47.326999],
                    [-98.499246, 47.326538],
                    [-98.466957, 47.312943],
                    [-98.467066, 47.240404],
                    [-98.439734, 46.979631000000005],
                    [-98.439056, 46.63112],
                    [-99.036625, 46.630211],
                    [-99.44972, 46.63163],
                    [-99.445891, 46.980189],
                    [-99.481095, 47.327027],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '27420',
            AFFGEOID: '310M600US27420',
            GEOID: '27420',
            NAME: 'Jamestown, ND',
            NAMELSAD: 'Jamestown, ND Micro Area',
            LSAD: 'M2',
            ALAND: 5754703480,
            AWATER: 197271625,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-109.046796, 34.959081, -107.308667, 36.002701630519],
            type: 'Polygon',
            coordinates: [
                [
                    [-109.046796, 35.363606],
                    [-109.046296, 35.614251],
                    [-109.046024, 35.8798],
                    [-109.045871168205, 36.002701630519],
                    [-107.626511, 36.000288],
                    [-107.308667, 35.998556],
                    [-107.309386, 35.305687],
                    [-107.628788, 35.304333],
                    [-107.628313, 35.348314],
                    [-107.73424100000001, 35.348145],
                    [-107.734224, 35.304987],
                    [-108.468715, 35.306658000000006],
                    [-108.468683, 35.299243],
                    [-108.46940200000002, 34.959081],
                    [-109.045851235183, 34.9598185158367],
                    [-109.046355894384, 35.1755071883512],
                    [-109.046796, 35.363606],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '23700',
            AFFGEOID: '310M600US23700',
            GEOID: '23700',
            NAME: 'Gallup, NM',
            NAMELSAD: 'Gallup, NM Micro Area',
            LSAD: 'M2',
            ALAND: 14118189398,
            AWATER: 12689169,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.4617910310013, 34.2993039510346, -78.805721, 34.952715],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.4617910310013, 34.6300306785915],
                    [-79.330825, 34.774078],
                    [-79.347868, 34.838553],
                    [-79.191391, 34.833717],
                    [-79.038747, 34.952715],
                    [-78.940844, 34.903368],
                    [-78.901998, 34.835268],
                    [-78.805721, 34.689202],
                    [-78.86896100000001, 34.484778],
                    [-78.959698, 34.443008],
                    [-79.0712436122253, 34.2993039510346],
                    [-79.0712542010482, 34.2993130268419],
                    [-79.358317, 34.545358],
                    [-79.4502772890434, 34.6206089970087],
                    [-79.4617910310013, 34.6300306785915],
                ],
            ],
        },
        properties: {
            CSAFP: '246',
            CBSAFP: '31300',
            AFFGEOID: '310M600US31300',
            GEOID: '31300',
            NAME: 'Lumberton, NC',
            NAMELSAD: 'Lumberton, NC Micro Area',
            LSAD: 'M2',
            ALAND: 2453481924,
            AWATER: 5076395,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.146393, 35.409101, -84.72324, 35.825],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.146393, 35.491299],
                    [-85.107762, 35.571359],
                    [-84.916062, 35.761939],
                    [-84.80163, 35.823066],
                    [-84.781901, 35.825],
                    [-84.754472, 35.75439],
                    [-84.723853, 35.75289600000001],
                    [-84.72324, 35.68967],
                    [-84.781804, 35.673908],
                    [-84.794904, 35.55255],
                    [-84.873969, 35.550217],
                    [-84.859949, 35.497072],
                    [-84.912148, 35.442671],
                    [-84.968953, 35.466674],
                    [-85.016661, 35.409101],
                    [-85.102158, 35.428442],
                    [-85.131094, 35.458004],
                    [-85.146393, 35.491299],
                ],
            ],
        },
        properties: {
            CSAFP: '174',
            CBSAFP: '19420',
            AFFGEOID: '310M600US19420',
            GEOID: '19420',
            NAME: 'Dayton, TN',
            NAMELSAD: 'Dayton, TN Micro Area',
            LSAD: 'M2',
            ALAND: 817071272,
            AWATER: 54409427,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-71.7631925213134, 44.085752, -71.0102711678683, 45.305451],
            type: 'Polygon',
            coordinates: [
                [
                    [-71.7631925213134, 44.4035682627764],
                    [-71.69092, 44.421234],
                    [-71.64655100000002, 44.468869],
                    [-71.579974, 44.501778],
                    [-71.588076, 44.54785],
                    [-71.544922, 44.579278],
                    [-71.551722, 44.627598],
                    [-71.584574, 44.665351],
                    [-71.626909, 44.747224],
                    [-71.570402, 44.805276000000006],
                    [-71.522393, 44.880811],
                    [-71.494403, 44.911837],
                    [-71.531605, 44.976023],
                    [-71.501088, 45.013377],
                    [-71.498399, 45.069629],
                    [-71.448678, 45.109001],
                    [-71.419058, 45.170488],
                    [-71.405636, 45.198139000000005],
                    [-71.438546, 45.239004],
                    [-71.360664, 45.269835],
                    [-71.283684, 45.301977],
                    [-71.24449900000002, 45.268139],
                    [-71.182587, 45.24106900000001],
                    [-71.13943, 45.242958],
                    [-71.109349, 45.282222],
                    [-71.083924, 45.305451],
                    [-71.05786100000002, 45.000049],
                    [-71.036705, 44.736498],
                    [-71.022992, 44.500058],
                    [-71.0194617294855, 44.4403632397875],
                    [-71.0135759038146, 44.340837452559505],
                    [-71.01127, 44.301846],
                    [-71.0102711678683, 44.284888321572],
                    [-71.04228, 44.237514],
                    [-71.251957, 44.24426600000001],
                    [-71.335882, 44.085752],
                    [-71.356482, 44.165836],
                    [-71.415898, 44.212169],
                    [-71.443338, 44.249172],
                    [-71.575819, 44.254596],
                    [-71.587004, 44.304759],
                    [-71.696885, 44.332118],
                    [-71.7631925213134, 44.4035682627764],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '13620',
            AFFGEOID: '310M600US13620',
            GEOID: '13620',
            NAME: 'Berlin, NH',
            NAMELSAD: 'Berlin, NH Micro Area',
            LSAD: 'M2',
            ALAND: 4647924324,
            AWATER: 92545557,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.280059, 38.388457, -79.349867, 39.115803],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.236831, 38.743765],
                    [-80.126105, 38.745836],
                    [-80.085062, 38.947236000000004],
                    [-79.896094, 38.973404],
                    [-79.825092, 39.115803],
                    [-79.785692, 39.037415],
                    [-79.508765, 38.975173],
                    [-79.357666, 38.964509],
                    [-79.349867, 38.957509],
                    [-79.374868, 38.92081],
                    [-79.519674, 38.89201],
                    [-79.510073, 38.780712],
                    [-79.626774, 38.664214],
                    [-79.73918, 38.679613],
                    [-79.776883, 38.73971100000001],
                    [-79.86325, 38.55082],
                    [-80.029208, 38.459184],
                    [-80.11692, 38.473953],
                    [-80.245518, 38.388457],
                    [-80.183931, 38.525386],
                    [-80.280059, 38.694867],
                    [-80.236831, 38.743765],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '21180',
            AFFGEOID: '310M600US21180',
            GEOID: '21180',
            NAME: 'Elkins, WV',
            NAMELSAD: 'Elkins, WV Micro Area',
            LSAD: 'M2',
            ALAND: 2692810850,
            AWATER: 667359,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.886673, 43.892977, -88.403195, 44.244102],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.886673, 44.242622],
                    [-88.7662, 44.243366],
                    [-88.73977, 44.243303],
                    [-88.524791, 44.244028],
                    [-88.40407, 44.244102],
                    [-88.404187, 43.9382],
                    [-88.403195, 43.892977],
                    [-88.88569700000001, 43.895184],
                    [-88.886193, 43.983233],
                    [-88.886673, 44.242622],
                ],
            ],
        },
        properties: {
            CSAFP: '118',
            CBSAFP: '36780',
            AFFGEOID: '310M600US36780',
            GEOID: '36780',
            NAME: 'Oshkosh-Neenah, WI',
            NAMELSAD: 'Oshkosh-Neenah, WI Metro Area',
            LSAD: 'M1',
            ALAND: 1125859770,
            AWATER: 372631716,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.60523, 30.986031, -96.596862, 31.863079000000003],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.60523, 31.587762],
                    [-97.277265, 31.745491999999995],
                    [-97.037266, 31.863079000000003],
                    [-96.932215, 31.708887],
                    [-96.801123, 31.522269],
                    [-96.596862, 31.2209],
                    [-96.830497, 31.105219999999996],
                    [-96.849079, 31.100471],
                    [-97.070057, 30.986031],
                    [-97.278113, 31.279799],
                    [-97.343426, 31.244215000000004],
                    [-97.418606, 31.320202000000002],
                    [-97.60523, 31.587762],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '47380',
            AFFGEOID: '310M600US47380',
            GEOID: '47380',
            NAME: 'Waco, TX',
            NAMELSAD: 'Waco, TX Metro Area',
            LSAD: 'M1',
            ALAND: 4667547595,
            AWATER: 82785146,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.3692013921691, 26.769601, -81.562531, 27.03525],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.3692013921691, 26.946081025633504],
                    [-82.255521, 26.945264],
                    [-82.255169, 27.033020000000004],
                    [-82.057506, 27.032119],
                    [-81.983242, 27.03525],
                    [-81.562624, 27.033835],
                    [-81.562531, 27.033687],
                    [-81.565748, 26.769601],
                    [-81.931098, 26.770049],
                    [-82.205562, 26.770528],
                    [-82.2805412776288, 26.7893085864431],
                    [-82.314277, 26.858384],
                    [-82.3692013921691, 26.946081025633504],
                ],
            ],
        },
        properties: {
            CSAFP: '412',
            CBSAFP: '39460',
            AFFGEOID: '310M600US39460',
            GEOID: '39460',
            NAME: 'Punta Gorda, FL',
            NAMELSAD: 'Punta Gorda, FL Metro Area',
            LSAD: 'M1',
            ALAND: 1764089884,
            AWATER: 689239393,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.053736, 39.768778, -83.516155, 40.040182],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.05101200000001, 39.87981],
                    [-84.036069, 40.040182],
                    [-83.516155, 40.010188],
                    [-83.588041, 39.768778],
                    [-83.647169, 39.773029],
                    [-83.829374, 39.797766],
                    [-83.939583, 39.843797],
                    [-84.053736, 39.850458],
                    [-84.05101200000001, 39.87981],
                ],
            ],
        },
        properties: {
            CSAFP: '212',
            CBSAFP: '44220',
            AFFGEOID: '310M600US44220',
            GEOID: '44220',
            NAME: 'Springfield, OH',
            NAMELSAD: 'Springfield, OH Metro Area',
            LSAD: 'M1',
            ALAND: 1027907500,
            AWATER: 14611224,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.596916, 39.786519, -85.200945, 40.077143],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.596678, 39.872926],
                    [-85.57619200000002, 39.945756],
                    [-85.576197, 40.077143],
                    [-85.214386, 40.076889],
                    [-85.213543, 40.015603],
                    [-85.201473, 40.004521000000004],
                    [-85.200945, 39.873799],
                    [-85.221118, 39.788449],
                    [-85.301584, 39.787579],
                    [-85.596916, 39.786519],
                    [-85.596678, 39.872926],
                ],
            ],
        },
        properties: {
            CSAFP: '294',
            CBSAFP: '35220',
            AFFGEOID: '310M600US35220',
            GEOID: '35220',
            NAME: 'New Castle, IN',
            NAMELSAD: 'New Castle, IN Micro Area',
            LSAD: 'M2',
            ALAND: 1014957933,
            AWATER: 7652084,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.04324, 43.891692, -87.507419, 44.327589],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.04324, 44.241018],
                    [-87.888087, 44.240461],
                    [-87.887526, 44.327589],
                    [-87.766026, 44.327184],
                    [-87.5433267854761, 44.3275128561299],
                    [-87.545382, 44.32138500000001],
                    [-87.521755, 44.259957],
                    [-87.507419, 44.210803],
                    [-87.53994, 44.15969],
                    [-87.600882, 44.131695],
                    [-87.655183, 44.081894],
                    [-87.69892, 43.965936],
                    [-87.7285795423089, 43.8922088874568],
                    [-88.041794, 43.891692],
                    [-88.04324, 44.241018],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '31820',
            AFFGEOID: '310M600US31820',
            GEOID: '31820',
            NAME: 'Manitowoc, WI',
            NAMELSAD: 'Manitowoc, WI Micro Area',
            LSAD: 'M2',
            ALAND: 1526295968,
            AWATER: 2343344181,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.786867, 36.859517, -89.30814691381221, 37.2609507184141],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.765211, 37.126066],
                    [-89.62025600000001, 37.233008],
                    [-89.482889284644, 37.2609507184141],
                    [-89.470525, 37.253357],
                    [-89.456105, 37.18812],
                    [-89.384175, 37.10326700000001],
                    [-89.359456, 37.042606],
                    [-89.30814691381221, 37.0289484973829],
                    [-89.465393, 36.935776],
                    [-89.519809, 36.869617],
                    [-89.56757000000002, 36.870909],
                    [-89.691603, 36.859517],
                    [-89.685544, 36.921917],
                    [-89.786867, 37.087102],
                    [-89.765211, 37.126066],
                ],
            ],
        },
        properties: {
            CSAFP: '164',
            CBSAFP: '43460',
            AFFGEOID: '310M600US43460',
            GEOID: '43460',
            NAME: 'Sikeston, MO',
            NAMELSAD: 'Sikeston, MO Micro Area',
            LSAD: 'M2',
            ALAND: 1087808789,
            AWATER: 15175233,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.942103, 39.868784000000005, -87.5268757732538, 40.4912244096904],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.942103, 40.225482],
                    [-87.932387, 40.3994],
                    [-87.935309, 40.485923],
                    [-87.5268757732538, 40.4912244096904],
                    [-87.5270652633992, 40.476882292461504],
                    [-87.530054, 40.250671],
                    [-87.531021612428, 40.1480353967457],
                    [-87.532308, 40.011587],
                    [-87.5324544938843, 39.8829991187823],
                    [-87.557533, 39.868784000000005],
                    [-87.615161, 39.881579],
                    [-87.937645, 39.879803],
                    [-87.942103, 40.225482],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '19180',
            AFFGEOID: '310M600US19180',
            GEOID: '19180',
            NAME: 'Danville, IL',
            NAMELSAD: 'Danville, IL Metro Area',
            LSAD: 'M1',
            ALAND: 2326634937,
            AWATER: 7531157,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-77.749932223724, 41.9987554366682, -76.965028, 42.580409],
            type: 'Polygon',
            coordinates: [
                [
                    [-77.731029, 42.40394],
                    [-77.722964, 42.471216],
                    [-77.720617, 42.535066],
                    [-77.661315, 42.535067],
                    [-77.659917, 42.580409],
                    [-77.490889, 42.577288],
                    [-77.455632, 42.576895],
                    [-77.366505, 42.576368],
                    [-77.143795, 42.576869],
                    [-77.107203, 42.483771],
                    [-77.099657, 42.272356],
                    [-76.965028, 42.278495],
                    [-76.9657915095295, 42.0007834917859],
                    [-77.007635, 42.000848],
                    [-77.610020114476, 41.9991498540796],
                    [-77.749932223724, 41.9987554366682],
                    [-77.731029, 42.40394],
                ],
            ],
        },
        properties: {
            CSAFP: '236',
            CBSAFP: '18500',
            AFFGEOID: '310M600US18500',
            GEOID: '18500',
            NAME: 'Corning, NY',
            NAMELSAD: 'Corning, NY Micro Area',
            LSAD: 'M2',
            ALAND: 3601398422,
            AWATER: 35039875,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.743017, 26.945264, -82.054349, 27.646382],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.719852, 27.528933000000002],
                    [-82.65072, 27.523115],
                    [-82.584629, 27.596021],
                    [-82.5528872070636, 27.6454475963655],
                    [-82.054349, 27.646382],
                    [-82.055753, 27.338264],
                    [-82.05649700000001, 27.207769000000003],
                    [-82.057506, 27.032119],
                    [-82.255169, 27.033020000000004],
                    [-82.255521, 26.945264],
                    [-82.3692013921691, 26.946081025633504],
                    [-82.452673, 27.079359],
                    [-82.5165854231874, 27.2078263040441],
                    [-82.539719, 27.254326],
                    [-82.610581, 27.348817],
                    [-82.6481706168595, 27.38972],
                    [-82.691821, 27.437218],
                    [-82.743017, 27.531086],
                    [-82.719852, 27.528933000000002],
                ],
            ],
        },
        properties: {
            CSAFP: '412',
            CBSAFP: '35840',
            AFFGEOID: '310M600US35840',
            GEOID: '35840',
            NAME: 'North Port-Sarasota-Bradenton, FL',
            NAMELSAD: 'North Port-Sarasota-Bradenton, FL Metro Area',
            LSAD: 'M1',
            ALAND: 3363793688,
            AWATER: 1659702745,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-66.832736, 17.961229, -66.419368, 18.250325],
            type: 'Polygon',
            coordinates: [
                [
                    [-66.832736, 18.22799],
                    [-66.817271, 18.230336],
                    [-66.782514, 18.250325],
                    [-66.69373, 18.216891],
                    [-66.670494, 18.152831],
                    [-66.649467, 18.158857],
                    [-66.591331, 18.172425],
                    [-66.552766, 18.152057],
                    [-66.54722, 18.15313],
                    [-66.521899, 18.151954],
                    [-66.470545, 18.177829],
                    [-66.44456, 18.176674],
                    [-66.419368, 18.145304],
                    [-66.430314, 18.082053],
                    [-66.426989, 18.042627],
                    [-66.421054, 18.019309],
                    [-66.4532967534793, 17.9801331134818],
                    [-66.510143, 17.985618],
                    [-66.5405373162362, 17.975475887827503],
                    [-66.583233, 17.961229],
                    [-66.645651, 17.98026],
                    [-66.664391, 17.968259],
                    [-66.6970344469585, 17.9819737685972],
                    [-66.678087, 18.1007],
                    [-66.69407, 18.130534],
                    [-66.749618, 18.113664],
                    [-66.769049, 18.134983],
                    [-66.797557, 18.133445],
                    [-66.827327, 18.171242],
                    [-66.832736, 18.22799],
                ],
            ],
        },
        properties: {
            CSAFP: '434',
            CBSAFP: '38660',
            AFFGEOID: '310M600US38660',
            GEOID: '38660',
            NAME: 'Ponce, PR',
            NAMELSAD: 'Ponce, PR Metro Area',
            LSAD: 'M1',
            ALAND: 718832220,
            AWATER: 329749489,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.368401, 40.523013, -96.463632, 41.046947],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.368118, 41.046947],
                    [-96.91094000000001, 41.046116],
                    [-96.908507, 41.046091],
                    [-96.463869, 41.045083],
                    [-96.463861, 41.016069],
                    [-96.463764, 40.78396],
                    [-96.463632, 40.523013],
                    [-96.912637, 40.523625],
                    [-96.91349300000002, 40.697948],
                    [-97.368401, 40.698625],
                    [-97.368118, 41.046947],
                ],
            ],
        },
        properties: {
            CSAFP: '339',
            CBSAFP: '30700',
            AFFGEOID: '310M600US30700',
            GEOID: '30700',
            NAME: 'Lincoln, NE',
            NAMELSAD: 'Lincoln, NE Metro Area',
            LSAD: 'M1',
            ALAND: 3649268662,
            AWATER: 34389571,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.453765, 40.368717, -78.801726, 40.911346],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.450176, 40.530149],
                    [-79.215235, 40.776016],
                    [-79.21531, 40.911346],
                    [-78.805167, 40.90598],
                    [-78.806375, 40.74316],
                    [-78.801726, 40.724618],
                    [-78.816853, 40.703458],
                    [-78.973814, 40.395421],
                    [-79.016617, 40.420279],
                    [-79.10065, 40.368717],
                    [-79.155789, 40.411765],
                    [-79.373741, 40.453853],
                    [-79.453765, 40.48425],
                    [-79.450176, 40.530149],
                ],
            ],
        },
        properties: {
            CSAFP: '430',
            CBSAFP: '26860',
            AFFGEOID: '310M600US26860',
            GEOID: '26860',
            NAME: 'Indiana, PA',
            NAMELSAD: 'Indiana, PA Micro Area',
            LSAD: 'M2',
            ALAND: 2142965919,
            AWATER: 18089072,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.9269253205306, 41.541278, -76.115172, 42.0007235735426],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.9269253205306, 42.0007235735426],
                    [-76.558118, 42.000155],
                    [-76.5576987221493, 42.0001496652537],
                    [-76.473030885808, 41.999072381007004],
                    [-76.462155, 41.998934],
                    [-76.1455189947821, 41.998866463308],
                    [-76.115172, 41.651825],
                    [-76.197998, 41.647823],
                    [-76.220073, 41.541278],
                    [-76.813731, 41.590034],
                    [-76.874714, 41.596919],
                    [-76.9269253205306, 42.0007235735426],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '42380',
            AFFGEOID: '310M600US42380',
            GEOID: '42380',
            NAME: 'Sayre, PA',
            NAMELSAD: 'Sayre, PA Micro Area',
            LSAD: 'M2',
            ALAND: 2971688854,
            AWATER: 35331261,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-100.671215189194, 28.1970007311399, -100.111406, 29.086318],
            type: 'Polygon',
            coordinates: [
                [
                    [-100.671215189194, 29.083516025344498],
                    [-100.111406, 29.086318],
                    [-100.11433600000001, 28.648122000000004],
                    [-100.113719, 28.197819],
                    [-100.197510055501, 28.1970007311399],
                    [-100.267604, 28.250269],
                    [-100.287554, 28.301093],
                    [-100.320393, 28.362117],
                    [-100.337059, 28.427151000000002],
                    [-100.368288, 28.477196],
                    [-100.38886, 28.515748],
                    [-100.39727, 28.575637],
                    [-100.448648, 28.616774],
                    [-100.500354, 28.66196],
                    [-100.506701, 28.716745],
                    [-100.533017, 28.763280000000005],
                    [-100.53583, 28.805888000000003],
                    [-100.576846, 28.836168],
                    [-100.627206, 28.903734000000004],
                    [-100.646993, 28.957079],
                    [-100.660208, 29.031497000000005],
                    [-100.671215189194, 29.083516025344498],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '20580',
            AFFGEOID: '310M600US20580',
            GEOID: '20580',
            NAME: 'Eagle Pass, TX',
            NAMELSAD: 'Eagle Pass, TX Micro Area',
            LSAD: 'M2',
            ALAND: 3313818749,
            AWATER: 31872415,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-73.508142, 42.0401165089147, -72.9489339463746, 42.74594],
            type: 'Polygon',
            coordinates: [
                [
                    [-73.508142, 42.086257],
                    [-73.4106444074273, 42.3517462996479],
                    [-73.352527, 42.510002],
                    [-73.3070040761806, 42.6326534514115],
                    [-73.264957, 42.74594],
                    [-73.1426721280253, 42.743429351364],
                    [-73.0230178932901, 42.7409727127269],
                    [-73.01864861335041, 42.7408830063998],
                    [-72.9489339463746, 42.7394516843506],
                    [-72.995686, 42.673649],
                    [-72.951039, 42.641006],
                    [-72.975409, 42.556037],
                    [-73.011683, 42.379683],
                    [-73.065772, 42.389111],
                    [-73.064288, 42.339732],
                    [-73.029398, 42.308568],
                    [-73.000278, 42.312597],
                    [-73.07213, 42.143548],
                    [-73.053397149764, 42.0401165089147],
                    [-73.1272291708784, 42.0421231483548],
                    [-73.231056, 42.044945],
                    [-73.487314, 42.049638],
                    [-73.4896799420321, 42.0537977095867],
                    [-73.508142, 42.086257],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '38340',
            AFFGEOID: '310M600US38340',
            GEOID: '38340',
            NAME: 'Pittsfield, MA',
            NAMELSAD: 'Pittsfield, MA Metro Area',
            LSAD: 'M1',
            ALAND: 2400592216,
            AWATER: 50709699,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.145587, 31.18368, -82.597509, 31.816154],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.145587, 31.472276],
                    [-83.11166, 31.478229000000002],
                    [-83.058807, 31.611212000000002],
                    [-82.99836, 31.673164],
                    [-82.995698, 31.780983000000003],
                    [-82.868118, 31.782347],
                    [-82.836366, 31.816154],
                    [-82.836633, 31.671398000000003],
                    [-82.627342, 31.672672000000002],
                    [-82.628273, 31.558328999999997],
                    [-82.597509, 31.469293],
                    [-82.628181, 31.469351],
                    [-82.628975, 31.36393],
                    [-82.629622, 31.274725999999998],
                    [-82.69920600000002, 31.278104],
                    [-82.671669, 31.183739000000003],
                    [-82.971247, 31.183988],
                    [-83.04688500000002, 31.18368],
                    [-83.033468, 31.275041000000005],
                    [-83.140483, 31.420395000000003],
                    [-83.145587, 31.472276],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '20060',
            AFFGEOID: '310M600US20060',
            GEOID: '20060',
            NAME: 'Douglas, GA',
            NAMELSAD: 'Douglas, GA Micro Area',
            LSAD: 'M2',
            ALAND: 2421631743,
            AWATER: 31562829,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-106.63588, 30.6288120187883, -104.91289, 32.002206],
            type: 'Polygon',
            coordinates: [
                [
                    [-106.623445, 31.914034000000004],
                    [-106.63011400000002, 31.971258000000002],
                    [-106.618486, 32.000495],
                    [-106.377173188247, 32.00124010103751],
                    [-106.200699, 32.001785],
                    [-105.997971151664, 32.001974590699],
                    [-105.750527, 32.002206],
                    [-105.153994, 32.00049700000001],
                    [-104.91835699428, 32.0004720730735],
                    [-104.91289, 31.625120000000003],
                    [-104.916487, 30.821034],
                    [-104.917163, 30.663626],
                    [-104.98075389132501, 30.6288120187883],
                    [-105.00124, 30.672583],
                    [-105.062334, 30.686303],
                    [-105.098282, 30.718914000000005],
                    [-105.160153, 30.757059],
                    [-105.21866, 30.801567],
                    [-105.314863, 30.816961],
                    [-105.394242, 30.852979],
                    [-105.399609, 30.888941],
                    [-105.488027, 30.943278],
                    [-105.55743, 30.990229000000003],
                    [-105.579542, 31.035396],
                    [-105.627349, 31.098545],
                    [-105.709491, 31.136375],
                    [-105.773257, 31.166896999999995],
                    [-105.794386, 31.202240000000003],
                    [-105.869353, 31.288634],
                    [-105.938452, 31.318735000000004],
                    [-105.953943, 31.364749000000003],
                    [-105.996429378287, 31.387840129512604],
                    [-106.004926, 31.392457999999998],
                    [-106.080258, 31.398702],
                    [-106.175675, 31.456278999999995],
                    [-106.236804, 31.513376],
                    [-106.280811, 31.562062],
                    [-106.303536, 31.620412999999996],
                    [-106.352610998498, 31.686946416503602],
                    [-106.370139, 31.710710000000002],
                    [-106.41794, 31.752009],
                    [-106.467642, 31.759608],
                    [-106.48464200000001, 31.747808999999997],
                    [-106.528242, 31.783148],
                    [-106.581344, 31.813906],
                    [-106.63588, 31.871514],
                    [-106.623445, 31.914034000000004],
                ],
            ],
        },
        properties: {
            CSAFP: '238',
            CBSAFP: '21340',
            AFFGEOID: '310M600US21340',
            GEOID: '21340',
            NAME: 'El Paso, TX',
            NAMELSAD: 'El Paso, TX Metro Area',
            LSAD: 'M1',
            ALAND: 14461869807,
            AWATER: 7512086,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.748526, 40.698284, -97.598253, 41.395068],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.74433000000002, 41.394178],
                    [-98.291419, 41.393894],
                    [-98.286584, 41.393898],
                    [-98.275408, 41.26434100000001],
                    [-98.101546, 41.264041000000006],
                    [-98.053137, 41.293123],
                    [-97.705497, 41.309134],
                    [-97.703765, 41.394876],
                    [-97.598461, 41.395068],
                    [-97.598253, 41.333119],
                    [-97.604363, 41.313851],
                    [-97.668462, 41.298278],
                    [-97.828256, 41.173439],
                    [-97.874073, 41.12314800000001],
                    [-98.010548, 41.072761],
                    [-98.282578, 40.868677],
                    [-98.282851, 40.698284],
                    [-98.72143, 40.698903],
                    [-98.721975, 41.046674],
                    [-98.748526, 41.046640000000004],
                    [-98.74433000000002, 41.394178],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '24260',
            AFFGEOID: '310M600US24260',
            GEOID: '24260',
            NAME: 'Grand Island, NE',
            NAMELSAD: 'Grand Island, NE Metro Area',
            LSAD: 'M1',
            ALAND: 4151718097,
            AWATER: 55758629,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.487065, 33.41641, -96.94386, 33.945013250454],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.4841434385876, 33.9138891088909],
                    [-97.451469, 33.87093],
                    [-97.444193, 33.823773],
                    [-97.372941, 33.819454],
                    [-97.318243, 33.865121],
                    [-97.24618, 33.900344],
                    [-97.206141, 33.91428],
                    [-97.166629, 33.847311],
                    [-97.205652, 33.809824],
                    [-97.14939400000002, 33.721967],
                    [-97.091072, 33.735115],
                    [-97.087852, 33.774099],
                    [-97.07859, 33.812756],
                    [-97.055838, 33.855741],
                    [-96.985567, 33.886522],
                    [-96.988745, 33.918468],
                    [-96.95231300000002, 33.944582],
                    [-96.9446168883705, 33.945013250454],
                    [-96.94386, 33.41641],
                    [-97.383095, 33.43045],
                    [-97.406768, 33.43311],
                    [-97.487065, 33.433681],
                    [-97.4841434385876, 33.9138891088909],
                ],
            ],
        },
        properties: {
            CSAFP: '206',
            CBSAFP: '23620',
            AFFGEOID: '310M600US23620',
            GEOID: '23620',
            NAME: 'Gainesville, TX',
            NAMELSAD: 'Gainesville, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2265789885,
            AWATER: 61319327,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.31625300000002, 44.681365, -89.223745, 45.555353],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.315038, 45.033829],
                    [-90.197516, 45.03364],
                    [-90.197765, 45.120604],
                    [-90.043685, 45.120214],
                    [-90.042273, 45.381866],
                    [-90.043414, 45.555082],
                    [-89.428258, 45.555353],
                    [-89.424824, 45.467166],
                    [-89.425971, 45.119098],
                    [-89.224214, 45.118567],
                    [-89.223814, 45.029246],
                    [-89.223745, 44.681365],
                    [-89.844931, 44.684945],
                    [-89.964013, 44.684829],
                    [-90.31625300000002, 44.685154],
                    [-90.315038, 45.033829],
                ],
            ],
        },
        properties: {
            CSAFP: '554',
            CBSAFP: '48140',
            AFFGEOID: '310M600US48140',
            GEOID: '48140',
            NAME: 'Wausau-Weston, WI',
            NAMELSAD: 'Wausau-Weston, WI Metro Area',
            LSAD: 'M1',
            ALAND: 6277868296,
            AWATER: 153396133,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.991605, 38.456348, -83.624596, 38.7672838752678],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.991605, 38.593701],
                    [-83.9043750861048, 38.7672838752678],
                    [-83.852085, 38.751433],
                    [-83.834015, 38.716008],
                    [-83.78362, 38.695641],
                    [-83.77216, 38.65815],
                    [-83.7058575510284, 38.6380366259831],
                    [-83.679484, 38.630036],
                    [-83.6469615501443, 38.6418337437309],
                    [-83.624596, 38.611425],
                    [-83.641852, 38.525381],
                    [-83.85928, 38.456348],
                    [-83.930348, 38.492278],
                    [-83.97077800000001, 38.587208],
                    [-83.991605, 38.593701],
                ],
            ],
        },
        properties: {
            CSAFP: '178',
            CBSAFP: '32500',
            AFFGEOID: '310M600US32500',
            GEOID: '32500',
            NAME: 'Maysville, KY',
            NAMELSAD: 'Maysville, KY Micro Area',
            LSAD: 'M2',
            ALAND: 621924469,
            AWATER: 16276484,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-122.82163100000001, 48.626074, -120.65585700000001, 49.002494],
            type: 'Polygon',
            coordinates: [
                [
                    [-122.82163100000001, 48.941369],
                    [-122.75802, 49.002357],
                    [-122.251063, 49.002494],
                    [-122.09835700000001, 49.002146],
                    [-121.751252, 48.997399],
                    [-121.39554300000002, 48.999851],
                    [-121.12624, 49.001412],
                    [-120.851527133018, 49.0005911528352],
                    [-120.73528100000001, 48.864374],
                    [-120.735427, 48.786487],
                    [-120.65585700000001, 48.724342],
                    [-120.751904, 48.657002],
                    [-120.90835700000001, 48.657003],
                    [-120.908358, 48.640901],
                    [-121.564067, 48.642332],
                    [-122.21583600000001, 48.64569],
                    [-122.50148899999999, 48.64532],
                    [-122.56591500000002, 48.629913],
                    [-122.607778, 48.626074],
                    [-122.671345425667, 48.6452989985202],
                    [-122.71017700000002, 48.722237],
                    [-122.720045, 48.78919500000001],
                    [-122.73250600000001, 48.838097],
                    [-122.79401800000001, 48.88313],
                    [-122.82163100000001, 48.941369],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '13380',
            AFFGEOID: '310M600US13380',
            GEOID: '13380',
            NAME: 'Bellingham, WA',
            NAMELSAD: 'Bellingham, WA Metro Area',
            LSAD: 'M1',
            ALAND: 5459483486,
            AWATER: 1028111662,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-121.18077600000001, 47.220133, -118.95078199999999, 48.54503],
            type: 'Polygon',
            coordinates: [
                [
                    [-121.11818300000002, 47.997103],
                    [-121.15326000000002, 48.039693],
                    [-121.015942, 48.074795],
                    [-120.907425, 48.163184],
                    [-121.00165799999999, 48.296009],
                    [-121.071589, 48.317802],
                    [-121.049714, 48.485399],
                    [-120.823947, 48.54503],
                    [-120.70206899999998, 48.531589],
                    [-120.652283, 48.536939],
                    [-120.633344, 48.468118],
                    [-120.64993300000002, 48.398139],
                    [-120.56020600000001, 48.361048],
                    [-120.583166, 48.319128000000006],
                    [-120.50821200000001, 48.310344],
                    [-120.46912399999998, 48.26898],
                    [-120.34628400000001, 48.199986],
                    [-120.361288, 48.15756900000001],
                    [-120.21305999999998, 48.079044],
                    [-120.14307500000001, 48.064882],
                    [-120.06602300000002, 47.966442],
                    [-119.87059499999998, 47.960455],
                    [-119.89535000000001, 48.050044],
                    [-119.774593, 48.104213],
                    [-119.669707, 48.075232],
                    [-119.693712, 48.032056],
                    [-119.652468, 48.000221],
                    [-119.517571, 48.062615],
                    [-119.43625700000001, 48.076688000000004],
                    [-119.34352300000002, 48.04943200000001],
                    [-119.29858500000002, 48.115485],
                    [-119.25685, 48.102027],
                    [-119.15098299999998, 48.146067],
                    [-119.05697, 48.136974],
                    [-119.00318999999999, 48.043263],
                    [-118.95078199999999, 48.018884],
                    [-118.982234, 47.962025],
                    [-119.103873, 47.961763],
                    [-119.125904, 47.917301],
                    [-119.212225, 47.887708],
                    [-119.21241, 47.800695],
                    [-119.29864900000001, 47.728328],
                    [-119.320304, 47.626797],
                    [-119.40519600000002, 47.612636],
                    [-119.532889, 47.527756],
                    [-119.533123, 47.441207000000006],
                    [-119.87576, 47.437492],
                    [-119.91891500000001, 47.364475],
                    [-120.00470400000002, 47.319994],
                    [-120.007074, 47.220133],
                    [-120.075969, 47.225898],
                    [-120.09446000000001, 47.262159],
                    [-120.389197, 47.260935],
                    [-120.5305, 47.334328],
                    [-120.664368, 47.343305],
                    [-120.80253300000001, 47.422626],
                    [-120.917936, 47.429367],
                    [-120.99657800000001, 47.518545],
                    [-121.07496999999998, 47.550891],
                    [-121.11356899999998, 47.597288],
                    [-121.121584, 47.685213],
                    [-121.066005, 47.713603],
                    [-121.119179, 47.779933],
                    [-121.07068500000001, 47.82661600000001],
                    [-121.18077600000001, 47.898769],
                    [-121.11818300000002, 47.997103],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '48300',
            AFFGEOID: '310M600US48300',
            GEOID: '48300',
            NAME: 'Wenatchee, WA',
            NAMELSAD: 'Wenatchee, WA Metro Area',
            LSAD: 'M1',
            ALAND: 12277634906,
            AWATER: 266210540,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-121.24588699999998, 36.740381, -120.052055, 37.633364],
            type: 'Polygon',
            coordinates: [
                [
                    [-121.226804, 37.134774],
                    [-120.96381200000002, 37.346144],
                    [-120.989811, 37.395871],
                    [-120.38766999999999, 37.633364],
                    [-120.17763600000002, 37.261526],
                    [-120.052055, 37.183108],
                    [-120.10638500000002, 37.167153],
                    [-120.227286, 37.16340000000001],
                    [-120.47669200000001, 37.09639],
                    [-120.541696, 37.044505],
                    [-120.59056600000001, 36.952641],
                    [-120.65595000000002, 36.952832],
                    [-120.91873100000001, 36.740381],
                    [-121.141523, 36.836656],
                    [-121.234683, 36.926894],
                    [-121.21540599999999, 36.961248],
                    [-121.24588699999998, 36.983036],
                    [-121.226804, 37.134774],
                ],
            ],
        },
        properties: {
            CSAFP: '488',
            CBSAFP: '32900',
            AFFGEOID: '310M600US32900',
            GEOID: '32900',
            NAME: 'Merced, CA',
            NAMELSAD: 'Merced, CA Metro Area',
            LSAD: 'M1',
            ALAND: 5019407131,
            AWATER: 105277636,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.393794, 39.168769, -82.740729, 39.516249],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.386057, 39.31969000000001],
                    [-83.372714, 39.377416],
                    [-83.266737, 39.516249],
                    [-82.998204, 39.480061],
                    [-82.740729, 39.46835],
                    [-82.748591, 39.368165],
                    [-82.760111, 39.237644],
                    [-82.762892, 39.207967],
                    [-82.785891, 39.168769],
                    [-83.35353100000002, 39.197585],
                    [-83.393794, 39.268048],
                    [-83.386057, 39.31969000000001],
                ],
            ],
        },
        properties: {
            CSAFP: '198',
            CBSAFP: '17060',
            AFFGEOID: '310M600US17060',
            GEOID: '17060',
            NAME: 'Chillicothe, OH',
            NAMELSAD: 'Chillicothe, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1784931094,
            AWATER: 9994410,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.169359, 37.891538, -84.725168, 38.374836],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.169359, 37.997576],
                    [-85.101646, 38.03727],
                    [-85.047783, 38.072341],
                    [-85.023711, 38.129052],
                    [-84.998898, 38.274979],
                    [-84.956959, 38.332271],
                    [-84.997667, 38.335586],
                    [-84.906256, 38.374836],
                    [-84.870482, 38.356751],
                    [-84.79341200000002, 38.338669],
                    [-84.740594, 38.352415],
                    [-84.73098900000001, 38.216338],
                    [-84.725168, 38.195426],
                    [-84.864804, 38.141373],
                    [-84.864908, 38.11692600000001],
                    [-84.830915, 38.093605],
                    [-84.84977900000001, 38.047985],
                    [-84.796327, 37.970088],
                    [-84.933085, 37.954186],
                    [-85.030528, 37.891538],
                    [-85.12670500000002, 37.917167],
                    [-85.152797, 37.89766100000001],
                    [-85.167834, 37.971809],
                    [-85.169359, 37.997576],
                ],
            ],
        },
        properties: {
            CSAFP: '336',
            CBSAFP: '23180',
            AFFGEOID: '310M600US23180',
            GEOID: '23180',
            NAME: 'Frankfort, KY',
            NAMELSAD: 'Frankfort, KY Micro Area',
            LSAD: 'M2',
            ALAND: 1061924384,
            AWATER: 16259471,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.195911, 40.150976, -81.61603, 40.456382],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.184277, 40.456382],
                    [-81.709132, 40.444775],
                    [-81.712288, 40.370804],
                    [-81.61603, 40.368118],
                    [-81.622683, 40.221308],
                    [-81.670899, 40.150976],
                    [-81.71627600000001, 40.15216600000001],
                    [-82.187105, 40.16688],
                    [-82.183492, 40.22726],
                    [-82.195911, 40.239071],
                    [-82.184277, 40.456382],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '18740',
            AFFGEOID: '310M600US18740',
            GEOID: '18740',
            NAME: 'Coshocton, OH',
            NAMELSAD: 'Coshocton, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1460721942,
            AWATER: 9128742,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-121.10753399999999, 43.697957, -119.65551699999999, 44.564009],
            type: 'Polygon',
            coordinates: [
                [
                    [-121.10753399999999, 44.3906],
                    [-120.98837500000002, 44.390265],
                    [-120.98882399999998, 44.476484],
                    [-120.826899, 44.476499],
                    [-120.82755500000002, 44.562793],
                    [-120.386561, 44.564009],
                    [-120.386074, 44.436083],
                    [-120.02162099999998, 44.438793],
                    [-120.02070799999998, 44.389687],
                    [-119.89928299999998, 44.389575],
                    [-119.899002, 44.30662],
                    [-119.65551699999999, 44.30704500000001],
                    [-119.65718299999999, 43.95892],
                    [-119.77540199999999, 43.959086],
                    [-119.777528, 43.697957],
                    [-119.897434, 43.6982],
                    [-120.25777700000002, 43.698762],
                    [-120.258386, 43.785297],
                    [-120.37810700000001, 43.785429],
                    [-120.37874, 43.872362],
                    [-120.747848, 43.871331],
                    [-120.74824899999999, 43.95731],
                    [-120.986587, 43.960852],
                    [-120.987271, 44.134026],
                    [-121.102773, 44.138125],
                    [-121.10753399999999, 44.3906],
                ],
            ],
        },
        properties: {
            CSAFP: '140',
            CBSAFP: '39260',
            AFFGEOID: '310M600US39260',
            GEOID: '39260',
            NAME: 'Prineville, OR',
            NAMELSAD: 'Prineville, OR Micro Area',
            LSAD: 'M2',
            ALAND: 7715390857,
            AWATER: 21004473,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.995048, 35.254663, -78.006551, 36.266191],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.936271, 35.771898],
                    [-78.90942500000001, 35.842937],
                    [-78.906071, 35.868096],
                    [-78.82996500000002, 35.866978],
                    [-78.806756, 35.922925],
                    [-78.75821, 35.921558000000005],
                    [-78.699316, 36.011448],
                    [-78.751273, 36.070834],
                    [-78.683224, 36.074484],
                    [-78.546414, 36.021826],
                    [-78.544812, 36.080846],
                    [-78.496614, 36.175199],
                    [-78.42188, 36.164309],
                    [-78.37814, 36.235065],
                    [-78.306965, 36.266191],
                    [-78.133399, 36.246383],
                    [-78.103202, 36.209062],
                    [-78.006551, 36.202633],
                    [-78.255973, 35.81812],
                    [-78.232876, 35.751427],
                    [-78.192117, 35.730545],
                    [-78.161255, 35.712871],
                    [-78.11564100000001, 35.600433],
                    [-78.064784, 35.585263],
                    [-78.15491, 35.41781],
                    [-78.153243, 35.350671],
                    [-78.30658, 35.287604],
                    [-78.411734, 35.254663],
                    [-78.491897, 35.263319],
                    [-78.538349, 35.31641],
                    [-78.529851, 35.334935],
                    [-78.660555, 35.496245],
                    [-78.708902, 35.519458],
                    [-78.914732, 35.583682],
                    [-78.995048, 35.610166],
                    [-78.936271, 35.771898],
                ],
            ],
        },
        properties: {
            CSAFP: '450',
            CBSAFP: '39580',
            AFFGEOID: '310M600US39580',
            GEOID: '39580',
            NAME: 'Raleigh-Cary, NC',
            NAMELSAD: 'Raleigh-Cary, NC Metro Area',
            LSAD: 'M1',
            ALAND: 5486715590,
            AWATER: 74581088,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.908507, 40.7837843870431, -95.154722, 41.8660952477016],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.905862, 41.453389],
                    [-96.568677, 41.432986],
                    [-96.47072, 41.393239],
                    [-96.329012, 41.393136],
                    [-96.434792, 41.494928],
                    [-96.444785, 41.68363300000001],
                    [-96.134276, 41.68273],
                    [-96.1079378717465, 41.6765089897621],
                    [-96.0876, 41.72218],
                    [-96.064537, 41.793002],
                    [-96.107911, 41.84033900000001],
                    [-96.1268212534654, 41.8660952477016],
                    [-95.672771, 41.863147],
                    [-95.55727700000001, 41.863449],
                    [-95.558143, 41.602383],
                    [-95.497793, 41.602333],
                    [-95.497997, 41.506074],
                    [-95.154722, 41.505211],
                    [-95.155851, 41.159236],
                    [-95.384349, 41.160011],
                    [-95.384964, 40.90155],
                    [-95.795156, 40.901625],
                    [-95.8187272785699, 40.8979480664065],
                    [-95.810709, 40.886681],
                    [-95.84130900000001, 40.845604],
                    [-95.8342438167144, 40.7837843870431],
                    [-96.463764, 40.78396],
                    [-96.463861, 41.016069],
                    [-96.463869, 41.045083],
                    [-96.908507, 41.046091],
                    [-96.905862, 41.453389],
                ],
            ],
        },
        properties: {
            CSAFP: '420',
            CBSAFP: '36540',
            AFFGEOID: '310M600US36540',
            GEOID: '36540',
            NAME: 'Omaha-Council Bluffs, NE-IA',
            NAMELSAD: 'Omaha-Council Bluffs, NE-IA Metro Area',
            LSAD: 'M1',
            ALAND: 11256901462,
            AWATER: 157682956,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.903313, 47.499136, -95.553392, 48.194845],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.903313, 48.194845],
                    [-97.1418970728279, 48.193696665318],
                    [-97.14584495947491, 48.1732234829905],
                    [-96.500843, 48.174108],
                    [-96.500901, 48.021656],
                    [-96.48255, 47.963595],
                    [-96.482092, 47.847428],
                    [-96.352708, 47.847328000000005],
                    [-96.351727, 47.7603],
                    [-95.837177, 47.76089400000001],
                    [-95.836827, 47.848219],
                    [-95.709148, 47.848594],
                    [-95.709619, 47.935805],
                    [-95.582155, 47.933966],
                    [-95.578839, 47.673677],
                    [-95.553392, 47.67361],
                    [-95.554248, 47.499893],
                    [-96.067621, 47.499136],
                    [-96.8559593490532, 47.499173229529],
                    [-96.854073, 47.57201],
                    [-96.882376, 47.649025],
                    [-96.89349330133992, 47.6721269594437],
                    [-97.472745, 47.672051],
                    [-97.882271, 47.672179],
                    [-97.882065, 48.020844000000004],
                    [-97.903313, 48.194845],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '24220',
            AFFGEOID: '310M600US24220',
            GEOID: '24220',
            NAME: 'Grand Forks, ND-MN',
            NAMELSAD: 'Grand Forks, ND-MN Metro Area',
            LSAD: 'M1',
            ALAND: 8824671445,
            AWATER: 77658176,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-118.196274, 35.9635526731109, -115.00084600000001, 39.16343],
            type: 'Polygon',
            coordinates: [
                [
                    [-118.196274, 38.999926],
                    [-117.865116, 39.073654],
                    [-117.77546799999999, 39.093425],
                    [-117.331857, 39.16343],
                    [-116.600946, 39.161463],
                    [-115.90707100000002, 39.161848],
                    [-115.00084600000001, 38.677322],
                    [-115.00084600000001, 38.050757],
                    [-115.893857, 38.050531],
                    [-115.896925, 36.842085],
                    [-115.895753, 36.172227],
                    [-115.84580300000002, 36.122048],
                    [-115.84611392276202, 35.9635526731109],
                    [-115.892975, 35.999967],
                    [-116.09360100000002, 36.155805],
                    [-116.375875, 36.372562],
                    [-116.488233, 36.459097],
                    [-117.000895, 36.847694],
                    [-117.166000564031, 36.9712075385903],
                    [-117.165612, 38.001692],
                    [-117.69102099999999, 38.473797],
                    [-118.196235, 38.919058],
                    [-118.196274, 38.999926],
                ],
            ],
        },
        properties: {
            CSAFP: '332',
            CBSAFP: '37220',
            AFFGEOID: '310M600US37220',
            GEOID: '37220',
            NAME: 'Pahrump, NV',
            NAMELSAD: 'Pahrump, NV Micro Area',
            LSAD: 'M2',
            ALAND: 47090939212,
            AWATER: 43207981,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.132271, 34.9340729057232, -93.695856, 35.774101],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.127213, 35.638788],
                    [-94.807757, 35.638546],
                    [-94.4731193906467, 35.6385471525294],
                    [-94.4930532906959, 35.7592465106763],
                    [-94.212502, 35.752986],
                    [-94.124269, 35.764757],
                    [-93.963054, 35.761005],
                    [-93.910836, 35.759752],
                    [-93.901165, 35.774101],
                    [-93.695856, 35.76862],
                    [-93.710043, 35.375237],
                    [-93.806947, 35.424174],
                    [-93.810086, 35.313031],
                    [-93.917127, 35.319046],
                    [-93.920157, 35.229585],
                    [-94.028872, 35.214176],
                    [-94.028946, 35.211598],
                    [-94.031236, 35.140908],
                    [-94.139339, 35.143521],
                    [-94.140463, 35.099674],
                    [-94.228244, 35.057595],
                    [-94.230551, 34.968547],
                    [-94.4475060874703, 34.9340729057232],
                    [-94.4429265938001, 35.0624856806022],
                    [-94.435316, 35.27589300000001],
                    [-94.431515, 35.369591],
                    [-94.4340725641195, 35.387474395379],
                    [-94.49237, 35.301776],
                    [-94.594801, 35.327974000000005],
                    [-94.668796, 35.295029],
                    [-94.750224, 35.356969],
                    [-94.813629, 35.323344],
                    [-94.914454, 35.347525],
                    [-94.901832, 35.39794],
                    [-95.00651, 35.4146],
                    [-95.049933, 35.458894],
                    [-95.085635, 35.461396],
                    [-95.132271, 35.526055],
                    [-95.127213, 35.638788],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '22900',
            AFFGEOID: '310M600US22900',
            GEOID: '22900',
            NAME: 'Fort Smith, AR-OK',
            NAMELSAD: 'Fort Smith, AR-OK Metro Area',
            LSAD: 'M1',
            ALAND: 6239217065,
            AWATER: 195403825,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.269397, 40.280919, -88.459475, 40.757608],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.269397, 40.594318],
                    [-89.133752, 40.596734],
                    [-89.044368, 40.627428],
                    [-88.984722, 40.664953000000004],
                    [-88.986896, 40.752297],
                    [-88.929331, 40.753337],
                    [-88.584272, 40.757608],
                    [-88.574502, 40.61655],
                    [-88.459475, 40.617345],
                    [-88.459957, 40.39885],
                    [-88.460418, 40.281935],
                    [-88.57488500000001, 40.281501],
                    [-88.919544, 40.282865],
                    [-89.148764, 40.282038],
                    [-89.148772, 40.28252],
                    [-89.26265, 40.280919],
                    [-89.26374, 40.325344],
                    [-89.269397, 40.594318],
                ],
            ],
        },
        properties: {
            CSAFP: '145',
            CBSAFP: '14010',
            AFFGEOID: '310M600US14010',
            GEOID: '14010',
            NAME: 'Bloomington, IL',
            NAMELSAD: 'Bloomington, IL Metro Area',
            LSAD: 'M1',
            ALAND: 3064597291,
            AWATER: 7804856,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-117.02688900000001, 41.996103, -114.990777, 44.510813],
            type: 'Polygon',
            coordinates: [
                [
                    [-117.02688900000001, 43.596033],
                    [-117.02566381044602, 43.680293635954],
                    [-117.02357699999999, 43.823811],
                    [-116.98554503849, 43.8811848528138],
                    [-116.95452000000002, 43.836487],
                    [-116.85315400000002, 43.79271],
                    [-116.712672, 43.807161],
                    [-116.712501, 43.981326],
                    [-116.57222400000002, 43.980485],
                    [-116.532117, 44.066024],
                    [-116.452551, 44.065414],
                    [-116.452068, 44.151465],
                    [-116.358492, 44.151158],
                    [-116.347394, 44.322103],
                    [-116.29987600000001, 44.444576],
                    [-116.213314, 44.510813],
                    [-116.15702200000001, 44.49892800000001],
                    [-116.152295, 44.324519],
                    [-116.21291000000001, 44.32437300000001],
                    [-116.21387100000001, 44.151387],
                    [-116.11249000000001, 44.147839],
                    [-116.10307199999998, 44.236409],
                    [-115.52028700000001, 44.235356],
                    [-115.40140800000002, 44.259623],
                    [-115.357484, 44.345959],
                    [-115.29461400000001, 44.339445],
                    [-115.17896399999998, 44.296521],
                    [-115.167684, 44.20284],
                    [-115.02418099999998, 44.155478],
                    [-115.04356, 44.05882],
                    [-114.990777, 43.949723],
                    [-115.06752700000001, 43.941275],
                    [-115.13620899999998, 43.983859],
                    [-115.181931, 44.090533],
                    [-115.230528, 44.098766],
                    [-115.19706, 44.068993],
                    [-115.287226, 43.93552],
                    [-115.508722, 43.884855],
                    [-115.547538, 43.792667],
                    [-115.59279599999999, 43.798289],
                    [-115.625615, 43.728255],
                    [-115.697508, 43.665373],
                    [-115.797108, 43.638608],
                    [-115.87592699999999, 43.591265],
                    [-115.97571900000001, 43.591438],
                    [-115.974881, 43.36502],
                    [-115.978651, 43.113432],
                    [-116.26620700000001, 43.112269],
                    [-116.191672, 43.082473],
                    [-116.20174000000002, 43.04893],
                    [-116.145817, 43.013416],
                    [-115.964966, 42.943673],
                    [-115.93565500000001, 43.001733],
                    [-115.791812, 42.972348],
                    [-115.77150499999999, 42.938664],
                    [-115.622852, 42.955698],
                    [-115.441522, 42.931127],
                    [-115.45422400000001, 42.767936],
                    [-115.037697, 42.768414],
                    [-115.038109752434, 41.9986341156765],
                    [-115.31387700000002, 41.996103],
                    [-115.62591399999998, 41.997415],
                    [-115.870181, 41.996766],
                    [-116.332763, 41.997283],
                    [-116.625947, 41.997379],
                    [-117.018203216741, 41.9998398503691],
                    [-117.026197, 41.99989],
                    [-117.026551, 42.378557],
                    [-117.026253, 42.807447],
                    [-117.02665199999998, 43.025128],
                    [-117.02688900000001, 43.596033],
                ],
            ],
        },
        properties: {
            CSAFP: '147',
            CBSAFP: '14260',
            AFFGEOID: '310M600US14260',
            GEOID: '14260',
            NAME: 'Boise City, ID',
            NAMELSAD: 'Boise City, ID Metro Area',
            LSAD: 'M1',
            ALAND: 30475337413,
            AWATER: 171002334,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-122.289749096157, 41.993323, -120.879925710236, 43.616645],
            type: 'Polygon',
            coordinates: [
                [
                    [-122.289749096157, 42.0077647304966],
                    [-122.28776, 42.440287],
                    [-122.284689, 42.4745],
                    [-122.28273, 42.996499],
                    [-122.282585, 43.067719],
                    [-122.093776, 43.076257],
                    [-122.03965000000001, 43.214102],
                    [-121.97419900000001, 43.261693],
                    [-122.01040300000001, 43.344697],
                    [-122.13203400000002, 43.440221],
                    [-122.131009, 43.557284],
                    [-122.00236199999999, 43.615498],
                    [-121.33296900000002, 43.616645],
                    [-121.34847099999999, 43.356753],
                    [-121.34967899999998, 42.746644],
                    [-120.883327, 42.744214],
                    [-120.879925710236, 41.9934832599849],
                    [-121.03519500000002, 41.993323],
                    [-121.25109900000001, 41.99757],
                    [-121.43961000000002, 41.99708],
                    [-121.447539998048, 41.9971900332726],
                    [-121.67534799999999, 42.000351],
                    [-121.84671200000001, 42.00307],
                    [-122.101922, 42.005766],
                    [-122.28952700000002, 42.007764],
                    [-122.289749096157, 42.0077647304966],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '28900',
            AFFGEOID: '310M600US28900',
            GEOID: '28900',
            NAME: 'Klamath Falls, OR',
            NAMELSAD: 'Klamath Falls, OR Micro Area',
            LSAD: 'M2',
            ALAND: 15410373389,
            AWATER: 484953082,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.172131, 37.542092, -78.06331, 38.475527],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.144388, 37.856096],
                    [-79.15739, 37.891003],
                    [-79.062454, 37.9176],
                    [-79.005129, 37.88169],
                    [-78.902282, 37.951402],
                    [-78.838874, 38.04737],
                    [-78.781078, 38.080757],
                    [-78.749396, 38.20664800000001],
                    [-78.655149, 38.260632],
                    [-78.663145, 38.277933],
                    [-78.553143, 38.334824],
                    [-78.48574, 38.42157900000001],
                    [-78.452801, 38.475527],
                    [-78.429626, 38.366704],
                    [-78.348971, 38.277413],
                    [-78.288837, 38.272656],
                    [-78.368984, 38.184146],
                    [-78.341674, 38.176898],
                    [-78.209384, 38.131128],
                    [-78.253989, 38.062813],
                    [-78.306763, 38.006474],
                    [-78.205125, 37.95734],
                    [-78.06331, 37.905108],
                    [-78.147714, 37.773949],
                    [-78.159326, 37.748528],
                    [-78.18099, 37.744959],
                    [-78.239748, 37.690495],
                    [-78.463499, 37.759782],
                    [-78.491345, 37.796956],
                    [-78.49771400000002, 37.7631],
                    [-78.643918, 37.733084],
                    [-78.641666, 37.683675],
                    [-78.677134, 37.69624],
                    [-78.739437, 37.631542],
                    [-78.82254, 37.630642],
                    [-78.825238, 37.552542],
                    [-78.869245, 37.542092],
                    [-78.900121, 37.547076],
                    [-78.98601700000002, 37.695546],
                    [-79.067355, 37.720255],
                    [-79.071417, 37.77830300000001],
                    [-79.137964, 37.814141],
                    [-79.172131, 37.803097],
                    [-79.144388, 37.856096],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '16820',
            AFFGEOID: '310M600US16820',
            GEOID: '16820',
            NAME: 'Charlottesville, VA',
            NAMELSAD: 'Charlottesville, VA Metro Area',
            LSAD: 'M1',
            ALAND: 4259304628,
            AWATER: 35681139,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.696755, 32.40984, -85.0011308391237, 32.7516296067514],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.696755, 32.697429],
                    [-85.679854, 32.71253],
                    [-85.593151, 32.72853],
                    [-85.574954, 32.736347],
                    [-85.285043, 32.730731],
                    [-85.1245332227506, 32.7516296067514],
                    [-85.11425, 32.730447],
                    [-85.088533, 32.657958],
                    [-85.0760723726048, 32.6080674751571],
                    [-85.069848, 32.583146],
                    [-85.0071, 32.523868],
                    [-85.0011308391237, 32.5101547883978],
                    [-85.059294, 32.472909],
                    [-85.333843, 32.468639],
                    [-85.330143, 32.410842],
                    [-85.434045, 32.40984],
                    [-85.438575, 32.49709],
                    [-85.489348, 32.496937],
                    [-85.695854, 32.595933],
                    [-85.696755, 32.697429],
                ],
            ],
        },
        properties: {
            CSAFP: '194',
            CBSAFP: '12220',
            AFFGEOID: '310M600US12220',
            GEOID: '12220',
            NAME: 'Auburn-Opelika, AL',
            NAMELSAD: 'Auburn-Opelika, AL Metro Area',
            LSAD: 'M1',
            ALAND: 1573526818,
            AWATER: 21516747,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.631494, 41.584949, -88.938679, 41.90493200000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.62933, 41.901617],
                    [-89.421901, 41.90493200000001],
                    [-89.41454, 41.885549],
                    [-88.941279, 41.891752],
                    [-88.938679, 41.628316],
                    [-89.16715, 41.628657000000004],
                    [-89.166561, 41.585289],
                    [-89.631494, 41.584949],
                    [-89.62933, 41.901617],
                ],
            ],
        },
        properties: {
            CSAFP: '221',
            CBSAFP: '19940',
            AFFGEOID: '310M600US19940',
            GEOID: '19940',
            NAME: 'Dixon, IL',
            NAMELSAD: 'Dixon, IL Micro Area',
            LSAD: 'M2',
            ALAND: 1877189070,
            AWATER: 9732924,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.562512, 33.721967, -96.933305, 34.507036],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.562512, 34.289183],
                    [-97.562323, 34.507036],
                    [-97.352183, 34.506796],
                    [-97.352115, 34.376188],
                    [-97.038239, 34.375707000000006],
                    [-97.028817, 34.332299],
                    [-96.933305, 34.332561],
                    [-96.933503, 34.172748],
                    [-96.968424, 34.172684],
                    [-96.969627, 34.07102],
                    [-96.934833, 34.01293],
                    [-96.934343303133, 33.9455889292528],
                    [-96.9446168883705, 33.945013250454],
                    [-96.95231300000002, 33.944582],
                    [-96.988745, 33.918468],
                    [-96.985567, 33.886522],
                    [-97.055838, 33.855741],
                    [-97.07859, 33.812756],
                    [-97.087852, 33.774099],
                    [-97.091072, 33.735115],
                    [-97.14939400000002, 33.721967],
                    [-97.205652, 33.809824],
                    [-97.166629, 33.847311],
                    [-97.206141, 33.91428],
                    [-97.24618, 33.900344],
                    [-97.318243, 33.865121],
                    [-97.372941, 33.819454],
                    [-97.444193, 33.823773],
                    [-97.451469, 33.87093],
                    [-97.4841434385876, 33.9138891088909],
                    [-97.48650500000001, 33.916994],
                    [-97.55827, 33.897099],
                    [-97.5612431502211, 33.899061506733],
                    [-97.536818, 33.983877],
                    [-97.56076100000001, 34.07115],
                    [-97.562365, 34.09485],
                    [-97.562512, 34.289183],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '11620',
            AFFGEOID: '310M600US11620',
            GEOID: '11620',
            NAME: 'Ardmore, OK',
            NAMELSAD: 'Ardmore, OK Micro Area',
            LSAD: 'M2',
            ALAND: 3460531266,
            AWATER: 77483918,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.356033, 35.24755, -88.360829, 36.222834000000006],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.339567, 35.862268],
                    [-89.342829, 35.880934],
                    [-89.280188, 35.886416],
                    [-89.188844, 35.99976],
                    [-89.206297, 36.011814],
                    [-89.163406, 36.026044],
                    [-89.154464, 36.204678],
                    [-88.961232, 36.201635],
                    [-88.959839, 36.222834000000006],
                    [-88.863298, 36.137639],
                    [-88.692709, 36.062746],
                    [-88.706811, 35.791075],
                    [-88.606862, 35.789354],
                    [-88.603575, 35.626084],
                    [-88.61361100000002, 35.588089],
                    [-88.56557000000001, 35.587088],
                    [-88.505354, 35.51203],
                    [-88.364005, 35.492801],
                    [-88.360829, 35.418972],
                    [-88.362572, 35.380994],
                    [-88.57176300000002, 35.382084000000006],
                    [-88.640934, 35.361017],
                    [-88.719761, 35.24755],
                    [-88.781768, 35.247587],
                    [-88.792777, 35.329188],
                    [-88.841607, 35.427826],
                    [-89.07887600000001, 35.431428],
                    [-89.069544, 35.692679],
                    [-89.338704, 35.789273],
                    [-89.356033, 35.817635],
                    [-89.339567, 35.862268],
                ],
            ],
        },
        properties: {
            CSAFP: '297',
            CBSAFP: '27180',
            AFFGEOID: '310M600US27180',
            GEOID: '27180',
            NAME: 'Jackson, TN',
            NAMELSAD: 'Jackson, TN Metro Area',
            LSAD: 'M1',
            ALAND: 4431944974,
            AWATER: 7224174,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.570351, 39.418858, -94.9657474186335, 39.65298],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.564126, 39.652872],
                    [-95.33974000000002, 39.65298],
                    [-95.11518900000002, 39.652549],
                    [-95.04405075257041, 39.6136682968209],
                    [-95.047165, 39.595117],
                    [-95.076688, 39.576764],
                    [-95.113557, 39.553941],
                    [-95.091419402824, 39.53325788521],
                    [-95.049845, 39.494415],
                    [-94.982144, 39.440552],
                    [-94.9657474186335, 39.421681744656404],
                    [-94.997852, 39.418858],
                    [-95.180891, 39.419218],
                    [-95.570351, 39.41905],
                    [-95.564126, 39.652872],
                ],
            ],
        },
        properties: {
            CSAFP: '312',
            CBSAFP: '11860',
            AFFGEOID: '310M600US11860',
            GEOID: '11860',
            NAME: 'Atchison, KS',
            NAMELSAD: 'Atchison, KS Micro Area',
            LSAD: 'M2',
            ALAND: 1116736555,
            AWATER: 6851102,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.759753, 31.685194, -89.653025, 33.375121],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.759753, 32.835763],
                    [-90.656632, 32.92251],
                    [-90.449893, 32.92329],
                    [-90.449323, 33.00901],
                    [-90.364031, 33.010596],
                    [-90.43195, 33.095917],
                    [-90.330312, 33.137818],
                    [-90.331589, 33.302825],
                    [-90.279627, 33.339509],
                    [-90.321513, 33.375121],
                    [-90.174614, 33.333378],
                    [-89.749441, 33.214478],
                    [-89.89636300000001, 32.932602],
                    [-89.965882, 32.879573],
                    [-89.840423, 32.885408],
                    [-89.730016, 32.885072],
                    [-89.730304, 32.634228],
                    [-89.770913, 32.60615],
                    [-89.784123, 32.58689100000001],
                    [-89.730063, 32.352733],
                    [-89.730424, 32.222039],
                    [-89.730066, 32.04707200000001],
                    [-89.662086, 32.047239],
                    [-89.653025, 31.780653],
                    [-89.755103, 31.774654],
                    [-89.974586, 31.761655],
                    [-90.122565, 31.75268],
                    [-90.195422, 31.727915],
                    [-90.245191, 31.717523999999997],
                    [-90.379409, 31.685194],
                    [-90.414198, 31.700455999999996],
                    [-90.736824, 31.698491],
                    [-90.73628200000002, 31.786134],
                    [-90.716785, 31.844583000000004],
                    [-90.718297, 32.048352],
                    [-90.728622, 32.225119],
                    [-90.705175, 32.346788],
                    [-90.636162, 32.374498],
                    [-90.553821, 32.507574],
                    [-90.654563, 32.546042],
                    [-90.655527, 32.589771],
                    [-90.720102, 32.618858],
                    [-90.691405, 32.636284],
                    [-90.72315900000001, 32.662128],
                    [-90.667678, 32.670983],
                    [-90.742634, 32.729225],
                    [-90.694631, 32.815642],
                    [-90.759753, 32.835763],
                ],
            ],
        },
        properties: {
            CSAFP: '298',
            CBSAFP: '27140',
            AFFGEOID: '310M600US27140',
            GEOID: '27140',
            NAME: 'Jackson, MS',
            NAMELSAD: 'Jackson, MS Metro Area',
            LSAD: 'M1',
            ALAND: 13999307733,
            AWATER: 230386947,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.860164, 35.241043, -84.40102500000002, 35.644651],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.805544, 35.448571],
                    [-84.619865, 35.644651],
                    [-84.584472, 35.644424],
                    [-84.566934, 35.629773],
                    [-84.52585, 35.624186],
                    [-84.409226, 35.398378],
                    [-84.40102500000002, 35.330374],
                    [-84.495529, 35.285472000000006],
                    [-84.667398, 35.268041],
                    [-84.703607, 35.241043],
                    [-84.69783800000002, 35.253173],
                    [-84.744441, 35.264223],
                    [-84.72314, 35.285471],
                    [-84.860164, 35.350074],
                    [-84.805544, 35.448571],
                ],
            ],
        },
        properties: {
            CSAFP: '174',
            CBSAFP: '11940',
            AFFGEOID: '310M600US11940',
            GEOID: '11940',
            NAME: 'Athens, TN',
            NAMELSAD: 'Athens, TN Micro Area',
            LSAD: 'M2',
            ALAND: 1113997311,
            AWATER: 5340568,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.192117, 35.585263, -77.665131, 35.867208],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.175342, 35.739529],
                    [-77.91565700000001, 35.83645],
                    [-77.844737, 35.837618],
                    [-77.828444, 35.867208],
                    [-77.751339, 35.827794],
                    [-77.718436, 35.728823],
                    [-77.665131, 35.674935],
                    [-77.700692, 35.65229500000001],
                    [-77.801777, 35.586205],
                    [-77.822511, 35.585383],
                    [-77.873751, 35.586241],
                    [-78.048572, 35.595877],
                    [-78.064784, 35.585263],
                    [-78.11564100000001, 35.600433],
                    [-78.161255, 35.712871],
                    [-78.192117, 35.730545],
                    [-78.175342, 35.739529],
                ],
            ],
        },
        properties: {
            CSAFP: '468',
            CBSAFP: '48980',
            AFFGEOID: '310M600US48980',
            GEOID: '48980',
            NAME: 'Wilson, NC',
            NAMELSAD: 'Wilson, NC Micro Area',
            LSAD: 'M2',
            ALAND: 952013027,
            AWATER: 14332439,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.9959431575134, 29.924059309219, -85.383948, 30.567574],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.992736, 30.38932],
                    [-85.8535, 30.440623],
                    [-85.487968, 30.436655000000002],
                    [-85.486358, 30.567574],
                    [-85.434782, 30.567560000000004],
                    [-85.383948, 30.566856000000005],
                    [-85.389679, 30.200973000000005],
                    [-85.3891608928388, 29.924059309219],
                    [-85.425956, 29.949888000000005],
                    [-85.487764, 29.961227000000004],
                    [-85.571907, 30.026440000000004],
                    [-85.601178, 30.056342],
                    [-85.69681000000001, 30.09689],
                    [-85.811219, 30.178319999999996],
                    [-85.9959431575134, 30.268823267328404],
                    [-85.992736, 30.38932],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '37460',
            AFFGEOID: '310M600US37460',
            GEOID: '37460',
            NAME: 'Panama City, FL',
            NAMELSAD: 'Panama City, FL Metro Area',
            LSAD: 'M1',
            ALAND: 1964874033,
            AWATER: 1833915193,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.3473046214524, 30.665307, -89.728147, 31.002431],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.3473046214524, 31.000360547908905],
                    [-90.346007, 31.000363000000004],
                    [-90.2595549404913, 31.0006617812291],
                    [-89.897516, 31.001913],
                    [-89.8359078243282, 31.002101423117605],
                    [-89.728147, 31.002431],
                    [-89.7281756901606, 31.002313887984105],
                    [-89.750073, 30.91293],
                    [-89.791745, 30.820387],
                    [-89.836331, 30.727197],
                    [-89.8261803591152, 30.668823270185204],
                    [-89.99459, 30.665307],
                    [-90.255749, 30.712443999999998],
                    [-90.317005, 30.89116],
                    [-90.346806, 30.905628],
                    [-90.3473046214524, 31.000360547908905],
                ],
            ],
        },
        properties: {
            CSAFP: '406',
            CBSAFP: '14220',
            AFFGEOID: '310M600US14220',
            GEOID: '14220',
            NAME: 'Bogalusa, LA',
            NAMELSAD: 'Bogalusa, LA Micro Area',
            LSAD: 'M2',
            ALAND: 1734352072,
            AWATER: 16140411,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.441689, 31.346750999999998, -92.718899, 32.148509],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.441171, 31.845233000000004],
                    [-93.419781, 31.934058],
                    [-93.355452, 31.932222],
                    [-93.239501, 31.90352],
                    [-93.23847, 31.972781],
                    [-93.121188, 31.97261],
                    [-93.187138, 32.148509],
                    [-92.939092, 32.14792],
                    [-92.872174, 31.945856],
                    [-92.9668, 31.781299000000004],
                    [-92.973529, 31.708921999999998],
                    [-92.964615, 31.680720999999995],
                    [-92.908313, 31.625169],
                    [-92.806206, 31.598548000000005],
                    [-92.718899, 31.517476],
                    [-92.947159, 31.346750999999998],
                    [-92.98146, 31.346864],
                    [-92.991988, 31.360473000000002],
                    [-93.236643, 31.364491],
                    [-93.236752, 31.537863],
                    [-93.337625, 31.538274000000005],
                    [-93.338436, 31.714205],
                    [-93.441689, 31.714485],
                    [-93.441171, 31.845233000000004],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '35060',
            AFFGEOID: '310M600US35060',
            GEOID: '35060',
            NAME: 'Natchitoches, LA',
            NAMELSAD: 'Natchitoches, LA Micro Area',
            LSAD: 'M2',
            ALAND: 3246092085,
            AWATER: 119733774,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.613147, 41.623334, -78.915642, 41.9991031002259],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.612071, 41.850328],
                    [-79.6108357749867, 41.9985199041084],
                    [-79.472472, 41.99825500000001],
                    [-79.0612581876299, 41.9988381187249],
                    [-78.983065, 41.998949],
                    [-78.9188983633788, 41.9991031002259],
                    [-78.915642, 41.840896],
                    [-78.948612, 41.830481],
                    [-78.956138, 41.623334],
                    [-79.51207, 41.624559],
                    [-79.613147, 41.623863],
                    [-79.612071, 41.850328],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '47620',
            AFFGEOID: '310M600US47620',
            GEOID: '47620',
            NAME: 'Warren, PA',
            NAMELSAD: 'Warren, PA Micro Area',
            LSAD: 'M2',
            ALAND: 2290280119,
            AWATER: 37368145,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.862168, 31.005097001895205, -91.153864, 31.761864],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.845019, 31.375916000000004],
                    [-91.776779, 31.385215],
                    [-91.855889, 31.423831],
                    [-91.857083, 31.494527],
                    [-91.807098, 31.438793999999998],
                    [-91.73833100000002, 31.431572],
                    [-91.738032, 31.46741],
                    [-91.798754, 31.473354],
                    [-91.779725, 31.530973000000003],
                    [-91.82692600000001, 31.591671000000005],
                    [-91.775836, 31.648626000000004],
                    [-91.737189, 31.614088],
                    [-91.705948, 31.642863000000002],
                    [-91.694469, 31.735285999999995],
                    [-91.635822, 31.729119],
                    [-91.601622, 31.761864],
                    [-91.544791, 31.751731000000003],
                    [-91.39829, 31.753212],
                    [-91.3801245299835, 31.732626953050005],
                    [-91.318576, 31.745315],
                    [-91.3204587332816, 31.7478006144822],
                    [-91.317864, 31.749766],
                    [-91.307062, 31.711361],
                    [-91.301028, 31.713863],
                    [-91.302116, 31.707495999999995],
                    [-91.296926, 31.707374000000005],
                    [-91.299095, 31.700088999999995],
                    [-91.294002, 31.697893999999998],
                    [-91.291888, 31.691702999999997],
                    [-91.292648, 31.687112],
                    [-91.300281, 31.681197],
                    [-91.297343, 31.678585000000005],
                    [-91.300257, 31.672487999999998],
                    [-91.153864, 31.610068000000002],
                    [-91.15815, 31.346695],
                    [-91.306294, 31.360862],
                    [-91.468435, 31.319569999999995],
                    [-91.488954, 31.22592],
                    [-91.5909938930645, 31.1919985656988],
                    [-91.621671, 31.13687],
                    [-91.594693, 31.091443999999996],
                    [-91.560365, 31.049507999999996],
                    [-91.6282572982088, 31.005097001895205],
                    [-91.723804, 31.044254],
                    [-91.719004, 31.134615999999998],
                    [-91.677302, 31.188047],
                    [-91.83428, 31.266343999999997],
                    [-91.862168, 31.302314000000003],
                    [-91.845019, 31.375916000000004],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '35020',
            AFFGEOID: '310M600US35020',
            GEOID: '35020',
            NAME: 'Natchez, MS-LA',
            NAMELSAD: 'Natchez, MS-LA Micro Area',
            LSAD: 'M2',
            ALAND: 3002824614,
            AWATER: 195596517,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-71.727573, 43.284994, -71.159364, 43.760979],
            type: 'Polygon',
            coordinates: [
                [
                    [-71.651153, 43.621119],
                    [-71.648346, 43.684511],
                    [-71.581403, 43.691949],
                    [-71.521466, 43.697992],
                    [-71.531658, 43.760979],
                    [-71.43989, 43.695558],
                    [-71.344245, 43.62922],
                    [-71.34362, 43.628794],
                    [-71.208941, 43.550517],
                    [-71.162675, 43.538959],
                    [-71.159364, 43.355123],
                    [-71.236011, 43.284994],
                    [-71.349051, 43.346578],
                    [-71.471454, 43.411298],
                    [-71.549423, 43.452389],
                    [-71.60121, 43.435139],
                    [-71.727573, 43.563411],
                    [-71.651153, 43.621119],
                ],
            ],
        },
        properties: {
            CSAFP: '148',
            CBSAFP: '29060',
            AFFGEOID: '310M600US29060',
            GEOID: '29060',
            NAME: 'Laconia, NH',
            NAMELSAD: 'Laconia, NH Micro Area',
            LSAD: 'M2',
            ALAND: 1040779615,
            AWATER: 176541257,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-119.22517599999999, 39.073654, -117.46158699999998, 40.001034],
            type: 'Polygon',
            coordinates: [
                [
                    [-119.22517599999999, 39.999385],
                    [-118.50097900000002, 39.998676],
                    [-117.54174899999998, 40.001034],
                    [-117.46163999999999, 39.886817],
                    [-117.495344, 39.748364],
                    [-117.46158699999998, 39.622644],
                    [-117.48523600000001, 39.545622],
                    [-117.59024, 39.517521],
                    [-117.65639700000001, 39.468043],
                    [-117.64601100000002, 39.426602],
                    [-117.75979500000001, 39.369225],
                    [-117.719897, 39.213377],
                    [-117.80618299999999, 39.145845],
                    [-117.77546799999999, 39.093425],
                    [-117.865116, 39.073654],
                    [-118.755059, 39.074702],
                    [-118.75506800000001, 39.112811],
                    [-118.82942899999999, 39.156334],
                    [-118.890663, 39.2272],
                    [-118.987721, 39.285474],
                    [-119.11771400000002, 39.388658],
                    [-119.11780200000001, 39.518946],
                    [-119.077136, 39.722637],
                    [-119.058566, 39.737129],
                    [-119.164262, 39.654694],
                    [-119.190676, 39.631787],
                    [-119.18934400000002, 39.651423],
                    [-119.170641, 39.68045300000001],
                    [-119.208543, 39.809967],
                    [-119.22517599999999, 39.999385],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '21980',
            AFFGEOID: '310M600US21980',
            GEOID: '21980',
            NAME: 'Fallon, NV',
            NAMELSAD: 'Fallon, NV Micro Area',
            LSAD: 'M2',
            ALAND: 12820847811,
            AWATER: 190742991,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.858405, 37.006035, -78.594394, 37.814141],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.847476, 37.309352],
                    [-79.788151, 37.351116],
                    [-79.778616, 37.381783],
                    [-79.792185, 37.380822],
                    [-79.81420300000002, 37.403654],
                    [-79.79049, 37.40879],
                    [-79.719076, 37.448957],
                    [-79.69006, 37.476374],
                    [-79.65879800000002, 37.48501],
                    [-79.634206, 37.457021],
                    [-79.626124, 37.455284],
                    [-79.588526, 37.449596],
                    [-79.498699, 37.532797],
                    [-79.434068, 37.572327],
                    [-79.436947, 37.617057],
                    [-79.345235, 37.660625],
                    [-79.231557, 37.813175],
                    [-79.172131, 37.803097],
                    [-79.137964, 37.814141],
                    [-79.071417, 37.77830300000001],
                    [-79.067355, 37.720255],
                    [-78.98601700000002, 37.695546],
                    [-78.900121, 37.547076],
                    [-78.869245, 37.542092],
                    [-78.825238, 37.552542],
                    [-78.635714, 37.416193],
                    [-78.63065, 37.384437],
                    [-78.594436, 37.397974],
                    [-78.594394, 37.345542],
                    [-78.681573, 37.248759],
                    [-78.777293, 37.229107],
                    [-78.824209, 37.205361],
                    [-78.904587, 37.022288],
                    [-78.971675, 37.05006],
                    [-79.092264, 37.061498],
                    [-79.194525, 37.055816],
                    [-79.217104, 37.118874],
                    [-79.341422, 37.137166],
                    [-79.442859, 37.055867],
                    [-79.479921, 37.006035],
                    [-79.594069, 37.042206],
                    [-79.670485, 37.149621],
                    [-79.718275, 37.18972],
                    [-79.84721700000001, 37.225406],
                    [-79.858405, 37.267155],
                    [-79.847476, 37.309352],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '31340',
            AFFGEOID: '310M600US31340',
            GEOID: '31340',
            NAME: 'Lynchburg, VA',
            NAMELSAD: 'Lynchburg, VA Metro Area',
            LSAD: 'M1',
            ALAND: 5491999818,
            AWATER: 68485365,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.7555738086957, 28.665897, -82.16914100000001, 29.04765],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.7555738086957, 29.0009301182604],
                    [-82.712039, 29.030834],
                    [-82.612653, 29.009271000000002],
                    [-82.535591, 29.044855],
                    [-82.458566, 29.04765],
                    [-82.349612, 28.988672],
                    [-82.311697, 28.960390999999998],
                    [-82.16914100000001, 28.792954000000005],
                    [-82.263052, 28.667634],
                    [-82.417684, 28.665897],
                    [-82.418353, 28.694858999999997],
                    [-82.6687096341287, 28.694077261821104],
                    [-82.668722, 28.695658],
                    [-82.712373, 28.720921000000004],
                    [-82.713121, 28.800283],
                    [-82.730245, 28.850155],
                    [-82.688864, 28.905609],
                    [-82.72386100000001, 28.953506],
                    [-82.7555738086957, 29.0009301182604],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '26140',
            AFFGEOID: '310M600US26140',
            GEOID: '26140',
            NAME: 'Homosassa Springs, FL',
            NAMELSAD: 'Homosassa Springs, FL Metro Area',
            LSAD: 'M1',
            ALAND: 1507163436,
            AWATER: 981145546,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.695804, 40.17719, -86.242365, 40.432156],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.695637, 40.214367],
                    [-86.694665, 40.432156],
                    [-86.375762, 40.431851],
                    [-86.309249, 40.431712],
                    [-86.242992, 40.373661],
                    [-86.242743, 40.215834],
                    [-86.242365, 40.180778],
                    [-86.356454, 40.178109],
                    [-86.39885, 40.17719],
                    [-86.695804, 40.178657],
                    [-86.695637, 40.214367],
                ],
            ],
        },
        properties: {
            CSAFP: '320',
            CBSAFP: '23140',
            AFFGEOID: '310M600US23140',
            GEOID: '23140',
            NAME: 'Frankfort, IN',
            NAMELSAD: 'Frankfort, IN Micro Area',
            LSAD: 'M2',
            ALAND: 1049119194,
            AWATER: 461117,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-105.330382, 38.519569, -104.051568, 39.129783],
            type: 'Polygon',
            coordinates: [
                [
                    [-105.329445, 39.129487],
                    [-105.033473, 39.129783],
                    [-104.662896, 39.129527],
                    [-104.051568, 39.128138],
                    [-104.055528, 38.868868],
                    [-104.05426, 38.522392],
                    [-104.941533, 38.519569],
                    [-104.942205, 38.650133],
                    [-105.239927, 38.647464],
                    [-105.237043, 38.697267],
                    [-105.328962, 38.697558],
                    [-105.330382, 39.129474],
                    [-105.329445, 39.129487],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '17820',
            AFFGEOID: '310M600US17820',
            GEOID: '17820',
            NAME: 'Colorado Springs, CO',
            NAMELSAD: 'Colorado Springs, CO Metro Area',
            LSAD: 'M1',
            ALAND: 6950375102,
            AWATER: 12030556,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.941911, 40.155111, -80.518991, 40.638801],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.92456600000001, 40.467975],
                    [-80.92092, 40.556315],
                    [-80.863567, 40.555411],
                    [-80.861994, 40.599404],
                    [-80.747112, 40.596991],
                    [-80.667957, 40.582496],
                    [-80.627171, 40.61993600000001],
                    [-80.583633, 40.61552],
                    [-80.518991, 40.638801],
                    [-80.5190171173474, 40.4773631462371],
                    [-80.5190296911941, 40.3996410563407],
                    [-80.519039, 40.34210100000001],
                    [-80.5190843704566, 40.1596719951181],
                    [-80.664006, 40.178947],
                    [-80.684173210708, 40.1870179733507],
                    [-80.7025753036226, 40.1571413980097],
                    [-80.766269, 40.155111],
                    [-80.882892, 40.159495],
                    [-80.865504, 40.422929],
                    [-80.941911, 40.4247],
                    [-80.92456600000001, 40.467975],
                ],
            ],
        },
        properties: {
            CSAFP: '430',
            CBSAFP: '48260',
            AFFGEOID: '310M600US48260',
            GEOID: '48260',
            NAME: 'Weirton-Steubenville, WV-OH',
            NAMELSAD: 'Weirton-Steubenville, WV-OH Metro Area',
            LSAD: 'M1',
            ALAND: 1502011858,
            AWATER: 29944993,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-106.190554078709, 40.260457, -104.943371, 40.998173],
            type: 'Polygon',
            coordinates: [
                [
                    [-106.190554078709, 40.9977466125744],
                    [-105.277138, 40.998173],
                    [-105.276859994035, 40.9981729176259],
                    [-104.943371, 40.9980741037298],
                    [-104.94555100000001, 40.349099],
                    [-105.05672, 40.349281],
                    [-105.055089, 40.261793],
                    [-105.15906, 40.261219],
                    [-105.653321, 40.260457],
                    [-105.81534, 40.421486],
                    [-105.807341, 40.474527],
                    [-105.854926, 40.486252],
                    [-105.90785700000002, 40.515493],
                    [-105.910892, 40.56642],
                    [-106.04797100000002, 40.804643],
                    [-106.185307, 40.933973],
                    [-106.190554078709, 40.9977466125744],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '22660',
            AFFGEOID: '310M600US22660',
            GEOID: '22660',
            NAME: 'Fort Collins, CO',
            NAMELSAD: 'Fort Collins, CO Metro Area',
            LSAD: 'M1',
            ALAND: 6723017943,
            AWATER: 98980718,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.092563, 39.864845, -86.695071, 40.214806],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.092563, 40.214806],
                    [-86.695637, 40.214367],
                    [-86.695804, 40.178657],
                    [-86.695071, 39.92277],
                    [-86.695127, 39.864845],
                    [-87.009477, 39.866705],
                    [-87.091542, 39.86726500000001],
                    [-87.091006, 39.953009],
                    [-87.092563, 40.214806],
                ],
            ],
        },
        properties: {
            CSAFP: '294',
            CBSAFP: '18820',
            AFFGEOID: '310M600US18820',
            GEOID: '18820',
            NAME: 'Crawfordsville, IN',
            NAMELSAD: 'Crawfordsville, IN Micro Area',
            LSAD: 'M2',
            ALAND: 1307030257,
            AWATER: 2067987,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.246339, 34.073942, -88.1549021840087, 34.756781],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.246339, 34.379344],
                    [-88.928901, 34.379361],
                    [-88.824966, 34.364759],
                    [-88.789442, 34.49493],
                    [-88.736465, 34.50891],
                    [-88.73544, 34.596483],
                    [-88.717916, 34.605209],
                    [-88.718508, 34.756781],
                    [-88.365472, 34.755604],
                    [-88.330699, 34.740721],
                    [-88.329193, 34.463571],
                    [-88.1549021840087, 34.46303430044],
                    [-88.1732617345949, 34.3210395343343],
                    [-88.2035834952194, 34.08652773146811],
                    [-88.543352, 34.089044],
                    [-88.712454, 34.089241],
                    [-88.718964, 34.074521],
                    [-88.824523, 34.074948],
                    [-89.139158, 34.074116],
                    [-89.244436, 34.073942],
                    [-89.245485, 34.161186],
                    [-89.246339, 34.379344],
                ],
            ],
        },
        properties: {
            CSAFP: '539',
            CBSAFP: '46180',
            AFFGEOID: '310M600US46180',
            GEOID: '46180',
            NAME: 'Tupelo, MS',
            NAMELSAD: 'Tupelo, MS Micro Area',
            LSAD: 'M2',
            ALAND: 4909474796,
            AWATER: 44772647,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-101.17456200000001, 32.525312, -100.65587, 32.970225000000006],
            type: 'Polygon',
            coordinates: [
                [
                    [-101.173378, 32.963597],
                    [-101.03866, 32.970225000000006],
                    [-100.990181, 32.965023],
                    [-100.65587, 32.963469],
                    [-100.660626, 32.525312],
                    [-101.17456200000001, 32.527703],
                    [-101.173378, 32.963597],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '43660',
            AFFGEOID: '310M600US43660',
            GEOID: '43660',
            NAME: 'Snyder, TX',
            NAMELSAD: 'Snyder, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2345090474,
            AWATER: 5436957,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.634943, 30.280435, -86.7856919760059, 30.999026000000004],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.592064, 30.95146],
                    [-87.598937, 30.997422000000004],
                    [-87.5988289462621, 30.997422176905296],
                    [-87.519533, 30.997552000000002],
                    [-87.425791, 30.998057999999997],
                    [-87.312206, 30.998403999999997],
                    [-87.163645308459, 30.999021835748],
                    [-87.16264400000001, 30.999026000000004],
                    [-86.927851, 30.997678000000004],
                    [-86.831979, 30.997354],
                    [-86.7856919760059, 30.9969829935168],
                    [-86.7995592762003, 30.384563878226402],
                    [-86.850625, 30.380967],
                    [-86.9188732979657, 30.369049350879703],
                    [-87.155392, 30.327747999999996],
                    [-87.2288564841704, 30.319732148247404],
                    [-87.267827, 30.31548],
                    [-87.319518, 30.317814],
                    [-87.41985900000002, 30.297128000000004],
                    [-87.518324, 30.280435],
                    [-87.452282, 30.344097],
                    [-87.431784, 30.403193],
                    [-87.366601, 30.436642999999997],
                    [-87.414685, 30.457289],
                    [-87.444722, 30.507484000000005],
                    [-87.43145, 30.550252],
                    [-87.401189, 30.604383000000002],
                    [-87.40018900000001, 30.657201],
                    [-87.442291, 30.692661],
                    [-87.523621, 30.738285],
                    [-87.542268, 30.767481000000004],
                    [-87.634943, 30.865857],
                    [-87.592064, 30.95146],
                ],
            ],
        },
        properties: {
            CSAFP: '426',
            CBSAFP: '37860',
            AFFGEOID: '310M600US37860',
            GEOID: '37860',
            NAME: 'Pensacola-Ferry Pass-Brent, FL',
            NAMELSAD: 'Pensacola-Ferry Pass-Brent, FL Metro Area',
            LSAD: 'M1',
            ALAND: 4323713095,
            AWATER: 1757264754,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.931614, 38.609353, -97.369839, 39.306397],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.931614, 39.277094],
                    [-97.929097, 39.306397],
                    [-97.369839, 39.306011],
                    [-97.371154, 39.131961],
                    [-97.371614, 38.957852],
                    [-97.381205, 38.9289],
                    [-97.371911, 38.609353],
                    [-97.924795, 38.60988],
                    [-97.928592, 38.871099],
                    [-97.92856, 38.958395],
                    [-97.929746, 39.219273],
                    [-97.931614, 39.277094],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '41460',
            AFFGEOID: '310M600US41460',
            GEOID: '41460',
            NAME: 'Salina, KS',
            NAMELSAD: 'Salina, KS Micro Area',
            LSAD: 'M2',
            ALAND: 3732112516,
            AWATER: 4328531,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.156465, 44.683633, -91.650361, 45.209554],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.156465, 45.209554],
                    [-91.665647, 45.207991],
                    [-91.666402, 45.120823],
                    [-91.65139, 45.02978100000001],
                    [-91.650455, 44.855951],
                    [-91.650361, 44.683633],
                    [-92.135202, 44.684374],
                    [-92.136348, 44.857848],
                    [-92.136113, 45.121388],
                    [-92.156465, 45.209554],
                ],
            ],
        },
        properties: {
            CSAFP: '232',
            CBSAFP: '32860',
            AFFGEOID: '310M600US32860',
            GEOID: '32860',
            NAME: 'Menomonie, WI',
            NAMELSAD: 'Menomonie, WI Micro Area',
            LSAD: 'M2',
            ALAND: 2201902840,
            AWATER: 35572613,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.844931, 44.243393, -89.223745, 44.684945],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.844931, 44.684945],
                    [-89.223745, 44.681365],
                    [-89.224813, 44.243393],
                    [-89.59798000000002, 44.245725],
                    [-89.724746, 44.247678],
                    [-89.726725, 44.511192],
                    [-89.844546, 44.511503],
                    [-89.844931, 44.684945],
                ],
            ],
        },
        properties: {
            CSAFP: '554',
            CBSAFP: '44620',
            AFFGEOID: '310M600US44620',
            GEOID: '44620',
            NAME: 'Stevens Point, WI',
            NAMELSAD: 'Stevens Point, WI Micro Area',
            LSAD: 'M2',
            ALAND: 2074252980,
            AWATER: 56835087,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.530322, 36.12029, -87.99092, 36.5014641981633],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.5163580599794, 36.5014641981633],
                    [-88.51192, 36.501457],
                    [-88.4890767387132, 36.5012837190757],
                    [-88.127378, 36.49854],
                    [-88.0533508007759, 36.4999962506816],
                    [-88.061428, 36.443992],
                    [-87.99092, 36.360133],
                    [-88.031779, 36.359809],
                    [-88.095868, 36.359265],
                    [-88.092269, 36.340315],
                    [-88.10266, 36.310933],
                    [-88.090782, 36.29485],
                    [-88.087082, 36.267898],
                    [-88.085228, 36.254549000000004],
                    [-88.212559, 36.12029],
                    [-88.21169800000001, 36.14597400000001],
                    [-88.530322, 36.152023],
                    [-88.5163580599794, 36.5014641981633],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '37540',
            AFFGEOID: '310M600US37540',
            GEOID: '37540',
            NAME: 'Paris, TN',
            NAMELSAD: 'Paris, TN Micro Area',
            LSAD: 'M2',
            ALAND: 1455320362,
            AWATER: 81582236,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.745671, 39.791837, -87.932387, 40.3994],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.745164, 40.055191],
                    [-88.745344, 40.098813],
                    [-88.57488500000001, 40.281501],
                    [-88.460418, 40.281935],
                    [-88.459957, 40.39885],
                    [-88.346987, 40.398417],
                    [-87.932387, 40.3994],
                    [-87.942103, 40.225482],
                    [-87.937645, 39.879803],
                    [-88.349187, 39.879451],
                    [-88.462207, 39.879092],
                    [-88.462288, 39.800795],
                    [-88.473182, 39.791837],
                    [-88.745671, 39.792146],
                    [-88.745164, 40.055191],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '16580',
            AFFGEOID: '310M600US16580',
            GEOID: '16580',
            NAME: 'Champaign-Urbana, IL',
            NAMELSAD: 'Champaign-Urbana, IL Metro Area',
            LSAD: 'M1',
            ALAND: 3717390101,
            AWATER: 6864664,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-104.05569519926101, 44.140772, -103.452453, 44.604777],
            type: 'Polygon',
            coordinates: [
                [
                    [-104.05569519926101, 44.5709903771422],
                    [-103.820639, 44.604777],
                    [-103.56765300000002, 44.604098],
                    [-103.574396, 44.256077],
                    [-103.453016, 44.256609],
                    [-103.452453, 44.140772],
                    [-104.055416190705, 44.1410812067834],
                    [-104.055406377827, 44.1803828740957],
                    [-104.055389, 44.249983],
                    [-104.05569519926101, 44.5709903771422],
                ],
            ],
        },
        properties: {
            CSAFP: '452',
            CBSAFP: '43940',
            AFFGEOID: '310M600US43940',
            GEOID: '43940',
            NAME: 'Spearfish, SD',
            NAMELSAD: 'Spearfish, SD Micro Area',
            LSAD: 'M2',
            ALAND: 2072127354,
            AWATER: 667509,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.217846, 39.653222, -88.745164, 40.055191],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.217846, 39.91699],
                    [-89.143451, 39.917647],
                    [-89.144764, 40.048853],
                    [-88.745164, 40.055191],
                    [-88.745671, 39.792146],
                    [-88.812709, 39.740486],
                    [-88.810575, 39.653222],
                    [-89.02568, 39.654183],
                    [-89.139125, 39.655131],
                    [-89.141967, 39.801273],
                    [-89.217473, 39.813666],
                    [-89.217846, 39.91699],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '19500',
            AFFGEOID: '310M600US19500',
            GEOID: '19500',
            NAME: 'Decatur, IL',
            NAMELSAD: 'Decatur, IL Metro Area',
            LSAD: 'M1',
            ALAND: 1503684111,
            AWATER: 13406665,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.507135, 33.286003, -88.669076, 33.73943],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.507135, 33.721819],
                    [-89.194309, 33.722141],
                    [-89.191781, 33.738602],
                    [-89.034498, 33.73943],
                    [-89.034924, 33.654712],
                    [-89.018343, 33.625119],
                    [-89.018613, 33.562114],
                    [-88.770596, 33.565212],
                    [-88.671526, 33.565135],
                    [-88.67125, 33.506168],
                    [-88.669076, 33.286922],
                    [-88.813053, 33.286424],
                    [-88.965963, 33.286108],
                    [-89.088255, 33.286003],
                    [-89.088652, 33.445821],
                    [-89.088438, 33.532518],
                    [-89.088446, 33.533202],
                    [-89.26110200000001, 33.518594],
                    [-89.381092, 33.460307],
                    [-89.506496, 33.459806],
                    [-89.506872, 33.677605],
                    [-89.507135, 33.721819],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '44260',
            AFFGEOID: '310M600US44260',
            GEOID: '44260',
            NAME: 'Starkville, MS',
            NAMELSAD: 'Starkville, MS Micro Area',
            LSAD: 'M2',
            ALAND: 2277628062,
            AWATER: 14930940,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.576256, 32.511626, -98.056094, 33.007913],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.576023, 32.733834],
                    [-98.575616, 32.954349],
                    [-98.427211, 32.9523],
                    [-98.42655300000001, 33.007913],
                    [-98.056094, 33.003332],
                    [-98.066836, 32.558822],
                    [-98.068545, 32.511626],
                    [-98.475177, 32.513039],
                    [-98.576256, 32.515163],
                    [-98.576023, 32.733834],
                ],
            ],
        },
        properties: {
            CSAFP: '206',
            CBSAFP: '33420',
            AFFGEOID: '310M600US33420',
            GEOID: '33420',
            NAME: 'Mineral Wells, TX',
            NAMELSAD: 'Mineral Wells, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2467090694,
            AWATER: 85364147,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-123.81652500000001, 44.276694, -123.073574, 44.721154],
            type: 'Polygon',
            coordinates: [
                [
                    [-123.81652500000001, 44.315303],
                    [-123.71553800000001, 44.360458],
                    [-123.72007100000002, 44.433374],
                    [-123.59767699999999, 44.433109],
                    [-123.602609, 44.721154],
                    [-123.301882, 44.71983500000001],
                    [-123.14902499999998, 44.720225],
                    [-123.073574, 44.650495],
                    [-123.17088, 44.634245],
                    [-123.26015100000001, 44.555148],
                    [-123.214683, 44.507681000000005],
                    [-123.245678, 44.332099],
                    [-123.18038800000001, 44.283725],
                    [-123.59332300000001, 44.276694],
                    [-123.775598, 44.283547],
                    [-123.81652500000001, 44.315303],
                ],
            ],
        },
        properties: {
            CSAFP: '440',
            CBSAFP: '18700',
            AFFGEOID: '310M600US18700',
            GEOID: '18700',
            NAME: 'Corvallis, OR',
            NAMELSAD: 'Corvallis, OR Metro Area',
            LSAD: 'M1',
            ALAND: 1748781823,
            AWATER: 7670893,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.841871, 38.28851, -91.633998, 39.064204],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.839837, 38.683974],
                    [-92.617147, 38.680013],
                    [-92.498377, 38.922084],
                    [-92.38130400000001, 38.808732],
                    [-92.394866, 38.73913],
                    [-92.357813, 38.679517],
                    [-92.220661, 38.643167],
                    [-92.216388, 38.714495],
                    [-92.135245, 38.813894],
                    [-92.1625, 38.865585],
                    [-92.110387, 39.064204],
                    [-91.633998, 39.059057],
                    [-91.647171, 38.703396],
                    [-91.64037200000001, 38.703792],
                    [-91.644729, 38.28851],
                    [-91.86312, 38.29361],
                    [-92.195675, 38.292237],
                    [-92.194476, 38.335536],
                    [-92.408407, 38.34134400000001],
                    [-92.40348400000002, 38.425331],
                    [-92.495185, 38.427829],
                    [-92.625189, 38.430778],
                    [-92.841871, 38.596792],
                    [-92.839837, 38.683974],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '27620',
            AFFGEOID: '310M600US27620',
            GEOID: '27620',
            NAME: 'Jefferson City, MO',
            NAMELSAD: 'Jefferson City, MO Metro Area',
            LSAD: 'M1',
            ALAND: 5821580093,
            AWATER: 79979072,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.960804, 35.252878, -86.599478, 35.710051],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.960804, 35.417021],
                    [-86.849573, 35.525515],
                    [-86.782016, 35.706595],
                    [-86.751651, 35.696447],
                    [-86.686193, 35.710051],
                    [-86.639436, 35.6859],
                    [-86.664164, 35.491771],
                    [-86.618572, 35.367401],
                    [-86.599478, 35.36495],
                    [-86.602613, 35.327013],
                    [-86.746899, 35.252878],
                    [-86.828301, 35.263677],
                    [-86.906451, 35.314075],
                    [-86.960804, 35.417021],
                ],
            ],
        },
        properties: {
            CSAFP: '400',
            CBSAFP: '30280',
            AFFGEOID: '310M600US30280',
            GEOID: '30280',
            NAME: 'Lewisburg, TN',
            NAMELSAD: 'Lewisburg, TN Micro Area',
            LSAD: 'M2',
            ALAND: 972442319,
            AWATER: 1801922,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.548199, 30.999982101791595, -90.2595549404913, 31.350274],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.548199, 31.349574000000004],
                    [-90.260391, 31.350274],
                    [-90.2595549404913, 31.0006617812291],
                    [-90.346007, 31.000363000000004],
                    [-90.3473046214524, 31.000360547908905],
                    [-90.5475744252331, 30.999982101791595],
                    [-90.548199, 31.349574000000004],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '32620',
            AFFGEOID: '310M600US32620',
            GEOID: '32620',
            NAME: 'McComb, MS',
            NAMELSAD: 'McComb, MS Micro Area',
            LSAD: 'M2',
            ALAND: 1059783761,
            AWATER: 3907959,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.077551, 41.042876, -85.65192800000001, 41.436856],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.077551, 41.172868],
                    [-86.054116, 41.176368],
                    [-86.05923, 41.435812],
                    [-85.653507, 41.436856],
                    [-85.653227, 41.351195],
                    [-85.65192800000001, 41.294776],
                    [-85.652715, 41.178489],
                    [-85.686574, 41.178376],
                    [-85.684181, 41.046716],
                    [-85.795814, 41.043588],
                    [-85.945561, 41.042876],
                    [-86.07593900000002, 41.084878],
                    [-86.077551, 41.172868],
                ],
            ],
        },
        properties: {
            CSAFP: '515',
            CBSAFP: '47700',
            AFFGEOID: '310M600US47700',
            GEOID: '47700',
            NAME: 'Warsaw, IN',
            NAMELSAD: 'Warsaw, IN Micro Area',
            LSAD: 'M2',
            ALAND: 1376438777,
            AWATER: 59432525,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-72.952204, 44.01281, -72.217078, 44.506019],
            type: 'Polygon',
            coordinates: [
                [
                    [-72.922295, 44.222735],
                    [-72.89498, 44.278097],
                    [-72.905958, 44.280554],
                    [-72.861251, 44.3669],
                    [-72.825957, 44.359025],
                    [-72.839829, 44.403452],
                    [-72.804857, 44.451882],
                    [-72.765559, 44.442806],
                    [-72.65434, 44.398297],
                    [-72.597285, 44.479385],
                    [-72.493561, 44.42397700000001],
                    [-72.482734, 44.44174100000001],
                    [-72.434116, 44.506019],
                    [-72.32138, 44.463159],
                    [-72.223806, 44.424888],
                    [-72.220115, 44.421162],
                    [-72.217078, 44.411311],
                    [-72.270128, 44.340385],
                    [-72.316834, 44.298781],
                    [-72.36888, 44.203888],
                    [-72.41919700000001, 44.220781],
                    [-72.443376, 44.1288],
                    [-72.586558, 44.16026],
                    [-72.629096, 44.087836],
                    [-72.682874, 44.01281],
                    [-72.742241, 44.029637],
                    [-72.91677800000001, 44.071886],
                    [-72.952204, 44.161412],
                    [-72.922295, 44.222735],
                ],
            ],
        },
        properties: {
            CSAFP: '162',
            CBSAFP: '12740',
            AFFGEOID: '310M600US12740',
            GEOID: '12740',
            NAME: 'Barre, VT',
            NAMELSAD: 'Barre, VT Micro Area',
            LSAD: 'M2',
            ALAND: 1779477072,
            AWATER: 21517931,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.91384, 39.090497, -79.894688, 39.469599],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.888744, 39.294298],
                    [-80.713324, 39.43025],
                    [-80.618629, 39.450375],
                    [-80.583091, 39.451415],
                    [-80.544521, 39.42912],
                    [-80.494085, 39.469599],
                    [-80.282063, 39.435449],
                    [-80.197286, 39.393186],
                    [-80.087334, 39.386215],
                    [-79.938878, 39.452465],
                    [-79.894688, 39.435588],
                    [-79.895536, 39.299584],
                    [-80.023411, 39.247892],
                    [-80.166387, 39.242283],
                    [-80.224687, 39.170977],
                    [-80.227173, 39.112792],
                    [-80.300209, 39.103857],
                    [-80.53629400000001, 39.147528],
                    [-80.596126, 39.167069],
                    [-80.700642, 39.090497],
                    [-80.728326, 39.095679],
                    [-80.81297, 39.109401],
                    [-80.91384, 39.272947],
                    [-80.888744, 39.294298],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '17220',
            AFFGEOID: '310M600US17220',
            GEOID: '17220',
            NAME: 'Clarksburg, WV',
            NAMELSAD: 'Clarksburg, WV Micro Area',
            LSAD: 'M2',
            ALAND: 2353009814,
            AWATER: 10802215,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-99.377052, 35.464225, -98.623244, 35.812402],
            type: 'Polygon',
            coordinates: [
                [
                    [-99.375817, 35.812188],
                    [-98.631985, 35.812402],
                    [-98.632196, 35.565627],
                    [-98.623244, 35.551522],
                    [-98.623336, 35.464225],
                    [-99.364217, 35.465328],
                    [-99.364141, 35.508793],
                    [-99.377052, 35.55174100000001],
                    [-99.375817, 35.812188],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '48220',
            AFFGEOID: '310M600US48220',
            GEOID: '48220',
            NAME: 'Weatherford, OK',
            NAMELSAD: 'Weatherford, OK Micro Area',
            LSAD: 'M2',
            ALAND: 2560924938,
            AWATER: 34326632,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-107.113672, 39.349469, -106.176464, 39.924914],
            type: 'Polygon',
            coordinates: [
                [
                    [-107.113672, 39.919112],
                    [-107.033968, 39.918913],
                    [-106.656536, 39.919961],
                    [-106.626555, 39.918671],
                    [-106.626569, 39.924785],
                    [-106.434508, 39.924914],
                    [-106.385478, 39.767164],
                    [-106.176464, 39.635722],
                    [-106.25163800000001, 39.465039],
                    [-106.206732, 39.379636],
                    [-106.283812, 39.349469],
                    [-106.426487, 39.361871],
                    [-106.509214, 39.362298],
                    [-107.11344600000001, 39.366066],
                    [-107.113672, 39.919112],
                ],
            ],
        },
        properties: {
            CSAFP: '233',
            CBSAFP: '20780',
            AFFGEOID: '310M600US20780',
            GEOID: '20780',
            NAME: 'Edwards, CO',
            NAMELSAD: 'Edwards, CO Micro Area',
            LSAD: 'M2',
            ALAND: 4362754228,
            AWATER: 18970639,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-100.112098, 29.086318, -99.411817, 29.627514],
            type: 'Polygon',
            coordinates: [
                [
                    [-100.112098, 29.623263000000005],
                    [-100.014188, 29.623495],
                    [-99.60313, 29.627181],
                    [-99.411817, 29.627514],
                    [-99.41388, 29.091335],
                    [-100.111406, 29.086318],
                    [-100.112098, 29.623263000000005],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '46620',
            AFFGEOID: '310M600US46620',
            GEOID: '46620',
            NAME: 'Uvalde, TX',
            NAMELSAD: 'Uvalde, TX Micro Area',
            LSAD: 'M2',
            ALAND: 4019458733,
            AWATER: 17382915,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.062841, 34.106179, -83.615218, 34.514096],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.057632, 34.183277],
                    [-83.989059, 34.195732],
                    [-83.925529, 34.286041],
                    [-83.957077, 34.334011],
                    [-83.981433, 34.357829],
                    [-83.980649, 34.418389],
                    [-83.931886, 34.469345],
                    [-83.843405, 34.505494],
                    [-83.788604, 34.514096],
                    [-83.666415, 34.503602],
                    [-83.615218, 34.431712],
                    [-83.669432, 34.366027],
                    [-83.619918, 34.29538],
                    [-83.817682, 34.127493],
                    [-83.890097, 34.106179],
                    [-84.062841, 34.167873],
                    [-84.057632, 34.183277],
                ],
            ],
        },
        properties: {
            CSAFP: '122',
            CBSAFP: '23580',
            AFFGEOID: '310M600US23580',
            GEOID: '23580',
            NAME: 'Gainesville, GA',
            NAMELSAD: 'Gainesville, GA Metro Area',
            LSAD: 'M1',
            ALAND: 1017835152,
            AWATER: 93987564,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.325242, 43.498297, -97.607012, 43.85105800000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.325242, 43.85105800000001],
                    [-97.967279, 43.850686],
                    [-97.85111, 43.849857],
                    [-97.608517, 43.849059],
                    [-97.607012, 43.499826],
                    [-97.96458600000001, 43.499041],
                    [-98.114758, 43.498297],
                    [-98.320595, 43.498651],
                    [-98.325242, 43.85105800000001],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '33580',
            AFFGEOID: '310M600US33580',
            GEOID: '33580',
            NAME: 'Mitchell, SD',
            NAMELSAD: 'Mitchell, SD Micro Area',
            LSAD: 'M2',
            ALAND: 2253847848,
            AWATER: 5407722,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-156.064364, 18.917466, -154.814417, 20.271548],
            type: 'Polygon',
            coordinates: [
                [
                    [-156.049651, 19.780452],
                    [-156.006267, 19.81758],
                    [-155.976651, 19.85053],
                    [-155.949251, 19.857034],
                    [-155.915662, 19.887126000000002],
                    [-155.892533, 19.932162],
                    [-155.856588, 19.968885],
                    [-155.831948, 19.982775],
                    [-155.825473, 20.025944],
                    [-155.850385, 20.062506],
                    [-155.890646, 20.123576],
                    [-155.90278, 20.177073],
                    [-155.890663, 20.25524],
                    [-155.853293, 20.271548],
                    [-155.798884, 20.254115],
                    [-155.737004, 20.222773],
                    [-155.704331, 20.191695000000003],
                    [-155.637459, 20.153051],
                    [-155.598033, 20.124539],
                    [-155.558933, 20.13157],
                    [-155.502561, 20.114155],
                    [-155.387578, 20.067119],
                    [-155.270316, 20.014525],
                    [-155.166625, 19.93789],
                    [-155.124618, 19.897288],
                    [-155.086341, 19.855399],
                    [-155.091216, 19.776368],
                    [-155.087118, 19.728013],
                    [-155.045382, 19.739824],
                    [-155.006423, 19.739286],
                    [-154.981102, 19.690687],
                    [-154.974342, 19.633201],
                    [-154.947106, 19.604856],
                    [-154.852618, 19.549172],
                    [-154.814417, 19.53009],
                    [-154.816009, 19.500648],
                    [-154.876618, 19.433223],
                    [-154.944185, 19.381852],
                    [-155.020537, 19.331317],
                    [-155.113272, 19.290613],
                    [-155.159635, 19.268375],
                    [-155.205892, 19.260907],
                    [-155.264619, 19.274213],
                    [-155.31337, 19.250698],
                    [-155.360631, 19.20893],
                    [-155.390701, 19.201171],
                    [-155.453516, 19.151952],
                    [-155.505281, 19.137908],
                    [-155.555326, 19.069377],
                    [-155.590697, 19.007673],
                    [-155.613966, 18.970399],
                    [-155.638054, 18.941723],
                    [-155.672005, 18.917466],
                    [-155.726043, 18.969437],
                    [-155.806109, 19.013967],
                    [-155.88155, 19.036644],
                    [-155.914216, 19.099147],
                    [-155.912069, 19.179114],
                    [-155.902565, 19.258427],
                    [-155.890842, 19.298905],
                    [-155.888701, 19.348031],
                    [-155.909087, 19.415455],
                    [-155.924732, 19.45391],
                    [-155.95149, 19.486649],
                    [-155.96935, 19.555963],
                    [-155.978206, 19.608159],
                    [-155.997728, 19.642816],
                    [-156.028982, 19.650098000000003],
                    [-156.033326, 19.66923],
                    [-156.064364, 19.730766],
                    [-156.049651, 19.780452],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '25900',
            AFFGEOID: '310M600US25900',
            GEOID: '25900',
            NAME: 'Hilo, HI',
            NAMELSAD: 'Hilo, HI Micro Area',
            LSAD: 'M2',
            ALAND: 10433635373,
            AWATER: 2739472560,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.881591, 35.504141, -80.0273389151412, 36.5506059569159],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.873205, 36.236305],
                    [-80.624245, 36.28682200000001],
                    [-80.547885, 36.285419],
                    [-80.452656, 36.241651],
                    [-80.451925, 36.261515],
                    [-80.4403427430004, 36.5506059569159],
                    [-80.431605, 36.550219],
                    [-80.295243, 36.543973],
                    [-80.0534550433501, 36.5426394280866],
                    [-80.0273389151412, 36.54249538561421],
                    [-80.035119, 36.257184],
                    [-80.038554, 36.146463],
                    [-80.043238, 36.010758],
                    [-80.046869, 35.920693],
                    [-80.066842, 35.505661],
                    [-80.182559, 35.504151],
                    [-80.183264, 35.504141],
                    [-80.20899, 35.581495],
                    [-80.333317, 35.683563],
                    [-80.334509, 35.720471],
                    [-80.422899, 35.725869],
                    [-80.458923, 35.742758],
                    [-80.69169300000001, 35.863494],
                    [-80.707824, 35.852961],
                    [-80.693197, 36.05126],
                    [-80.881591, 36.055718],
                    [-80.873205, 36.236305],
                ],
            ],
        },
        properties: {
            CSAFP: '268',
            CBSAFP: '49180',
            AFFGEOID: '310M600US49180',
            GEOID: '49180',
            NAME: 'Winston-Salem, NC',
            NAMELSAD: 'Winston-Salem, NC Metro Area',
            LSAD: 'M1',
            ALAND: 5203328303,
            AWATER: 81529764,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.871023, 35.52637, -91.19855600000001, 36.497540382727706],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.84945600000002, 35.867301],
                    [-91.753447, 35.942849],
                    [-91.710483, 35.941784],
                    [-91.682207, 36.000567],
                    [-91.691443, 36.25316000000001],
                    [-91.566061, 36.249961],
                    [-91.565898, 36.337408],
                    [-91.454363, 36.335535],
                    [-91.450023110521, 36.497540382727706],
                    [-91.4068712895641, 36.4971382315405],
                    [-91.274863, 36.374583],
                    [-91.25892, 36.25788],
                    [-91.349834, 36.230967],
                    [-91.35742, 35.89066],
                    [-91.19855600000001, 35.890024],
                    [-91.232503, 35.812655],
                    [-91.35389, 35.709514],
                    [-91.369655, 35.52637],
                    [-91.582094, 35.530332],
                    [-91.745063, 35.534311],
                    [-91.793577, 35.534078],
                    [-91.789645, 35.711784],
                    [-91.837176, 35.705019],
                    [-91.871023, 35.809549],
                    [-91.84945600000002, 35.867301],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '12900',
            AFFGEOID: '310M600US12900',
            GEOID: '12900',
            NAME: 'Batesville, AR',
            NAMELSAD: 'Batesville, AR Micro Area',
            LSAD: 'M2',
            ALAND: 3543973593,
            AWATER: 25460921,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-119.57894200000001, 33.215432, -118.633461, 34.901171],
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [-119.477946706724, 34.3788375587756],
                        [-119.445991, 34.404067],
                        [-119.442269, 34.463949],
                        [-119.442353, 34.561101],
                        [-119.44369100000002, 34.901171],
                        [-119.276435, 34.872481],
                        [-119.242534, 34.814323],
                        [-118.977926, 34.811714],
                        [-118.88040500000001, 34.788655],
                        [-118.738618, 34.498969],
                        [-118.652285, 34.323392],
                        [-118.633461, 34.269522],
                        [-118.667742, 34.236694],
                        [-118.667944, 34.199166],
                        [-118.693834, 34.168557],
                        [-118.723374, 34.167861],
                        [-118.788889, 34.168214],
                        [-118.85647300000001, 34.126765000000006],
                        [-118.940965, 34.07483],
                        [-118.944479511459, 34.046738953351],
                        [-118.954722, 34.048167],
                        [-119.06995899999998, 34.09047],
                        [-119.109784, 34.094566],
                        [-119.22774299999999, 34.161728],
                        [-119.257043, 34.213304],
                        [-119.270144, 34.252903],
                        [-119.276614251859, 34.2563404250143],
                        [-119.313034, 34.275689],
                        [-119.37578, 34.321118000000006],
                        [-119.461036, 34.374064],
                        [-119.477946706724, 34.3788375587756],
                    ],
                ],
                [
                    [
                        [-119.47073665694401, 34.053996622104],
                        [-119.442654, 34.054156],
                        [-119.364214, 34.050794],
                        [-119.363065, 34.000548],
                        [-119.39158699999999, 33.994636],
                        [-119.487719557382, 33.9965151543893],
                        [-119.47073665694401, 34.053996622104],
                    ],
                ],
                [
                    [
                        [-119.57894200000001, 33.278628],
                        [-119.51048900000002, 33.307269],
                        [-119.42717100000002, 33.266023],
                        [-119.42955899999998, 33.228167],
                        [-119.464725, 33.215432],
                        [-119.545872, 33.233406],
                        [-119.57894200000001, 33.278628],
                    ],
                ],
            ],
        },
        properties: {
            CSAFP: '348',
            CBSAFP: '37100',
            AFFGEOID: '310M600US37100',
            GEOID: '37100',
            NAME: 'Oxnard-Thousand Oaks-Ventura, CA',
            NAMELSAD: 'Oxnard-Thousand Oaks-Ventura, CA Metro Area',
            LSAD: 'M1',
            ALAND: 4767622161,
            AWATER: 947345735,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.451916, 40.183812, -88.929331, 41.234479],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.450227, 40.276335],
                    [-90.445627, 40.627638],
                    [-90.444339, 40.714868],
                    [-89.986064, 40.712361],
                    [-89.985479, 40.974495],
                    [-89.984559, 41.149394],
                    [-89.868152, 41.149493],
                    [-89.867847, 41.214925],
                    [-89.857616, 41.234479],
                    [-89.638708, 41.233862],
                    [-89.63853600000002, 41.148545],
                    [-89.466101, 41.148524],
                    [-89.32927, 41.14793100000001],
                    [-89.358437, 41.103849],
                    [-89.162237, 41.104008],
                    [-89.047394, 41.104786],
                    [-89.047718, 40.925749],
                    [-88.93139, 40.92774],
                    [-88.929331, 40.753337],
                    [-88.986896, 40.752297],
                    [-88.984722, 40.664953000000004],
                    [-89.044368, 40.627428],
                    [-89.133752, 40.596734],
                    [-89.269397, 40.594318],
                    [-89.26374, 40.325344],
                    [-89.602979, 40.320129],
                    [-89.714927, 40.319218],
                    [-89.717104, 40.435655],
                    [-89.92468, 40.435921],
                    [-90.03795, 40.375041],
                    [-90.118966, 40.235263],
                    [-90.199635, 40.183812],
                    [-90.451916, 40.188803],
                    [-90.450227, 40.276335],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '37900',
            AFFGEOID: '310M600US37900',
            GEOID: '37900',
            NAME: 'Peoria, IL',
            NAMELSAD: 'Peoria, IL Metro Area',
            LSAD: 'M1',
            ALAND: 8633290080,
            AWATER: 174447369,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.027, 42.209267, -92.064187, 42.907112000000005],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.027, 42.55681],
                    [-92.5543, 42.555854],
                    [-92.554492, 42.642314000000006],
                    [-92.554211, 42.907112000000005],
                    [-92.081568, 42.907013],
                    [-92.081661, 42.642056000000004],
                    [-92.064187, 42.468221],
                    [-92.064766, 42.297259],
                    [-92.298792, 42.297527],
                    [-92.53351, 42.297001],
                    [-92.76809, 42.29668],
                    [-92.767463, 42.21014],
                    [-93.001674, 42.209267],
                    [-93.002902, 42.470638],
                    [-93.027, 42.55681],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '47940',
            AFFGEOID: '310M600US47940',
            GEOID: '47940',
            NAME: 'Waterloo-Cedar Falls, IA',
            NAMELSAD: 'Waterloo-Cedar Falls, IA Metro Area',
            LSAD: 'M1',
            ALAND: 3892988479,
            AWATER: 28187704,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-111.80538, 44.474072, -110.783833, 46.192834],
            type: 'Polygon',
            coordinates: [
                [
                    [-111.805259, 45.798306],
                    [-111.704659, 45.795979],
                    [-111.660057, 45.8335],
                    [-111.587696, 45.906682],
                    [-111.419618, 45.996322],
                    [-111.423831, 46.061951],
                    [-111.349372, 46.122607],
                    [-111.34665200000002, 46.187785],
                    [-111.061956, 46.192834],
                    [-110.783833, 46.192699],
                    [-110.790767, 46.141846],
                    [-110.794774, 45.590682],
                    [-110.857382, 45.59065],
                    [-110.858252, 45.524879],
                    [-110.919222, 45.524861],
                    [-110.917899, 45.351273],
                    [-111.038169, 45.351391],
                    [-111.04431854543, 45.0008800033077],
                    [-111.056888, 44.866658],
                    [-111.055511, 44.725343],
                    [-111.055332727124, 44.6662623099175],
                    [-111.055208, 44.624927],
                    [-111.048974, 44.474072],
                    [-111.122654, 44.493659],
                    [-111.143557, 44.535732],
                    [-111.201459, 44.575696],
                    [-111.224161, 44.623402],
                    [-111.26875, 44.668279],
                    [-111.323669, 44.72447400000001],
                    [-111.377138236735, 44.7511964139636],
                    [-111.35003000000002, 45.350548],
                    [-111.35138, 45.641938],
                    [-111.536241, 45.642257],
                    [-111.659738, 45.743344],
                    [-111.721683, 45.743278],
                    [-111.721847, 45.772369],
                    [-111.763273, 45.772554],
                    [-111.763324, 45.78682800000001],
                    [-111.80538, 45.786748],
                    [-111.805259, 45.798306],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '14580',
            AFFGEOID: '310M600US14580',
            GEOID: '14580',
            NAME: 'Bozeman, MT',
            NAMELSAD: 'Bozeman, MT Micro Area',
            LSAD: 'M2',
            ALAND: 6747831014,
            AWATER: 73615974,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.33636, 38.07717, -75.701777515245, 38.704124],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.33636, 38.492235],
                    [-76.277461, 38.541851],
                    [-76.29004300000001, 38.569158],
                    [-76.279589, 38.60952],
                    [-76.231187, 38.61401],
                    [-76.203065, 38.610741],
                    [-76.165435, 38.6102],
                    [-76.1701618745873, 38.6408420471501],
                    [-76.027717, 38.572433],
                    [-75.945248, 38.674109],
                    [-75.839103, 38.704124],
                    [-75.707555026429, 38.6353853550599],
                    [-75.707551, 38.635335],
                    [-75.701777515245, 38.5607669290786],
                    [-75.823706, 38.481022],
                    [-75.864213, 38.350782],
                    [-75.928628, 38.297272],
                    [-75.9237660824283, 38.246285277002],
                    [-75.9445, 38.249145],
                    [-76.038935, 38.254932],
                    [-76.031988, 38.18742],
                    [-76.011916, 38.122214],
                    [-76.005904, 38.07717],
                    [-76.048692, 38.08672800000001],
                    [-76.095548, 38.125123],
                    [-76.088639, 38.192649],
                    [-76.135513, 38.232185],
                    [-76.217613, 38.305683],
                    [-76.257667, 38.324855],
                    [-76.25, 38.362304],
                    [-76.280551, 38.403143],
                    [-76.33636, 38.492235],
                ],
            ],
        },
        properties: {
            CSAFP: '480',
            CBSAFP: '15700',
            AFFGEOID: '310M600US15700',
            GEOID: '15700',
            NAME: 'Cambridge, MD',
            NAMELSAD: 'Cambridge, MD Micro Area',
            LSAD: 'M2',
            ALAND: 1400573746,
            AWATER: 1145353068,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.22289400000001, 36.982057, -89.128899, 37.603024],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.217657, 37.314967],
                    [-90.145699, 37.312091],
                    [-90.146763, 37.597434],
                    [-89.861123, 37.599286],
                    [-89.602429, 37.603024],
                    [-89.5017910494998, 37.5588957193232],
                    [-89.5124, 37.52981],
                    [-89.471201, 37.466473],
                    [-89.42594, 37.407471],
                    [-89.428185, 37.356158],
                    [-89.4744347243599, 37.334500215676],
                    [-89.248566, 37.335298],
                    [-89.261779, 37.105742],
                    [-89.1666202120197, 37.0721103437999],
                    [-89.128899, 37.017908],
                    [-89.132915, 36.982057],
                    [-89.195039, 36.989768],
                    [-89.257608, 37.015496],
                    [-89.30814691381221, 37.0289484973829],
                    [-89.359456, 37.042606],
                    [-89.384175, 37.10326700000001],
                    [-89.456105, 37.18812],
                    [-89.470525, 37.253357],
                    [-89.482889284644, 37.2609507184141],
                    [-89.62025600000001, 37.233008],
                    [-89.765211, 37.126066],
                    [-89.866627, 37.126227],
                    [-89.959576, 37.127353],
                    [-89.99572, 37.04011],
                    [-90.111247, 37.041205],
                    [-90.110354, 37.085871],
                    [-90.22289400000001, 37.086537],
                    [-90.217657, 37.314967],
                ],
            ],
        },
        properties: {
            CSAFP: '164',
            CBSAFP: '16020',
            AFFGEOID: '310M600US16020',
            GEOID: '16020',
            NAME: 'Cape Girardeau, MO-IL',
            NAMELSAD: 'Cape Girardeau, MO-IL Metro Area',
            LSAD: 'M1',
            ALAND: 3708383562,
            AWATER: 73454289,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-114.285448, 41.98858, -112.999965, 43.199859],
            type: 'Polygon',
            coordinates: [
                [
                    [-114.285448, 42.417333],
                    [-114.069162, 42.417124],
                    [-114.067073, 42.514453],
                    [-114.000388, 42.526373],
                    [-113.931799, 42.535243],
                    [-113.931687, 42.765026],
                    [-113.763862, 42.764508],
                    [-113.76382, 42.849823],
                    [-113.714044, 42.849734],
                    [-113.714642, 43.199783],
                    [-113.41386700000001, 43.199859],
                    [-113.413138, 42.849169],
                    [-113.472155, 42.849201],
                    [-113.47217700000002, 42.669352],
                    [-113.373117, 42.687638],
                    [-113.3044, 42.628749],
                    [-113.236968, 42.625254],
                    [-113.175678, 42.589464],
                    [-113.002792, 42.588003],
                    [-112.999965, 42.327427],
                    [-113.000040139624, 41.9982276125391],
                    [-113.000821, 41.998223],
                    [-113.249159, 41.996203],
                    [-113.496548, 41.993305],
                    [-113.817964, 41.98858],
                    [-114.04172300000002, 41.99372],
                    [-114.28179946722, 41.9942138857579],
                    [-114.285448, 42.417333],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '15420',
            AFFGEOID: '310M600US15420',
            GEOID: '15420',
            NAME: 'Burley, ID',
            NAMELSAD: 'Burley, ID Micro Area',
            LSAD: 'M2',
            ALAND: 8605508859,
            AWATER: 53086488,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-106.37366, 33.132303, -104.88513, 34.347205],
            type: 'Polygon',
            coordinates: [
                [
                    [-106.37366, 33.660504],
                    [-106.052718, 33.650297],
                    [-106.052662, 33.825606],
                    [-105.924685, 33.825624],
                    [-105.925968, 34.25999],
                    [-105.313218, 34.260031000000005],
                    [-105.313072, 34.347205],
                    [-104.891745, 34.347043],
                    [-104.893377, 34.088407],
                    [-104.89243300000001, 33.398199],
                    [-104.88513, 33.305827],
                    [-104.905424, 33.138904],
                    [-105.316968, 33.132303],
                    [-105.317376, 33.305977],
                    [-105.725957, 33.304834],
                    [-105.727171, 33.390633],
                    [-106.34571, 33.390595],
                    [-106.371895, 33.391821],
                    [-106.372797, 33.479875],
                    [-106.37366, 33.660504],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '40760',
            AFFGEOID: '310M600US40760',
            GEOID: '40760',
            NAME: 'Ruidoso, NM',
            NAMELSAD: 'Ruidoso, NM Micro Area',
            LSAD: 'M2',
            ALAND: 12512530053,
            AWATER: 554839,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.780858, 31.7105645979661, -80.84313, 32.5989648538998],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.780858, 32.15289],
                    [-81.43583, 32.241289],
                    [-81.446334, 32.284717],
                    [-81.534904, 32.39358000000001],
                    [-81.548006, 32.489286],
                    [-81.528169, 32.514888000000006],
                    [-81.3869019026942, 32.5989648538998],
                    [-81.328753, 32.561228],
                    [-81.2842383817656, 32.547110946285],
                    [-81.274927, 32.544158],
                    [-81.194829, 32.465086],
                    [-81.194931, 32.411489],
                    [-81.1734737944861, 32.3849027808867],
                    [-81.133032, 32.334794],
                    [-81.128034, 32.276297],
                    [-81.15353100000002, 32.237687],
                    [-81.1475951704554, 32.2271694465971],
                    [-81.119361, 32.177142],
                    [-81.113334, 32.113205],
                    [-81.038265, 32.084469],
                    [-81.006745, 32.101152],
                    [-80.943226, 32.057824],
                    [-80.885517, 32.0346],
                    [-80.84313, 32.024226],
                    [-80.848441, 31.988278999999995],
                    [-80.911207, 31.943769000000003],
                    [-80.941359, 31.912984000000005],
                    [-81.000317, 31.856743999999996],
                    [-81.036873, 31.812721000000003],
                    [-81.077057, 31.761256],
                    [-81.130634, 31.722692000000002],
                    [-81.1352985902005, 31.7105645979661],
                    [-81.154731, 31.713070000000002],
                    [-81.259887, 31.753754],
                    [-81.355837, 31.814900000000005],
                    [-81.407737, 31.944301999999997],
                    [-81.475456, 31.974167000000005],
                    [-81.593292, 31.971233000000005],
                    [-81.60366500000002, 32.008469],
                    [-81.692695, 32.088313],
                    [-81.718658, 32.089351],
                    [-81.75634, 32.104584],
                    [-81.780858, 32.15289],
                ],
            ],
        },
        properties: {
            CSAFP: '496',
            CBSAFP: '42340',
            AFFGEOID: '310M600US42340',
            GEOID: '42340',
            NAME: 'Savannah, GA',
            NAMELSAD: 'Savannah, GA Metro Area',
            LSAD: 'M1',
            ALAND: 3495232087,
            AWATER: 564143049,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.419843, 41.254003, -82.840087, 41.500228],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.414626, 41.500228],
                    [-83.338196, 41.457508],
                    [-83.067459, 41.457169],
                    [-82.95222400000002, 41.458239],
                    [-82.847804, 41.430516],
                    [-82.8446, 41.362779],
                    [-82.841475, 41.290023],
                    [-82.840087, 41.255337],
                    [-83.419843, 41.254003],
                    [-83.414626, 41.500228],
                ],
            ],
        },
        properties: {
            CSAFP: '534',
            CBSAFP: '23380',
            AFFGEOID: '310M600US23380',
            GEOID: '23380',
            NAME: 'Fremont, OH',
            NAMELSAD: 'Fremont, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1057351022,
            AWATER: 24514231,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-116.10617152617601, 32.6185787329199, -114.463127, 33.43354499999981],
            type: 'Polygon',
            coordinates: [
                [
                    [-116.105634, 32.72157],
                    [-116.08109000000002, 33.074833],
                    [-116.08516499999999, 33.425932],
                    [-115.467506, 33.426992],
                    [-114.830833, 33.430094],
                    [-114.629146518189, 33.43354499999981],
                    [-114.63518300000001, 33.422726],
                    [-114.673901, 33.418299],
                    [-114.725282, 33.405048],
                    [-114.70734799999998, 33.376628],
                    [-114.707962, 33.323421],
                    [-114.723259, 33.288079],
                    [-114.67449100000002, 33.255597],
                    [-114.678097, 33.23030000000001],
                    [-114.679359, 33.159519],
                    [-114.706175, 33.105335],
                    [-114.670803, 33.037984],
                    [-114.62829300000001, 33.031052],
                    [-114.575161, 33.036542],
                    [-114.517492069319, 33.0247159744928],
                    [-114.511343, 33.023455],
                    [-114.481315, 32.972064],
                    [-114.47664, 32.923628],
                    [-114.463127, 32.901884],
                    [-114.468971, 32.845155],
                    [-114.531746, 32.782503],
                    [-114.570675, 32.747417],
                    [-114.617386523962, 32.7410527731766],
                    [-114.66749300000001, 32.734226],
                    [-114.705717, 32.741581],
                    [-114.71963300000002, 32.718763],
                    [-115.000802, 32.699676],
                    [-115.465164, 32.6671],
                    [-116.04662, 32.623353],
                    [-116.10617152617601, 32.6185787329199],
                    [-116.105634, 32.72157],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '20940',
            AFFGEOID: '310M600US20940',
            GEOID: '20940',
            NAME: 'El Centro, CA',
            NAMELSAD: 'El Centro, CA Metro Area',
            LSAD: 'M1',
            ALAND: 10814595262,
            AWATER: 790216761,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-123.07781200000001, 38.667506, -122.340172, 39.580668],
            type: 'Polygon',
            coordinates: [
                [
                    [-123.07781200000001, 39.173791],
                    [-122.99483900000001, 39.235924],
                    [-123.075114, 39.407683],
                    [-123.06318100000001, 39.503539],
                    [-122.89031, 39.529014],
                    [-122.88535200000001, 39.580108],
                    [-122.735639, 39.580668],
                    [-122.739062, 39.383266],
                    [-122.78509, 39.382975],
                    [-122.743482, 39.36813],
                    [-122.77504299999998, 39.311778],
                    [-122.680927, 39.238726],
                    [-122.47728999999998, 39.173950000000005],
                    [-122.49127800000001, 39.052991],
                    [-122.41341599999998, 39.020218],
                    [-122.40911800000002, 38.962793],
                    [-122.340172, 38.924246],
                    [-122.403941, 38.925289],
                    [-122.42190399999998, 38.90377],
                    [-122.39505599999998, 38.864245],
                    [-122.39798900000001, 38.804001],
                    [-122.46389, 38.705203],
                    [-122.62739600000002, 38.667506],
                    [-122.77248, 38.82153],
                    [-122.82159200000001, 38.85014],
                    [-122.948865, 38.90022],
                    [-122.98664000000001, 38.997283],
                    [-123.05621200000002, 39.021096],
                    [-123.056115, 39.048815],
                    [-123.07781200000001, 39.173791],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '17340',
            AFFGEOID: '310M600US17340',
            GEOID: '17340',
            NAME: 'Clearlake, CA',
            NAMELSAD: 'Clearlake, CA Micro Area',
            LSAD: 'M2',
            ALAND: 3254452700,
            AWATER: 188748214,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.94139100000001, 36.6286377186817, -85.950661, 37.395123],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.894067, 37.088349],
                    [-86.924273, 37.144623],
                    [-86.899268, 37.212313],
                    [-86.815852, 37.328859],
                    [-86.759533, 37.304099],
                    [-86.612095, 37.395123],
                    [-86.487152, 37.362501],
                    [-86.469126, 37.321099],
                    [-86.187534, 37.340796],
                    [-86.157589, 37.335649],
                    [-86.049133, 37.215285],
                    [-86.05644300000002, 37.167208],
                    [-86.074267, 37.053285],
                    [-86.113512, 37.061072],
                    [-86.16674, 36.934015],
                    [-86.106421, 36.865064],
                    [-86.06531, 36.899128],
                    [-86.069957, 36.805783],
                    [-85.97691, 36.722722],
                    [-85.950661, 36.651023],
                    [-85.975713174144, 36.6286377186817],
                    [-86.081944, 36.633848],
                    [-86.205565269856, 36.6392468703288],
                    [-86.4114961989664, 36.6482404228482],
                    [-86.418977, 36.676274],
                    [-86.405769, 36.776187],
                    [-86.400518, 36.807226],
                    [-86.583289, 36.830282],
                    [-86.611586, 36.883057],
                    [-86.67446200000002, 36.99976600000001],
                    [-86.84535, 37.056513],
                    [-86.94139100000001, 37.068981],
                    [-86.894067, 37.088349],
                ],
            ],
        },
        properties: {
            CSAFP: '150',
            CBSAFP: '14540',
            AFFGEOID: '310M600US14540',
            GEOID: '14540',
            NAME: 'Bowling Green, KY',
            NAMELSAD: 'Bowling Green, KY Metro Area',
            LSAD: 'M1',
            ALAND: 4182926393,
            AWATER: 61821085,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.688491, 40.906502, -81.001695, 41.351157],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.68699, 41.135956],
                    [-81.68495, 41.277146],
                    [-81.670199, 41.277256],
                    [-81.59054200000001, 41.27755400000001],
                    [-81.565139, 41.277758],
                    [-81.598052, 41.351157],
                    [-81.517658, 41.350154],
                    [-81.450331, 41.349148],
                    [-81.391694, 41.348272],
                    [-81.003319, 41.34786],
                    [-81.003191, 41.347859],
                    [-81.00288, 41.271842],
                    [-81.00229, 41.134189],
                    [-81.00236, 41.062093],
                    [-81.001695, 40.987783],
                    [-81.086312, 40.988031],
                    [-81.183849, 40.988208],
                    [-81.195679, 40.988205],
                    [-81.357529, 40.988717],
                    [-81.393249, 40.988531],
                    [-81.420363, 40.906502],
                    [-81.647735, 40.914016],
                    [-81.64768900000001, 40.988563],
                    [-81.688491, 40.98859],
                    [-81.68699, 41.135956],
                ],
            ],
        },
        properties: {
            CSAFP: '184',
            CBSAFP: '10420',
            AFFGEOID: '310M600US10420',
            GEOID: '10420',
            NAME: 'Akron, OH',
            NAMELSAD: 'Akron, OH Metro Area',
            LSAD: 'M1',
            ALAND: 2331502236,
            AWATER: 62132648,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.96363, 30.296786, -96.079969, 31.357128999999997],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.96363, 30.557198000000003],
                    [-96.621423, 30.730764000000004],
                    [-96.61224, 30.75771],
                    [-96.64128, 30.818051],
                    [-96.75633, 30.927206000000005],
                    [-96.767149, 31.042829],
                    [-96.830497, 31.105219999999996],
                    [-96.596862, 31.2209],
                    [-96.319165, 31.357128999999997],
                    [-96.33109, 31.252230000000004],
                    [-96.269199, 31.077855],
                    [-96.260594, 31.077071000000004],
                    [-96.243258, 31.061137],
                    [-96.24102, 30.973737],
                    [-96.207477, 30.947081],
                    [-96.16837, 30.822997000000004],
                    [-96.187765, 30.598737],
                    [-96.121806, 30.443426],
                    [-96.079969, 30.430030000000002],
                    [-96.147255, 30.38284],
                    [-96.15463, 30.330288000000003],
                    [-96.178661, 30.394718000000005],
                    [-96.299173, 30.379151999999998],
                    [-96.357407, 30.373318000000005],
                    [-96.640883, 30.296786],
                    [-96.743672, 30.319756999999996],
                    [-96.87711, 30.447945],
                    [-96.918902, 30.539211999999996],
                    [-96.96363, 30.557198000000003],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '17780',
            AFFGEOID: '310M600US17780',
            GEOID: '17780',
            NAME: 'College Station-Bryan, TX',
            NAMELSAD: 'College Station-Bryan, TX Metro Area',
            LSAD: 'M1',
            ALAND: 5439946981,
            AWATER: 86586475,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-105.72052900000001, 35.041605, -103.637053, 36.261863],
            type: 'Polygon',
            coordinates: [
                [
                    [-105.717477, 35.978741],
                    [-105.568045, 35.973796],
                    [-105.53038000000001, 36.013014],
                    [-105.41999200000001, 36.131704],
                    [-105.409677, 36.236814],
                    [-105.341264, 36.261863],
                    [-104.865244, 36.261419],
                    [-104.865402, 36.217869],
                    [-104.436056, 36.218534],
                    [-104.414546, 36.123761],
                    [-104.327284, 35.960197],
                    [-104.38973600000001, 35.857604],
                    [-104.37012, 35.779302],
                    [-104.021702, 35.781494],
                    [-103.97690100000001, 35.801747],
                    [-103.723609, 35.422785],
                    [-103.637053, 35.389662],
                    [-103.63713, 35.240819],
                    [-103.85753, 35.242405],
                    [-104.125121, 35.142058],
                    [-104.125136, 35.215696],
                    [-105.291169, 35.216489],
                    [-105.290791, 35.04203],
                    [-105.714419, 35.041605],
                    [-105.72052900000001, 35.871185],
                    [-105.717477, 35.978741],
                ],
            ],
        },
        properties: {
            CSAFP: '106',
            CBSAFP: '29780',
            AFFGEOID: '310M600US29780',
            GEOID: '29780',
            NAME: 'Las Vegas, NM',
            NAMELSAD: 'Las Vegas, NM Micro Area',
            LSAD: 'M2',
            ALAND: 17217628895,
            AWATER: 57473567,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-75.415319, 42.314151, -74.630631, 42.908363],
            type: 'Polygon',
            coordinates: [
                [
                    [-75.374905, 42.410784],
                    [-75.403616, 42.509222],
                    [-75.325407, 42.588821],
                    [-75.295877, 42.744106],
                    [-75.251416, 42.784565],
                    [-75.247963, 42.871604],
                    [-75.212158, 42.879973],
                    [-75.210642, 42.85654900000001],
                    [-75.100999, 42.908363],
                    [-74.90673800000002, 42.824943],
                    [-74.878822, 42.898274],
                    [-74.763303, 42.863237],
                    [-74.648298, 42.829558],
                    [-74.667512, 42.750710000000005],
                    [-74.630631, 42.626674],
                    [-74.71158, 42.517799],
                    [-74.844121, 42.512591],
                    [-75.197237, 42.358329],
                    [-75.415319, 42.314151],
                    [-75.374905, 42.410784],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '36580',
            AFFGEOID: '310M600US36580',
            GEOID: '36580',
            NAME: 'Oneonta, NY',
            NAMELSAD: 'Oneonta, NY Micro Area',
            LSAD: 'M2',
            ALAND: 2594487152,
            AWATER: 36314099,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-105.693453, 39.91288600000001, -105.052823, 40.261793],
            type: 'Polygon',
            coordinates: [
                [
                    [-105.693453, 39.944017],
                    [-105.638797, 40.038787000000006],
                    [-105.653321, 40.260457],
                    [-105.15906, 40.261219],
                    [-105.055089, 40.261793],
                    [-105.055393, 40.138263],
                    [-105.055408, 40.018176],
                    [-105.052823, 40.00026],
                    [-105.071899, 39.950198],
                    [-105.109517, 39.956996],
                    [-105.16615, 39.914047],
                    [-105.339547, 39.913786],
                    [-105.397849, 39.91288600000001],
                    [-105.398041, 39.934854],
                    [-105.675798, 39.932445],
                    [-105.693453, 39.944017],
                ],
            ],
        },
        properties: {
            CSAFP: '216',
            CBSAFP: '14500',
            AFFGEOID: '310M600US14500',
            GEOID: '14500',
            NAME: 'Boulder, CO',
            NAMELSAD: 'Boulder, CO Metro Area',
            LSAD: 'M1',
            ALAND: 1881325109,
            AWATER: 36485630,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.65333, 39.368165, -82.024219, 40.7126],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.647169, 39.773029],
                    [-83.588041, 39.768778],
                    [-83.516155, 40.010188],
                    [-83.503714, 40.111468],
                    [-83.494498, 40.225467],
                    [-83.551338, 40.22937],
                    [-83.520227, 40.504077],
                    [-83.41867, 40.505229],
                    [-83.267767, 40.506906],
                    [-83.24829800000002, 40.500997],
                    [-83.248595, 40.443989],
                    [-83.020798, 40.433795],
                    [-82.958401, 40.490664],
                    [-82.957715, 40.635653],
                    [-82.859753, 40.646395],
                    [-82.858302, 40.705019],
                    [-82.838905, 40.7126],
                    [-82.727164, 40.711203],
                    [-82.689113, 40.711033],
                    [-82.627191, 40.709416],
                    [-82.644977, 40.666425],
                    [-82.62360900000002, 40.549879],
                    [-82.646503, 40.345076],
                    [-82.744931, 40.349603],
                    [-82.750747, 40.276996],
                    [-82.476114, 40.264703],
                    [-82.477273, 40.24581],
                    [-82.195911, 40.239071],
                    [-82.183492, 40.22726],
                    [-82.187105, 40.16688],
                    [-82.194786, 40.020694],
                    [-82.198772, 39.95014],
                    [-82.233974, 39.91326],
                    [-82.162385, 39.909371],
                    [-82.170047, 39.82075],
                    [-82.072934, 39.816227],
                    [-82.076639, 39.77096],
                    [-82.080224, 39.72767300000001],
                    [-82.024219, 39.724686],
                    [-82.04752800000001, 39.55082500000001],
                    [-82.159161, 39.556574],
                    [-82.167379, 39.466307],
                    [-82.28041, 39.472906],
                    [-82.28966300000002, 39.384195],
                    [-82.514479, 39.395767],
                    [-82.51758, 39.369468],
                    [-82.748591, 39.368165],
                    [-82.740729, 39.46835],
                    [-82.998204, 39.480061],
                    [-83.266737, 39.516249],
                    [-83.252435, 39.695438],
                    [-83.65333, 39.716876],
                    [-83.647169, 39.773029],
                ],
            ],
        },
        properties: {
            CSAFP: '198',
            CBSAFP: '18140',
            AFFGEOID: '310M600US18140',
            GEOID: '18140',
            NAME: 'Columbus, OH',
            NAMELSAD: 'Columbus, OH Metro Area',
            LSAD: 'M1',
            ALAND: 12422979449,
            AWATER: 139223844,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.215671, 33.529389, -90.657002, 34.1200652947774],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.215671, 33.529423],
                    [-91.206415960587, 33.5456284081563],
                    [-91.188942, 33.576225],
                    [-91.130902, 33.610919],
                    [-91.178311, 33.651109000000005],
                    [-91.10098000000002, 33.660551],
                    [-91.075389, 33.714403],
                    [-91.143287, 33.747141],
                    [-91.111494, 33.774568],
                    [-91.08551, 33.77641],
                    [-91.026782, 33.763642],
                    [-91.025173, 33.805953],
                    [-91.052819, 33.824181],
                    [-91.061247, 33.877505],
                    [-91.02638200000001, 33.90798],
                    [-91.035961, 33.943758],
                    [-91.088696, 33.961334],
                    [-91.048367, 33.985078],
                    [-91.004981, 33.977011],
                    [-90.979945, 34.000106],
                    [-90.942662, 34.01805],
                    [-90.89242, 34.026860000000006],
                    [-90.874541, 34.072041],
                    [-90.90113, 34.094667],
                    [-90.946323, 34.109374],
                    [-90.94484495223631, 34.1164215264479],
                    [-90.94408076019381, 34.1200652947774],
                    [-90.659457, 34.118867],
                    [-90.657002, 33.987586],
                    [-90.658719, 33.616264],
                    [-90.763469, 33.616164],
                    [-90.763509, 33.529389],
                    [-91.2156615141091, 33.5294098627481],
                    [-91.215671, 33.529423],
                ],
            ],
        },
        properties: {
            CSAFP: '185',
            CBSAFP: '17380',
            AFFGEOID: '310M600US17380',
            GEOID: '17380',
            NAME: 'Cleveland, MS',
            NAMELSAD: 'Cleveland, MS Micro Area',
            LSAD: 'M2',
            ALAND: 2270141682,
            AWATER: 81446732,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-121.970427, 35.789276, -120.21397899999998, 36.919683],
            type: 'Polygon',
            coordinates: [
                [
                    [-121.970427, 36.582754],
                    [-121.92386600000002, 36.634559],
                    [-121.860604, 36.611136],
                    [-121.81446200000002, 36.682858],
                    [-121.796826, 36.777543],
                    [-121.791544, 36.815186],
                    [-121.81273242676198, 36.8500494437455],
                    [-121.69995599999999, 36.919683],
                    [-121.644001, 36.893996],
                    [-121.597698, 36.83735],
                    [-121.45134, 36.724793],
                    [-121.46808599999999, 36.685518],
                    [-121.35009200000002, 36.648357],
                    [-121.318373, 36.610026],
                    [-121.311788, 36.502736],
                    [-121.24409100000001, 36.506911],
                    [-121.193465, 36.445751],
                    [-121.040797, 36.323969],
                    [-121.02736200000001, 36.26008],
                    [-120.920551, 36.311012],
                    [-120.86508, 36.292578],
                    [-120.76148300000001, 36.20358],
                    [-120.71842600000001, 36.196848],
                    [-120.71769599999999, 36.260012],
                    [-120.75833999999999, 36.308604],
                    [-120.67858200000002, 36.267319],
                    [-120.62691, 36.203227],
                    [-120.66755100000002, 36.138057],
                    [-120.433053, 35.968933],
                    [-120.31506800000001, 35.907186],
                    [-120.27576, 35.905881],
                    [-120.21397899999998, 35.789276],
                    [-120.75065, 35.791131],
                    [-121.34705395600402, 35.7951862038146],
                    [-121.40682300000002, 35.844623],
                    [-121.46226399999999, 35.885618],
                    [-121.48619999999998, 35.970348],
                    [-121.53187600000001, 36.014368],
                    [-121.574602, 36.025156],
                    [-121.62200899999999, 36.099695],
                    [-121.680145, 36.16581800000001],
                    [-121.77985100000001, 36.227407],
                    [-121.82642499999999, 36.24186],
                    [-121.88849100000002, 36.30281000000001],
                    [-121.90319499999998, 36.393603],
                    [-121.9416, 36.485602],
                    [-121.970427, 36.582754],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '41500',
            AFFGEOID: '310M600US41500',
            GEOID: '41500',
            NAME: 'Salinas, CA',
            NAMELSAD: 'Salinas, CA Metro Area',
            LSAD: 'M1',
            ALAND: 8499623032,
            AWATER: 1267793988,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-124.02520517453, 38.7676562160393, -122.82159200000001, 40.001923],
            type: 'Polygon',
            coordinates: [
                [
                    [-124.02520517453, 40.001299341669],
                    [-123.54445800000002, 40.001923],
                    [-123.544563, 39.977015],
                    [-122.93401300000001, 39.978131],
                    [-122.90978699999998, 39.936385],
                    [-122.95007699999998, 39.907718],
                    [-122.937654, 39.798156],
                    [-122.89267599999998, 39.708898],
                    [-122.88535200000001, 39.580108],
                    [-122.89031, 39.529014],
                    [-123.06318100000001, 39.503539],
                    [-123.075114, 39.407683],
                    [-122.99483900000001, 39.235924],
                    [-123.07781200000001, 39.173791],
                    [-123.056115, 39.048815],
                    [-123.05621200000002, 39.021096],
                    [-122.98664000000001, 38.997283],
                    [-122.948865, 38.90022],
                    [-122.82159200000001, 38.85014],
                    [-123.080997, 38.852438],
                    [-123.136253, 38.809136],
                    [-123.36823600000001, 38.777066],
                    [-123.540922011634, 38.7676562160393],
                    [-123.571987, 38.798189],
                    [-123.638637, 38.843865],
                    [-123.65984599999999, 38.872529],
                    [-123.71053999999998, 38.91323],
                    [-123.73289199999999, 38.954994],
                    [-123.69074000000002, 39.021293],
                    [-123.72150499999998, 39.125327],
                    [-123.765891, 39.193657],
                    [-123.79899100000002, 39.271355],
                    [-123.82533100000002, 39.360814],
                    [-123.81469, 39.446538],
                    [-123.76647499999999, 39.552803],
                    [-123.78232200000001, 39.621486],
                    [-123.79265900000001, 39.684122],
                    [-123.829545, 39.723071],
                    [-123.85171400000002, 39.832041],
                    [-123.907664, 39.863028],
                    [-123.95495200000002, 39.922373],
                    [-124.02520517453, 40.001299341669],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '46380',
            AFFGEOID: '310M600US46380',
            GEOID: '46380',
            NAME: 'Ukiah, CA',
            NAMELSAD: 'Ukiah, CA Micro Area',
            LSAD: 'M2',
            ALAND: 9082632466,
            AWATER: 961740401,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.945019, 34.071488, -81.316911, 34.533652],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.945019, 34.203148],
                    [-81.89533100000001, 34.300497],
                    [-81.782627, 34.365089],
                    [-81.73710300000002, 34.430544],
                    [-81.703198, 34.452631],
                    [-81.643552, 34.533652],
                    [-81.54145500000001, 34.445166],
                    [-81.423241, 34.494379],
                    [-81.342297, 34.266928],
                    [-81.316911, 34.239458],
                    [-81.33896100000001, 34.197587],
                    [-81.43717200000002, 34.129963],
                    [-81.394124, 34.071488],
                    [-81.471972, 34.07655200000001],
                    [-81.502477, 34.07388],
                    [-81.568511, 34.099687],
                    [-81.731614, 34.187613],
                    [-81.860571, 34.179373],
                    [-81.870084, 34.135357],
                    [-81.897154, 34.146307],
                    [-81.945019, 34.203148],
                ],
            ],
        },
        properties: {
            CSAFP: '192',
            CBSAFP: '35140',
            AFFGEOID: '310M600US35140',
            GEOID: '35140',
            NAME: 'Newberry, SC',
            NAMELSAD: 'Newberry, SC Micro Area',
            LSAD: 'M2',
            ALAND: 1632452022,
            AWATER: 44011454,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.886052, 43.542943, -88.160873, 43.9382],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.88569700000001, 43.895184],
                    [-88.403195, 43.892977],
                    [-88.404187, 43.9382],
                    [-88.161654, 43.937683],
                    [-88.162274, 43.891511],
                    [-88.160873, 43.542943],
                    [-88.400426, 43.543532],
                    [-88.401041, 43.630933],
                    [-88.886052, 43.63354],
                    [-88.88569700000001, 43.895184],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '22540',
            AFFGEOID: '310M600US22540',
            GEOID: '22540',
            NAME: 'Fond du Lac, WI',
            NAMELSAD: 'Fond du Lac, WI Metro Area',
            LSAD: 'M1',
            ALAND: 1863667254,
            AWATER: 119853823,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.2284230027078, 38.20664800000001, -78.48574, 38.840074],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.201459, 38.527821],
                    [-79.154357, 38.606518],
                    [-79.092955, 38.65951700000001],
                    [-79.088055, 38.690115],
                    [-79.057253, 38.761413],
                    [-79.023053, 38.798613],
                    [-78.999014, 38.840074],
                    [-78.869276, 38.762991],
                    [-78.64176900000001, 38.604719],
                    [-78.691312, 38.510008],
                    [-78.48574, 38.42157900000001],
                    [-78.553143, 38.334824],
                    [-78.663145, 38.277933],
                    [-78.655149, 38.260632],
                    [-78.749396, 38.20664800000001],
                    [-78.888258, 38.303691],
                    [-78.913046, 38.303645],
                    [-79.2284230027078, 38.4797415574873],
                    [-79.201459, 38.527821],
                ],
            ],
        },
        properties: {
            CSAFP: '277',
            CBSAFP: '25500',
            AFFGEOID: '310M600US25500',
            GEOID: '25500',
            NAME: 'Harrisonburg, VA',
            NAMELSAD: 'Harrisonburg, VA Metro Area',
            LSAD: 'M1',
            ALAND: 2245852970,
            AWATER: 10777837,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-122.75256300000001, 47.828098, -122.333348, 48.416853],
            type: 'Polygon',
            coordinates: [
                [
                    [-122.75256300000001, 48.260061],
                    [-122.707077, 48.315286],
                    [-122.666982528216, 48.4124669920443],
                    [-122.58717000000001, 48.416853],
                    [-122.45859600000001, 48.297703],
                    [-122.39297700000002, 48.23743],
                    [-122.417909, 48.170622],
                    [-122.35028899999999, 48.080312],
                    [-122.333348, 47.933217],
                    [-122.398047, 47.861617],
                    [-122.42962500000002, 47.828098],
                    [-122.501809, 47.929713],
                    [-122.573745864315, 47.9510041672091],
                    [-122.546824, 47.967215],
                    [-122.542924, 47.996404],
                    [-122.607342, 48.030992],
                    [-122.598301, 48.110616],
                    [-122.63316700000001, 48.163281],
                    [-122.71150800000001, 48.19357300000001],
                    [-122.75256300000001, 48.260061],
                ],
            ],
        },
        properties: {
            CSAFP: '500',
            CBSAFP: '36020',
            AFFGEOID: '310M600US36020',
            GEOID: '36020',
            NAME: 'Oak Harbor, WA',
            NAMELSAD: 'Oak Harbor, WA Micro Area',
            LSAD: 'M2',
            ALAND: 539943912,
            AWATER: 799920796,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.310782, 35.7151240626578, -82.89375141843142, 36.18089400000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.296358, 35.904879],
                    [-83.276677, 36.007652],
                    [-83.259059, 35.999843],
                    [-83.234585, 36.085094],
                    [-83.245787, 36.124194],
                    [-83.175098, 36.127357],
                    [-83.18738600000002, 36.174995],
                    [-83.166085, 36.18089400000001],
                    [-83.172485, 36.157095],
                    [-83.102761, 36.120909],
                    [-82.89375141843142, 35.9338572697089],
                    [-82.910608, 35.926930000000006],
                    [-82.89971800000001, 35.874602],
                    [-82.937437, 35.82732],
                    [-82.9666497428986, 35.795445979574],
                    [-82.978414, 35.78261],
                    [-83.04853, 35.787706],
                    [-83.097193, 35.776067],
                    [-83.161537, 35.763363],
                    [-83.198267, 35.725494],
                    [-83.255351, 35.71623],
                    [-83.2561415523036, 35.7151240626578],
                    [-83.261806, 35.825024],
                    [-83.310782, 35.895846],
                    [-83.296358, 35.904879],
                ],
            ],
        },
        properties: {
            CSAFP: '315',
            CBSAFP: '35460',
            AFFGEOID: '310M600US35460',
            GEOID: '35460',
            NAME: 'Newport, TN',
            NAMELSAD: 'Newport, TN Micro Area',
            LSAD: 'M2',
            ALAND: 1129584563,
            AWATER: 17932684,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-77.680127, 34.4373849694266, -77.1129643930768, 34.9739],
            type: 'Polygon',
            coordinates: [
                [
                    [-77.653352, 34.940535],
                    [-77.674805, 34.9739],
                    [-77.502771, 34.969635],
                    [-77.366768, 34.90865],
                    [-77.267647, 34.92579],
                    [-77.165986, 34.78503200000001],
                    [-77.125646, 34.756707],
                    [-77.1129643930768, 34.6380928323915],
                    [-77.136843, 34.632926],
                    [-77.240991, 34.587507],
                    [-77.322524, 34.535574000000004],
                    [-77.462922, 34.471354],
                    [-77.5152165445848, 34.4373849694266],
                    [-77.576165, 34.47660700000001],
                    [-77.680127, 34.720595],
                    [-77.653352, 34.940535],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '27340',
            AFFGEOID: '310M600US27340',
            GEOID: '27340',
            NAME: 'Jacksonville, NC',
            NAMELSAD: 'Jacksonville, NC Metro Area',
            LSAD: 'M1',
            ALAND: 1973791553,
            AWATER: 370648829,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-148.663265, 64.257196, -143.88408, 65.453523],
            type: 'Polygon',
            coordinates: [
                [
                    [-148.661199, 65.20987500000001],
                    [-147.557771, 65.209895],
                    [-147.279115, 65.279762],
                    [-146.903142, 65.283375],
                    [-146.548654, 65.345003],
                    [-146.508383, 65.433109],
                    [-146.39704, 65.418421],
                    [-146.198886, 65.453523],
                    [-146.119349, 65.403987],
                    [-145.98704, 65.408443],
                    [-146.007899, 65.359434],
                    [-146.138999, 65.315193],
                    [-146.079908, 65.247981],
                    [-145.949873, 65.205483],
                    [-145.689426, 65.189078],
                    [-145.633882, 65.136802],
                    [-145.778697, 65.080003],
                    [-145.650312, 65.034303],
                    [-145.372503, 65.069496],
                    [-145.225944, 65.069216],
                    [-144.983514, 65.136988],
                    [-144.684887, 65.106143],
                    [-144.443678, 65.058436],
                    [-144.331377, 65.107707],
                    [-143.973639, 65.119304],
                    [-143.88408, 65.091068],
                    [-144.098484, 65.020385],
                    [-143.986949, 64.967243],
                    [-144.127807, 64.799108],
                    [-144.029789, 64.767593],
                    [-144.095375, 64.741713],
                    [-144.069029, 64.683562],
                    [-144.072511, 64.654082],
                    [-144.31598, 64.645136],
                    [-144.460096, 64.58326],
                    [-144.552526, 64.600645],
                    [-144.798044, 64.568296],
                    [-144.985331, 64.568609],
                    [-145.08278700000002, 64.507349],
                    [-145.373929, 64.483836],
                    [-145.374083, 64.455243],
                    [-145.603118, 64.42309],
                    [-145.740002, 64.460132],
                    [-145.925934, 64.424531],
                    [-146.003772, 64.381091],
                    [-146.230686, 64.363734],
                    [-146.232329, 64.310792],
                    [-146.344673, 64.276364],
                    [-146.48644600000003, 64.281768],
                    [-146.655165, 64.257212],
                    [-146.999909, 64.257196],
                    [-147.779479, 64.259029],
                    [-148.046696, 64.343802],
                    [-148.176111, 64.400924],
                    [-148.270857, 64.491769],
                    [-148.403117, 64.531297],
                    [-148.424918, 64.574854],
                    [-148.550742, 64.617208],
                    [-148.663265, 64.59079],
                    [-148.661199, 65.20987500000001],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '21820',
            AFFGEOID: '310M600US21820',
            GEOID: '21820',
            NAME: 'Fairbanks, AK',
            NAMELSAD: 'Fairbanks, AK Metro Area',
            LSAD: 'M1',
            ALAND: 18997100791,
            AWATER: 259310746,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.1359444040215, 42.442386, -78.463233, 43.3708954221664],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.111361, 42.613358],
                    [-79.06376, 42.644758],
                    [-79.04886, 42.689158],
                    [-78.972378, 42.715991],
                    [-78.904843, 42.746121],
                    [-78.851355, 42.791758],
                    [-78.8568849923499, 42.8052919998773],
                    [-78.865656, 42.826758],
                    [-78.88255700000002, 42.867258],
                    [-78.912458, 42.886557],
                    [-78.909159, 42.933257],
                    [-78.927957, 42.952922],
                    [-78.96176100000001, 42.957756],
                    [-79.019964, 42.994756],
                    [-79.00545, 43.057231],
                    [-79.018246, 43.066016],
                    [-79.0195778848943, 43.0662964678904],
                    [-79.074467, 43.077855],
                    [-79.060206, 43.124799],
                    [-79.0552524409396, 43.133812266617],
                    [-79.044567, 43.153255],
                    [-79.05286800000002, 43.222054],
                    [-79.070469, 43.262454],
                    [-78.834061, 43.317555],
                    [-78.547395, 43.369541],
                    [-78.4655511875961, 43.3708954221664],
                    [-78.465505, 43.128619],
                    [-78.464449, 43.088703],
                    [-78.464381, 42.867461],
                    [-78.486153, 42.861532],
                    [-78.463233, 42.780461],
                    [-78.46394, 42.536332],
                    [-78.695937, 42.47194],
                    [-78.919766, 42.442386],
                    [-78.991702, 42.529249],
                    [-79.060777, 42.537853],
                    [-79.107778, 42.569965],
                    [-79.1359444040215, 42.5691787099736],
                    [-79.111361, 42.613358],
                ],
            ],
        },
        properties: {
            CSAFP: '160',
            CBSAFP: '15380',
            AFFGEOID: '310M600US15380',
            GEOID: '15380',
            NAME: 'Buffalo-Cheektowaga, NY',
            NAMELSAD: 'Buffalo-Cheektowaga, NY Metro Area',
            LSAD: 'M1',
            ALAND: 4053548068,
            AWATER: 2075721485,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.956138, 41.601874, -78.20364, 41.999877],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.948612, 41.830481],
                    [-78.915642, 41.840896],
                    [-78.9188983633788, 41.9991031002259],
                    [-78.59665, 41.999877],
                    [-78.3081443402607, 41.99907117769861],
                    [-78.271204, 41.998968],
                    [-78.2063790981571, 41.9990887227984],
                    [-78.20364, 41.617599],
                    [-78.412163, 41.617301],
                    [-78.420498, 41.601874],
                    [-78.483815, 41.631481],
                    [-78.956138, 41.623334],
                    [-78.948612, 41.830481],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '14620',
            AFFGEOID: '310M600US14620',
            GEOID: '14620',
            NAME: 'Bradford, PA',
            NAMELSAD: 'Bradford, PA Micro Area',
            LSAD: 'M2',
            ALAND: 2537395883,
            AWATER: 13034578,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.482971, 36.201635, -88.81642000000001, 36.50563],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.444587, 36.235538],
                    [-89.36012, 36.409277],
                    [-89.3451943195388, 36.5013432086369],
                    [-89.211409, 36.50563],
                    [-88.964471, 36.502191],
                    [-88.8345890228743, 36.5019803422593],
                    [-88.8271780363405, 36.5019683222569],
                    [-88.81642000000001, 36.410732],
                    [-88.949163, 36.410099],
                    [-88.959839, 36.222834000000006],
                    [-88.961232, 36.201635],
                    [-89.154464, 36.204678],
                    [-89.482971, 36.212659],
                    [-89.444587, 36.235538],
                ],
            ],
        },
        properties: {
            CSAFP: '362',
            CBSAFP: '46460',
            AFFGEOID: '310M600US46460',
            GEOID: '46460',
            NAME: 'Union City, TN',
            NAMELSAD: 'Union City, TN Micro Area',
            LSAD: 'M2',
            ALAND: 1411149164,
            AWATER: 27448111,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.94408076019381, 33.985689, -90.399014, 34.5243442788972],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.938064, 34.148754],
                    [-90.894385, 34.160953],
                    [-90.882701, 34.184364],
                    [-90.89456, 34.22438],
                    [-90.839981, 34.23611400000001],
                    [-90.812829, 34.279438],
                    [-90.752681, 34.289266],
                    [-90.765174, 34.342818],
                    [-90.729131, 34.364206],
                    [-90.660404, 34.33576000000001],
                    [-90.641398, 34.383869],
                    [-90.575336, 34.415152],
                    [-90.583717, 34.458829],
                    [-90.5694607451505, 34.5243442788972],
                    [-90.399498, 34.423807],
                    [-90.399014, 34.261524],
                    [-90.450572, 34.262402],
                    [-90.450981, 34.072932],
                    [-90.451012, 33.985689],
                    [-90.657002, 33.987586],
                    [-90.659457, 34.118867],
                    [-90.94408076019381, 34.1200652947774],
                    [-90.938064, 34.148754],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '17260',
            AFFGEOID: '310M600US17260',
            GEOID: '17260',
            NAME: 'Clarksdale, MS',
            NAMELSAD: 'Clarksdale, MS Micro Area',
            LSAD: 'M2',
            ALAND: 1432526489,
            AWATER: 77525442,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-122.04647100000001, 39.295621, -121.07669500000002, 40.151905],
            type: 'Polygon',
            coordinates: [
                [
                    [-122.04486499999999, 39.88377100000001],
                    [-121.80404100000001, 39.884344],
                    [-121.703241, 39.98423],
                    [-121.646032, 39.982527],
                    [-121.58640699999998, 40.100522],
                    [-121.436886, 40.151905],
                    [-121.366818, 40.086054],
                    [-121.41947999999998, 40.015766],
                    [-121.42995700000002, 39.900313],
                    [-121.350821, 39.825704],
                    [-121.21087800000001, 39.725651],
                    [-121.13671500000001, 39.628169],
                    [-121.07669500000002, 39.597264],
                    [-121.14990700000001, 39.526446],
                    [-121.30512200000001, 39.519585],
                    [-121.33442000000001, 39.425212],
                    [-121.407527, 39.339754],
                    [-121.62376, 39.295621],
                    [-121.63806600000001, 39.305566],
                    [-121.908269, 39.303878],
                    [-121.89910899999998, 39.357286],
                    [-121.890013, 39.383864],
                    [-121.856532, 39.536904],
                    [-121.99402100000002, 39.533926],
                    [-121.99434299999999, 39.671458],
                    [-121.94986800000001, 39.733001],
                    [-121.96786799999998, 39.722397],
                    [-122.02649800000002, 39.800137],
                    [-122.04647100000001, 39.797648],
                    [-122.04486499999999, 39.88377100000001],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '17020',
            AFFGEOID: '310M600US17020',
            GEOID: '17020',
            NAME: 'Chico, CA',
            NAMELSAD: 'Chico, CA Metro Area',
            LSAD: 'M1',
            ALAND: 4238488701,
            AWATER: 105260548,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-73.290944, 42.7390651376842, -72.819619, 43.3145364556694],
            type: 'Polygon',
            coordinates: [
                [
                    [-73.278673, 42.83341],
                    [-73.273832807021, 42.9436317475265],
                    [-73.2700383015528, 43.0300409104512],
                    [-73.26978, 43.035923],
                    [-73.2553649410914, 43.3145364556694],
                    [-72.98759, 43.305005],
                    [-72.975885, 43.290551],
                    [-72.868647, 43.300529],
                    [-72.819895, 43.299372],
                    [-72.819619, 43.256006],
                    [-72.848112, 43.262847],
                    [-72.868242, 43.113931],
                    [-72.99713900000002, 43.117457],
                    [-73.008133, 42.930556],
                    [-73.006753, 42.930742],
                    [-72.92379, 42.927442],
                    [-72.9301066166812, 42.7390651376842],
                    [-72.9489339463746, 42.7394516843506],
                    [-73.01864861335041, 42.7408830063998],
                    [-73.0230178932901, 42.7409727127269],
                    [-73.1426721280253, 42.743429351364],
                    [-73.264957, 42.74594],
                    [-73.290944, 42.80192],
                    [-73.278673, 42.83341],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '13540',
            AFFGEOID: '310M600US13540',
            GEOID: '13540',
            NAME: 'Bennington, VT',
            NAMELSAD: 'Bennington, VT Micro Area',
            LSAD: 'M2',
            ALAND: 1746264292,
            AWATER: 6898408,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-103.814507, 32.0001655686764, -103.052610516564, 33.5705747764422],
            type: 'Polygon',
            coordinates: [
                [
                    [-103.814507, 32.965114],
                    [-103.766417, 32.965264],
                    [-103.766361, 33.395297],
                    [-103.720809, 33.569537],
                    [-103.510129, 33.570117],
                    [-103.052610516564, 33.5705747764422],
                    [-103.056495064338, 33.3884142146206],
                    [-103.060103, 33.219225],
                    [-103.063468888475, 32.9591063818305],
                    [-103.064889, 32.849359],
                    [-103.064761, 32.587983],
                    [-103.064696, 32.522193],
                    [-103.064422, 32.145006],
                    [-103.064422401106, 32.087051],
                    [-103.064423, 32.000518],
                    [-103.326501, 32.00037],
                    [-103.722881979148, 32.0001655686764],
                    [-103.722938, 32.522344],
                    [-103.81421600000002, 32.522368],
                    [-103.814507, 32.965114],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '26020',
            AFFGEOID: '310M600US26020',
            GEOID: '26020',
            NAME: 'Hobbs, NM',
            NAMELSAD: 'Hobbs, NM Micro Area',
            LSAD: 'M2',
            ALAND: 11374066527,
            AWATER: 8147103,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.594541, 32.135559, -94.985272, 32.687026],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.594541, 32.687026],
                    [-95.469093, 32.605642],
                    [-95.413061, 32.607111],
                    [-95.332164, 32.604252],
                    [-95.165305, 32.544359],
                    [-95.15341, 32.570115],
                    [-95.015037, 32.542696],
                    [-95.009992, 32.516478],
                    [-94.986935, 32.537246],
                    [-94.985411, 32.37082],
                    [-94.985272, 32.137989],
                    [-95.459908, 32.135559],
                    [-95.478585, 32.147224],
                    [-95.488733, 32.232486],
                    [-95.449165, 32.355233],
                    [-95.5943, 32.479858],
                    [-95.594541, 32.687026],
                ],
            ],
        },
        properties: {
            CSAFP: '540',
            CBSAFP: '46340',
            AFFGEOID: '310M600US46340',
            GEOID: '46340',
            NAME: 'Tyler, TX',
            NAMELSAD: 'Tyler, TX Metro Area',
            LSAD: 'M1',
            ALAND: 2386604317,
            AWATER: 73163656,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.9887760560898, 33.0084819174935, -92.0690147239268, 33.377142],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.978483, 33.377142],
                    [-92.66084000000001, 33.355985],
                    [-92.567156, 33.3672],
                    [-92.43138600000002, 33.269468],
                    [-92.350277, 33.298842],
                    [-92.359113, 33.281961],
                    [-92.224569, 33.253267],
                    [-92.137549, 33.162904],
                    [-92.143552, 33.098025],
                    [-92.0690147239268, 33.0084819174935],
                    [-92.222825, 33.00908],
                    [-92.501383, 33.01216],
                    [-92.723553, 33.014328],
                    [-92.7247431731793, 33.0143417676748],
                    [-92.971137, 33.017192],
                    [-92.9887760560898, 33.0172511697504],
                    [-92.978483, 33.377142],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '20980',
            AFFGEOID: '310M600US20980',
            GEOID: '20980',
            NAME: 'El Dorado, AR',
            NAMELSAD: 'El Dorado, AR Micro Area',
            LSAD: 'M2',
            ALAND: 2691171273,
            AWATER: 42102269,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.677385, 33.1200562677204, -78.9951309899024, 33.779878],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.677385, 33.336413],
                    [-79.53808, 33.496896],
                    [-79.435733, 33.572702],
                    [-79.409332, 33.68889],
                    [-79.317041, 33.779878],
                    [-79.246384, 33.695347],
                    [-79.190264, 33.705787],
                    [-79.137683, 33.66413],
                    [-79.101074, 33.572969],
                    [-78.9951309899024, 33.572664],
                    [-79.028516, 33.533365],
                    [-79.08458800000001, 33.483669],
                    [-79.135441, 33.403867],
                    [-79.162332, 33.327246],
                    [-79.180563, 33.237955],
                    [-79.172394, 33.206577],
                    [-79.215453, 33.155569],
                    [-79.2744945846428, 33.1200562677204],
                    [-79.269396, 33.136883],
                    [-79.350816, 33.15352800000001],
                    [-79.446699, 33.213458],
                    [-79.458734, 33.235219],
                    [-79.677014, 33.304944],
                    [-79.677385, 33.336413],
                ],
            ],
        },
        properties: {
            CSAFP: '396',
            CBSAFP: '23860',
            AFFGEOID: '310M600US23860',
            GEOID: '23860',
            NAME: 'Georgetown, SC',
            NAMELSAD: 'Georgetown, SC Micro Area',
            LSAD: 'M2',
            ALAND: 2107275277,
            AWATER: 572929958,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.608517, 43.0825529251945, -96.439335, 43.8495068930184],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.608517, 43.849059],
                    [-97.369463, 43.848526],
                    [-97.129089, 43.847973],
                    [-96.88866400000002, 43.848385],
                    [-96.4529095743663, 43.8495068930184],
                    [-96.453315, 43.552299],
                    [-96.45326000000001, 43.50039000000001],
                    [-96.598928, 43.500457],
                    [-96.584603, 43.46961],
                    [-96.594254, 43.434153],
                    [-96.521572, 43.38564],
                    [-96.524289, 43.347214],
                    [-96.530392, 43.300034],
                    [-96.578823, 43.291095000000006],
                    [-96.5590273427097, 43.257555675618],
                    [-96.552963, 43.247281],
                    [-96.522084, 43.22096],
                    [-96.47557100000002, 43.221054],
                    [-96.458854, 43.143356],
                    [-96.439335, 43.113916],
                    [-96.4520975160537, 43.0825529251945],
                    [-96.805682, 43.08367],
                    [-96.924142, 43.083733],
                    [-97.160543, 43.083145],
                    [-97.160544, 43.169978],
                    [-97.399191, 43.169419],
                    [-97.40145, 43.499783],
                    [-97.607012, 43.499826],
                    [-97.608517, 43.849059],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '43620',
            AFFGEOID: '310M600US43620',
            GEOID: '43620',
            NAME: 'Sioux Falls, SD',
            NAMELSAD: 'Sioux Falls, SD Metro Area',
            LSAD: 'M1',
            ALAND: 6670256892,
            AWATER: 27900457,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-94.732732, 29.562102820174797, -93.702436, 30.526976999999995],
            type: 'Polygon',
            coordinates: [
                [
                    [-94.732732, 30.490066],
                    [-94.537926, 30.491059],
                    [-94.545717, 30.526976999999995],
                    [-94.071689, 30.526033000000005],
                    [-94.119394, 30.426298000000003],
                    [-94.083386, 30.342181],
                    [-94.117604, 30.241665],
                    [-93.901452, 30.242672000000002],
                    [-93.7110621713009, 30.243970715640298],
                    [-93.713359, 30.225261],
                    [-93.703764, 30.173936000000005],
                    [-93.702436, 30.112721],
                    [-93.70394, 30.054291000000003],
                    [-93.7061835663174, 30.0523143321691],
                    [-93.74107800000002, 30.021571],
                    [-93.807815, 29.954549],
                    [-93.830374, 29.894359],
                    [-93.8523098709223, 29.872091004391898],
                    [-93.872446, 29.85165],
                    [-93.929208, 29.802952000000005],
                    [-93.890821, 29.761673],
                    [-93.863204, 29.724059000000004],
                    [-93.837971, 29.690619],
                    [-93.861291, 29.679007],
                    [-93.961867, 29.682213],
                    [-94.056506, 29.671163],
                    [-94.161549, 29.636588000000003],
                    [-94.3541225449137, 29.562102820174797],
                    [-94.357976, 29.887464],
                    [-94.442235, 29.889538],
                    [-94.44491, 30.113015000000004],
                    [-94.596338, 30.112100999999996],
                    [-94.732732, 30.490066],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '13140',
            AFFGEOID: '310M600US13140',
            GEOID: '13140',
            NAME: 'Beaumont-Port Arthur, TX',
            NAMELSAD: 'Beaumont-Port Arthur, TX Metro Area',
            LSAD: 'M1',
            ALAND: 5441915487,
            AWATER: 747466136,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.092861, 41.862394, -94.628724, 42.210405],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.090851, 42.210405],
                    [-94.85841200000002, 42.209692],
                    [-94.628806, 42.209396],
                    [-94.628724, 41.862763],
                    [-94.744876, 41.862394],
                    [-95.092861, 41.863374],
                    [-95.090851, 42.210405],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '16140',
            AFFGEOID: '310M600US16140',
            GEOID: '16140',
            NAME: 'Carroll, IA',
            NAMELSAD: 'Carroll, IA Micro Area',
            LSAD: 'M2',
            ALAND: 1474877210,
            AWATER: 2145349,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-67.955811, 18.036472, -66.897964, 18.289705],
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [-67.1912212777205, 18.2667488840118],
                        [-67.135819, 18.245928],
                        [-67.082002, 18.254946],
                        [-67.037935, 18.289705],
                        [-67.03819, 18.28047],
                        [-66.932119, 18.253387],
                        [-66.907236, 18.25309],
                        [-66.897964, 18.187744],
                        [-66.97838, 18.207916],
                        [-67.019836, 18.195471],
                        [-67.051461, 18.174053],
                        [-67.086359, 18.149829],
                        [-67.08105, 18.106715000000005],
                        [-67.100492, 18.106395],
                        [-67.126157, 18.115143000000003],
                        [-67.159733, 18.153227],
                        [-67.180754718857, 18.1681984717822],
                        [-67.158001, 18.216719],
                        [-67.1912212777205, 18.2667488840118],
                    ],
                ],
                [
                    [
                        [-67.941222, 18.126927],
                        [-67.896293, 18.136802],
                        [-67.846219, 18.127576000000005],
                        [-67.820915, 18.084711],
                        [-67.850633, 18.046268],
                        [-67.885547, 18.036472],
                        [-67.955811, 18.074232],
                        [-67.941222, 18.126927],
                    ],
                ],
            ],
        },
        properties: {
            CSAFP: '364',
            CBSAFP: '32420',
            AFFGEOID: '310M600US32420',
            GEOID: '32420',
            NAME: 'Mayagüez, PR',
            NAMELSAD: 'Mayagüez, PR Metro Area',
            LSAD: 'M1',
            ALAND: 350631151,
            AWATER: 509082470,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.2156615141091, 33.007697, -90.679607, 33.5294098627481],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.2156615141091, 33.5294098627481],
                    [-90.763509, 33.529389],
                    [-90.763773, 33.270359],
                    [-90.716277, 33.270032],
                    [-90.680319, 33.201971],
                    [-90.679607, 33.102483],
                    [-90.701845, 33.096294],
                    [-90.914465, 33.097417],
                    [-90.914337, 33.007697],
                    [-90.965433, 33.010545],
                    [-91.1596060796211, 33.0112423416025],
                    [-91.120379, 33.05453],
                    [-91.180836, 33.098364],
                    [-91.153015, 33.135093],
                    [-91.104317, 33.131598],
                    [-91.084366, 33.180856],
                    [-91.068708, 33.232936],
                    [-91.086137, 33.273652],
                    [-91.125539, 33.280255000000004],
                    [-91.142219, 33.348989],
                    [-91.113764, 33.393124],
                    [-91.147663, 33.427172],
                    [-91.171799, 33.462342],
                    [-91.189375, 33.493005],
                    [-91.2156615141091, 33.5294098627481],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '24740',
            AFFGEOID: '310M600US24740',
            GEOID: '24740',
            NAME: 'Greenville, MS',
            NAMELSAD: 'Greenville, MS Micro Area',
            LSAD: 'M2',
            ALAND: 1875077589,
            AWATER: 94257003,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.059974, 38.643167, -92.10437400000002, 39.343586],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.050453, 38.928244],
                    [-93.048471, 38.972418],
                    [-92.934569, 39.064547],
                    [-92.94385, 39.121738],
                    [-92.849224, 39.226218],
                    [-92.790369, 39.343586],
                    [-92.707367, 39.321614],
                    [-92.430229, 39.248795],
                    [-92.314471, 39.246454],
                    [-92.10437400000002, 39.239809],
                    [-92.110387, 39.064204],
                    [-92.1625, 38.865585],
                    [-92.135245, 38.813894],
                    [-92.216388, 38.714495],
                    [-92.220661, 38.643167],
                    [-92.357813, 38.679517],
                    [-92.394866, 38.73913],
                    [-92.38130400000001, 38.808732],
                    [-92.498377, 38.922084],
                    [-92.617147, 38.680013],
                    [-92.839837, 38.683974],
                    [-93.059974, 38.693077],
                    [-93.050453, 38.928244],
                ],
            ],
        },
        properties: {
            CSAFP: '190',
            CBSAFP: '17860',
            AFFGEOID: '310M600US17860',
            GEOID: '17860',
            NAME: 'Columbia, MO',
            NAMELSAD: 'Columbia, MO Metro Area',
            LSAD: 'M1',
            ALAND: 4439509518,
            AWATER: 45549807,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.727164, 40.549879, -82.336962, 40.995638],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.724792, 40.995638],
                    [-82.43285200000001, 40.992944],
                    [-82.420752, 40.82333],
                    [-82.339211, 40.726683],
                    [-82.338483, 40.638502],
                    [-82.336962, 40.555001],
                    [-82.374898, 40.550871],
                    [-82.62360900000002, 40.549879],
                    [-82.644977, 40.666425],
                    [-82.627191, 40.709416],
                    [-82.689113, 40.711033],
                    [-82.727164, 40.711203],
                    [-82.724792, 40.995638],
                ],
            ],
        },
        properties: {
            CSAFP: '360',
            CBSAFP: '31900',
            AFFGEOID: '310M600US31900',
            GEOID: '31900',
            NAME: 'Mansfield, OH',
            NAMELSAD: 'Mansfield, OH Metro Area',
            LSAD: 'M1',
            ALAND: 1282631596,
            AWATER: 12560134,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.815258, 29.626814, -90.47376, 31.044254],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.805063, 30.975745999999997],
                    [-91.750119, 31.018813999999995],
                    [-91.723804, 31.044254],
                    [-91.6282572982088, 31.005097001895205],
                    [-91.636942, 30.999416],
                    [-91.224068, 30.999183],
                    [-91.1761401239669, 30.999224202318604],
                    [-91.0601274679966, 30.999323935309203],
                    [-90.82582914129192, 30.999525355340598],
                    [-90.758775, 30.999583000000005],
                    [-90.5671954006585, 30.999945024399896],
                    [-90.567198, 30.824806],
                    [-90.567126, 30.650014999999996],
                    [-90.5669, 30.52613],
                    [-90.546118, 30.430283000000003],
                    [-90.47376, 30.345246],
                    [-90.485826, 30.276992],
                    [-90.554147, 30.195628],
                    [-90.592021, 30.208323999999998],
                    [-90.631905, 30.22119],
                    [-90.642111, 30.166448],
                    [-90.915565, 30.113285000000005],
                    [-90.963745, 30.066831],
                    [-90.946294, 29.977932000000003],
                    [-90.911804, 29.978500000000004],
                    [-90.885749, 29.905348],
                    [-90.885266, 29.877565],
                    [-90.925046, 29.830377],
                    [-90.981838, 29.744875000000004],
                    [-91.00689000000001, 29.715093000000003],
                    [-91.082611, 29.626814],
                    [-91.105395, 29.643281000000005],
                    [-91.10001, 29.699402],
                    [-91.092682, 29.801000000000002],
                    [-91.182817, 29.846172],
                    [-91.255229, 29.971457000000004],
                    [-91.22423, 30.025559999999995],
                    [-91.231842, 30.040385000000004],
                    [-91.368835, 30.058824],
                    [-91.463992, 30.102931000000005],
                    [-91.475303, 30.229491],
                    [-91.56276, 30.241949000000005],
                    [-91.621055, 30.309938],
                    [-91.640078, 30.442673],
                    [-91.701339, 30.497747],
                    [-91.756209, 30.498133000000003],
                    [-91.731432, 30.512119],
                    [-91.75698, 30.562551],
                    [-91.733673, 30.70173],
                    [-91.815258, 30.848107],
                    [-91.805063, 30.975745999999997],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '12940',
            AFFGEOID: '310M600US12940',
            GEOID: '12940',
            NAME: 'Baton Rouge, LA',
            NAMELSAD: 'Baton Rouge, LA Metro Area',
            LSAD: 'M1',
            ALAND: 11320050072,
            AWATER: 523384897,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-106.024880029032, 43.494969, -104.054794284348, 45.000396],
            type: 'Polygon',
            coordinates: [
                [
                    [-106.024880029032, 44.9975841235561],
                    [-105.848065, 45.000396],
                    [-105.076600952254, 45.0002966134074],
                    [-105.038252188274, 45.0002916729918],
                    [-105.025266, 45.00029],
                    [-104.05769800000002, 44.997431],
                    [-104.05581, 44.691343],
                    [-104.05569519926101, 44.5709903771422],
                    [-104.055389, 44.249983],
                    [-104.055406377827, 44.1803828740957],
                    [-104.055416190705, 44.1410812067834],
                    [-104.055488, 43.853477],
                    [-104.055032, 43.558603],
                    [-104.054794284348, 43.503327530406004],
                    [-104.899941, 43.49964],
                    [-105.079797, 43.498445000000004],
                    [-106.017366, 43.494969],
                    [-106.009201, 43.821579],
                    [-106.014477, 44.52098000000001],
                    [-106.009676, 44.563981],
                    [-106.024880029032, 44.9975841235561],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '23940',
            AFFGEOID: '310M600US23940',
            GEOID: '23940',
            NAME: 'Gillette, WY',
            NAMELSAD: 'Gillette, WY Micro Area',
            LSAD: 'M2',
            ALAND: 26041164939,
            AWATER: 44421978,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-77.731026, 34.78503200000001, -76.304136, 35.418362],
            type: 'Polygon',
            coordinates: [
                [
                    [-77.731026, 35.008138],
                    [-77.601282, 35.071368],
                    [-77.507463, 35.172814],
                    [-77.527182, 35.243001],
                    [-77.473692, 35.228985],
                    [-77.431253, 35.32983],
                    [-77.391026, 35.339533],
                    [-77.29434, 35.37913700000001],
                    [-77.247896, 35.354415],
                    [-77.189115, 35.418362],
                    [-76.958887, 35.301013],
                    [-76.895919, 35.253889],
                    [-76.845505, 35.216059],
                    [-76.632561, 35.235324],
                    [-76.590497, 35.361857],
                    [-76.522131, 35.353623],
                    [-76.454738, 35.334376],
                    [-76.304136, 35.201453],
                    [-76.71158, 34.981768],
                    [-76.652571, 34.937595],
                    [-76.693018, 34.86918],
                    [-76.883201, 34.844893],
                    [-76.954438, 34.809066],
                    [-77.078263, 34.811322],
                    [-77.161676, 34.795075],
                    [-77.165986, 34.78503200000001],
                    [-77.267647, 34.92579],
                    [-77.366768, 34.90865],
                    [-77.502771, 34.969635],
                    [-77.674805, 34.9739],
                    [-77.731026, 35.008138],
                ],
            ],
        },
        properties: {
            CSAFP: '404',
            CBSAFP: '35100',
            AFFGEOID: '310M600US35100',
            GEOID: '35100',
            NAME: 'New Bern, NC',
            NAMELSAD: 'New Bern, NC Metro Area',
            LSAD: 'M1',
            ALAND: 3922422269,
            AWATER: 761846047,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.936749, 30.709713999999998, -81.405153, 31.169650000000004],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.936749, 31.060787],
                    [-81.840671, 31.097664999999996],
                    [-81.766241, 31.169650000000004],
                    [-81.631624, 31.108117000000004],
                    [-81.562606, 31.130284999999997],
                    [-81.57269200000002, 31.097269],
                    [-81.4125180355795, 30.9908345035084],
                    [-81.408484, 30.977717999999996],
                    [-81.405153, 30.908202999999997],
                    [-81.44013, 30.821369000000004],
                    [-81.460061, 30.769912],
                    [-81.444124, 30.709713999999998],
                    [-81.507218, 30.722936000000004],
                    [-81.52828100000002, 30.723359],
                    [-81.56171, 30.715597],
                    [-81.633273, 30.729602999999997],
                    [-81.668283, 30.744644],
                    [-81.732238, 30.749635],
                    [-81.763384, 30.773821],
                    [-81.808543, 30.790015999999998],
                    [-81.868624, 30.792756],
                    [-81.902354, 30.82082],
                    [-81.9059784394064, 30.821407341501995],
                    [-81.906036, 31.039715],
                    [-81.93600600000002, 31.047993000000005],
                    [-81.936749, 31.060787],
                ],
            ],
        },
        properties: {
            CSAFP: '300',
            CBSAFP: '41220',
            AFFGEOID: '310M600US41220',
            GEOID: '41220',
            NAME: 'St. Marys, GA',
            NAMELSAD: 'St. Marys, GA Micro Area',
            LSAD: 'M2',
            ALAND: 1632622505,
            AWATER: 392508756,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-135.219128, 57.79203626159529, -133.176979642158, 58.974972],
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [-134.772728, 58.16649600000001],
                        [-134.724477838091, 58.211465285645104],
                        [-134.699956, 58.161494],
                        [-134.608911, 58.17163699999999],
                        [-134.46176, 58.15928900000001],
                        [-134.329872, 58.13499000000001],
                        [-134.256223, 58.14479300000001],
                        [-134.25438605080402, 58.1443552742868],
                        [-134.538413, 58.098172],
                        [-134.577773, 58.05411600000001],
                        [-134.697766, 58.032036],
                        [-134.784681, 58.096599],
                        [-134.772728, 58.16649600000001],
                    ],
                ],
                [
                    [
                        [-135.219128, 58.974972],
                        [-134.942425, 58.96606],
                        [-134.327992277918, 58.9629721778136],
                        [-134.328964, 58.919593000000006],
                        [-134.250526, 58.858046],
                        [-133.840392, 58.727991],
                        [-133.699835, 58.60729],
                        [-133.379908, 58.42790900000001],
                        [-133.461475, 58.385526],
                        [-133.343725, 58.27091500000001],
                        [-133.176979642158, 58.1505376923889],
                        [-133.696701, 57.792724],
                        [-133.703025360283, 57.79203626159529],
                        [-133.703097, 57.792152],
                        [-133.848776, 57.93544000000001],
                        [-134.049603, 58.062027],
                        [-134.078155, 58.15204599999999],
                        [-134.146685, 58.199084],
                        [-134.234572, 58.197233999999995],
                        [-134.375579, 58.208705],
                        [-134.464635, 58.22738799999999],
                        [-134.631203, 58.24744599999999],
                        [-134.750586, 58.391533],
                        [-134.936897, 58.457474000000005],
                        [-135.061769077114, 58.4513532436234],
                        [-135.219128, 58.974972],
                    ],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '27940',
            AFFGEOID: '310M600US27940',
            GEOID: '27940',
            NAME: 'Juneau, AK',
            NAMELSAD: 'Juneau, AK Micro Area',
            LSAD: 'M2',
            ALAND: 7003406836,
            AWATER: 1426230792,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-124.479882226932, 42.666697, -123.70214399999999, 43.610931759189],
            type: 'Polygon',
            coordinates: [
                [
                    [-124.436198, 43.071312],
                    [-124.41139, 43.159853],
                    [-124.39560700000001, 43.223908],
                    [-124.38246000000001, 43.27016700000001],
                    [-124.400404, 43.302121],
                    [-124.353332, 43.342667],
                    [-124.286896, 43.436296],
                    [-124.233534, 43.55713],
                    [-124.219057676906, 43.610931759189],
                    [-123.87569799999999, 43.608407],
                    [-123.87562800000002, 43.515947000000004],
                    [-123.81665700000002, 43.516449],
                    [-123.81761400000002, 43.431306],
                    [-123.761528, 43.422294],
                    [-123.764356, 43.258054],
                    [-123.704752, 43.257741],
                    [-123.70214399999999, 43.087083],
                    [-123.761333, 43.08316],
                    [-123.761594, 42.996318],
                    [-123.821118, 42.996291],
                    [-123.81155300000002, 42.788837],
                    [-123.925717, 42.774514],
                    [-123.99629100000001, 42.685651],
                    [-124.132918, 42.666697],
                    [-124.15996600000001, 42.739947],
                    [-124.141475, 42.850586],
                    [-124.219623, 42.933782],
                    [-124.258388, 42.954946],
                    [-124.479882226932, 42.9543224376774],
                    [-124.436198, 43.071312],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '18300',
            AFFGEOID: '310M600US18300',
            GEOID: '18300',
            NAME: 'Coos Bay, OR',
            NAMELSAD: 'Coos Bay, OR Micro Area',
            LSAD: 'M2',
            ALAND: 4133788140,
            AWATER: 544653413,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-112.047781, 46.823626, -110.636381, 47.698738],
            type: 'Polygon',
            coordinates: [
                [
                    [-112.047781, 47.516034],
                    [-111.927109, 47.500146],
                    [-111.922936, 47.611231],
                    [-111.66626, 47.611253],
                    [-111.666482, 47.697761],
                    [-111.40885, 47.698123],
                    [-110.976171, 47.698738],
                    [-111.049832, 47.598325],
                    [-110.86463, 47.524714],
                    [-110.768465, 47.525638],
                    [-110.769129, 47.446096],
                    [-110.639589, 47.445425],
                    [-110.63955900000002, 47.416486],
                    [-110.638911, 47.359032],
                    [-110.767125, 47.359128],
                    [-110.788302, 47.307848],
                    [-110.770698, 47.096546],
                    [-110.645212, 47.095870000000005],
                    [-110.636381, 46.837161],
                    [-110.652877, 46.823626],
                    [-110.751586, 46.844531],
                    [-110.752715, 46.890184],
                    [-110.856038, 46.921347],
                    [-110.913035, 46.980559],
                    [-111.104792, 47.029036],
                    [-111.080918, 47.088033],
                    [-111.158559, 47.088301],
                    [-111.158386, 47.011771],
                    [-111.285268, 47.001026],
                    [-111.539373, 47.000559],
                    [-111.539214, 46.913371],
                    [-111.658132, 46.913423],
                    [-111.790236, 46.913764],
                    [-111.789278, 47.12892200000001],
                    [-111.942139, 47.143582],
                    [-111.977739, 47.184103],
                    [-112.04431100000001, 47.192826],
                    [-112.047781, 47.516034],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '24500',
            AFFGEOID: '310M600US24500',
            GEOID: '24500',
            NAME: 'Great Falls, MT',
            NAMELSAD: 'Great Falls, MT Metro Area',
            LSAD: 'M1',
            ALAND: 6988324590,
            AWATER: 34310133,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.030233, 32.15289, -81.43583, 32.652797],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.001236, 32.60691],
                    [-81.84100500000001, 32.649093],
                    [-81.82785, 32.652797],
                    [-81.689643, 32.54629],
                    [-81.548006, 32.489286],
                    [-81.534904, 32.39358000000001],
                    [-81.446334, 32.284717],
                    [-81.43583, 32.241289],
                    [-81.780858, 32.15289],
                    [-81.81628, 32.23747],
                    [-81.969069, 32.268783],
                    [-81.918671, 32.41541],
                    [-82.030233, 32.53877],
                    [-82.001236, 32.60691],
                ],
            ],
        },
        properties: {
            CSAFP: '496',
            CBSAFP: '44340',
            AFFGEOID: '310M600US44340',
            GEOID: '44340',
            NAME: 'Statesboro, GA',
            NAMELSAD: 'Statesboro, GA Micro Area',
            LSAD: 'M2',
            ALAND: 1750735217,
            AWATER: 32827779,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-70.132595, 44.107662, -69.372419, 44.718161],
            type: 'Polygon',
            coordinates: [
                [
                    [-70.100491, 44.383854],
                    [-70.12939, 44.487217],
                    [-70.04235, 44.48523500000001],
                    [-70.035546, 44.508083],
                    [-70.072226, 44.529324],
                    [-70.041163, 44.571253],
                    [-70.040127, 44.588453],
                    [-69.953495, 44.574688],
                    [-69.946761, 44.582346],
                    [-69.930451, 44.610852],
                    [-69.822683, 44.60775900000001],
                    [-69.792813, 44.57733],
                    [-69.744282, 44.602607],
                    [-69.665525, 44.586402],
                    [-69.605746, 44.577264],
                    [-69.587342, 44.634076],
                    [-69.62848, 44.671969],
                    [-69.632971, 44.698763],
                    [-69.470382, 44.718161],
                    [-69.471712, 44.692899],
                    [-69.393123, 44.640375],
                    [-69.409539, 44.578852],
                    [-69.372419, 44.544585],
                    [-69.395987, 44.457078],
                    [-69.445498, 44.463839],
                    [-69.506382, 44.34287],
                    [-69.528223, 44.275447],
                    [-69.547398, 44.260988],
                    [-69.65017100000001, 44.279625],
                    [-69.665441, 44.227576],
                    [-69.656942, 44.125461],
                    [-69.666298, 44.124003],
                    [-69.758208, 44.139334],
                    [-69.763566, 44.153217],
                    [-69.854983, 44.167862],
                    [-69.897783, 44.107662],
                    [-70.005471, 44.124519],
                    [-70.026319, 44.133838000000004],
                    [-69.993792, 44.180594],
                    [-70.06049800000001, 44.191775],
                    [-70.07426, 44.290154],
                    [-70.100255, 44.33462],
                    [-70.132595, 44.371382],
                    [-70.100491, 44.383854],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '12300',
            AFFGEOID: '310M600US12300',
            GEOID: '12300',
            NAME: 'Augusta-Waterville, ME',
            NAMELSAD: 'Augusta-Waterville, ME Micro Area',
            LSAD: 'M2',
            ALAND: 2246754883,
            AWATER: 216775743,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.578589, 40.076889, -85.214386, 40.379524],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.578589, 40.379524],
                    [-85.44433, 40.37914],
                    [-85.219901, 40.379034],
                    [-85.218758, 40.306706000000005],
                    [-85.214386, 40.076889],
                    [-85.576197, 40.077143],
                    [-85.578589, 40.379524],
                ],
            ],
        },
        properties: {
            CSAFP: '294',
            CBSAFP: '34620',
            AFFGEOID: '310M600US34620',
            GEOID: '34620',
            NAME: 'Muncie, IN',
            NAMELSAD: 'Muncie, IN Metro Area',
            LSAD: 'M1',
            ALAND: 1015536280,
            AWATER: 9863733,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-104.055488, 43.68756, -101.999994, 45.039569],
            type: 'Polygon',
            coordinates: [
                [
                    [-104.055416190705, 44.1410812067834],
                    [-103.452453, 44.140772],
                    [-103.453016, 44.256609],
                    [-103.574396, 44.256077],
                    [-103.56765300000002, 44.604098],
                    [-102.964125, 44.604287],
                    [-102.957371, 45.039569],
                    [-101.999994, 45.038274],
                    [-102.000432, 44.511663],
                    [-102.001068, 44.510926],
                    [-102.007336, 43.99453],
                    [-102.018887, 43.708618],
                    [-102.033477, 43.689342],
                    [-102.139158, 43.700948],
                    [-102.175231, 43.687756],
                    [-102.809398, 43.68756],
                    [-102.695526, 43.79815],
                    [-102.687191, 43.855599],
                    [-104.055488, 43.853477],
                    [-104.055416190705, 44.1410812067834],
                ],
            ],
        },
        properties: {
            CSAFP: '452',
            CBSAFP: '39660',
            AFFGEOID: '310M600US39660',
            GEOID: '39660',
            NAME: 'Rapid City, SD',
            NAMELSAD: 'Rapid City, SD Metro Area',
            LSAD: 'M1',
            ALAND: 16181465596,
            AWATER: 50668883,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-119.36942899999998, 46.73662300000001, -117.95933500000001, 47.261709],
            type: 'Polygon',
            coordinates: [
                [
                    [-119.368662, 46.91256200000001],
                    [-118.982652, 46.911344],
                    [-118.97977700000001, 47.261709],
                    [-117.96093, 47.260568],
                    [-117.95933500000001, 46.914811],
                    [-118.01556700000002, 46.784873],
                    [-118.21024300000002, 46.73883300000001],
                    [-118.358881, 46.73662300000001],
                    [-119.36942899999998, 46.737695],
                    [-119.368662, 46.91256200000001],
                ],
            ],
        },
        properties: {
            CSAFP: '393',
            CBSAFP: '36830',
            AFFGEOID: '310M600US36830',
            GEOID: '36830',
            NAME: 'Othello, WA',
            NAMELSAD: 'Othello, WA Micro Area',
            LSAD: 'M2',
            ALAND: 4985698856,
            AWATER: 12464425,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.542428, 35.843303, -79.237295, 36.249673],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.541728, 35.899851],
                    [-79.53241, 36.241463],
                    [-79.531865, 36.249673],
                    [-79.257952, 36.243445],
                    [-79.268243, 35.909182],
                    [-79.249538, 35.87681],
                    [-79.237295, 35.844247],
                    [-79.542428, 35.843303],
                    [-79.541728, 35.899851],
                ],
            ],
        },
        properties: {
            CSAFP: '268',
            CBSAFP: '15500',
            AFFGEOID: '310M600US15500',
            GEOID: '15500',
            NAME: 'Burlington, NC',
            NAMELSAD: 'Burlington, NC Metro Area',
            LSAD: 'M1',
            ALAND: 1096736059,
            AWATER: 27940532,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.003345, 31.027741000000002, -94.129632, 31.526915999999996],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.003345, 31.42571],
                    [-94.865857, 31.526915999999996],
                    [-94.82287, 31.483978],
                    [-94.545121, 31.431855],
                    [-94.53136, 31.398540000000004],
                    [-94.49587400000001, 31.405728000000003],
                    [-94.449422, 31.321337],
                    [-94.396017, 31.302356000000003],
                    [-94.326616, 31.224753999999997],
                    [-94.218997, 31.173264999999997],
                    [-94.129632, 31.099280000000004],
                    [-94.457816, 31.033325],
                    [-94.477026, 31.027741000000002],
                    [-94.56194300000001, 31.058951999999998],
                    [-94.573675, 31.068432000000005],
                    [-94.738594, 31.103690000000004],
                    [-94.84294700000001, 31.146578],
                    [-94.860501, 31.234954999999996],
                    [-94.95811, 31.38693],
                    [-94.976718, 31.409144999999995],
                    [-95.003345, 31.42571],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '31260',
            AFFGEOID: '310M600US31260',
            GEOID: '31260',
            NAME: 'Lufkin, TX',
            NAMELSAD: 'Lufkin, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2066460812,
            AWATER: 173300091,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.806795, 38.910847, -88.360654, 39.216263],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.805325, 39.216263],
                    [-88.470906, 39.215029],
                    [-88.470865, 39.171463],
                    [-88.360654, 39.171118],
                    [-88.361745, 38.910847],
                    [-88.693531, 38.914617],
                    [-88.806795, 38.911585],
                    [-88.805325, 39.216263],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '20820',
            AFFGEOID: '310M600US20820',
            GEOID: '20820',
            NAME: 'Effingham, IL',
            NAMELSAD: 'Effingham, IL Micro Area',
            LSAD: 'M2',
            ALAND: 1240005909,
            AWATER: 3174583,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.67125, 33.286922, -88.2483874739095, 33.745591],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.67125, 33.506168],
                    [-88.472714, 33.524451],
                    [-88.513917, 33.650209],
                    [-88.40656, 33.673342],
                    [-88.325119, 33.745591],
                    [-88.2483874739095, 33.74490759488171],
                    [-88.254445, 33.698779],
                    [-88.2745164707837, 33.5340014777852],
                    [-88.3044428135104, 33.28832],
                    [-88.669076, 33.286922],
                    [-88.67125, 33.506168],
                ],
            ],
        },
        properties: {
            CSAFP: '200',
            CBSAFP: '18060',
            AFFGEOID: '310M600US18060',
            GEOID: '18060',
            NAME: 'Columbus, MS',
            NAMELSAD: 'Columbus, MS Micro Area',
            LSAD: 'M2',
            ALAND: 1309105223,
            AWATER: 28532500,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-77.195772, 35.216059, -76.46776100000001, 35.732831],
            type: 'Polygon',
            coordinates: [
                [
                    [-77.174, 35.732831],
                    [-76.985842, 35.658848],
                    [-76.847264, 35.719982],
                    [-76.844279, 35.705717],
                    [-76.637513, 35.705215],
                    [-76.588191, 35.620654],
                    [-76.46776100000001, 35.556504],
                    [-76.509605, 35.518356],
                    [-76.599085, 35.51996],
                    [-76.522131, 35.353623],
                    [-76.590497, 35.361857],
                    [-76.632561, 35.235324],
                    [-76.845505, 35.216059],
                    [-76.895919, 35.253889],
                    [-76.958887, 35.301013],
                    [-77.189115, 35.418362],
                    [-77.176178, 35.518812],
                    [-77.100803, 35.551455],
                    [-77.08586, 35.553317],
                    [-77.172766, 35.6391],
                    [-77.195772, 35.699779],
                    [-77.174, 35.732831],
                ],
            ],
        },
        properties: {
            CSAFP: '272',
            CBSAFP: '47820',
            AFFGEOID: '310M600US47820',
            GEOID: '47820',
            NAME: 'Washington, NC',
            NAMELSAD: 'Washington, NC Micro Area',
            LSAD: 'M2',
            ALAND: 2156776424,
            AWATER: 336988229,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.15341, 31.844113, -94.0427882328053, 32.90450200000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.15274, 32.660949],
                    [-95.15211, 32.902641],
                    [-94.71994200000002, 32.90450200000001],
                    [-94.705878, 32.879177],
                    [-94.672351, 32.834756],
                    [-94.70214, 32.793088],
                    [-94.507323, 32.693738],
                    [-94.390783, 32.69437700000001],
                    [-94.242809, 32.75073],
                    [-94.176401, 32.694694],
                    [-94.092738, 32.734269],
                    [-94.0430515276176, 32.6930299766656],
                    [-94.043083, 32.564261],
                    [-94.0427882328053, 32.3922831932812],
                    [-94.35064, 32.326583],
                    [-94.385839, 32.3672],
                    [-94.491818, 32.394137],
                    [-94.530714, 32.271842],
                    [-94.5805, 32.201363],
                    [-94.599978, 31.973193000000002],
                    [-94.511431, 31.973984000000005],
                    [-94.510837, 31.923201],
                    [-94.452513, 31.844113],
                    [-94.937532, 31.845555000000004],
                    [-94.984768, 31.846144],
                    [-94.985272, 32.137989],
                    [-94.985411, 32.37082],
                    [-94.986935, 32.537246],
                    [-95.009992, 32.516478],
                    [-95.015037, 32.542696],
                    [-95.15341, 32.570115],
                    [-95.15274, 32.660949],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '30980',
            AFFGEOID: '310M600US30980',
            GEOID: '30980',
            NAME: 'Longview, TX',
            NAMELSAD: 'Longview, TX Metro Area',
            LSAD: 'M1',
            ALAND: 6942767221,
            AWATER: 108951107,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-153.001736, 60.73298700000001, -146.423984, 63.47763900000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-153.001672, 62.292622],
                    [-152.999609, 62.72583099999999],
                    [-151.893784, 62.725864],
                    [-151.894015, 62.79805799999999],
                    [-149.49234, 63.33321800000001],
                    [-148.025618, 63.333233],
                    [-148.025627, 63.47760900000001],
                    [-146.999966, 63.47761800000001],
                    [-146.494333, 63.47763900000001],
                    [-146.494311, 63.16897500000001],
                    [-146.450134, 62.957864],
                    [-146.436899, 62.85699100000001],
                    [-146.42399, 62.510454],
                    [-146.423984, 62.250511],
                    [-146.979807, 62.250497],
                    [-146.960978, 62.163852000000006],
                    [-146.942601, 61.817271999999996],
                    [-146.942559, 61.470675],
                    [-147.206605, 61.47066300000001],
                    [-147.206597, 61.426265],
                    [-147.7443, 61.426276],
                    [-148.462471, 61.426267],
                    [-148.472065, 60.84852000000001],
                    [-148.738363, 60.84854599999999],
                    [-148.745618, 60.73300699999999],
                    [-149.037741, 60.73298700000001],
                    [-149.03408, 60.848534],
                    [-149.181942, 60.90631700000001],
                    [-149.359399, 60.90633],
                    [-149.748463763199, 61.0012376770684],
                    [-149.717167, 61.011303],
                    [-149.831922, 61.07619700000001],
                    [-150.005041, 61.138556],
                    [-150.065646, 61.151079],
                    [-150.265894, 61.127365000000005],
                    [-150.228774, 61.162581],
                    [-150.220991045748, 61.1941844223187],
                    [-150.204894, 61.259548],
                    [-150.425, 61.245552],
                    [-150.53599700000004, 61.269724],
                    [-150.679902, 61.26588799999999],
                    [-150.827295, 61.228390000000005],
                    [-150.939251, 61.210299],
                    [-150.974011916343, 61.19446729869951],
                    [-150.974014, 61.25294],
                    [-151.332802, 61.252936],
                    [-151.332784, 61.42623900000001],
                    [-151.857505, 61.426218000000006],
                    [-153.001736, 61.426172],
                    [-153.001672, 62.292622],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '11260',
            AFFGEOID: '310M600US11260',
            GEOID: '11260',
            NAME: 'Anchorage, AK',
            NAMELSAD: 'Anchorage, AK Metro Area',
            LSAD: 'M1',
            ALAND: 68411607413,
            AWATER: 2067666231,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.132766, 42.294699, -90.48434567271632, 42.6743180564343],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.132766, 42.645844],
                    [-90.897415, 42.645714],
                    [-90.8969620271584, 42.6743180564343],
                    [-90.852497, 42.664822],
                    [-90.743677, 42.64556000000001],
                    [-90.700856, 42.626445],
                    [-90.672727, 42.576599],
                    [-90.642843, 42.50848100000001],
                    [-90.646727, 42.471904],
                    [-90.590416, 42.447493],
                    [-90.565248, 42.438742],
                    [-90.517516, 42.403019],
                    [-90.48434567271632, 42.3815977781919],
                    [-90.665029, 42.382155],
                    [-90.665551, 42.294699],
                    [-90.89802, 42.295236],
                    [-91.130079, 42.295761],
                    [-91.132766, 42.645844],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '20220',
            AFFGEOID: '310M600US20220',
            GEOID: '20220',
            NAME: 'Dubuque, IA',
            NAMELSAD: 'Dubuque, IA Metro Area',
            LSAD: 'M1',
            ALAND: 1575487976,
            AWATER: 21138781,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.581936, 34.099073, -86.057712, 34.599802],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.58132, 34.371094],
                    [-86.552782, 34.539951],
                    [-86.550166, 34.545963],
                    [-86.53344500000001, 34.502795],
                    [-86.423914, 34.479581],
                    [-86.330198, 34.512684],
                    [-86.326853, 34.599403],
                    [-86.326958, 34.599744],
                    [-86.318264, 34.599801],
                    [-86.316036, 34.599802],
                    [-86.182992, 34.599442],
                    [-86.148464, 34.599069],
                    [-86.097741, 34.512547],
                    [-86.087628, 34.466558],
                    [-86.057712, 34.475994],
                    [-86.118894, 34.403845],
                    [-86.106086, 34.200756],
                    [-86.1089, 34.186311],
                    [-86.206107, 34.17265],
                    [-86.303516, 34.099073],
                    [-86.40901700000002, 34.205002],
                    [-86.45302, 34.259317],
                    [-86.477525, 34.302758],
                    [-86.581936, 34.304694],
                    [-86.58132, 34.371094],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '10700',
            AFFGEOID: '310M600US10700',
            GEOID: '10700',
            NAME: 'Albertville, AL',
            NAMELSAD: 'Albertville, AL Micro Area',
            LSAD: 'M2',
            ALAND: 1465531833,
            AWATER: 148497637,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.041159, 44.194916, -92.24536464900541, 44.7149227852596],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.039485, 44.471871],
                    [-93.039344, 44.515350000000005],
                    [-92.7926, 44.542971],
                    [-92.792584, 44.629722],
                    [-92.732043, 44.629493],
                    [-92.7316238513032, 44.7149227852596],
                    [-92.69649100000001, 44.689436],
                    [-92.618025, 44.61287000000001],
                    [-92.54928, 44.577704],
                    [-92.39928100000002, 44.558292],
                    [-92.361518, 44.558935000000005],
                    [-92.3169331334058, 44.5392760122027],
                    [-92.314071, 44.538014],
                    [-92.291005, 44.485464],
                    [-92.24536464900541, 44.4542511243518],
                    [-92.430277, 44.455321],
                    [-92.430629, 44.368291],
                    [-92.550733, 44.36852100000001],
                    [-92.550871, 44.194916],
                    [-92.678717, 44.195516],
                    [-93.041159, 44.196726],
                    [-93.039485, 44.471871],
                ],
            ],
        },
        properties: {
            CSAFP: '378',
            CBSAFP: '39860',
            AFFGEOID: '310M600US39860',
            GEOID: '39860',
            NAME: 'Red Wing, MN',
            NAMELSAD: 'Red Wing, MN Micro Area',
            LSAD: 'M2',
            ALAND: 1959853268,
            AWATER: 59175413,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.00363100000001, 41.499924051206, -80.5191767503534, 41.977523],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.00363100000001, 41.715137],
                    [-81.0022694065175, 41.8491724664376],
                    [-80.900342, 41.868912],
                    [-80.800794, 41.909635],
                    [-80.581882, 41.95761],
                    [-80.519425, 41.977523],
                    [-80.5193967266905, 41.8495629905593],
                    [-80.519357, 41.669767],
                    [-80.5191767503534, 41.499924051206],
                    [-81.003125, 41.501685],
                    [-81.00363100000001, 41.715137],
                ],
            ],
        },
        properties: {
            CSAFP: '184',
            CBSAFP: '11780',
            AFFGEOID: '310M600US11780',
            GEOID: '11780',
            NAME: 'Ashtabula, OH',
            NAMELSAD: 'Ashtabula, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1818345034,
            AWATER: 1724513202,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.359765, 35.307161, -78.96964, 35.628164],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.350073, 35.518002],
                    [-79.24466300000002, 35.569116],
                    [-79.117096, 35.628164],
                    [-78.96964, 35.521669],
                    [-79.183556, 35.307161],
                    [-79.273565, 35.353369],
                    [-79.359765, 35.469675],
                    [-79.350073, 35.518002],
                ],
            ],
        },
        properties: {
            CSAFP: '246',
            CBSAFP: '41820',
            AFFGEOID: '310M600US41820',
            GEOID: '41820',
            NAME: 'Sanford, NC',
            NAMELSAD: 'Sanford, NC Micro Area',
            LSAD: 'M2',
            ALAND: 660607453,
            AWATER: 10725289,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-120.91873100000001, 35.907186, -118.360586, 37.585737],
            type: 'Polygon',
            coordinates: [
                [
                    [-120.65595000000002, 36.952832],
                    [-120.59056600000001, 36.952641],
                    [-120.541696, 37.044505],
                    [-120.450341, 36.911172],
                    [-120.45624600000001, 36.862863],
                    [-120.37025599999998, 36.78494],
                    [-120.22917900000002, 36.769687],
                    [-120.057776, 36.82286],
                    [-120.02777499999999, 36.814505],
                    [-119.932338, 36.843323],
                    [-119.81442100000001, 36.850138],
                    [-119.71318199999999, 36.991638],
                    [-119.705356, 36.999794],
                    [-119.690779, 37.011987],
                    [-119.658611, 37.01333],
                    [-119.65937700000002, 37.038938],
                    [-119.64748900000001, 37.043775],
                    [-119.621116, 37.026605],
                    [-119.60490800000001, 37.071016],
                    [-119.56297899999998, 37.064095],
                    [-119.567234, 37.115946],
                    [-119.506733, 37.150554],
                    [-119.470844, 37.110548],
                    [-119.43305999999998, 37.161516],
                    [-119.36275000000002, 37.167201],
                    [-119.33057300000002, 37.205791],
                    [-119.33518899999999, 37.310774],
                    [-119.286658, 37.374943],
                    [-119.02236300000001, 37.585737],
                    [-118.91706, 37.550336],
                    [-118.850474, 37.475796],
                    [-118.775014, 37.463052],
                    [-118.786736, 37.34338],
                    [-118.716016, 37.328208],
                    [-118.65459300000002, 37.14182600000001],
                    [-118.59266100000002, 37.138147000000004],
                    [-118.43713700000002, 37.059818],
                    [-118.36083099999999, 36.887734],
                    [-118.389758, 36.834466],
                    [-118.360586, 36.744773],
                    [-118.98244099999998, 36.741646],
                    [-118.98477899999999, 36.657147],
                    [-119.088295, 36.657514],
                    [-119.174791, 36.658878],
                    [-119.304625, 36.660606],
                    [-119.305102, 36.573725],
                    [-119.466322, 36.575238],
                    [-119.54613100000002, 36.508963],
                    [-119.573194, 36.488835],
                    [-119.6664, 36.41888],
                    [-119.754213, 36.40202300000001],
                    [-119.95922699999998, 36.400975],
                    [-119.95905800000001, 36.181747],
                    [-120.31506800000001, 35.907186],
                    [-120.433053, 35.968933],
                    [-120.66755100000002, 36.138057],
                    [-120.62691, 36.203227],
                    [-120.67858200000002, 36.267319],
                    [-120.59656199999999, 36.328488],
                    [-120.597155, 36.48823500000001],
                    [-120.91873100000001, 36.740381],
                    [-120.65595000000002, 36.952832],
                ],
            ],
        },
        properties: {
            CSAFP: '260',
            CBSAFP: '23420',
            AFFGEOID: '310M600US23420',
            GEOID: '23420',
            NAME: 'Fresno, CA',
            NAMELSAD: 'Fresno, CA Metro Area',
            LSAD: 'M1',
            ALAND: 15432139297,
            AWATER: 136609893,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-124.353615670887, 46.792129, -123.15836400000002, 47.5335370830521],
            type: 'Polygon',
            coordinates: [
                [
                    [-124.353615670887, 47.5335370830521],
                    [-124.04674, 47.518525],
                    [-123.50591699999998, 47.51771600000001],
                    [-123.50502100000001, 47.258535],
                    [-123.48894299999999, 47.082646],
                    [-123.20189100000002, 47.085059],
                    [-123.15836400000002, 46.995871],
                    [-123.16049999999998, 46.827161],
                    [-123.160588, 46.793383],
                    [-123.35139100000002, 46.79350900000001],
                    [-123.37095000000001, 46.792129],
                    [-124.096796667905, 46.794085],
                    [-124.101232, 46.810656],
                    [-124.13822500000002, 46.905534],
                    [-124.180111, 46.926357],
                    [-124.169113, 46.994508],
                    [-124.18854400000001, 47.157859],
                    [-124.195893, 47.174],
                    [-124.236349, 47.287287],
                    [-124.31937899999998, 47.355559],
                    [-124.353615670887, 47.5335370830521],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '10140',
            AFFGEOID: '310M600US10140',
            GEOID: '10140',
            NAME: 'Aberdeen, WA',
            NAMELSAD: 'Aberdeen, WA Micro Area',
            LSAD: 'M2',
            ALAND: 4924794342,
            AWATER: 834310602,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-74.6447393198257, 44.09925, -73.909687, 44.999203],
            type: 'Polygon',
            coordinates: [
                [
                    [-74.611048, 44.999203],
                    [-74.436934, 44.996177],
                    [-74.234136, 44.992148],
                    [-74.0274312115718, 44.9973653643332],
                    [-73.986382, 44.707773],
                    [-73.966148, 44.709118],
                    [-73.909687, 44.429699],
                    [-74.141424, 44.407268],
                    [-74.09349, 44.137615],
                    [-74.28187, 44.120552],
                    [-74.535156, 44.09925],
                    [-74.525683, 44.170636],
                    [-74.6447393198257, 44.9970275604167],
                    [-74.611048, 44.999203],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '31660',
            AFFGEOID: '310M600US31660',
            GEOID: '31660',
            NAME: 'Malone, NY',
            NAMELSAD: 'Malone, NY Micro Area',
            LSAD: 'M2',
            ALAND: 4219857155,
            AWATER: 176622254,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-105.35401, 32.518776, -103.509759, 34.088407],
            type: 'Polygon',
            coordinates: [
                [
                    [-105.316047, 32.9621],
                    [-105.316968, 33.132303],
                    [-104.905424, 33.138904],
                    [-104.88513, 33.305827],
                    [-104.89243300000001, 33.398199],
                    [-104.893377, 34.088407],
                    [-104.786429, 34.087664],
                    [-104.786104, 34.000137],
                    [-104.15626900000001, 33.995413],
                    [-104.1556, 34.083205],
                    [-103.946019, 34.082463],
                    [-103.841691, 34.081909],
                    [-103.841933, 33.8192],
                    [-103.716885, 33.819558],
                    [-103.718333, 33.65621],
                    [-103.509759, 33.657262],
                    [-103.510129, 33.570117],
                    [-103.720809, 33.569537],
                    [-103.766361, 33.395297],
                    [-103.766417, 32.965264],
                    [-103.814507, 32.965114],
                    [-104.841576, 32.963213],
                    [-104.842171, 32.52054],
                    [-104.851524, 32.520541],
                    [-105.35401, 32.518776],
                    [-105.316047, 32.9621],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '40740',
            AFFGEOID: '310M600US40740',
            GEOID: '40740',
            NAME: 'Roswell, NM',
            NAMELSAD: 'Roswell, NM Micro Area',
            LSAD: 'M2',
            ALAND: 15713914833,
            AWATER: 20472234,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-101.761619209528, 29.232831568098, -100.699141, 30.288276],
            type: 'Polygon',
            coordinates: [
                [
                    [-101.75840000000001, 30.288044],
                    [-100.960643, 30.287776],
                    [-100.700393, 30.288276],
                    [-100.699932, 29.623897],
                    [-100.699141, 29.419747],
                    [-100.757822, 29.238325],
                    [-100.801867546029, 29.232831568098],
                    [-100.848664, 29.271421],
                    [-100.886842, 29.307848],
                    [-100.995607, 29.363403],
                    [-101.060151, 29.458661],
                    [-101.137503, 29.473542],
                    [-101.19272, 29.520285000000005],
                    [-101.254895, 29.520342],
                    [-101.30553300000001, 29.577925],
                    [-101.307332, 29.640716],
                    [-101.367198, 29.664040999999997],
                    [-101.400636, 29.738079],
                    [-101.453499, 29.759671000000004],
                    [-101.503223, 29.764582],
                    [-101.56156900000002, 29.794658000000002],
                    [-101.654578, 29.765163],
                    [-101.714224, 29.767660000000003],
                    [-101.761619209528, 29.7788600967222],
                    [-101.75840000000001, 30.288044],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '19620',
            AFFGEOID: '310M600US19620',
            GEOID: '19620',
            NAME: 'Del Rio, TX',
            NAMELSAD: 'Del Rio, TX Micro Area',
            LSAD: 'M2',
            ALAND: 8144863966,
            AWATER: 227711025,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-111.247496, 39.467455, -109.88308, 39.81328500000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-111.247496, 39.813028],
                    [-110.85778000000002, 39.81328500000001],
                    [-109.976814, 39.80623],
                    [-109.88308, 39.806236],
                    [-109.96214300000001, 39.725885],
                    [-110.037952, 39.566667],
                    [-110.023963, 39.469268],
                    [-110.024118, 39.469269],
                    [-111.081333, 39.467455],
                    [-111.085349, 39.521522],
                    [-111.132248, 39.613876],
                    [-111.159113, 39.595947],
                    [-111.247391, 39.70435],
                    [-111.247496, 39.813028],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '39220',
            AFFGEOID: '310M600US39220',
            GEOID: '39220',
            NAME: 'Price, UT',
            NAMELSAD: 'Price, UT Micro Area',
            LSAD: 'M2',
            ALAND: 3831067718,
            AWATER: 15844443,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.935286, 29.361363733346003, -91.082611, 29.956304],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.935286, 29.671939000000002],
                    [-91.711283, 29.846131],
                    [-91.624347, 29.94546],
                    [-91.471953, 29.956304],
                    [-91.373642, 29.834401],
                    [-91.386491, 29.783680999999998],
                    [-91.215195, 29.751210999999998],
                    [-91.10001, 29.699402],
                    [-91.105395, 29.643281000000005],
                    [-91.082611, 29.626814],
                    [-91.178048, 29.609703],
                    [-91.27124, 29.535186],
                    [-91.258332, 29.444319],
                    [-91.213419, 29.417498000000002],
                    [-91.235419, 29.374071999999998],
                    [-91.2663226707192, 29.361363733346003],
                    [-91.334051, 29.391525000000005],
                    [-91.363967, 29.420664],
                    [-91.347514, 29.444438000000005],
                    [-91.394307, 29.497115],
                    [-91.46096300000002, 29.469961000000005],
                    [-91.48559, 29.499116],
                    [-91.531021, 29.531543000000003],
                    [-91.537445, 29.565887999999998],
                    [-91.541974, 29.594353],
                    [-91.600179, 29.631155999999997],
                    [-91.643832, 29.630625],
                    [-91.623829, 29.69924],
                    [-91.667128, 29.745822],
                    [-91.737253, 29.749369999999995],
                    [-91.808142, 29.725097000000005],
                    [-91.85307, 29.702936000000005],
                    [-91.8625569965226, 29.667395463759902],
                    [-91.935286, 29.671939000000002],
                ],
            ],
        },
        properties: {
            CSAFP: '318',
            CBSAFP: '34020',
            AFFGEOID: '310M600US34020',
            GEOID: '34020',
            NAME: 'Morgan City, LA',
            NAMELSAD: 'Morgan City, LA Micro Area',
            LSAD: 'M2',
            ALAND: 1439626085,
            AWATER: 1460444966,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.98725, 27.822058, -80.4476769492176, 28.791192871322703],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.964466, 28.612992000000002],
                    [-80.967895, 28.790197],
                    [-80.7275056700737, 28.791192871322703],
                    [-80.647288, 28.677874999999997],
                    [-80.583884, 28.597705],
                    [-80.525094, 28.459454],
                    [-80.587813, 28.410856],
                    [-80.606874, 28.336484],
                    [-80.604214, 28.257733],
                    [-80.58997500000001, 28.17799],
                    [-80.547675, 28.048795],
                    [-80.4476769492176, 27.860513513361],
                    [-80.509075, 27.822058],
                    [-80.868881, 27.822522],
                    [-80.862908, 28.347487],
                    [-80.873318, 28.48206],
                    [-80.98725, 28.612997],
                    [-80.964466, 28.612992000000002],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '37340',
            AFFGEOID: '310M600US37340',
            GEOID: '37340',
            NAME: 'Palm Bay-Melbourne-Titusville, FL',
            NAMELSAD: 'Palm Bay-Melbourne-Titusville, FL Metro Area',
            LSAD: 'M1',
            ALAND: 2628764720,
            AWATER: 1403938853,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.76975, 45.758891, -95.13967, 46.10745],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.76975, 46.10745],
                    [-95.14588, 46.106761],
                    [-95.139731, 45.773416],
                    [-95.13967, 45.758891],
                    [-95.75850800000002, 45.759932],
                    [-95.76975, 46.10745],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '10820',
            AFFGEOID: '310M600US10820',
            GEOID: '10820',
            NAME: 'Alexandria, MN',
            NAMELSAD: 'Alexandria, MN Micro Area',
            LSAD: 'M2',
            ALAND: 1649659292,
            AWATER: 215526674,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-109.059962, 38.499987, -107.394486, 39.366677549586306],
            type: 'Polygon',
            coordinates: [
                [
                    [-109.05151200000002, 39.126095],
                    [-109.05122383101701, 39.366677549586306],
                    [-108.106015, 39.366151],
                    [-107.430949, 39.366178],
                    [-107.465187, 39.314515],
                    [-107.394486, 39.256299],
                    [-107.428073, 39.257596],
                    [-107.500606, 39.217916],
                    [-107.637893, 39.14202],
                    [-107.76549, 39.043294],
                    [-107.85888800000001, 39.080112],
                    [-107.98720400000002, 39.052876],
                    [-108.094786, 38.979684000000006],
                    [-108.140323, 38.908767],
                    [-108.378951, 38.829036],
                    [-108.378698, 38.668097],
                    [-108.379136, 38.499987],
                    [-109.059962, 38.499987],
                    [-109.05151200000002, 39.126095],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '24300',
            AFFGEOID: '310M600US24300',
            GEOID: '24300',
            NAME: 'Grand Junction, CO',
            NAMELSAD: 'Grand Junction, CO Metro Area',
            LSAD: 'M1',
            ALAND: 8621348071,
            AWATER: 31991710,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-100.197510055501, 27.2622401970428, -98.798087, 28.204627],
            type: 'Polygon',
            coordinates: [
                [
                    [-100.197510055501, 28.1970007311399],
                    [-100.113719, 28.197819],
                    [-99.394177, 28.204627],
                    [-99.393972, 28.197495],
                    [-99.388946, 28.030419],
                    [-98.803325, 28.05748],
                    [-98.798323, 27.354029],
                    [-98.798087, 27.268012000000002],
                    [-98.954669, 27.269397000000005],
                    [-99.33361, 27.273223],
                    [-99.370621, 27.319174],
                    [-99.4608257991343, 27.2622401970428],
                    [-99.463309, 27.268437],
                    [-99.487937, 27.294941],
                    [-99.529654, 27.306051],
                    [-99.487521, 27.412396],
                    [-99.495104, 27.451518],
                    [-99.497519, 27.500496],
                    [-99.52832, 27.498896],
                    [-99.530138, 27.580207],
                    [-99.556812, 27.614336],
                    [-99.624515, 27.634515],
                    [-99.704601, 27.654954],
                    [-99.758534, 27.717071],
                    [-99.801651, 27.741771],
                    [-99.844737, 27.778809],
                    [-99.87784, 27.824376],
                    [-99.904385, 27.875284],
                    [-99.917461, 27.917973],
                    [-99.932161, 27.96771],
                    [-99.98492300000001, 27.990729],
                    [-100.028725, 28.073118],
                    [-100.075474, 28.124882],
                    [-100.174413, 28.179448],
                    [-100.197510055501, 28.1970007311399],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '29700',
            AFFGEOID: '310M600US29700',
            GEOID: '29700',
            NAME: 'Laredo, TX',
            NAMELSAD: 'Laredo, TX Metro Area',
            LSAD: 'M1',
            ALAND: 8706187276,
            AWATER: 36554555,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-111.04672300000001, 40.996346, -110.048, 41.5798449482258],
            type: 'Polygon',
            coordinates: [
                [
                    [-111.046636983126, 41.2516269562514],
                    [-111.0466, 41.360692],
                    [-111.045789, 41.565571],
                    [-111.045818464842, 41.5798449482258],
                    [-110.084778, 41.577615],
                    [-110.048, 41.578015],
                    [-110.048482995685, 40.9972972928506],
                    [-110.121639, 40.997101],
                    [-110.539819, 40.996346],
                    [-111.04672300000001, 40.997959],
                    [-111.046636983126, 41.2516269562514],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '21740',
            AFFGEOID: '310M600US21740',
            GEOID: '21740',
            NAME: 'Evanston, WY',
            NAMELSAD: 'Evanston, WY Micro Area',
            LSAD: 'M2',
            ALAND: 5391631765,
            AWATER: 16625817,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.581739, 40.561361, -86.165024, 40.910925],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.581532, 40.910925],
                    [-86.468509, 40.909889],
                    [-86.169012, 40.909836],
                    [-86.165024, 40.562649],
                    [-86.374417, 40.561361],
                    [-86.373521, 40.69246],
                    [-86.525164, 40.69241900000001],
                    [-86.581739, 40.735765],
                    [-86.581532, 40.910925],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '30900',
            AFFGEOID: '310M600US30900',
            GEOID: '30900',
            NAME: 'Logansport, IN',
            NAMELSAD: 'Logansport, IN Micro Area',
            LSAD: 'M2',
            ALAND: 1067448391,
            AWATER: 7002041,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.64599, 39.520315, -89.926037, 39.87675],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.599105, 39.79006],
                    [-90.571424, 39.838961],
                    [-90.583534, 39.87675],
                    [-89.994405, 39.87286],
                    [-89.993539, 39.785393],
                    [-89.926037, 39.522104000000006],
                    [-90.153782, 39.520315],
                    [-90.301831, 39.520344],
                    [-90.581055, 39.521728],
                    [-90.64599, 39.703368],
                    [-90.599105, 39.79006],
                ],
            ],
        },
        properties: {
            CSAFP: '522',
            CBSAFP: '27300',
            AFFGEOID: '310M600US27300',
            GEOID: '27300',
            NAME: 'Jacksonville, IL',
            NAMELSAD: 'Jacksonville, IL Micro Area',
            LSAD: 'M2',
            ALAND: 2123137845,
            AWATER: 13868093,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.498163, 39.386215, -79.938878, 39.639292],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.498163, 39.563327],
                    [-80.432837, 39.639292],
                    [-80.39581, 39.637347000000005],
                    [-80.347466, 39.611665],
                    [-80.153532, 39.610426],
                    [-79.938878, 39.452465],
                    [-80.087334, 39.386215],
                    [-80.197286, 39.393186],
                    [-80.282063, 39.435449],
                    [-80.494085, 39.469599],
                    [-80.498163, 39.563327],
                ],
            ],
        },
        properties: {
            CSAFP: '390',
            CBSAFP: '21900',
            AFFGEOID: '310M600US21900',
            GEOID: '21900',
            NAME: 'Fairmont, WV',
            NAMELSAD: 'Fairmont, WV Micro Area',
            LSAD: 'M2',
            ALAND: 799667746,
            AWATER: 7160908,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.066836, 32.052087, -95.85872300000001, 33.433873],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.056094, 33.003332],
                    [-97.921642, 33.001284],
                    [-97.918189, 33.433873],
                    [-97.487065, 33.433681],
                    [-97.406768, 33.43311],
                    [-97.383095, 33.43045],
                    [-96.94386, 33.41641],
                    [-96.85369, 33.413613],
                    [-96.83411, 33.405498],
                    [-96.384602, 33.397826],
                    [-96.384495, 33.341119],
                    [-96.295413, 33.351936],
                    [-95.85872300000001, 33.40953],
                    [-95.861778, 33.21933],
                    [-95.862521, 32.979571],
                    [-95.9453, 32.979877],
                    [-95.987259, 32.876415],
                    [-95.934433, 32.83721700000001],
                    [-96.076801, 32.838489],
                    [-96.075899, 32.357505],
                    [-96.196231, 32.354762],
                    [-96.452372, 32.358659],
                    [-96.451453, 32.344679],
                    [-96.38291, 32.328929],
                    [-96.896209, 32.073977000000006],
                    [-96.94065600000002, 32.052087],
                    [-97.086117, 32.265471000000005],
                    [-97.466106, 32.183889],
                    [-97.476086, 32.17346],
                    [-97.514682, 32.133605],
                    [-97.615055, 32.203581],
                    [-97.615557, 32.318617],
                    [-97.617066, 32.555484],
                    [-98.066836, 32.558822],
                    [-98.056094, 33.003332],
                ],
            ],
        },
        properties: {
            CSAFP: '206',
            CBSAFP: '19100',
            AFFGEOID: '310M600US19100',
            GEOID: '19100',
            NAME: 'Dallas-Fort Worth-Arlington, TX',
            NAMELSAD: 'Dallas-Fort Worth-Arlington, TX Metro Area',
            LSAD: 'M1',
            ALAND: 22468863980,
            AWATER: 859661521,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.1265376607175, 36.4959375921945, -90.139804, 36.926653],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.115926, 36.823672],
                    [-90.662617, 36.811453],
                    [-90.661244, 36.882909],
                    [-90.678562, 36.92649],
                    [-90.352708, 36.926653],
                    [-90.258563, 36.922043],
                    [-90.204205, 36.772262],
                    [-90.139804, 36.696072],
                    [-90.148398, 36.630656],
                    [-90.209954, 36.542034],
                    [-90.2207490539154, 36.4959375921945],
                    [-90.494575, 36.498368],
                    [-90.5761790655673, 36.498405927798],
                    [-90.765672, 36.498494],
                    [-90.78402920740501, 36.4984625681699],
                    [-91.017974, 36.498062],
                    [-91.1265376607175, 36.4977977039745],
                    [-91.115926, 36.823672],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '38740',
            AFFGEOID: '310M600US38740',
            GEOID: '38740',
            NAME: 'Poplar Bluff, MO',
            NAMELSAD: 'Poplar Bluff, MO Micro Area',
            LSAD: 'M2',
            ALAND: 3429849068,
            AWATER: 16647048,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-124.409591, 40.001299341669, -123.40608200000001, 41.46473618849091],
            type: 'Polygon',
            coordinates: [
                [
                    [-124.38702300000001, 40.504954],
                    [-124.30135499999999, 40.659643],
                    [-124.176715, 40.843618],
                    [-124.118147, 40.989263],
                    [-124.125448, 41.048504],
                    [-124.154513, 41.087159],
                    [-124.163988, 41.138675],
                    [-124.122677, 41.189726],
                    [-124.09228400000002, 41.28769500000001],
                    [-124.063076, 41.439579],
                    [-124.067473659422, 41.46473618849091],
                    [-123.770551, 41.464193],
                    [-123.77023900000002, 41.380776],
                    [-123.661363, 41.38209],
                    [-123.487831, 41.376934],
                    [-123.40829100000002, 41.179944],
                    [-123.464006, 41.076348],
                    [-123.40608200000001, 41.013792],
                    [-123.48145700000002, 40.914957],
                    [-123.560163, 40.950257],
                    [-123.62382600000001, 40.929421],
                    [-123.55985, 40.829584],
                    [-123.54405900000002, 40.739386],
                    [-123.54445800000002, 40.001923],
                    [-124.02520517453, 40.001299341669],
                    [-124.035904, 40.013319],
                    [-124.068908, 40.021307],
                    [-124.087086, 40.078442],
                    [-124.13995200000001, 40.11635],
                    [-124.187874, 40.130542],
                    [-124.25840500000001, 40.184277],
                    [-124.34307, 40.243979],
                    [-124.36341400000002, 40.260974],
                    [-124.35312399999998, 40.331425],
                    [-124.36535700000002, 40.374855000000004],
                    [-124.409591, 40.438076],
                    [-124.38702300000001, 40.504954],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '21700',
            AFFGEOID: '310M600US21700',
            GEOID: '21700',
            NAME: 'Eureka-Arcata, CA',
            NAMELSAD: 'Eureka-Arcata, CA Micro Area',
            LSAD: 'M2',
            ALAND: 9241565229,
            AWATER: 1253726036,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-111.720505, 32.983673, -110.000646, 34.498057],
            type: 'Polygon',
            coordinates: [
                [
                    [-111.674381, 34.306172000000004],
                    [-111.659644, 34.386251],
                    [-111.577285, 34.420375],
                    [-111.55465300000002, 34.471703],
                    [-111.502415, 34.498057],
                    [-111.472046, 34.462265],
                    [-111.51765700000001, 34.430392],
                    [-111.423066, 34.437225],
                    [-111.41853300000001, 34.377287],
                    [-111.397229, 34.426062],
                    [-111.250302, 34.453274],
                    [-111.147094, 34.37518500000001],
                    [-111.096661, 34.396431],
                    [-110.95886300000001, 34.314469],
                    [-110.848059, 34.267205],
                    [-110.783111, 34.299861],
                    [-110.750678, 34.263314],
                    [-110.750672, 34.000048],
                    [-110.00066000000001, 34.000046],
                    [-110.000649, 33.57693],
                    [-110.000646, 33.465916],
                    [-110.168992, 33.451698],
                    [-110.340949, 33.427066],
                    [-110.441544, 33.38167],
                    [-110.457194, 33.295268],
                    [-110.400676, 33.198814],
                    [-110.44902100000002, 33.194526],
                    [-110.593459, 33.133926],
                    [-110.632529, 33.142771],
                    [-110.725583, 33.069896],
                    [-110.780689, 32.983673],
                    [-110.955497, 33.279139],
                    [-110.974706, 33.342095],
                    [-111.039976, 33.466019],
                    [-111.153354, 33.677606],
                    [-111.222055, 33.602446],
                    [-111.28649, 33.657375],
                    [-111.389444, 33.776663000000006],
                    [-111.372317, 33.823924],
                    [-111.450904, 33.919877],
                    [-111.445079, 34.001176],
                    [-111.49498800000002, 33.99995],
                    [-111.463758, 34.072436],
                    [-111.477243, 34.150938],
                    [-111.720505, 34.160354],
                    [-111.674381, 34.306172000000004],
                ],
            ],
        },
        properties: {
            CSAFP: '429',
            CBSAFP: '37740',
            AFFGEOID: '310M600US37740',
            GEOID: '37740',
            NAME: 'Payson, AZ',
            NAMELSAD: 'Payson, AZ Micro Area',
            LSAD: 'M2',
            ALAND: 12322070149,
            AWATER: 96465330,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.643841, 40.653129, -85.33466700000001, 41.00525],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.643841, 41.002305],
                    [-85.335643, 41.00525],
                    [-85.33603, 40.917082],
                    [-85.33466700000001, 40.654413],
                    [-85.448825, 40.653607],
                    [-85.638587, 40.653129],
                    [-85.643841, 41.002305],
                ],
            ],
        },
        properties: {
            CSAFP: '258',
            CBSAFP: '26540',
            AFFGEOID: '310M600US26540',
            GEOID: '26540',
            NAME: 'Huntington, IN',
            NAMELSAD: 'Huntington, IN Micro Area',
            LSAD: 'M2',
            ALAND: 991062905,
            AWATER: 13127495,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.441848, 43.66814300000001, -75.446277, 44.4051920135031],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.412138, 43.925676],
                    [-76.279314, 43.972462],
                    [-76.307674, 44.025277],
                    [-76.37556, 44.031536],
                    [-76.361836, 44.072721],
                    [-76.370706, 44.100499],
                    [-76.355679, 44.133258],
                    [-76.334584, 44.164945],
                    [-76.286547, 44.203773],
                    [-76.206777, 44.214543],
                    [-76.164265, 44.239603],
                    [-76.161833, 44.280777],
                    [-76.097351, 44.299547],
                    [-76.000998, 44.347534],
                    [-75.94954, 44.349129],
                    [-75.8612711928347, 44.4051920135031],
                    [-75.446277, 44.21773],
                    [-75.548012, 44.101698],
                    [-75.484957, 44.073682],
                    [-75.543102, 43.968019],
                    [-75.60356, 43.971368],
                    [-75.758633, 43.87877],
                    [-75.840399, 43.883926],
                    [-75.850914, 43.791894],
                    [-75.786954, 43.788702],
                    [-75.773819, 43.688778],
                    [-76.025087, 43.707018],
                    [-76.022003, 43.66814300000001],
                    [-76.2014825473857, 43.6802859602237],
                    [-76.213205, 43.753513],
                    [-76.229268, 43.804135],
                    [-76.29675800000001, 43.857079],
                    [-76.361037, 43.872585],
                    [-76.441848, 43.882864],
                    [-76.412138, 43.925676],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '48060',
            AFFGEOID: '310M600US48060',
            GEOID: '48060',
            NAME: 'Watertown-Fort Drum, NY',
            NAMELSAD: 'Watertown-Fort Drum, NY Metro Area',
            LSAD: 'M1',
            ALAND: 3283879458,
            AWATER: 1524564723,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.794275, 35.562981, -83.2561415523036, 36.038486],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.794275, 35.887125],
                    [-83.650957, 35.986368],
                    [-83.673297, 36.038486],
                    [-83.474901, 35.922893],
                    [-83.310782, 35.895846],
                    [-83.261806, 35.825024],
                    [-83.2561415523036, 35.7151240626578],
                    [-83.26474416829, 35.7030894961701],
                    [-83.297154, 35.65775],
                    [-83.347262, 35.660474],
                    [-83.421576, 35.611186],
                    [-83.452431, 35.602918],
                    [-83.498335, 35.562981],
                    [-83.58782700000002, 35.566963],
                    [-83.653159, 35.568309],
                    [-83.6629119015893, 35.5677997932083],
                    [-83.662814, 35.690618],
                    [-83.70525, 35.71158],
                    [-83.794275, 35.887125],
                ],
            ],
        },
        properties: {
            CSAFP: '315',
            CBSAFP: '42940',
            AFFGEOID: '310M600US42940',
            GEOID: '42940',
            NAME: 'Sevierville, TN',
            NAMELSAD: 'Sevierville, TN Micro Area',
            LSAD: 'M2',
            ALAND: 1534539907,
            AWATER: 13487879,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.981389, 31.538667, -81.133493, 32.089351],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.981389, 31.794578000000005],
                    [-81.824405, 32.01488200000001],
                    [-81.761735, 32.0479],
                    [-81.718658, 32.089351],
                    [-81.692695, 32.088313],
                    [-81.60366500000002, 32.008469],
                    [-81.593292, 31.971233000000005],
                    [-81.475456, 31.974167000000005],
                    [-81.407737, 31.944301999999997],
                    [-81.355837, 31.814900000000005],
                    [-81.259887, 31.753754],
                    [-81.154731, 31.713070000000002],
                    [-81.1352985902005, 31.7105645979661],
                    [-81.139394, 31.699917000000003],
                    [-81.133493, 31.623348],
                    [-81.173079, 31.555908],
                    [-81.1748326365906, 31.539596449494802],
                    [-81.22222, 31.630419999999997],
                    [-81.314548, 31.659382],
                    [-81.43576, 31.642055],
                    [-81.491798, 31.699575],
                    [-81.566009, 31.576467],
                    [-81.663206, 31.538667],
                    [-81.681892, 31.541810000000005],
                    [-81.701774, 31.584922],
                    [-81.850624, 31.667671],
                    [-81.969052, 31.789324],
                    [-81.981389, 31.794578000000005],
                ],
            ],
        },
        properties: {
            CSAFP: '496',
            CBSAFP: '25980',
            AFFGEOID: '310M600US25980',
            GEOID: '25980',
            NAME: 'Hinesville, GA',
            NAMELSAD: 'Hinesville, GA Metro Area',
            LSAD: 'M1',
            ALAND: 2374660669,
            AWATER: 231746810,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-116.26620700000001, 42.767936, -114.971351, 44.098766],
            type: 'Polygon',
            coordinates: [
                [
                    [-116.26620700000001, 43.112269],
                    [-115.978651, 43.113432],
                    [-115.974881, 43.36502],
                    [-115.97571900000001, 43.591438],
                    [-115.87592699999999, 43.591265],
                    [-115.797108, 43.638608],
                    [-115.697508, 43.665373],
                    [-115.625615, 43.728255],
                    [-115.59279599999999, 43.798289],
                    [-115.547538, 43.792667],
                    [-115.508722, 43.884855],
                    [-115.287226, 43.93552],
                    [-115.19706, 44.068993],
                    [-115.230528, 44.098766],
                    [-115.181931, 44.090533],
                    [-115.13620899999998, 43.983859],
                    [-115.06752700000001, 43.941275],
                    [-114.990777, 43.949723],
                    [-114.971351, 43.938308],
                    [-114.996687, 43.914407],
                    [-114.98925800000002, 43.858563],
                    [-115.04234000000001, 43.745582],
                    [-115.03383399999998, 43.618636],
                    [-115.083071, 43.604399],
                    [-115.086155, 43.198064],
                    [-115.086852, 42.91456000000001],
                    [-115.037774, 42.911864],
                    [-115.037697, 42.768414],
                    [-115.45422400000001, 42.767936],
                    [-115.441522, 42.931127],
                    [-115.622852, 42.955698],
                    [-115.77150499999999, 42.938664],
                    [-115.791812, 42.972348],
                    [-115.93565500000001, 43.001733],
                    [-115.964966, 42.943673],
                    [-116.145817, 43.013416],
                    [-116.20174000000002, 43.04893],
                    [-116.191672, 43.082473],
                    [-116.26620700000001, 43.112269],
                ],
            ],
        },
        properties: {
            CSAFP: '147',
            CBSAFP: '34300',
            AFFGEOID: '310M600US34300',
            GEOID: '34300',
            NAME: 'Mountain Home, ID',
            NAMELSAD: 'Mountain Home, ID Micro Area',
            LSAD: 'M2',
            ALAND: 7964417376,
            AWATER: 68330393,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-71.8987687719366, 41.6015155410003, -70.525567, 43.54022095536651],
            type: 'Polygon',
            coordinates: [
                [
                    [-71.8987687719366, 42.7114667208088],
                    [-71.8053896027383, 42.7089149435285],
                    [-71.7725564843125, 42.7080177112404],
                    [-71.745817, 42.707287],
                    [-71.636214, 42.704888],
                    [-71.5425377992987, 42.70266947451811],
                    [-71.386943174292, 42.6989845402676],
                    [-71.351874, 42.698154],
                    [-71.294205, 42.69699],
                    [-71.255605, 42.736389],
                    [-71.2551101218106, 42.7363970532872],
                    [-71.2453840329207, 42.7365553285746],
                    [-71.284261, 42.760113000000004],
                    [-71.364418, 42.78523700000001],
                    [-71.360679, 42.798962],
                    [-71.427067, 42.82484],
                    [-71.442301, 42.890399],
                    [-71.451154, 42.9309],
                    [-71.377186, 42.947145],
                    [-71.392388, 43.009309],
                    [-71.402962, 43.041054],
                    [-71.356117, 43.102043],
                    [-71.317709, 43.162399],
                    [-71.280796, 43.218603],
                    [-71.262068, 43.250937],
                    [-71.247336, 43.275232],
                    [-71.236011, 43.284994],
                    [-71.159364, 43.355123],
                    [-71.162675, 43.538959],
                    [-71.037931, 43.529826],
                    [-71.024244, 43.482913],
                    [-70.9637926798064, 43.54022095536651],
                    [-70.954755, 43.509802],
                    [-70.960788896047, 43.474088733621606],
                    [-70.968359, 43.429283],
                    [-70.984335, 43.376128],
                    [-70.923949, 43.324768],
                    [-70.872585, 43.270152],
                    [-70.813119, 43.217252],
                    [-70.8247767120681, 43.1797631051822],
                    [-70.824801, 43.179685],
                    [-70.8281, 43.129086],
                    [-70.819549285713, 43.1232309734312],
                    [-70.756397, 43.079988],
                    [-70.703818, 43.059825],
                    [-70.735477, 43.012201],
                    [-70.765222, 42.975349],
                    [-70.798636, 42.924288],
                    [-70.8114391267743, 42.8886107767412],
                    [-70.817296, 42.87229],
                    [-70.80522, 42.781798],
                    [-70.7857977672424, 42.7401079501753],
                    [-70.772267, 42.711064],
                    [-70.72982, 42.669602],
                    [-70.681594, 42.662342],
                    [-70.645101, 42.689423],
                    [-70.602506, 42.677702],
                    [-70.594014, 42.63503],
                    [-70.654727, 42.582234],
                    [-70.698574, 42.577393],
                    [-70.804091, 42.561595],
                    [-70.848492, 42.550195],
                    [-70.8460490136975, 42.538528426184],
                    [-70.835991, 42.490496],
                    [-70.886493, 42.470197],
                    [-70.8992339791018, 42.4499156556865],
                    [-70.905993, 42.4391565115922],
                    [-70.913192, 42.427697],
                    [-70.9552110614633, 42.4254690903917],
                    [-70.9675557644072, 42.4248145569171],
                    [-70.982994, 42.423996],
                    [-70.9825456015879, 42.420221800052],
                    [-70.974897, 42.355843],
                    [-70.9758929421261, 42.3543392537219],
                    [-70.9830935859472, 42.3434671949331],
                    [-70.997838, 42.321205],
                    [-70.99305963994821, 42.3128922804124],
                    [-70.967351, 42.268168],
                    [-70.9484970435343, 42.2823546937823],
                    [-70.91749, 42.305686],
                    [-70.881242, 42.300663],
                    [-70.851093, 42.26827],
                    [-70.8358517753944, 42.2647632647134],
                    [-70.788724, 42.25392],
                    [-70.7815745455685, 42.2486369460239],
                    [-70.73056, 42.21094],
                    [-70.685315, 42.133025],
                    [-70.6792377745025, 42.1263494594225],
                    [-70.63848, 42.081579],
                    [-70.6395682630454, 42.0749487151251],
                    [-70.644337, 42.045895],
                    [-70.678798, 42.00551],
                    [-70.662476, 41.960592],
                    [-70.608166, 41.940701],
                    [-70.583572, 41.950007],
                    [-70.546386, 41.916751],
                    [-70.525567, 41.85873],
                    [-70.54103, 41.815754],
                    [-70.5364072541248, 41.8116341457464],
                    [-70.565363, 41.786669],
                    [-70.632555, 41.762904],
                    [-70.620785, 41.74753],
                    [-70.690964, 41.660457],
                    [-70.7159798446688, 41.6140132687642],
                    [-70.7617700797884, 41.6395180722704],
                    [-70.765463, 41.641575],
                    [-70.8039626132079, 41.6015155410003],
                    [-70.839175, 41.614761],
                    [-70.865003, 41.700164],
                    [-70.88497, 41.75611500000001],
                    [-70.907184, 41.763543],
                    [-70.921782, 41.791244],
                    [-71.026288, 41.778888],
                    [-71.014591, 41.799568],
                    [-71.03657, 41.816525],
                    [-70.973717, 41.860879],
                    [-70.987256, 41.905808],
                    [-70.997397, 41.918399],
                    [-70.999773, 41.929671],
                    [-71.054718, 41.985057],
                    [-71.080483, 42.095539],
                    [-71.138607, 42.072775],
                    [-71.16868100000002, 42.061161],
                    [-71.28826500000001, 42.014462],
                    [-71.353265, 41.98954],
                    [-71.3814313468931, 41.9850841191295],
                    [-71.381401, 42.018798],
                    [-71.4580809945561, 42.0168788251287],
                    [-71.4982240131247, 42.0158741110185],
                    [-71.498287, 42.064878],
                    [-71.499689, 42.10349000000001],
                    [-71.478522, 42.131378],
                    [-71.478119, 42.156782],
                    [-71.502626, 42.191416],
                    [-71.555738, 42.182503],
                    [-71.586759, 42.259545],
                    [-71.550115, 42.266023],
                    [-71.522178, 42.266454],
                    [-71.506478, 42.264276],
                    [-71.486797, 42.330189],
                    [-71.551126, 42.326395000000005],
                    [-71.58521, 42.310952],
                    [-71.624702, 42.350465],
                    [-71.604251, 42.39770300000001],
                    [-71.5591, 42.411943],
                    [-71.560367, 42.47435],
                    [-71.529841, 42.519841],
                    [-71.53878, 42.54303],
                    [-71.619927, 42.552502],
                    [-71.635869, 42.524024],
                    [-71.678969, 42.53043],
                    [-71.664601, 42.611598],
                    [-71.775755, 42.64423],
                    [-71.858483, 42.633815],
                    [-71.8987687719366, 42.7114667208088],
                ],
            ],
        },
        properties: {
            CSAFP: '148',
            CBSAFP: '14460',
            AFFGEOID: '310M600US14460',
            GEOID: '14460',
            NAME: 'Boston-Cambridge-Newton, MA-NH',
            NAMELSAD: 'Boston-Cambridge-Newton, MA-NH Metro Area',
            LSAD: 'M1',
            ALAND: 9028977559,
            AWATER: 2654814510,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.885639, 25.147441, -80.03212, 26.970534],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.885639, 26.958919],
                    [-80.580237, 26.957934],
                    [-80.142007, 26.956640000000004],
                    [-80.0830776297553, 26.970534],
                    [-80.046263, 26.859238],
                    [-80.0375222775318, 26.805032294969905],
                    [-80.033785231012, 26.7818569519624],
                    [-80.03212, 26.77153],
                    [-80.03576300000002, 26.676043],
                    [-80.0355674560264, 26.6449040887889],
                    [-80.035363, 26.612346],
                    [-80.038863, 26.569347],
                    [-80.050363, 26.509549],
                    [-80.061607857332, 26.4264024766503],
                    [-80.0758744394432, 26.3209127698802],
                    [-80.085565, 26.249259],
                    [-80.1086585055892, 26.0932938862554],
                    [-80.109566, 26.087165],
                    [-80.1129782512854, 26.017023960834],
                    [-80.11500678360632, 25.9753261627924],
                    [-80.1151289238901, 25.9728154900106],
                    [-80.117904, 25.915772],
                    [-80.1145142441078, 25.8741996346921],
                    [-80.1143854517086, 25.8726201095467],
                    [-80.109953, 25.81826],
                    [-80.123806, 25.762767],
                    [-80.154972, 25.66549],
                    [-80.176916, 25.685062],
                    [-80.229107, 25.732509],
                    [-80.265879, 25.658373],
                    [-80.301464, 25.613299],
                    [-80.313918, 25.539164],
                    [-80.3159759814327, 25.5326208272661],
                    [-80.33704900000001, 25.465621],
                    [-80.31036, 25.389707],
                    [-80.234849, 25.42196],
                    [-80.176024, 25.521154],
                    [-80.163156, 25.452184],
                    [-80.2293537849126, 25.3421186263507],
                    [-80.265472, 25.354296],
                    [-80.372121, 25.310215],
                    [-80.395081, 25.272937],
                    [-80.394931, 25.253537],
                    [-80.41887, 25.23631],
                    [-80.450659, 25.240241],
                    [-80.48282600000002, 25.224786],
                    [-80.492769, 25.205579],
                    [-80.495394, 25.199808],
                    [-80.542391, 25.206379],
                    [-80.650532, 25.189097],
                    [-80.710607, 25.15253],
                    [-80.747746, 25.147441],
                    [-80.81213300000002, 25.186039],
                    [-80.8581700400306, 25.1775203265261],
                    [-80.87319, 25.363993],
                    [-80.873125, 25.760885],
                    [-80.873096, 25.805377],
                    [-80.872932, 25.979434],
                    [-80.87971, 26.259308],
                    [-80.881285, 26.333806],
                    [-80.885639, 26.958919],
                ],
            ],
        },
        properties: {
            CSAFP: '370',
            CBSAFP: '33100',
            AFFGEOID: '310M600US33100',
            GEOID: '33100',
            NAME: 'Miami-Fort Lauderdale-Pompano Beach, FL',
            NAMELSAD: 'Miami-Fort Lauderdale-Pompano Beach, FL Metro Area',
            LSAD: 'M1',
            ALAND: 13123595256,
            AWATER: 2625991838,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.932519, 29.9697520164336, -83.604186, 30.711542],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.932519, 30.606393000000004],
                    [-84.860943, 30.693246],
                    [-84.864693, 30.711542],
                    [-84.8634638443825, 30.7114970146544],
                    [-84.812997, 30.70965],
                    [-84.474519, 30.692783],
                    [-84.38075418355251, 30.688827197045903],
                    [-84.2855145090395, 30.684809171948],
                    [-84.124993, 30.678037],
                    [-84.0837530823, 30.675943397968],
                    [-84.007454, 30.672069968469202],
                    [-83.820973, 30.662603],
                    [-83.743729, 30.6585269137505],
                    [-83.6117040588264, 30.6515600937086],
                    [-83.604186, 30.581916],
                    [-83.736954, 30.507569000000004],
                    [-83.72172, 30.434354],
                    [-83.806667, 30.371689],
                    [-83.820235, 30.30354],
                    [-83.856987, 30.273318000000003],
                    [-83.915533, 30.245386],
                    [-83.992310340478, 30.089268737728695],
                    [-84.000716, 30.096209],
                    [-84.06299, 30.101378],
                    [-84.0761320719114, 30.099089883730105],
                    [-84.124889, 30.090601000000003],
                    [-84.179149, 30.073187],
                    [-84.20801, 30.084776000000005],
                    [-84.289727, 30.057197],
                    [-84.36611500000001, 30.008661999999998],
                    [-84.3454463222869, 29.9697520164336],
                    [-84.467713, 30.001853000000004],
                    [-84.523992, 29.981728],
                    [-84.54445, 30.011030000000005],
                    [-84.591048, 30.029637000000005],
                    [-84.670364, 30.128208000000004],
                    [-84.713351, 30.30017],
                    [-84.715144, 30.333077],
                    [-84.646408, 30.388281000000003],
                    [-84.781111, 30.459794000000002],
                    [-84.781813, 30.518693000000003],
                    [-84.882616, 30.533094],
                    [-84.883316, 30.605589],
                    [-84.932519, 30.606393000000004],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '45220',
            AFFGEOID: '310M600US45220',
            GEOID: '45220',
            NAME: 'Tallahassee, FL',
            NAMELSAD: 'Tallahassee, FL Metro Area',
            LSAD: 'M1',
            ALAND: 6188265281,
            AWATER: 1115059165,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-73.029537, 41.2538242743911, -72.099229, 42.0389034162467],
            type: 'Polygon',
            coordinates: [
                [
                    [-73.0087627816307, 42.0389034162467],
                    [-72.999549, 42.038653],
                    [-72.847142, 42.036894],
                    [-72.810078, 41.998316],
                    [-72.7747593160786, 42.0021291041803],
                    [-72.766739, 42.002995],
                    [-72.735496, 42.036399],
                    [-72.607933, 42.030795],
                    [-72.528131, 42.034295],
                    [-72.5091793362983, 42.0340804965901],
                    [-72.3974762551959, 42.0328161911358],
                    [-72.317148, 42.031907],
                    [-72.198845978132, 42.030103893282],
                    [-72.1357314619467, 42.0291419299416],
                    [-72.1021594345268, 42.0286302400548],
                    [-72.099229, 41.958948],
                    [-72.151654, 41.959302],
                    [-72.21698, 41.958161],
                    [-72.217719, 41.835184000000005],
                    [-72.16449800000001, 41.834913],
                    [-72.156321, 41.753491],
                    [-72.252643, 41.728202],
                    [-72.239559, 41.714191],
                    [-72.334226, 41.643598],
                    [-72.32451, 41.61857],
                    [-72.38415, 41.588652],
                    [-72.410367, 41.602581],
                    [-72.46673, 41.583899],
                    [-72.430767, 41.524567],
                    [-72.32293100000001, 41.518119],
                    [-72.305531, 41.436282],
                    [-72.43371, 41.423995000000005],
                    [-72.37600400000001, 41.376255],
                    [-72.3400129087019, 41.2778492622384],
                    [-72.348643, 41.277446],
                    [-72.386629, 41.261798],
                    [-72.40593, 41.278398],
                    [-72.472539, 41.270103],
                    [-72.5345648971681, 41.2538242743911],
                    [-72.613919, 41.329428],
                    [-72.653672, 41.438125],
                    [-72.678739, 41.433797],
                    [-72.746141, 41.423297],
                    [-72.74478, 41.498183],
                    [-72.748878, 41.544279],
                    [-72.752181, 41.578894],
                    [-72.84428, 41.544725],
                    [-72.94635, 41.556797],
                    [-72.938501, 41.644311],
                    [-72.98325, 41.639598],
                    [-72.99855, 41.712498],
                    [-72.99895, 41.714398],
                    [-73.016936, 41.79818300000001],
                    [-72.949024, 41.806431],
                    [-72.905945, 41.921696],
                    [-72.88706, 41.973121],
                    [-73.029537, 41.966606],
                    [-73.0087627816307, 42.0389034162467],
                ],
            ],
        },
        properties: {
            CSAFP: '278',
            CBSAFP: '25540',
            AFFGEOID: '310M600US25540',
            GEOID: '25540',
            NAME: 'Hartford-East Hartford-Middletown, CT',
            NAMELSAD: 'Hartford-East Hartford-Middletown, CT Metro Area',
            LSAD: 'M1',
            ALAND: 3922771125,
            AWATER: 238846677,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.270817, 38.57236958956191, -82.650039, 39.015791],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.270817, 39.015791],
                    [-83.212107, 38.960148],
                    [-83.04362, 38.956939000000006],
                    [-82.80709, 38.948073],
                    [-82.753391, 38.945373000000004],
                    [-82.76069, 38.854875],
                    [-82.650039, 38.849073],
                    [-82.662886, 38.747977],
                    [-82.706823, 38.677495],
                    [-82.763895, 38.679974],
                    [-82.740383, 38.597167],
                    [-82.8115423846504, 38.57236958956191],
                    [-82.851314, 38.604334],
                    [-82.869592, 38.678177],
                    [-82.871192, 38.718377],
                    [-82.8822845498777, 38.741607822612],
                    [-82.889193, 38.756076],
                    [-82.943147, 38.74328],
                    [-83.011816, 38.730057],
                    [-83.0303349312311, 38.7168676766561],
                    [-83.04233800000002, 38.708319],
                    [-83.112372, 38.671685],
                    [-83.128973, 38.640231],
                    [-83.172647, 38.620252],
                    [-83.239515, 38.628588],
                    [-83.2642676908446, 38.6131319856547],
                    [-83.268292, 38.634012],
                    [-83.270817, 39.015791],
                ],
            ],
        },
        properties: {
            CSAFP: '170',
            CBSAFP: '39020',
            AFFGEOID: '310M600US39020',
            GEOID: '39020',
            NAME: 'Portsmouth, OH',
            NAMELSAD: 'Portsmouth, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1580267231,
            AWATER: 16098454,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-75.886756, 42.824943, -74.695985, 44.095939],
            type: 'Polygon',
            coordinates: [
                [
                    [-75.886756, 43.325021],
                    [-75.814627, 43.483578],
                    [-75.75641600000002, 43.470416],
                    [-75.53342900000001, 43.419815],
                    [-75.110692, 43.61498400000001],
                    [-75.167806, 44.095939],
                    [-75.062779, 44.0504],
                    [-74.854171, 44.070089],
                    [-74.775617, 43.486677],
                    [-74.867712, 43.339826],
                    [-74.712615, 43.286143],
                    [-74.695985, 43.174177],
                    [-74.763614, 43.05796200000001],
                    [-74.759895, 43.047423],
                    [-74.74380500000001, 43.030741],
                    [-74.763303, 42.863237],
                    [-74.878822, 42.898274],
                    [-74.90673800000002, 42.824943],
                    [-75.100999, 42.908363],
                    [-75.210642, 42.85654900000001],
                    [-75.212158, 42.879973],
                    [-75.247963, 42.871604],
                    [-75.437167, 42.863319],
                    [-75.44417300000002, 42.933089],
                    [-75.542803, 42.931774],
                    [-75.552774, 43.037554],
                    [-75.723929, 43.162596],
                    [-75.884275, 43.155562],
                    [-75.886756, 43.325021],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '46540',
            AFFGEOID: '310M600US46540',
            GEOID: '46540',
            NAME: 'Utica-Rome, NY',
            NAMELSAD: 'Utica-Rome, NY Metro Area',
            LSAD: 'M1',
            ALAND: 6793613847,
            AWATER: 237715700,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.288692, 33.775372, -79.324317, 34.531925],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.288596, 34.366207],
                    [-80.045439, 34.492631],
                    [-79.884402, 34.491404],
                    [-79.829981, 34.531925],
                    [-79.752769, 34.514059],
                    [-79.750566, 34.436797],
                    [-79.65776400000001, 34.30519],
                    [-79.633413, 34.297994],
                    [-79.601003, 34.290532],
                    [-79.54793, 34.230913],
                    [-79.571834, 34.183689],
                    [-79.51530800000002, 34.097207],
                    [-79.51694, 33.99398],
                    [-79.324317, 33.799123],
                    [-79.496466, 33.775372],
                    [-79.635808, 33.818038],
                    [-79.687973, 33.804914],
                    [-79.87562, 33.885245],
                    [-79.945948, 33.885997],
                    [-79.974601, 33.946527],
                    [-79.893517, 33.988017],
                    [-79.903658, 34.002245],
                    [-80.000594, 34.047877],
                    [-80.02593000000002, 34.073226],
                    [-80.069878, 34.086191],
                    [-80.158582, 34.161623],
                    [-80.112727, 34.197018],
                    [-80.177225, 34.366203],
                    [-80.243226, 34.324455],
                    [-80.288692, 34.364139],
                    [-80.288596, 34.366207],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '22500',
            AFFGEOID: '310M600US22500',
            GEOID: '22500',
            NAME: 'Florence, SC',
            NAMELSAD: 'Florence, SC Metro Area',
            LSAD: 'M1',
            ALAND: 3525216739,
            AWATER: 24435234,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-72.556112, 42.7122936725326, -71.9290300590535, 43.181968],
            type: 'Polygon',
            coordinates: [
                [
                    [-72.556112, 42.866252],
                    [-72.531469, 42.89795],
                    [-72.532186, 42.954945],
                    [-72.492597, 42.967648],
                    [-72.444977, 43.004416000000006],
                    [-72.4519547090682, 43.0204949106303],
                    [-72.462248, 43.044214],
                    [-72.435191, 43.086622],
                    [-72.451802, 43.153486],
                    [-72.4503959392159, 43.1612135169064],
                    [-72.431632, 43.135635],
                    [-72.372045, 43.132118],
                    [-72.156661, 43.181968],
                    [-72.178497, 43.131866],
                    [-72.038149, 43.128807],
                    [-72.062222, 42.944796],
                    [-72.001215, 42.919443],
                    [-71.97471, 42.789402],
                    [-71.9290300590535, 42.7122936725326],
                    [-72.081365050188, 42.7164565385412],
                    [-72.1110573073528, 42.7172679403547],
                    [-72.124526, 42.717636],
                    [-72.2036134798068, 42.7198185286799],
                    [-72.2830340925109, 42.722010250624],
                    [-72.4120300647523, 42.7255700733633],
                    [-72.4511950518227, 42.7266508854936],
                    [-72.458519, 42.726853],
                    [-72.477615, 42.761245],
                    [-72.53960000000001, 42.804832],
                    [-72.5539020508486, 42.8580316101697],
                    [-72.556112, 42.866252],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '28300',
            AFFGEOID: '310M600US28300',
            GEOID: '28300',
            NAME: 'Keene, NH',
            NAMELSAD: 'Keene, NH Micro Area',
            LSAD: 'M2',
            ALAND: 1830367250,
            AWATER: 57589052,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.752754, 38.895633, -81.162455, 39.409274],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.752754, 39.184676],
                    [-81.7214681391725, 39.2109608092037],
                    [-81.711628, 39.219228],
                    [-81.678331, 39.273755],
                    [-81.613896, 39.275339],
                    [-81.565247, 39.276175],
                    [-81.559647, 39.330774],
                    [-81.503189, 39.373242000000005],
                    [-81.456143, 39.409274],
                    [-81.412706, 39.394618],
                    [-81.393794, 39.351706],
                    [-81.3703898088263, 39.34870067240341],
                    [-81.363638, 39.320502],
                    [-81.239477, 39.268328],
                    [-81.259324, 39.267131],
                    [-81.298017, 39.185572],
                    [-81.250252, 39.035074],
                    [-81.162455, 39.030608],
                    [-81.27269, 38.936338],
                    [-81.278412, 38.914867],
                    [-81.343911, 38.895633],
                    [-81.448148, 38.941645],
                    [-81.502628, 38.917922],
                    [-81.532186, 39.00545],
                    [-81.581447, 39.026179],
                    [-81.720677, 39.084228],
                    [-81.7502668434263, 39.1040311930998],
                    [-81.742953, 39.106578],
                    [-81.7522970367401, 39.1810347474059],
                    [-81.752754, 39.184676],
                ],
            ],
        },
        properties: {
            CSAFP: '425',
            CBSAFP: '37620',
            AFFGEOID: '310M600US37620',
            GEOID: '37620',
            NAME: 'Parkersburg-Vienna, WV',
            NAMELSAD: 'Parkersburg-Vienna, WV Metro Area',
            LSAD: 'M1',
            ALAND: 1551430857,
            AWATER: 32430476,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-122.40678600000001, 38.031761, -121.59327300000001, 38.535399],
            type: 'Polygon',
            coordinates: [
                [
                    [-122.40678600000001, 38.155632],
                    [-122.213457, 38.154974],
                    [-122.19588400000002, 38.162776],
                    [-122.20598199999999, 38.315713],
                    [-122.061379, 38.327411],
                    [-122.126389, 38.428918],
                    [-122.10328100000001, 38.513348],
                    [-122.01167299999999, 38.488991],
                    [-121.940285, 38.533384],
                    [-121.707976, 38.535399],
                    [-121.693697, 38.313732],
                    [-121.59327300000001, 38.313089],
                    [-121.615413, 38.195696],
                    [-121.685645, 38.159644],
                    [-121.71213000000002, 38.08552],
                    [-121.74316400000001, 38.087229],
                    [-121.796395, 38.060847],
                    [-121.827916, 38.066223],
                    [-121.843228, 38.076655],
                    [-121.860477, 38.070607],
                    [-121.862462, 38.06603],
                    [-121.87823499999999, 38.049318],
                    [-122.05038600000002, 38.060198],
                    [-122.09573299999998, 38.048355],
                    [-122.14312000000001, 38.031761],
                    [-122.26932005491699, 38.0603744975312],
                    [-122.301804, 38.105142],
                    [-122.39358799999998, 38.143449],
                    [-122.397581230624, 38.1420035972533],
                    [-122.40678600000001, 38.155632],
                ],
            ],
        },
        properties: {
            CSAFP: '488',
            CBSAFP: '46700',
            AFFGEOID: '310M600US46700',
            GEOID: '46700',
            NAME: 'Vallejo, CA',
            NAMELSAD: 'Vallejo, CA Metro Area',
            LSAD: 'M1',
            ALAND: 2128527683,
            AWATER: 218619356,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.50073800000001, 38.738587, -95.056258, 39.067205],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.500724, 38.869815],
                    [-95.500254, 39.056656],
                    [-95.419439, 39.067205],
                    [-95.285425, 39.032162],
                    [-95.187103, 39.044109],
                    [-95.186189, 38.964542],
                    [-95.056258, 38.98212],
                    [-95.056412, 38.738587],
                    [-95.50073800000001, 38.738808],
                    [-95.500724, 38.869815],
                ],
            ],
        },
        properties: {
            CSAFP: '312',
            CBSAFP: '29940',
            AFFGEOID: '310M600US29940',
            GEOID: '29940',
            NAME: 'Lawrence, KS',
            NAMELSAD: 'Lawrence, KS Metro Area',
            LSAD: 'M1',
            ALAND: 1180428103,
            AWATER: 48875040,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.452372, 32.005895, -95.428512, 32.358659],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.452372, 32.358659],
                    [-96.196231, 32.354762],
                    [-96.075899, 32.357505],
                    [-95.449165, 32.355233],
                    [-95.488733, 32.232486],
                    [-95.478585, 32.147224],
                    [-95.459908, 32.135559],
                    [-95.428512, 32.084475],
                    [-96.052786, 32.005895],
                    [-96.05478, 32.012526],
                    [-96.105364, 32.075337],
                    [-96.146523, 32.211715],
                    [-96.196965, 32.206942],
                    [-96.38291, 32.328929],
                    [-96.451453, 32.344679],
                    [-96.452372, 32.358659],
                ],
            ],
        },
        properties: {
            CSAFP: '206',
            CBSAFP: '11980',
            AFFGEOID: '310M600US11980',
            GEOID: '11980',
            NAME: 'Athens, TX',
            NAMELSAD: 'Athens, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2263082389,
            AWATER: 193353703,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.9164070054658, 40.00095857255451, -96.463632, 40.523625],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.916055, 40.349727],
                    [-96.913468, 40.369365],
                    [-96.912637, 40.523625],
                    [-96.463632, 40.523013],
                    [-96.463667, 40.262173],
                    [-96.4637120767748, 40.00095857255451],
                    [-96.46994500000001, 40.000966000000005],
                    [-96.805768, 40.0013684550954],
                    [-96.873812, 40.00145],
                    [-96.9164070054658, 40.0014540910439],
                    [-96.916055, 40.349727],
                ],
            ],
        },
        properties: {
            CSAFP: '339',
            CBSAFP: '13100',
            AFFGEOID: '310M600US13100',
            GEOID: '13100',
            NAME: 'Beatrice, NE',
            NAMELSAD: 'Beatrice, NE Micro Area',
            LSAD: 'M2',
            ALAND: 2205273758,
            AWATER: 22012188,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-113.00776, 42.863051000000006, -111.588049, 43.626623],
            type: 'Polygon',
            coordinates: [
                [
                    [-113.007712, 43.110933],
                    [-113.007318, 43.284778],
                    [-112.947016, 43.284821],
                    [-112.936835, 43.449386],
                    [-112.816704, 43.449164],
                    [-112.816278, 43.535949],
                    [-112.698891, 43.540052],
                    [-112.697122, 43.62314],
                    [-112.639238, 43.626574],
                    [-112.519861, 43.626623],
                    [-112.520024, 43.42517],
                    [-112.103298, 43.425057],
                    [-112.043672, 43.410143],
                    [-112.043821, 43.366708],
                    [-111.822475, 43.366035],
                    [-111.822494, 43.282286],
                    [-111.588049, 43.281841],
                    [-111.58948900000001, 43.020052],
                    [-112.063023, 43.022364],
                    [-112.678364, 43.02173],
                    [-112.750562, 42.951528],
                    [-112.816578, 42.863231],
                    [-113.00776, 42.863051000000006],
                    [-113.007712, 43.110933],
                ],
            ],
        },
        properties: {
            CSAFP: '292',
            CBSAFP: '13940',
            AFFGEOID: '310M600US13940',
            GEOID: '13940',
            NAME: 'Blackfoot, ID',
            NAMELSAD: 'Blackfoot, ID Micro Area',
            LSAD: 'M2',
            ALAND: 5422741967,
            AWATER: 68632371,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.172485, 35.923986, -82.595065, 36.418769],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.166085, 36.18089400000001],
                    [-83.14219, 36.18739],
                    [-83.082312, 36.242296],
                    [-82.937183, 36.334797],
                    [-82.702566, 36.410412],
                    [-82.631794, 36.418769],
                    [-82.647769, 36.146404],
                    [-82.595065, 36.096205],
                    [-82.6057036107936, 36.0371988635619],
                    [-82.628365, 36.062105],
                    [-82.725065, 36.018204],
                    [-82.779397, 35.992511],
                    [-82.787465, 35.952163],
                    [-82.81613, 35.923986],
                    [-82.860724, 35.94743],
                    [-82.89375141843142, 35.9338572697089],
                    [-83.102761, 36.120909],
                    [-83.172485, 36.157095],
                    [-83.166085, 36.18089400000001],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '24620',
            AFFGEOID: '310M600US24620',
            GEOID: '24620',
            NAME: 'Greeneville, TN',
            NAMELSAD: 'Greeneville, TN Micro Area',
            LSAD: 'M2',
            ALAND: 1611409727,
            AWATER: 5099752,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-105.278236, 40.998048, -104.052287, 41.656655],
            type: 'Polygon',
            coordinates: [
                [
                    [-105.278236, 41.656655],
                    [-104.653338, 41.653007],
                    [-104.652992, 41.565014000000005],
                    [-104.052634571633, 41.5642757795737],
                    [-104.052287, 41.393307],
                    [-104.052287134121, 41.3932140001934],
                    [-104.052453, 41.278202],
                    [-104.053142, 41.114457],
                    [-104.053249, 41.00140600000001],
                    [-104.497058, 41.001805],
                    [-104.855273, 40.998048],
                    [-104.943371, 40.9980741037298],
                    [-105.276859994035, 40.9981729176259],
                    [-105.278236, 41.656655],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '16940',
            AFFGEOID: '310M600US16940',
            GEOID: '16940',
            NAME: 'Cheyenne, WY',
            NAMELSAD: 'Cheyenne, WY Metro Area',
            LSAD: 'M1',
            ALAND: 6956355039,
            AWATER: 4348083,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.459908, 31.42571, -94.865857, 32.137989],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.459908, 32.135559],
                    [-94.985272, 32.137989],
                    [-94.984768, 31.846144],
                    [-94.937532, 31.845555000000004],
                    [-94.978128, 31.793294],
                    [-94.924219, 31.579487],
                    [-94.865857, 31.526915999999996],
                    [-95.003345, 31.42571],
                    [-95.101382, 31.469887],
                    [-95.099966, 31.518869],
                    [-95.168509, 31.583671],
                    [-95.273203, 31.592886],
                    [-95.258859, 31.609959000000003],
                    [-95.275849, 31.657229],
                    [-95.396531, 31.765265999999997],
                    [-95.412908, 31.835157000000002],
                    [-95.446747, 31.843116],
                    [-95.423832, 31.932009000000004],
                    [-95.44644900000002, 31.998517000000003],
                    [-95.428512, 32.084475],
                    [-95.459908, 32.135559],
                ],
            ],
        },
        properties: {
            CSAFP: '540',
            CBSAFP: '27380',
            AFFGEOID: '310M600US27380',
            GEOID: '27380',
            NAME: 'Jacksonville, TX',
            NAMELSAD: 'Jacksonville, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2727141726,
            AWATER: 23873001,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.969675, 33.440533, -93.104405, 34.008893],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.969675, 33.739666],
                    [-93.956951, 33.750927],
                    [-93.831577, 33.748236],
                    [-93.823076, 34.008893],
                    [-93.749844, 34.006872],
                    [-93.527784, 33.943024],
                    [-93.456665, 33.95682],
                    [-93.418764, 33.948839],
                    [-93.373364, 33.957093],
                    [-93.258544, 33.828893],
                    [-93.104405, 33.777093],
                    [-93.116328, 33.452922],
                    [-93.22134, 33.440533],
                    [-93.365644, 33.444491],
                    [-93.483931, 33.447368],
                    [-93.483217, 33.476172],
                    [-93.719951, 33.48203],
                    [-93.772085, 33.498602],
                    [-93.825878, 33.609891],
                    [-93.967717, 33.675836],
                    [-93.969675, 33.739666],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '26260',
            AFFGEOID: '310M600US26260',
            GEOID: '26260',
            NAME: 'Hope, AR',
            NAMELSAD: 'Hope, AR Micro Area',
            LSAD: 'M2',
            ALAND: 3483506356,
            AWATER: 43227398,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-120.670835, 33.895712, -119.442269, 35.114678],
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [-119.91621599999999, 34.058351],
                        [-119.857304, 34.071298],
                        [-119.739472, 34.049299],
                        [-119.5667, 34.053452],
                        [-119.47073665694401, 34.053996622104],
                        [-119.487719557382, 33.9965151543893],
                        [-119.55447200000002, 33.99782],
                        [-119.66282500000001, 33.985889],
                        [-119.721206, 33.959583],
                        [-119.79593799999999, 33.962929],
                        [-119.873358, 33.980375],
                        [-119.876916, 34.023527],
                        [-119.91621599999999, 34.058351],
                    ],
                ],
                [
                    [
                        [-120.368278, 34.076465],
                        [-120.24248000000001, 34.057172],
                        [-120.135853, 34.026087],
                        [-120.055107, 34.037729],
                        [-119.984316, 33.983948],
                        [-119.973691, 33.942481],
                        [-120.049682, 33.914563],
                        [-120.121817, 33.895712],
                        [-120.179049, 33.927994000000005],
                        [-120.20008500000002, 33.956904],
                        [-120.36484, 33.991781],
                        [-120.454134, 34.028081],
                        [-120.368278, 34.076465],
                    ],
                ],
                [
                    [
                        [-120.65030521184002, 34.9751663022687],
                        [-120.496222, 34.993155],
                        [-120.435473, 34.986759],
                        [-120.30182699999999, 34.905571],
                        [-120.33454799999998, 35.006315],
                        [-120.188383, 35.030378000000006],
                        [-120.167616, 35.075297],
                        [-120.08208400000001, 35.114678],
                        [-119.980122, 35.057573],
                        [-119.928419, 35.059794],
                        [-119.74556600000001, 34.973676],
                        [-119.672963, 34.973375],
                        [-119.535757, 34.897576],
                        [-119.47249399999998, 34.901378],
                        [-119.44369100000002, 34.901171],
                        [-119.442353, 34.561101],
                        [-119.442269, 34.463949],
                        [-119.445991, 34.404067],
                        [-119.477946706724, 34.3788375587756],
                        [-119.536957, 34.395495],
                        [-119.616862, 34.420995],
                        [-119.684666, 34.408297],
                        [-119.709067, 34.395397],
                        [-119.78587100000001, 34.415997],
                        [-119.835771, 34.415796],
                        [-119.873971, 34.408795],
                        [-119.971951, 34.444641],
                        [-120.05068199999998, 34.461651],
                        [-120.14116500000002, 34.473405],
                        [-120.295051, 34.470623],
                        [-120.451425, 34.447094],
                        [-120.511421, 34.522953],
                        [-120.55009200000002, 34.542794],
                        [-120.581293, 34.556959],
                        [-120.62257500000001, 34.554017],
                        [-120.64573899999999, 34.581035],
                        [-120.60197000000001, 34.692095],
                        [-120.614852, 34.730709],
                        [-120.62632, 34.738072],
                        [-120.61026599999998, 34.85818],
                        [-120.670835, 34.904115],
                        [-120.65030521184002, 34.9751663022687],
                    ],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '42200',
            AFFGEOID: '310M600US42200',
            GEOID: '42200',
            NAME: 'Santa Maria-Santa Barbara, CA',
            NAMELSAD: 'Santa Maria-Santa Barbara, CA Metro Area',
            LSAD: 'M1',
            ALAND: 7080860229,
            AWATER: 2729213507,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-109.05122383101701, 38.978303, -106.426487, 40.091538],
            type: 'Polygon',
            coordinates: [
                [
                    [-109.05106684627302, 39.4977388093662],
                    [-109.050871925262, 39.6604717771594],
                    [-108.895728, 39.650337],
                    [-108.55462700000001, 39.650164],
                    [-108.554722, 39.69364],
                    [-107.938546, 39.694464],
                    [-107.938502, 39.824996],
                    [-107.43177, 39.828377],
                    [-107.43166500000001, 39.917725],
                    [-107.31875100000002, 39.914968],
                    [-107.316993, 40.090765],
                    [-107.037363, 40.091538],
                    [-107.03205, 40.002939],
                    [-107.033968, 39.918913],
                    [-107.113672, 39.919112],
                    [-107.11344600000001, 39.366066],
                    [-106.509214, 39.362298],
                    [-106.426487, 39.361871],
                    [-106.50238, 39.298567],
                    [-106.508614, 39.166681],
                    [-106.561265, 39.155677],
                    [-106.577966, 39.057908],
                    [-106.599214, 38.997994],
                    [-106.694969, 39.055667],
                    [-106.749357, 39.042528],
                    [-106.801874, 38.978303],
                    [-106.86168700000002, 39.009439],
                    [-106.906422, 38.992091],
                    [-107.001622, 39.042273],
                    [-107.06632, 39.118805],
                    [-107.28249, 39.118718],
                    [-107.375608, 39.197931],
                    [-107.394486, 39.256299],
                    [-107.465187, 39.314515],
                    [-107.430949, 39.366178],
                    [-108.106015, 39.366151],
                    [-109.05122383101701, 39.366677549586306],
                    [-109.05106684627302, 39.4977388093662],
                ],
            ],
        },
        properties: {
            CSAFP: '233',
            CBSAFP: '24060',
            AFFGEOID: '310M600US24060',
            GEOID: '24060',
            NAME: 'Glenwood Springs, CO',
            NAMELSAD: 'Glenwood Springs, CO Micro Area',
            LSAD: 'M2',
            ALAND: 10147912171,
            AWATER: 28169202,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.9328490284944, 41.236798, -86.486433, 41.76024],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.9328490284944, 41.7164969379022],
                    [-86.90913, 41.726938],
                    [-86.824828, 41.76024],
                    [-86.6413229205208, 41.7596749381428],
                    [-86.640044, 41.759671000000004],
                    [-86.5242197424429, 41.7595721559735],
                    [-86.526682, 41.65044],
                    [-86.486433, 41.570545],
                    [-86.524711, 41.432886],
                    [-86.642542, 41.432639],
                    [-86.739995, 41.321708],
                    [-86.814652, 41.273501],
                    [-86.870265, 41.264553],
                    [-86.930079, 41.236798],
                    [-86.9328490284944, 41.7164969379022],
                ],
            ],
        },
        properties: {
            CSAFP: '176',
            CBSAFP: '33140',
            AFFGEOID: '310M600US33140',
            GEOID: '33140',
            NAME: 'Michigan City-La Porte, IN',
            NAMELSAD: 'Michigan City-La Porte, IN Metro Area',
            LSAD: 'M1',
            ALAND: 1549555243,
            AWATER: 38779440,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.593177, 32.72853, -84.861768, 33.224693],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.593177, 33.10734],
                    [-85.232441, 33.108075],
                    [-85.2365953712528, 33.1295440282507],
                    [-85.117402, 33.163654],
                    [-85.10798, 33.195098],
                    [-84.939015, 33.224693],
                    [-84.862135, 33.223858],
                    [-84.862359, 33.191173],
                    [-84.861768, 32.872495],
                    [-84.881702, 32.868761],
                    [-85.1861174017892, 32.8701382699716],
                    [-85.1844, 32.861317],
                    [-85.160963, 32.826672],
                    [-85.1245332227506, 32.7516296067514],
                    [-85.285043, 32.730731],
                    [-85.574954, 32.736347],
                    [-85.593151, 32.72853],
                    [-85.593177, 33.10734],
                ],
            ],
        },
        properties: {
            CSAFP: '122',
            CBSAFP: '29300',
            AFFGEOID: '310M600US29300',
            GEOID: '29300',
            NAME: 'LaGrange, GA-AL',
            NAMELSAD: 'LaGrange, GA-AL Micro Area',
            LSAD: 'M2',
            ALAND: 2617363818,
            AWATER: 99729451,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-123.72500100000002, 44.683425, -121.73422299999999, 45.284729],
            type: 'Polygon',
            coordinates: [
                [
                    [-123.724663, 45.044432],
                    [-123.72436800000001, 45.076226000000005],
                    [-123.068667, 45.07486],
                    [-122.99669299999998, 45.117063],
                    [-123.04117, 45.220788],
                    [-122.968666, 45.284729],
                    [-122.849925, 45.259697],
                    [-122.73903, 45.259359],
                    [-122.78700699999999, 45.128625],
                    [-122.70511499999999, 45.059023],
                    [-122.59552300000001, 45.019679],
                    [-122.53599900000002, 44.937161],
                    [-122.40102100000001, 44.88572],
                    [-121.73422299999999, 44.88572],
                    [-121.75299399999999, 44.829919],
                    [-121.819998, 44.800612],
                    [-121.759748, 44.761935],
                    [-121.80001500000002, 44.683425],
                    [-122.033006, 44.685691],
                    [-122.138702, 44.725179],
                    [-122.230559, 44.697867],
                    [-122.27667500000001, 44.74613500000001],
                    [-122.56372700000001, 44.767975],
                    [-122.620463, 44.789213],
                    [-122.690864, 44.775417],
                    [-122.782608, 44.794346],
                    [-122.956458, 44.721122],
                    [-123.00684700000001, 44.686898],
                    [-123.04200899999998, 44.737719],
                    [-123.143843, 44.748914],
                    [-123.14902499999998, 44.720225],
                    [-123.301882, 44.71983500000001],
                    [-123.602609, 44.721154],
                    [-123.704467, 44.721154],
                    [-123.72500100000002, 44.739046],
                    [-123.724663, 45.044432],
                ],
            ],
        },
        properties: {
            CSAFP: '440',
            CBSAFP: '41420',
            AFFGEOID: '310M600US41420',
            GEOID: '41420',
            NAME: 'Salem, OR',
            NAMELSAD: 'Salem, OR Metro Area',
            LSAD: 'M1',
            ALAND: 4978011245,
            AWATER: 34703953,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.288896, 36.242296, -81.605865, 36.929039],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.280595, 36.394689],
                    [-83.120966, 36.513883],
                    [-83.010789, 36.563691],
                    [-82.950519, 36.543005],
                    [-82.830433, 36.593761],
                    [-82.9844584955724, 36.59528996905],
                    [-82.94758700000001, 36.67587],
                    [-82.823358, 36.733603],
                    [-82.771595, 36.800031],
                    [-82.61774, 36.880773],
                    [-82.411098, 36.88504400000001],
                    [-82.406633, 36.876356],
                    [-82.356356, 36.779852],
                    [-82.332078, 36.708969],
                    [-82.103853, 36.798167],
                    [-82.078703, 36.854776],
                    [-81.94595, 36.864607],
                    [-81.837096, 36.929039],
                    [-81.699561, 36.789316],
                    [-81.625477, 36.646311],
                    [-81.605865, 36.638079],
                    [-81.6469, 36.611918],
                    [-81.8267341480526, 36.6147191041614],
                    [-81.871443, 36.602015],
                    [-81.983843, 36.508314],
                    [-82.188653, 36.400408],
                    [-82.299859, 36.396505],
                    [-82.421572, 36.447254],
                    [-82.60147400000001, 36.4232],
                    [-82.680677, 36.4318],
                    [-82.702566, 36.410412],
                    [-82.937183, 36.334797],
                    [-83.082312, 36.242296],
                    [-83.16259, 36.345292],
                    [-83.255292, 36.289092],
                    [-83.288896, 36.378789],
                    [-83.280595, 36.394689],
                ],
            ],
        },
        properties: {
            CSAFP: '304',
            CBSAFP: '28700',
            AFFGEOID: '310M600US28700',
            GEOID: '28700',
            NAME: 'Kingsport-Bristol, TN-VA',
            NAMELSAD: 'Kingsport-Bristol, TN-VA Metro Area',
            LSAD: 'M1',
            ALAND: 5206983779,
            AWATER: 95978507,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-123.068789, 40.286971, -121.319976, 41.183886],
            type: 'Polygon',
            coordinates: [
                [
                    [-123.068789, 40.332233],
                    [-122.99868200000002, 40.418142],
                    [-122.903738, 40.445071],
                    [-122.84595399999999, 40.505181],
                    [-122.69879, 40.569293],
                    [-122.75202300000001, 40.689882],
                    [-122.669839, 40.77356700000001],
                    [-122.666058, 40.825875],
                    [-122.60045800000002, 40.899749],
                    [-122.607363, 40.957748],
                    [-122.527753, 41.014395],
                    [-122.51335400000002, 41.08822700000001],
                    [-122.457144, 41.096611],
                    [-122.445453, 41.15793],
                    [-122.49837600000001, 41.18267500000001],
                    [-121.44649500000001, 41.183484],
                    [-121.331786, 41.183886],
                    [-121.319976, 40.905886],
                    [-121.32782600000002, 40.445367],
                    [-121.36145600000002, 40.446822],
                    [-121.49763500000002, 40.445591],
                    [-121.73429, 40.435681],
                    [-121.80653900000001, 40.44495],
                    [-121.94098900000002, 40.415331],
                    [-122.01031599999999, 40.426558],
                    [-122.310412, 40.371064],
                    [-122.52567699999999, 40.387613],
                    [-122.65134100000002, 40.328288],
                    [-122.74035800000001, 40.365],
                    [-122.872274, 40.348969],
                    [-122.91805500000001, 40.306972],
                    [-122.976548, 40.315162],
                    [-123.065426, 40.286971],
                    [-123.068789, 40.332233],
                ],
            ],
        },
        properties: {
            CSAFP: '454',
            CBSAFP: '39820',
            AFFGEOID: '310M600US39820',
            GEOID: '39820',
            NAME: 'Redding, CA',
            NAMELSAD: 'Redding, CA Metro Area',
            LSAD: 'M1',
            ALAND: 9778670503,
            AWATER: 186039037,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.803325, 27.260981, -97.79851700000002, 28.057966],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.803325, 28.05748],
                    [-98.334323, 28.0578],
                    [-98.235497, 28.057966],
                    [-97.883148, 28.0569],
                    [-97.838918, 28.034613],
                    [-97.801388, 28.03836],
                    [-97.79851700000002, 27.995659],
                    [-97.934274, 27.885202],
                    [-97.94214600000001, 27.635932],
                    [-98.059464, 27.635862],
                    [-98.05807800000001, 27.260981],
                    [-98.232464, 27.262487000000004],
                    [-98.523284, 27.26515],
                    [-98.52229300000002, 27.343284],
                    [-98.589537, 27.358695],
                    [-98.798323, 27.354029],
                    [-98.803325, 28.05748],
                ],
            ],
        },
        properties: {
            CSAFP: '204',
            CBSAFP: '10860',
            AFFGEOID: '310M600US10860',
            GEOID: '10860',
            NAME: 'Alice, TX',
            NAMELSAD: 'Alice, TX Micro Area',
            LSAD: 'M2',
            ALAND: 6885880043,
            AWATER: 14201639,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-103.064696, 32.085795, -102.211037, 32.523244],
            type: 'Polygon',
            coordinates: [
                [
                    [-103.064696, 32.522193],
                    [-102.211037, 32.523244],
                    [-102.211249, 32.0868],
                    [-102.287048, 32.086991],
                    [-102.799086, 32.085795],
                    [-102.844395, 32.087062],
                    [-103.064422401106, 32.087051],
                    [-103.064422, 32.145006],
                    [-103.064696, 32.522193],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '11380',
            AFFGEOID: '310M600US11380',
            GEOID: '11380',
            NAME: 'Andrews, TX',
            NAMELSAD: 'Andrews, TX Micro Area',
            LSAD: 'M2',
            ALAND: 3886850259,
            AWATER: 957039,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.2976, 29.630718000000005, -97.024461, 30.904413999999996],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.172977, 30.356312],
                    [-98.125556, 30.426186000000005],
                    [-98.12342, 30.486482000000002],
                    [-98.049886, 30.624155000000002],
                    [-97.96286, 30.785640999999995],
                    [-97.8281, 30.904413999999996],
                    [-97.624051, 30.870004000000005],
                    [-97.315485, 30.752363000000003],
                    [-97.269949, 30.735361999999995],
                    [-97.155219, 30.457344000000003],
                    [-97.334463, 30.402843],
                    [-97.251707, 30.362848000000003],
                    [-97.199578, 30.337833000000003],
                    [-97.158811, 30.310695],
                    [-97.081834, 30.259357],
                    [-97.024461, 30.051435],
                    [-97.194235, 29.896776],
                    [-97.315823, 29.786540999999996],
                    [-97.317893, 29.784658],
                    [-97.597743, 29.630718000000005],
                    [-97.633176, 29.649863],
                    [-97.768322, 29.718883],
                    [-97.780917, 29.759258],
                    [-97.799396, 29.75226],
                    [-97.875259, 29.858208],
                    [-97.943564, 29.806914000000003],
                    [-97.999271, 29.752444],
                    [-98.01518, 29.801485],
                    [-98.030523, 29.848539],
                    [-98.12127, 29.912844000000003],
                    [-98.2976, 30.037994],
                    [-98.172977, 30.356312],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '12420',
            AFFGEOID: '310M600US12420',
            GEOID: '12420',
            NAME: 'Austin-Round Rock-Georgetown, TX',
            NAMELSAD: 'Austin-Round Rock-Georgetown, TX Metro Area',
            LSAD: 'M1',
            ALAND: 10928514508,
            AWATER: 150754244,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.647031, 33.693583, -82.779506, 34.27353],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.537385, 33.965912],
                    [-83.503054, 33.999577],
                    [-83.454025, 34.004809],
                    [-83.384863, 34.037805],
                    [-83.360028, 34.040572],
                    [-83.386763, 34.050241],
                    [-83.357051, 34.116199],
                    [-83.402428, 34.197499],
                    [-83.355269, 34.22374],
                    [-83.313591, 34.259144],
                    [-83.172745, 34.238522],
                    [-83.11336, 34.27353],
                    [-83.078004, 34.223606],
                    [-83.103287, 34.170708],
                    [-82.976294, 34.043219],
                    [-82.942268, 34.014957],
                    [-82.779506, 33.971124],
                    [-82.874587, 33.921767],
                    [-82.987286, 33.78071],
                    [-82.949046, 33.733333],
                    [-82.995602, 33.693583],
                    [-83.118729, 33.698818],
                    [-83.28034, 33.761774],
                    [-83.29145, 33.734315],
                    [-83.406189, 33.698307],
                    [-83.483177, 33.805576],
                    [-83.505928, 33.81776],
                    [-83.533165, 33.820923],
                    [-83.647031, 33.90619800000001],
                    [-83.537385, 33.965912],
                ],
            ],
        },
        properties: {
            CSAFP: '122',
            CBSAFP: '12020',
            AFFGEOID: '310M600US12020',
            GEOID: '12020',
            NAME: 'Athens-Clarke County, GA',
            NAMELSAD: 'Athens-Clarke County, GA Metro Area',
            LSAD: 'M1',
            ALAND: 2654607902,
            AWATER: 26109459,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.949148, 40.603544, -76.380334, 41.17204],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.939883, 40.638142],
                    [-76.864014, 40.706693],
                    [-76.849786, 40.809089],
                    [-76.840626, 40.821324],
                    [-76.813022, 40.847058],
                    [-76.798714, 40.878366],
                    [-76.800242, 40.881994],
                    [-76.873964, 40.946752],
                    [-76.856424, 41.047412],
                    [-76.896114, 41.13907],
                    [-76.883833, 41.157414],
                    [-76.732672, 41.17204],
                    [-76.73489100000002, 41.092133],
                    [-76.793741, 40.948989],
                    [-76.661348, 40.967996],
                    [-76.556977, 40.938609],
                    [-76.550935, 40.882784],
                    [-76.528024, 40.882613000000006],
                    [-76.501598, 40.824765],
                    [-76.411487, 40.83349],
                    [-76.380334, 40.775445],
                    [-76.562175, 40.709007],
                    [-76.691533, 40.66408400000001],
                    [-76.701624, 40.658082],
                    [-76.919272, 40.603544],
                    [-76.949148, 40.628167],
                    [-76.939883, 40.638142],
                ],
            ],
        },
        properties: {
            CSAFP: '146',
            CBSAFP: '44980',
            AFFGEOID: '310M600US44980',
            GEOID: '44980',
            NAME: 'Sunbury, PA',
            NAMELSAD: 'Sunbury, PA Micro Area',
            LSAD: 'M2',
            ALAND: 1185344304,
            AWATER: 50674892,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.864791, 40.378367, -85.44433, 40.653607],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.864791, 40.65169300000001],
                    [-85.638587, 40.653129],
                    [-85.448825, 40.653607],
                    [-85.447014, 40.566929],
                    [-85.44433, 40.37914],
                    [-85.578589, 40.379524],
                    [-85.862296, 40.378367],
                    [-85.862117, 40.406889],
                    [-85.863641, 40.565525],
                    [-85.864791, 40.65169300000001],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '31980',
            AFFGEOID: '310M600US31980',
            GEOID: '31980',
            NAME: 'Marion, IN',
            NAMELSAD: 'Marion, IN Micro Area',
            LSAD: 'M2',
            ALAND: 1072484471,
            AWATER: 2101804,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.620383, 40.242107, -78.116521, 40.742486],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.561466, 40.36967],
                    [-78.539819, 40.506293],
                    [-78.441208, 40.597239],
                    [-78.439584, 40.645034],
                    [-78.348668, 40.723443],
                    [-78.359912, 40.732592],
                    [-78.145661, 40.742486],
                    [-78.116521, 40.739127],
                    [-78.22197, 40.674539],
                    [-78.166371, 40.617948],
                    [-78.19708300000002, 40.558444],
                    [-78.130081, 40.48199],
                    [-78.25730500000002, 40.297883],
                    [-78.288764, 40.242107],
                    [-78.434288, 40.302301],
                    [-78.447895, 40.244395],
                    [-78.620383, 40.327025000000006],
                    [-78.561466, 40.36967],
                ],
            ],
        },
        properties: {
            CSAFP: '107',
            CBSAFP: '11020',
            AFFGEOID: '310M600US11020',
            GEOID: '11020',
            NAME: 'Altoona, PA',
            NAMELSAD: 'Altoona, PA Metro Area',
            LSAD: 'M1',
            ALAND: 1360458062,
            AWATER: 3294803,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.85076, 37.105452, -87.295965, 37.569988],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.813413, 37.350645],
                    [-87.593575, 37.461963],
                    [-87.497112, 37.487802],
                    [-87.37514500000002, 37.569988],
                    [-87.328543, 37.398462],
                    [-87.295965, 37.391819],
                    [-87.38703, 37.273606],
                    [-87.333741, 37.157186],
                    [-87.520116, 37.105452],
                    [-87.680617, 37.149239],
                    [-87.805029, 37.241083],
                    [-87.85076, 37.322136],
                    [-87.813413, 37.350645],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '31580',
            AFFGEOID: '310M600US31580',
            GEOID: '31580',
            NAME: 'Madisonville, KY',
            NAMELSAD: 'Madisonville, KY Micro Area',
            LSAD: 'M2',
            ALAND: 1403991594,
            AWATER: 31937243,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.525231, 44.19613400000001, -93.039485, 44.544006],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.52432700000001, 44.543618],
                    [-93.357704, 44.544006],
                    [-93.281521, 44.543957],
                    [-93.281686, 44.471998],
                    [-93.039485, 44.471871],
                    [-93.041159, 44.196726],
                    [-93.04591, 44.196716],
                    [-93.162285, 44.196568000000006],
                    [-93.406565, 44.196377],
                    [-93.525231, 44.19613400000001],
                    [-93.52432700000001, 44.543618],
                ],
            ],
        },
        properties: {
            CSAFP: '378',
            CBSAFP: '22060',
            AFFGEOID: '310M600US22060',
            GEOID: '22060',
            NAME: 'Faribault-Northfield, MN',
            NAMELSAD: 'Faribault-Northfield, MN Micro Area',
            LSAD: 'M2',
            ALAND: 1284218948,
            AWATER: 52062452,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-123.065426, 39.797648, -121.342264, 40.445591],
            type: 'Polygon',
            coordinates: [
                [
                    [-123.065426, 40.286971],
                    [-122.976548, 40.315162],
                    [-122.91805500000001, 40.306972],
                    [-122.872274, 40.348969],
                    [-122.74035800000001, 40.365],
                    [-122.65134100000002, 40.328288],
                    [-122.52567699999999, 40.387613],
                    [-122.310412, 40.371064],
                    [-122.01031599999999, 40.426558],
                    [-121.94098900000002, 40.415331],
                    [-121.80653900000001, 40.44495],
                    [-121.73429, 40.435681],
                    [-121.49763500000002, 40.445591],
                    [-121.47039500000001, 40.350213],
                    [-121.342264, 40.309751],
                    [-121.36839499999999, 40.21223],
                    [-121.436886, 40.151905],
                    [-121.58640699999998, 40.100522],
                    [-121.646032, 39.982527],
                    [-121.703241, 39.98423],
                    [-121.80404100000001, 39.884344],
                    [-122.04486499999999, 39.88377100000001],
                    [-122.04647100000001, 39.797648],
                    [-122.937654, 39.798156],
                    [-122.95007699999998, 39.907718],
                    [-122.90978699999998, 39.936385],
                    [-122.93401300000001, 39.978131],
                    [-122.989623, 40.145496],
                    [-122.97755, 40.241285000000005],
                    [-123.065426, 40.286971],
                ],
            ],
        },
        properties: {
            CSAFP: '454',
            CBSAFP: '39780',
            AFFGEOID: '310M600US39780',
            GEOID: '39780',
            NAME: 'Red Bluff, CA',
            NAMELSAD: 'Red Bluff, CA Micro Area',
            LSAD: 'M2',
            ALAND: 7638229340,
            AWATER: 33767224,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-160.24961, 21.779518, -159.293013, 22.232603],
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [-159.783746, 22.064897],
                        [-159.745247, 22.097508],
                        [-159.730544, 22.139953],
                        [-159.705531, 22.159321],
                        [-159.61165, 22.201388],
                        [-159.581058, 22.223488],
                        [-159.54392400000003, 22.221695],
                        [-159.510756, 22.203548],
                        [-159.487939, 22.229512],
                        [-159.431707, 22.220015],
                        [-159.402466, 22.232603],
                        [-159.361507, 22.214092],
                        [-159.312293, 22.183082],
                        [-159.293013, 22.12296],
                        [-159.31828, 22.061417000000002],
                        [-159.334489, 22.041698],
                        [-159.332564, 21.999352],
                        [-159.33768, 21.951173],
                        [-159.385271, 21.912439],
                        [-159.444868, 21.868627],
                        [-159.526918, 21.883886],
                        [-159.574521, 21.892806],
                        [-159.603279, 21.892248],
                        [-159.649766, 21.933848],
                        [-159.707795, 21.961229000000003],
                        [-159.754795, 21.977772],
                        [-159.786702, 22.018801],
                        [-159.783746, 22.064897],
                    ],
                ],
                [
                    [
                        [-160.24961, 21.815145],
                        [-160.228965, 21.889117],
                        [-160.193959, 21.922386],
                        [-160.13705000000002, 21.948632],
                        [-160.122262, 21.962881],
                        [-160.112746, 21.995245],
                        [-160.072123, 22.003334],
                        [-160.058543, 21.99638],
                        [-160.051128, 21.98106],
                        [-160.070292, 21.963951],
                        [-160.085787, 21.927295],
                        [-160.079065, 21.89608],
                        [-160.124283, 21.876789],
                        [-160.156092, 21.86793],
                        [-160.174796, 21.846923],
                        [-160.189782, 21.82245],
                        [-160.205851, 21.779518],
                        [-160.230373, 21.789675],
                        [-160.24961, 21.815145],
                    ],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '28180',
            AFFGEOID: '310M600US28180',
            GEOID: '28180',
            NAME: 'Kapaa, HI',
            NAMELSAD: 'Kapaa, HI Micro Area',
            LSAD: 'M2',
            ALAND: 1605492716,
            AWATER: 1674243852,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.376518, 40.691557, -77.14416, 41.252752],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.376518, 40.754417],
                    [-78.288441, 40.849747],
                    [-78.24922, 40.855383],
                    [-78.168128, 40.960865],
                    [-78.07774, 40.958824],
                    [-78.056276, 41.00417],
                    [-78.112239, 41.114217],
                    [-78.038203, 41.153633],
                    [-77.898195, 41.252752],
                    [-77.892724, 41.178259000000004],
                    [-77.79856, 41.18195],
                    [-77.741911, 41.107614],
                    [-77.621759, 41.091185],
                    [-77.514275, 40.96956200000001],
                    [-77.50702, 40.963203],
                    [-77.339925, 40.987167],
                    [-77.14416, 41.044338],
                    [-77.279236, 40.90971],
                    [-77.36418, 40.846937],
                    [-77.652574, 40.744921],
                    [-77.681334, 40.729758],
                    [-77.824506, 40.743681],
                    [-77.94425, 40.691557],
                    [-78.085263, 40.73092],
                    [-78.116521, 40.739127],
                    [-78.145661, 40.742486],
                    [-78.359912, 40.732592],
                    [-78.376518, 40.754417],
                ],
            ],
        },
        properties: {
            CSAFP: '524',
            CBSAFP: '44300',
            AFFGEOID: '310M600US44300',
            GEOID: '44300',
            NAME: 'State College, PA',
            NAMELSAD: 'State College, PA Metro Area',
            LSAD: 'M1',
            ALAND: 2871539708,
            AWATER: 11177389,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.8041205270149, 40.3527622302971, -84.43438700000002, 40.728306],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.804119, 40.352844],
                    [-84.8029313665713, 40.4653866217198],
                    [-84.802547, 40.50181],
                    [-84.8024138785794, 40.5722128806467],
                    [-84.8021190322638, 40.7281459368726],
                    [-84.456172, 40.728306],
                    [-84.45618, 40.684862],
                    [-84.455713, 40.567888],
                    [-84.455029, 40.451305],
                    [-84.455532, 40.363963],
                    [-84.43438700000002, 40.35453400000001],
                    [-84.434631, 40.354259],
                    [-84.8041205270149, 40.3527622302971],
                    [-84.804119, 40.352844],
                ],
            ],
        },
        properties: {
            CSAFP: '338',
            CBSAFP: '16380',
            AFFGEOID: '310M600US16380',
            GEOID: '16380',
            NAME: 'Celina, OH',
            NAMELSAD: 'Celina, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1197732212,
            AWATER: 28431995,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.95393800000001, 33.396195, -97.947572, 34.2046698986035],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.953202, 33.834025000000004],
                    [-98.9523248875282, 34.2046698986035],
                    [-98.94022, 34.203686],
                    [-98.872229, 34.160446],
                    [-98.80681000000001, 34.155901],
                    [-98.737232, 34.130992],
                    [-98.690072, 34.133155],
                    [-98.648073, 34.164441],
                    [-98.6102002179226, 34.1561768671247],
                    [-98.577136, 34.148962],
                    [-98.5282, 34.094961],
                    [-98.475066, 34.064269],
                    [-98.423533, 34.0819494760059],
                    [-98.414426, 34.085074],
                    [-98.398441, 34.128456],
                    [-98.364023, 34.157109],
                    [-98.31875, 34.146421],
                    [-98.225282, 34.127245],
                    [-98.16912, 34.114171],
                    [-98.1384860257464, 34.1412060197111],
                    [-98.123377, 34.15454],
                    [-98.099328, 34.104295],
                    [-98.099096, 34.048639],
                    [-98.082839, 34.002412],
                    [-98.005667, 33.995964],
                    [-97.947572, 33.991053],
                    [-97.953695, 33.924373],
                    [-97.9519059508552, 33.8912258148573],
                    [-97.977869, 33.888738],
                    [-97.97891900000002, 33.467129],
                    [-98.421419, 33.466797],
                    [-98.420666, 33.396195],
                    [-98.95393800000001, 33.397534],
                    [-98.953202, 33.834025000000004],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '48660',
            AFFGEOID: '310M600US48660',
            GEOID: '48660',
            NAME: 'Wichita Falls, TX',
            NAMELSAD: 'Wichita Falls, TX Metro Area',
            LSAD: 'M1',
            ALAND: 6784839979,
            AWATER: 143918743,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.3785331592868, 28.3898650304879, -95.507037396917, 29.229702],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.3785331592868, 28.3898650304879],
                    [-96.322731, 28.642944],
                    [-96.323522, 28.675597],
                    [-96.309178, 28.963291],
                    [-95.965196, 29.146865000000002],
                    [-95.874028, 29.229702],
                    [-95.84631, 29.107799],
                    [-95.770446, 29.066209999999998],
                    [-95.76939300000001, 28.971109000000002],
                    [-95.67955, 28.965734],
                    [-95.567708, 28.829758000000005],
                    [-95.507037396917, 28.824735512192103],
                    [-95.588801, 28.783169],
                    [-95.684089, 28.734040999999998],
                    [-95.812504, 28.664941999999996],
                    [-96.000682, 28.588238],
                    [-96.194412, 28.502224],
                    [-96.328817, 28.423659],
                    [-96.3785331592868, 28.3898650304879],
                ],
            ],
        },
        properties: {
            CSAFP: '288',
            CBSAFP: '13060',
            AFFGEOID: '310M600US13060',
            GEOID: '13060',
            NAME: 'Bay City, TX',
            NAMELSAD: 'Bay City, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2830645444,
            AWATER: 1345742564,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-119.573194, 35.788935, -117.980761, 36.744773],
            type: 'Polygon',
            coordinates: [
                [
                    [-119.54613100000002, 36.508963],
                    [-119.466322, 36.575238],
                    [-119.305102, 36.573725],
                    [-119.304625, 36.660606],
                    [-119.174791, 36.658878],
                    [-119.088295, 36.657514],
                    [-118.98477899999999, 36.657147],
                    [-118.98244099999998, 36.741646],
                    [-118.360586, 36.744773],
                    [-118.366328, 36.691635],
                    [-118.27462400000002, 36.597334],
                    [-118.29052300000001, 36.558535],
                    [-118.239021, 36.523635000000006],
                    [-118.21482, 36.435036],
                    [-118.10031700000002, 36.346137],
                    [-118.127147, 36.279656],
                    [-118.033611, 36.008944],
                    [-118.003577, 35.983718],
                    [-117.980761, 35.867515],
                    [-118.00548899999998, 35.862699],
                    [-118.00809400000001, 35.788935],
                    [-118.058925, 35.791474],
                    [-118.80244000000002, 35.790166],
                    [-119.053437, 35.790774],
                    [-119.53812000000002, 35.789898],
                    [-119.536161, 36.050782],
                    [-119.529358, 36.26985],
                    [-119.47460699999999, 36.269025],
                    [-119.474892, 36.400953],
                    [-119.528792, 36.401465],
                    [-119.52828599999998, 36.487545],
                    [-119.573194, 36.488835],
                    [-119.54613100000002, 36.508963],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '47300',
            AFFGEOID: '310M600US47300',
            GEOID: '47300',
            NAME: 'Visalia, CA',
            NAMELSAD: 'Visalia, CA Metro Area',
            LSAD: 'M1',
            ALAND: 12493840854,
            AWATER: 37260863,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-99.1768160923811, 26.23596, -98.32067, 26.785694],
            type: 'Polygon',
            coordinates: [
                [
                    [-99.1768160923811, 26.5696605553736],
                    [-99.011112, 26.675023],
                    [-98.95423, 26.785694],
                    [-98.422616, 26.783535],
                    [-98.32067, 26.783081],
                    [-98.491943, 26.445362],
                    [-98.5933002182792, 26.2429363486766],
                    [-98.613465, 26.252028],
                    [-98.654221, 26.23596],
                    [-98.698856, 26.265619000000004],
                    [-98.779912, 26.326542],
                    [-98.807348, 26.369421],
                    [-98.890965, 26.357569],
                    [-98.958325, 26.394056],
                    [-99.032316, 26.412082],
                    [-99.08200200000002, 26.39651],
                    [-99.110855, 26.426278],
                    [-99.091635, 26.476977],
                    [-99.105031, 26.500335],
                    [-99.171404, 26.549848],
                    [-99.1768160923811, 26.5696605553736],
                ],
            ],
        },
        properties: {
            CSAFP: '365',
            CBSAFP: '40100',
            AFFGEOID: '310M600US40100',
            GEOID: '40100',
            NAME: 'Rio Grande City-Roma, TX',
            NAMELSAD: 'Rio Grande City-Roma, TX Micro Area',
            LSAD: 'M2',
            ALAND: 3168019235,
            AWATER: 15346374,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.10877700000002, 44.108016000000006, -94.36885000000001, 44.498218],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.10748, 44.281768],
                    [-94.866259, 44.282372],
                    [-94.865824, 44.498218],
                    [-94.81488, 44.48445],
                    [-94.78063, 44.45665],
                    [-94.666062, 44.394048],
                    [-94.491333, 44.359676],
                    [-94.371731, 44.264448],
                    [-94.36885000000001, 44.108698],
                    [-94.859803, 44.108016000000006],
                    [-95.100221, 44.108034],
                    [-95.100892, 44.180296],
                    [-95.10877700000002, 44.195044],
                    [-95.10748, 44.281768],
                ],
            ],
        },
        properties: {
            CSAFP: '359',
            CBSAFP: '35580',
            AFFGEOID: '310M600US35580',
            GEOID: '35580',
            NAME: 'New Ulm, MN',
            NAMELSAD: 'New Ulm, MN Micro Area',
            LSAD: 'M2',
            ALAND: 1582765944,
            AWATER: 18533953,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.930367, 28.0598208616046, -96.322731, 28.730230999999996],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.930367, 28.586728],
                    [-96.853757, 28.635199],
                    [-96.643442, 28.712105000000005],
                    [-96.42839400000001, 28.703523],
                    [-96.39954000000002, 28.730230999999996],
                    [-96.404445, 28.674409],
                    [-96.323522, 28.675597],
                    [-96.322731, 28.642944],
                    [-96.3785331592868, 28.3898650304879],
                    [-96.390376, 28.381815],
                    [-96.442849, 28.317666],
                    [-96.63201, 28.222821],
                    [-96.719627, 28.164594],
                    [-96.792158, 28.110497],
                    [-96.8520706227301, 28.0598208616046],
                    [-96.830928, 28.107894000000005],
                    [-96.790512, 28.319238],
                    [-96.764132, 28.426522],
                    [-96.813494, 28.430261],
                    [-96.890951, 28.507178],
                    [-96.930367, 28.586728],
                ],
            ],
        },
        properties: {
            CSAFP: '544',
            CBSAFP: '38920',
            AFFGEOID: '310M600US38920',
            GEOID: '38920',
            NAME: 'Port Lavaca, TX',
            NAMELSAD: 'Port Lavaca, TX Micro Area',
            LSAD: 'M2',
            ALAND: 1312960774,
            AWATER: 1361631001,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-120.006555, 44.994596, -117.97294799999999, 46.000935],
            type: 'Polygon',
            coordinates: [
                [
                    [-119.99950515346201, 45.8116849230819],
                    [-119.96574400000002, 45.824365],
                    [-119.868153889391, 45.8382266026357],
                    [-119.80265499999999, 45.84753],
                    [-119.66987700000001, 45.856867],
                    [-119.600549, 45.919581],
                    [-119.571584, 45.925456],
                    [-119.48782900000002, 45.906307],
                    [-119.432138558816, 45.9132091442339],
                    [-119.36439600000001, 45.921605],
                    [-119.25715, 45.939926],
                    [-119.12612, 45.932859],
                    [-119.061462, 45.958527],
                    [-118.987227539565, 45.9998002135237],
                    [-118.987129, 45.999855000000004],
                    [-118.67787, 46.000935],
                    [-118.60678915418899, 46.0008632497912],
                    [-118.36779, 46.000622],
                    [-117.99697042960301, 46.0001906671726],
                    [-117.977657306532, 46.000168202382],
                    [-117.97760100000002, 45.876889],
                    [-117.97298000000002, 45.860595],
                    [-117.97294799999999, 45.81689600000001],
                    [-118.045003, 45.817073],
                    [-118.066383, 45.68839500000001],
                    [-118.11719400000001, 45.688607],
                    [-118.116461, 45.470732],
                    [-118.19795500000001, 45.4704],
                    [-118.197876, 45.427204],
                    [-118.42853900000001, 45.429076],
                    [-118.42832899999999, 45.35519],
                    [-118.69776599999999, 45.345647],
                    [-118.69698500000001, 45.257613],
                    [-118.656102, 45.257729],
                    [-118.655992, 45.196928],
                    [-118.54663199999999, 45.196898],
                    [-118.54765200000001, 45.080753],
                    [-118.518689, 44.995877],
                    [-119.16296599999998, 44.996241],
                    [-119.64374499999998, 44.994596],
                    [-119.67222800000002, 44.995117],
                    [-119.791162, 44.994755],
                    [-119.79069899999999, 45.067823],
                    [-119.76073100000002, 45.081278],
                    [-119.75975899999999, 45.169211],
                    [-119.883914, 45.169152],
                    [-119.88234600000001, 45.256916],
                    [-120.006555, 45.257706],
                    [-119.99950515346201, 45.8116849230819],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '25840',
            AFFGEOID: '310M600US25840',
            GEOID: '25840',
            NAME: 'Hermiston-Pendleton, OR',
            NAMELSAD: 'Hermiston-Pendleton, OR Micro Area',
            LSAD: 'M2',
            ALAND: 13587023089,
            AWATER: 85991362,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-113.165811, 45.748503, -111.498095, 47.953918],
            type: 'Polygon',
            coordinates: [
                [
                    [-113.165811, 47.719764],
                    [-113.080997, 47.749917],
                    [-113.08663400000002, 47.918182],
                    [-112.984734, 47.953918],
                    [-112.91325600000002, 47.826216],
                    [-112.91422, 47.694958],
                    [-112.856907, 47.62949100000001],
                    [-112.780545, 47.598622],
                    [-112.54972100000002, 47.65197700000001],
                    [-112.429183, 47.603386],
                    [-112.40514600000002, 47.552317],
                    [-112.317471, 47.54207],
                    [-112.278, 47.505623],
                    [-112.047781, 47.516034],
                    [-112.04431100000001, 47.192826],
                    [-111.977739, 47.184103],
                    [-111.942139, 47.143582],
                    [-111.789278, 47.12892200000001],
                    [-111.790236, 46.913764],
                    [-111.658132, 46.913423],
                    [-111.639502, 46.832134],
                    [-111.498095, 46.762502],
                    [-111.602294, 46.778261],
                    [-111.639261, 46.73818],
                    [-111.631906, 46.569972],
                    [-111.787949, 46.569465],
                    [-111.784565, 46.049813],
                    [-111.660422, 46.049192],
                    [-111.660057, 45.8335],
                    [-111.704659, 45.795979],
                    [-111.805259, 45.798306],
                    [-111.926965, 45.855694],
                    [-112.085801, 45.851101],
                    [-112.190269, 45.748503],
                    [-112.414212, 45.836684],
                    [-112.404349, 45.888049],
                    [-112.51999700000002, 46.136394],
                    [-112.50519100000001, 46.184221],
                    [-112.577106, 46.178981],
                    [-112.597936, 46.236894],
                    [-112.558599, 46.265821],
                    [-112.570046, 46.304972],
                    [-112.449521, 46.334098],
                    [-112.31057300000002, 46.421171],
                    [-112.316754, 46.57441800000001],
                    [-112.29853100000001, 46.62597300000001],
                    [-112.320871, 46.655927],
                    [-112.410638, 46.656011],
                    [-112.410846, 46.699505],
                    [-112.535597, 46.699482],
                    [-112.535748, 46.834234],
                    [-112.795642, 46.831665],
                    [-112.795808, 47.177004],
                    [-113.059053, 47.17957],
                    [-113.059199, 47.488599],
                    [-113.123098, 47.51573],
                    [-113.144368, 47.595507],
                    [-113.165811, 47.719764],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '25740',
            AFFGEOID: '310M600US25740',
            GEOID: '25740',
            NAME: 'Helena, MT',
            NAMELSAD: 'Helena, MT Micro Area',
            LSAD: 'M2',
            ALAND: 13248767753,
            AWATER: 107752388,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-158.277679, 21.2577, -157.6518, 21.712704],
            type: 'Polygon',
            coordinates: [
                [
                    [-158.232192, 21.583806],
                    [-158.12561, 21.586739],
                    [-158.079895, 21.628101],
                    [-158.050692, 21.671215],
                    [-157.9923, 21.708000000000002],
                    [-157.968628, 21.712704],
                    [-157.924591, 21.651183],
                    [-157.87735, 21.575277],
                    [-157.836945, 21.529945],
                    [-157.84549, 21.466747],
                    [-157.8139, 21.4403],
                    [-157.764572, 21.461335],
                    [-157.722506, 21.459225],
                    [-157.724324, 21.403311],
                    [-157.7106, 21.3585],
                    [-157.6518, 21.3139],
                    [-157.655030363263, 21.3093885180138],
                    [-157.673069, 21.284196],
                    [-157.7001, 21.264],
                    [-157.7572, 21.278],
                    [-157.77994400000003, 21.265252],
                    [-157.8096, 21.2577],
                    [-157.851048, 21.28453],
                    [-157.89, 21.3065],
                    [-157.950736, 21.312509],
                    [-157.98152500000003, 21.315898],
                    [-158.0245, 21.3093],
                    [-158.0883, 21.2988],
                    [-158.1033, 21.2979],
                    [-158.129371, 21.344818],
                    [-158.13093136646, 21.3489558479967],
                    [-158.1403, 21.3738],
                    [-158.1792, 21.4043],
                    [-158.182648, 21.430073000000004],
                    [-158.233, 21.4876],
                    [-158.23117100000002, 21.523857],
                    [-158.277679, 21.578789],
                    [-158.232192, 21.583806],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '46520',
            AFFGEOID: '310M600US46520',
            GEOID: '46520',
            NAME: 'Urban Honolulu, HI',
            NAMELSAD: 'Urban Honolulu, HI Metro Area',
            LSAD: 'M1',
            ALAND: 1555612626,
            AWATER: 4054877012,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.075365, 34.8049623069232, -79.458751, 35.183855],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.075365, 35.143081],
                    [-79.851268, 35.183855],
                    [-79.614304, 35.163679],
                    [-79.575931, 35.069904],
                    [-79.458751, 35.043639],
                    [-79.573423, 34.99221000000001],
                    [-79.561856, 34.91035],
                    [-79.61404500000002, 34.815309],
                    [-79.6929463837093, 34.8049623069232],
                    [-79.9246842531364, 34.8078290197515],
                    [-79.849536, 34.900006],
                    [-79.877197, 35.03531],
                    [-79.923972, 35.11543],
                    [-79.992941, 35.08559],
                    [-80.075365, 35.143081],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '40460',
            AFFGEOID: '310M600US40460',
            GEOID: '40460',
            NAME: 'Rockingham, NC',
            NAMELSAD: 'Rockingham, NC Micro Area',
            LSAD: 'M2',
            ALAND: 1226849523,
            AWATER: 15575098,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.718493, 42.070366, -84.131136, 42.424611],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.718493, 42.42152],
                    [-84.600403, 42.421984],
                    [-84.140713, 42.424611],
                    [-84.131136, 42.424567],
                    [-84.131963, 42.071577],
                    [-84.363297, 42.073456],
                    [-84.709556, 42.070366],
                    [-84.718493, 42.42152],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '27100',
            AFFGEOID: '310M600US27100',
            GEOID: '27100',
            NAME: 'Jackson, MI',
            NAMELSAD: 'Jackson, MI Metro Area',
            LSAD: 'M1',
            ALAND: 1817904856,
            AWATER: 55933446,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.421936, 32.661732, -86.043993, 34.259317],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.421936, 33.003379],
                    [-87.318539, 33.006179],
                    [-87.281945, 33.13306],
                    [-87.199317, 33.130657],
                    [-87.199153, 33.196554],
                    [-87.065738, 33.246907],
                    [-87.065754, 33.275937],
                    [-87.074634, 33.276018],
                    [-87.074602, 33.279682],
                    [-87.087546, 33.279666],
                    [-87.087497, 33.290675],
                    [-87.126756, 33.290844],
                    [-87.127142, 33.306013],
                    [-87.162007, 33.306587],
                    [-87.162175, 33.321012],
                    [-87.179638, 33.321112],
                    [-87.179754, 33.32801],
                    [-87.194841, 33.343069],
                    [-87.341698, 33.470501],
                    [-87.26692300000002, 33.512929],
                    [-87.271586, 33.52942],
                    [-87.185794, 33.555882],
                    [-87.17943, 33.613794],
                    [-87.05775700000001, 33.656363],
                    [-87.005965, 33.787237],
                    [-86.953664, 33.815297],
                    [-86.963358, 33.858221],
                    [-86.924387, 33.909222],
                    [-86.793914, 33.952059],
                    [-86.685365, 34.05914],
                    [-86.692474, 34.092405],
                    [-86.601147, 34.11904],
                    [-86.604311, 34.125112],
                    [-86.587614, 34.13833100000001],
                    [-86.573969, 34.165436],
                    [-86.56385, 34.170493],
                    [-86.518927, 34.252321],
                    [-86.45302, 34.259317],
                    [-86.40901700000002, 34.205002],
                    [-86.303516, 34.099073],
                    [-86.332394, 34.040499],
                    [-86.37015200000002, 33.93977],
                    [-86.32562200000001, 33.940147],
                    [-86.290127, 33.983719],
                    [-86.199164, 33.988918],
                    [-86.142717, 33.899391],
                    [-86.065272, 33.842198],
                    [-86.043993, 33.763595],
                    [-86.145562, 33.679098],
                    [-86.193733, 33.697851],
                    [-86.16948, 33.619236],
                    [-86.235328, 33.494532],
                    [-86.281999, 33.509895],
                    [-86.378665, 33.390983],
                    [-86.341113, 33.354599],
                    [-86.35734, 33.296917],
                    [-86.458026, 33.241434],
                    [-86.503127, 33.179144],
                    [-86.491029, 33.102944],
                    [-86.511142, 33.088431],
                    [-86.517344, 33.02056600000001],
                    [-86.515959, 32.929361],
                    [-86.451524, 32.863692],
                    [-86.457015, 32.813899],
                    [-86.374974, 32.75358],
                    [-86.413335, 32.750591],
                    [-86.413116, 32.707386],
                    [-86.714219, 32.705694],
                    [-86.71339, 32.661732],
                    [-86.917595, 32.664169],
                    [-87.01766, 32.663269],
                    [-87.01776200000002, 32.729532],
                    [-87.019157, 32.837034],
                    [-87.319184, 32.831522],
                    [-87.319473, 32.875124],
                    [-87.4212, 32.874508],
                    [-87.421936, 33.003379],
                ],
            ],
        },
        properties: {
            CSAFP: '142',
            CBSAFP: '13820',
            AFFGEOID: '310M600US13820',
            GEOID: '13820',
            NAME: 'Birmingham-Hoover, AL',
            NAMELSAD: 'Birmingham-Hoover, AL Metro Area',
            LSAD: 'M1',
            ALAND: 11625777855,
            AWATER: 196426879,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-113.354176, 34.263314, -110.750678, 37.003197],
            type: 'Polygon',
            coordinates: [
                [
                    [-113.319971, 36.096769],
                    [-113.224919, 36.091144],
                    [-113.196751, 36.152949],
                    [-113.13708, 36.166424],
                    [-113.009038, 36.23904],
                    [-112.902949, 36.253761],
                    [-112.865033, 36.282381],
                    [-112.792436, 36.285036],
                    [-112.723991, 36.345992],
                    [-112.681571, 36.341662],
                    [-112.629468, 36.391871],
                    [-112.623017, 36.474736],
                    [-112.660059, 36.54356],
                    [-112.620946, 36.59594200000001],
                    [-112.638679, 36.674478],
                    [-112.600381, 36.831928],
                    [-112.529299, 36.952437],
                    [-112.538571, 37.000744128882],
                    [-112.35769, 37.001025000000006],
                    [-111.412783987873, 37.001477687156],
                    [-111.405869, 37.001481],
                    [-111.278286, 37.000465],
                    [-111.066496, 37.002389],
                    [-110.75069, 37.003197],
                    [-110.75071, 35.034206],
                    [-110.750678, 34.263314],
                    [-110.783111, 34.299861],
                    [-110.848059, 34.267205],
                    [-110.95886300000001, 34.314469],
                    [-111.096661, 34.396431],
                    [-111.147094, 34.37518500000001],
                    [-111.250302, 34.453274],
                    [-111.397229, 34.426062],
                    [-111.41853300000001, 34.377287],
                    [-111.423066, 34.437225],
                    [-111.51765700000001, 34.430392],
                    [-111.472046, 34.462265],
                    [-111.502415, 34.498057],
                    [-111.55465300000002, 34.471703],
                    [-111.552106, 34.8025],
                    [-111.778265, 34.803304],
                    [-111.777623, 34.981001],
                    [-112.008116, 34.975867],
                    [-112.33453600000001, 34.973024],
                    [-112.33579100000001, 35.148234],
                    [-112.442289, 35.149238],
                    [-112.442389, 35.25917],
                    [-112.54177, 35.236418],
                    [-112.694667, 35.311651],
                    [-112.735084, 35.305021],
                    [-112.953209, 35.352902],
                    [-112.976935, 35.392803],
                    [-113.147635, 35.47898],
                    [-113.176327, 35.507222],
                    [-113.334161, 35.528217],
                    [-113.33402400000001, 35.803031],
                    [-113.310523, 35.86435],
                    [-113.33485, 35.913859],
                    [-113.317122, 35.961385],
                    [-113.354176, 36.040368],
                    [-113.319971, 36.096769],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '22380',
            AFFGEOID: '310M600US22380',
            GEOID: '22380',
            NAME: 'Flagstaff, AZ',
            NAMELSAD: 'Flagstaff, AZ Metro Area',
            LSAD: 'M1',
            ALAND: 48216135500,
            AWATER: 116631423,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.18803100000001, 24.498131, -80.2293537849126, 25.805377],
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [-81.148718, 24.710477],
                        [-81.038908, 24.772596],
                        [-80.847471, 24.851753],
                        [-80.610869, 25.006995],
                        [-80.516571, 25.095457],
                        [-80.500513, 25.156674],
                        [-80.495394, 25.199808],
                        [-80.492769, 25.205579],
                        [-80.48282600000002, 25.224786],
                        [-80.450659, 25.240241],
                        [-80.41887, 25.23631],
                        [-80.394931, 25.253537],
                        [-80.395081, 25.272937],
                        [-80.372121, 25.310215],
                        [-80.265472, 25.354296],
                        [-80.2293537849126, 25.3421186263507],
                        [-80.238555, 25.32682],
                        [-80.358183, 25.153228000000002],
                        [-80.496761, 24.999324],
                        [-80.651189, 24.866131],
                        [-80.966245, 24.707852],
                        [-81.103373, 24.669463],
                        [-81.148718, 24.710477],
                    ],
                ],
                [
                    [
                        [-81.4423076374621, 25.803330250874602],
                        [-80.873096, 25.805377],
                        [-80.873125, 25.760885],
                        [-80.87319, 25.363993],
                        [-80.8581700400306, 25.1775203265261],
                        [-80.87546, 25.174321],
                        [-80.915924, 25.141301],
                        [-81.009598, 25.125403],
                        [-81.079859, 25.118797],
                        [-81.142278, 25.183],
                        [-81.170907, 25.245857],
                        [-81.148103, 25.332793],
                        [-81.146765, 25.407577],
                        [-81.208201, 25.504937],
                        [-81.240519, 25.599041],
                        [-81.289901, 25.673554],
                        [-81.35598600000002, 25.703526],
                        [-81.38381, 25.776751],
                        [-81.4423076374621, 25.803330250874602],
                    ],
                ],
                [
                    [
                        [-81.811693, 24.568745],
                        [-81.751266, 24.653517],
                        [-81.672338, 24.699514000000004],
                        [-81.584599, 24.736696],
                        [-81.571151, 24.756354000000005],
                        [-81.44351, 24.813364],
                        [-81.305054, 24.755185000000004],
                        [-81.243232, 24.673998],
                        [-81.342192, 24.637774],
                        [-81.401889, 24.623544],
                        [-81.443915, 24.642677],
                        [-81.517404, 24.621239],
                        [-81.595334, 24.593107],
                        [-81.685243, 24.558676],
                        [-81.812536, 24.545469],
                        [-81.811693, 24.568745],
                    ],
                ],
                [
                    [
                        [-82.01491, 24.543071],
                        [-81.983908, 24.580682],
                        [-81.868711, 24.584120000000002],
                        [-81.918855, 24.498131],
                        [-82.028091, 24.498716],
                        [-82.01491, 24.543071],
                    ],
                ],
                [
                    [
                        [-82.18803100000001, 24.574699],
                        [-82.14409900000001, 24.622481],
                        [-82.086643, 24.590071],
                        [-82.100757, 24.533288],
                        [-82.179454, 24.52947],
                        [-82.18803100000001, 24.574699],
                    ],
                ],
            ],
        },
        properties: {
            CSAFP: '370',
            CBSAFP: '28580',
            AFFGEOID: '310M600US28580',
            GEOID: '28580',
            NAME: 'Key West, FL',
            NAMELSAD: 'Key West, FL Micro Area',
            LSAD: 'M2',
            ALAND: 2546067521,
            AWATER: 11153842007,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.69920600000002, 30.564395, -81.997421, 31.530699000000002],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.69920600000002, 31.278104],
                    [-82.629622, 31.274725999999998],
                    [-82.628975, 31.36393],
                    [-82.628181, 31.469351],
                    [-82.597509, 31.469293],
                    [-82.494718, 31.46869],
                    [-82.48146, 31.417534],
                    [-82.417246, 31.417119],
                    [-82.344843, 31.43013],
                    [-82.300422, 31.493175000000004],
                    [-82.22658500000001, 31.530699000000002],
                    [-82.157264, 31.472185000000003],
                    [-82.132794, 31.471262],
                    [-82.063387, 31.466362],
                    [-82.041129, 31.373721],
                    [-81.997421, 31.288534],
                    [-82.114822, 31.270355],
                    [-82.193031, 31.202120000000004],
                    [-82.284561, 31.224449],
                    [-82.208732, 31.170938000000003],
                    [-82.208262, 31.084768],
                    [-82.131698, 31.010714000000004],
                    [-82.415603, 31.01359],
                    [-82.420442, 30.795229000000003],
                    [-82.149872, 30.784336000000003],
                    [-82.218607, 30.564395],
                    [-82.4189841121785, 30.5809166697315],
                    [-82.435852, 30.820068],
                    [-82.495476, 30.819553],
                    [-82.490558, 30.963166],
                    [-82.592071, 31.018487],
                    [-82.671669, 31.183739000000003],
                    [-82.69920600000002, 31.278104],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '48180',
            AFFGEOID: '310M600US48180',
            GEOID: '48180',
            NAME: 'Waycross, GA',
            NAMELSAD: 'Waycross, GA Micro Area',
            LSAD: 'M2',
            ALAND: 3210797856,
            AWATER: 29839229,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-120.00514200000002, 39.165509, -119.170641, 41.996296],
            type: 'Polygon',
            coordinates: [
                [
                    [-120.004795512304, 39.3164750119585],
                    [-120.00302891965701, 39.4450457761605],
                    [-120.00174, 39.538852],
                    [-119.99993544808501, 39.7224069247242],
                    [-119.997634, 39.956505],
                    [-119.997124, 40.126363],
                    [-119.996155, 40.32125],
                    [-119.997533, 40.720992],
                    [-119.99923100000001, 40.865899],
                    [-119.999866, 41.183974],
                    [-119.99827999999998, 41.618765],
                    [-119.99927600000001, 41.874891],
                    [-119.99916800000001, 41.99454],
                    [-119.72573, 41.996296],
                    [-119.36012311960302, 41.994092254981204],
                    [-119.32418412043899, 41.9938756277933],
                    [-119.323955, 41.414519],
                    [-119.305713, 41.234542],
                    [-119.329722, 40.97385],
                    [-119.309598, 40.960378],
                    [-119.313036, 40.523104],
                    [-119.33929599999999, 39.998609],
                    [-119.22517599999999, 39.999385],
                    [-119.208543, 39.809967],
                    [-119.170641, 39.68045300000001],
                    [-119.18934400000002, 39.651423],
                    [-119.25734199999998, 39.65132],
                    [-119.28311600000002, 39.622317],
                    [-119.478792, 39.337851],
                    [-119.71211200000002, 39.251046],
                    [-119.71264500000001, 39.250871],
                    [-119.77087100000001, 39.215285],
                    [-119.843933, 39.200776],
                    [-119.88078700000001, 39.165509],
                    [-120.003364028833, 39.1656267640688],
                    [-120.00514200000002, 39.291258],
                    [-120.004795512304, 39.3164750119585],
                ],
            ],
        },
        properties: {
            CSAFP: '456',
            CBSAFP: '39900',
            AFFGEOID: '310M600US39900',
            GEOID: '39900',
            NAME: 'Reno, NV',
            NAMELSAD: 'Reno, NV Metro Area',
            LSAD: 'M1',
            ALAND: 17041227033,
            AWATER: 584619444,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.080527, 37.821615, -83.433942, 38.296949],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.992845, 38.173364],
                    [-83.977924, 38.192118],
                    [-83.848355, 38.296949],
                    [-83.725393, 38.292584],
                    [-83.64615, 38.171001],
                    [-83.635195, 38.187525],
                    [-83.53442, 38.094693],
                    [-83.462805, 38.07065200000001],
                    [-83.498846, 38.05062],
                    [-83.463408, 38.01697500000001],
                    [-83.433942, 38.033974],
                    [-83.444343, 37.942468],
                    [-83.504925, 37.870096],
                    [-83.49508, 37.861906],
                    [-83.55141, 37.821615],
                    [-83.63106, 37.825372],
                    [-83.66296, 37.834735],
                    [-83.768783, 37.918371],
                    [-83.814919, 37.900227],
                    [-83.96631, 37.930768],
                    [-84.080527, 38.115118],
                    [-83.992845, 38.173364],
                ],
            ],
        },
        properties: {
            CSAFP: '336',
            CBSAFP: '34460',
            AFFGEOID: '310M600US34460',
            GEOID: '34460',
            NAME: 'Mount Sterling, KY',
            NAMELSAD: 'Mount Sterling, KY Micro Area',
            LSAD: 'M2',
            ALAND: 1760500007,
            AWATER: 23424341,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-110.037952, 39.461801, -109.048249, 40.858401],
            type: 'Polygon',
            coordinates: [
                [
                    [-110.037952, 39.566667],
                    [-109.96214300000001, 39.725885],
                    [-109.88308, 39.806236],
                    [-109.976814, 39.80623],
                    [-109.97640200000001, 40.809686],
                    [-109.870207, 40.763829],
                    [-109.525495, 40.82637],
                    [-109.484332, 40.740432],
                    [-109.394465, 40.791032],
                    [-109.394491, 40.858166],
                    [-109.20325, 40.858401],
                    [-109.163425, 40.78595500000001],
                    [-109.161063, 40.683817],
                    [-109.048259750048, 40.662601817228],
                    [-109.048249, 40.653601],
                    [-109.050732112077, 40.2226552582576],
                    [-109.050973, 40.180849],
                    [-109.050615, 39.87497],
                    [-109.050871925262, 39.6604717771594],
                    [-109.05106684627302, 39.4977388093662],
                    [-109.106972, 39.461979],
                    [-109.953349, 39.461801],
                    [-110.023963, 39.469268],
                    [-110.037952, 39.566667],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '46860',
            AFFGEOID: '310M600US46860',
            GEOID: '46860',
            NAME: 'Vernal, UT',
            NAMELSAD: 'Vernal, UT Micro Area',
            LSAD: 'M2',
            ALAND: 11609319393,
            AWATER: 56115801,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.624803, 33.694215, -95.7896393177523, 34.157317],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.624803, 34.009724],
                    [-96.548028, 34.063302],
                    [-96.58535500000002, 34.114459],
                    [-96.453836, 34.1141],
                    [-96.407517, 34.157317],
                    [-95.991578, 34.156805],
                    [-95.93815, 34.1251],
                    [-95.918871, 33.925057],
                    [-95.7896393177523, 33.8724373846723],
                    [-95.820596, 33.858465],
                    [-95.8448785340707, 33.860421904719],
                    [-95.88749100000001, 33.863856],
                    [-95.935325, 33.875099],
                    [-96.063924, 33.841523],
                    [-96.15163, 33.831946],
                    [-96.173025, 33.80056],
                    [-96.229023, 33.748021],
                    [-96.277269, 33.769735],
                    [-96.30738900000001, 33.735005],
                    [-96.363135, 33.694215],
                    [-96.3796608259521, 33.715530908566],
                    [-96.403507, 33.746289],
                    [-96.436455, 33.78005],
                    [-96.502286, 33.77346],
                    [-96.523863, 33.818114],
                    [-96.572937, 33.819098],
                    [-96.592926, 33.830916],
                    [-96.590112, 33.880665],
                    [-96.5946743073771, 33.8830186573983],
                    [-96.57701, 33.964406],
                    [-96.624803, 34.009724],
                ],
            ],
        },
        properties: {
            CSAFP: '206',
            CBSAFP: '20460',
            AFFGEOID: '310M600US20460',
            GEOID: '20460',
            NAME: 'Durant, OK',
            NAMELSAD: 'Durant, OK Micro Area',
            LSAD: 'M2',
            ALAND: 2342004810,
            AWATER: 102054256,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-121.804426, 44.821769, -120.37144100000002, 45.704949],
            type: 'Polygon',
            coordinates: [
                [
                    [-121.804426, 45.012877],
                    [-121.65871, 45.066355],
                    [-121.65263799999998, 45.101992],
                    [-121.731438, 45.170633],
                    [-121.681525, 45.248818],
                    [-121.69647900000001, 45.25792],
                    [-121.48188800000001, 45.258128],
                    [-121.44064600000002, 45.519394],
                    [-121.440695827861, 45.6990737947724],
                    [-121.42359200000001, 45.69399],
                    [-121.33776999999999, 45.704949],
                    [-121.215779, 45.67123800000001],
                    [-121.18384099999999, 45.606441],
                    [-121.12220000000002, 45.616067],
                    [-121.08493300000002, 45.647893],
                    [-120.943977, 45.656445],
                    [-120.91393501308401, 45.6480658664235],
                    [-120.908459, 45.558241],
                    [-120.826385, 45.514978],
                    [-120.86856900000001, 45.401146],
                    [-120.956471, 45.310345],
                    [-121.01569, 45.307148],
                    [-121.023879, 45.218291],
                    [-120.88935700000002, 45.202305],
                    [-120.72356899999998, 45.130911],
                    [-120.72979200000002, 45.083731],
                    [-120.50373299999998, 45.083477],
                    [-120.49516000000001, 45.068279],
                    [-120.45520400000001, 44.865607],
                    [-120.37144100000002, 44.821769],
                    [-121.08948000000001, 44.823272],
                    [-121.725068, 44.825519],
                    [-121.75299399999999, 44.829919],
                    [-121.73422299999999, 44.88572],
                    [-121.804426, 45.012877],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '45520',
            AFFGEOID: '310M600US45520',
            GEOID: '45520',
            NAME: 'The Dalles, OR',
            NAMELSAD: 'The Dalles, OR Micro Area',
            LSAD: 'M2',
            ALAND: 6167113903,
            AWATER: 35722739,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.168851, 34.583187, -84.618637, 34.988329],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.144789, 34.767639],
                    [-85.061648, 34.819478000000004],
                    [-84.9798540252315, 34.9872056139756],
                    [-84.9769738065923, 34.9872198373549],
                    [-84.861314, 34.987791],
                    [-84.8104774668864, 34.9878779552292],
                    [-84.7758376712973, 34.9879372061493],
                    [-84.727434, 34.98802],
                    [-84.621483, 34.988329],
                    [-84.622731, 34.875077],
                    [-84.618637, 34.855398],
                    [-84.627348, 34.78472800000001],
                    [-84.657098, 34.728905],
                    [-84.654523, 34.583187],
                    [-84.715205, 34.622474],
                    [-84.863793, 34.608597],
                    [-84.913456, 34.634128],
                    [-84.92611900000001, 34.616164],
                    [-85.050446, 34.622482000000005],
                    [-85.050847, 34.71998],
                    [-85.168851, 34.720279],
                    [-85.144789, 34.767639],
                ],
            ],
        },
        properties: {
            CSAFP: '174',
            CBSAFP: '19140',
            AFFGEOID: '310M600US19140',
            GEOID: '19140',
            NAME: 'Dalton, GA',
            NAMELSAD: 'Dalton, GA Metro Area',
            LSAD: 'M1',
            ALAND: 1644483809,
            AWATER: 7298674,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.8837542129764, 36.800031, -82.298596, 37.2014503744627],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.865192, 36.920923],
                    [-82.869183, 36.974182],
                    [-82.815748, 37.007196],
                    [-82.750715, 37.024107],
                    [-82.72225400000002, 37.05794800000001],
                    [-82.726294, 37.111852],
                    [-82.565275702797, 37.1959011068831],
                    [-82.558178, 37.199606],
                    [-82.5536395449037, 37.2014503744627],
                    [-82.546135, 37.125118],
                    [-82.467345, 37.022136],
                    [-82.361944, 36.955715],
                    [-82.32783000000002, 36.970982],
                    [-82.298596, 36.905761],
                    [-82.406633, 36.876356],
                    [-82.411098, 36.88504400000001],
                    [-82.61774, 36.880773],
                    [-82.771595, 36.800031],
                    [-82.847362, 36.847663],
                    [-82.8837542129764, 36.8971301366542],
                    [-82.865192, 36.920923],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '13720',
            AFFGEOID: '310M600US13720',
            GEOID: '13720',
            NAME: 'Big Stone Gap, VA',
            NAMELSAD: 'Big Stone Gap, VA Micro Area',
            LSAD: 'M2',
            ALAND: 1064270834,
            AWATER: 5345461,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.529722, 34.299299, -86.550166, 34.800022],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.529667, 34.567081],
                    [-87.44577, 34.650968],
                    [-87.42651, 34.800022],
                    [-87.344224, 34.796493],
                    [-87.297905, 34.750812],
                    [-87.260676, 34.758626],
                    [-87.222773, 34.763226],
                    [-87.105073, 34.686037000000006],
                    [-86.838554, 34.556632],
                    [-86.790056, 34.55079],
                    [-86.689353, 34.586425],
                    [-86.646393, 34.548921],
                    [-86.578855, 34.581355],
                    [-86.550166, 34.545963],
                    [-86.552782, 34.539951],
                    [-86.58132, 34.371094],
                    [-86.581936, 34.304694],
                    [-87.110111, 34.313799],
                    [-87.109911, 34.299299],
                    [-87.529722, 34.304598],
                    [-87.529667, 34.567081],
                ],
            ],
        },
        properties: {
            CSAFP: '290',
            CBSAFP: '19460',
            AFFGEOID: '310M600US19460',
            GEOID: '19460',
            NAME: 'Decatur, AL',
            NAMELSAD: 'Decatur, AL Metro Area',
            LSAD: 'M1',
            ALAND: 3290199884,
            AWATER: 118878663,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-99.30399600000001, 30.134298, -98.587897, 30.499875],
            type: 'Polygon',
            coordinates: [
                [
                    [-99.30399600000001, 30.499832],
                    [-98.964232, 30.498482000000003],
                    [-98.59167, 30.499875],
                    [-98.587897, 30.138954000000002],
                    [-98.920147, 30.13829],
                    [-99.300988, 30.134298],
                    [-99.301718, 30.286653],
                    [-99.30399600000001, 30.499832],
                ],
            ],
        },
        properties: {
            CSAFP: '314',
            CBSAFP: '23240',
            AFFGEOID: '310M600US23240',
            GEOID: '23240',
            NAME: 'Fredericksburg, TX',
            NAMELSAD: 'Fredericksburg, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2740731305,
            AWATER: 9000601,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.851865, 34.445166, -81.415894, 34.912865],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.782791, 34.837211],
                    [-81.714094, 34.912865],
                    [-81.481945, 34.866495],
                    [-81.457257, 34.83928],
                    [-81.478454, 34.821509],
                    [-81.456304, 34.792521],
                    [-81.482351, 34.744398],
                    [-81.415894, 34.633589],
                    [-81.422706, 34.572029],
                    [-81.427545, 34.529482],
                    [-81.423241, 34.494379],
                    [-81.54145500000001, 34.445166],
                    [-81.643552, 34.533652],
                    [-81.677321, 34.557972],
                    [-81.851865, 34.594775],
                    [-81.782791, 34.837211],
                ],
            ],
        },
        properties: {
            CSAFP: '273',
            CBSAFP: '46420',
            AFFGEOID: '310M600US46420',
            GEOID: '46420',
            NAME: 'Union, SC',
            NAMELSAD: 'Union, SC Micro Area',
            LSAD: 'M2',
            ALAND: 1330180037,
            AWATER: 4792745,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-102.163015, 35.620035, -101.6228, 36.055405],
            type: 'Polygon',
            coordinates: [
                [
                    [-102.163015, 36.055249],
                    [-101.623466, 36.055405],
                    [-101.62283100000002, 35.624057],
                    [-101.6228, 35.620196],
                    [-102.162752, 35.620035],
                    [-102.162809, 35.627515],
                    [-102.163015, 36.055249],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '20300',
            AFFGEOID: '310M600US20300',
            GEOID: '20300',
            NAME: 'Dumas, TX',
            NAMELSAD: 'Dumas, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2330207191,
            AWATER: 25699789,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-123.20189100000002, 46.763061, -122.20311500000001, 47.19422],
            type: 'Polygon',
            coordinates: [
                [
                    [-123.20189100000002, 47.085059],
                    [-123.09678400000001, 47.085354],
                    [-123.07495700000001, 47.090844000000004],
                    [-123.073654, 47.106242],
                    [-123.060583, 47.114025],
                    [-123.034109, 47.115041],
                    [-123.015389, 47.123792],
                    [-123.010456, 47.143326],
                    [-123.00243500000002, 47.151302],
                    [-122.969123, 47.161525],
                    [-122.95281900000002, 47.170103],
                    [-122.94704600000001, 47.185641],
                    [-122.936283, 47.189457],
                    [-122.902417, 47.157381],
                    [-122.820576, 47.19422],
                    [-122.759505, 47.141315000000006],
                    [-122.69217799999998, 47.099206],
                    [-122.659863, 47.003225],
                    [-122.56043600000001, 46.933457],
                    [-122.494116, 46.905218],
                    [-122.49067400000001, 46.867263],
                    [-122.30357600000002, 46.828117],
                    [-122.26514200000001, 46.76530300000001],
                    [-122.20311500000001, 46.763061],
                    [-123.160909, 46.76433],
                    [-123.160588, 46.793383],
                    [-123.16049999999998, 46.827161],
                    [-123.15836400000002, 46.995871],
                    [-123.20189100000002, 47.085059],
                ],
            ],
        },
        properties: {
            CSAFP: '500',
            CBSAFP: '36500',
            AFFGEOID: '310M600US36500',
            GEOID: '36500',
            NAME: 'Olympia-Lacey-Tumwater, WA',
            NAMELSAD: 'Olympia-Lacey-Tumwater, WA Metro Area',
            LSAD: 'M1',
            ALAND: 1871256793,
            AWATER: 133627810,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-123.78453999999999, 44.88572, -121.52232100000002, 46.388627],
            type: 'Polygon',
            coordinates: [
                [
                    [-123.78453999999999, 45.216294],
                    [-123.463518, 45.216312],
                    [-123.46488, 45.433332],
                    [-123.461181, 45.49156],
                    [-123.29943800000001, 45.60724700000001],
                    [-123.423273, 45.636176],
                    [-123.484726, 45.708764],
                    [-123.361028, 45.708696],
                    [-123.36162199999998, 45.779579],
                    [-123.363744507266, 46.1462431943485],
                    [-123.28016599999998, 46.144843],
                    [-123.212493591209, 46.1710964584003],
                    [-123.16641400000002, 46.188973],
                    [-123.11590400000001, 46.185268],
                    [-123.004233, 46.133823],
                    [-122.962681, 46.104817],
                    [-122.904119, 46.083734],
                    [-122.856158, 46.014469],
                    [-122.813998, 45.960984],
                    [-122.81151000000001, 45.91272500000001],
                    [-122.785026, 45.867699],
                    [-122.788086362595, 45.8510074543561],
                    [-122.71976699999999, 45.870903],
                    [-122.719712, 45.933256],
                    [-122.657632, 45.928134],
                    [-122.47710399999998, 45.988089],
                    [-122.35776500000001, 45.956915],
                    [-122.245856, 46.053853],
                    [-122.240966, 46.385361],
                    [-122.236759, 46.385893],
                    [-122.11429100000001, 46.386480000000006],
                    [-122.03214000000001, 46.38781],
                    [-121.522324, 46.388627],
                    [-121.522324, 46.388224],
                    [-121.52232100000002, 46.044006],
                    [-121.61223200000002, 46.044093],
                    [-121.60924599999998, 45.782519],
                    [-121.52400610422002, 45.7238362251992],
                    [-121.53310600000002, 45.726541],
                    [-121.668362, 45.705082],
                    [-121.73510400000002, 45.694039],
                    [-121.81130399999999, 45.706761],
                    [-121.867167, 45.693277],
                    [-121.90085800000001, 45.662009],
                    [-121.923749361985, 45.65434950896941],
                    [-121.90661, 45.52123],
                    [-121.819587, 45.461675],
                    [-121.69751700000002, 45.373663],
                    [-121.69647900000001, 45.25792],
                    [-121.681525, 45.248818],
                    [-121.731438, 45.170633],
                    [-121.65263799999998, 45.101992],
                    [-121.65871, 45.066355],
                    [-121.804426, 45.012877],
                    [-121.73422299999999, 44.88572],
                    [-122.40102100000001, 44.88572],
                    [-122.53599900000002, 44.937161],
                    [-122.59552300000001, 45.019679],
                    [-122.70511499999999, 45.059023],
                    [-122.78700699999999, 45.128625],
                    [-122.73903, 45.259359],
                    [-122.849925, 45.259697],
                    [-122.968666, 45.284729],
                    [-123.04117, 45.220788],
                    [-122.99669299999998, 45.117063],
                    [-123.068667, 45.07486],
                    [-123.72436800000001, 45.076226000000005],
                    [-123.784218, 45.07665500000001],
                    [-123.78453999999999, 45.216294],
                ],
            ],
        },
        properties: {
            CSAFP: '440',
            CBSAFP: '38900',
            AFFGEOID: '310M600US38900',
            GEOID: '38900',
            NAME: 'Portland-Vancouver-Hillsboro, OR-WA',
            NAMELSAD: 'Portland-Vancouver-Hillsboro, OR-WA Metro Area',
            LSAD: 'M1',
            ALAND: 17320463242,
            AWATER: 354512535,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-112.15917745151, 41.368818, -111.401576, 42.430635],
            type: 'Polygon',
            coordinates: [
                [
                    [-112.109532, 41.997598],
                    [-112.10944305582, 41.9975984294066],
                    [-112.109271, 42.169911],
                    [-112.070959, 42.238263],
                    [-112.126194, 42.285229],
                    [-111.983848, 42.28555],
                    [-111.896728, 42.256276],
                    [-111.875636, 42.41615800000001],
                    [-111.733742, 42.430635],
                    [-111.599227, 42.415395],
                    [-111.552151, 42.351386],
                    [-111.626104, 42.207542],
                    [-111.599116, 42.099006],
                    [-111.521577, 42.074437],
                    [-111.50781303517601, 41.9996856683522],
                    [-111.507806195687, 41.9996856783643],
                    [-111.471321, 41.928157000000006],
                    [-111.507864, 41.852589],
                    [-111.460722, 41.81698500000001],
                    [-111.401576, 41.654081],
                    [-111.510839, 41.4231],
                    [-111.666576, 41.42883],
                    [-111.76059, 41.368818],
                    [-111.885443, 41.42637400000001],
                    [-111.873197, 41.485174],
                    [-111.946089, 41.553029],
                    [-111.981042, 41.534117],
                    [-112.042792, 41.710433],
                    [-112.008789, 41.795077],
                    [-112.15917745151, 41.9986819297378],
                    [-112.109532, 41.997598],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '30860',
            AFFGEOID: '310M600US30860',
            GEOID: '30860',
            NAME: 'Logan, UT-ID',
            NAMELSAD: 'Logan, UT-ID Metro Area',
            LSAD: 'M1',
            ALAND: 4733838660,
            AWATER: 33251170,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-124.041128, 45.77308, -123.36162199999998, 46.269131],
            type: 'Polygon',
            coordinates: [
                [
                    [-123.998052, 46.235327],
                    [-123.91240500000002, 46.17945],
                    [-123.838801, 46.19221100000001],
                    [-123.757589, 46.213001],
                    [-123.718149, 46.188989],
                    [-123.660868, 46.216296],
                    [-123.586205, 46.228654],
                    [-123.54765900000001, 46.259109],
                    [-123.479644, 46.269131],
                    [-123.427629, 46.229348],
                    [-123.430847, 46.181827],
                    [-123.371433, 46.146372],
                    [-123.363744507266, 46.1462431943485],
                    [-123.36162199999998, 45.779579],
                    [-123.720001, 45.77308],
                    [-123.966278384519, 45.7830849035453],
                    [-123.961544, 45.837101],
                    [-123.96762999999999, 45.907807],
                    [-123.99370300000001, 45.946431],
                    [-123.937471, 45.977306],
                    [-123.92933, 46.041978],
                    [-123.95919, 46.141675],
                    [-124.041128, 46.197672000000004],
                    [-123.998052, 46.235327],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '11820',
            AFFGEOID: '310M600US11820',
            GEOID: '11820',
            NAME: 'Astoria, OR',
            NAMELSAD: 'Astoria, OR Micro Area',
            LSAD: 'M2',
            ALAND: 2144846710,
            AWATER: 661654767,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-104.053127, 41.3932140001934, -103.36262, 43.0007588128236],
            type: 'Polygon',
            coordinates: [
                [
                    [-104.053127, 43.000585],
                    [-103.505099916029, 43.0007588128236],
                    [-103.480335, 42.784908],
                    [-103.48001, 42.437267],
                    [-103.444278, 42.437275],
                    [-103.444108, 42.09070800000001],
                    [-103.401591, 42.090411],
                    [-103.401522, 42.003537],
                    [-103.363337, 42.00293],
                    [-103.36262, 41.743548],
                    [-103.370391, 41.69921],
                    [-103.369005, 41.43776600000001],
                    [-103.37762, 41.394633],
                    [-104.052287134121, 41.3932140001934],
                    [-104.052287, 41.393307],
                    [-104.052634571633, 41.5642757795737],
                    [-104.052735, 41.613676],
                    [-104.052825094979, 41.6978228532141],
                    [-104.053026, 41.885464],
                    [-104.052762005445, 42.0017762678376],
                    [-104.052729, 42.016318],
                    [-104.052793, 42.249962],
                    [-104.053107, 42.499964],
                    [-104.052662191568, 42.6117663005415],
                    [-104.05258600000002, 42.630917],
                    [-104.053127, 43.000585],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '42420',
            AFFGEOID: '310M600US42420',
            GEOID: '42420',
            NAME: 'Scottsbluff, NE',
            NAMELSAD: 'Scottsbluff, NE Micro Area',
            LSAD: 'M2',
            ALAND: 9200592654,
            AWATER: 17460065,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-106.210173, 38.693551, -103.705701, 40.044144],
            type: 'Polygon',
            coordinates: [
                [
                    [-106.210173, 39.103918],
                    [-106.173847, 39.137076],
                    [-106.18397200000001, 39.309354],
                    [-106.135529, 39.379546],
                    [-106.021966, 39.361713],
                    [-105.966786, 39.438035],
                    [-105.817631, 39.53831],
                    [-105.82966200000001, 39.564865000000005],
                    [-105.77601, 39.609267],
                    [-105.924618, 39.698972],
                    [-105.887622, 39.7966],
                    [-105.736426, 39.804801],
                    [-105.690348, 39.851996],
                    [-105.699719, 39.891499],
                    [-105.675798, 39.932445],
                    [-105.398041, 39.934854],
                    [-105.397849, 39.91288600000001],
                    [-105.339547, 39.913786],
                    [-105.16615, 39.914047],
                    [-105.109517, 39.956996],
                    [-105.071899, 39.950198],
                    [-105.052823, 40.00026],
                    [-104.961071, 40.044144],
                    [-104.96132, 40.014982],
                    [-104.961538, 40.000404],
                    [-104.96140500000001, 40.000419],
                    [-104.96140500000001, 40.000337],
                    [-104.489757, 40.000613],
                    [-104.150332, 40.000856],
                    [-103.705701, 40.001366],
                    [-103.706547, 39.73989400000001],
                    [-103.707072, 39.566295000000004],
                    [-103.71435500000001, 39.566331],
                    [-103.719664, 38.866827],
                    [-104.055528, 38.868868],
                    [-104.051568, 39.128138],
                    [-104.662896, 39.129527],
                    [-105.033473, 39.129783],
                    [-105.329445, 39.129487],
                    [-105.330382, 39.129474],
                    [-105.328962, 38.697558],
                    [-105.96975, 38.693551],
                    [-105.906587, 38.806962],
                    [-105.925123, 38.872178],
                    [-106.028138, 38.942849],
                    [-106.110995, 38.940099],
                    [-106.191006, 39.056073],
                    [-106.210173, 39.103918],
                ],
            ],
        },
        properties: {
            CSAFP: '216',
            CBSAFP: '19740',
            AFFGEOID: '310M600US19740',
            GEOID: '19740',
            NAME: 'Denver-Aurora-Lakewood, CO',
            NAMELSAD: 'Denver-Aurora-Lakewood, CO Metro Area',
            LSAD: 'M1',
            ALAND: 21612483485,
            AWATER: 149328082,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.828026, 35.979154, -85.082497, 36.539891],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.828026, 36.411836],
                    [-85.813885, 36.499473],
                    [-85.704075, 36.522395],
                    [-85.57596, 36.471617],
                    [-85.495422, 36.403074],
                    [-85.446372, 36.49797],
                    [-85.284878, 36.531293],
                    [-85.268647, 36.539891],
                    [-85.120445, 36.407164],
                    [-85.122245, 36.304599],
                    [-85.082497, 36.196171],
                    [-85.119583, 36.14471900000001],
                    [-85.100213, 36.138561],
                    [-85.15275000000001, 36.099544],
                    [-85.246362, 36.100698],
                    [-85.264206, 35.979154],
                    [-85.309363, 36.031592],
                    [-85.507677, 36.082012],
                    [-85.644604, 36.015053],
                    [-85.767136, 36.070087],
                    [-85.80741500000002, 36.131582],
                    [-85.809168, 36.206598],
                    [-85.780148, 36.238011],
                    [-85.82766, 36.255272],
                    [-85.828026, 36.411836],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '18260',
            AFFGEOID: '310M600US18260',
            GEOID: '18260',
            NAME: 'Cookeville, TN',
            NAMELSAD: 'Cookeville, TN Micro Area',
            LSAD: 'M2',
            ALAND: 2961395663,
            AWATER: 35022324,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.790296, 32.4722024074067, -79.269396, 33.500743],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.790296, 33.18084],
                    [-80.50279, 33.334496],
                    [-80.484578, 33.280034],
                    [-80.361851, 33.257443],
                    [-80.253836, 33.29926],
                    [-80.222267, 33.443716],
                    [-80.150003, 33.448463],
                    [-80.101697, 33.496891],
                    [-79.971062, 33.500743],
                    [-79.677014, 33.304944],
                    [-79.458734, 33.235219],
                    [-79.446699, 33.213458],
                    [-79.350816, 33.15352800000001],
                    [-79.269396, 33.136883],
                    [-79.2744945846428, 33.1200562677204],
                    [-79.291591, 33.109773],
                    [-79.329909, 33.089986],
                    [-79.339313, 33.050336],
                    [-79.359961, 33.006672],
                    [-79.423447, 33.015085],
                    [-79.483499, 33.001265],
                    [-79.522449, 33.03535],
                    [-79.580725, 33.006447],
                    [-79.606615, 32.972248],
                    [-79.569762, 32.926692],
                    [-79.60130900000001, 32.89815],
                    [-79.695141, 32.850398],
                    [-79.726389, 32.805996],
                    [-79.818237, 32.766352],
                    [-79.868352, 32.734849],
                    [-79.884961, 32.684402],
                    [-79.968468, 32.639732],
                    [-80.000801, 32.605892],
                    [-80.077039, 32.603319],
                    [-80.148406, 32.578479],
                    [-80.190108, 32.546841],
                    [-80.246361, 32.531114],
                    [-80.2494423292999, 32.5293593843875],
                    [-80.338354, 32.47873],
                    [-80.4032765927714, 32.4722024074067],
                    [-80.407846, 32.523448],
                    [-80.411927, 32.53408],
                    [-80.409735, 32.53699],
                    [-80.418903, 32.543064],
                    [-80.421662, 32.547143],
                    [-80.422201, 32.553489],
                    [-80.420442, 32.558048],
                    [-80.418467, 32.559927],
                    [-80.409699, 32.564393],
                    [-80.40580200000001, 32.56831],
                    [-80.401039, 32.570234],
                    [-80.394654, 32.569521],
                    [-80.393325, 32.575271],
                    [-80.398138, 32.582399],
                    [-80.400003, 32.587807],
                    [-80.399621, 32.596101],
                    [-80.398401, 32.599697],
                    [-80.394528, 32.605699],
                    [-80.38854, 32.612754],
                    [-80.389215, 32.616237],
                    [-80.393114, 32.61782],
                    [-80.395903, 32.616973],
                    [-80.407357, 32.610148],
                    [-80.4151, 32.604949],
                    [-80.418971, 32.6033],
                    [-80.426009, 32.603736],
                    [-80.424087, 32.605294],
                    [-80.426983, 32.608754],
                    [-80.428633, 32.613543],
                    [-80.429065, 32.623602],
                    [-80.389401, 32.65282],
                    [-80.45363, 32.7399],
                    [-80.40134, 32.858466],
                    [-80.418813, 32.967264],
                    [-80.390533, 33.043909],
                    [-80.532013, 33.069653],
                    [-80.6221, 33.06608],
                    [-80.709736, 33.15647],
                    [-80.790296, 33.18084],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '16700',
            AFFGEOID: '310M600US16700',
            GEOID: '16700',
            NAME: 'Charleston-North Charleston, SC',
            NAMELSAD: 'Charleston-North Charleston, SC Metro Area',
            LSAD: 'M1',
            ALAND: 6708570000,
            AWATER: 1483700811,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.541579, 42.842095, -87.7910201400285, 43.543532],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.541579, 43.019387],
                    [-88.535843, 43.196048],
                    [-88.417987, 43.194697000000005],
                    [-88.418439, 43.370175],
                    [-88.400426, 43.543532],
                    [-88.160873, 43.542943],
                    [-88.040528, 43.542361],
                    [-87.7910201400285, 43.543015444928],
                    [-87.793239, 43.492783],
                    [-87.840955, 43.420677000000005],
                    [-87.889207, 43.307652],
                    [-87.896286, 43.197108],
                    [-87.8965793111888, 43.1921346325265],
                    [-87.900485, 43.12591],
                    [-87.870184, 43.064412],
                    [-87.895784, 43.015814],
                    [-87.842681, 42.944116],
                    [-87.834879, 42.856717],
                    [-87.8274775244631, 42.8489230088186],
                    [-87.821162938034, 42.8422735467354],
                    [-88.069924, 42.843323],
                    [-88.306384, 42.842095],
                    [-88.541535, 42.842996],
                    [-88.541579, 43.019387],
                ],
            ],
        },
        properties: {
            CSAFP: '376',
            CBSAFP: '33340',
            AFFGEOID: '310M600US33340',
            GEOID: '33340',
            NAME: 'Milwaukee-Waukesha, WI',
            NAMELSAD: 'Milwaukee-Waukesha, WI Metro Area',
            LSAD: 'M1',
            ALAND: 3768081847,
            AWATER: 4835621033,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.278302, 34.479581, -86.256968, 34.9990491809997],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.278302, 34.773563],
                    [-87.214914, 34.816011],
                    [-87.2107585799823, 34.9990491809997],
                    [-86.8362863159495, 34.992803004217],
                    [-86.783648, 34.991925],
                    [-86.783628017169, 34.991924921992],
                    [-86.467798, 34.990692],
                    [-86.31876076546511, 34.99107858044281],
                    [-86.311274, 34.991098],
                    [-86.256968, 34.937775],
                    [-86.325569, 34.937323],
                    [-86.359034, 34.63261],
                    [-86.326853, 34.599403],
                    [-86.330198, 34.512684],
                    [-86.423914, 34.479581],
                    [-86.53344500000001, 34.502795],
                    [-86.550166, 34.545963],
                    [-86.578855, 34.581355],
                    [-86.646393, 34.548921],
                    [-86.689353, 34.586425],
                    [-86.790056, 34.55079],
                    [-86.838554, 34.556632],
                    [-87.105073, 34.686037000000006],
                    [-87.222773, 34.763226],
                    [-87.260676, 34.758626],
                    [-87.278302, 34.773563],
                ],
            ],
        },
        properties: {
            CSAFP: '290',
            CBSAFP: '26620',
            AFFGEOID: '310M600US26620',
            GEOID: '26620',
            NAME: 'Huntsville, AL',
            NAMELSAD: 'Huntsville, AL Metro Area',
            LSAD: 'M1',
            ALAND: 3526435226,
            AWATER: 151094916,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-113.333894, 31.4247651231261, -110.451002, 32.513995],
            type: 'Polygon',
            coordinates: [
                [
                    [-113.333894, 32.505188],
                    [-112.625204, 32.50535500000001],
                    [-112.203624, 32.506721],
                    [-111.792536, 32.506644],
                    [-111.351577, 32.50139],
                    [-111.157493, 32.511255],
                    [-110.85872, 32.511223],
                    [-110.842071, 32.513865],
                    [-110.451522, 32.513995],
                    [-110.451002, 32.42754600000001],
                    [-110.4523, 31.731219000000003],
                    [-111.05804, 31.72623],
                    [-111.164183, 31.72589],
                    [-111.163717, 31.521724000000003],
                    [-111.366922, 31.521361],
                    [-111.366782684389, 31.4247651231261],
                    [-112.365043, 31.741130000000002],
                    [-113.333767973058, 32.0402490801033],
                    [-113.333894, 32.505188],
                ],
            ],
        },
        properties: {
            CSAFP: '536',
            CBSAFP: '46060',
            AFFGEOID: '310M600US46060',
            GEOID: '46060',
            NAME: 'Tucson, AZ',
            NAMELSAD: 'Tucson, AZ Metro Area',
            LSAD: 'M1',
            ALAND: 23798578890,
            AWATER: 5112016,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-120.653274, 37.633704, -119.20028299999998, 38.433521],
            type: 'Polygon',
            coordinates: [
                [
                    [-120.514952, 37.95339],
                    [-120.53427599999999, 37.989443],
                    [-120.462798, 38.011927],
                    [-120.442529, 38.058741],
                    [-120.17617700000001, 38.374014],
                    [-120.019951, 38.433521],
                    [-119.884749, 38.356185],
                    [-119.705385, 38.416102],
                    [-119.639205, 38.32688],
                    [-119.651509, 38.286462],
                    [-119.60418199999998, 38.234961],
                    [-119.63267100000002, 38.198858],
                    [-119.576496, 38.157661],
                    [-119.46945599999998, 38.128283],
                    [-119.34592200000002, 38.083113],
                    [-119.30459000000002, 38.023891],
                    [-119.308389, 37.946824],
                    [-119.20028299999998, 37.885827],
                    [-119.20127999999998, 37.804329],
                    [-119.26897899999999, 37.73923],
                    [-119.30899500000001, 37.777986],
                    [-119.42679200000002, 37.866724],
                    [-119.47429500000001, 37.855623],
                    [-119.53569899999998, 37.904122],
                    [-119.64620300000001, 37.846823],
                    [-119.667203, 37.801224000000005],
                    [-119.809409, 37.755025],
                    [-119.90701300000002, 37.757926],
                    [-120.079508, 37.828808],
                    [-120.12722600000001, 37.781566],
                    [-120.34543700000002, 37.724787],
                    [-120.30631200000002, 37.665419],
                    [-120.391931, 37.683559],
                    [-120.38761299999999, 37.633704],
                    [-120.653274, 37.831858],
                    [-120.514952, 37.95339],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '43760',
            AFFGEOID: '310M600US43760',
            GEOID: '43760',
            NAME: 'Sonora, CA',
            NAMELSAD: 'Sonora, CA Micro Area',
            LSAD: 'M2',
            ALAND: 5752133238,
            AWATER: 138668837,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.388182, 42.560309, -94.91389, 42.909897],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.388182, 42.909897],
                    [-94.91389, 42.9097],
                    [-94.914485, 42.560309],
                    [-95.38801000000001, 42.561742],
                    [-95.388182, 42.909897],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '44740',
            AFFGEOID: '310M600US44740',
            GEOID: '44740',
            NAME: 'Storm Lake, IA',
            NAMELSAD: 'Storm Lake, IA Micro Area',
            LSAD: 'M2',
            ALAND: 1488981867,
            AWATER: 13798140,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.054578, 38.99087, -86.31747400000002, 39.473345],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.053646, 39.342621],
                    [-86.942473, 39.342043],
                    [-86.939981, 39.473345],
                    [-86.68571, 39.470063],
                    [-86.63191, 39.441086],
                    [-86.630818, 39.346948],
                    [-86.611706, 39.340251],
                    [-86.381395, 39.339752000000004],
                    [-86.370066, 39.048375],
                    [-86.318133, 39.04881],
                    [-86.31747400000002, 38.99087],
                    [-86.682277, 38.992404],
                    [-86.683002, 39.165746],
                    [-87.054578, 39.168086],
                    [-87.053646, 39.342621],
                ],
            ],
        },
        properties: {
            CSAFP: '144',
            CBSAFP: '14020',
            AFFGEOID: '310M600US14020',
            GEOID: '14020',
            NAME: 'Bloomington, IN',
            NAMELSAD: 'Bloomington, IN Metro Area',
            LSAD: 'M1',
            ALAND: 2019716019,
            AWATER: 50079576,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-118.69776599999999, 44.958396, -117.266005, 45.861072],
            type: 'Polygon',
            coordinates: [
                [
                    [-118.69776599999999, 45.345647],
                    [-118.42832899999999, 45.35519],
                    [-118.42853900000001, 45.429076],
                    [-118.197876, 45.427204],
                    [-118.19795500000001, 45.4704],
                    [-118.116461, 45.470732],
                    [-118.11719400000001, 45.688607],
                    [-118.066383, 45.68839500000001],
                    [-118.045003, 45.817073],
                    [-117.97294799999999, 45.81689600000001],
                    [-117.97298000000002, 45.860595],
                    [-117.747327, 45.861072],
                    [-117.74765, 45.773625],
                    [-117.788395, 45.773515],
                    [-117.787623, 45.689567000000004],
                    [-117.727225, 45.61699],
                    [-117.727235, 45.51478],
                    [-117.663391, 45.365749],
                    [-117.58234400000002, 45.338096],
                    [-117.522249, 45.267731],
                    [-117.520941, 45.209768],
                    [-117.476684, 45.166539],
                    [-117.26600600000002, 45.166539],
                    [-117.266005, 45.08057],
                    [-117.56348600000001, 45.079446],
                    [-117.56403300000001, 44.992866],
                    [-117.77750400000001, 44.992161],
                    [-117.84343, 45.058477],
                    [-117.969083, 44.995831],
                    [-118.13035099999999, 45.045024],
                    [-118.244662, 44.958396],
                    [-118.36421400000002, 44.990763],
                    [-118.43610000000001, 44.96242],
                    [-118.518689, 44.995877],
                    [-118.54765200000001, 45.080753],
                    [-118.54663199999999, 45.196898],
                    [-118.655992, 45.196928],
                    [-118.656102, 45.257729],
                    [-118.69698500000001, 45.257613],
                    [-118.69776599999999, 45.345647],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '29260',
            AFFGEOID: '310M600US29260',
            GEOID: '29260',
            NAME: 'La Grande, OR',
            NAMELSAD: 'La Grande, OR Micro Area',
            LSAD: 'M2',
            ALAND: 5275651494,
            AWATER: 4936782,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-101.40726, 40.697802, -100.223311, 41.7432],
            type: 'Polygon',
            coordinates: [
                [
                    [-101.406466, 41.7432],
                    [-100.87543, 41.739903],
                    [-100.842459, 41.740404],
                    [-100.711409, 41.739759],
                    [-100.265474, 41.739856],
                    [-100.251224, 41.739817],
                    [-100.251098, 41.393298],
                    [-100.238117, 41.06102],
                    [-100.224235, 41.046597000000006],
                    [-100.223311, 40.700254],
                    [-100.777833, 40.700135],
                    [-101.247963, 40.697802],
                    [-101.249975, 41.00395],
                    [-101.270381, 41.047392],
                    [-101.269695, 41.394934],
                    [-101.40726, 41.395416],
                    [-101.406466, 41.7432],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '35820',
            AFFGEOID: '310M600US35820',
            GEOID: '35820',
            NAME: 'North Platte, NE',
            NAMELSAD: 'North Platte, NE Micro Area',
            LSAD: 'M2',
            ALAND: 10344655415,
            AWATER: 32320949,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-94.61791900000001, 35.752986, -93.44574, 36.4996],
            type: 'Polygon',
            coordinates: [
                [
                    [-94.61791900000001, 36.49941400000001],
                    [-94.361203, 36.4996],
                    [-94.0770881248555, 36.49897595691],
                    [-93.95919, 36.498717],
                    [-93.8666892118508, 36.4988662760355],
                    [-93.890446, 36.378042],
                    [-93.81507800000001, 36.306551],
                    [-93.657512, 36.303016],
                    [-93.620058, 36.201248],
                    [-93.551135, 36.126271],
                    [-93.479521, 36.125054],
                    [-93.44574, 36.080787],
                    [-93.450788, 35.967497],
                    [-93.523646, 35.881514],
                    [-93.519185, 35.763487],
                    [-93.695856, 35.76862],
                    [-93.901165, 35.774101],
                    [-93.910836, 35.759752],
                    [-93.963054, 35.761005],
                    [-94.124269, 35.764757],
                    [-94.212502, 35.752986],
                    [-94.4930532906959, 35.7592465106763],
                    [-94.494549, 35.768303],
                    [-94.532071, 35.987852],
                    [-94.5518886566514, 36.1021239714216],
                    [-94.5622679285528, 36.1619726146264],
                    [-94.5862, 36.299969],
                    [-94.61791900000001, 36.49941400000001],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '22220',
            AFFGEOID: '310M600US22220',
            GEOID: '22220',
            NAME: 'Fayetteville-Springdale-Rogers, AR',
            NAMELSAD: 'Fayetteville-Springdale-Rogers, AR Metro Area',
            LSAD: 'M1',
            ALAND: 6794625128,
            AWATER: 130213984,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.530405, 32.410161, -93.147058, 33.0187422898925],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.5209925078522, 33.0187422898925],
                    [-93.4905581209888, 33.0186347572102],
                    [-93.377134, 33.018234],
                    [-93.2386468140122, 33.0180159427056],
                    [-93.237744, 32.715973],
                    [-93.173803, 32.63364],
                    [-93.178751, 32.584808],
                    [-93.147058, 32.45534],
                    [-93.237242, 32.454349],
                    [-93.236922, 32.410161],
                    [-93.372722, 32.41019],
                    [-93.44115, 32.410468],
                    [-93.44486700000002, 32.761395],
                    [-93.473797, 32.783172],
                    [-93.448039, 32.866321],
                    [-93.530405, 32.975397],
                    [-93.5209925078522, 33.0187422898925],
                ],
            ],
        },
        properties: {
            CSAFP: '508',
            CBSAFP: '33380',
            AFFGEOID: '310M600US33380',
            GEOID: '33380',
            NAME: 'Minden, LA',
            NAMELSAD: 'Minden, LA Micro Area',
            LSAD: 'M2',
            ALAND: 1536614989,
            AWATER: 56020319,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-122.19216200000001, 36.196848, -120.59656199999999, 37.484537],
            type: 'Polygon',
            coordinates: [
                [
                    [-122.19040200000002, 37.431472],
                    [-122.144933, 37.4582],
                    [-122.08147300000002, 37.477838],
                    [-122.05967299999999, 37.464087],
                    [-121.92504100000001, 37.454186],
                    [-121.85576200000001, 37.484537],
                    [-121.47264800000002, 37.48217],
                    [-121.40969300000002, 37.382013],
                    [-121.45905100000002, 37.282739],
                    [-121.404636, 37.155989],
                    [-121.28227099999998, 37.183675],
                    [-121.226804, 37.134774],
                    [-121.24588699999998, 36.983036],
                    [-121.21540599999999, 36.961248],
                    [-121.234683, 36.926894],
                    [-121.141523, 36.836656],
                    [-120.91873100000001, 36.740381],
                    [-120.597155, 36.48823500000001],
                    [-120.59656199999999, 36.328488],
                    [-120.67858200000002, 36.267319],
                    [-120.75833999999999, 36.308604],
                    [-120.71769599999999, 36.260012],
                    [-120.71842600000001, 36.196848],
                    [-120.76148300000001, 36.20358],
                    [-120.86508, 36.292578],
                    [-120.920551, 36.311012],
                    [-121.02736200000001, 36.26008],
                    [-121.040797, 36.323969],
                    [-121.193465, 36.445751],
                    [-121.24409100000001, 36.506911],
                    [-121.311788, 36.502736],
                    [-121.318373, 36.610026],
                    [-121.35009200000002, 36.648357],
                    [-121.46808599999999, 36.685518],
                    [-121.45134, 36.724793],
                    [-121.597698, 36.83735],
                    [-121.644001, 36.893996],
                    [-121.581354, 36.899152],
                    [-121.738627, 36.990085],
                    [-121.80907600000002, 37.069301],
                    [-121.84629, 37.09702],
                    [-121.99109, 37.14427],
                    [-122.15227800000001, 37.286055000000005],
                    [-122.19216200000001, 37.319094],
                    [-122.19040200000002, 37.431472],
                ],
            ],
        },
        properties: {
            CSAFP: '488',
            CBSAFP: '41940',
            AFFGEOID: '310M600US41940',
            GEOID: '41940',
            NAME: 'San Jose-Sunnyvale-Santa Clara, CA',
            NAMELSAD: 'San Jose-Sunnyvale-Santa Clara, CA Metro Area',
            LSAD: 'M1',
            ALAND: 6940483805,
            AWATER: 38315150,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.39035, 38.572433, -75.896774, 38.944102],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.379739, 38.788314],
                    [-76.310081, 38.796846],
                    [-76.271575, 38.851771],
                    [-76.219328, 38.812371],
                    [-76.19109, 38.82966],
                    [-76.196868759948, 38.8557419634208],
                    [-76.103512, 38.882308],
                    [-76.09733300000002, 38.944102],
                    [-75.94933500000002, 38.918333],
                    [-75.896774, 38.815109],
                    [-76.014427, 38.726463],
                    [-75.945248, 38.674109],
                    [-76.027717, 38.572433],
                    [-76.1701618745873, 38.6408420471501],
                    [-76.175159, 38.673236],
                    [-76.200334, 38.670774],
                    [-76.238725, 38.712845],
                    [-76.275015, 38.712714],
                    [-76.322418, 38.679304],
                    [-76.347998, 38.686234],
                    [-76.340543, 38.730338],
                    [-76.39035, 38.757004],
                    [-76.379739, 38.788314],
                ],
            ],
        },
        properties: {
            CSAFP: '548',
            CBSAFP: '20660',
            AFFGEOID: '310M600US20660',
            GEOID: '20660',
            NAME: 'Easton, MD',
            NAMELSAD: 'Easton, MD Micro Area',
            LSAD: 'M2',
            ALAND: 695561637,
            AWATER: 539363457,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.6929463837093, 34.6300306785915, -79.330825, 35.043639],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.6929463837093, 34.8049623069232],
                    [-79.61404500000002, 34.815309],
                    [-79.561856, 34.91035],
                    [-79.573423, 34.99221000000001],
                    [-79.458751, 35.043639],
                    [-79.353124, 34.944242],
                    [-79.347868, 34.838553],
                    [-79.330825, 34.774078],
                    [-79.4617910310013, 34.6300306785915],
                    [-79.675299, 34.804744],
                    [-79.6929463837093, 34.8049623069232],
                ],
            ],
        },
        properties: {
            CSAFP: '246',
            CBSAFP: '29900',
            AFFGEOID: '310M600US29900',
            GEOID: '29900',
            NAME: 'Laurinburg, NC',
            NAMELSAD: 'Laurinburg, NC Micro Area',
            LSAD: 'M2',
            ALAND: 826569986,
            AWATER: 3842049,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.092043, 34.593528, -95.34940900000001, 35.296019],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.092001, 34.767479],
                    [-96.088792, 35.049875],
                    [-95.983077, 35.151695],
                    [-95.907966, 35.143494],
                    [-95.870663, 35.185986],
                    [-95.75597, 35.177198],
                    [-95.615664, 35.249698],
                    [-95.602342, 35.220739],
                    [-95.45125, 35.296019],
                    [-95.455018, 35.160381],
                    [-95.349525, 35.160021],
                    [-95.34940900000001, 35.05852],
                    [-95.507738, 35.029265],
                    [-95.514475, 34.680718],
                    [-95.514511, 34.594153],
                    [-95.67181, 34.593732],
                    [-95.881987, 34.593528],
                    [-95.882196, 34.68053400000001],
                    [-96.092043, 34.680556],
                    [-96.092001, 34.767479],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '32540',
            AFFGEOID: '310M600US32540',
            GEOID: '32540',
            NAME: 'McAlester, OK',
            NAMELSAD: 'McAlester, OK Micro Area',
            LSAD: 'M2',
            ALAND: 3381287299,
            AWATER: 187417567,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-121.580022, 37.481783, -120.920993, 38.300252],
            type: 'Polygon',
            coordinates: [
                [
                    [-121.580022, 38.094414],
                    [-121.472138, 38.259659],
                    [-121.448142, 38.255008],
                    [-121.398193, 38.227427],
                    [-121.28226699999999, 38.249836],
                    [-121.22353700000001, 38.244463],
                    [-121.027084, 38.300252],
                    [-120.99549700000001, 38.225402],
                    [-120.94211300000002, 38.096328],
                    [-120.92644900000002, 38.077421],
                    [-120.920993, 37.737947],
                    [-120.99569600000001, 37.760178],
                    [-121.11004900000002, 37.742128],
                    [-121.241219, 37.664009],
                    [-121.471925, 37.481783],
                    [-121.556655, 37.542732],
                    [-121.556959, 37.743051],
                    [-121.55699700000001, 37.816488],
                    [-121.53416699999998, 37.849023],
                    [-121.580022, 38.094414],
                ],
            ],
        },
        properties: {
            CSAFP: '488',
            CBSAFP: '44700',
            AFFGEOID: '310M600US44700',
            GEOID: '44700',
            NAME: 'Stockton, CA',
            NAMELSAD: 'Stockton, CA Metro Area',
            LSAD: 'M1',
            ALAND: 3606222503,
            AWATER: 89027654,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.555804, 35.515039, -78.4572778962559, 36.542113],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.542428, 35.843303],
                    [-79.237295, 35.844247],
                    [-79.249538, 35.87681],
                    [-79.268243, 35.909182],
                    [-79.257952, 36.243445],
                    [-79.153997, 36.241875],
                    [-79.1383358988714, 36.5416382700559],
                    [-78.942009, 36.542113],
                    [-78.7962739551405, 36.54175949358161],
                    [-78.7341228011172, 36.5416087348408],
                    [-78.509965, 36.541065],
                    [-78.4572778962559, 36.5414487083995],
                    [-78.497783, 36.514477],
                    [-78.511224, 36.454808],
                    [-78.496614, 36.175199],
                    [-78.544812, 36.080846],
                    [-78.546414, 36.021826],
                    [-78.683224, 36.074484],
                    [-78.751273, 36.070834],
                    [-78.699316, 36.011448],
                    [-78.75821, 35.921558000000005],
                    [-78.806756, 35.922925],
                    [-78.82996500000002, 35.866978],
                    [-78.906071, 35.868096],
                    [-78.90942500000001, 35.842937],
                    [-78.936271, 35.771898],
                    [-78.995048, 35.610166],
                    [-78.914732, 35.583682],
                    [-78.96964, 35.521669],
                    [-79.117096, 35.628164],
                    [-79.24466300000002, 35.569116],
                    [-79.350073, 35.518002],
                    [-79.555804, 35.515039],
                    [-79.542428, 35.843303],
                ],
            ],
        },
        properties: {
            CSAFP: '450',
            CBSAFP: '20500',
            AFFGEOID: '310M600US20500',
            GEOID: '20500',
            NAME: 'Durham-Chapel Hill, NC',
            NAMELSAD: 'Durham-Chapel Hill, NC Metro Area',
            LSAD: 'M1',
            ALAND: 5931500865,
            AWATER: 154277675,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.384602, 33.341119, -95.8448785340707, 33.875099],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.384602, 33.397826],
                    [-96.3796608259521, 33.715530908566],
                    [-96.363135, 33.694215],
                    [-96.30738900000001, 33.735005],
                    [-96.277269, 33.769735],
                    [-96.229023, 33.748021],
                    [-96.173025, 33.80056],
                    [-96.15163, 33.831946],
                    [-96.063924, 33.841523],
                    [-95.935325, 33.875099],
                    [-95.88749100000001, 33.863856],
                    [-95.8448785340707, 33.860421904719],
                    [-95.85505, 33.827939],
                    [-95.857738, 33.461722],
                    [-95.85872300000001, 33.40953],
                    [-96.295413, 33.351936],
                    [-96.384495, 33.341119],
                    [-96.384602, 33.397826],
                ],
            ],
        },
        properties: {
            CSAFP: '206',
            CBSAFP: '14300',
            AFFGEOID: '310M600US14300',
            GEOID: '14300',
            NAME: 'Bonham, TX',
            NAMELSAD: 'Bonham, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2307251052,
            AWATER: 20847065,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-70.726088944807, 41.5129296626746, -69.928261, 42.092907],
            type: 'Polygon',
            coordinates: [
                [
                    [-70.698197, 41.559002],
                    [-70.695392, 41.602546],
                    [-70.7159798446688, 41.6140132687642],
                    [-70.690964, 41.660457],
                    [-70.620785, 41.74753],
                    [-70.632555, 41.762904],
                    [-70.565363, 41.786669],
                    [-70.5364072541248, 41.8116341457464],
                    [-70.494048, 41.773883],
                    [-70.441718, 41.752898],
                    [-70.323819, 41.736058],
                    [-70.272289, 41.721346],
                    [-70.216073, 41.742981],
                    [-70.121978, 41.758841],
                    [-70.024734, 41.787364],
                    [-70.003842, 41.80852],
                    [-70.006111, 41.852396],
                    [-70.06901, 41.884924],
                    [-70.074006, 41.93865],
                    [-70.083775, 42.012041],
                    [-70.15076, 42.026569],
                    [-70.190834, 42.020028],
                    [-70.245385, 42.063733],
                    [-70.189305, 42.082337],
                    [-70.1772584572585, 42.0848652837952],
                    [-70.138942, 42.092907],
                    [-70.049382, 42.064689],
                    [-69.994136, 41.999258],
                    [-69.9802313615877, 41.9459864602333],
                    [-69.974781, 41.925105],
                    [-69.935952, 41.809422],
                    [-69.928261, 41.6917],
                    [-69.931129, 41.622659],
                    [-69.964982, 41.55111],
                    [-70.011229, 41.543931],
                    [-70.011961, 41.619797],
                    [-70.007011, 41.671579],
                    [-70.055523, 41.664843],
                    [-70.158621, 41.650438],
                    [-70.269687, 41.617775],
                    [-70.321588, 41.630508],
                    [-70.400581, 41.606382],
                    [-70.445289, 41.591815],
                    [-70.476256, 41.558502],
                    [-70.559689, 41.54833],
                    [-70.654104, 41.519025],
                    [-70.6690583666005, 41.5129296626746],
                    [-70.697498, 41.527158],
                    [-70.726088944807, 41.5432369319176],
                    [-70.698197, 41.559002],
                ],
            ],
        },
        properties: {
            CSAFP: '148',
            CBSAFP: '12700',
            AFFGEOID: '310M600US12700',
            GEOID: '12700',
            NAME: 'Barnstable Town, MA',
            NAMELSAD: 'Barnstable Town, MA Metro Area',
            LSAD: 'M1',
            ALAND: 1021007945,
            AWATER: 2360328047,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.448825, 40.566898, -85.068479, 40.917823],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.448825, 40.653607],
                    [-85.33466700000001, 40.654413],
                    [-85.33603, 40.917082],
                    [-85.073861, 40.917823],
                    [-85.068479, 40.56824],
                    [-85.201146, 40.567242],
                    [-85.390539, 40.566898],
                    [-85.447014, 40.566929],
                    [-85.448825, 40.653607],
                ],
            ],
        },
        properties: {
            CSAFP: '258',
            CBSAFP: '14160',
            AFFGEOID: '310M600US14160',
            GEOID: '14160',
            NAME: 'Bluffton, IN',
            NAMELSAD: 'Bluffton, IN Micro Area',
            LSAD: 'M2',
            ALAND: 953341275,
            AWATER: 5596377,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.298792, 41.860069, -90.89802, 42.299063],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.298792, 42.297527],
                    [-92.064766, 42.297259],
                    [-91.831089, 42.299063],
                    [-91.596981, 42.296408],
                    [-91.363263, 42.296445],
                    [-91.130079, 42.295761],
                    [-90.89802, 42.295236],
                    [-90.898373, 42.033548],
                    [-90.898484, 41.946245],
                    [-91.365877, 41.94741],
                    [-91.366221, 41.860069],
                    [-91.831379, 41.861851],
                    [-92.298164, 41.862779],
                    [-92.298792, 42.297527],
                ],
            ],
        },
        properties: {
            CSAFP: '168',
            CBSAFP: '16300',
            AFFGEOID: '310M600US16300',
            GEOID: '16300',
            NAME: 'Cedar Rapids, IA',
            NAMELSAD: 'Cedar Rapids, IA Metro Area',
            LSAD: 'M1',
            ALAND: 5202714119,
            AWATER: 29350978,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.366101, 36.5876942581124, -83.507886, 37.358075],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.358024, 36.9594],
                    [-84.303016, 37.038396],
                    [-84.289076, 37.15174],
                    [-84.254315, 37.239082],
                    [-84.137898, 37.319759],
                    [-84.124945, 37.335626],
                    [-84.00560600000001, 37.25334300000001],
                    [-83.943718, 37.250551],
                    [-83.901574, 37.25374],
                    [-83.784975, 37.348717],
                    [-83.653303, 37.358075],
                    [-83.526907, 37.258529],
                    [-83.507886, 37.235877],
                    [-83.551303, 37.16751],
                    [-83.553101, 37.079281],
                    [-83.509083, 36.938509],
                    [-83.589521, 36.955786],
                    [-83.566142, 36.931525],
                    [-83.798248, 36.785197],
                    [-83.877503, 36.687259],
                    [-83.956319, 36.645433],
                    [-83.9307607839439, 36.5876942581124],
                    [-83.98761095068281, 36.5895922860725],
                    [-83.98784200000001, 36.5896],
                    [-84.2271939619983, 36.5921795123551],
                    [-84.307598, 36.676809],
                    [-84.35596700000002, 36.811019],
                    [-84.318033, 36.833763],
                    [-84.297416, 36.945931],
                    [-84.366101, 36.943423],
                    [-84.358024, 36.9594],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '30940',
            AFFGEOID: '310M600US30940',
            GEOID: '30940',
            NAME: 'London, KY',
            NAMELSAD: 'London, KY Micro Area',
            LSAD: 'M2',
            ALAND: 4474005634,
            AWATER: 52498403,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-74.0274312115718, 44.429699, -73.33443, 45.01084],
            type: 'Polygon',
            coordinates: [
                [
                    [-74.0274312115718, 44.9973653643332],
                    [-73.874597, 45.001223],
                    [-73.639718, 45.003464],
                    [-73.343124, 45.01084],
                    [-73.34474, 44.970468],
                    [-73.338979, 44.917681],
                    [-73.37982200000002, 44.857037],
                    [-73.365678, 44.826451],
                    [-73.33443, 44.802188],
                    [-73.3358066361073, 44.799157043087206],
                    [-73.357671, 44.751018],
                    [-73.36556, 44.700297],
                    [-73.389966, 44.61962],
                    [-73.367275, 44.567545],
                    [-73.3636601533155, 44.5635384629765],
                    [-73.34798323242651, 44.546162853597],
                    [-73.463838, 44.537681],
                    [-73.496604, 44.486081],
                    [-73.669281, 44.441355],
                    [-73.909687, 44.429699],
                    [-73.966148, 44.709118],
                    [-73.986382, 44.707773],
                    [-74.0274312115718, 44.9973653643332],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '38460',
            AFFGEOID: '310M600US38460',
            GEOID: '38460',
            NAME: 'Plattsburgh, NY',
            NAMELSAD: 'Plattsburgh, NY Micro Area',
            LSAD: 'M2',
            ALAND: 2687763026,
            AWATER: 206582000,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-71.862772, 41.146339, -70.8039626132079, 42.095539],
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [-71.631472, 41.166678],
                        [-71.593342, 41.237428],
                        [-71.54541, 41.24273],
                        [-71.534084, 41.181862],
                        [-71.51921, 41.149624],
                        [-71.5937, 41.146339],
                        [-71.631472, 41.166678],
                    ],
                ],
                [
                    [
                        [-71.860513, 41.320248],
                        [-71.835951, 41.353935],
                        [-71.839649, 41.412119],
                        [-71.797683, 41.416709],
                        [-71.7976736925498, 41.4169104184967],
                        [-71.7893586727423, 41.5968521603427],
                        [-71.789356, 41.59691],
                        [-71.789464590839, 41.6400171907069],
                        [-71.789678, 41.724734],
                        [-71.7896954316824, 41.7251982448091],
                        [-71.792767, 41.807001],
                        [-71.799242, 42.008065],
                        [-71.6066944190753, 42.0131050585708],
                        [-71.591096527631, 42.0135133435572],
                        [-71.559439, 42.014342],
                        [-71.4982240131247, 42.0158741110185],
                        [-71.4580809945561, 42.0168788251287],
                        [-71.381401, 42.018798],
                        [-71.3814313468931, 41.9850841191295],
                        [-71.353265, 41.98954],
                        [-71.28826500000001, 42.014462],
                        [-71.16868100000002, 42.061161],
                        [-71.138607, 42.072775],
                        [-71.080483, 42.095539],
                        [-71.054718, 41.985057],
                        [-70.999773, 41.929671],
                        [-70.997397, 41.918399],
                        [-70.987256, 41.905808],
                        [-70.973717, 41.860879],
                        [-71.03657, 41.816525],
                        [-71.014591, 41.799568],
                        [-71.026288, 41.778888],
                        [-70.921782, 41.791244],
                        [-70.907184, 41.763543],
                        [-70.88497, 41.75611500000001],
                        [-70.865003, 41.700164],
                        [-70.839175, 41.614761],
                        [-70.8039626132079, 41.6015155410003],
                        [-70.82191, 41.582841],
                        [-70.853121, 41.587321],
                        [-70.8576236660334, 41.5865120924635],
                        [-70.9090917764468, 41.5772658054655],
                        [-70.910165, 41.577073],
                        [-70.941785, 41.540121],
                        [-70.981708, 41.51007],
                        [-71.0203212322244, 41.502159475917],
                        [-71.035514, 41.499047],
                        [-71.085663, 41.509292],
                        [-71.12057, 41.497448],
                        [-71.140224, 41.485855],
                        [-71.19302, 41.457931],
                        [-71.2126727591074, 41.4666017059031],
                        [-71.2398044238189, 41.4785720701705],
                        [-71.245992, 41.481302],
                        [-71.285639, 41.487805],
                        [-71.312694, 41.451402],
                        [-71.351096, 41.450802],
                        [-71.389284, 41.460605],
                        [-71.4172127867938, 41.4560313135425],
                        [-71.428652, 41.454158],
                        [-71.455371, 41.407962],
                        [-71.483295, 41.371722],
                        [-71.555381, 41.373316],
                        [-71.624505, 41.360870000000006],
                        [-71.701631, 41.336968],
                        [-71.7299497041705, 41.3331970310328],
                        [-71.785957, 41.325739],
                        [-71.862772, 41.309791000000004],
                        [-71.860513, 41.320248],
                    ],
                ],
            ],
        },
        properties: {
            CSAFP: '148',
            CBSAFP: '39300',
            AFFGEOID: '310M600US39300',
            GEOID: '39300',
            NAME: 'Providence-Warwick, RI-MA',
            NAMELSAD: 'Providence-Warwick, RI-MA Metro Area',
            LSAD: 'M1',
            ALAND: 4110317164,
            AWATER: 1681144321,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.495331, 37.38093, -86.62295, 37.99715],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.495331, 37.647547],
                    [-87.472811, 37.664869],
                    [-87.408547, 37.683563],
                    [-87.394093, 37.723995],
                    [-87.271608, 37.78012],
                    [-87.323402, 37.819239],
                    [-87.304057452442, 37.8934325491223],
                    [-87.270387407739, 37.87542282659],
                    [-87.25525, 37.867326],
                    [-87.180063, 37.841375],
                    [-87.137502, 37.807264],
                    [-87.105614, 37.767631],
                    [-87.057836, 37.827457],
                    [-87.043049, 37.875049],
                    [-87.010315, 37.91966800000001],
                    [-86.9777414251102, 37.9256992083727],
                    [-86.927747, 37.934956],
                    [-86.875874, 37.97077],
                    [-86.8135795707459, 37.9960671327985],
                    [-86.810913, 37.99715],
                    [-86.779993, 37.956522],
                    [-86.722247, 37.892648],
                    [-86.658374, 37.869376],
                    [-86.6467093891411, 37.8649113991803],
                    [-86.677873, 37.792848],
                    [-86.62295, 37.656565],
                    [-86.638935, 37.661877],
                    [-86.823429, 37.737754],
                    [-86.817783, 37.673679],
                    [-86.94876, 37.630548],
                    [-87.03826, 37.560803],
                    [-87.135629, 37.485153],
                    [-87.099803, 37.416042],
                    [-87.210958, 37.38093],
                    [-87.295965, 37.391819],
                    [-87.328543, 37.398462],
                    [-87.37514500000002, 37.569988],
                    [-87.482702, 37.600914],
                    [-87.495331, 37.647547],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '36980',
            AFFGEOID: '310M600US36980',
            GEOID: '36980',
            NAME: 'Owensboro, KY',
            NAMELSAD: 'Owensboro, KY Metro Area',
            LSAD: 'M1',
            ALAND: 2327340283,
            AWATER: 85568964,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.712288, 40.214253, -81.264744, 40.66692],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.709132, 40.444775],
                    [-81.66982, 40.443987],
                    [-81.64911, 40.634525],
                    [-81.459019, 40.66692],
                    [-81.317739, 40.651579],
                    [-81.321659, 40.56664800000001],
                    [-81.264744, 40.565042],
                    [-81.268434, 40.476714],
                    [-81.269951, 40.433486],
                    [-81.275319, 40.303434],
                    [-81.334601, 40.304316],
                    [-81.338057, 40.214253],
                    [-81.622683, 40.221308],
                    [-81.61603, 40.368118],
                    [-81.712288, 40.370804],
                    [-81.709132, 40.444775],
                ],
            ],
        },
        properties: {
            CSAFP: '184',
            CBSAFP: '35420',
            AFFGEOID: '310M600US35420',
            GEOID: '35420',
            NAME: 'New Philadelphia-Dover, OH',
            NAMELSAD: 'New Philadelphia-Dover, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1469575828,
            AWATER: 10363325,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-77.137425, 39.720062, -76.2396837411146, 40.226328],
            type: 'Polygon',
            coordinates: [
                [
                    [-77.028784, 40.147797],
                    [-76.905875, 40.165723],
                    [-76.902886, 40.22460300000001],
                    [-76.859023, 40.226328],
                    [-76.839468, 40.22161500000001],
                    [-76.824696, 40.202319],
                    [-76.800585, 40.200111],
                    [-76.752683, 40.175803],
                    [-76.72162, 40.12007],
                    [-76.663825, 40.063227],
                    [-76.644508, 40.058069],
                    [-76.534524, 40.051348],
                    [-76.51719000000001, 40.018],
                    [-76.47358, 39.947352],
                    [-76.2396837411146, 39.7216419899246],
                    [-76.41898, 39.721526000000004],
                    [-76.5694491257313, 39.72145857075271],
                    [-76.715771, 39.721393],
                    [-76.7870971997576, 39.7210481461113],
                    [-76.991062, 39.720062],
                    [-76.9993180263332, 39.7200676723526],
                    [-77.000312, 39.831219],
                    [-76.954932, 39.85685],
                    [-76.966202, 39.935192],
                    [-77.018648, 40.013947],
                    [-77.137425, 40.069945],
                    [-77.028784, 40.147797],
                ],
            ],
        },
        properties: {
            CSAFP: '276',
            CBSAFP: '49620',
            AFFGEOID: '310M600US49620',
            GEOID: '49620',
            NAME: 'York-Hanover, PA',
            NAMELSAD: 'York-Hanover, PA Metro Area',
            LSAD: 'M1',
            ALAND: 2342295972,
            AWATER: 16503210,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-107.543526, 42.431094, -106.073869, 43.501362],
            type: 'Polygon',
            coordinates: [
                [
                    [-107.543526, 42.781558000000004],
                    [-107.501425, 42.781458],
                    [-107.502327, 43.12806],
                    [-107.517031, 43.472659],
                    [-107.534897, 43.501362],
                    [-107.110734, 43.500286],
                    [-107.090251, 43.49239],
                    [-106.078068, 43.494471],
                    [-106.073869, 43.47257],
                    [-106.077069, 42.777965],
                    [-106.075852, 42.433218],
                    [-106.654781, 42.431094],
                    [-107.522923, 42.43435600000001],
                    [-107.542058, 42.44184],
                    [-107.543526, 42.781558000000004],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '16220',
            AFFGEOID: '310M600US16220',
            GEOID: '16220',
            NAME: 'Casper, WY',
            NAMELSAD: 'Casper, WY Metro Area',
            LSAD: 'M1',
            ALAND: 13831928341,
            AWATER: 90969261,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.419836, 40.433795, -82.858302, 40.705019],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.419836, 40.686823],
                    [-83.310556, 40.687109],
                    [-83.304687, 40.702006],
                    [-83.11136, 40.702915],
                    [-82.858302, 40.705019],
                    [-82.859753, 40.646395],
                    [-82.957715, 40.635653],
                    [-82.958401, 40.490664],
                    [-83.020798, 40.433795],
                    [-83.248595, 40.443989],
                    [-83.24829800000002, 40.500997],
                    [-83.267767, 40.506906],
                    [-83.41867, 40.505229],
                    [-83.416307, 40.526528],
                    [-83.419836, 40.686823],
                ],
            ],
        },
        properties: {
            CSAFP: '198',
            CBSAFP: '32020',
            AFFGEOID: '310M600US32020',
            GEOID: '32020',
            NAME: 'Marion, OH',
            NAMELSAD: 'Marion, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1045853061,
            AWATER: 908577,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.6832322084994, 45.713063, -87.615597, 46.246653],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.679132, 46.013538],
                    [-88.65776, 45.989287],
                    [-88.613063, 45.990627],
                    [-88.59386, 46.015132],
                    [-88.526673, 46.020822],
                    [-88.40986400000001, 45.979688],
                    [-88.38018300000002, 45.991654],
                    [-88.30952000000002, 45.959369],
                    [-88.246307, 45.96298300000001],
                    [-88.178008, 45.947111],
                    [-88.1168565373761, 45.9228112422627],
                    [-88.117407, 46.246618],
                    [-87.615597, 46.246653],
                    [-87.617091, 45.986014],
                    [-87.617396, 45.898846],
                    [-87.696946, 45.898842],
                    [-87.697159, 45.72296],
                    [-87.8330494680353, 45.7227525753469],
                    [-87.879812, 45.754843],
                    [-87.96697, 45.764021],
                    [-87.995876, 45.795435],
                    [-88.048514, 45.782549],
                    [-88.057010465949, 45.7849770301437],
                    [-88.059246, 45.713063],
                    [-88.425302, 45.722426],
                    [-88.675821, 45.7229],
                    [-88.6832322084994, 46.0144656703203],
                    [-88.679132, 46.013538],
                ],
            ],
        },
        properties: {
            CSAFP: '361',
            CBSAFP: '27020',
            AFFGEOID: '310M600US27020',
            GEOID: '27020',
            NAME: 'Iron Mountain, MI-WI',
            NAMELSAD: 'Iron Mountain, MI-WI Micro Area',
            LSAD: 'M2',
            ALAND: 3235077589,
            AWATER: 64617589,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.8042532576115, 41.165524, -84.228222, 41.42781],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.8042532576115, 41.4260497115499],
                    [-84.341664, 41.427568],
                    [-84.228454, 41.42781],
                    [-84.228222, 41.165862],
                    [-84.341602, 41.165524],
                    [-84.341601, 41.20903],
                    [-84.457059, 41.209434],
                    [-84.457217, 41.253485],
                    [-84.8036449822556, 41.2525616835482],
                    [-84.8037035726169, 41.2712583305706],
                    [-84.804133, 41.408292],
                    [-84.8042532576115, 41.4260497115499],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '19580',
            AFFGEOID: '310M600US19580',
            GEOID: '19580',
            NAME: 'Defiance, OH',
            NAMELSAD: 'Defiance, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1065623072,
            AWATER: 7100811,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.959839, 36.062746, -88.5163580599794, 36.5019683222569],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.949163, 36.410099],
                    [-88.81642000000001, 36.410732],
                    [-88.8271780363405, 36.5019683222569],
                    [-88.8167641573481, 36.5019514318132],
                    [-88.5163580599794, 36.5014641981633],
                    [-88.530322, 36.152023],
                    [-88.532233, 36.115416],
                    [-88.655488, 36.117338],
                    [-88.692709, 36.062746],
                    [-88.863298, 36.137639],
                    [-88.959839, 36.222834000000006],
                    [-88.949163, 36.410099],
                ],
            ],
        },
        properties: {
            CSAFP: '362',
            CBSAFP: '32280',
            AFFGEOID: '310M600US32280',
            GEOID: '32280',
            NAME: 'Martin, TN',
            NAMELSAD: 'Martin, TN Micro Area',
            LSAD: 'M2',
            ALAND: 1503107797,
            AWATER: 3707114,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.67804, 40.196644, -76.151215, 40.555157],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.67804, 40.474717000000005],
                    [-76.535414, 40.555157],
                    [-76.440181, 40.495952],
                    [-76.205528, 40.360661],
                    [-76.151215, 40.315971],
                    [-76.307578, 40.255066],
                    [-76.566339, 40.196644],
                    [-76.67804, 40.474717000000005],
                ],
            ],
        },
        properties: {
            CSAFP: '276',
            CBSAFP: '30140',
            AFFGEOID: '310M600US30140',
            GEOID: '30140',
            NAME: 'Lebanon, PA',
            NAMELSAD: 'Lebanon, PA Metro Area',
            LSAD: 'M1',
            ALAND: 937148912,
            AWATER: 1722125,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-123.50591699999998, 47.082646, -122.801012, 47.606308],
            type: 'Polygon',
            coordinates: [
                [
                    [-123.50591699999998, 47.604615],
                    [-122.950621, 47.606308],
                    [-123.038404, 47.520236],
                    [-122.801012, 47.520406],
                    [-122.801199, 47.403578],
                    [-122.852917, 47.256462],
                    [-122.820576, 47.19422],
                    [-122.902417, 47.157381],
                    [-122.936283, 47.189457],
                    [-122.94704600000001, 47.185641],
                    [-122.95281900000002, 47.170103],
                    [-122.969123, 47.161525],
                    [-123.00243500000002, 47.151302],
                    [-123.010456, 47.143326],
                    [-123.015389, 47.123792],
                    [-123.034109, 47.115041],
                    [-123.060583, 47.114025],
                    [-123.073654, 47.106242],
                    [-123.07495700000001, 47.090844000000004],
                    [-123.09678400000001, 47.085354],
                    [-123.20189100000002, 47.085059],
                    [-123.48894299999999, 47.082646],
                    [-123.50502100000001, 47.258535],
                    [-123.50591699999998, 47.51771600000001],
                    [-123.50591699999998, 47.604615],
                ],
            ],
        },
        properties: {
            CSAFP: '500',
            CBSAFP: '43220',
            AFFGEOID: '310M600US43220',
            GEOID: '43220',
            NAME: 'Shelton, WA',
            NAMELSAD: 'Shelton, WA Micro Area',
            LSAD: 'M2',
            ALAND: 2485218918,
            AWATER: 236969801,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.5197051125992, 39.720776, -78.973814, 41.172865],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.5197051125992, 40.8513367786854],
                    [-80.15853, 40.855096],
                    [-80.166472, 41.000928],
                    [-80.098294, 41.071054],
                    [-80.082007, 41.085033],
                    [-79.999779, 41.171861],
                    [-79.885546, 41.172463],
                    [-79.694984, 41.172865],
                    [-79.689332, 41.169471],
                    [-79.620787, 40.975167],
                    [-79.28499800000002, 41.009295],
                    [-79.215214, 41.050515],
                    [-79.210722, 41.032421],
                    [-79.21531, 40.911346],
                    [-79.215235, 40.776016],
                    [-79.450176, 40.530149],
                    [-79.453765, 40.48425],
                    [-79.373741, 40.453853],
                    [-79.155789, 40.411765],
                    [-79.10065, 40.368717],
                    [-79.016617, 40.420279],
                    [-78.973814, 40.395421],
                    [-78.974025, 40.394902],
                    [-78.998614, 40.355878],
                    [-79.056053, 40.284861],
                    [-79.183619, 40.110686],
                    [-79.293884, 40.040446],
                    [-79.417558, 39.853776],
                    [-79.356757, 39.781477],
                    [-79.409959, 39.734978],
                    [-79.3924584050224, 39.7214393586401],
                    [-79.47666200000002, 39.721078],
                    [-79.763774, 39.720776],
                    [-79.9160173560378, 39.7210559335188],
                    [-79.923325, 39.921547],
                    [-79.998014, 39.983322],
                    [-80.301396, 40.020559],
                    [-80.5191607943145, 39.9622000525219],
                    [-80.51912, 40.01641],
                    [-80.5190843704566, 40.1596719951181],
                    [-80.519039, 40.34210100000001],
                    [-80.5190296911941, 40.3996410563407],
                    [-80.5190171173474, 40.4773631462371],
                    [-80.518991, 40.638801],
                    [-80.5197051125992, 40.8513367786854],
                ],
            ],
        },
        properties: {
            CSAFP: '430',
            CBSAFP: '38300',
            AFFGEOID: '310M600US38300',
            GEOID: '38300',
            NAME: 'Pittsburgh, PA',
            NAMELSAD: 'Pittsburgh, PA Metro Area',
            LSAD: 'M1',
            ALAND: 13682742222,
            AWATER: 156664130,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.888484, 44.856458, -83.265896, 45.207103993148],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.888482, 44.856635],
                    [-83.881223, 45.202071],
                    [-83.3852110792565, 45.207103993148],
                    [-83.315924, 45.139992],
                    [-83.265896, 45.026844],
                    [-83.340257, 45.041545],
                    [-83.399255, 45.070364],
                    [-83.442052, 45.051056],
                    [-83.435822, 45.000012],
                    [-83.438856, 44.940843],
                    [-83.352815, 44.886164],
                    [-83.320503, 44.880571],
                    [-83.31626610857661, 44.8585914505609],
                    [-83.888484, 44.856458],
                    [-83.888482, 44.856635],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '10980',
            AFFGEOID: '310M600US10980',
            GEOID: '10980',
            NAME: 'Alpena, MI',
            NAMELSAD: 'Alpena, MI Micro Area',
            LSAD: 'M2',
            ALAND: 1481205162,
            AWATER: 2908633230,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.313251, 34.681163, -96.619655, 36.164663],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.313153, 35.725161],
                    [-98.207104, 35.725187],
                    [-97.674026, 35.72597],
                    [-97.675617, 36.164663],
                    [-97.460792, 36.164462],
                    [-97.371722, 36.164285],
                    [-97.354132, 36.159072],
                    [-97.35387, 35.984934],
                    [-97.32362, 36.010877],
                    [-97.203592, 35.944795],
                    [-97.140583, 35.941991],
                    [-96.619655, 35.94157100000001],
                    [-96.620828, 35.639005],
                    [-96.624681, 35.462707],
                    [-97.14149, 35.463977],
                    [-97.14211400000002, 35.37686],
                    [-97.142349, 34.92817600000001],
                    [-97.01593, 34.907348],
                    [-96.930586, 34.964364],
                    [-96.932436, 34.854496],
                    [-97.668099, 34.855164],
                    [-97.668512, 34.68134],
                    [-98.089097, 34.681163],
                    [-98.088978, 34.837657],
                    [-98.09312700000001, 34.854988],
                    [-98.096014, 35.377753000000006],
                    [-98.30632600000001, 35.377239],
                    [-98.313251, 35.55152300000001],
                    [-98.313153, 35.725161],
                ],
            ],
        },
        properties: {
            CSAFP: '416',
            CBSAFP: '36420',
            AFFGEOID: '310M600US36420',
            GEOID: '36420',
            NAME: 'Oklahoma City, OK',
            NAMELSAD: 'Oklahoma City, OK Metro Area',
            LSAD: 'M1',
            ALAND: 14275294767,
            AWATER: 181143756,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.655486, 31.913074000000005, -82.181912, 32.35383],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.649104, 32.311496],
                    [-82.40913, 32.353738],
                    [-82.409118, 32.35383],
                    [-82.354603, 32.292475],
                    [-82.232788, 32.318838],
                    [-82.181912, 32.166125],
                    [-82.225042, 31.913074000000005],
                    [-82.40724500000002, 31.949488000000002],
                    [-82.431531, 31.966182],
                    [-82.483131, 31.968961000000004],
                    [-82.480224, 31.954961],
                    [-82.543655, 31.958914000000004],
                    [-82.597671, 32.013979],
                    [-82.644309, 32.294193],
                    [-82.655486, 32.297561],
                    [-82.649104, 32.311496],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '47080',
            AFFGEOID: '310M600US47080',
            GEOID: '47080',
            NAME: 'Vidalia, GA',
            NAMELSAD: 'Vidalia, GA Micro Area',
            LSAD: 'M2',
            ALAND: 1567351925,
            AWATER: 27267606,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.1655541262812, 29.0009301182604, -82.050955, 29.945234],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.1655541262812, 29.288964743454],
                    [-83.06052800000002, 29.349457000000005],
                    [-82.984371, 29.471004],
                    [-82.937329, 29.591196],
                    [-82.954879, 29.585604999999997],
                    [-82.949707, 29.754409],
                    [-82.920608, 29.824143],
                    [-82.893371, 29.826839000000003],
                    [-82.879802, 29.886847],
                    [-82.863266, 29.911050000000003],
                    [-82.800477, 29.932126],
                    [-82.770379, 29.928109],
                    [-82.768445, 29.897007000000002],
                    [-82.658554, 29.830143999999997],
                    [-82.641008, 29.828473000000002],
                    [-82.529705, 29.940880999999997],
                    [-82.510819, 29.945234],
                    [-82.41872800000002, 29.923093],
                    [-82.272563, 29.843011],
                    [-82.133126, 29.835949],
                    [-82.05562500000002, 29.718232000000004],
                    [-82.050955, 29.702569000000004],
                    [-82.055899, 29.471232000000004],
                    [-82.055643, 29.441847000000003],
                    [-82.199639, 29.422172000000003],
                    [-82.211449, 29.484067],
                    [-82.40662, 29.485047999999995],
                    [-82.405478, 29.361096999999997],
                    [-82.403237, 29.215623000000004],
                    [-82.53486, 29.21468],
                    [-82.535591, 29.044855],
                    [-82.612653, 29.009271000000002],
                    [-82.712039, 29.030834],
                    [-82.7555738086957, 29.0009301182604],
                    [-82.759378, 29.006618999999997],
                    [-82.759704, 29.054192000000004],
                    [-82.823659, 29.098902],
                    [-82.798876, 29.114504],
                    [-82.827073, 29.158424999999998],
                    [-82.927107, 29.168907],
                    [-82.996144, 29.178073999999995],
                    [-83.016248, 29.125370999999998],
                    [-83.053207, 29.130839000000005],
                    [-83.078986, 29.196944],
                    [-83.074734, 29.247975],
                    [-83.107477, 29.268889],
                    [-83.1655541262812, 29.288964743454],
                ],
            ],
        },
        properties: {
            CSAFP: '264',
            CBSAFP: '23540',
            AFFGEOID: '310M600US23540',
            GEOID: '23540',
            NAME: 'Gainesville, FL',
            NAMELSAD: 'Gainesville, FL Metro Area',
            LSAD: 'M1',
            ALAND: 6069800012,
            AWATER: 1480026017,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-111.366922, 31.332239, -110.4523, 31.731219000000003],
            type: 'Polygon',
            coordinates: [
                [
                    [-111.366922, 31.521361],
                    [-111.163717, 31.521724000000003],
                    [-111.164183, 31.72589],
                    [-111.05804, 31.72623],
                    [-110.4523, 31.731219000000003],
                    [-110.460752, 31.613883000000005],
                    [-110.45975162749302, 31.333141997042],
                    [-111.074825, 31.332239],
                    [-111.366782684389, 31.4247651231261],
                    [-111.366922, 31.521361],
                ],
            ],
        },
        properties: {
            CSAFP: '536',
            CBSAFP: '35700',
            AFFGEOID: '310M600US35700',
            GEOID: '35700',
            NAME: 'Nogales, AZ',
            NAMELSAD: 'Nogales, AZ Micro Area',
            LSAD: 'M2',
            ALAND: 3201853239,
            AWATER: 3068237,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-120.042866, 46.628283, -118.97350400000002, 47.962025],
            type: 'Polygon',
            coordinates: [
                [
                    [-120.00206500000002, 47.126153],
                    [-120.007074, 47.220133],
                    [-120.00470400000002, 47.319994],
                    [-119.91891500000001, 47.364475],
                    [-119.87576, 47.437492],
                    [-119.533123, 47.441207000000006],
                    [-119.532889, 47.527756],
                    [-119.40519600000002, 47.612636],
                    [-119.320304, 47.626797],
                    [-119.29864900000001, 47.728328],
                    [-119.21241, 47.800695],
                    [-119.212225, 47.887708],
                    [-119.125904, 47.917301],
                    [-119.103873, 47.961763],
                    [-118.982234, 47.962025],
                    [-118.97350400000002, 47.943414],
                    [-118.97977700000001, 47.261709],
                    [-118.982652, 46.911344],
                    [-119.368662, 46.91256200000001],
                    [-119.36942899999998, 46.737695],
                    [-119.36916499999998, 46.67817900000001],
                    [-119.45319000000002, 46.679243],
                    [-119.51138799999998, 46.727687],
                    [-119.61847900000001, 46.647583],
                    [-119.874042, 46.628283],
                    [-119.904046, 46.638101],
                    [-119.973036, 46.737126],
                    [-119.92743600000001, 46.798445],
                    [-119.96612900000001, 46.943757],
                    [-120.042866, 47.073453],
                    [-120.00206500000002, 47.126153],
                ],
            ],
        },
        properties: {
            CSAFP: '393',
            CBSAFP: '34180',
            AFFGEOID: '310M600US34180',
            GEOID: '34180',
            NAME: 'Moses Lake, WA',
            NAMELSAD: 'Moses Lake, WA Micro Area',
            LSAD: 'M2',
            ALAND: 6940003416,
            AWATER: 289811930,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.255202, 46.41970400000001, -87.591498, 48.21065],
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [-88.989529, 46.680119],
                        [-88.864473, 46.679311],
                        [-88.863906, 46.765013],
                        [-88.93248, 46.764787000000005],
                        [-88.9333684513417, 47.033599097856],
                        [-88.924492, 47.042156],
                        [-88.88914, 47.100575],
                        [-88.814834, 47.141399],
                        [-88.69966, 47.204831000000006],
                        [-88.584912, 47.242361],
                        [-88.5129465056361, 47.286107247715],
                        [-88.50078, 47.293503],
                        [-88.418673, 47.371188],
                        [-88.285195, 47.422392],
                        [-88.217822, 47.448738],
                        [-88.085252, 47.468961],
                        [-87.929269, 47.478737],
                        [-87.801184, 47.473301],
                        [-87.68006700000001, 47.455685],
                        [-87.591498, 47.424113],
                        [-87.604704, 47.388625],
                        [-87.80029400000001, 47.392148],
                        [-87.941613, 47.390073],
                        [-87.94336, 47.335899],
                        [-88.016478, 47.306275],
                        [-88.096851, 47.261351],
                        [-88.194218, 47.209242],
                        [-88.20045067593371, 47.199716895982],
                        [-88.23989500000002, 47.139436],
                        [-88.340052, 47.080494],
                        [-88.385606, 47.004522],
                        [-88.4393631859438, 46.9419824250004],
                        [-88.55218500000001, 46.938533],
                        [-88.551943, 46.851795],
                        [-88.678879, 46.851801],
                        [-88.679392, 46.420139],
                        [-88.991236, 46.41970400000001],
                        [-88.989529, 46.680119],
                    ],
                ],
                [
                    [
                        [-89.221332, 47.908069000000005],
                        [-89.179154, 47.93503],
                        [-89.018303, 47.992525],
                        [-88.940886, 48.01959],
                        [-88.893701, 48.03477],
                        [-88.816084, 48.057006],
                        [-88.728198, 48.101914],
                        [-88.631908, 48.148307],
                        [-88.547033, 48.174891],
                        [-88.425162, 48.21065],
                        [-88.427373, 48.166764],
                        [-88.55044, 48.102111],
                        [-88.57917200000001, 48.040758],
                        [-88.718555, 47.995134],
                        [-88.852923, 47.965322],
                        [-88.898986, 47.900685],
                        [-89.044463, 47.85575],
                        [-89.157738, 47.824015],
                        [-89.201812, 47.850243],
                        [-89.255202, 47.876102],
                        [-89.221332, 47.908069000000005],
                    ],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '26340',
            AFFGEOID: '310M600US26340',
            GEOID: '26340',
            NAME: 'Houghton, MI',
            NAMELSAD: 'Houghton, MI Micro Area',
            LSAD: 'M2',
            ALAND: 4012441638,
            AWATER: 15329028156,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.472794, 37.732675, -97.698692, 38.173814],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.472794, 38.172757],
                    [-98.216032, 38.173498],
                    [-98.215924, 38.15875],
                    [-97.922136, 38.173713],
                    [-97.701841, 38.173814],
                    [-97.701969, 37.911325],
                    [-97.698692, 37.735056],
                    [-97.807823, 37.733855],
                    [-98.46480400000002, 37.732675],
                    [-98.46482, 37.810206],
                    [-98.472257, 37.824497],
                    [-98.472794, 38.172757],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '26740',
            AFFGEOID: '310M600US26740',
            GEOID: '26740',
            NAME: 'Hutchinson, KS',
            NAMELSAD: 'Hutchinson, KS Micro Area',
            LSAD: 'M2',
            ALAND: 3251231984,
            AWATER: 43517492,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.725486, 32.25873, -91.4359307415474, 33.0143417676748],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.725486, 32.818136],
                    [-92.7247431731793, 33.0143417676748],
                    [-92.723553, 33.014328],
                    [-92.501383, 33.01216],
                    [-92.222825, 33.00908],
                    [-92.0690147239268, 33.0084819174935],
                    [-92.0689280885167, 33.0084815806166],
                    [-91.875128, 33.007728],
                    [-91.489176, 33.00618200000001],
                    [-91.46037623392111, 33.0059969558674],
                    [-91.4359307415474, 33.0058398887954],
                    [-91.437551, 32.980932],
                    [-91.566363, 32.855561],
                    [-91.637595, 32.667298],
                    [-91.742286, 32.558906],
                    [-91.80432, 32.571316],
                    [-91.839532, 32.518482],
                    [-91.913057, 32.505902],
                    [-91.994208, 32.401094],
                    [-92.034473, 32.277237],
                    [-92.106327, 32.25873],
                    [-92.312163, 32.277348],
                    [-92.311957, 32.320966],
                    [-92.41557700000001, 32.408029],
                    [-92.415515, 32.495609],
                    [-92.415228, 32.582854],
                    [-92.415169, 32.670254],
                    [-92.518999, 32.670807],
                    [-92.571295, 32.74857900000001],
                    [-92.725407, 32.759443],
                    [-92.725486, 32.818136],
                ],
            ],
        },
        properties: {
            CSAFP: '384',
            CBSAFP: '33740',
            AFFGEOID: '310M600US33740',
            GEOID: '33740',
            NAME: 'Monroe, LA',
            NAMELSAD: 'Monroe, LA Metro Area',
            LSAD: 'M1',
            ALAND: 5910868327,
            AWATER: 157865597,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.017789, 42.211408, -95.669265, 43.08367],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.017789, 42.351347],
                    [-97.0156311127472, 42.7565254624882],
                    [-96.965679, 42.724532],
                    [-96.906797, 42.7338],
                    [-96.8073706000982, 42.700678985373],
                    [-96.805682, 43.08367],
                    [-96.4520975160537, 43.0825529251945],
                    [-96.458201, 43.067554],
                    [-96.511605, 43.039927],
                    [-96.492693, 43.005089],
                    [-96.520246, 42.977643],
                    [-96.50030800000002, 42.959391],
                    [-96.541689, 42.922576],
                    [-96.5404723291933, 42.908595697956],
                    [-96.537851, 42.878475],
                    [-96.577937, 42.827645],
                    [-96.621875, 42.779255],
                    [-96.624704, 42.725497000000004],
                    [-96.591602, 42.688081],
                    [-96.526766, 42.64118400000001],
                    [-96.48002247898, 42.5613247765607],
                    [-95.859949, 42.56062],
                    [-95.741611, 42.561285],
                    [-95.741821, 42.474865],
                    [-95.669265, 42.47464500000001],
                    [-95.67082200000002, 42.211408],
                    [-96.3372160947059, 42.2148495068956],
                    [-96.336323, 42.218922],
                    [-96.336003, 42.264806],
                    [-96.351957192496, 42.2808947045433],
                    [-96.380298, 42.276351],
                    [-96.726661, 42.278005],
                    [-96.73778, 42.264104],
                    [-96.822497, 42.264292],
                    [-96.823077, 42.264292],
                    [-97.01773, 42.264453],
                    [-97.017789, 42.351347],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '43580',
            AFFGEOID: '310M600US43580',
            GEOID: '43580',
            NAME: 'Sioux City, IA-NE-SD',
            NAMELSAD: 'Sioux City, IA-NE-SD Metro Area',
            LSAD: 'M1',
            ALAND: 5371737574,
            AWATER: 54552245,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.045966, 43.5004149885627, -91.7302169978377, 44.455321],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.04591, 44.196716],
                    [-93.041159, 44.196726],
                    [-92.678717, 44.195516],
                    [-92.550871, 44.194916],
                    [-92.550733, 44.36852100000001],
                    [-92.430629, 44.368291],
                    [-92.430277, 44.455321],
                    [-92.24536464900541, 44.4542511243518],
                    [-92.232472, 44.445434],
                    [-92.111085, 44.413948],
                    [-92.0845285804928, 44.404614314801],
                    [-91.9636, 44.362112],
                    [-91.916191, 44.318094],
                    [-91.89269800000001, 44.231105],
                    [-91.85449997529771, 44.1972265195522],
                    [-92.079236, 44.192966],
                    [-92.079498, 44.106721],
                    [-92.079141, 43.847126],
                    [-91.730424, 43.846935],
                    [-91.7302169978377, 43.5006857009461],
                    [-91.82484800000002, 43.500684],
                    [-92.0798019931283, 43.5007048851766],
                    [-92.178863, 43.500713],
                    [-92.4489479514378, 43.5004149885627],
                    [-92.448965, 43.834105],
                    [-92.674231, 43.83390300000001],
                    [-92.689391, 43.84857],
                    [-93.045966, 43.848467],
                    [-93.04591, 44.196716],
                ],
            ],
        },
        properties: {
            CSAFP: '462',
            CBSAFP: '40340',
            AFFGEOID: '310M600US40340',
            GEOID: '40340',
            NAME: 'Rochester, MN',
            NAMELSAD: 'Rochester, MN Metro Area',
            LSAD: 'M1',
            ALAND: 6415529416,
            AWATER: 74957694,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-70.987258046746, 43.059825, -69.6958154562599, 44.170989],
            type: 'Polygon',
            coordinates: [
                [
                    [-70.987258046746, 43.7929735928842],
                    [-70.957548, 43.785123],
                    [-70.880657, 43.810858],
                    [-70.796543, 43.806571],
                    [-70.782984, 43.814147000000006],
                    [-70.801612, 43.856083],
                    [-70.72978, 43.92833],
                    [-70.715418, 43.961815],
                    [-70.821803, 44.077089],
                    [-70.624506, 44.170989],
                    [-70.587331, 44.122516],
                    [-70.595631, 44.032057],
                    [-70.544094, 44.005647],
                    [-70.480078, 44.032078],
                    [-70.363874, 43.98644500000001],
                    [-70.315374, 44.037807],
                    [-70.229244, 43.974064],
                    [-70.11586, 43.90671],
                    [-70.080839, 43.926458],
                    [-70.034238, 43.975661],
                    [-70.051824, 43.990982],
                    [-70.033264, 44.001344],
                    [-70.005471, 44.124519],
                    [-69.897783, 44.107662],
                    [-69.854983, 44.167862],
                    [-69.763566, 44.153217],
                    [-69.758208, 44.139334],
                    [-69.813549, 44.026011],
                    [-69.746329, 44.013802],
                    [-69.700097, 44.006685],
                    [-69.743263, 43.893926],
                    [-69.6958154562599, 43.7960549672913],
                    [-69.717074, 43.792403],
                    [-69.754091, 43.743866],
                    [-69.807359, 43.728081],
                    [-69.833471, 43.701281],
                    [-69.855081, 43.704746],
                    [-69.862155, 43.758962],
                    [-69.8874061241577, 43.7665933794518],
                    [-69.915593, 43.77511200000001],
                    [-69.98368500000001, 43.744395000000004],
                    [-70.00127300000001, 43.710388],
                    [-70.0627766602365, 43.7133599465128],
                    [-70.071304, 43.713772],
                    [-70.0884503900385, 43.68500682510451],
                    [-70.096039, 43.672276],
                    [-70.168227, 43.675136],
                    [-70.190704, 43.645582],
                    [-70.217087, 43.596717],
                    [-70.206123, 43.557627000000004],
                    [-70.245499, 43.539635],
                    [-70.321116, 43.527262],
                    [-70.3387374772484, 43.5281092793689],
                    [-70.361214, 43.52919],
                    [-70.385615, 43.487031],
                    [-70.327303, 43.458521],
                    [-70.383981, 43.41294],
                    [-70.3978722622715, 43.39064824070801],
                    [-70.416311, 43.361059],
                    [-70.465975, 43.340246],
                    [-70.4755551171812, 43.3409482085119],
                    [-70.51769500000002, 43.344037],
                    [-70.5341490659506, 43.3339572407458],
                    [-70.553854, 43.321886],
                    [-70.585184, 43.270113],
                    [-70.576986634166, 43.2280191731452],
                    [-70.575787, 43.22185900000001],
                    [-70.596185, 43.163466],
                    [-70.62251, 43.134573],
                    [-70.665958, 43.076234],
                    [-70.703818, 43.059825],
                    [-70.756397, 43.079988],
                    [-70.819549285713, 43.1232309734312],
                    [-70.8281, 43.129086],
                    [-70.824801, 43.179685],
                    [-70.8247767120681, 43.1797631051822],
                    [-70.813119, 43.217252],
                    [-70.872585, 43.270152],
                    [-70.923949, 43.324768],
                    [-70.984335, 43.376128],
                    [-70.968359, 43.429283],
                    [-70.960788896047, 43.474088733621606],
                    [-70.954755, 43.509802],
                    [-70.9637926798064, 43.54022095536651],
                    [-70.972716, 43.570255],
                    [-70.9758307355675, 43.6143624227896],
                    [-70.981946, 43.70096],
                    [-70.987258046746, 43.7929735928842],
                ],
            ],
        },
        properties: {
            CSAFP: '438',
            CBSAFP: '38860',
            AFFGEOID: '310M600US38860',
            GEOID: '38860',
            NAME: 'Portland-South Portland, ME',
            NAMELSAD: 'Portland-South Portland, ME Metro Area',
            LSAD: 'M1',
            ALAND: 5390753165,
            AWATER: 2009370778,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-77.356628, 40.638142, -76.798714, 40.88806],
            type: 'Polygon',
            coordinates: [
                [
                    [-77.356628, 40.807334],
                    [-76.941788, 40.88806],
                    [-76.800242, 40.881994],
                    [-76.798714, 40.878366],
                    [-76.813022, 40.847058],
                    [-76.840626, 40.821324],
                    [-76.849786, 40.809089],
                    [-76.864014, 40.706693],
                    [-76.939883, 40.638142],
                    [-77.109597, 40.691578],
                    [-77.287941, 40.693595],
                    [-77.354097, 40.701667],
                    [-77.356628, 40.807334],
                ],
            ],
        },
        properties: {
            CSAFP: '146',
            CBSAFP: '42780',
            AFFGEOID: '310M600US42780',
            GEOID: '42780',
            NAME: 'Selinsgrove, PA',
            NAMELSAD: 'Selinsgrove, PA Micro Area',
            LSAD: 'M2',
            ALAND: 851547199,
            AWATER: 7158854,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-66.4532967534793, 17.939004, -66.331244, 18.042627],
            type: 'Polygon',
            coordinates: [
                [
                    [-66.4532967534793, 17.9801331134818],
                    [-66.421054, 18.019309],
                    [-66.426989, 18.042627],
                    [-66.356779, 18.036942],
                    [-66.331244, 18.015891],
                    [-66.3381394256891, 17.9763514577553],
                    [-66.33839, 17.976458],
                    [-66.385059, 17.939004],
                    [-66.445481, 17.979379],
                    [-66.4532967534793, 17.9801331134818],
                ],
            ],
        },
        properties: {
            CSAFP: '434',
            CBSAFP: '42180',
            AFFGEOID: '310M600US42180',
            GEOID: '42180',
            NAME: 'Santa Isabel, PR',
            NAMELSAD: 'Santa Isabel, PR Micro Area',
            LSAD: 'M2',
            ALAND: 88182043,
            AWATER: 111103138,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-102.233892, 47.847589000000006, -100.197996, 48.999168],
            type: 'Polygon',
            coordinates: [
                [
                    [-102.2336, 48.72029],
                    [-102.152545, 48.720294],
                    [-102.152164, 48.8072],
                    [-102.021643, 48.807108],
                    [-102.021217638045, 48.998756534494],
                    [-101.625438, 48.999168],
                    [-101.496550279613, 48.9991448003959],
                    [-101.496684, 48.720078],
                    [-101.45127500000001, 48.546079],
                    [-101.059663, 48.545419],
                    [-101.059624, 48.63236100000001],
                    [-100.40678, 48.631878],
                    [-100.406513, 48.544627],
                    [-100.276619, 48.544813],
                    [-100.276614, 48.371199],
                    [-100.237022, 48.021866],
                    [-100.197996, 47.847652],
                    [-100.585179, 47.847761000000006],
                    [-100.971158, 47.848696],
                    [-101.871862, 47.847589000000006],
                    [-101.871537, 48.023176],
                    [-101.92212300000001, 48.023173],
                    [-101.922237, 48.372330000000005],
                    [-101.973542, 48.372275],
                    [-101.973282, 48.546485],
                    [-102.233892, 48.546339],
                    [-102.2336, 48.72029],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '33500',
            AFFGEOID: '310M600US33500',
            GEOID: '33500',
            NAME: 'Minot, ND',
            NAMELSAD: 'Minot, ND Micro Area',
            LSAD: 'M2',
            ALAND: 12339257388,
            AWATER: 249226410,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.157347, 36.312442, -87.1150035566884, 37.157186],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.157347, 36.867183],
                    [-88.002697, 36.910168],
                    [-87.876996, 36.960116],
                    [-87.85808800000001, 36.950714],
                    [-87.733368, 37.002103],
                    [-87.710912, 37.021607],
                    [-87.680617, 37.149239],
                    [-87.520116, 37.105452],
                    [-87.333741, 37.157186],
                    [-87.334277, 37.13181800000001],
                    [-87.259371, 37.072401],
                    [-87.3359790500808, 36.6415771604931],
                    [-87.1150035566884, 36.6441420446512],
                    [-87.150371, 36.566824],
                    [-87.120443, 36.45546],
                    [-87.281241, 36.352794],
                    [-87.286501, 36.321933],
                    [-87.303129, 36.312442],
                    [-87.513533, 36.334713],
                    [-87.586957, 36.34178],
                    [-87.592358, 36.367663],
                    [-87.715494, 36.36824],
                    [-87.821816, 36.328724],
                    [-87.98074, 36.353307],
                    [-87.99092, 36.360133],
                    [-88.061428, 36.443992],
                    [-88.0533508007759, 36.4999962506816],
                    [-88.050466, 36.500053],
                    [-88.033802, 36.551733],
                    [-88.055738, 36.630475],
                    [-88.070532, 36.678118],
                    [-88.080126, 36.706739],
                    [-88.110831, 36.74715],
                    [-88.129944, 36.807546],
                    [-88.157347, 36.867183],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '17300',
            AFFGEOID: '310M600US17300',
            GEOID: '17300',
            NAME: 'Clarksville, TN-KY',
            NAMELSAD: 'Clarksville, TN-KY Metro Area',
            LSAD: 'M1',
            ALAND: 5589088418,
            AWATER: 218217640,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.961278, 31.803579, -83.609663, 32.030595],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.961278, 32.030595],
                    [-83.609663, 32.027938],
                    [-83.612256, 31.854089000000002],
                    [-83.612642, 31.804128],
                    [-83.802723, 31.803579],
                    [-83.845323, 31.831190000000003],
                    [-83.939437, 31.847929],
                    [-83.922494, 31.909653000000002],
                    [-83.920102, 31.927242000000003],
                    [-83.961278, 32.030595],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '18380',
            AFFGEOID: '310M600US18380',
            GEOID: '18380',
            NAME: 'Cordele, GA',
            NAMELSAD: 'Cordele, GA Micro Area',
            LSAD: 'M2',
            ALAND: 706237950,
            AWATER: 21408088,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.807823, 36.998698609394, -96.522782, 38.174634000000005],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.807823, 37.733855],
                    [-97.698692, 37.735056],
                    [-97.701969, 37.911325],
                    [-97.701841, 38.173814],
                    [-97.37175, 38.173673],
                    [-97.153093, 38.174634000000005],
                    [-97.152913, 38.087704],
                    [-96.840772, 38.085622],
                    [-96.522782, 38.08637],
                    [-96.5253, 37.607015],
                    [-96.52569, 37.476405],
                    [-97.153331, 37.475544],
                    [-97.1477209669227, 36.9989723356215],
                    [-97.384925, 36.998843],
                    [-97.4623460298641, 36.9988242387969],
                    [-97.768704, 36.99875],
                    [-97.8023129703503, 36.998698609394],
                    [-97.804337, 37.366069],
                    [-97.807057, 37.386293],
                    [-97.8076, 37.474184],
                    [-97.807823, 37.733855],
                ],
            ],
        },
        properties: {
            CSAFP: '556',
            CBSAFP: '48620',
            AFFGEOID: '310M600US48620',
            GEOID: '48620',
            NAME: 'Wichita, KS',
            NAMELSAD: 'Wichita, KS Metro Area',
            LSAD: 'M1',
            ALAND: 10743441473,
            AWATER: 86140450,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-115.020079, 47.595507, -112.878931, 49.00139],
            type: 'Polygon',
            coordinates: [
                [
                    [-115.020079, 48.225954],
                    [-114.848098, 48.226125],
                    [-114.849803, 48.573502],
                    [-114.888679, 48.573546],
                    [-114.88859000000001, 48.658765],
                    [-114.639007, 48.658878],
                    [-114.69213099999999, 48.684577],
                    [-114.612063, 48.751858],
                    [-114.732545, 48.809752],
                    [-114.69167100000001, 48.842115],
                    [-114.718806, 48.938678],
                    [-114.6848, 48.973276],
                    [-114.72705020628, 49.0005869898058],
                    [-114.67821700000002, 49.000725],
                    [-114.375977, 49.00139],
                    [-114.180211, 48.999703],
                    [-114.068177472879, 48.999355878623],
                    [-114.021207, 48.961343],
                    [-114.058093, 48.888954],
                    [-113.956864, 48.817474],
                    [-113.841159, 48.855942],
                    [-113.756941, 48.818318],
                    [-113.761646, 48.773303],
                    [-113.702938, 48.719865],
                    [-113.75045400000002, 48.614335],
                    [-113.59905, 48.57517300000001],
                    [-113.543488, 48.58687200000001],
                    [-113.46737200000001, 48.547555],
                    [-113.47916000000001, 48.453115],
                    [-113.355976, 48.423912],
                    [-113.332966, 48.387632],
                    [-113.387048, 48.341502],
                    [-113.349046, 48.310263],
                    [-113.22768, 48.235752],
                    [-113.23090600000002, 48.170426],
                    [-113.014811, 48.13103],
                    [-112.878931, 47.975713],
                    [-112.984734, 47.953918],
                    [-113.08663400000002, 47.918182],
                    [-113.080997, 47.749917],
                    [-113.165811, 47.719764],
                    [-113.144368, 47.595507],
                    [-113.325845, 47.595507],
                    [-113.466485, 47.600057],
                    [-113.634079, 47.600027],
                    [-113.60392400000002, 47.72144],
                    [-113.64058800000001, 47.719998000000004],
                    [-113.6817, 47.805153],
                    [-113.6677, 47.89063],
                    [-113.747183, 47.952952],
                    [-113.842313, 47.978877],
                    [-113.88454100000001, 48.05190000000001],
                    [-114.029042, 48.051653],
                    [-114.078282, 48.023148],
                    [-114.038545, 47.96255],
                    [-114.49219, 47.961989],
                    [-114.47729, 47.789941],
                    [-114.60446200000001, 47.789871],
                    [-114.604584, 47.875605],
                    [-114.989344, 47.872807],
                    [-115.01067200000001, 48.017621],
                    [-115.020079, 48.225954],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '28060',
            AFFGEOID: '310M600US28060',
            GEOID: '28060',
            NAME: 'Kalispell, MT',
            NAMELSAD: 'Kalispell, MT Micro Area',
            LSAD: 'M2',
            ALAND: 13175845388,
            AWATER: 437161601,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-104.851524, 32.00001, -103.722881979148, 32.965114],
            type: 'Polygon',
            coordinates: [
                [
                    [-104.851524, 32.520541],
                    [-104.842171, 32.52054],
                    [-104.841576, 32.963213],
                    [-103.814507, 32.965114],
                    [-103.81421600000002, 32.522368],
                    [-103.722938, 32.522344],
                    [-103.722881979148, 32.0001655686764],
                    [-103.980213477381, 32.0000328513626],
                    [-104.024521, 32.00001],
                    [-104.643526, 32.000443],
                    [-104.84774, 32.000464602835],
                    [-104.851524, 32.520541],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '16100',
            AFFGEOID: '310M600US16100',
            GEOID: '16100',
            NAME: 'Carlsbad-Artesia, NM',
            NAMELSAD: 'Carlsbad-Artesia, NM Micro Area',
            LSAD: 'M2',
            ALAND: 10816688004,
            AWATER: 56493049,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-101.274799, 31.079379, -100.111234, 32.087208],
            type: 'Polygon',
            coordinates: [
                [
                    [-101.267947, 31.528688000000002],
                    [-101.26763, 31.556462],
                    [-101.267122, 31.650854000000002],
                    [-101.264216, 32.087136],
                    [-101.183997, 32.087208],
                    [-100.821594, 32.08661],
                    [-100.82537, 31.696155],
                    [-100.235761, 31.692970000000003],
                    [-100.233783, 31.582196],
                    [-100.111234, 31.580267000000003],
                    [-100.115216, 31.087994000000002],
                    [-100.688764, 31.086576000000004],
                    [-100.962176, 31.08249],
                    [-101.274799, 31.079379],
                    [-101.267947, 31.528688000000002],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '41660',
            AFFGEOID: '310M600US41660',
            GEOID: '41660',
            NAME: 'San Angelo, TX',
            NAMELSAD: 'San Angelo, TX Metro Area',
            LSAD: 'M1',
            ALAND: 9057174174,
            AWATER: 48421083,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-121.923749361985, 45.25792, -121.44064600000002, 45.726541],
            type: 'Polygon',
            coordinates: [
                [
                    [-121.923749361985, 45.65434950896941],
                    [-121.90085800000001, 45.662009],
                    [-121.867167, 45.693277],
                    [-121.81130399999999, 45.706761],
                    [-121.73510400000002, 45.694039],
                    [-121.668362, 45.705082],
                    [-121.53310600000002, 45.726541],
                    [-121.52400610422002, 45.7238362251992],
                    [-121.440695827861, 45.6990737947724],
                    [-121.44064600000002, 45.519394],
                    [-121.48188800000001, 45.258128],
                    [-121.69647900000001, 45.25792],
                    [-121.69751700000002, 45.373663],
                    [-121.819587, 45.461675],
                    [-121.90661, 45.52123],
                    [-121.923749361985, 45.65434950896941],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '26220',
            AFFGEOID: '310M600US26220',
            GEOID: '26220',
            NAME: 'Hood River, OR',
            NAMELSAD: 'Hood River, OR Micro Area',
            LSAD: 'M2',
            ALAND: 1352200146,
            AWATER: 29043701,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.055899, 29.32443, -81.433992, 29.840175999999996],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.050955, 29.702569000000004],
                    [-82.05562500000002, 29.718232000000004],
                    [-82.049244, 29.71867],
                    [-81.939427, 29.747497000000003],
                    [-81.90926, 29.793559999999996],
                    [-81.81243, 29.83649],
                    [-81.581207, 29.840175999999996],
                    [-81.52523, 29.759497000000003],
                    [-81.524804, 29.721586000000002],
                    [-81.52366, 29.622432],
                    [-81.520596, 29.500249000000004],
                    [-81.476893, 29.396552000000003],
                    [-81.433992, 29.398551999999995],
                    [-81.445886, 29.380142],
                    [-81.680903, 29.32443],
                    [-81.741422, 29.371049],
                    [-81.776205, 29.487448],
                    [-81.843009, 29.521004],
                    [-82.004385, 29.501715000000004],
                    [-82.055899, 29.471232000000004],
                    [-82.050955, 29.702569000000004],
                ],
            ],
        },
        properties: {
            CSAFP: '300',
            CBSAFP: '37260',
            AFFGEOID: '310M600US37260',
            GEOID: '37260',
            NAME: 'Palatka, FL',
            NAMELSAD: 'Palatka, FL Micro Area',
            LSAD: 'M2',
            ALAND: 1886452945,
            AWATER: 255262400,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-122.00236199999999, 43.611164, -119.89658099999998, 44.39263],
            type: 'Polygon',
            coordinates: [
                [
                    [-121.964918, 43.627045],
                    [-121.986267, 43.661708],
                    [-121.962098, 43.763537],
                    [-121.97511299999998, 43.85757],
                    [-121.928213, 43.909181],
                    [-121.869974, 43.911664],
                    [-121.857063, 43.965248],
                    [-121.76900500000002, 44.10203500000001],
                    [-121.77790500000002, 44.218877],
                    [-121.799426, 44.25828],
                    [-121.841346, 44.285652],
                    [-121.843138, 44.39263],
                    [-121.10753399999999, 44.3906],
                    [-121.102773, 44.138125],
                    [-120.987271, 44.134026],
                    [-120.986587, 43.960852],
                    [-120.74824899999999, 43.95731],
                    [-120.747848, 43.871331],
                    [-120.37874, 43.872362],
                    [-120.37810700000001, 43.785429],
                    [-120.258386, 43.785297],
                    [-120.25777700000002, 43.698762],
                    [-119.897434, 43.6982],
                    [-119.89658099999998, 43.611164],
                    [-120.35095000000001, 43.6116],
                    [-120.37775200000002, 43.615946],
                    [-121.33296900000002, 43.616645],
                    [-122.00236199999999, 43.615498],
                    [-121.964918, 43.627045],
                ],
            ],
        },
        properties: {
            CSAFP: '140',
            CBSAFP: '13460',
            AFFGEOID: '310M600US13460',
            GEOID: '13460',
            NAME: 'Bend, OR',
            NAMELSAD: 'Bend, OR Metro Area',
            LSAD: 'M1',
            ALAND: 7815593157,
            AWATER: 94707741,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.2890769140827, 35.410153, -89.64727, 36.000608],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.2890769140827, 35.9965128689775],
                    [-90.103842, 35.998143],
                    [-89.95937463191622, 35.9990141141563],
                    [-89.901183, 35.999365],
                    [-89.733095, 36.000608],
                    [-89.686924, 35.947716],
                    [-89.652279, 35.921462],
                    [-89.6491186188564, 35.9047155762998],
                    [-89.64727, 35.89492],
                    [-89.722634, 35.873718],
                    [-89.729517, 35.847632],
                    [-89.723426, 35.809382],
                    [-89.79705300000002, 35.782648],
                    [-89.863874, 35.747592],
                    [-89.915491, 35.754917],
                    [-89.956589, 35.695486],
                    [-89.89891600000001, 35.650904],
                    [-89.876548, 35.626653],
                    [-89.9325, 35.607865],
                    [-89.944754, 35.560308],
                    [-89.958498, 35.541703],
                    [-90.037615, 35.550329],
                    [-90.045805, 35.496533],
                    [-90.022064, 35.457375],
                    [-90.045306, 35.415435],
                    [-90.070549, 35.423291],
                    [-90.11250400000002, 35.410153],
                    [-90.140114, 35.436771],
                    [-90.286926, 35.438439],
                    [-90.286789, 35.69991],
                    [-90.2890769140827, 35.9965128689775],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '14180',
            AFFGEOID: '310M600US14180',
            GEOID: '14180',
            NAME: 'Blytheville, AR',
            NAMELSAD: 'Blytheville, AR Micro Area',
            LSAD: 'M2',
            ALAND: 2336409933,
            AWATER: 72224609,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-104.048087180484, 47.95595, -102.828163, 48.6339103786847],
            type: 'Polygon',
            coordinates: [
                [
                    [-104.048087180484, 48.6339103786847],
                    [-102.885775, 48.633298],
                    [-102.885763, 48.54639],
                    [-102.88586200000002, 48.372702],
                    [-102.828163, 48.372641],
                    [-102.828477, 48.123664],
                    [-103.107083, 48.146494],
                    [-103.20189, 48.135208],
                    [-103.225965, 48.075123],
                    [-103.288366, 48.040123],
                    [-103.42837000000002, 48.035924],
                    [-103.485072, 48.006725],
                    [-103.58477400000001, 48.054124],
                    [-103.548572, 48.087724],
                    [-103.597614, 48.134392],
                    [-103.74221600000001, 48.093403],
                    [-103.709454, 48.065462],
                    [-103.784394, 48.059491],
                    [-103.800817, 47.994269],
                    [-103.856306, 48.015072],
                    [-103.919789, 47.95595],
                    [-104.04409310666901, 47.9960816799606],
                    [-104.044093214262, 47.9960981889019],
                    [-104.045692, 48.241415],
                    [-104.046782142047, 48.3892980643109],
                    [-104.047555, 48.49414],
                    [-104.048087180484, 48.6339103786847],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '48780',
            AFFGEOID: '310M600US48780',
            GEOID: '48780',
            NAME: 'Williston, ND',
            NAMELSAD: 'Williston, ND Micro Area',
            LSAD: 'M2',
            ALAND: 5380959392,
            AWATER: 181836633,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-121.94551399999999, 38.734598, -121.009477, 39.639459],
            type: 'Polygon',
            coordinates: [
                [
                    [-121.908269, 39.303878],
                    [-121.63806600000001, 39.305566],
                    [-121.62376, 39.295621],
                    [-121.407527, 39.339754],
                    [-121.33442000000001, 39.425212],
                    [-121.30512200000001, 39.519585],
                    [-121.14990700000001, 39.526446],
                    [-121.07669500000002, 39.597264],
                    [-121.009477, 39.639459],
                    [-121.05820300000002, 39.537043],
                    [-121.022085, 39.391558],
                    [-121.12748100000002, 39.380237],
                    [-121.26613200000001, 39.272717],
                    [-121.279533, 39.03461800000001],
                    [-121.33213500000001, 39.042021],
                    [-121.365643, 39.031743],
                    [-121.414779, 38.996452000000005],
                    [-121.41439899999999, 38.926214],
                    [-121.469356, 38.925992],
                    [-121.484396, 38.734598],
                    [-121.55986800000001, 38.736302],
                    [-121.602894, 38.735838],
                    [-121.673454, 38.743035],
                    [-121.72314900000002, 38.851306],
                    [-121.81481400000001, 38.876582],
                    [-121.835488, 38.924481],
                    [-121.795366, 38.995686],
                    [-121.88910100000001, 39.072373],
                    [-121.94551399999999, 39.179843000000005],
                    [-121.908269, 39.303878],
                ],
            ],
        },
        properties: {
            CSAFP: '472',
            CBSAFP: '49700',
            AFFGEOID: '310M600US49700',
            GEOID: '49700',
            NAME: 'Yuba City, CA',
            NAMELSAD: 'Yuba City, CA Metro Area',
            LSAD: 'M1',
            ALAND: 3197863368,
            AWATER: 45895634,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-124.245027, 41.380776, -123.51911283680398, 41.9991725180329],
            type: 'Polygon',
            coordinates: [
                [
                    [-124.21959199999999, 41.846432],
                    [-124.203402, 41.940964],
                    [-124.21160500000002, 41.99846],
                    [-124.00118800000001, 41.996146],
                    [-123.822037598588, 41.99562081694701],
                    [-123.656998, 41.995137],
                    [-123.51911283680398, 41.9991725180329],
                    [-123.56544200000002, 41.903141],
                    [-123.64281200000002, 41.889353],
                    [-123.70368100000002, 41.829487],
                    [-123.66020500000002, 41.714055],
                    [-123.71917400000001, 41.595613],
                    [-123.64804600000001, 41.535024],
                    [-123.61179, 41.462127],
                    [-123.661363, 41.38209],
                    [-123.77023900000002, 41.380776],
                    [-123.770551, 41.464193],
                    [-124.067473659422, 41.46473618849091],
                    [-124.08198700000001, 41.547761],
                    [-124.11603700000002, 41.628849],
                    [-124.143479, 41.709284000000004],
                    [-124.15424600000001, 41.728801],
                    [-124.19104, 41.736079],
                    [-124.245027, 41.7923],
                    [-124.21959199999999, 41.846432],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '18860',
            AFFGEOID: '310M600US18860',
            GEOID: '18860',
            NAME: 'Crescent City, CA',
            NAMELSAD: 'Crescent City, CA Micro Area',
            LSAD: 'M2',
            ALAND: 2606118035,
            AWATER: 578742633,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.19347600000002, 30.993455000000004, -85.002499, 31.762968703418302],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.19347600000002, 31.192213],
                    [-86.125405, 31.182056999999997],
                    [-85.791402, 31.196349],
                    [-85.710333, 31.195173000000004],
                    [-85.665775, 31.267591],
                    [-85.485854, 31.246096000000005],
                    [-85.417434, 31.314973],
                    [-85.416437, 31.619466],
                    [-85.416038, 31.706664000000004],
                    [-85.216076, 31.702409000000003],
                    [-85.1254405773425, 31.762968703418302],
                    [-85.11893, 31.732663999999996],
                    [-85.12553, 31.694965000000003],
                    [-85.058169, 31.620227],
                    [-85.05796, 31.570840000000004],
                    [-85.041881, 31.544684],
                    [-85.0516814295062, 31.519540329141705],
                    [-85.071621, 31.468384],
                    [-85.066005, 31.431363],
                    [-85.092487, 31.362881],
                    [-85.087929, 31.321648],
                    [-85.0888299635305, 31.308647755496605],
                    [-85.089774, 31.295026],
                    [-85.108192, 31.258590999999996],
                    [-85.107516, 31.186450999999998],
                    [-85.035615, 31.108192],
                    [-85.0211075623628, 31.0754638723064],
                    [-85.011392, 31.053546000000004],
                    [-85.002499, 31.000681999999998],
                    [-85.031285, 31.000647],
                    [-85.145959, 31.000693],
                    [-85.333319, 30.999555],
                    [-85.4882982889391, 30.9979646232873],
                    [-85.49800158508951, 30.997865049361398],
                    [-85.5794975763383, 30.997028748410898],
                    [-85.74971500000001, 30.995282],
                    [-85.893632, 30.993455000000004],
                    [-86.0350381049506, 30.993749607302302],
                    [-86.1872480489813, 30.9940667234622],
                    [-86.190559, 31.01363],
                    [-86.19347600000002, 31.192213],
                ],
            ],
        },
        properties: {
            CSAFP: '222',
            CBSAFP: '20020',
            AFFGEOID: '310M600US20020',
            GEOID: '20020',
            NAME: 'Dothan, AL',
            NAMELSAD: 'Dothan, AL Metro Area',
            LSAD: 'M1',
            ALAND: 4444610312,
            AWATER: 33316633,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.353238, 34.472483, -82.840009, 35.0560052045865],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.353238, 34.728648],
                    [-83.320062, 34.759616],
                    [-83.323866, 34.789712],
                    [-83.284812, 34.823043],
                    [-83.252582, 34.853483],
                    [-83.201183, 34.884653],
                    [-83.140621, 34.924915],
                    [-83.124378, 34.95524],
                    [-83.108606, 35.000659000000006],
                    [-83.108399912205, 35.0007130457328],
                    [-83.0084105925124, 35.0269348608162],
                    [-82.897559231248, 35.0560052045865],
                    [-82.943604, 34.988154],
                    [-82.903309, 34.935044],
                    [-82.900458, 34.741449],
                    [-82.855588, 34.72527],
                    [-82.840009, 34.623311],
                    [-82.978252, 34.492579],
                    [-82.9913881531823, 34.4729806272358],
                    [-82.99509, 34.472483],
                    [-83.048289, 34.493254],
                    [-83.0505729641024, 34.4950536521691],
                    [-83.096858, 34.531524],
                    [-83.1028738260357, 34.5374309097654],
                    [-83.154577, 34.588198],
                    [-83.221402, 34.609947],
                    [-83.27796, 34.644853],
                    [-83.33869, 34.682002],
                    [-83.3400391067846, 34.6863277104121],
                    [-83.3496085109133, 34.7170105826273],
                    [-83.353238, 34.728648],
                ],
            ],
        },
        properties: {
            CSAFP: '273',
            CBSAFP: '42860',
            AFFGEOID: '310M600US42860',
            GEOID: '42860',
            NAME: 'Seneca, SC',
            NAMELSAD: 'Seneca, SC Micro Area',
            LSAD: 'M2',
            ALAND: 1622773470,
            AWATER: 121733747,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-75.4171070774881, 39.1766975035188, -74.856015, 39.568746],
            type: 'Polygon',
            coordinates: [
                [
                    [-75.4171070774881, 39.3889106867137],
                    [-75.368213, 39.456479],
                    [-75.23843400000001, 39.559039],
                    [-75.080309, 39.460648],
                    [-75.061854, 39.568746],
                    [-74.985271, 39.514967],
                    [-74.856015, 39.423958],
                    [-74.861294, 39.323815],
                    [-74.926347, 39.209012],
                    [-74.9151558055627, 39.1766975035188],
                    [-74.998002, 39.191253],
                    [-75.048487, 39.215222],
                    [-75.090794, 39.210802],
                    [-75.136667, 39.181883],
                    [-75.166667, 39.222577],
                    [-75.21251, 39.262755],
                    [-75.244357, 39.2857],
                    [-75.285333, 39.292212],
                    [-75.326754, 39.33247300000001],
                    [-75.355558, 39.347823],
                    [-75.399304, 39.37949],
                    [-75.4171070774881, 39.3889106867137],
                ],
            ],
        },
        properties: {
            CSAFP: '428',
            CBSAFP: '47220',
            AFFGEOID: '310M600US47220',
            GEOID: '47220',
            NAME: 'Vineland-Bridgeton, NJ',
            NAMELSAD: 'Vineland-Bridgeton, NJ Metro Area',
            LSAD: 'M1',
            ALAND: 1251898249,
            AWATER: 503713126,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.168127, 43.478957, -83.698816, 43.99697400000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.167318, 43.825902],
                    [-84.166377, 43.996901],
                    [-84.045409, 43.99697400000001],
                    [-84.044843, 43.91114600000001],
                    [-83.901329352181, 43.9084270508442],
                    [-83.910613, 43.89322],
                    [-83.929375, 43.777091],
                    [-83.94774, 43.73516500000001],
                    [-83.909479, 43.672622],
                    [-83.817894, 43.673789],
                    [-83.731005, 43.623369],
                    [-83.6994170918811, 43.6016371502565],
                    [-83.699484, 43.565823],
                    [-83.698816, 43.478957],
                    [-83.817678, 43.479052],
                    [-83.817228, 43.522345],
                    [-83.916091, 43.522629],
                    [-84.050987, 43.524064],
                    [-84.05, 43.567324],
                    [-84.168127, 43.568899],
                    [-84.167318, 43.825902],
                ],
            ],
        },
        properties: {
            CSAFP: '474',
            CBSAFP: '13020',
            AFFGEOID: '310M600US13020',
            GEOID: '13020',
            NAME: 'Bay City, MI',
            NAMELSAD: 'Bay City, MI Metro Area',
            LSAD: 'M1',
            ALAND: 1145834939,
            AWATER: 487713370,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-119.04149799999999, 45.9998002135237, -117.991481, 46.597262],
            type: 'Polygon',
            coordinates: [
                [
                    [-119.023417, 46.216656],
                    [-118.86073300000001, 46.252455],
                    [-118.76689799999998, 46.297916],
                    [-118.746199, 46.359512],
                    [-118.63382600000001, 46.414022],
                    [-118.615592, 46.504543],
                    [-118.471646, 46.597262],
                    [-118.228635, 46.593362],
                    [-118.241872, 46.295064],
                    [-118.116117, 46.295455000000004],
                    [-118.11662400000002, 46.20805],
                    [-117.991481, 46.207702],
                    [-117.99697042960301, 46.0001906671726],
                    [-118.36779, 46.000622],
                    [-118.60678915418899, 46.0008632497912],
                    [-118.67787, 46.000935],
                    [-118.987129, 45.999855000000004],
                    [-118.987227539565, 45.9998002135237],
                    [-118.941323, 46.027528],
                    [-118.974664, 46.139523],
                    [-119.04149799999999, 46.192667],
                    [-119.023417, 46.216656],
                ],
            ],
        },
        properties: {
            CSAFP: '313',
            CBSAFP: '47460',
            AFFGEOID: '310M600US47460',
            GEOID: '47460',
            NAME: 'Walla Walla, WA',
            NAMELSAD: 'Walla Walla, WA Metro Area',
            LSAD: 'M1',
            ALAND: 3289370358,
            AWATER: 74932288,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-124.552441, 41.99562081694701, -123.71649200000002, 42.954946],
            type: 'Polygon',
            coordinates: [
                [
                    [-124.552441, 42.840568],
                    [-124.48093799999998, 42.951495],
                    [-124.479882226932, 42.9543224376774],
                    [-124.258388, 42.954946],
                    [-124.219623, 42.933782],
                    [-124.141475, 42.850586],
                    [-124.15996600000001, 42.739947],
                    [-124.132918, 42.666697],
                    [-123.99629100000001, 42.685651],
                    [-123.925717, 42.774514],
                    [-123.81155300000002, 42.788837],
                    [-123.783304, 42.799426],
                    [-123.71649200000002, 42.784061],
                    [-123.83126400000002, 42.632313],
                    [-123.890657, 42.500538],
                    [-124.00839699999999, 42.49635200000001],
                    [-124.041983, 42.387656],
                    [-123.888411, 42.351627],
                    [-123.79220000000001, 42.237623],
                    [-123.858549, 42.135116],
                    [-123.82213900000002, 42.087522],
                    [-123.822037598588, 41.99562081694701],
                    [-124.00118800000001, 41.996146],
                    [-124.21160500000002, 41.99846],
                    [-124.270464, 42.045553],
                    [-124.31428900000002, 42.067864],
                    [-124.35153499999998, 42.129796],
                    [-124.361009, 42.180752],
                    [-124.38363300000002, 42.22716],
                    [-124.41098200000002, 42.250547],
                    [-124.410556, 42.307431],
                    [-124.42555399999999, 42.351874],
                    [-124.43510500000001, 42.440163000000005],
                    [-124.399065, 42.539928],
                    [-124.40091800000002, 42.597518],
                    [-124.413119, 42.657934],
                    [-124.45074, 42.675798],
                    [-124.448418, 42.689909],
                    [-124.510017, 42.734746],
                    [-124.552441, 42.840568],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '15060',
            AFFGEOID: '310M600US15060',
            GEOID: '15060',
            NAME: 'Brookings, OR',
            NAMELSAD: 'Brookings, OR Micro Area',
            LSAD: 'M2',
            ALAND: 4217489863,
            AWATER: 934614482,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.363297, 41.7069558826335, -83.7631498513652, 42.08243],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.363297, 42.073456],
                    [-84.131963, 42.071577],
                    [-83.773922, 42.08243],
                    [-83.7631498513652, 41.7235468017856],
                    [-83.8803904653533, 41.7201944903196],
                    [-84.134417, 41.712931],
                    [-84.3604190413973, 41.7069558826335],
                    [-84.363297, 42.073456],
                ],
            ],
        },
        properties: {
            CSAFP: '220',
            CBSAFP: '10300',
            AFFGEOID: '310M600US10300',
            GEOID: '10300',
            NAME: 'Adrian, MI',
            NAMELSAD: 'Adrian, MI Micro Area',
            LSAD: 'M2',
            ALAND: 1941526154,
            AWATER: 30497573,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-132.199681399057, 54.71691, -129.982348, 56.4070947732236],
            type: 'Polygon',
            coordinates: [
                [
                    [-132.199681399057, 55.633834],
                    [-132.091178, 55.660703],
                    [-132.058825, 55.71093],
                    [-131.962744, 55.700757],
                    [-131.978551, 55.753366],
                    [-131.935635, 55.798431],
                    [-132.011037, 55.854788],
                    [-131.871943, 55.948878],
                    [-131.905601, 55.979061],
                    [-131.829365, 56.056465],
                    [-131.664222, 56.067502],
                    [-131.653403, 56.103851],
                    [-131.527013, 56.053161],
                    [-131.498849, 56.01909700000001],
                    [-131.364317, 56.017943],
                    [-131.369226, 56.06991],
                    [-131.439957, 56.131083],
                    [-131.253358, 56.20685],
                    [-131.240485, 56.172614],
                    [-131.110314, 56.194069],
                    [-130.99843, 56.274565],
                    [-131.013177, 56.314211],
                    [-131.09623, 56.335166],
                    [-131.086794696282, 56.4070947732236],
                    [-131.085704, 56.40654],
                    [-130.810707, 56.37106300000001],
                    [-130.740619, 56.342953],
                    [-130.622482, 56.267939],
                    [-130.466874, 56.23978900000001],
                    [-130.425575, 56.140676],
                    [-130.343716, 56.127162],
                    [-130.246577284174, 56.0971959884747],
                    [-130.282366, 56.079598],
                    [-130.289296, 56.047321],
                    [-130.22006, 55.999137],
                    [-130.288619, 55.998125],
                    [-130.320172, 55.968974],
                    [-130.388606, 55.943179],
                    [-130.288673, 55.887006],
                    [-130.29848, 55.867112],
                    [-130.237973, 55.816645],
                    [-130.138222339589, 55.7630275658063],
                    [-130.150061, 55.727099],
                    [-130.111677, 55.682051],
                    [-130.120132, 55.563919],
                    [-130.085413, 55.491517],
                    [-130.039928, 55.429422],
                    [-130.023558, 55.338259],
                    [-129.982348, 55.302079],
                    [-130.001735, 55.264557],
                    [-130.104749, 55.188975],
                    [-130.169294, 55.105424],
                    [-130.221512, 55.02599],
                    [-130.339504, 54.921376],
                    [-130.529228, 54.8109],
                    [-130.636745, 54.778456],
                    [-130.62807, 54.73934100000001],
                    [-130.686192, 54.71691],
                    [-130.737423, 54.753545],
                    [-130.792122, 54.784784],
                    [-130.844145, 54.765801],
                    [-130.866866, 54.769068],
                    [-130.932454, 54.806938],
                    [-130.947338, 54.88673300000001],
                    [-130.9604, 54.933685],
                    [-130.97503, 54.97485300000001],
                    [-131.012061, 54.996238],
                    [-130.997057, 55.044256],
                    [-131.013215, 55.090069],
                    [-131.052298, 55.11816],
                    [-131.087497, 55.163036],
                    [-131.093806, 55.191335],
                    [-131.160492, 55.197481],
                    [-131.190628, 55.108013],
                    [-131.190033, 55.04317300000001],
                    [-131.246018, 54.989555],
                    [-131.245988, 54.940491],
                    [-131.195197, 54.919767],
                    [-131.253671, 54.866779],
                    [-131.327624, 54.859122],
                    [-131.433473, 54.896535],
                    [-131.491504, 54.930392],
                    [-131.594567, 54.93113],
                    [-131.621948, 54.946531],
                    [-131.611889743272, 54.9822706348831],
                    [-131.51066, 55.016366],
                    [-131.336983, 54.995534],
                    [-131.239554, 55.169683000000006],
                    [-131.569223, 55.294691],
                    [-131.648375, 55.227951],
                    [-131.68413198565, 55.1190936140756],
                    [-131.748334, 55.128588],
                    [-131.828395, 55.198482],
                    [-131.862162, 55.289284],
                    [-131.854297, 55.421074],
                    [-131.844157, 55.456742],
                    [-131.971792, 55.498279],
                    [-132.114654, 55.550623],
                    [-132.183207, 55.588128],
                    [-132.199681399057, 55.633834],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '28540',
            AFFGEOID: '310M600US28540',
            GEOID: '28540',
            NAME: 'Ketchikan, AK',
            NAMELSAD: 'Ketchikan, AK Micro Area',
            LSAD: 'M2',
            ALAND: 12579389790,
            AWATER: 4651604394,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-115.896925, 35.001857, -114.046838, 36.853084],
            type: 'Polygon',
            coordinates: [
                [
                    [-115.896925, 36.842085],
                    [-115.74084600000002, 36.853084],
                    [-114.762301, 36.843856],
                    [-114.05016077608802, 36.8431415396894],
                    [-114.049493, 36.604059],
                    [-114.047584, 36.325573],
                    [-114.048226, 36.268874],
                    [-114.046838, 36.194069],
                    [-114.09987, 36.121654],
                    [-114.138203, 36.053161],
                    [-114.151725, 36.024563],
                    [-114.21369, 36.01561300000001],
                    [-114.27064500000002, 36.03572],
                    [-114.316109, 36.063109],
                    [-114.337273, 36.10802],
                    [-114.372106, 36.143114],
                    [-114.41695000000001, 36.145761],
                    [-114.448654, 36.12641],
                    [-114.487034, 36.129396],
                    [-114.511721, 36.150956],
                    [-114.572031, 36.151610000000005],
                    [-114.62785500000001, 36.141012],
                    [-114.66653799999999, 36.117343],
                    [-114.73616500000001, 36.104367],
                    [-114.743342, 36.070535],
                    [-114.74329924588602, 36.0659351737601],
                    [-114.74277900000001, 36.009963],
                    [-114.73115900000002, 35.943916],
                    [-114.70027099999999, 35.901772],
                    [-114.669687, 35.865084],
                    [-114.70371, 35.814585],
                    [-114.697309, 35.733686],
                    [-114.68940700000002, 35.651412],
                    [-114.653406, 35.610789],
                    [-114.663105, 35.524491],
                    [-114.6645, 35.449497],
                    [-114.62713699999999, 35.409504],
                    [-114.587129, 35.26237600000001],
                    [-114.572747, 35.138725],
                    [-114.59912, 35.12105],
                    [-114.619905, 35.121632],
                    [-114.62506899999998, 35.068478],
                    [-114.63348700000002, 35.001857],
                    [-114.80424899999998, 35.139689],
                    [-115.043812, 35.332012],
                    [-115.160068, 35.424129],
                    [-115.303743, 35.538207],
                    [-115.404537, 35.617605],
                    [-115.647683, 35.809358],
                    [-115.648032019625, 35.8096292126029],
                    [-115.84611392276202, 35.9635526731109],
                    [-115.84580300000002, 36.122048],
                    [-115.895753, 36.172227],
                    [-115.896925, 36.842085],
                ],
            ],
        },
        properties: {
            CSAFP: '332',
            CBSAFP: '29820',
            AFFGEOID: '310M600US29820',
            GEOID: '29820',
            NAME: 'Las Vegas-Henderson-Paradise, NV',
            NAMELSAD: 'Las Vegas-Henderson-Paradise, NV Metro Area',
            LSAD: 'M1',
            ALAND: 20439308565,
            AWATER: 438384090,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-112.15637, 43.621976, -111.047219183002, 44.755128],
            type: 'Polygon',
            coordinates: [
                [
                    [-112.155935, 44.05778],
                    [-112.034317, 44.057358],
                    [-112.033664, 44.144049],
                    [-111.913601, 44.144337],
                    [-111.857851, 44.187759],
                    [-111.852276, 44.316996],
                    [-111.791541, 44.317021],
                    [-111.791624, 44.40161],
                    [-111.617437, 44.401254],
                    [-111.617107034305, 44.5571272484186],
                    [-111.562814, 44.555209000000005],
                    [-111.519126, 44.582916],
                    [-111.468833, 44.679335],
                    [-111.456947232494, 44.6956407378395],
                    [-111.438793, 44.720546],
                    [-111.385005, 44.755128],
                    [-111.377138236735, 44.7511964139636],
                    [-111.323669, 44.72447400000001],
                    [-111.26875, 44.668279],
                    [-111.224161, 44.623402],
                    [-111.201459, 44.575696],
                    [-111.143557, 44.535732],
                    [-111.122654, 44.493659],
                    [-111.048974, 44.474072],
                    [-111.049148, 44.374925],
                    [-111.048452, 44.114831],
                    [-111.047219183002, 43.983431292607705],
                    [-111.187256, 43.932114],
                    [-111.398781, 43.92289],
                    [-111.399765, 43.621976],
                    [-111.565974, 43.622037],
                    [-111.626043, 43.626756],
                    [-111.717356, 43.655326],
                    [-111.934357, 43.76616],
                    [-111.983527, 43.753806],
                    [-111.976849, 43.927385],
                    [-111.97701600000002, 43.970909],
                    [-112.15637, 43.971247],
                    [-112.155935, 44.05778],
                ],
            ],
        },
        properties: {
            CSAFP: '292',
            CBSAFP: '39940',
            AFFGEOID: '310M600US39940',
            GEOID: '39940',
            NAME: 'Rexburg, ID',
            NAMELSAD: 'Rexburg, ID Micro Area',
            LSAD: 'M2',
            ALAND: 6043029378,
            AWATER: 93941603,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.114186, 41.063995, -89.857616, 41.7827687259846],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.114186, 41.250029],
                    [-91.0744154980196, 41.3336315690407],
                    [-91.0740876236741, 41.3343208019205],
                    [-91.071552, 41.339651],
                    [-91.065058, 41.36910100000001],
                    [-91.027787, 41.42360300000001],
                    [-90.966662, 41.430051],
                    [-90.924343, 41.42286],
                    [-90.867282, 41.448215],
                    [-90.78628200000001, 41.45288800000001],
                    [-90.783812, 41.596531],
                    [-90.899853, 41.597133],
                    [-90.898373, 41.771392],
                    [-90.676373, 41.761023],
                    [-90.471723, 41.773603],
                    [-90.3118568504162, 41.7285328921536],
                    [-90.310708, 41.742214],
                    [-90.248631, 41.779805],
                    [-90.2423682473972, 41.7827687259846],
                    [-90.229749, 41.675375],
                    [-90.161233, 41.643927],
                    [-90.185536, 41.584574],
                    [-89.862351, 41.584005],
                    [-89.857616, 41.234479],
                    [-89.867847, 41.214925],
                    [-89.868152, 41.149493],
                    [-89.984559, 41.149394],
                    [-90.437652, 41.151463],
                    [-90.439423, 41.063995],
                    [-90.785194, 41.068749],
                    [-90.9518941426813, 41.0698727358664],
                    [-90.9522654164822, 41.07273174967],
                    [-90.957246, 41.111085],
                    [-90.997906, 41.162564],
                    [-91.041536, 41.166138],
                    [-91.08144500000002, 41.214429],
                    [-91.114186, 41.250029],
                ],
            ],
        },
        properties: {
            CSAFP: '209',
            CBSAFP: '19340',
            AFFGEOID: '310M600US19340',
            GEOID: '19340',
            NAME: 'Davenport-Moline-Rock Island, IA-IL',
            NAMELSAD: 'Davenport-Moline-Rock Island, IA-IL Metro Area',
            LSAD: 'M1',
            ALAND: 5878996902,
            AWATER: 114047027,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.564062, 27.032474, -80.940676, 27.646645],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.563533, 27.646645],
                    [-81.5299, 27.646422],
                    [-81.142164, 27.643238],
                    [-81.140472, 27.601302],
                    [-81.214284, 27.528709],
                    [-81.09845, 27.379108],
                    [-81.034429, 27.35916],
                    [-80.971144, 27.217787],
                    [-80.940676, 27.205939],
                    [-81.169138, 27.209585],
                    [-81.168281, 27.121734],
                    [-81.267677, 27.121097],
                    [-81.267216, 27.032474],
                    [-81.562531, 27.033687],
                    [-81.562624, 27.033835],
                    [-81.564062, 27.340641],
                    [-81.563533, 27.646645],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '42700',
            AFFGEOID: '310M600US42700',
            GEOID: '42700',
            NAME: 'Sebring-Avon Park, FL',
            NAMELSAD: 'Sebring-Avon Park, FL Metro Area',
            LSAD: 'M1',
            ALAND: 2635726554,
            AWATER: 230667878,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-109.050732112077, 40.22167, -107.314153, 41.003073],
            type: 'Polygon',
            coordinates: [
                [
                    [-109.048249, 40.653601],
                    [-109.048259750048, 40.662601817228],
                    [-109.048455, 40.826081],
                    [-109.050076, 41.000659],
                    [-108.250649, 41.000114],
                    [-107.918418289849, 41.0012270706441],
                    [-107.367443, 41.003073],
                    [-107.31779575979, 41.0028424812322],
                    [-107.314153, 40.600281],
                    [-107.428813, 40.542207],
                    [-107.439386, 40.223379],
                    [-107.644175, 40.224238],
                    [-108.113179, 40.22167],
                    [-109.050732112077, 40.2226552582576],
                    [-109.048249, 40.653601],
                ],
            ],
        },
        properties: {
            CSAFP: '525',
            CBSAFP: '18780',
            AFFGEOID: '310M600US18780',
            GEOID: '18780',
            NAME: 'Craig, CO',
            NAMELSAD: 'Craig, CO Micro Area',
            LSAD: 'M2',
            ALAND: 12284943821,
            AWATER: 19803411,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-72.315088, 41.634799, -71.478119, 42.722010250624],
            type: 'Polygon',
            coordinates: [
                [
                    [-72.315088, 42.398856],
                    [-72.244868, 42.513439],
                    [-72.271352, 42.547227],
                    [-72.227752, 42.615867],
                    [-72.2830340925109, 42.722010250624],
                    [-72.2036134798068, 42.7198185286799],
                    [-72.124526, 42.717636],
                    [-72.1110573073528, 42.7172679403547],
                    [-72.081365050188, 42.7164565385412],
                    [-71.9290300590535, 42.7122936725326],
                    [-71.8987687719366, 42.7114667208088],
                    [-71.858483, 42.633815],
                    [-71.775755, 42.64423],
                    [-71.664601, 42.611598],
                    [-71.678969, 42.53043],
                    [-71.635869, 42.524024],
                    [-71.619927, 42.552502],
                    [-71.53878, 42.54303],
                    [-71.529841, 42.519841],
                    [-71.560367, 42.47435],
                    [-71.5591, 42.411943],
                    [-71.604251, 42.39770300000001],
                    [-71.624702, 42.350465],
                    [-71.58521, 42.310952],
                    [-71.551126, 42.326395000000005],
                    [-71.486797, 42.330189],
                    [-71.506478, 42.264276],
                    [-71.522178, 42.266454],
                    [-71.550115, 42.266023],
                    [-71.586759, 42.259545],
                    [-71.555738, 42.182503],
                    [-71.502626, 42.191416],
                    [-71.478119, 42.156782],
                    [-71.478522, 42.131378],
                    [-71.499689, 42.10349000000001],
                    [-71.498287, 42.064878],
                    [-71.4982240131247, 42.0158741110185],
                    [-71.559439, 42.014342],
                    [-71.591096527631, 42.0135133435572],
                    [-71.6066944190753, 42.0131050585708],
                    [-71.799242, 42.008065],
                    [-71.792767, 41.807001],
                    [-71.7896954316824, 41.7251982448091],
                    [-71.789678, 41.724734],
                    [-71.789464590839, 41.6400171907069],
                    [-71.857917, 41.644227],
                    [-71.954983, 41.634799],
                    [-72.062051, 41.652239],
                    [-72.146717, 41.66716900000001],
                    [-72.157546, 41.657627],
                    [-72.239559, 41.714191],
                    [-72.252643, 41.728202],
                    [-72.156321, 41.753491],
                    [-72.16449800000001, 41.834913],
                    [-72.217719, 41.835184000000005],
                    [-72.21698, 41.958161],
                    [-72.151654, 41.959302],
                    [-72.099229, 41.958948],
                    [-72.1021594345268, 42.0286302400548],
                    [-72.1357314619467, 42.0291419299416],
                    [-72.135029, 42.146994],
                    [-72.141886, 42.161],
                    [-72.199348, 42.153707],
                    [-72.263924, 42.183831],
                    [-72.221218, 42.245252],
                    [-72.211079, 42.251262],
                    [-72.210795, 42.31138],
                    [-72.288559, 42.352078],
                    [-72.314253, 42.343688],
                    [-72.315088, 42.398856],
                ],
            ],
        },
        properties: {
            CSAFP: '148',
            CBSAFP: '49340',
            AFFGEOID: '310M600US49340',
            GEOID: '49340',
            NAME: 'Worcester, MA-CT',
            NAMELSAD: 'Worcester, MA-CT Metro Area',
            LSAD: 'M1',
            ALAND: 5241095671,
            AWATER: 198849712,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-66.3381394256891, 17.913769, -66.166145, 18.095339],
            type: 'Polygon',
            coordinates: [
                [
                    [-66.331244, 18.015891],
                    [-66.310623, 18.046967],
                    [-66.257527, 18.075884],
                    [-66.22449, 18.095339],
                    [-66.202038, 18.046113],
                    [-66.166145, 18.050063],
                    [-66.205592, 18.01298],
                    [-66.22053069398, 17.9178103809448],
                    [-66.243235, 17.913769],
                    [-66.297679, 17.959148],
                    [-66.3381394256891, 17.9763514577553],
                    [-66.331244, 18.015891],
                ],
            ],
        },
        properties: {
            CSAFP: '490',
            CBSAFP: '17640',
            AFFGEOID: '310M600US17640',
            GEOID: '17640',
            NAME: 'Coco, PR',
            NAMELSAD: 'Coco, PR Micro Area',
            LSAD: 'M2',
            ALAND: 179755401,
            AWATER: 115816412,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.014489, 36.879001, -80.178125, 37.474695],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.9808452568928, 37.3008528161905],
                    [-80.919259, 37.306163],
                    [-80.835479, 37.334824],
                    [-80.883248, 37.383933],
                    [-80.865148, 37.419927],
                    [-80.8581481168198, 37.4210069067215],
                    [-80.8573585701898, 37.42112871399901],
                    [-80.83644600000001, 37.424355],
                    [-80.770082, 37.372363],
                    [-80.664971, 37.414215],
                    [-80.544836, 37.474695],
                    [-80.4695688905042, 37.4290254659927],
                    [-80.44682500000002, 37.327578],
                    [-80.430943, 37.316378],
                    [-80.32484, 37.368833],
                    [-80.262184, 37.341526],
                    [-80.189592, 37.233449],
                    [-80.178125, 37.1134],
                    [-80.347858, 37.021593],
                    [-80.520014, 37.011223],
                    [-80.543933, 36.984639],
                    [-80.56826, 36.960672],
                    [-80.633987, 36.93178],
                    [-80.68259, 36.895144],
                    [-80.740483, 36.879001],
                    [-80.893249, 37.027399],
                    [-80.912674, 37.073349],
                    [-80.930627, 37.115087],
                    [-80.854627, 37.147482],
                    [-81.014489, 37.275385],
                    [-80.9808452568928, 37.3008528161905],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '13980',
            AFFGEOID: '310M600US13980',
            GEOID: '13980',
            NAME: 'Blacksburg-Christiansburg, VA',
            NAMELSAD: 'Blacksburg-Christiansburg, VA Metro Area',
            LSAD: 'M1',
            ALAND: 2780576862,
            AWATER: 43249043,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.929208, 29.5887857069487, -92.616033, 30.490523],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.929208, 29.802952000000005],
                    [-93.872446, 29.85165],
                    [-93.8523098709223, 29.872091004391898],
                    [-93.830374, 29.894359],
                    [-93.807815, 29.954549],
                    [-93.74107800000002, 30.021571],
                    [-93.7061835663174, 30.0523143321691],
                    [-93.70394, 30.054291000000003],
                    [-93.702436, 30.112721],
                    [-93.703764, 30.173936000000005],
                    [-93.713359, 30.225261],
                    [-93.7110621713009, 30.243970715640298],
                    [-93.70719, 30.275513],
                    [-93.760328, 30.329924000000002],
                    [-93.745333, 30.397021999999996],
                    [-93.7381729998702, 30.4025470540047],
                    [-93.486159, 30.402547],
                    [-93.486325, 30.490523],
                    [-93.384521, 30.490166],
                    [-93.38524, 30.402658],
                    [-93.131224, 30.403058000000005],
                    [-93.030959, 30.379389999999997],
                    [-93.030728, 30.260564],
                    [-92.9963, 30.156955000000004],
                    [-92.893247, 30.156509999999997],
                    [-92.887222, 30.083455000000004],
                    [-92.996479, 30.082593],
                    [-92.996624, 30.038481],
                    [-92.757328, 30.039446999999996],
                    [-92.737217, 30.037455],
                    [-92.616033, 30.038260000000005],
                    [-92.6160814772774, 29.5887857069487],
                    [-92.684486, 29.605001],
                    [-92.879992, 29.680284999999998],
                    [-92.99312800000001, 29.723846],
                    [-93.08818200000002, 29.749125000000003],
                    [-93.17693, 29.770487],
                    [-93.29557300000002, 29.775071],
                    [-93.411087, 29.767357],
                    [-93.538462, 29.763298999999996],
                    [-93.74194800000001, 29.736343],
                    [-93.79925, 29.71526],
                    [-93.837971, 29.690619],
                    [-93.863204, 29.724059000000004],
                    [-93.890821, 29.761673],
                    [-93.929208, 29.802952000000005],
                ],
            ],
        },
        properties: {
            CSAFP: '324',
            CBSAFP: '29340',
            AFFGEOID: '310M600US29340',
            GEOID: '29340',
            NAME: 'Lake Charles, LA',
            NAMELSAD: 'Lake Charles, LA Metro Area',
            LSAD: 'M1',
            ALAND: 6083159947,
            AWATER: 1766802554,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.452801, 37.990688, -76.387002, 39.720233],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.442767, 38.527775],
                    [-78.338176, 38.627312],
                    [-78.318775, 38.737866],
                    [-78.284805, 38.759315],
                    [-78.290403, 38.780373],
                    [-78.394704, 38.82279],
                    [-78.30041400000002, 38.94359200000001],
                    [-78.31408, 39.007994],
                    [-78.163871, 39.017928],
                    [-78.151614, 39.036629],
                    [-78.10979400000001, 39.095822],
                    [-78.0331834609342, 39.2646236984747],
                    [-78.0331852353666, 39.2646248420052],
                    [-78.035454, 39.278224],
                    [-77.988179, 39.320084],
                    [-77.966953, 39.367164],
                    [-77.930868, 39.381170000000004],
                    [-77.84714, 39.446557],
                    [-77.8109438962622, 39.5007391665665],
                    [-77.798201, 39.475719],
                    [-77.740012, 39.401694],
                    [-77.74593, 39.353221],
                    [-77.719519, 39.321314],
                    [-77.6776957620787, 39.317940817088],
                    [-77.656177, 39.368397],
                    [-77.570182, 39.619998],
                    [-77.4691450036804, 39.72022900225],
                    [-77.4591384979573, 39.7202291767892],
                    [-77.23995, 39.720233],
                    [-77.2170237552374, 39.7202172483854],
                    [-77.307312, 39.61887],
                    [-77.198024, 39.57543],
                    [-77.107711, 39.494556],
                    [-77.16808, 39.353957],
                    [-77.168801, 39.353502],
                    [-77.18693700000001, 39.339265],
                    [-77.134466, 39.276834],
                    [-77.070112, 39.254104],
                    [-76.973666, 39.162209],
                    [-76.958517, 39.134023],
                    [-76.888505, 39.130967],
                    [-76.840362, 39.103142],
                    [-76.831424, 39.069034],
                    [-76.790741, 39.045722],
                    [-76.751258, 39.034714],
                    [-76.669969, 38.90652],
                    [-76.710639, 38.815694],
                    [-76.686358, 38.748475],
                    [-76.642749, 38.769281],
                    [-76.615902, 38.720804],
                    [-76.5270925473942, 38.7127503012383],
                    [-76.528923, 38.663889],
                    [-76.511278, 38.615745],
                    [-76.517506, 38.539149],
                    [-76.492699, 38.482849],
                    [-76.450937, 38.442422],
                    [-76.393378, 38.38947700000001],
                    [-76.387002, 38.361267],
                    [-76.400194624698, 38.3198719439614],
                    [-76.453453, 38.305057],
                    [-76.501475, 38.372497],
                    [-76.608373, 38.424445],
                    [-76.674118, 38.499622],
                    [-76.76829, 38.512949],
                    [-76.87146, 38.389937],
                    [-76.8270361292819, 38.2583001006141],
                    [-76.864292, 38.268945],
                    [-76.922177, 38.311339],
                    [-76.975492, 38.347327],
                    [-77.001638, 38.421952],
                    [-77.016371, 38.445572],
                    [-77.075489, 38.42471],
                    [-77.123325, 38.410646],
                    [-77.211188, 38.380662],
                    [-77.259962, 38.435821],
                    [-77.246584, 38.538341],
                    [-77.183767, 38.600699],
                    [-77.129084, 38.614364],
                    [-77.1302, 38.635017],
                    [-77.2241458553015, 38.6351782749009],
                    [-77.246704, 38.635217],
                    [-77.295274, 38.562125],
                    [-77.2992320126661, 38.5483767385109],
                    [-77.31260428411932, 38.5019278005841],
                    [-77.322622, 38.467131],
                    [-77.317288, 38.383576],
                    [-77.2843464997922, 38.3516368165143],
                    [-77.330094, 38.33401],
                    [-77.326692, 38.245136],
                    [-77.370297, 38.246576],
                    [-77.517616, 38.10542],
                    [-77.642518, 37.990688],
                    [-77.687469, 38.007806],
                    [-77.838217, 38.106239],
                    [-77.953639, 38.118501],
                    [-77.715081, 38.335764],
                    [-77.70284300000002, 38.36084],
                    [-77.770061, 38.392992],
                    [-77.782494, 38.364465],
                    [-77.90897900000002, 38.381503],
                    [-78.039921, 38.311566],
                    [-78.094498, 38.311242],
                    [-78.231833, 38.230529],
                    [-78.252822, 38.234483],
                    [-78.267717, 38.2637],
                    [-78.288837, 38.272656],
                    [-78.348971, 38.277413],
                    [-78.429626, 38.366704],
                    [-78.452801, 38.475527],
                    [-78.442767, 38.527775],
                ],
            ],
        },
        properties: {
            CSAFP: '548',
            CBSAFP: '47900',
            AFFGEOID: '310M600US47900',
            GEOID: '47900',
            NAME: 'Washington-Arlington-Alexandria, DC-VA-MD-WV',
            NAMELSAD: 'Washington-Arlington-Alexandria, DC-VA-MD-WV Metro Area',
            LSAD: 'M1',
            ALAND: 17010273437,
            AWATER: 1128111563,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.3866705737728, 32.84464, -83.269213, 34.617924],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.3866705737728, 33.9017014940033],
                    [-85.212791, 33.899212],
                    [-85.05031, 33.904488],
                    [-85.049833, 33.952635],
                    [-84.978683, 33.951393],
                    [-84.922742, 34.082497],
                    [-85.047046, 34.082881],
                    [-85.046871, 34.096412],
                    [-85.023102, 34.347112],
                    [-85.005775, 34.392446],
                    [-84.988793, 34.384578],
                    [-84.653232, 34.41259],
                    [-84.644432, 34.455789],
                    [-84.654366, 34.548946],
                    [-84.50261, 34.563681],
                    [-84.467989, 34.563488],
                    [-84.437226, 34.54935],
                    [-84.37135200000002, 34.548495],
                    [-84.345553, 34.562735],
                    [-84.336639, 34.578945],
                    [-84.255112, 34.568333],
                    [-84.19675400000001, 34.617924],
                    [-84.188557, 34.602692],
                    [-84.19104, 34.539181],
                    [-84.102037, 34.464544],
                    [-83.980649, 34.418389],
                    [-83.981433, 34.357829],
                    [-83.957077, 34.334011],
                    [-83.925529, 34.286041],
                    [-83.989059, 34.195732],
                    [-84.057632, 34.183277],
                    [-84.062841, 34.167873],
                    [-83.890097, 34.106179],
                    [-83.817682, 34.127493],
                    [-83.767532, 34.066448],
                    [-83.563277, 34.031864],
                    [-83.537385, 33.965912],
                    [-83.647031, 33.90619800000001],
                    [-83.533165, 33.820923],
                    [-83.505928, 33.81776],
                    [-83.483177, 33.805576],
                    [-83.406189, 33.698307],
                    [-83.34687, 33.600498],
                    [-83.269213, 33.533226],
                    [-83.279931, 33.483438],
                    [-83.533736, 33.434472],
                    [-83.545876, 33.171944],
                    [-83.816048, 33.131816],
                    [-83.822261, 33.180238],
                    [-83.842615, 33.196773],
                    [-84.041498, 33.202629],
                    [-84.05417500000001, 32.931497],
                    [-84.12334, 32.932184],
                    [-84.122361, 32.989576],
                    [-84.27014, 32.991011],
                    [-84.489708, 32.993729],
                    [-84.52702, 32.970548],
                    [-84.532118, 32.960729],
                    [-84.526902, 32.914098],
                    [-84.506888, 32.881788],
                    [-84.570669, 32.845179],
                    [-84.700538, 32.84464],
                    [-84.751338, 32.8692],
                    [-84.861768, 32.872495],
                    [-84.862359, 33.191173],
                    [-84.862135, 33.223858],
                    [-84.939015, 33.224693],
                    [-85.10798, 33.195098],
                    [-85.117402, 33.163654],
                    [-85.2365953712528, 33.1295440282507],
                    [-85.2943468643453, 33.4279931463646],
                    [-85.3049438332249, 33.4827563368455],
                    [-85.314048, 33.529805],
                    [-85.3381162999059, 33.6531143916014],
                    [-85.360532, 33.767957],
                    [-85.3866705737728, 33.9017014940033],
                ],
            ],
        },
        properties: {
            CSAFP: '122',
            CBSAFP: '12060',
            AFFGEOID: '310M600US12060',
            GEOID: '12060',
            NAME: 'Atlanta-Sandy Springs-Alpharetta, GA',
            NAMELSAD: 'Atlanta-Sandy Springs-Alpharetta, GA Metro Area',
            LSAD: 'M1',
            ALAND: 22495873026,
            AWATER: 386782308,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.5933002182792, 26.041275, -97.861875, 26.783081],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.5933002182792, 26.2429363486766],
                    [-98.491943, 26.445362],
                    [-98.32067, 26.783081],
                    [-97.985494, 26.780917],
                    [-97.985303, 26.615809000000002],
                    [-97.957405, 26.611769],
                    [-98.004189, 26.448784],
                    [-97.86192, 26.433579],
                    [-97.861875, 26.348156],
                    [-97.861875, 26.120295],
                    [-97.8622815631063, 26.0577468284402],
                    [-97.871187, 26.058083],
                    [-97.944345, 26.059621],
                    [-98.010971, 26.063863],
                    [-98.039239, 26.041275],
                    [-98.091038, 26.059169],
                    [-98.149463, 26.055813],
                    [-98.197046, 26.056153],
                    [-98.2049539555924, 26.0587423360004],
                    [-98.248806, 26.073101],
                    [-98.302979, 26.11005],
                    [-98.3082001170459, 26.1130325510287],
                    [-98.386694, 26.157872],
                    [-98.44253600000002, 26.199151],
                    [-98.503492, 26.214798],
                    [-98.576188, 26.235221],
                    [-98.5933002182792, 26.2429363486766],
                ],
            ],
        },
        properties: {
            CSAFP: '365',
            CBSAFP: '32580',
            AFFGEOID: '310M600US32580',
            GEOID: '32580',
            NAME: 'McAllen-Edinburg-Mission, TX',
            NAMELSAD: 'McAllen-Edinburg-Mission, TX Metro Area',
            LSAD: 'M1',
            ALAND: 4068758842,
            AWATER: 30739605,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.862521, 32.96043400000001, -95.30859300000002, 33.37719],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.861778, 33.21933],
                    [-95.786682, 33.253997],
                    [-95.731056, 33.305222],
                    [-95.720231, 33.299411],
                    [-95.673722, 33.302117],
                    [-95.584549, 33.359344],
                    [-95.501827, 33.347865],
                    [-95.30859300000002, 33.37719],
                    [-95.308957, 32.962572],
                    [-95.665389, 32.96043400000001],
                    [-95.676795, 32.97959],
                    [-95.862521, 32.979571],
                    [-95.861778, 33.21933],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '44860',
            AFFGEOID: '310M600US44860',
            GEOID: '44860',
            NAME: 'Sulphur Springs, TX',
            NAMELSAD: 'Sulphur Springs, TX Micro Area',
            LSAD: 'M2',
            ALAND: 1987636011,
            AWATER: 65632971,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-122.631832, 38.154974, -122.061379, 38.864245],
            type: 'Polygon',
            coordinates: [
                [
                    [-122.62739600000002, 38.667506],
                    [-122.46389, 38.705203],
                    [-122.39798900000001, 38.804001],
                    [-122.39505599999998, 38.864245],
                    [-122.287998, 38.839931],
                    [-122.224206, 38.699984],
                    [-122.16830100000001, 38.655296],
                    [-122.10328100000001, 38.513348],
                    [-122.126389, 38.428918],
                    [-122.061379, 38.327411],
                    [-122.20598199999999, 38.315713],
                    [-122.19588400000002, 38.162776],
                    [-122.213457, 38.154974],
                    [-122.40678600000001, 38.155632],
                    [-122.349564, 38.193972],
                    [-122.54389300000001, 38.519966],
                    [-122.60265900000002, 38.557496],
                    [-122.631832, 38.569367],
                    [-122.62739600000002, 38.667506],
                ],
            ],
        },
        properties: {
            CSAFP: '488',
            CBSAFP: '34900',
            AFFGEOID: '310M600US34900',
            GEOID: '34900',
            NAME: 'Napa, CA',
            NAMELSAD: 'Napa, CA Metro Area',
            LSAD: 'M1',
            ALAND: 1938032964,
            AWATER: 104262608,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.602315, 47.409848, -94.416279, 48.540211000000006],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.602315, 48.538887],
                    [-95.34254, 48.540211000000006],
                    [-95.211966, 48.539961],
                    [-95.210953, 48.365971],
                    [-94.428702, 48.367333],
                    [-94.417747, 47.933110000000006],
                    [-94.418543, 47.845816],
                    [-94.416279, 47.444828],
                    [-94.416387, 47.41183000000001],
                    [-94.670214, 47.410151],
                    [-94.799934, 47.409848],
                    [-95.183216, 47.412773],
                    [-95.193705, 47.872544000000005],
                    [-95.277834, 47.925346],
                    [-95.22913300000002, 48.019958],
                    [-95.582887, 48.020557],
                    [-95.592287, 48.037359],
                    [-95.593788, 48.172916],
                    [-95.602315, 48.538887],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '13420',
            AFFGEOID: '310M600US13420',
            GEOID: '13420',
            NAME: 'Bemidji, MN',
            NAMELSAD: 'Bemidji, MN Micro Area',
            LSAD: 'M2',
            ALAND: 6487154459,
            AWATER: 1426287777,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.19937800000001, 31.616414, -85.657668, 32.061890000000005],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.191379, 31.966453000000005],
                    [-85.995563, 31.967554],
                    [-85.996853, 32.051049],
                    [-85.946298, 32.061890000000005],
                    [-85.893652, 32.047351],
                    [-85.88435, 31.967253],
                    [-85.79004800000001, 31.967254000000004],
                    [-85.791047, 31.880357],
                    [-85.657668, 31.880275],
                    [-85.66623, 31.772877000000005],
                    [-85.735732, 31.624493000000005],
                    [-85.748251, 31.618048],
                    [-85.789142, 31.617964],
                    [-86.145895, 31.617741],
                    [-86.179672, 31.616414],
                    [-86.147147, 31.66315],
                    [-86.148339, 31.790951000000003],
                    [-86.19937800000001, 31.79045],
                    [-86.191379, 31.966453000000005],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '45980',
            AFFGEOID: '310M600US45980',
            GEOID: '45980',
            NAME: 'Troy, AL',
            NAMELSAD: 'Troy, AL Micro Area',
            LSAD: 'M2',
            ALAND: 1740645369,
            AWATER: 2339145,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.503127, 33.102944, -85.794559, 33.697851],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.503127, 33.179144],
                    [-86.458026, 33.241434],
                    [-86.35734, 33.296917],
                    [-86.341113, 33.354599],
                    [-86.378665, 33.390983],
                    [-86.281999, 33.509895],
                    [-86.235328, 33.494532],
                    [-86.16948, 33.619236],
                    [-86.193733, 33.697851],
                    [-86.145562, 33.679098],
                    [-86.050669, 33.67459],
                    [-85.994935, 33.586475],
                    [-85.794559, 33.585565],
                    [-85.796054, 33.55622],
                    [-85.805241, 33.535189],
                    [-85.85189, 33.498742],
                    [-85.904909, 33.498655],
                    [-85.923762, 33.396206],
                    [-85.976525, 33.38187],
                    [-85.980293, 33.29419],
                    [-86.118198, 33.29632],
                    [-86.120567, 33.194511],
                    [-86.17283, 33.195681],
                    [-86.17437, 33.104394],
                    [-86.491029, 33.102944],
                    [-86.503127, 33.179144],
                ],
            ],
        },
        properties: {
            CSAFP: '142',
            CBSAFP: '45180',
            AFFGEOID: '310M600US45180',
            GEOID: '45180',
            NAME: 'Talladega-Sylacauga, AL',
            NAMELSAD: 'Talladega-Sylacauga, AL Micro Area',
            LSAD: 'M2',
            ALAND: 1908346574,
            AWATER: 60874403,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-124.160207315876, 43.437395, -121.76900500000002, 44.290537],
            type: 'Polygon',
            coordinates: [
                [
                    [-124.150267, 43.91085],
                    [-124.12240600000001, 44.104442],
                    [-124.11105400000001, 44.235071],
                    [-124.114370074258, 44.276194],
                    [-123.961647, 44.277376],
                    [-123.941075, 44.282951],
                    [-123.775598, 44.283547],
                    [-123.59332300000001, 44.276694],
                    [-123.18038800000001, 44.283725],
                    [-123.165428, 44.200071],
                    [-122.905755, 44.200736],
                    [-122.903999, 44.25912900000001],
                    [-122.865465, 44.287317],
                    [-122.76273499999999, 44.290537],
                    [-122.646812, 44.266618],
                    [-122.577019, 44.227881],
                    [-122.38456100000002, 44.217063],
                    [-122.32715, 44.252841],
                    [-121.799426, 44.25828],
                    [-121.77790500000002, 44.218877],
                    [-121.76900500000002, 44.10203500000001],
                    [-121.857063, 43.965248],
                    [-121.869974, 43.911664],
                    [-121.928213, 43.909181],
                    [-121.97511299999998, 43.85757],
                    [-121.962098, 43.763537],
                    [-121.986267, 43.661708],
                    [-121.964918, 43.627045],
                    [-122.00236199999999, 43.615498],
                    [-122.131009, 43.557284],
                    [-122.13203400000002, 43.440221],
                    [-122.741716, 43.437395],
                    [-122.741845, 43.544654],
                    [-123.10768600000002, 43.54001],
                    [-123.137211, 43.605906],
                    [-123.137319, 43.77967],
                    [-123.348246, 43.78017],
                    [-123.34820000000002, 43.809212],
                    [-123.470371, 43.810056],
                    [-123.579782, 43.86829],
                    [-123.61901200000001, 43.921117],
                    [-123.7038, 43.944319],
                    [-123.82700399999999, 43.944837],
                    [-123.94629100000002, 43.862682],
                    [-124.160207315876, 43.8637205015216],
                    [-124.150267, 43.91085],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '21660',
            AFFGEOID: '310M600US21660',
            GEOID: '21660',
            NAME: 'Eugene-Springfield, OR',
            NAMELSAD: 'Eugene-Springfield, OR Metro Area',
            LSAD: 'M1',
            ALAND: 11795044830,
            AWATER: 436058218,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.026319, 30.228314, -87.366601, 31.319017],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.026319, 30.753358],
                    [-87.944546, 30.827046000000003],
                    [-87.979688, 30.888993],
                    [-87.92429, 30.935405000000003],
                    [-87.965187, 30.967784000000005],
                    [-87.941152, 31.048161],
                    [-87.976222, 31.089534999999998],
                    [-87.940121, 31.147237],
                    [-87.972869, 31.162694],
                    [-87.946588, 31.19293],
                    [-87.83592, 31.231053],
                    [-87.804023, 31.319017],
                    [-87.765152, 31.297346],
                    [-87.714287, 31.302117],
                    [-87.61589, 31.244458000000005],
                    [-87.61537, 31.000181],
                    [-87.5988289462621, 30.997422176905296],
                    [-87.598937, 30.997422000000004],
                    [-87.592064, 30.95146],
                    [-87.634943, 30.865857],
                    [-87.542268, 30.767481000000004],
                    [-87.523621, 30.738285],
                    [-87.442291, 30.692661],
                    [-87.40018900000001, 30.657201],
                    [-87.401189, 30.604383000000002],
                    [-87.43145, 30.550252],
                    [-87.444722, 30.507484000000005],
                    [-87.414685, 30.457289],
                    [-87.366601, 30.436642999999997],
                    [-87.431784, 30.403193],
                    [-87.452282, 30.344097],
                    [-87.518324, 30.280435],
                    [-87.65688800000001, 30.249709000000003],
                    [-87.818867, 30.228314],
                    [-87.893201, 30.239237],
                    [-87.806466, 30.279798000000003],
                    [-87.796717, 30.324198],
                    [-87.865017, 30.383450000000003],
                    [-87.914136, 30.446144000000004],
                    [-87.933355, 30.487357],
                    [-87.901711, 30.550879],
                    [-87.914956, 30.585893],
                    [-87.93107, 30.652694],
                    [-88.008396, 30.684955999999996],
                    [-88.026319, 30.753358],
                ],
            ],
        },
        properties: {
            CSAFP: '380',
            CBSAFP: '19300',
            AFFGEOID: '310M600US19300',
            GEOID: '19300',
            NAME: 'Daphne-Fairhope-Foley, AL',
            NAMELSAD: 'Daphne-Fairhope-Foley, AL Metro Area',
            LSAD: 'M1',
            ALAND: 4117656514,
            AWATER: 1132955729,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-124.219057676906, 42.70261, -121.97419900000001, 43.944837],
            type: 'Polygon',
            coordinates: [
                [
                    [-124.19345500000001, 43.706085],
                    [-124.160207315876, 43.8637205015216],
                    [-123.94629100000002, 43.862682],
                    [-123.82700399999999, 43.944837],
                    [-123.7038, 43.944319],
                    [-123.61901200000001, 43.921117],
                    [-123.579782, 43.86829],
                    [-123.470371, 43.810056],
                    [-123.34820000000002, 43.809212],
                    [-123.348246, 43.78017],
                    [-123.137319, 43.77967],
                    [-123.137211, 43.605906],
                    [-123.10768600000002, 43.54001],
                    [-122.741845, 43.544654],
                    [-122.741716, 43.437395],
                    [-122.13203400000002, 43.440221],
                    [-122.01040300000001, 43.344697],
                    [-121.97419900000001, 43.261693],
                    [-122.03965000000001, 43.214102],
                    [-122.093776, 43.076257],
                    [-122.282585, 43.067719],
                    [-122.28273, 42.996499],
                    [-122.401025, 42.996632],
                    [-122.46022400000001, 42.939873],
                    [-122.560299, 42.92469],
                    [-122.677181, 42.88108],
                    [-122.794776, 42.778221],
                    [-122.95119899999999, 42.774773],
                    [-123.00976499999999, 42.752144],
                    [-123.152233, 42.755835],
                    [-123.22961900000001, 42.70261],
                    [-123.27428300000001, 42.73203],
                    [-123.44545600000001, 42.703025],
                    [-123.581699, 42.73992],
                    [-123.71650500000001, 42.739658],
                    [-123.71649200000002, 42.784061],
                    [-123.783304, 42.799426],
                    [-123.81155300000002, 42.788837],
                    [-123.821118, 42.996291],
                    [-123.761594, 42.996318],
                    [-123.761333, 43.08316],
                    [-123.70214399999999, 43.087083],
                    [-123.704752, 43.257741],
                    [-123.764356, 43.258054],
                    [-123.761528, 43.422294],
                    [-123.81761400000002, 43.431306],
                    [-123.81665700000002, 43.516449],
                    [-123.87562800000002, 43.515947000000004],
                    [-123.87569799999999, 43.608407],
                    [-124.219057676906, 43.610931759189],
                    [-124.19345500000001, 43.706085],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '40700',
            AFFGEOID: '310M600US40700',
            GEOID: '40700',
            NAME: 'Roseburg, OR',
            NAMELSAD: 'Roseburg, OR Micro Area',
            LSAD: 'M2',
            ALAND: 13042399612,
            AWATER: 251900488,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-119.71211200000002, 38.4134036584505, -118.755059, 39.737129],
            type: 'Polygon',
            coordinates: [
                [
                    [-119.71211200000002, 39.251046],
                    [-119.478792, 39.337851],
                    [-119.28311600000002, 39.622317],
                    [-119.25734199999998, 39.65132],
                    [-119.18934400000002, 39.651423],
                    [-119.190676, 39.631787],
                    [-119.164262, 39.654694],
                    [-119.058566, 39.737129],
                    [-119.077136, 39.722637],
                    [-119.11780200000001, 39.518946],
                    [-119.11771400000002, 39.388658],
                    [-118.987721, 39.285474],
                    [-118.890663, 39.2272],
                    [-118.82942899999999, 39.156334],
                    [-118.75506800000001, 39.112811],
                    [-118.755059, 39.074702],
                    [-118.89941400000001, 39.075243],
                    [-118.92303700000001, 39.069408],
                    [-119.014858, 38.94414],
                    [-119.014499, 38.851951],
                    [-118.902141, 38.851922],
                    [-118.907473, 38.764654],
                    [-118.90624399999999, 38.415363],
                    [-119.15581582440899, 38.4134036584505],
                    [-119.27926199999999, 38.499914000000004],
                    [-119.330366469799, 38.5355095158185],
                    [-119.349893, 38.736227],
                    [-119.405969, 38.733543],
                    [-119.43980500000002, 38.882088],
                    [-119.40185199999999, 38.983651],
                    [-119.31048499999999, 39.019389],
                    [-119.310841, 39.084681],
                    [-119.55038599999999, 39.086259],
                    [-119.56201200000001, 39.194847],
                    [-119.647945, 39.19506],
                    [-119.71211200000002, 39.251046],
                ],
            ],
        },
        properties: {
            CSAFP: '456',
            CBSAFP: '22280',
            AFFGEOID: '310M600US22280',
            GEOID: '22280',
            NAME: 'Fernley, NV',
            NAMELSAD: 'Fernley, NV Micro Area',
            LSAD: 'M2',
            ALAND: 5187939862,
            AWATER: 59489121,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.29887900000001, 42.070366, -84.709556, 42.42152],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.29887900000001, 42.419849],
                    [-85.071609, 42.421428],
                    [-84.718493, 42.42152],
                    [-84.709556, 42.070366],
                    [-84.826491, 42.07246800000001],
                    [-85.293626, 42.071553],
                    [-85.29887900000001, 42.419849],
                ],
            ],
        },
        properties: {
            CSAFP: '310',
            CBSAFP: '12980',
            AFFGEOID: '310M600US12980',
            GEOID: '12980',
            NAME: 'Battle Creek, MI',
            NAMELSAD: 'Battle Creek, MI Metro Area',
            LSAD: 'M1',
            ALAND: 1829210175,
            AWATER: 31256789,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.851989, 35.964691, -90.1891019136628, 36.267032],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.819211, 36.080378],
                    [-90.747519, 36.149112],
                    [-90.809812, 36.14966],
                    [-90.806676, 36.267032],
                    [-90.319581, 36.259139],
                    [-90.32096, 36.200575],
                    [-90.1891019136628, 36.1989984552544],
                    [-90.220425, 36.184764],
                    [-90.23558500000001, 36.139474],
                    [-90.294492, 36.112949],
                    [-90.339343, 36.047112000000006],
                    [-90.36871800000002, 35.995812],
                    [-90.387324, 35.964691],
                    [-90.851989, 35.967879],
                    [-90.819211, 36.080378],
                ],
            ],
        },
        properties: {
            CSAFP: '308',
            CBSAFP: '37500',
            AFFGEOID: '310M600US37500',
            GEOID: '37500',
            NAME: 'Paragould, AR',
            NAMELSAD: 'Paragould, AR Micro Area',
            LSAD: 'M2',
            ALAND: 1495304293,
            AWATER: 5273183,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-94.8545550676107, 43.500175476707604, -94.247123, 43.848099],
            type: 'Polygon',
            coordinates: [
                [
                    [-94.854444, 43.848099],
                    [-94.368974, 43.848047],
                    [-94.247123, 43.847946],
                    [-94.2479674415552, 43.500175476707604],
                    [-94.390597, 43.500469],
                    [-94.4428499897182, 43.5004785076547],
                    [-94.8545550676107, 43.5005534191522],
                    [-94.854444, 43.848099],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '21860',
            AFFGEOID: '310M600US21860',
            GEOID: '21860',
            NAME: 'Fairmont, MN',
            NAMELSAD: 'Fairmont, MN Micro Area',
            LSAD: 'M2',
            ALAND: 1844928074,
            AWATER: 44669375,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-77.599278, 41.06884, -76.447597, 41.596919],
            type: 'Polygon',
            coordinates: [
                [
                    [-77.599278, 41.542271],
                    [-76.960481, 41.551517],
                    [-76.874714, 41.596919],
                    [-76.813731, 41.590034],
                    [-76.749497, 41.405572],
                    [-76.592532, 41.304332],
                    [-76.447597, 41.275629],
                    [-76.592607, 41.157765],
                    [-76.640767, 41.155718],
                    [-76.678776, 41.154172],
                    [-76.732672, 41.17204],
                    [-76.883833, 41.157414],
                    [-76.896114, 41.13907],
                    [-76.960229, 41.148801],
                    [-76.97793900000002, 41.087883],
                    [-77.144111, 41.06884],
                    [-77.349765, 41.219918],
                    [-77.526537, 41.358528],
                    [-77.597282, 41.441056],
                    [-77.598129, 41.478576],
                    [-77.599278, 41.542271],
                ],
            ],
        },
        properties: {
            CSAFP: '558',
            CBSAFP: '48700',
            AFFGEOID: '310M600US48700',
            GEOID: '48700',
            NAME: 'Williamsport, PA',
            NAMELSAD: 'Williamsport, PA Metro Area',
            LSAD: 'M1',
            ALAND: 3182886778,
            AWATER: 38917581,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.846743, 39.318716, -91.1828755008197, 39.948235],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.840353, 39.948235],
                    [-91.4368432685991, 39.9452434636785],
                    [-91.428956, 39.90772900000001],
                    [-91.436051, 39.84551],
                    [-91.397853, 39.821122],
                    [-91.361571, 39.787548],
                    [-91.3646166033233, 39.7587182263223],
                    [-91.367753, 39.729029],
                    [-91.3057603349652, 39.6862154700779],
                    [-91.27614, 39.665759],
                    [-91.1828755008197, 39.5982331157954],
                    [-91.460442, 39.450722],
                    [-91.438235, 39.318716],
                    [-91.718467, 39.324505],
                    [-91.717968, 39.339122],
                    [-91.711934, 39.599095],
                    [-91.715247, 39.657644],
                    [-91.846743, 39.658513],
                    [-91.840353, 39.948235],
                ],
            ],
        },
        properties: {
            CSAFP: '448',
            CBSAFP: '25300',
            AFFGEOID: '310M600US25300',
            GEOID: '25300',
            NAME: 'Hannibal, MO',
            NAMELSAD: 'Hannibal, MO Micro Area',
            LSAD: 'M2',
            ALAND: 2348366330,
            AWATER: 55494823,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-67.27135, 18.152831, -66.56662, 18.511706],
            type: 'Polygon',
            coordinates: [
                [
                    [-67.2391283574046, 18.373827545192004],
                    [-67.226744, 18.378247],
                    [-67.1601698778747, 18.4155997471433],
                    [-67.159608, 18.415915],
                    [-67.169011, 18.466352],
                    [-67.125655, 18.511706],
                    [-67.0973034258565, 18.5116679163542],
                    [-67.042276, 18.511594],
                    [-66.95631595186, 18.4939004288703],
                    [-66.947333, 18.401596],
                    [-66.92202, 18.393204],
                    [-66.8989, 18.366108],
                    [-66.893339, 18.367089],
                    [-66.876992, 18.356897],
                    [-66.824223, 18.342998],
                    [-66.826128, 18.323382],
                    [-66.770072, 18.325013],
                    [-66.735947, 18.349675],
                    [-66.717302, 18.315681],
                    [-66.606989, 18.328186],
                    [-66.56662, 18.298549],
                    [-66.597532, 18.283943],
                    [-66.649467, 18.158857],
                    [-66.670494, 18.152831],
                    [-66.69373, 18.216891],
                    [-66.782514, 18.250325],
                    [-66.817271, 18.230336],
                    [-66.832736, 18.22799],
                    [-66.827327, 18.171242],
                    [-66.83676, 18.170553000000005],
                    [-66.897964, 18.187744],
                    [-66.907236, 18.25309],
                    [-66.932119, 18.253387],
                    [-67.03819, 18.28047],
                    [-67.037935, 18.289705],
                    [-67.082002, 18.254946],
                    [-67.135819, 18.245928],
                    [-67.1912212777205, 18.2667488840118],
                    [-67.209963, 18.294974],
                    [-67.2252402484797, 18.297984662974],
                    [-67.235137, 18.299935],
                    [-67.27135, 18.362329],
                    [-67.2391283574046, 18.373827545192004],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '10380',
            AFFGEOID: '310M600US10380',
            GEOID: '10380',
            NAME: 'Aguadilla-Isabela, PR',
            NAMELSAD: 'Aguadilla-Isabela, PR Metro Area',
            LSAD: 'M1',
            ALAND: 1222529589,
            AWATER: 358907268,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-110.053708, 40.9972972928506, -107.50972, 42.270744],
            type: 'Polygon',
            coordinates: [
                [
                    [-110.053708, 42.270744],
                    [-109.496675, 42.263318],
                    [-109.043864, 42.263684],
                    [-108.756031, 42.262875],
                    [-107.639128, 42.261356],
                    [-107.522722, 42.261756],
                    [-107.522321, 42.087957],
                    [-107.50972, 41.657452],
                    [-107.929736, 41.659596],
                    [-107.92988, 41.39853],
                    [-107.918418289849, 41.0012270706441],
                    [-108.250649, 41.000114],
                    [-109.050076, 41.000659],
                    [-109.250735, 41.001009],
                    [-109.715409, 40.998191],
                    [-110.000716736441, 40.9974254597082],
                    [-110.048482995685, 40.9972972928506],
                    [-110.048, 41.578015],
                    [-110.053708, 42.270744],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '40540',
            AFFGEOID: '310M600US40540',
            GEOID: '40540',
            NAME: 'Rock Springs, WY',
            NAMELSAD: 'Rock Springs, WY Micro Area',
            LSAD: 'M2',
            ALAND: 27005754359,
            AWATER: 166230245,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.440181, 40.137107, -75.529694, 40.67727],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.440181, 40.495952],
                    [-76.171624, 40.534947],
                    [-76.017294, 40.57424],
                    [-75.993212, 40.639712],
                    [-75.891473, 40.67727],
                    [-75.529694, 40.446995],
                    [-75.559753, 40.409723],
                    [-75.696782, 40.241863],
                    [-75.873375, 40.137107],
                    [-76.091764, 40.277692],
                    [-76.151215, 40.315971],
                    [-76.205528, 40.360661],
                    [-76.440181, 40.495952],
                ],
            ],
        },
        properties: {
            CSAFP: '428',
            CBSAFP: '39740',
            AFFGEOID: '310M600US39740',
            GEOID: '39740',
            NAME: 'Reading, PA',
            NAMELSAD: 'Reading, PA Metro Area',
            LSAD: 'M1',
            ALAND: 2218072313,
            AWATER: 24220127,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.9532313801504, 35.0007130457328, -82.920881, 35.7030894961701],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.916801, 35.473612],
                    [-83.848502, 35.519259],
                    [-83.771736, 35.562118],
                    [-83.6629119015893, 35.5677997932083],
                    [-83.653159, 35.568309],
                    [-83.58782700000002, 35.566963],
                    [-83.498335, 35.562981],
                    [-83.452431, 35.602918],
                    [-83.421576, 35.611186],
                    [-83.347262, 35.660474],
                    [-83.297154, 35.65775],
                    [-83.26474416829, 35.7030894961701],
                    [-83.181977, 35.671327],
                    [-83.155835, 35.552622],
                    [-83.186322, 35.514368],
                    [-83.164571, 35.509571],
                    [-82.920881, 35.292037],
                    [-82.983216, 35.13192],
                    [-83.056934, 35.053864],
                    [-83.0084105925124, 35.0269348608162],
                    [-83.108399912205, 35.0007130457328],
                    [-83.143257, 35.077927],
                    [-83.223796, 35.157386],
                    [-83.224332, 35.238645],
                    [-83.276955, 35.228045],
                    [-83.339609, 35.330993],
                    [-83.432454, 35.325183],
                    [-83.495347, 35.299633],
                    [-83.680114, 35.2795],
                    [-83.585756, 35.434832],
                    [-83.9532313801504, 35.4600208895767],
                    [-83.916801, 35.473612],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '19000',
            AFFGEOID: '310M600US19000',
            GEOID: '19000',
            NAME: 'Cullowhee, NC',
            NAMELSAD: 'Cullowhee, NC Micro Area',
            LSAD: 'M2',
            ALAND: 2638779066,
            AWATER: 42162991,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.9264658538311, 42.196881, -89.396192, 42.5057868210378],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.9264658538311, 42.5057868210378],
                    [-89.8375948863256, 42.5049103527915],
                    [-89.493216, 42.501514],
                    [-89.4014168110406, 42.5004419310737],
                    [-89.397809, 42.307171],
                    [-89.396192, 42.201916],
                    [-89.688486, 42.199112],
                    [-89.919772, 42.196881],
                    [-89.9264658538311, 42.5057868210378],
                ],
            ],
        },
        properties: {
            CSAFP: '466',
            CBSAFP: '23300',
            AFFGEOID: '310M600US23300',
            GEOID: '23300',
            NAME: 'Freeport, IL',
            NAMELSAD: 'Freeport, IL Micro Area',
            LSAD: 'M2',
            ALAND: 1461392061,
            AWATER: 1350223,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.714850811052, 36.540738, -79.092264, 37.137166],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.640652, 36.856139],
                    [-79.594069, 37.042206],
                    [-79.479921, 37.006035],
                    [-79.442859, 37.055867],
                    [-79.341422, 37.137166],
                    [-79.217104, 37.118874],
                    [-79.194525, 37.055816],
                    [-79.092264, 37.061498],
                    [-79.2184554959223, 36.5414445361761],
                    [-79.3431159193695, 36.5411430999685],
                    [-79.4700566739243, 36.5408361497866],
                    [-79.510647, 36.540738],
                    [-79.5136476625086, 36.5407482050707],
                    [-79.714850811052, 36.5414324847413],
                    [-79.640652, 36.856139],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '19260',
            AFFGEOID: '310M600US19260',
            GEOID: '19260',
            NAME: 'Danville, VA',
            NAMELSAD: 'Danville, VA Micro Area',
            LSAD: 'M2',
            ALAND: 2620550199,
            AWATER: 26245213,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.94214600000001, 27.558473, -97.0436840836865, 28.176848],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.934274, 27.885202],
                    [-97.79851700000002, 27.995659],
                    [-97.801388, 28.03836],
                    [-97.838918, 28.034613],
                    [-97.883148, 28.0569],
                    [-97.904081, 28.114228],
                    [-97.817716, 28.176848],
                    [-97.568482, 28.129846],
                    [-97.541071, 28.164669],
                    [-97.482384, 28.168616],
                    [-97.427171, 28.117668],
                    [-97.317163, 28.137189],
                    [-97.260818, 28.075759],
                    [-97.136312, 27.90439],
                    [-97.09571300000002, 27.957835],
                    [-97.0436840836865, 27.8365323534718],
                    [-97.044846, 27.834466],
                    [-97.090735, 27.785888000000003],
                    [-97.140854, 27.716688],
                    [-97.212679, 27.59642],
                    [-97.2229914075318, 27.5766073164796],
                    [-97.325216, 27.560896],
                    [-97.840536, 27.558473],
                    [-97.94214600000001, 27.635932],
                    [-97.934274, 27.885202],
                ],
            ],
        },
        properties: {
            CSAFP: '204',
            CBSAFP: '18580',
            AFFGEOID: '310M600US18580',
            GEOID: '18580',
            NAME: 'Corpus Christi, TX',
            NAMELSAD: 'Corpus Christi, TX Metro Area',
            LSAD: 'M1',
            ALAND: 3969160234,
            AWATER: 882893802,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.8744102050921, 34.83928, -81.3676041320777, 35.183520599502],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.8744102050921, 35.183520599502],
                    [-81.7681344077739, 35.1797077063839],
                    [-81.494265, 35.169882],
                    [-81.3676041320777, 35.1640921256134],
                    [-81.408816, 35.047077],
                    [-81.487016, 35.034853],
                    [-81.49210800000002, 34.948956],
                    [-81.457257, 34.83928],
                    [-81.481945, 34.866495],
                    [-81.714094, 34.912865],
                    [-81.755098, 34.930323],
                    [-81.806252, 35.055505],
                    [-81.8744102050921, 35.183520599502],
                ],
            ],
        },
        properties: {
            CSAFP: '273',
            CBSAFP: '23500',
            AFFGEOID: '310M600US23500',
            GEOID: '23500',
            NAME: 'Gaffney, SC',
            NAMELSAD: 'Gaffney, SC Micro Area',
            LSAD: 'M2',
            ALAND: 1017770812,
            AWATER: 11691708,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.535591, 28.960053, -81.640925, 29.521004],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.53486, 29.21468],
                    [-82.403237, 29.215623000000004],
                    [-82.405478, 29.361096999999997],
                    [-82.40662, 29.485047999999995],
                    [-82.211449, 29.484067],
                    [-82.199639, 29.422172000000003],
                    [-82.055643, 29.441847000000003],
                    [-82.055899, 29.471232000000004],
                    [-82.004385, 29.501715000000004],
                    [-81.843009, 29.521004],
                    [-81.776205, 29.487448],
                    [-81.741422, 29.371049],
                    [-81.680903, 29.32443],
                    [-81.641916, 29.276765999999995],
                    [-81.640925, 29.168809000000003],
                    [-81.658698, 28.960345],
                    [-81.761654, 28.960932],
                    [-81.95419, 28.960053],
                    [-82.311697, 28.960390999999998],
                    [-82.349612, 28.988672],
                    [-82.458566, 29.04765],
                    [-82.535591, 29.044855],
                    [-82.53486, 29.21468],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '36100',
            AFFGEOID: '310M600US36100',
            GEOID: '36100',
            NAME: 'Ocala, FL',
            NAMELSAD: 'Ocala, FL Metro Area',
            LSAD: 'M1',
            ALAND: 4113994502,
            AWATER: 192281277,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-117.039833386028, 46.5417089920381, -116.329418, 47.1272689922541],
            type: 'Polygon',
            coordinates: [
                [
                    [-117.039833386028, 47.1272689922541],
                    [-116.83526900000001, 47.032452],
                    [-116.45812000000001, 47.036596],
                    [-116.329418, 47.02191500000001],
                    [-116.329436, 46.934669],
                    [-116.32954000000001, 46.627957],
                    [-116.456098, 46.629097],
                    [-116.61174000000001, 46.631245],
                    [-116.719085, 46.542874],
                    [-117.03977765556202, 46.5417089920381],
                    [-117.039833386028, 47.1272689922541],
                ],
            ],
        },
        properties: {
            CSAFP: '446',
            CBSAFP: '34140',
            AFFGEOID: '310M600US34140',
            GEOID: '34140',
            NAME: 'Moscow, ID',
            NAMELSAD: 'Moscow, ID Micro Area',
            LSAD: 'M2',
            ALAND: 2786483032,
            AWATER: 2369979,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.369332, 41.3336315690407, -90.783812, 41.598373],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.368521, 41.423178],
                    [-91.366448, 41.598373],
                    [-90.899853, 41.597133],
                    [-90.783812, 41.596531],
                    [-90.78628200000001, 41.45288800000001],
                    [-90.867282, 41.448215],
                    [-90.924343, 41.42286],
                    [-90.966662, 41.430051],
                    [-91.027787, 41.42360300000001],
                    [-91.065058, 41.36910100000001],
                    [-91.071552, 41.339651],
                    [-91.0740876236741, 41.3343208019205],
                    [-91.0744154980196, 41.3336315690407],
                    [-91.369332, 41.336063],
                    [-91.368521, 41.423178],
                ],
            ],
        },
        properties: {
            CSAFP: '209',
            CBSAFP: '34700',
            AFFGEOID: '310M600US34700',
            GEOID: '34700',
            NAME: 'Muscatine, IA',
            NAMELSAD: 'Muscatine, IA Micro Area',
            LSAD: 'M2',
            ALAND: 1132964480,
            AWATER: 30262337,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-94.78063, 43.847946, -93.767761, 44.456716],
            type: 'Polygon',
            coordinates: [
                [
                    [-94.78063, 44.45665],
                    [-94.62420000000002, 44.45603],
                    [-93.92955, 44.456716],
                    [-93.92789200000001, 44.44541],
                    [-93.94092, 44.412347],
                    [-93.950696, 44.40677500000001],
                    [-93.953264, 44.38367],
                    [-93.963128, 44.381589],
                    [-93.955259, 44.374],
                    [-93.961661, 44.364084],
                    [-93.932685, 44.344885],
                    [-94.025252, 44.25687500000001],
                    [-94.011868, 44.23952],
                    [-93.768031, 44.239384],
                    [-93.767971, 44.195836],
                    [-93.767761, 43.847956],
                    [-94.247123, 43.847946],
                    [-94.368974, 43.848047],
                    [-94.36885000000001, 44.108698],
                    [-94.371731, 44.264448],
                    [-94.491333, 44.359676],
                    [-94.666062, 44.394048],
                    [-94.78063, 44.45665],
                ],
            ],
        },
        properties: {
            CSAFP: '359',
            CBSAFP: '31860',
            AFFGEOID: '310M600US31860',
            GEOID: '31860',
            NAME: 'Mankato, MN',
            NAMELSAD: 'Mankato, MN Metro Area',
            LSAD: 'M1',
            ALAND: 3098768393,
            AWATER: 93477781,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-114.797204, 46.644485, -113.302903, 47.600057],
            type: 'Polygon',
            coordinates: [
                [
                    [-114.797204, 47.268916],
                    [-114.563737, 47.254611000000004],
                    [-114.346111, 47.176235000000005],
                    [-114.264148, 47.12107],
                    [-114.18717000000001, 47.138191],
                    [-113.933503, 47.138289],
                    [-113.933568, 47.18172],
                    [-113.821447, 47.181709],
                    [-113.821166, 47.2687],
                    [-113.87076900000001, 47.288158],
                    [-113.87111, 47.39197],
                    [-113.95118, 47.47599400000001],
                    [-113.908581, 47.514436],
                    [-113.93028900000002, 47.598499],
                    [-113.948843, 47.599718],
                    [-113.634079, 47.600027],
                    [-113.466485, 47.600057],
                    [-113.46645, 47.179186],
                    [-113.302943, 47.179643],
                    [-113.302903, 46.832159],
                    [-113.479832, 46.802772],
                    [-113.47985600000001, 46.744714],
                    [-113.668166, 46.716229],
                    [-113.667996, 46.658372],
                    [-113.827743, 46.660896],
                    [-114.317808, 46.660551],
                    [-114.335724935405, 46.6552729673536],
                    [-114.360709, 46.669059],
                    [-114.453239, 46.649266],
                    [-114.54732100000001, 46.644485],
                    [-114.591116003227, 46.6525504803548],
                    [-114.62148300000001, 46.658143],
                    [-114.626695, 46.712889],
                    [-114.676827162695, 46.73183873981311],
                    [-114.549127, 46.747366],
                    [-114.566129, 46.963405],
                    [-114.525358, 47.00925],
                    [-114.419532, 47.023246],
                    [-114.483694, 47.067508],
                    [-114.566094, 47.06738],
                    [-114.58673500000002, 47.123757],
                    [-114.71228300000001, 47.182187],
                    [-114.712274, 47.22559],
                    [-114.77583299999999, 47.225573],
                    [-114.797204, 47.268916],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '33540',
            AFFGEOID: '310M600US33540',
            GEOID: '33540',
            NAME: 'Missoula, MT',
            NAMELSAD: 'Missoula, MT Metro Area',
            LSAD: 'M1',
            ALAND: 6715861997,
            AWATER: 64585269,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.4695688905042, 36.794365, -79.498699, 37.800973],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.46482, 37.426144],
                    [-80.39988, 37.462314],
                    [-80.291644, 37.536505],
                    [-80.28244, 37.585481],
                    [-80.223386, 37.623185],
                    [-80.2243032443768, 37.6239913711709],
                    [-80.145565, 37.596428],
                    [-80.020554, 37.647442],
                    [-79.91756, 37.701848],
                    [-79.816721, 37.800973],
                    [-79.674162, 37.763093],
                    [-79.683554, 37.661763],
                    [-79.498699, 37.532797],
                    [-79.588526, 37.449596],
                    [-79.626124, 37.455284],
                    [-79.634206, 37.457021],
                    [-79.65879800000002, 37.48501],
                    [-79.69006, 37.476374],
                    [-79.719076, 37.448957],
                    [-79.79049, 37.40879],
                    [-79.81420300000002, 37.403654],
                    [-79.792185, 37.380822],
                    [-79.778616, 37.381783],
                    [-79.788151, 37.351116],
                    [-79.847476, 37.309352],
                    [-79.858405, 37.267155],
                    [-79.84721700000001, 37.225406],
                    [-79.718275, 37.18972],
                    [-79.670485, 37.149621],
                    [-79.594069, 37.042206],
                    [-79.640652, 36.856139],
                    [-79.669153, 36.827838],
                    [-79.741417, 36.806806],
                    [-79.999437, 36.830618],
                    [-80.041493, 36.794365],
                    [-80.06665200000002, 36.841604],
                    [-80.235455, 36.87213],
                    [-80.134619, 36.964655],
                    [-80.128674, 37.122901],
                    [-80.178125, 37.1134],
                    [-80.189592, 37.233449],
                    [-80.262184, 37.341526],
                    [-80.32484, 37.368833],
                    [-80.430943, 37.316378],
                    [-80.44682500000002, 37.327578],
                    [-80.4695688905042, 37.4290254659927],
                    [-80.46482, 37.426144],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '40220',
            AFFGEOID: '310M600US40220',
            GEOID: '40220',
            NAME: 'Roanoke, VA',
            NAMELSAD: 'Roanoke, VA Metro Area',
            LSAD: 'M1',
            ALAND: 4836979660,
            AWATER: 72217444,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-77.501917, 36.028834, -75.76628, 37.603174],
            type: 'Polygon',
            coordinates: [
                [
                    [-77.430127, 36.707658],
                    [-76.953546, 36.94445],
                    [-76.849684, 36.996211],
                    [-76.698255, 37.05914],
                    [-76.641581, 37.15382700000001],
                    [-76.660445, 37.204146],
                    [-76.760314, 37.176799],
                    [-76.79774, 37.214087],
                    [-76.883156, 37.223057],
                    [-76.874437, 37.365427],
                    [-76.904471, 37.377665],
                    [-76.891798, 37.432632],
                    [-76.741984, 37.467076],
                    [-76.712305, 37.431309],
                    [-76.646794, 37.481154],
                    [-76.654076, 37.603174],
                    [-76.55593, 37.555054000000005],
                    [-76.437561, 37.51436],
                    [-76.40411800000001, 37.535542],
                    [-76.2894927971781, 37.5360752880088],
                    [-76.273488, 37.495321],
                    [-76.250454, 37.421886],
                    [-76.24846, 37.375135],
                    [-76.275552, 37.309964],
                    [-76.366751, 37.374495],
                    [-76.393958, 37.39594],
                    [-76.4029458753529, 37.3926000017912],
                    [-76.437525, 37.37975],
                    [-76.38777, 37.30767000000001],
                    [-76.36229, 37.270226],
                    [-76.3769368097175, 37.2494917623633],
                    [-76.394132, 37.22515],
                    [-76.3471899289676, 37.18964449365531],
                    [-76.3431, 37.186551],
                    [-76.311088, 37.138495],
                    [-76.293126918301, 37.1141637008803],
                    [-76.271262, 37.084544],
                    [-76.2838279531207, 37.0528851070211],
                    [-76.304272, 37.001378],
                    [-76.2967922902952, 36.9937909664517],
                    [-76.2809720508166, 36.977743727668],
                    [-76.267962, 36.964547],
                    [-76.189959, 36.931447],
                    [-76.1769475851987, 36.928538680155],
                    [-76.087955, 36.908647],
                    [-76.043054, 36.927547000000004],
                    [-75.996252, 36.922047],
                    [-75.961589, 36.799995],
                    [-75.92174800000001, 36.692051],
                    [-75.890946, 36.630753],
                    [-75.867044, 36.550754],
                    [-75.838437, 36.434897],
                    [-75.79641, 36.290351],
                    [-75.7706514625597, 36.2320803070508],
                    [-75.76628, 36.056956],
                    [-75.840046, 36.028834],
                    [-75.874806, 36.041789],
                    [-75.906295, 36.085877],
                    [-76.022398, 36.086654],
                    [-76.022607, 36.096725],
                    [-76.02939, 36.158819],
                    [-76.12458, 36.263963],
                    [-76.216211, 36.298302],
                    [-76.231329, 36.37208],
                    [-76.437286, 36.467846],
                    [-76.491336, 36.510677],
                    [-76.491405, 36.468648],
                    [-76.453711, 36.378092],
                    [-76.55964600000001, 36.351056],
                    [-76.669975, 36.315214],
                    [-76.696571, 36.296138000000006],
                    [-76.779467, 36.362469000000004],
                    [-76.946258, 36.413822],
                    [-76.9160376328562, 36.5460813302903],
                    [-76.917318, 36.546046],
                    [-77.1643226037182, 36.5461528198479],
                    [-77.190175, 36.546164000000005],
                    [-77.2987701888118, 36.5460390108545],
                    [-77.355111, 36.554122],
                    [-77.414985, 36.654069],
                    [-77.501917, 36.670506],
                    [-77.430127, 36.707658],
                ],
            ],
        },
        properties: {
            CSAFP: '545',
            CBSAFP: '47260',
            AFFGEOID: '310M600US47260',
            GEOID: '47260',
            NAME: 'Virginia Beach-Norfolk-Newport News, VA-NC',
            NAMELSAD: 'Virginia Beach-Norfolk-Newport News, VA-NC Metro Area',
            LSAD: 'M1',
            ALAND: 9143665739,
            AWATER: 3427717114,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.117407, 45.985117, -87.116138, 46.9174499452762],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.116571, 46.419951],
                    [-88.115843, 46.678381],
                    [-87.991546, 46.679113],
                    [-87.991389, 46.765159],
                    [-88.044709, 46.765353],
                    [-88.0445216822815, 46.9174499452762],
                    [-87.900339, 46.909686],
                    [-87.77693, 46.876726],
                    [-87.687164, 46.841742],
                    [-87.595307, 46.78295],
                    [-87.573203, 46.720471],
                    [-87.503025, 46.647497],
                    [-87.38164900000001, 46.580059],
                    [-87.366767, 46.507303],
                    [-87.175065, 46.497548],
                    [-87.1163595894707, 46.5061514021205],
                    [-87.116138, 46.15905],
                    [-87.241506, 46.159103],
                    [-87.242885, 45.985117],
                    [-87.367849, 45.985321],
                    [-87.617091, 45.986014],
                    [-87.615597, 46.246653],
                    [-88.117407, 46.246618],
                    [-88.116571, 46.419951],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '32100',
            AFFGEOID: '310M600US32100',
            GEOID: '32100',
            NAME: 'Marquette, MI',
            NAMELSAD: 'Marquette, MI Micro Area',
            LSAD: 'M2',
            ALAND: 4685420988,
            AWATER: 4185337774,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.418637, 38.007743, -89.138393, 39.523316],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.415832, 38.8453],
                    [-91.264927, 38.843833],
                    [-91.264287, 38.992762],
                    [-91.260232, 39.139845],
                    [-91.18546, 39.13905],
                    [-91.182942, 39.227233],
                    [-90.7232836234127, 39.2241029700576],
                    [-90.72996, 39.255894],
                    [-90.840106, 39.340438],
                    [-90.9352871462396, 39.3994805728603],
                    [-90.613775, 39.395416],
                    [-90.59123, 39.198624],
                    [-90.61454000000002, 39.14229],
                    [-90.603569, 39.117592],
                    [-90.580587, 39.184895],
                    [-90.313289, 39.174289],
                    [-90.31393, 39.225128],
                    [-90.148121, 39.261947],
                    [-90.153782, 39.520315],
                    [-89.926037, 39.522104000000006],
                    [-89.701661, 39.523316],
                    [-89.698555, 38.998979],
                    [-89.639265, 38.999129],
                    [-89.586344, 39.028329],
                    [-89.25030900000002, 39.028185],
                    [-89.25736, 38.99917],
                    [-89.256714, 38.917403],
                    [-89.254185, 38.742018],
                    [-89.138393, 38.73633100000001],
                    [-89.143866, 38.503089],
                    [-89.351483, 38.518861],
                    [-89.48188, 38.46859],
                    [-89.572447, 38.482831],
                    [-89.704245, 38.415779],
                    [-89.703256, 38.219404],
                    [-89.899043, 38.220755],
                    [-90.036316, 38.223246],
                    [-90.03586700000001, 38.135736],
                    [-90.2038911808417, 38.0873651230282],
                    [-90.218708, 38.094365],
                    [-90.252484, 38.127571],
                    [-90.2527463239757, 38.1277738262293],
                    [-90.253076, 38.11553800000001],
                    [-90.416022, 38.042315],
                    [-90.628084, 38.007743],
                    [-90.63998, 38.076548],
                    [-90.684547, 38.086311],
                    [-90.780185, 38.204112],
                    [-90.970187, 38.206687],
                    [-91.095765, 38.204083],
                    [-91.349553, 38.204078],
                    [-91.367482, 38.209741],
                    [-91.369192, 38.699324],
                    [-91.418637, 38.709778],
                    [-91.415832, 38.8453],
                ],
            ],
        },
        properties: {
            CSAFP: '476',
            CBSAFP: '41180',
            AFFGEOID: '310M600US41180',
            GEOID: '41180',
            NAME: 'St. Louis, MO-IL',
            NAMELSAD: 'St. Louis, MO-IL Metro Area',
            LSAD: 'M1',
            ALAND: 20366491185,
            AWATER: 565587191,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-94.746925, 33.019219, -93.664921, 33.945249],
            type: 'Polygon',
            coordinates: [
                [
                    [-94.746096, 33.703016],
                    [-94.7319329019959, 33.7208305162803],
                    [-94.714865, 33.707261],
                    [-94.630586, 33.673401],
                    [-94.572872, 33.669886],
                    [-94.528928, 33.62184],
                    [-94.485875, 33.637867],
                    [-94.481842, 33.78900800000001],
                    [-94.4772691832212, 33.9408298614626],
                    [-94.408441, 33.945249],
                    [-94.303523, 33.831608],
                    [-94.182551, 33.788442],
                    [-94.040865, 33.781979],
                    [-93.95891000000002, 33.751058],
                    [-93.956951, 33.750927],
                    [-93.969675, 33.739666],
                    [-93.967717, 33.675836],
                    [-93.825878, 33.609891],
                    [-93.772085, 33.498602],
                    [-93.719951, 33.48203],
                    [-93.74476, 33.44458000000001],
                    [-93.6991, 33.387929],
                    [-93.70525300000001, 33.339197],
                    [-93.664921, 33.329487],
                    [-93.739424, 33.20181],
                    [-93.736622, 33.166081],
                    [-93.832852, 33.148479],
                    [-93.863322, 33.098852],
                    [-93.8071252110518, 33.0193945746385],
                    [-93.8145980610549, 33.0193890113342],
                    [-94.04296400000001, 33.019219],
                    [-94.042719, 33.160291],
                    [-94.0429458450603, 33.2712410493864],
                    [-94.056144, 33.298036],
                    [-94.218517, 33.296936],
                    [-94.231743, 33.240857],
                    [-94.379409, 33.255651],
                    [-94.415739, 33.284017],
                    [-94.517868, 33.282096],
                    [-94.572463, 33.249826],
                    [-94.65226, 33.268861],
                    [-94.691826, 33.312405],
                    [-94.746925, 33.328938],
                    [-94.746096, 33.703016],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '45500',
            AFFGEOID: '310M600US45500',
            GEOID: '45500',
            NAME: 'Texarkana, TX-AR',
            NAMELSAD: 'Texarkana, TX-AR Metro Area',
            LSAD: 'M1',
            ALAND: 5284182328,
            AWATER: 218349567,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.119058, 30.6585269137505, -83.736158, 31.077970999999998],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.116644, 31.077970999999998],
                    [-84.003627, 31.077290000000005],
                    [-84.003876, 31.041582],
                    [-83.736158, 31.037678999999997],
                    [-83.743729, 30.6585269137505],
                    [-83.820973, 30.662603],
                    [-84.007454, 30.672069968469202],
                    [-84.0837530823, 30.675943397968],
                    [-84.075958, 30.912538],
                    [-84.119058, 30.980956000000003],
                    [-84.116644, 31.077970999999998],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '45620',
            AFFGEOID: '310M600US45620',
            GEOID: '45620',
            NAME: 'Thomasville, GA',
            NAMELSAD: 'Thomasville, GA Micro Area',
            LSAD: 'M2',
            ALAND: 1410592607,
            AWATER: 19645695,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.817682, 33.965912, -83.357051, 34.29538],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.817682, 34.127493],
                    [-83.619918, 34.29538],
                    [-83.561521, 34.252712],
                    [-83.479238, 34.26071],
                    [-83.402428, 34.197499],
                    [-83.357051, 34.116199],
                    [-83.386763, 34.050241],
                    [-83.360028, 34.040572],
                    [-83.384863, 34.037805],
                    [-83.454025, 34.004809],
                    [-83.503054, 33.999577],
                    [-83.537385, 33.965912],
                    [-83.563277, 34.031864],
                    [-83.767532, 34.066448],
                    [-83.817682, 34.127493],
                ],
            ],
        },
        properties: {
            CSAFP: '122',
            CBSAFP: '27600',
            AFFGEOID: '310M600US27600',
            GEOID: '27600',
            NAME: 'Jefferson, GA',
            NAMELSAD: 'Jefferson, GA Micro Area',
            LSAD: 'M2',
            ALAND: 879711035,
            AWATER: 8858770,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.354132, 35.94157100000001, -96.619655, 36.246478],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.354132, 36.159072],
                    [-97.140668, 36.159231],
                    [-97.140968, 36.246435],
                    [-96.925069, 36.246474],
                    [-96.818968, 36.246478],
                    [-96.819005, 36.15888600000001],
                    [-96.62212, 36.159903],
                    [-96.62161, 36.154323],
                    [-96.619655, 35.94157100000001],
                    [-97.140583, 35.941991],
                    [-97.203592, 35.944795],
                    [-97.32362, 36.010877],
                    [-97.35387, 35.984934],
                    [-97.354132, 36.159072],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '44660',
            AFFGEOID: '310M600US44660',
            GEOID: '44660',
            NAME: 'Stillwater, OK',
            NAMELSAD: 'Stillwater, OK Micro Area',
            LSAD: 'M2',
            ALAND: 1773885850,
            AWATER: 31991880,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-66.95631595186, 18.315681, -66.579868, 18.494199],
            type: 'Polygon',
            coordinates: [
                [
                    [-66.95631595186, 18.4939004288703],
                    [-66.924089, 18.487267],
                    [-66.9015667585517, 18.4882612574349],
                    [-66.836591129039, 18.4911296443528],
                    [-66.79932, 18.492775],
                    [-66.7655707778363, 18.4827960078403],
                    [-66.733986, 18.473457],
                    [-66.624618, 18.494199],
                    [-66.5862547756768, 18.4879522919662],
                    [-66.579868, 18.467957],
                    [-66.588181, 18.389408],
                    [-66.590785, 18.33806],
                    [-66.606989, 18.328186],
                    [-66.717302, 18.315681],
                    [-66.735947, 18.349675],
                    [-66.770072, 18.325013],
                    [-66.826128, 18.323382],
                    [-66.824223, 18.342998],
                    [-66.876992, 18.356897],
                    [-66.893339, 18.367089],
                    [-66.8989, 18.366108],
                    [-66.92202, 18.393204],
                    [-66.947333, 18.401596],
                    [-66.95631595186, 18.4939004288703],
                ],
            ],
        },
        properties: {
            CSAFP: '490',
            CBSAFP: '11640',
            AFFGEOID: '310M600US11640',
            GEOID: '11640',
            NAME: 'Arecibo, PR',
            NAMELSAD: 'Arecibo, PR Metro Area',
            LSAD: 'M1',
            ALAND: 613186622,
            AWATER: 231848340,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.04653, 37.916848, -75.048939, 38.961873],
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [-75.942375, 38.18706600000001],
                        [-75.864104, 38.200858],
                        [-75.8754473114647, 38.219709301961],
                        [-75.888513, 38.241423],
                        [-75.9237660824283, 38.246285277002],
                        [-75.928628, 38.297272],
                        [-75.864213, 38.350782],
                        [-75.823706, 38.481022],
                        [-75.701777515245, 38.5607669290786],
                        [-75.707551, 38.635335],
                        [-75.707555026429, 38.6353853550599],
                        [-75.7231026933327, 38.8298265565277],
                        [-75.555013, 38.835649],
                        [-75.484125, 38.904448],
                        [-75.410463, 38.916418],
                        [-75.380649, 38.961873],
                        [-75.3066521095097, 38.9476601633284],
                        [-75.302552, 38.939002],
                        [-75.304078, 38.91316],
                        [-75.232029, 38.844254],
                        [-75.159022, 38.790193],
                        [-75.113331, 38.782998],
                        [-75.089473, 38.797198],
                        [-75.071805, 38.696497],
                        [-75.053973, 38.536273],
                        [-75.048939, 38.451263],
                        [-75.085518, 38.32427],
                        [-75.102947, 38.311525],
                        [-75.143229, 38.220475],
                        [-75.177394, 38.130014],
                        [-75.193796, 38.096013],
                        [-75.242266, 38.027209],
                        [-75.624341, 37.994211],
                        [-75.669711, 37.950796],
                        [-75.722662, 37.97131000000001],
                        [-75.783815, 37.972594],
                        [-75.860727, 37.91831],
                        [-75.892686, 37.916848],
                        [-75.89895600000001, 37.974514],
                        [-75.857507, 38.038778],
                        [-75.85888100000001, 38.060135],
                        [-75.86381, 38.100968],
                        [-75.937089, 38.124209],
                        [-75.942375, 38.18706600000001],
                    ],
                ],
                [
                    [
                        [-76.046213, 38.025533],
                        [-76.007337, 38.036706],
                        [-75.980089, 38.004891],
                        [-75.984648, 37.938121],
                        [-76.04653, 37.953586],
                        [-76.046213, 38.025533],
                    ],
                ],
            ],
        },
        properties: {
            CSAFP: '480',
            CBSAFP: '41540',
            AFFGEOID: '310M600US41540',
            GEOID: '41540',
            NAME: 'Salisbury, MD-DE',
            NAMELSAD: 'Salisbury, MD-DE Metro Area',
            LSAD: 'M1',
            ALAND: 5435702462,
            AWATER: 2080034554,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.502208, 33.769801, -92.886136, 34.340839],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.47848500000002, 34.340839],
                    [-93.40818, 34.339467],
                    [-93.356877, 34.280308],
                    [-93.19828, 34.277301],
                    [-93.034967, 34.236605],
                    [-93.038473, 34.15515],
                    [-92.886136, 34.155699],
                    [-92.89306, 33.983293],
                    [-92.961217, 33.973977],
                    [-92.893303, 33.902403],
                    [-92.893216, 33.810069],
                    [-92.917878, 33.82164],
                    [-93.031537, 33.769801],
                    [-93.104405, 33.777093],
                    [-93.258544, 33.828893],
                    [-93.373364, 33.957093],
                    [-93.435069, 34.07049],
                    [-93.461694, 34.20581],
                    [-93.502208, 34.21410600000001],
                    [-93.47848500000002, 34.340839],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '11660',
            AFFGEOID: '310M600US11660',
            GEOID: '11660',
            NAME: 'Arkadelphia, AR',
            NAMELSAD: 'Arkadelphia, AR Micro Area',
            LSAD: 'M2',
            ALAND: 2242855171,
            AWATER: 43965580,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-94.443083, 42.209161, -93.93158, 42.645164],
            type: 'Polygon',
            coordinates: [
                [
                    [-94.443083, 42.645164],
                    [-93.971714, 42.644707],
                    [-93.971583, 42.558139],
                    [-93.931684, 42.472011],
                    [-93.93158, 42.209908],
                    [-94.16470400000001, 42.20992],
                    [-94.39752600000001, 42.209161],
                    [-94.397671, 42.4733],
                    [-94.443033, 42.473378],
                    [-94.44302400000001, 42.558843],
                    [-94.443083, 42.645164],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '22700',
            AFFGEOID: '310M600US22700',
            GEOID: '22700',
            NAME: 'Fort Dodge, IA',
            NAMELSAD: 'Fort Dodge, IA Micro Area',
            LSAD: 'M2',
            ALAND: 1853670894,
            AWATER: 7269394,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.26474416829, 35.1464753478475, -82.16904900000002, 36.062105],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.2561415523036, 35.7151240626578],
                    [-83.255351, 35.71623],
                    [-83.198267, 35.725494],
                    [-83.161537, 35.763363],
                    [-83.097193, 35.776067],
                    [-83.04853, 35.787706],
                    [-82.978414, 35.78261],
                    [-82.9666497428986, 35.795445979574],
                    [-82.937437, 35.82732],
                    [-82.89971800000001, 35.874602],
                    [-82.910608, 35.926930000000006],
                    [-82.89375141843142, 35.9338572697089],
                    [-82.860724, 35.94743],
                    [-82.81613, 35.923986],
                    [-82.787465, 35.952163],
                    [-82.779397, 35.992511],
                    [-82.725065, 36.018204],
                    [-82.628365, 36.062105],
                    [-82.6057036107936, 36.0371988635619],
                    [-82.595525, 36.026012],
                    [-82.610885, 35.974442],
                    [-82.557874, 35.953901],
                    [-82.5080076597225, 35.9820132054415],
                    [-82.494781, 35.909484],
                    [-82.408602, 35.818178],
                    [-82.361956, 35.821245],
                    [-82.335054, 35.749396],
                    [-82.284733, 35.725526],
                    [-82.275399, 35.704438],
                    [-82.291026, 35.591322],
                    [-82.16904900000002, 35.527811],
                    [-82.233203, 35.519402],
                    [-82.265795, 35.467818],
                    [-82.280563, 35.443069],
                    [-82.261305, 35.393198],
                    [-82.359152, 35.244489],
                    [-82.3531752729195, 35.1987141296007],
                    [-82.411301, 35.202483],
                    [-82.455609, 35.177425],
                    [-82.53256, 35.155617],
                    [-82.5777151484413, 35.1464753478475],
                    [-82.619248, 35.303708],
                    [-82.745139, 35.422967],
                    [-82.831628, 35.31859],
                    [-82.920881, 35.292037],
                    [-83.164571, 35.509571],
                    [-83.186322, 35.514368],
                    [-83.155835, 35.552622],
                    [-83.181977, 35.671327],
                    [-83.26474416829, 35.7030894961701],
                    [-83.2561415523036, 35.7151240626578],
                ],
            ],
        },
        properties: {
            CSAFP: '120',
            CBSAFP: '11700',
            AFFGEOID: '310M600US11700',
            GEOID: '11700',
            NAME: 'Asheville, NC',
            NAMELSAD: 'Asheville, NC Metro Area',
            LSAD: 'M1',
            ALAND: 5264465453,
            AWATER: 21752837,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.755614, 30.909877, -88.834339, 31.796863000000002],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.755103, 31.774654],
                    [-89.653025, 31.780653],
                    [-89.401227, 31.796863000000002],
                    [-89.399178, 31.434029],
                    [-89.146092, 31.434052],
                    [-88.943468, 31.433458],
                    [-88.841707, 31.433703],
                    [-88.840896, 31.006553],
                    [-88.834339, 30.997983],
                    [-88.834475, 30.910323],
                    [-88.885038, 30.910788000000004],
                    [-89.137967, 30.909877],
                    [-89.340806, 30.909912000000002],
                    [-89.340716, 30.995366000000004],
                    [-89.34895, 31.010370000000005],
                    [-89.42533700000001, 30.982452000000002],
                    [-89.50134, 31.016026000000004],
                    [-89.654038, 31.002502],
                    [-89.654212, 31.433805],
                    [-89.586819, 31.433688],
                    [-89.586919, 31.513443999999996],
                    [-89.755614, 31.609751],
                    [-89.755103, 31.774654],
                ],
            ],
        },
        properties: {
            CSAFP: '279',
            CBSAFP: '25620',
            AFFGEOID: '310M600US25620',
            GEOID: '25620',
            NAME: 'Hattiesburg, MS',
            NAMELSAD: 'Hattiesburg, MS Metro Area',
            LSAD: 'M1',
            ALAND: 5241389920,
            AWATER: 30186739,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.064107, 35.37669, -95.207943, 36.9992010529279],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.064107, 36.684207],
                    [-96.931943, 36.686097],
                    [-96.889475, 36.75123],
                    [-96.752389, 36.782091],
                    [-96.749838, 36.998988],
                    [-96.5255820442829, 36.9986779687248],
                    [-96.500288, 36.998643],
                    [-96.217571, 36.99907],
                    [-96.0008102061996, 36.9992010529279],
                    [-96.00117100000001, 36.423686],
                    [-95.812342, 36.42358],
                    [-95.81234, 36.576134],
                    [-95.80954, 36.59754],
                    [-95.43157, 36.59754],
                    [-95.32837, 36.59763000000001],
                    [-95.32817, 36.51024],
                    [-95.43561, 36.510160000000006],
                    [-95.43997, 36.075268],
                    [-95.207943, 36.074772],
                    [-95.27870100000001, 35.964102],
                    [-95.231459, 35.851195],
                    [-95.265679, 35.813266],
                    [-95.360363, 35.822411],
                    [-95.589736, 35.76516],
                    [-95.65041900000001, 35.856644],
                    [-95.766114, 35.856284],
                    [-95.76626500000002, 35.72575],
                    [-95.713081, 35.725807],
                    [-95.712953, 35.551738],
                    [-95.822361, 35.551693],
                    [-95.822429, 35.463685],
                    [-95.875347, 35.463623],
                    [-95.875405, 35.37669],
                    [-95.981465, 35.376788],
                    [-96.08753, 35.376877],
                    [-96.087777, 35.552003],
                    [-96.1927, 35.551968],
                    [-96.192563, 35.639087],
                    [-96.620828, 35.639005],
                    [-96.619655, 35.94157100000001],
                    [-96.62161, 36.154323],
                    [-96.62212, 36.159903],
                    [-96.819005, 36.15888600000001],
                    [-96.818968, 36.246478],
                    [-96.925069, 36.246474],
                    [-96.924936, 36.333344],
                    [-97.032106, 36.33335400000001],
                    [-97.032118, 36.506957],
                    [-97.009785, 36.506935],
                    [-96.88801700000002, 36.57488600000001],
                    [-96.91275600000002, 36.598949],
                    [-97.057556, 36.593872],
                    [-97.064107, 36.684207],
                ],
            ],
        },
        properties: {
            CSAFP: '538',
            CBSAFP: '46140',
            AFFGEOID: '310M600US46140',
            GEOID: '46140',
            NAME: 'Tulsa, OK',
            NAMELSAD: 'Tulsa, OK Metro Area',
            LSAD: 'M1',
            ALAND: 16239819921,
            AWATER: 491965911,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-111.399765, 43.235178, -110.05157, 44.666291],
            type: 'Polygon',
            coordinates: [
                [
                    [-111.398781, 43.92289],
                    [-111.187256, 43.932114],
                    [-111.047219183002, 43.983431292607705],
                    [-111.048452, 44.114831],
                    [-111.049148, 44.374925],
                    [-111.048974, 44.474072],
                    [-111.055208, 44.624927],
                    [-111.055332727124, 44.6662623099175],
                    [-110.667867, 44.666291],
                    [-110.667899, 44.582792],
                    [-110.375334, 44.583016],
                    [-110.29485, 44.546923],
                    [-110.276506, 44.515665],
                    [-110.29664800000002, 44.43134100000001],
                    [-110.228408, 44.373844],
                    [-110.19019, 44.300497],
                    [-110.1511, 44.292464],
                    [-110.111284, 44.199375],
                    [-110.119193, 44.132584],
                    [-110.053357, 44.132628],
                    [-110.053179, 44.008022],
                    [-110.05157, 43.464783],
                    [-110.05731, 43.377768],
                    [-110.34421800000001, 43.377918],
                    [-110.343959, 43.291165],
                    [-110.57838, 43.29164],
                    [-110.578656, 43.235224],
                    [-110.813993, 43.235178],
                    [-110.813996, 43.31394],
                    [-111.044617, 43.31572],
                    [-111.04536056097, 43.5010514511122],
                    [-111.207561, 43.543857],
                    [-111.243839, 43.621853],
                    [-111.399765, 43.621976],
                    [-111.398781, 43.92289],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '27220',
            AFFGEOID: '310M600US27220',
            GEOID: '27220',
            NAME: 'Jackson, WY-ID',
            NAMELSAD: 'Jackson, WY-ID Micro Area',
            LSAD: 'M2',
            ALAND: 11514930030,
            AWATER: 573727587,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.260818, 27.8365323534718, -96.790512, 28.319238],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.144916, 28.102622],
                    [-97.02358600000001, 28.187657],
                    [-97.12396, 28.272310000000004],
                    [-96.790512, 28.319238],
                    [-96.830928, 28.107894000000005],
                    [-96.8520706227301, 28.0598208616046],
                    [-96.88645900000002, 28.030734000000002],
                    [-97.003325, 27.908307],
                    [-97.0436840836865, 27.8365323534718],
                    [-97.09571300000002, 27.957835],
                    [-97.136312, 27.90439],
                    [-97.260818, 28.075759],
                    [-97.144916, 28.102622],
                ],
            ],
        },
        properties: {
            CSAFP: '204',
            CBSAFP: '40530',
            AFFGEOID: '310M600US40530',
            GEOID: '40530',
            NAME: 'Rockport, TX',
            NAMELSAD: 'Rockport, TX Micro Area',
            LSAD: 'M2',
            ALAND: 652856780,
            AWATER: 714538201,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.724387, 40.350347, -98.27809, 40.698903],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.724387, 40.689772],
                    [-98.72143, 40.698903],
                    [-98.282851, 40.698284],
                    [-98.278103, 40.698291],
                    [-98.27809, 40.350347],
                    [-98.723948, 40.350391],
                    [-98.724387, 40.689772],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '25580',
            AFFGEOID: '310M600US25580',
            GEOID: '25580',
            NAME: 'Hastings, NE',
            NAMELSAD: 'Hastings, NE Micro Area',
            LSAD: 'M2',
            ALAND: 1458872834,
            AWATER: 2477681,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.997426, 35.504573, -85.557535, 35.84636],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.997426, 35.711955],
                    [-85.885156, 35.839658],
                    [-85.868174, 35.84636],
                    [-85.682095, 35.831254],
                    [-85.622195, 35.80181],
                    [-85.602876, 35.794996],
                    [-85.615164, 35.761064],
                    [-85.594632, 35.617898],
                    [-85.557535, 35.532977],
                    [-85.607326, 35.531651],
                    [-85.820334, 35.504573],
                    [-85.876962, 35.524095],
                    [-85.948192, 35.573422],
                    [-85.985062, 35.660108],
                    [-85.997426, 35.711955],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '32660',
            AFFGEOID: '310M600US32660',
            GEOID: '32660',
            NAME: 'McMinnville, TN',
            NAMELSAD: 'McMinnville, TN Micro Area',
            LSAD: 'M2',
            ALAND: 1120619165,
            AWATER: 3524883,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-114.042145146235, 40.769093, -111.221776, 42.000991],
            type: 'Polygon',
            coordinates: [
                [
                    [-114.042145, 40.999926],
                    [-114.041447, 41.207752],
                    [-114.040231, 41.49169],
                    [-114.03990100000001, 41.753781],
                    [-114.04172300000002, 41.99372],
                    [-113.817964, 41.98858],
                    [-113.496548, 41.993305],
                    [-113.249159, 41.996203],
                    [-113.000821, 41.998223],
                    [-113.000040139624, 41.9982276125391],
                    [-112.648019, 42.000307],
                    [-112.264936, 42.000991],
                    [-112.15917745151, 41.9986819297378],
                    [-112.008789, 41.795077],
                    [-112.042792, 41.710433],
                    [-111.981042, 41.534117],
                    [-111.946089, 41.553029],
                    [-111.873197, 41.485174],
                    [-111.885443, 41.42637400000001],
                    [-111.76059, 41.368818],
                    [-111.666576, 41.42883],
                    [-111.510839, 41.4231],
                    [-111.477618, 41.38434800000001],
                    [-111.420728, 41.361308],
                    [-111.331944, 41.357472],
                    [-111.261803, 41.296681],
                    [-111.281298, 41.22497],
                    [-111.221776, 41.215119],
                    [-111.264974, 41.144044],
                    [-111.366604, 41.130018],
                    [-111.380452, 41.087248],
                    [-111.50751, 41.063234],
                    [-111.592981, 40.993015],
                    [-111.524895, 40.953842],
                    [-111.479555, 40.858288],
                    [-111.527985, 40.785362],
                    [-111.641052, 40.798925],
                    [-111.663996, 40.850194],
                    [-111.738744, 40.860998],
                    [-111.946289, 40.82179],
                    [-111.958672, 40.921822000000006],
                    [-112.00656600000002, 40.921846],
                    [-112.260216, 40.769093],
                    [-112.493515, 41.076888],
                    [-112.79935900000001, 40.999939],
                    [-114.042145146235, 40.9998965772633],
                    [-114.042145, 40.999926],
                ],
            ],
        },
        properties: {
            CSAFP: '482',
            CBSAFP: '36260',
            AFFGEOID: '310M600US36260',
            GEOID: '36260',
            NAME: 'Ogden-Clearfield, UT',
            NAMELSAD: 'Ogden-Clearfield, UT Metro Area',
            LSAD: 'M1',
            ALAND: 18725888761,
            AWATER: 3639142499,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.502118, 35.397126, -89.069544, 35.822657],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.502118, 35.580621],
                    [-89.401805, 35.822657],
                    [-89.356033, 35.817635],
                    [-89.338704, 35.789273],
                    [-89.069544, 35.692679],
                    [-89.07887600000001, 35.431428],
                    [-89.182551, 35.432816],
                    [-89.183944, 35.397126],
                    [-89.347491, 35.400338],
                    [-89.375071, 35.399936000000004],
                    [-89.474171, 35.403039],
                    [-89.469862, 35.546566],
                    [-89.502118, 35.580621],
                ],
            ],
        },
        properties: {
            CSAFP: '297',
            CBSAFP: '15140',
            AFFGEOID: '310M600US15140',
            GEOID: '15140',
            NAME: 'Brownsville, TN',
            NAMELSAD: 'Brownsville, TN Micro Area',
            LSAD: 'M2',
            ALAND: 1380741528,
            AWATER: 2472509,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-72.46673, 41.2778492622384, -71.789356, 41.714191],
            type: 'Polygon',
            coordinates: [
                [
                    [-72.46673, 41.583899],
                    [-72.410367, 41.602581],
                    [-72.38415, 41.588652],
                    [-72.32451, 41.61857],
                    [-72.334226, 41.643598],
                    [-72.239559, 41.714191],
                    [-72.157546, 41.657627],
                    [-72.146717, 41.66716900000001],
                    [-72.062051, 41.652239],
                    [-71.954983, 41.634799],
                    [-71.857917, 41.644227],
                    [-71.789464590839, 41.6400171907069],
                    [-71.789356, 41.59691],
                    [-71.7893586727423, 41.5968521603427],
                    [-71.7976736925498, 41.4169104184967],
                    [-71.797683, 41.416709],
                    [-71.839649, 41.412119],
                    [-71.835951, 41.353935],
                    [-71.860513, 41.320248],
                    [-71.886302, 41.33641],
                    [-71.956747, 41.329871],
                    [-72.021898, 41.316838],
                    [-72.094443, 41.314164],
                    [-72.134221, 41.299398],
                    [-72.201422, 41.315697],
                    [-72.235531, 41.300413],
                    [-72.293044, 41.280044],
                    [-72.3400129087019, 41.2778492622384],
                    [-72.37600400000001, 41.376255],
                    [-72.43371, 41.423995000000005],
                    [-72.305531, 41.436282],
                    [-72.32293100000001, 41.518119],
                    [-72.430767, 41.524567],
                    [-72.46673, 41.583899],
                ],
            ],
        },
        properties: {
            CSAFP: '278',
            CBSAFP: '35980',
            AFFGEOID: '310M600US35980',
            GEOID: '35980',
            NAME: 'Norwich-New London, CT',
            NAMELSAD: 'Norwich-New London, CT Metro Area',
            LSAD: 'M1',
            ALAND: 1722511318,
            AWATER: 276863144,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-121.331786, 39.70765800000001, -119.996155, 41.183974],
            type: 'Polygon',
            coordinates: [
                [
                    [-121.331786, 41.183886],
                    [-120.501404, 41.18394],
                    [-119.999866, 41.183974],
                    [-119.99923100000001, 40.865899],
                    [-119.997533, 40.720992],
                    [-119.996155, 40.32125],
                    [-119.997124, 40.126363],
                    [-119.997634, 39.956505],
                    [-119.99993544808501, 39.7224069247242],
                    [-120.015734, 39.708721],
                    [-120.14714900000001, 39.70765800000001],
                    [-120.110627, 39.765779],
                    [-120.108761, 39.93951],
                    [-120.201264, 40.013474],
                    [-120.209641, 40.086008],
                    [-120.341385, 40.115243],
                    [-120.445892, 40.176854],
                    [-120.510817, 40.248945],
                    [-120.65215800000001, 40.30765600000001],
                    [-120.764403, 40.31601],
                    [-120.928582, 40.191931],
                    [-121.061493, 40.256417],
                    [-121.061417, 40.446536],
                    [-121.32782600000002, 40.445367],
                    [-121.319976, 40.905886],
                    [-121.331786, 41.183886],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '45000',
            AFFGEOID: '310M600US45000',
            GEOID: '45000',
            NAME: 'Susanville, CA',
            NAMELSAD: 'Susanville, CA Micro Area',
            LSAD: 'M2',
            ALAND: 11762091407,
            AWATER: 462950203,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.737217, 29.473925000000005, -91.092682, 30.498133000000003],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.637841, 30.072174],
                    [-92.625484, 30.09233],
                    [-92.59112900000001, 30.182365],
                    [-92.63097500000002, 30.374585],
                    [-92.594586, 30.420339000000002],
                    [-92.63202500000001, 30.481181999999997],
                    [-92.493259, 30.480499000000002],
                    [-92.24479500000001, 30.480193],
                    [-92.176059, 30.400471000000003],
                    [-92.17577800000001, 30.3497],
                    [-92.158605, 30.349908999999997],
                    [-92.158554, 30.342445],
                    [-92.142184, 30.298817000000003],
                    [-92.089446, 30.330166],
                    [-92.04943, 30.381307000000003],
                    [-91.988718, 30.368598],
                    [-91.978184, 30.407403000000002],
                    [-91.678896, 30.394731000000004],
                    [-91.756209, 30.498133000000003],
                    [-91.701339, 30.497747],
                    [-91.640078, 30.442673],
                    [-91.621055, 30.309938],
                    [-91.56276, 30.241949000000005],
                    [-91.475303, 30.229491],
                    [-91.463992, 30.102931000000005],
                    [-91.368835, 30.058824],
                    [-91.231842, 30.040385000000004],
                    [-91.22423, 30.025559999999995],
                    [-91.255229, 29.971457000000004],
                    [-91.182817, 29.846172],
                    [-91.092682, 29.801000000000002],
                    [-91.10001, 29.699402],
                    [-91.215195, 29.751210999999998],
                    [-91.386491, 29.783680999999998],
                    [-91.373642, 29.834401],
                    [-91.471953, 29.956304],
                    [-91.624347, 29.94546],
                    [-91.711283, 29.846131],
                    [-91.935286, 29.671939000000002],
                    [-91.8625569965226, 29.667395463759902],
                    [-91.873266, 29.627277],
                    [-91.80373, 29.595952000000004],
                    [-91.711081, 29.569328000000002],
                    [-91.768263, 29.490362000000005],
                    [-91.821579, 29.473925000000005],
                    [-91.915321, 29.518513],
                    [-92.030186, 29.572668999999998],
                    [-92.0428943038274, 29.577480288972],
                    [-92.064513, 29.585665],
                    [-92.158624, 29.581616],
                    [-92.25186, 29.539353999999996],
                    [-92.32346500000001, 29.531497000000005],
                    [-92.40986, 29.547476999999997],
                    [-92.473585, 29.561080999999998],
                    [-92.568038, 29.577397000000005],
                    [-92.6160814772774, 29.5887857069487],
                    [-92.616033, 30.038260000000005],
                    [-92.737217, 30.037455],
                    [-92.637841, 30.072174],
                ],
            ],
        },
        properties: {
            CSAFP: '318',
            CBSAFP: '29180',
            AFFGEOID: '310M600US29180',
            GEOID: '29180',
            NAME: 'Lafayette, LA',
            NAMELSAD: 'Lafayette, LA Metro Area',
            LSAD: 'M1',
            ALAND: 8828934526,
            AWATER: 2349123955,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-74.214625, 42.94129, -73.242042, 43.8081589885495],
            type: 'Polygon',
            coordinates: [
                [
                    [-74.214625, 43.728703],
                    [-74.057005, 43.744513],
                    [-73.43812, 43.803687],
                    [-73.3825261495399, 43.8081589885495],
                    [-73.350707, 43.770463],
                    [-73.3611071516843, 43.7532334572839],
                    [-73.393723, 43.6992],
                    [-73.414546, 43.658209],
                    [-73.424977, 43.598775],
                    [-73.395767, 43.568087],
                    [-73.327702, 43.625913],
                    [-73.292113, 43.584509],
                    [-73.242042, 43.534925],
                    [-73.252832, 43.363493],
                    [-73.2553649410914, 43.3145364556694],
                    [-73.26978, 43.035923],
                    [-73.2700383015528, 43.0300409104512],
                    [-73.273832807021, 42.9436317475265],
                    [-73.430623, 42.95865],
                    [-73.635463, 42.94129],
                    [-73.577876, 43.056888],
                    [-73.594713, 43.305928],
                    [-73.739824, 43.265559],
                    [-73.767498, 43.222123],
                    [-73.835811, 43.253756],
                    [-73.825828, 43.305348],
                    [-73.884139, 43.398041],
                    [-74.1601, 43.371532],
                    [-74.214625, 43.728703],
                ],
            ],
        },
        properties: {
            CSAFP: '104',
            CBSAFP: '24020',
            AFFGEOID: '310M600US24020',
            GEOID: '24020',
            NAME: 'Glens Falls, NY',
            NAMELSAD: 'Glens Falls, NY Metro Area',
            LSAD: 'M1',
            ALAND: 4398863786,
            AWATER: 204966066,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.793741, 40.775445, -76.207827, 41.310198],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.73489100000002, 41.092133],
                    [-76.732672, 41.17204],
                    [-76.678776, 41.154172],
                    [-76.640767, 41.155718],
                    [-76.592607, 41.157765],
                    [-76.447597, 41.275629],
                    [-76.407934, 41.308418],
                    [-76.310261, 41.310198],
                    [-76.319957, 41.211255],
                    [-76.277639, 41.131804],
                    [-76.228975, 41.138466],
                    [-76.207827, 40.94974],
                    [-76.284611, 40.883588],
                    [-76.30717, 40.801809],
                    [-76.380334, 40.775445],
                    [-76.411487, 40.83349],
                    [-76.501598, 40.824765],
                    [-76.528024, 40.882613000000006],
                    [-76.550935, 40.882784],
                    [-76.556977, 40.938609],
                    [-76.661348, 40.967996],
                    [-76.793741, 40.948989],
                    [-76.73489100000002, 41.092133],
                ],
            ],
        },
        properties: {
            CSAFP: '146',
            CBSAFP: '14100',
            AFFGEOID: '310M600US14100',
            GEOID: '14100',
            NAME: 'Bloomsburg-Berwick, PA',
            NAMELSAD: 'Bloomsburg-Berwick, PA Metro Area',
            LSAD: 'M1',
            ALAND: 1588834555,
            AWATER: 24008846,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.917595, 31.962167000000004, -85.852625, 32.76813],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.917595, 32.664169],
                    [-86.71339, 32.661732],
                    [-86.714219, 32.705694],
                    [-86.413116, 32.707386],
                    [-86.413335, 32.750591],
                    [-86.374974, 32.75358],
                    [-86.336694, 32.76813],
                    [-86.31948, 32.753698],
                    [-86.007187, 32.754984],
                    [-85.87986, 32.754528],
                    [-85.886148, 32.493053],
                    [-85.852625, 32.475747],
                    [-86.023012, 32.419978],
                    [-85.991283, 32.336098],
                    [-85.919293, 32.274382],
                    [-85.986557, 32.272342],
                    [-85.999157, 32.250543],
                    [-85.997859, 32.141605],
                    [-85.996853, 32.051049],
                    [-85.995563, 31.967554],
                    [-86.191379, 31.966453000000005],
                    [-86.302217, 31.965065],
                    [-86.303616, 32.051664],
                    [-86.406276, 32.050731],
                    [-86.405005, 31.963775],
                    [-86.448198, 31.964629],
                    [-86.857583, 31.962167000000004],
                    [-86.856576, 32.048352],
                    [-86.906956, 32.04797],
                    [-86.908302, 32.225028],
                    [-86.810313, 32.224747],
                    [-86.864367, 32.274918],
                    [-86.814912, 32.340803],
                    [-86.906403, 32.536712],
                    [-86.917595, 32.664169],
                ],
            ],
        },
        properties: {
            CSAFP: '388',
            CBSAFP: '33860',
            AFFGEOID: '310M600US33860',
            GEOID: '33860',
            NAME: 'Montgomery, AL',
            NAMELSAD: 'Montgomery, AL Metro Area',
            LSAD: 'M1',
            ALAND: 7030291054,
            AWATER: 186430355,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-73.320519, 41.1637262711643, -72.5345648971681, 41.644311],
            type: 'Polygon',
            coordinates: [
                [
                    [-73.320519, 41.503981],
                    [-73.252265, 41.512134],
                    [-73.204197, 41.508527],
                    [-73.15573, 41.515279],
                    [-73.145036, 41.557822],
                    [-73.05801, 41.588647],
                    [-73.058024, 41.60674],
                    [-72.98325, 41.639598],
                    [-72.938501, 41.644311],
                    [-72.94635, 41.556797],
                    [-72.84428, 41.544725],
                    [-72.752181, 41.578894],
                    [-72.748878, 41.544279],
                    [-72.74478, 41.498183],
                    [-72.746141, 41.423297],
                    [-72.678739, 41.433797],
                    [-72.653672, 41.438125],
                    [-72.613919, 41.329428],
                    [-72.5345648971681, 41.2538242743911],
                    [-72.547235, 41.250499],
                    [-72.598036, 41.268698],
                    [-72.653838, 41.265897],
                    [-72.6543540201512, 41.2656263083002],
                    [-72.690439, 41.246697],
                    [-72.760341, 41.241235],
                    [-72.786142, 41.264796],
                    [-72.881445, 41.242597],
                    [-72.9039337600629, 41.2491941344625],
                    [-72.935646, 41.258497],
                    [-72.9705054154992, 41.2412743089963],
                    [-72.9848408127547, 41.234191742814],
                    [-72.986247, 41.23349700000001],
                    [-73.02044900000001, 41.206397],
                    [-73.07945, 41.194015],
                    [-73.1011738216445, 41.1637262711643],
                    [-73.122353, 41.179797],
                    [-73.086752, 41.25459600000001],
                    [-73.086852, 41.276596],
                    [-73.067851, 41.300996000000005],
                    [-73.115754, 41.332196],
                    [-73.147555, 41.367696],
                    [-73.183269, 41.391711],
                    [-73.207688, 41.420544],
                    [-73.29776, 41.448276],
                    [-73.310473, 41.468815],
                    [-73.320519, 41.503981],
                ],
            ],
        },
        properties: {
            CSAFP: '408',
            CBSAFP: '35300',
            AFFGEOID: '310M600US35300',
            GEOID: '35300',
            NAME: 'New Haven-Milford, CT',
            NAMELSAD: 'New Haven-Milford, CT Metro Area',
            LSAD: 'M1',
            ALAND: 1565143224,
            AWATER: 667604268,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.702566, 35.953901, -81.9343692444926, 36.508314],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.680677, 36.4318],
                    [-82.60147400000001, 36.4232],
                    [-82.421572, 36.447254],
                    [-82.299859, 36.396505],
                    [-82.188653, 36.400408],
                    [-81.983843, 36.508314],
                    [-82.058946, 36.367515],
                    [-81.9343692444926, 36.2647162043798],
                    [-81.960101, 36.228131],
                    [-82.02874, 36.124322],
                    [-82.08014300000002, 36.10572],
                    [-82.0811489171324, 36.1056921143326],
                    [-82.127146, 36.104417],
                    [-82.140847, 36.136216],
                    [-82.211251, 36.159012],
                    [-82.2202530102866, 36.153820039843104],
                    [-82.26569, 36.127614],
                    [-82.297655, 36.13351],
                    [-82.346857, 36.115209],
                    [-82.40945800000001, 36.083409],
                    [-82.41693759467321, 36.0729700107085],
                    [-82.464558, 36.006508],
                    [-82.5080076597225, 35.9820132054415],
                    [-82.557874, 35.953901],
                    [-82.610885, 35.974442],
                    [-82.595525, 36.026012],
                    [-82.6057036107936, 36.0371988635619],
                    [-82.595065, 36.096205],
                    [-82.647769, 36.146404],
                    [-82.631794, 36.418769],
                    [-82.702566, 36.410412],
                    [-82.680677, 36.4318],
                ],
            ],
        },
        properties: {
            CSAFP: '304',
            CBSAFP: '27740',
            AFFGEOID: '310M600US27740',
            GEOID: '27740',
            NAME: 'Johnson City, TN',
            NAMELSAD: 'Johnson City, TN Metro Area',
            LSAD: 'M1',
            ALAND: 2211513956,
            AWATER: 25981127,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.834536, 41.742238, -97.019359, 42.43877500000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.834431, 42.437684],
                    [-97.485298, 42.438578],
                    [-97.36759, 42.43877500000001],
                    [-97.367724, 42.351861],
                    [-97.368404, 42.090922],
                    [-97.019359, 42.09057700000001],
                    [-97.019911, 41.74298],
                    [-97.252425, 41.743085],
                    [-97.36807, 41.743207000000005],
                    [-97.83049300000002, 41.742238],
                    [-97.833288, 41.91628600000001],
                    [-97.834536, 42.08975],
                    [-97.834431, 42.437684],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '35740',
            AFFGEOID: '310M600US35740',
            GEOID: '35740',
            NAME: 'Norfolk, NE',
            NAMELSAD: 'Norfolk, NE Micro Area',
            LSAD: 'M2',
            ALAND: 4075292589,
            AWATER: 19597304,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.359034, 34.466558, -85.5828123337853, 34.991098],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.359034, 34.63261],
                    [-86.325569, 34.937323],
                    [-86.256968, 34.937775],
                    [-86.311274, 34.991098],
                    [-85.8639461118069, 34.9870308587481],
                    [-85.605165, 34.984678],
                    [-85.595165, 34.924171],
                    [-85.5828123337853, 34.8604354744776],
                    [-85.785471, 34.624584],
                    [-86.01559400000001, 34.481639],
                    [-86.057712, 34.475994],
                    [-86.087628, 34.466558],
                    [-86.097741, 34.512547],
                    [-86.148464, 34.599069],
                    [-86.182992, 34.599442],
                    [-86.316036, 34.599802],
                    [-86.318264, 34.599801],
                    [-86.326958, 34.599744],
                    [-86.326853, 34.599403],
                    [-86.359034, 34.63261],
                ],
            ],
        },
        properties: {
            CSAFP: '497',
            CBSAFP: '42460',
            AFFGEOID: '310M600US42460',
            GEOID: '42460',
            NAME: 'Scottsboro, AL',
            NAMELSAD: 'Scottsboro, AL Micro Area',
            LSAD: 'M2',
            ALAND: 2792044654,
            AWATER: 126334707,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.139731, 45.282797, -93.759435, 45.822143],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.139731, 45.773416],
                    [-94.64399, 45.77395],
                    [-94.5133, 45.77438],
                    [-94.271776, 45.775208],
                    [-94.353112, 45.821967],
                    [-93.763948, 45.822143],
                    [-93.759435, 45.559646],
                    [-94.149467, 45.559066],
                    [-94.141989, 45.493243],
                    [-94.047327, 45.422861],
                    [-94.09272, 45.342467],
                    [-94.261131, 45.283941],
                    [-94.38344900000001, 45.282797],
                    [-94.383613, 45.32663500000001],
                    [-94.640096, 45.325601],
                    [-94.76308, 45.3261],
                    [-94.762934, 45.412861],
                    [-95.13173, 45.412402],
                    [-95.13967, 45.758891],
                    [-95.139731, 45.773416],
                ],
            ],
        },
        properties: {
            CSAFP: '378',
            CBSAFP: '41060',
            AFFGEOID: '310M600US41060',
            GEOID: '41060',
            NAME: 'St. Cloud, MN',
            NAMELSAD: 'St. Cloud, MN Metro Area',
            LSAD: 'M1',
            ALAND: 4535393406,
            AWATER: 134523861,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.41366900000001, 37.602542, -92.021165, 38.021179],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.409231, 37.85918],
                    [-92.406872, 37.977802],
                    [-92.406275, 38.021179],
                    [-92.18537, 38.016338],
                    [-92.021165, 38.010639],
                    [-92.029258, 37.602542],
                    [-92.183261, 37.605243],
                    [-92.24946300000002, 37.604543],
                    [-92.24886500000001, 37.648442],
                    [-92.41366900000001, 37.713941],
                    [-92.409231, 37.85918],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '22780',
            AFFGEOID: '310M600US22780',
            GEOID: '22780',
            NAME: 'Fort Leonard Wood, MO',
            NAMELSAD: 'Fort Leonard Wood, MO Micro Area',
            LSAD: 'M2',
            ALAND: 1417014582,
            AWATER: 11312724,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-72.062222, 42.69699, -71.2453840329207, 43.207099],
            type: 'Polygon',
            coordinates: [
                [
                    [-72.038149, 43.128807],
                    [-72.035544, 43.180283],
                    [-72.009074, 43.187882],
                    [-71.913215, 43.207099],
                    [-71.8561, 43.115135],
                    [-71.810277, 43.124271],
                    [-71.73256, 43.139786],
                    [-71.667771, 43.152534],
                    [-71.641291, 43.054168],
                    [-71.517467, 43.068909],
                    [-71.512767, 43.049615],
                    [-71.392388, 43.009309],
                    [-71.377186, 42.947145],
                    [-71.451154, 42.9309],
                    [-71.442301, 42.890399],
                    [-71.427067, 42.82484],
                    [-71.360679, 42.798962],
                    [-71.364418, 42.78523700000001],
                    [-71.284261, 42.760113000000004],
                    [-71.2453840329207, 42.7365553285746],
                    [-71.2551101218106, 42.7363970532872],
                    [-71.255605, 42.736389],
                    [-71.294205, 42.69699],
                    [-71.351874, 42.698154],
                    [-71.386943174292, 42.6989845402676],
                    [-71.5425377992987, 42.70266947451811],
                    [-71.636214, 42.704888],
                    [-71.745817, 42.707287],
                    [-71.7725564843125, 42.7080177112404],
                    [-71.8053896027383, 42.7089149435285],
                    [-71.8987687719366, 42.7114667208088],
                    [-71.9290300590535, 42.7122936725326],
                    [-71.97471, 42.789402],
                    [-72.001215, 42.919443],
                    [-72.062222, 42.944796],
                    [-72.038149, 43.128807],
                ],
            ],
        },
        properties: {
            CSAFP: '148',
            CBSAFP: '31700',
            AFFGEOID: '310M600US31700',
            GEOID: '31700',
            NAME: 'Manchester-Nashua, NH',
            NAMELSAD: 'Manchester-Nashua, NH Metro Area',
            LSAD: 'M1',
            ALAND: 2270191096,
            AWATER: 41320770,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-114.743342, 34.209626, -112.529299, 37.000744128882],
            type: 'Polygon',
            coordinates: [
                [
                    [-114.743342, 36.070535],
                    [-114.73616500000001, 36.104367],
                    [-114.66653799999999, 36.117343],
                    [-114.62785500000001, 36.141012],
                    [-114.572031, 36.151610000000005],
                    [-114.511721, 36.150956],
                    [-114.487034, 36.129396],
                    [-114.448654, 36.12641],
                    [-114.41695000000001, 36.145761],
                    [-114.372106, 36.143114],
                    [-114.337273, 36.10802],
                    [-114.316109, 36.063109],
                    [-114.27064500000002, 36.03572],
                    [-114.21369, 36.01561300000001],
                    [-114.151725, 36.024563],
                    [-114.138203, 36.053161],
                    [-114.09987, 36.121654],
                    [-114.046838, 36.194069],
                    [-114.048226, 36.268874],
                    [-114.047584, 36.325573],
                    [-114.049493, 36.604059],
                    [-114.05016077608802, 36.8431415396894],
                    [-114.05060000000002, 37.000396],
                    [-113.96590700000002, 37.000025],
                    [-112.966471, 37.000219],
                    [-112.899190897616, 37.00030122862841],
                    [-112.829501944372, 37.0003864013144],
                    [-112.545094, 37.000734],
                    [-112.538571, 37.000744128882],
                    [-112.529299, 36.952437],
                    [-112.600381, 36.831928],
                    [-112.638679, 36.674478],
                    [-112.620946, 36.59594200000001],
                    [-112.660059, 36.54356],
                    [-112.623017, 36.474736],
                    [-112.629468, 36.391871],
                    [-112.681571, 36.341662],
                    [-112.723991, 36.345992],
                    [-112.792436, 36.285036],
                    [-112.865033, 36.282381],
                    [-112.902949, 36.253761],
                    [-113.009038, 36.23904],
                    [-113.13708, 36.166424],
                    [-113.196751, 36.152949],
                    [-113.224919, 36.091144],
                    [-113.319971, 36.096769],
                    [-113.354176, 36.040368],
                    [-113.317122, 35.961385],
                    [-113.33485, 35.913859],
                    [-113.310523, 35.86435],
                    [-113.33402400000001, 35.803031],
                    [-113.334161, 35.528217],
                    [-113.334105, 35.508416],
                    [-113.334359, 34.561314],
                    [-113.333422, 34.317913000000004],
                    [-113.370507, 34.291968],
                    [-113.539524, 34.306377],
                    [-113.606093, 34.229334],
                    [-113.68277600000002, 34.209626],
                    [-113.73074, 34.240252],
                    [-114.031132, 34.259819],
                    [-114.14081715128, 34.3031275138817],
                    [-114.14093, 34.305919],
                    [-114.172845, 34.344979],
                    [-114.26431700000002, 34.401329],
                    [-114.335372, 34.450038],
                    [-114.378852, 34.450376],
                    [-114.378223, 34.516521],
                    [-114.422382, 34.580711],
                    [-114.465246, 34.691202],
                    [-114.490971, 34.724848],
                    [-114.576452, 34.8153],
                    [-114.63438199999999, 34.87289],
                    [-114.629769, 34.94304],
                    [-114.63348700000002, 35.001857],
                    [-114.62506899999998, 35.068478],
                    [-114.619905, 35.121632],
                    [-114.59912, 35.12105],
                    [-114.572747, 35.138725],
                    [-114.587129, 35.26237600000001],
                    [-114.62713699999999, 35.409504],
                    [-114.6645, 35.449497],
                    [-114.663105, 35.524491],
                    [-114.653406, 35.610789],
                    [-114.68940700000002, 35.651412],
                    [-114.697309, 35.733686],
                    [-114.70371, 35.814585],
                    [-114.669687, 35.865084],
                    [-114.70027099999999, 35.901772],
                    [-114.73115900000002, 35.943916],
                    [-114.74277900000001, 36.009963],
                    [-114.74329924588602, 36.0659351737601],
                    [-114.743342, 36.070535],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '29420',
            AFFGEOID: '310M600US29420',
            GEOID: '29420',
            NAME: 'Lake Havasu City-Kingman, AZ',
            NAMELSAD: 'Lake Havasu City-Kingman, AZ Metro Area',
            LSAD: 'M1',
            ALAND: 34530024782,
            AWATER: 333401646,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-123.21795, 45.8510074543561, -122.240966, 46.385617],
            type: 'Polygon',
            coordinates: [
                [
                    [-123.21795, 46.385617],
                    [-122.240966, 46.385361],
                    [-122.245856, 46.053853],
                    [-122.35776500000001, 45.956915],
                    [-122.47710399999998, 45.988089],
                    [-122.657632, 45.928134],
                    [-122.719712, 45.933256],
                    [-122.71976699999999, 45.870903],
                    [-122.788086362595, 45.8510074543561],
                    [-122.785026, 45.867699],
                    [-122.81151000000001, 45.91272500000001],
                    [-122.813998, 45.960984],
                    [-122.856158, 46.014469],
                    [-122.904119, 46.083734],
                    [-122.962681, 46.104817],
                    [-123.004233, 46.133823],
                    [-123.11590400000001, 46.185268],
                    [-123.16641400000002, 46.188973],
                    [-123.212493591209, 46.1710964584003],
                    [-123.21795, 46.385617],
                ],
            ],
        },
        properties: {
            CSAFP: '440',
            CBSAFP: '31020',
            AFFGEOID: '310M600US31020',
            GEOID: '31020',
            NAME: 'Longview, WA',
            NAMELSAD: 'Longview, WA Metro Area',
            LSAD: 'M1',
            ALAND: 2955587567,
            AWATER: 65453464,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.4880524671283, 37.421996, -85.46625, 38.147418],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.471903, 38.04621800000001],
                    [-86.434046, 38.086763],
                    [-86.4335700794087, 38.0871478523782],
                    [-86.387216, 38.124632],
                    [-86.3564090219089, 38.1352772306995],
                    [-86.321274, 38.147418],
                    [-86.27865600000001, 38.098509],
                    [-86.206439, 38.021876],
                    [-86.095766, 38.00893],
                    [-86.033386, 37.970382],
                    [-85.9973516597543, 37.9912256022991],
                    [-85.976028, 38.00356],
                    [-85.9517264381006, 38.0149416294697],
                    [-85.938321, 37.998756],
                    [-85.854871, 37.945385],
                    [-85.838198, 37.879284],
                    [-85.737777, 37.81179],
                    [-85.736295, 37.781026],
                    [-85.680488, 37.732284],
                    [-85.592088, 37.693479],
                    [-85.62394, 37.540489],
                    [-85.52129, 37.554343],
                    [-85.503001, 37.54909],
                    [-85.46625, 37.465595],
                    [-85.498842, 37.482951],
                    [-85.583497, 37.469917],
                    [-85.634193, 37.471785],
                    [-85.657332, 37.421996],
                    [-85.892804, 37.440308],
                    [-86.047598, 37.449933],
                    [-86.114095, 37.567131],
                    [-86.27527000000002, 37.593519],
                    [-86.151285, 37.798878],
                    [-86.242777, 37.877002],
                    [-86.4880524671283, 38.0436653088997],
                    [-86.471903, 38.04621800000001],
                ],
            ],
        },
        properties: {
            CSAFP: '350',
            CBSAFP: '21060',
            AFFGEOID: '310M600US21060',
            GEOID: '21060',
            NAME: 'Elizabethtown-Fort Knox, KY',
            NAMELSAD: 'Elizabethtown-Fort Knox, KY Metro Area',
            LSAD: 'M1',
            ALAND: 3083101552,
            AWATER: 71931066,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.763773, 33.270032, -90.451012, 33.987586],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.763509, 33.529389],
                    [-90.763469, 33.616164],
                    [-90.658719, 33.616264],
                    [-90.657002, 33.987586],
                    [-90.451012, 33.985689],
                    [-90.451643, 33.81139],
                    [-90.453233, 33.329484],
                    [-90.555549, 33.329181],
                    [-90.555211, 33.270533],
                    [-90.716277, 33.270032],
                    [-90.763773, 33.270359],
                    [-90.763509, 33.529389],
                ],
            ],
        },
        properties: {
            CSAFP: '185',
            CBSAFP: '26940',
            AFFGEOID: '310M600US26940',
            GEOID: '26940',
            NAME: 'Indianola, MS',
            NAMELSAD: 'Indianola, MS Micro Area',
            LSAD: 'M2',
            ALAND: 1807187011,
            AWATER: 23747316,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-75.7604414164505, 38.8298265565277, -75.3066521095097, 39.3703942225828],
            type: 'Polygon',
            coordinates: [
                [
                    [-75.7604414164505, 39.2967896211001],
                    [-75.714901, 39.299366],
                    [-75.651159, 39.291594],
                    [-75.619631, 39.310058],
                    [-75.584765, 39.30864400000001],
                    [-75.5056428576184, 39.3703942225828],
                    [-75.469324, 39.33082],
                    [-75.408376, 39.264698],
                    [-75.39479, 39.188354],
                    [-75.407473, 39.133706],
                    [-75.396277, 39.057884],
                    [-75.34089, 39.01996],
                    [-75.3066521095097, 38.9476601633284],
                    [-75.380649, 38.961873],
                    [-75.410463, 38.916418],
                    [-75.484125, 38.904448],
                    [-75.555013, 38.835649],
                    [-75.7231026933327, 38.8298265565277],
                    [-75.7481548142541, 39.1431317309597],
                    [-75.7564352155685, 39.2466875371253],
                    [-75.7604414164505, 39.2967896211001],
                ],
            ],
        },
        properties: {
            CSAFP: '428',
            CBSAFP: '20100',
            AFFGEOID: '310M600US20100',
            GEOID: '20100',
            NAME: 'Dover, DE',
            NAMELSAD: 'Dover, DE Metro Area',
            LSAD: 'M1',
            ALAND: 1517891096,
            AWATER: 549776917,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-101.068106, 36.9977361751404, -100.633324986158, 37.387779],
            type: 'Polygon',
            coordinates: [
                [
                    [-101.068106, 37.387779],
                    [-100.652728, 37.387627],
                    [-100.63392, 37.381174],
                    [-100.633324986158, 37.0001736090529],
                    [-100.855634, 36.998626],
                    [-100.94546679307801, 36.9982468287288],
                    [-101.06645021566, 36.9977361751404],
                    [-101.068106, 37.387779],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '30580',
            AFFGEOID: '310M600US30580',
            GEOID: '30580',
            NAME: 'Liberal, KS',
            NAMELSAD: 'Liberal, KS Micro Area',
            LSAD: 'M2',
            ALAND: 1656693305,
            AWATER: 1961444,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.957641, 27.642288000000004, -80.862908, 29.276765999999995],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.954247, 28.55169],
                    [-81.95419, 28.960053],
                    [-81.761654, 28.960932],
                    [-81.658698, 28.960345],
                    [-81.640925, 29.168809000000003],
                    [-81.641916, 29.276765999999995],
                    [-81.61234, 29.202942999999998],
                    [-81.531262, 29.176621],
                    [-81.502055, 29.097802],
                    [-81.354554, 28.984375],
                    [-81.36694, 28.879227],
                    [-81.320473, 28.83453],
                    [-81.226003, 28.832629999999998],
                    [-81.18143300000001, 28.783277],
                    [-81.067829, 28.813325],
                    [-81.06111, 28.750001000000005],
                    [-80.98725, 28.612997],
                    [-80.873318, 28.48206],
                    [-80.862908, 28.347487],
                    [-80.868881, 27.822522],
                    [-80.881172, 27.80917],
                    [-80.87315, 27.642288000000004],
                    [-81.142164, 27.643238],
                    [-81.145954, 27.710533],
                    [-81.208124, 27.821125],
                    [-81.301862, 27.862081],
                    [-81.308696, 27.921880000000005],
                    [-81.419978, 28.001794000000004],
                    [-81.45957, 28.051644],
                    [-81.379239, 28.011699],
                    [-81.346428, 28.084687],
                    [-81.455758, 28.08489],
                    [-81.456214, 28.143163],
                    [-81.524227, 28.142805],
                    [-81.557459, 28.259362],
                    [-81.657173, 28.264513],
                    [-81.657268, 28.347098],
                    [-81.857624, 28.361868],
                    [-81.957641, 28.34518],
                    [-81.954247, 28.55169],
                ],
            ],
        },
        properties: {
            CSAFP: '422',
            CBSAFP: '36740',
            AFFGEOID: '310M600US36740',
            GEOID: '36740',
            NAME: 'Orlando-Kissimmee-Sanford, FL',
            NAMELSAD: 'Orlando-Kissimmee-Sanford, FL Metro Area',
            LSAD: 'M1',
            ALAND: 9043108363,
            AWATER: 1346663705,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-113.24347800000001, 42.256276, -111.875636, 43.110946],
            type: 'Polygon',
            coordinates: [
                [
                    [-113.24347800000001, 43.110946],
                    [-113.007712, 43.110933],
                    [-113.00776, 42.863051000000006],
                    [-112.816578, 42.863231],
                    [-112.750562, 42.951528],
                    [-112.678364, 43.02173],
                    [-112.063023, 43.022364],
                    [-112.082053, 42.950425],
                    [-112.149835, 42.895558],
                    [-112.120335, 42.831008],
                    [-112.141034, 42.763093],
                    [-112.10588, 42.699203],
                    [-111.969449, 42.718039],
                    [-111.91692700000002, 42.617264],
                    [-111.875636, 42.41615800000001],
                    [-111.896728, 42.256276],
                    [-111.983848, 42.28555],
                    [-112.126194, 42.285229],
                    [-112.190656, 42.350839],
                    [-112.327102, 42.32383600000001],
                    [-112.298113, 42.454499],
                    [-112.419825, 42.503038],
                    [-112.498139, 42.502967],
                    [-112.497849, 42.415998],
                    [-112.653989, 42.416169],
                    [-112.654028, 42.328872],
                    [-112.999965, 42.327427],
                    [-113.002792, 42.588003],
                    [-113.175678, 42.589464],
                    [-113.236968, 42.625254],
                    [-113.178633, 42.67546],
                    [-113.17887700000001, 42.762355],
                    [-113.236943, 42.762212],
                    [-113.24347800000001, 43.110946],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '38540',
            AFFGEOID: '310M600US38540',
            GEOID: '38540',
            NAME: 'Pocatello, ID',
            NAMELSAD: 'Pocatello, ID Metro Area',
            LSAD: 'M1',
            ALAND: 6517228819,
            AWATER: 191281556,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-69.355667, 44.644023000000004, -67.93976, 46.395782],
            type: 'Polygon',
            coordinates: [
                [
                    [-69.355667, 45.073469],
                    [-69.232021, 45.090166],
                    [-69.109443, 45.107192],
                    [-68.856587, 45.142783],
                    [-68.880923, 45.224947],
                    [-68.776061, 45.240317],
                    [-68.825425, 45.420552],
                    [-68.831206, 45.44243],
                    [-68.857297, 45.527301],
                    [-68.964653, 45.512367],
                    [-68.95891, 45.662195],
                    [-68.827039, 45.684826],
                    [-68.819401, 46.395782],
                    [-68.706297, 46.395494],
                    [-68.436443, 46.382121],
                    [-68.436902, 46.04289],
                    [-68.427598, 45.944591],
                    [-68.433001, 45.581204],
                    [-68.410884, 45.573337],
                    [-68.260136, 45.604483],
                    [-68.048012, 45.639897],
                    [-67.93976, 45.26784500000001],
                    [-68.054985, 45.252577],
                    [-68.303714, 45.221531],
                    [-68.270497, 45.100121],
                    [-68.396903, 45.083296],
                    [-68.375892, 44.997373],
                    [-68.501095, 44.980718],
                    [-68.477952, 44.889091],
                    [-68.454889, 44.801550000000006],
                    [-68.445219, 44.763014],
                    [-68.528542, 44.752167],
                    [-68.616673, 44.740992],
                    [-68.647134, 44.703636],
                    [-68.69093, 44.721668],
                    [-68.743953, 44.660579],
                    [-68.815793, 44.683754],
                    [-68.837529, 44.702125],
                    [-69.014167, 44.671794000000006],
                    [-69.183732, 44.644023000000004],
                    [-69.2052, 44.729586],
                    [-69.266785, 44.721928],
                    [-69.28186, 44.788267],
                    [-69.281275, 44.808657000000004],
                    [-69.305521, 44.895988],
                    [-69.330949, 44.986901],
                    [-69.355667, 45.073469],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '12620',
            AFFGEOID: '310M600US12620',
            GEOID: '12620',
            NAME: 'Bangor, ME',
            NAMELSAD: 'Bangor, ME Metro Area',
            LSAD: 'M1',
            ALAND: 8798699728,
            AWATER: 413983093,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.284561, 30.9908345035084, -81.1748326365906, 31.699575],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.284561, 31.224449],
                    [-82.193031, 31.202120000000004],
                    [-82.114822, 31.270355],
                    [-81.997421, 31.288534],
                    [-82.041129, 31.373721],
                    [-81.923238, 31.345875000000003],
                    [-81.801052, 31.363737],
                    [-81.731694, 31.330048],
                    [-81.691977, 31.400545000000005],
                    [-81.62429, 31.452982],
                    [-81.612054, 31.469404999999995],
                    [-81.663206, 31.538667],
                    [-81.566009, 31.576467],
                    [-81.491798, 31.699575],
                    [-81.43576, 31.642055],
                    [-81.314548, 31.659382],
                    [-81.22222, 31.630419999999997],
                    [-81.1748326365906, 31.539596449494802],
                    [-81.177254, 31.517074],
                    [-81.21349300000001, 31.462818],
                    [-81.258616, 31.404425000000003],
                    [-81.279338, 31.351126999999998],
                    [-81.260958, 31.303909999999995],
                    [-81.2643779480884, 31.2945990647455],
                    [-81.282842, 31.24433],
                    [-81.304957, 31.206173],
                    [-81.368241, 31.136534],
                    [-81.402096, 31.125383],
                    [-81.401267, 31.072781],
                    [-81.420474, 31.016703000000003],
                    [-81.4125180355795, 30.9908345035084],
                    [-81.57269200000002, 31.097269],
                    [-81.562606, 31.130284999999997],
                    [-81.631624, 31.108117000000004],
                    [-81.766241, 31.169650000000004],
                    [-81.840671, 31.097664999999996],
                    [-81.936749, 31.060787],
                    [-81.93600600000002, 31.047993000000005],
                    [-82.06087, 31.075809],
                    [-82.081207, 31.010630000000003],
                    [-82.131698, 31.010714000000004],
                    [-82.208262, 31.084768],
                    [-82.208732, 31.170938000000003],
                    [-82.284561, 31.224449],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '15260',
            AFFGEOID: '310M600US15260',
            GEOID: '15260',
            NAME: 'Brunswick, GA',
            NAMELSAD: 'Brunswick, GA Metro Area',
            LSAD: 'M1',
            ALAND: 3352025011,
            AWATER: 808563829,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-94.744876, 41.15661, -92.756508, 41.863419],
            type: 'Polygon',
            coordinates: [
                [
                    [-94.744876, 41.862394],
                    [-94.628724, 41.862763],
                    [-94.28034, 41.862999],
                    [-94.164428, 41.863243],
                    [-93.815721, 41.863419],
                    [-93.698032, 41.86337],
                    [-93.347933, 41.863104],
                    [-93.231858, 41.862711],
                    [-92.765999, 41.862373],
                    [-92.766759, 41.601355],
                    [-92.756508, 41.508794],
                    [-92.871421, 41.508522],
                    [-93.328614, 41.50782400000001],
                    [-93.328407, 41.490794],
                    [-93.327886, 41.160659],
                    [-93.55755600000002, 41.161271],
                    [-93.789196, 41.162035],
                    [-93.898813, 41.15661],
                    [-94.014189, 41.156762],
                    [-94.241637, 41.157134],
                    [-94.241593, 41.503679],
                    [-94.700629, 41.504148],
                    [-94.700745, 41.60218400000001],
                    [-94.744204, 41.602185000000006],
                    [-94.744876, 41.862394],
                ],
            ],
        },
        properties: {
            CSAFP: '218',
            CBSAFP: '19780',
            AFFGEOID: '310M600US19780',
            GEOID: '19780',
            NAME: 'Des Moines-West Des Moines, IA',
            NAMELSAD: 'Des Moines-West Des Moines, IA Metro Area',
            LSAD: 'M1',
            ALAND: 9356047019,
            AWATER: 83572521,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.093381, 40.963203, -77.144111, 41.478576],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.093381, 41.216931],
                    [-77.988786, 41.367453],
                    [-77.989194, 41.474822],
                    [-77.598129, 41.478576],
                    [-77.597282, 41.441056],
                    [-77.526537, 41.358528],
                    [-77.349765, 41.219918],
                    [-77.144111, 41.06884],
                    [-77.14416, 41.044338],
                    [-77.339925, 40.987167],
                    [-77.50702, 40.963203],
                    [-77.514275, 40.96956200000001],
                    [-77.621759, 41.091185],
                    [-77.741911, 41.107614],
                    [-77.79856, 41.18195],
                    [-77.892724, 41.178259000000004],
                    [-77.898195, 41.252752],
                    [-78.038203, 41.153633],
                    [-78.061007, 41.174539],
                    [-78.093381, 41.216931],
                ],
            ],
        },
        properties: {
            CSAFP: '558',
            CBSAFP: '30820',
            AFFGEOID: '310M600US30820',
            GEOID: '30820',
            NAME: 'Lock Haven, PA',
            NAMELSAD: 'Lock Haven, PA Micro Area',
            LSAD: 'M2',
            ALAND: 2299868386,
            AWATER: 23178644,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.334885, 29.04006, -89.998954, 29.922007],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.334885, 29.298775],
                    [-91.276647, 29.329825000000003],
                    [-91.26545200000001, 29.360976000000004],
                    [-91.2663226707192, 29.361363733346003],
                    [-91.235419, 29.374071999999998],
                    [-91.213419, 29.417498000000002],
                    [-91.258332, 29.444319],
                    [-91.27124, 29.535186],
                    [-91.178048, 29.609703],
                    [-91.082611, 29.626814],
                    [-91.00689000000001, 29.715093000000003],
                    [-90.981838, 29.744875000000004],
                    [-90.925046, 29.830377],
                    [-90.885266, 29.877565],
                    [-90.885749, 29.905348],
                    [-90.7787, 29.922007],
                    [-90.656312, 29.889246],
                    [-90.629134, 29.895230000000005],
                    [-90.529601, 29.884997],
                    [-90.479904, 29.847377000000005],
                    [-90.468043, 29.803474],
                    [-90.371978, 29.759239],
                    [-90.352104, 29.695512],
                    [-90.228157, 29.692028],
                    [-90.155807, 29.677530000000004],
                    [-90.151725, 29.587871000000003],
                    [-90.186172, 29.563341000000005],
                    [-90.137504, 29.477163],
                    [-90.020622, 29.428461],
                    [-90.030189, 29.332070999999996],
                    [-89.998954, 29.298339],
                    [-90.0898354481942, 29.164475356621104],
                    [-90.122753, 29.144286],
                    [-90.223587, 29.085075],
                    [-90.334935, 29.063803],
                    [-90.4096172499074, 29.058433864509105],
                    [-90.442734, 29.056052999999995],
                    [-90.488117, 29.05876],
                    [-90.652116, 29.057721],
                    [-90.748377, 29.04006],
                    [-90.81255, 29.042138],
                    [-90.867853, 29.056064000000003],
                    [-90.877583, 29.104891],
                    [-90.941877, 29.162373],
                    [-91.000096, 29.169481],
                    [-91.09401500000001, 29.187710999999997],
                    [-91.158148, 29.218100000000003],
                    [-91.27879200000001, 29.247776000000005],
                    [-91.334885, 29.298775],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '26380',
            AFFGEOID: '310M600US26380',
            GEOID: '26380',
            NAME: 'Houma-Thibodaux, LA',
            NAMELSAD: 'Houma-Thibodaux, LA Metro Area',
            LSAD: 'M1',
            ALAND: 5950967002,
            AWATER: 3245232828,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.367849, 45.542053, -86.4598805177494, 46.159103],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.367849, 45.985321],
                    [-87.242885, 45.985117],
                    [-87.241506, 46.159103],
                    [-87.116138, 46.15905],
                    [-86.615601, 46.158691],
                    [-86.490142, 46.158178],
                    [-86.49025000000002, 45.896337],
                    [-86.4598805177494, 45.750226550596],
                    [-86.54143, 45.708110000000005],
                    [-86.616972, 45.620581],
                    [-86.636895, 45.542053],
                    [-86.712328, 45.610939],
                    [-86.705184, 45.690901],
                    [-86.647319, 45.732618],
                    [-86.773279, 45.811385],
                    [-86.838746, 45.722307],
                    [-86.964275, 45.672761],
                    [-87.070442, 45.718779],
                    [-87.172241, 45.661788],
                    [-87.2534496287802, 45.5501155908891],
                    [-87.327502, 45.55125],
                    [-87.325391, 45.898665],
                    [-87.367849, 45.985321],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '21540',
            AFFGEOID: '310M600US21540',
            GEOID: '21540',
            NAME: 'Escanaba, MI',
            NAMELSAD: 'Escanaba, MI Micro Area',
            LSAD: 'M2',
            ALAND: 3033137846,
            AWATER: 2124389420,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.628404, 36.809178, -92.685867, 37.904232],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.628404, 37.829435],
                    [-93.573202, 37.828035],
                    [-93.563313, 37.813031],
                    [-93.187915, 37.80273700000001],
                    [-93.182648, 37.904232],
                    [-93.072447, 37.902627],
                    [-92.855384, 37.895736],
                    [-92.846281, 37.721039],
                    [-92.853481, 37.48397],
                    [-92.686671, 37.481545],
                    [-92.685867, 37.067051],
                    [-92.82467, 37.068674],
                    [-92.903273, 37.070651],
                    [-92.909336, 36.809178],
                    [-93.304359, 36.816866],
                    [-93.337451, 36.992494],
                    [-93.610126, 36.99581],
                    [-93.60889900000001, 37.09815300000001],
                    [-93.605113, 37.280253],
                    [-93.625844, 37.282011],
                    [-93.621153, 37.427423],
                    [-93.616033, 37.572689000000004],
                    [-93.609489, 37.741755],
                    [-93.628404, 37.829435],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '44180',
            AFFGEOID: '310M600US44180',
            GEOID: '44180',
            NAME: 'Springfield, MO',
            NAMELSAD: 'Springfield, MO Metro Area',
            LSAD: 'M1',
            ALAND: 7788096057,
            AWATER: 35512791,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-107.626511, 34.259822, -105.290396, 36.219809],
            type: 'Polygon',
            coordinates: [
                [
                    [-107.624283, 36.219809],
                    [-106.885053, 36.21657],
                    [-106.885317, 35.998969],
                    [-106.247164, 36.001434],
                    [-106.248161, 35.966327],
                    [-106.399469, 35.960092],
                    [-106.41877800000002, 35.819986],
                    [-106.250499, 35.755818],
                    [-106.248848, 35.443282],
                    [-106.245162, 35.238661],
                    [-106.244216, 35.215657],
                    [-106.243874, 35.040003],
                    [-106.194713, 35.04025600000001],
                    [-105.714419, 35.041605],
                    [-105.290791, 35.04203],
                    [-105.290396, 34.606766],
                    [-105.313072, 34.347205],
                    [-105.313218, 34.260031000000005],
                    [-105.925968, 34.25999],
                    [-106.415972, 34.259822],
                    [-106.416924, 34.439687],
                    [-106.76828100000002, 34.531262],
                    [-106.882813, 34.579628],
                    [-107.201743, 34.578757],
                    [-107.202882, 34.957572],
                    [-107.06656200000002, 34.95719],
                    [-107.197244, 35.219459],
                    [-107.23889, 35.305507],
                    [-107.309386, 35.305687],
                    [-107.308667, 35.998556],
                    [-107.626511, 36.000288],
                    [-107.624283, 36.219809],
                ],
            ],
        },
        properties: {
            CSAFP: '106',
            CBSAFP: '10740',
            AFFGEOID: '310M600US10740',
            GEOID: '10740',
            NAME: 'Albuquerque, NM',
            NAMELSAD: 'Albuquerque, NM Metro Area',
            LSAD: 'M1',
            ALAND: 24043885303,
            AWATER: 31811671,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.986885, 35.54702, -80.92754, 36.122619],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.906652, 35.883376],
                    [-81.943188, 35.96005],
                    [-81.899871, 35.999909],
                    [-81.80716200000002, 35.961955],
                    [-81.735673, 36.067584],
                    [-81.810516, 36.111511],
                    [-81.674833, 36.122619],
                    [-81.545488, 36.117475],
                    [-81.397817, 36.066061],
                    [-81.328324, 35.996583],
                    [-81.263596, 36.025818],
                    [-81.028833, 36.045652],
                    [-81.00554100000001, 35.958287],
                    [-81.041339, 35.857855],
                    [-81.109507, 35.776594],
                    [-81.061328, 35.710641],
                    [-81.005321, 35.710209],
                    [-80.99444500000001, 35.70211],
                    [-80.982037, 35.68249200000001],
                    [-80.966305, 35.670313],
                    [-80.951241, 35.641413],
                    [-80.92754, 35.622978],
                    [-80.933483, 35.591329],
                    [-80.960025, 35.54702],
                    [-81.535403, 35.568137],
                    [-81.537599, 35.564228],
                    [-81.56725, 35.558201],
                    [-81.691989, 35.580041],
                    [-81.753407, 35.613588],
                    [-81.824122, 35.574983],
                    [-81.869388, 35.719624],
                    [-81.986885, 35.807066],
                    [-81.906652, 35.883376],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '25860',
            AFFGEOID: '310M600US25860',
            GEOID: '25860',
            NAME: 'Hickory-Lenoir-Morganton, NC',
            NAMELSAD: 'Hickory-Lenoir-Morganton, NC Metro Area',
            LSAD: 'M1',
            ALAND: 4246242558,
            AWATER: 75161380,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.086679, 40.9003239342489, -79.999779, 41.501685],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.086312, 40.988031],
                    [-81.001695, 40.987783],
                    [-81.00236, 41.062093],
                    [-81.00229, 41.134189],
                    [-81.00288, 41.271842],
                    [-81.003191, 41.347859],
                    [-81.003319, 41.34786],
                    [-81.003125, 41.501685],
                    [-80.5191767503534, 41.499924051206],
                    [-80.5191652733637, 41.4891096844823],
                    [-80.125369, 41.480396],
                    [-79.999779, 41.490049],
                    [-79.999779, 41.171861],
                    [-80.082007, 41.085033],
                    [-80.098294, 41.071054],
                    [-80.147303, 41.113917],
                    [-80.257933, 41.128165],
                    [-80.5192220860809, 41.1250935667958],
                    [-80.5196437725473, 40.98739265400581],
                    [-80.519891, 40.906661],
                    [-80.5198697076862, 40.9003239342489],
                    [-80.821923, 40.900935],
                    [-80.895813, 40.930187],
                    [-80.896032, 40.900995],
                    [-81.086679, 40.901609],
                    [-81.086312, 40.988031],
                ],
            ],
        },
        properties: {
            CSAFP: '566',
            CBSAFP: '49660',
            AFFGEOID: '310M600US49660',
            GEOID: '49660',
            NAME: 'Youngstown-Warren-Boardman, OH-PA',
            NAMELSAD: 'Youngstown-Warren-Boardman, OH-PA Metro Area',
            LSAD: 'M1',
            ALAND: 4408441873,
            AWATER: 109322747,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-77.165986, 34.588577, -76.013145, 35.201453],
            type: 'Polygon',
            coordinates: [
                [
                    [-77.161676, 34.795075],
                    [-77.078263, 34.811322],
                    [-76.954438, 34.809066],
                    [-76.883201, 34.844893],
                    [-76.693018, 34.86918],
                    [-76.652571, 34.937595],
                    [-76.71158, 34.981768],
                    [-76.304136, 35.201453],
                    [-76.065774, 35.124937],
                    [-76.013145, 35.061855],
                    [-76.137269, 34.987858],
                    [-76.233088, 34.905477],
                    [-76.31021, 34.852309],
                    [-76.386804, 34.784579],
                    [-76.450454, 34.71445],
                    [-76.535946, 34.588577],
                    [-76.553806, 34.628252],
                    [-76.618719, 34.67255],
                    [-76.726969, 34.69669],
                    [-76.906257, 34.68282],
                    [-77.1129643930768, 34.6380928323915],
                    [-77.125646, 34.756707],
                    [-77.165986, 34.78503200000001],
                    [-77.161676, 34.795075],
                ],
            ],
        },
        properties: {
            CSAFP: '404',
            CBSAFP: '33980',
            AFFGEOID: '310M600US33980',
            GEOID: '33980',
            NAME: 'Morehead City, NC',
            NAMELSAD: 'Morehead City, NC Micro Area',
            LSAD: 'M2',
            ALAND: 1314688730,
            AWATER: 2131061851,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.824828, 41.759553, -86.22294, 42.243379],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.693267, 41.835403],
                    [-86.597899, 41.918291],
                    [-86.501322, 42.08454],
                    [-86.466262, 42.134406],
                    [-86.3663791270383, 42.2431077271809],
                    [-86.223563, 42.243379],
                    [-86.22294, 42.071484],
                    [-86.2260943449874, 41.7600159591164],
                    [-86.501773, 41.759553],
                    [-86.5242197424429, 41.7595721559735],
                    [-86.640044, 41.759671000000004],
                    [-86.6413229205208, 41.7596749381428],
                    [-86.824828, 41.76024],
                    [-86.693267, 41.835403],
                ],
            ],
        },
        properties: {
            CSAFP: '515',
            CBSAFP: '35660',
            AFFGEOID: '310M600US35660',
            GEOID: '35660',
            NAME: 'Niles, MI',
            NAMELSAD: 'Niles, MI Metro Area',
            LSAD: 'M1',
            ALAND: 1470545556,
            AWATER: 2625448034,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-107.299631, 31.783148, -106.340515, 33.052777],
            type: 'Polygon',
            coordinates: [
                [
                    [-107.299475, 32.780166],
                    [-106.887733, 32.779273],
                    [-106.887891, 32.822781],
                    [-106.340515, 33.052777],
                    [-106.340709, 32.958382],
                    [-106.376585, 32.95805000000001],
                    [-106.377173188247, 32.00124010103751],
                    [-106.618486, 32.000495],
                    [-106.63011400000002, 31.971258000000002],
                    [-106.623445, 31.914034000000004],
                    [-106.63588, 31.871514],
                    [-106.581344, 31.813906],
                    [-106.528242, 31.783148],
                    [-106.53173100000001, 31.78391],
                    [-106.993544, 31.783689000000003],
                    [-107.296792996712, 31.783625337106702],
                    [-107.299631, 32.60537],
                    [-107.299475, 32.780166],
                ],
            ],
        },
        properties: {
            CSAFP: '238',
            CBSAFP: '29740',
            AFFGEOID: '310M600US29740',
            GEOID: '29740',
            NAME: 'Las Cruces, NM',
            NAMELSAD: 'Las Cruces, NM Metro Area',
            LSAD: 'M1',
            ALAND: 9863163868,
            AWATER: 17594250,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.526682, 41.432886, -85.760186, 42.071484],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.5242197424429, 41.7595721559735],
                    [-86.501773, 41.759553],
                    [-86.2260943449874, 41.7600159591164],
                    [-86.22294, 42.071484],
                    [-85.762943, 42.069327],
                    [-85.760186, 41.798814],
                    [-85.79133496196282, 41.7590510395575],
                    [-85.791363, 41.759051],
                    [-86.0625630290106, 41.759653033158],
                    [-86.059451, 41.479154],
                    [-86.467108, 41.476459000000006],
                    [-86.466603, 41.432968],
                    [-86.524711, 41.432886],
                    [-86.486433, 41.570545],
                    [-86.526682, 41.65044],
                    [-86.5242197424429, 41.7595721559735],
                ],
            ],
        },
        properties: {
            CSAFP: '515',
            CBSAFP: '43780',
            AFFGEOID: '310M600US43780',
            GEOID: '43780',
            NAME: 'South Bend-Mishawaka, IN-MI',
            NAMELSAD: 'South Bend-Mishawaka, IN-MI Metro Area',
            LSAD: 'M1',
            ALAND: 2455011331,
            AWATER: 56486701,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-110.457194, 32.424755, -109.11418, 33.652697],
            type: 'Polygon',
            coordinates: [
                [
                    [-110.441544, 33.38167],
                    [-110.340949, 33.427066],
                    [-110.168992, 33.451698],
                    [-110.000646, 33.465916],
                    [-110.000649, 33.57693],
                    [-109.903421, 33.57511600000001],
                    [-109.891676, 33.567874],
                    [-109.80327000000001, 33.481727],
                    [-109.49544, 33.652697],
                    [-109.49574, 33.078409],
                    [-109.333425, 32.843266],
                    [-109.245005, 32.741985],
                    [-109.206249, 32.63580300000001],
                    [-109.22607, 32.52909600000001],
                    [-109.11418, 32.426353],
                    [-109.213077, 32.426361],
                    [-109.22774800000002, 32.424755],
                    [-109.432075, 32.427572],
                    [-110.451002, 32.42754600000001],
                    [-110.451522, 32.513995],
                    [-110.448487, 32.534517],
                    [-110.44902100000002, 33.194526],
                    [-110.400676, 33.198814],
                    [-110.457194, 33.295268],
                    [-110.441544, 33.38167],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '40940',
            AFFGEOID: '310M600US40940',
            GEOID: '40940',
            NAME: 'Safford, AZ',
            NAMELSAD: 'Safford, AZ Micro Area',
            LSAD: 'M2',
            ALAND: 11970605955,
            AWATER: 47476264,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.4890767387132, 36.49854, -88.033802, 36.750328],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.485697, 36.750328],
                    [-88.110831, 36.74715],
                    [-88.080126, 36.706739],
                    [-88.070532, 36.678118],
                    [-88.055738, 36.630475],
                    [-88.033802, 36.551733],
                    [-88.050466, 36.500053],
                    [-88.0533508007759, 36.4999962506816],
                    [-88.127378, 36.49854],
                    [-88.4890767387132, 36.5012837190757],
                    [-88.485697, 36.750328],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '34660',
            AFFGEOID: '310M600US34660',
            GEOID: '34660',
            NAME: 'Murray, KY',
            NAMELSAD: 'Murray, KY Micro Area',
            LSAD: 'M2',
            ALAND: 997224714,
            AWATER: 66474183,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.106206, 27.643238, -81.142164, 28.361868],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.106206, 28.25924],
                    [-82.055819, 28.312794],
                    [-81.958029, 28.308925],
                    [-81.957641, 28.34518],
                    [-81.857624, 28.361868],
                    [-81.657268, 28.347098],
                    [-81.657173, 28.264513],
                    [-81.557459, 28.259362],
                    [-81.524227, 28.142805],
                    [-81.456214, 28.143163],
                    [-81.455758, 28.08489],
                    [-81.346428, 28.084687],
                    [-81.379239, 28.011699],
                    [-81.45957, 28.051644],
                    [-81.419978, 28.001794000000004],
                    [-81.308696, 27.921880000000005],
                    [-81.301862, 27.862081],
                    [-81.208124, 27.821125],
                    [-81.145954, 27.710533],
                    [-81.142164, 27.643238],
                    [-81.5299, 27.646422],
                    [-81.563533, 27.646645],
                    [-82.054349, 27.646382],
                    [-82.054874, 27.907726],
                    [-82.055258, 27.927085],
                    [-82.056261, 28.171592],
                    [-82.105853, 28.17165],
                    [-82.106206, 28.25924],
                ],
            ],
        },
        properties: {
            CSAFP: '422',
            CBSAFP: '29460',
            AFFGEOID: '310M600US29460',
            GEOID: '29460',
            NAME: 'Lakeland-Winter Haven, FL',
            NAMELSAD: 'Lakeland-Winter Haven, FL Metro Area',
            LSAD: 'M1',
            ALAND: 4656518803,
            AWATER: 550424838,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-118.24912200000001, 46.423707, -117.036645748421, 47.260568],
            type: 'Polygon',
            coordinates: [
                [
                    [-118.24912200000001, 46.734138],
                    [-118.21024300000002, 46.73883300000001],
                    [-118.01556700000002, 46.784873],
                    [-117.95933500000001, 46.914811],
                    [-117.96093, 47.260568],
                    [-117.823629, 47.26022],
                    [-117.04015975186302, 47.259272],
                    [-117.03983599999998, 47.154734],
                    [-117.039833386028, 47.1272689922541],
                    [-117.03977765556202, 46.5417089920381],
                    [-117.039771, 46.471779],
                    [-117.036645748421, 46.4261004826554],
                    [-117.208628, 46.423707],
                    [-117.22812, 46.462245],
                    [-117.254262, 46.545082],
                    [-117.38274200000001, 46.614684],
                    [-117.464769, 46.699626],
                    [-117.59779200000001, 46.672543],
                    [-117.740868, 46.694583],
                    [-117.85185000000001, 46.62467],
                    [-117.88185700000001, 46.592193],
                    [-118.165292, 46.555135],
                    [-118.21566000000001, 46.588924],
                    [-118.24912200000001, 46.734138],
                ],
            ],
        },
        properties: {
            CSAFP: '446',
            CBSAFP: '39420',
            AFFGEOID: '310M600US39420',
            GEOID: '39420',
            NAME: 'Pullman, WA',
            NAMELSAD: 'Pullman, WA Micro Area',
            LSAD: 'M2',
            ALAND: 5592463291,
            AWATER: 48246698,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.737777, 37.540489, -85.152797, 37.990654],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.737777, 37.81179],
                    [-85.680648, 37.814878],
                    [-85.576259, 37.885129],
                    [-85.489202, 37.990654],
                    [-85.399559, 37.939929],
                    [-85.167834, 37.971809],
                    [-85.152797, 37.89766100000001],
                    [-85.204253, 37.846453],
                    [-85.254333, 37.866423],
                    [-85.401784, 37.730658],
                    [-85.450493, 37.685948],
                    [-85.52129, 37.554343],
                    [-85.62394, 37.540489],
                    [-85.592088, 37.693479],
                    [-85.680488, 37.732284],
                    [-85.736295, 37.781026],
                    [-85.737777, 37.81179],
                ],
            ],
        },
        properties: {
            CSAFP: '350',
            CBSAFP: '12680',
            AFFGEOID: '310M600US12680',
            GEOID: '12680',
            NAME: 'Bardstown, KY',
            NAMELSAD: 'Bardstown, KY Micro Area',
            LSAD: 'M2',
            ALAND: 1081379029,
            AWATER: 16973349,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.649705, 32.803863, -81.187271, 33.984195],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.649705, 33.608768],
                    [-82.507223, 33.659994],
                    [-82.479518, 33.639535],
                    [-82.593527, 33.827322],
                    [-82.572297, 33.86634300000001],
                    [-82.605687, 33.859942],
                    [-82.645451, 33.984195],
                    [-82.636698, 33.974092],
                    [-82.5629967985809, 33.9565549105269],
                    [-82.556835, 33.945353],
                    [-82.51295, 33.936969],
                    [-82.43115, 33.867051],
                    [-82.32448, 33.820033],
                    [-82.239098, 33.730872],
                    [-82.2159383647475, 33.6877549753187],
                    [-82.199747, 33.657611],
                    [-82.161908, 33.610643],
                    [-82.1424564745009, 33.6053995999921],
                    [-82.1146538385848, 33.59790505457],
                    [-82.095527, 33.633869],
                    [-82.182987, 33.724113],
                    [-82.11372800000001, 33.82988],
                    [-82.1557, 33.92929],
                    [-82.046974, 33.95321],
                    [-82.008298, 33.961646],
                    [-81.894595, 33.975415],
                    [-81.836803, 33.866501],
                    [-81.65175900000001, 33.81451],
                    [-81.571487, 33.877318],
                    [-81.415308, 33.732161],
                    [-81.187271, 33.652937],
                    [-81.37293100000001, 33.490417],
                    [-81.742519, 33.208846],
                    [-81.76250598279582, 33.1972658882973],
                    [-81.75513500000001, 33.15155],
                    [-81.658433, 33.103152],
                    [-81.6159557107891, 33.089338539364],
                    [-81.601655, 33.08468800000001],
                    [-81.5439662243139, 33.0443999541444],
                    [-81.767553, 32.909411],
                    [-81.85798500000001, 32.953882],
                    [-82.081349, 32.916762],
                    [-82.144977, 32.812741],
                    [-82.213745, 32.803863],
                    [-82.316512, 32.835772],
                    [-82.272353, 32.937796],
                    [-82.29118, 33.062827],
                    [-82.232554, 33.231211],
                    [-82.26758, 33.267397],
                    [-82.33277400000001, 33.308681],
                    [-82.353498, 33.312318],
                    [-82.383829, 33.312106],
                    [-82.548068, 33.352783],
                    [-82.649705, 33.608768],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '12260',
            AFFGEOID: '310M600US12260',
            GEOID: '12260',
            NAME: 'Augusta-Richmond County, GA-SC',
            NAMELSAD: 'Augusta-Richmond County, GA-SC Metro Area',
            LSAD: 'M1',
            ALAND: 9015018014,
            AWATER: 262412034,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-94.119664, 38.55621, -93.49311, 38.937909],
            type: 'Polygon',
            coordinates: [
                [
                    [-94.119096, 38.833167],
                    [-94.114175, 38.917304],
                    [-93.834719, 38.937909],
                    [-93.497278, 38.92843],
                    [-93.49311, 38.899016],
                    [-93.51103, 38.55621],
                    [-94.06431700000002, 38.56738],
                    [-94.119664, 38.568712000000005],
                    [-94.119096, 38.833167],
                ],
            ],
        },
        properties: {
            CSAFP: '312',
            CBSAFP: '47660',
            AFFGEOID: '310M600US47660',
            GEOID: '47660',
            NAME: 'Warrensburg, MO',
            NAMELSAD: 'Warrensburg, MO Micro Area',
            LSAD: 'M2',
            ALAND: 2147776085,
            AWATER: 10180467,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.305682, 34.059477, -91.632992, 35.368417],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.305682, 34.875463],
                    [-93.040561, 35.07688],
                    [-92.629185, 35.076162],
                    [-92.554443, 35.113418],
                    [-92.521689, 35.11459700000001],
                    [-92.52772, 35.230787],
                    [-92.484883, 35.275489],
                    [-92.48146800000002, 35.368417],
                    [-92.252843, 35.36365],
                    [-92.111535, 35.362633],
                    [-92.119748, 35.06656],
                    [-92.013056, 35.076317],
                    [-91.902557, 35.027687],
                    [-91.802509, 35.030698],
                    [-91.803703, 34.921524],
                    [-91.693705, 34.919605],
                    [-91.700828, 34.672029],
                    [-91.638682, 34.624127],
                    [-91.632992, 34.552576],
                    [-91.67822, 34.481913],
                    [-91.70525, 34.482731],
                    [-91.974805, 34.488721],
                    [-92.030254, 34.489539],
                    [-92.208042, 34.492809],
                    [-92.212827, 34.230399],
                    [-92.233303, 34.062367],
                    [-92.336695, 34.059477],
                    [-92.405887, 34.115691],
                    [-92.493624, 34.144252],
                    [-92.678382, 34.149645],
                    [-92.668654, 34.414504],
                    [-92.685066, 34.473102],
                    [-92.79318900000001, 34.503573],
                    [-92.808028, 34.590406],
                    [-92.875394, 34.635196],
                    [-92.979978, 34.637249],
                    [-92.969317, 34.772263],
                    [-93.075178, 34.771892],
                    [-93.287785, 34.773242],
                    [-93.305682, 34.875463],
                ],
            ],
        },
        properties: {
            CSAFP: '340',
            CBSAFP: '30780',
            AFFGEOID: '310M600US30780',
            GEOID: '30780',
            NAME: 'Little Rock-North Little Rock-Conway, AR',
            NAMELSAD: 'Little Rock-North Little Rock-Conway, AR Metro Area',
            LSAD: 'M1',
            ALAND: 10576986122,
            AWATER: 298606991,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-74.780693, 41.58061000000001, -73.910675, 42.176992],
            type: 'Polygon',
            coordinates: [
                [
                    [-74.530985, 42.146475],
                    [-74.451713, 42.169225],
                    [-74.307571, 42.114346],
                    [-74.074797, 42.096589],
                    [-74.00245, 42.176992],
                    [-73.910675, 42.127293],
                    [-73.929626, 42.078778],
                    [-73.964413, 41.913148],
                    [-73.941081, 41.735993],
                    [-73.953307, 41.589977],
                    [-74.053685, 41.58061000000001],
                    [-74.264292, 41.632629],
                    [-74.367055, 41.590977],
                    [-74.395071, 41.644876],
                    [-74.575086, 41.745258],
                    [-74.453685, 41.875595],
                    [-74.780693, 42.016375],
                    [-74.530985, 42.146475],
                ],
            ],
        },
        properties: {
            CSAFP: '408',
            CBSAFP: '28740',
            AFFGEOID: '310M600US28740',
            GEOID: '28740',
            NAME: 'Kingston, NY',
            NAMELSAD: 'Kingston, NY Metro Area',
            LSAD: 'M1',
            ALAND: 2911757764,
            AWATER: 94596811,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.862351, 40.925749, -88.58624, 41.631389],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.862351, 41.584005],
                    [-89.631494, 41.584949],
                    [-89.166561, 41.585289],
                    [-89.16715, 41.628657000000004],
                    [-88.938679, 41.628316],
                    [-88.818233, 41.631351],
                    [-88.712444, 41.630491],
                    [-88.60224, 41.631389],
                    [-88.595961, 41.457129],
                    [-88.58624, 41.108293],
                    [-88.930881, 41.1059],
                    [-88.93139, 40.92774],
                    [-89.047718, 40.925749],
                    [-89.047394, 41.104786],
                    [-89.162237, 41.104008],
                    [-89.358437, 41.103849],
                    [-89.32927, 41.14793100000001],
                    [-89.466101, 41.148524],
                    [-89.63853600000002, 41.148545],
                    [-89.638708, 41.233862],
                    [-89.857616, 41.234479],
                    [-89.862351, 41.584005],
                ],
            ],
        },
        properties: {
            CSAFP: '176',
            CBSAFP: '36837',
            AFFGEOID: '310M600US36837',
            GEOID: '36837',
            NAME: 'Ottawa, IL',
            NAMELSAD: 'Ottawa, IL Micro Area',
            LSAD: 'M2',
            ALAND: 5605593761,
            AWATER: 76427899,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.306391, 37.76331, -80.817424, 39.1040311930998],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.192314, 38.046314],
                    [-82.283316, 38.141936],
                    [-82.264849, 38.229199],
                    [-82.220797, 38.310411],
                    [-82.047128, 38.374432],
                    [-82.048589, 38.290138],
                    [-81.997406, 38.266605],
                    [-81.915197, 38.325597],
                    [-81.900222, 38.398572],
                    [-81.723066, 38.467772],
                    [-81.750547, 38.591014],
                    [-81.696348, 38.626427],
                    [-81.73987700000002, 38.675321],
                    [-81.772843, 38.680843],
                    [-81.79126500000001, 38.733104],
                    [-81.910606, 38.87597],
                    [-81.89847, 38.929603],
                    [-81.82735400000001, 38.945898],
                    [-81.775734, 38.980737],
                    [-81.793304, 39.040353],
                    [-81.807855, 39.083978],
                    [-81.7502668434263, 39.1040311930998],
                    [-81.720677, 39.084228],
                    [-81.581447, 39.026179],
                    [-81.532186, 39.00545],
                    [-81.502628, 38.917922],
                    [-81.52731, 38.901666],
                    [-81.546631, 38.672387],
                    [-81.522166, 38.612746],
                    [-81.471654, 38.546336],
                    [-81.194113, 38.527634],
                    [-81.130775, 38.565948],
                    [-81.08371, 38.611982],
                    [-81.031677, 38.667839],
                    [-80.913289, 38.563827],
                    [-80.881232, 38.507045],
                    [-80.817424, 38.478553000000005],
                    [-80.911361, 38.414785],
                    [-81.231434, 38.263716],
                    [-81.231636, 38.263514],
                    [-81.329265, 38.182481],
                    [-81.380829, 37.969109],
                    [-81.438531, 37.968008],
                    [-81.456632, 37.987307],
                    [-81.571534, 37.927707],
                    [-81.514228, 37.791211],
                    [-81.57653, 37.76331],
                    [-81.607532, 37.788709],
                    [-81.722136, 37.809507],
                    [-81.785641, 37.936404],
                    [-81.980248, 37.9865],
                    [-81.932507, 38.025356],
                    [-81.98877600000002, 38.024884],
                    [-82.190658, 37.97477800000001],
                    [-82.306391, 37.94364],
                    [-82.192314, 38.046314],
                ],
            ],
        },
        properties: {
            CSAFP: '170',
            CBSAFP: '16620',
            AFFGEOID: '310M600US16620',
            GEOID: '16620',
            NAME: 'Charleston, WV',
            NAMELSAD: 'Charleston, WV Metro Area',
            LSAD: 'M1',
            ALAND: 6854592354,
            AWATER: 56066247,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.014763, 40.22937, -83.520227, 40.538694],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.0037, 40.312927],
                    [-84.002372, 40.483115],
                    [-83.993867, 40.535174],
                    [-83.880039, 40.538694],
                    [-83.880001, 40.531893],
                    [-83.520227, 40.504077],
                    [-83.551338, 40.22937],
                    [-83.784196, 40.260046],
                    [-84.014763, 40.273459],
                    [-84.0037, 40.312927],
                ],
            ],
        },
        properties: {
            CSAFP: '198',
            CBSAFP: '13340',
            AFFGEOID: '310M600US13340',
            GEOID: '13340',
            NAME: 'Bellefontaine, OH',
            NAMELSAD: 'Bellefontaine, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1187523861,
            AWATER: 21593253,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.706174, 46.629348, -96.172405, 47.239977],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.705935, 47.239977],
                    [-97.451512, 47.239061],
                    [-96.8360095945419, 47.2379818754871],
                    [-96.8265691272283, 47.150539],
                    [-96.194671, 47.151149],
                    [-96.174108, 46.97737500000001],
                    [-96.174587, 46.717854],
                    [-96.172405, 46.630649],
                    [-96.281196, 46.630779],
                    [-96.78578618259851, 46.6295908492609],
                    [-96.7897865130498, 46.6357466727167],
                    [-96.816308, 46.630185],
                    [-97.279948, 46.629348],
                    [-97.682006, 46.629928],
                    [-97.68150800000001, 46.978481],
                    [-97.706174, 46.978558],
                    [-97.705935, 47.239977],
                ],
            ],
        },
        properties: {
            CSAFP: '244',
            CBSAFP: '22020',
            AFFGEOID: '310M600US22020',
            GEOID: '22020',
            NAME: 'Fargo, ND-MN',
            NAMELSAD: 'Fargo, ND-MN Metro Area',
            LSAD: 'M1',
            ALAND: 7278152547,
            AWATER: 26752592,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.963745, 28.933811999999996, -89.01428, 30.712443999999998],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.963745, 30.066831],
                    [-90.915565, 30.113285000000005],
                    [-90.642111, 30.166448],
                    [-90.631905, 30.22119],
                    [-90.592021, 30.208323999999998],
                    [-90.554147, 30.195628],
                    [-90.485826, 30.276992],
                    [-90.320464, 30.298985],
                    [-90.160636, 30.234337],
                    [-90.243649, 30.361580999999997],
                    [-90.244305, 30.503592000000005],
                    [-90.243384, 30.644831000000003],
                    [-90.255749, 30.712443999999998],
                    [-89.99459, 30.665307],
                    [-89.8261803591152, 30.668823270185204],
                    [-89.821868, 30.644024000000005],
                    [-89.791664, 30.551524000000004],
                    [-89.71249300000001, 30.477510000000002],
                    [-89.6999320828408, 30.454036880786003],
                    [-89.678514, 30.414012000000003],
                    [-89.634208, 30.308256000000004],
                    [-89.607655, 30.217096],
                    [-89.524504, 30.180753],
                    [-89.6050884565546, 30.1428141575593],
                    [-89.656986, 30.118381],
                    [-89.683712, 30.076018000000005],
                    [-89.782534, 30.045372000000004],
                    [-89.845065, 30.01841],
                    [-89.8452971195724, 30.0163818405701],
                    [-89.852583, 29.952721000000004],
                    [-89.795969, 29.934003000000004],
                    [-89.744272, 29.917647],
                    [-89.701725, 29.874085],
                    [-89.647064, 29.863602],
                    [-89.598129, 29.881409000000005],
                    [-89.574425, 29.983738000000002],
                    [-89.494064, 30.040972000000004],
                    [-89.444618, 30.060959],
                    [-89.342163, 30.059172000000004],
                    [-89.303026, 30.091570000000004],
                    [-89.233168, 30.134956999999996],
                    [-89.183256, 30.149344000000003],
                    [-89.185799, 30.063934000000003],
                    [-89.215675, 29.993522999999996],
                    [-89.231178, 29.925484],
                    [-89.236298, 29.877081000000004],
                    [-89.293251, 29.803053000000002],
                    [-89.271034, 29.756355000000003],
                    [-89.399162, 29.770592],
                    [-89.403956, 29.681808000000004],
                    [-89.465562, 29.651738],
                    [-89.50096675313371, 29.6334554250588],
                    [-89.504738, 29.631507999999997],
                    [-89.535202, 29.648567000000003],
                    [-89.602109, 29.610294999999997],
                    [-89.564615, 29.543786000000004],
                    [-89.569607, 29.494044000000002],
                    [-89.53215, 29.434567],
                    [-89.482318, 29.406222],
                    [-89.380001, 29.391785000000002],
                    [-89.312085, 29.388038],
                    [-89.257852, 29.336872],
                    [-89.200389, 29.344418],
                    [-89.134337, 29.27934],
                    [-89.116653, 29.219532],
                    [-89.025974, 29.215153000000004],
                    [-89.01428, 29.166913],
                    [-89.06661700000001, 29.090713999999995],
                    [-89.11653, 29.074096999999995],
                    [-89.148792, 29.02967],
                    [-89.142866, 28.991623],
                    [-89.218673, 29.022515],
                    [-89.259354, 29.058358000000002],
                    [-89.322011, 29.010251000000004],
                    [-89.400966, 28.933811999999996],
                    [-89.40353, 29.016964],
                    [-89.361098, 29.071848000000003],
                    [-89.390515, 29.123576],
                    [-89.432932, 29.149022999999996],
                    [-89.482844, 29.215053],
                    [-89.564551, 29.242527],
                    [-89.606651, 29.252023],
                    [-89.639663, 29.290531000000005],
                    [-89.726162, 29.304026],
                    [-89.842641, 29.318823],
                    [-89.883463, 29.307103000000005],
                    [-89.9027063240243, 29.293036606496003],
                    [-89.95646, 29.253744],
                    [-90.058512, 29.183686999999995],
                    [-90.0898354481942, 29.164475356621104],
                    [-89.998954, 29.298339],
                    [-90.030189, 29.332070999999996],
                    [-90.020622, 29.428461],
                    [-90.137504, 29.477163],
                    [-90.186172, 29.563341000000005],
                    [-90.151725, 29.587871000000003],
                    [-90.155807, 29.677530000000004],
                    [-90.228157, 29.692028],
                    [-90.352104, 29.695512],
                    [-90.371978, 29.759239],
                    [-90.468043, 29.803474],
                    [-90.479904, 29.847377000000005],
                    [-90.529601, 29.884997],
                    [-90.629134, 29.895230000000005],
                    [-90.656312, 29.889246],
                    [-90.7787, 29.922007],
                    [-90.885749, 29.905348],
                    [-90.911804, 29.978500000000004],
                    [-90.946294, 29.977932000000003],
                    [-90.963745, 30.066831],
                ],
            ],
        },
        properties: {
            CSAFP: '406',
            CBSAFP: '35380',
            AFFGEOID: '310M600US35380',
            GEOID: '35380',
            NAME: 'New Orleans-Metairie, LA',
            NAMELSAD: 'New Orleans-Metairie, LA Metro Area',
            LSAD: 'M1',
            ALAND: 8297021697,
            AWATER: 12085399973,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.885639, 26.956640000000004, -80.0830776297553, 27.55847],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.677427, 27.121619000000003],
                    [-80.677862, 27.205988],
                    [-80.67982100000002, 27.55847],
                    [-80.3166866116204, 27.5573400246076],
                    [-80.253665, 27.37979],
                    [-80.1980213526587, 27.263008803672502],
                    [-80.153375, 27.169308],
                    [-80.138605, 27.111517],
                    [-80.116772, 27.072397],
                    [-80.0830776297553, 26.970534],
                    [-80.142007, 26.956640000000004],
                    [-80.580237, 26.957934],
                    [-80.885639, 26.958919],
                    [-80.677427, 27.121619000000003],
                ],
            ],
        },
        properties: {
            CSAFP: '370',
            CBSAFP: '38940',
            AFFGEOID: '310M600US38940',
            GEOID: '38940',
            NAME: 'Port St. Lucie, FL',
            NAMELSAD: 'Port St. Lucie, FL Metro Area',
            LSAD: 'M1',
            ALAND: 2889087065,
            AWATER: 842996044,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.900892, 36.932934, -80.835479, 37.588658],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.900892, 37.142553],
                    [-81.761894, 37.201927],
                    [-81.7390550304855, 37.2394971038728],
                    [-81.678603, 37.202467],
                    [-81.560625, 37.206663],
                    [-81.53307, 37.223414],
                    [-81.483559, 37.250604],
                    [-81.427946, 37.271015],
                    [-81.362156, 37.337687],
                    [-81.311201, 37.424509],
                    [-81.291455, 37.414391],
                    [-81.307387, 37.459486],
                    [-81.222121, 37.510416],
                    [-81.198869, 37.575533],
                    [-81.09463, 37.588658],
                    [-80.866887, 37.434589],
                    [-80.8581481168198, 37.4210069067215],
                    [-80.865148, 37.419927],
                    [-80.883248, 37.383933],
                    [-80.835479, 37.334824],
                    [-80.919259, 37.306163],
                    [-80.9808452568928, 37.3008528161905],
                    [-81.014489, 37.275385],
                    [-80.854627, 37.147482],
                    [-80.930627, 37.115087],
                    [-80.912674, 37.073349],
                    [-81.114319, 37.022612],
                    [-81.22462400000002, 37.047835],
                    [-81.379402, 36.952442],
                    [-81.43673, 37.010135],
                    [-81.474539, 37.018476],
                    [-81.682786, 36.932934],
                    [-81.780545, 36.958629],
                    [-81.891041, 37.113128],
                    [-81.900892, 37.142553],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '14140',
            AFFGEOID: '310M600US14140',
            GEOID: '14140',
            NAME: 'Bluefield, WV-VA',
            NAMELSAD: 'Bluefield, WV-VA Micro Area',
            LSAD: 'M2',
            ALAND: 3355080344,
            AWATER: 9909863,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-103.740066, 34.302648, -103.042738818608, 34.954172],
            type: 'Polygon',
            coordinates: [
                [
                    [-103.738166, 34.60467],
                    [-103.70608800000001, 34.60501],
                    [-103.705788, 34.692446],
                    [-103.49565300000002, 34.691393],
                    [-103.495646, 34.778575],
                    [-103.390274, 34.778122],
                    [-103.389798, 34.865429],
                    [-103.28431700000002, 34.86499],
                    [-103.28414900000001, 34.954172],
                    [-103.042738818608, 34.9541420403614],
                    [-103.042769, 34.747361],
                    [-103.043072, 34.619782],
                    [-103.043946, 34.379555],
                    [-103.043850301172, 34.3127498735079],
                    [-103.043835830165, 34.302648],
                    [-103.45982, 34.303261],
                    [-103.740066, 34.303056],
                    [-103.738166, 34.60467],
                ],
            ],
        },
        properties: {
            CSAFP: '188',
            CBSAFP: '17580',
            AFFGEOID: '310M600US17580',
            GEOID: '17580',
            NAME: 'Clovis, NM',
            NAMELSAD: 'Clovis, NM Micro Area',
            LSAD: 'M2',
            ALAND: 3640295680,
            AWATER: 7992977,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.2805412776288, 26.316245000000002, -81.56218, 26.7893085864431],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.2805412776288, 26.7893085864431],
                    [-82.205562, 26.770528],
                    [-81.931098, 26.770049],
                    [-81.565748, 26.769601],
                    [-81.565103, 26.600333],
                    [-81.563763, 26.513331],
                    [-81.56218, 26.422625000000004],
                    [-81.659506, 26.421077],
                    [-81.6579, 26.317563],
                    [-81.746167, 26.316896],
                    [-81.819019, 26.316245000000002],
                    [-81.8464852479618, 26.3303720485028],
                    [-81.923611, 26.436658],
                    [-81.956611, 26.452358],
                    [-82.013913, 26.452058],
                    [-82.075015, 26.422059],
                    [-82.126671, 26.436279],
                    [-82.180717, 26.476257],
                    [-82.245395, 26.601094],
                    [-82.264351, 26.698496],
                    [-82.264682, 26.756836],
                    [-82.2805412776288, 26.7893085864431],
                ],
            ],
        },
        properties: {
            CSAFP: '163',
            CBSAFP: '15980',
            AFFGEOID: '310M600US15980',
            GEOID: '15980',
            NAME: 'Cape Coral-Fort Myers, FL',
            NAMELSAD: 'Cape Coral-Fort Myers, FL Metro Area',
            LSAD: 'M1',
            ALAND: 2022803065,
            AWATER: 1900583561,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.743729, 30.5916365790876, -82.581202, 31.183988],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.736158, 31.037678999999997],
                    [-83.573959, 31.033875000000002],
                    [-83.574857, 31.078135],
                    [-83.551628, 31.054329000000003],
                    [-83.475617, 31.031963999999995],
                    [-83.295052, 31.027278],
                    [-83.197971, 31.025405000000003],
                    [-83.165072, 31.147198],
                    [-83.038246, 31.146705],
                    [-83.04688500000002, 31.18368],
                    [-82.971247, 31.183988],
                    [-82.971336, 30.869392],
                    [-82.846353, 30.834988],
                    [-82.766159, 30.732404],
                    [-82.581202, 30.696237],
                    [-82.58400518641952, 30.5916365790876],
                    [-82.68952979847282, 30.5978892906418],
                    [-82.87731100000002, 30.609016],
                    [-83.131431, 30.623575],
                    [-83.1366188582036, 30.6238859448931],
                    [-83.3093471215958, 30.634238766671604],
                    [-83.3577163136281, 30.637137873134204],
                    [-83.499951, 30.645662999999995],
                    [-83.6117040588264, 30.6515600937086],
                    [-83.743729, 30.6585269137505],
                    [-83.736158, 31.037678999999997],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '46660',
            AFFGEOID: '310M600US46660',
            GEOID: '46660',
            NAME: 'Valdosta, GA',
            NAMELSAD: 'Valdosta, GA Metro Area',
            LSAD: 'M1',
            ALAND: 4163063562,
            AWATER: 55726310,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.255973, 35.674935, -77.342418, 36.202633],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.255973, 35.81812],
                    [-78.006551, 36.202633],
                    [-77.8864, 36.143994],
                    [-77.720742, 36.133884],
                    [-77.697956, 36.15315],
                    [-77.5407, 36.096085],
                    [-77.517646, 36.036775],
                    [-77.402614, 36.004966],
                    [-77.342418, 35.905262],
                    [-77.350369, 35.819228],
                    [-77.390154, 35.833062],
                    [-77.531262, 35.753528],
                    [-77.665131, 35.674935],
                    [-77.718436, 35.728823],
                    [-77.751339, 35.827794],
                    [-77.828444, 35.867208],
                    [-77.844737, 35.837618],
                    [-77.91565700000001, 35.83645],
                    [-78.175342, 35.739529],
                    [-78.192117, 35.730545],
                    [-78.232876, 35.751427],
                    [-78.255973, 35.81812],
                ],
            ],
        },
        properties: {
            CSAFP: '468',
            CBSAFP: '40580',
            AFFGEOID: '310M600US40580',
            GEOID: '40580',
            NAME: 'Rocky Mount, NC',
            NAMELSAD: 'Rocky Mount, NC Metro Area',
            LSAD: 'M1',
            ALAND: 2708816979,
            AWATER: 9880743,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.4655511875961, 42.463054, -76.702324, 43.37551],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.4655511875961, 43.3708954221664],
                    [-78.329374, 43.373149000000005],
                    [-78.145195, 43.37551],
                    [-77.9955897949384, 43.3653102556776],
                    [-77.994838, 43.365259],
                    [-77.816533, 43.343560000000004],
                    [-77.8082634096072, 43.3432076368983],
                    [-77.760231, 43.341161],
                    [-77.660359, 43.282998],
                    [-77.551022, 43.235763],
                    [-77.50092, 43.250363],
                    [-77.3760519959534, 43.274033763487],
                    [-77.341092, 43.280661],
                    [-77.264177, 43.277363],
                    [-77.130429, 43.285635000000006],
                    [-76.99969100000001, 43.271456],
                    [-76.952174, 43.270692],
                    [-76.841675, 43.305399],
                    [-76.769025, 43.318452],
                    [-76.7220025657128, 43.3375801959814],
                    [-76.702324, 43.080159],
                    [-76.713806, 43.024035],
                    [-76.963926, 43.013157],
                    [-76.981334, 42.861213],
                    [-76.971392, 42.764223],
                    [-76.947429, 42.76443],
                    [-76.895349, 42.656255],
                    [-76.895596, 42.541537],
                    [-76.889805, 42.463054],
                    [-76.987913, 42.463239],
                    [-76.989329, 42.484821],
                    [-77.107203, 42.483771],
                    [-77.143795, 42.576869],
                    [-77.366505, 42.576368],
                    [-77.455632, 42.576895],
                    [-77.490889, 42.577288],
                    [-77.659917, 42.580409],
                    [-77.661315, 42.535067],
                    [-77.720617, 42.535066],
                    [-77.722964, 42.471216],
                    [-77.840231, 42.474576],
                    [-77.840694, 42.517771],
                    [-78.038261, 42.521522],
                    [-78.060899, 42.53316],
                    [-77.95633400000001, 42.667322],
                    [-77.954964, 42.862754],
                    [-77.95553, 42.905861],
                    [-77.909832, 42.987762],
                    [-77.904121, 43.037056],
                    [-77.905934, 43.133561],
                    [-77.99729, 43.132981],
                    [-78.465505, 43.128619],
                    [-78.4655511875961, 43.3708954221664],
                ],
            ],
        },
        properties: {
            CSAFP: '464',
            CBSAFP: '40380',
            AFFGEOID: '310M600US40380',
            GEOID: '40380',
            NAME: 'Rochester, NY',
            NAMELSAD: 'Rochester, NY Metro Area',
            LSAD: 'M1',
            ALAND: 8458946389,
            AWATER: 5127534229,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.95222400000002, 41.284244, -82.342337, 41.4967057954382],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.95222400000002, 41.458239],
                    [-82.73044, 41.479343],
                    [-82.6905671606649, 41.4967057954382],
                    [-82.687921, 41.492324],
                    [-82.616952, 41.428425],
                    [-82.533208, 41.391157],
                    [-82.460599, 41.386316],
                    [-82.361784, 41.426644],
                    [-82.3480192068912, 41.42726330873451],
                    [-82.345195, 41.357165],
                    [-82.345048, 41.353479],
                    [-82.342337, 41.284244],
                    [-82.841475, 41.290023],
                    [-82.8446, 41.362779],
                    [-82.847804, 41.430516],
                    [-82.95222400000002, 41.458239],
                ],
            ],
        },
        properties: {
            CSAFP: '184',
            CBSAFP: '41780',
            AFFGEOID: '310M600US41780',
            GEOID: '41780',
            NAME: 'Sandusky, OH',
            NAMELSAD: 'Sandusky, OH Micro Area',
            LSAD: 'M2',
            ALAND: 650833148,
            AWATER: 969939819,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.7381729998702, 30.402547, -92.978673, 30.882698],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.702665, 30.429947],
                    [-93.710117, 30.5064],
                    [-93.729195, 30.544842],
                    [-93.684329, 30.592586],
                    [-93.685121, 30.625201000000004],
                    [-93.629904, 30.67994],
                    [-93.61768800000002, 30.738478999999998],
                    [-93.569303, 30.802969],
                    [-93.558617, 30.869424000000002],
                    [-93.5545758543455, 30.877469501861103],
                    [-93.478425, 30.859362000000004],
                    [-93.426486, 30.882698],
                    [-92.978673, 30.878136],
                    [-92.97909800000001, 30.597995000000004],
                    [-93.13073200000001, 30.598092999999995],
                    [-93.131155, 30.423591],
                    [-93.131224, 30.403058000000005],
                    [-93.38524, 30.402658],
                    [-93.384521, 30.490166],
                    [-93.486325, 30.490523],
                    [-93.486159, 30.402547],
                    [-93.7381729998702, 30.4025470540047],
                    [-93.702665, 30.429947],
                ],
            ],
        },
        properties: {
            CSAFP: '217',
            CBSAFP: '19760',
            AFFGEOID: '310M600US19760',
            GEOID: '19760',
            NAME: 'DeRidder, LA',
            NAMELSAD: 'DeRidder, LA Micro Area',
            LSAD: 'M2',
            ALAND: 2997852789,
            AWATER: 21944259,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.196774200302, 41.526437, -84.8049581562396, 41.760216],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.196774200302, 41.759870841803],
                    [-84.8251282838768, 41.760199006351904],
                    [-84.805883, 41.760216],
                    [-84.806082, 41.696089],
                    [-84.8049581562396, 41.5301378133882],
                    [-85.194084, 41.526437],
                    [-85.196774200302, 41.759870841803],
                ],
            ],
        },
        properties: {
            CSAFP: '258',
            CBSAFP: '11420',
            AFFGEOID: '310M600US11420',
            GEOID: '11420',
            NAME: 'Angola, IN',
            NAMELSAD: 'Angola, IN Micro Area',
            LSAD: 'M2',
            ALAND: 799737112,
            AWATER: 35446879,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.680903, 28.612992000000002, -80.7275056700737, 29.6706408057043],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.680903, 29.32443],
                    [-81.445886, 29.380142],
                    [-81.433992, 29.398551999999995],
                    [-81.476893, 29.396552000000003],
                    [-81.520596, 29.500249000000004],
                    [-81.52366, 29.622432],
                    [-81.324059, 29.62561],
                    [-81.2104095965538, 29.6706408057043],
                    [-81.163581, 29.555290000000003],
                    [-81.1029673517454, 29.426996650452],
                    [-81.046678, 29.307856000000005],
                    [-80.966176, 29.14796],
                    [-80.907275, 29.064262],
                    [-80.787021, 28.875266],
                    [-80.7275056700737, 28.791192871322703],
                    [-80.967895, 28.790197],
                    [-80.964466, 28.612992000000002],
                    [-80.98725, 28.612997],
                    [-81.06111, 28.750001000000005],
                    [-81.067829, 28.813325],
                    [-81.18143300000001, 28.783277],
                    [-81.226003, 28.832629999999998],
                    [-81.320473, 28.83453],
                    [-81.36694, 28.879227],
                    [-81.354554, 28.984375],
                    [-81.502055, 29.097802],
                    [-81.531262, 29.176621],
                    [-81.61234, 29.202942999999998],
                    [-81.641916, 29.276765999999995],
                    [-81.680903, 29.32443],
                ],
            ],
        },
        properties: {
            CSAFP: '422',
            CBSAFP: '19660',
            AFFGEOID: '310M600US19660',
            GEOID: '19660',
            NAME: 'Deltona-Daytona Beach-Ormond Beach, FL',
            NAMELSAD: 'Deltona-Daytona Beach-Ormond Beach, FL Metro Area',
            LSAD: 'M1',
            ALAND: 4111637369,
            AWATER: 1076797418,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-73.389966, 44.161412, -72.53328, 45.014790000000005],
            type: 'Polygon',
            coordinates: [
                [
                    [-73.389966, 44.61962],
                    [-73.36556, 44.700297],
                    [-73.357671, 44.751018],
                    [-73.3358066361073, 44.799157043087206],
                    [-73.33443, 44.802188],
                    [-73.365678, 44.826451],
                    [-73.37982200000002, 44.857037],
                    [-73.338979, 44.917681],
                    [-73.34474, 44.970468],
                    [-73.343124, 45.01084],
                    [-73.1925747582411, 45.0128576207511],
                    [-73.1702286644526, 45.0131570971351],
                    [-73.048386, 45.014790000000005],
                    [-72.93644000000002, 45.014267],
                    [-72.582371, 45.011543],
                    [-72.5537800215062, 45.0094314139369],
                    [-72.53328, 44.834081],
                    [-72.579075, 44.780896],
                    [-72.613597, 44.800417],
                    [-72.678379, 44.802482],
                    [-72.729097, 44.804039],
                    [-72.79322, 44.690669],
                    [-72.85079300000001, 44.711124],
                    [-72.923777, 44.633214],
                    [-72.833735, 44.556843],
                    [-72.809956, 44.54671400000001],
                    [-72.838296, 44.459293],
                    [-72.804857, 44.451882],
                    [-72.839829, 44.403452],
                    [-72.825957, 44.359025],
                    [-72.861251, 44.3669],
                    [-72.905958, 44.280554],
                    [-72.89498, 44.278097],
                    [-72.922295, 44.222735],
                    [-72.952204, 44.161412],
                    [-72.965447, 44.231393],
                    [-72.975883, 44.295036],
                    [-73.3174559746651, 44.2635221898768],
                    [-73.324229, 44.310023],
                    [-73.33463700000001, 44.356877],
                    [-73.320954, 44.382669],
                    [-73.293613, 44.440559],
                    [-73.312871, 44.50724600000001],
                    [-73.34798323242651, 44.546162853597],
                    [-73.3636601533155, 44.5635384629765],
                    [-73.367275, 44.567545],
                    [-73.389966, 44.61962],
                ],
            ],
        },
        properties: {
            CSAFP: '162',
            CBSAFP: '15540',
            AFFGEOID: '310M600US15540',
            GEOID: '15540',
            NAME: 'Burlington-South Burlington, VT',
            NAMELSAD: 'Burlington-South Burlington, VT Metro Area',
            LSAD: 'M1',
            ALAND: 3236384145,
            AWATER: 656770685,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.014533, 39.048375, -85.57619200000002, 40.379524],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.013062, 39.604787],
                    [-87.009477, 39.866705],
                    [-86.695127, 39.864845],
                    [-86.695071, 39.92277],
                    [-86.695804, 40.178657],
                    [-86.39885, 40.17719],
                    [-86.356454, 40.178109],
                    [-86.242365, 40.180778],
                    [-86.242743, 40.215834],
                    [-85.861903, 40.218936],
                    [-85.862296, 40.378367],
                    [-85.578589, 40.379524],
                    [-85.576197, 40.077143],
                    [-85.57619200000002, 39.945756],
                    [-85.596678, 39.872926],
                    [-85.596916, 39.786519],
                    [-85.633491, 39.786177],
                    [-85.633228, 39.698462],
                    [-85.629327, 39.45275],
                    [-85.63063, 39.350205],
                    [-85.684515, 39.350049],
                    [-85.952005, 39.347373],
                    [-86.085601, 39.344189],
                    [-86.07903, 39.079641],
                    [-86.080272, 39.05027],
                    [-86.318133, 39.04881],
                    [-86.370066, 39.048375],
                    [-86.381395, 39.339752000000004],
                    [-86.611706, 39.340251],
                    [-86.630818, 39.346948],
                    [-86.63191, 39.441086],
                    [-86.68571, 39.470063],
                    [-86.939981, 39.473345],
                    [-87.014533, 39.473572],
                    [-87.013062, 39.604787],
                ],
            ],
        },
        properties: {
            CSAFP: '294',
            CBSAFP: '26900',
            AFFGEOID: '310M600US26900',
            GEOID: '26900',
            NAME: 'Indianapolis-Carmel-Anderson, IN',
            NAMELSAD: 'Indianapolis-Carmel-Anderson, IN Metro Area',
            LSAD: 'M1',
            ALAND: 11153926538,
            AWATER: 88449263,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.085601, 39.04091, -85.684515, 39.350049],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.085601, 39.344189],
                    [-85.952005, 39.347373],
                    [-85.684515, 39.350049],
                    [-85.686783, 39.130859],
                    [-85.79983400000002, 39.128897],
                    [-85.798804, 39.068536],
                    [-85.860573, 39.04091],
                    [-86.080272, 39.05027],
                    [-86.07903, 39.079641],
                    [-86.085601, 39.344189],
                ],
            ],
        },
        properties: {
            CSAFP: '294',
            CBSAFP: '18020',
            AFFGEOID: '310M600US18020',
            GEOID: '18020',
            NAME: 'Columbus, IN',
            NAMELSAD: 'Columbus, IN Metro Area',
            LSAD: 'M1',
            ALAND: 1053968851,
            AWATER: 6715105,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-122.31768200000002, 36.8500494437455, -121.581354, 37.286055000000005],
            type: 'Polygon',
            coordinates: [
                [
                    [-122.31768200000002, 37.186945],
                    [-122.15277399999998, 37.215444],
                    [-122.15227800000001, 37.286055000000005],
                    [-121.99109, 37.14427],
                    [-121.84629, 37.09702],
                    [-121.80907600000002, 37.069301],
                    [-121.738627, 36.990085],
                    [-121.581354, 36.899152],
                    [-121.644001, 36.893996],
                    [-121.69995599999999, 36.919683],
                    [-121.81273242676198, 36.8500494437455],
                    [-121.862266, 36.931552],
                    [-121.906468, 36.96895],
                    [-121.95166999999998, 36.97145],
                    [-122.02717400000002, 36.95115],
                    [-122.06731672905998, 36.9535956897314],
                    [-122.105976, 36.955951],
                    [-122.20618000000002, 37.013949],
                    [-122.260481, 37.072548],
                    [-122.284882, 37.101747],
                    [-122.29431019191301, 37.1051413867181],
                    [-122.31768200000002, 37.186945],
                ],
            ],
        },
        properties: {
            CSAFP: '488',
            CBSAFP: '42100',
            AFFGEOID: '310M600US42100',
            GEOID: '42100',
            NAME: 'Santa Cruz-Watsonville, CA',
            NAMELSAD: 'Santa Cruz-Watsonville, CA Metro Area',
            LSAD: 'M1',
            ALAND: 1152823584,
            AWATER: 419714230,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.3480192068912, 40.98859, -81.0022694065175, 41.8491724664376],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.3480192068912, 41.42726330873451],
                    [-82.268479, 41.430842],
                    [-82.181598, 41.471634],
                    [-82.0711504528736, 41.4969119867847],
                    [-81.994565, 41.51444],
                    [-81.968480702529, 41.5038610079018],
                    [-81.9603249700039, 41.5005532925978],
                    [-81.937862, 41.491443],
                    [-81.82250357969941, 41.4952594192894],
                    [-81.810758, 41.49564800000001],
                    [-81.7685751416216, 41.4914896464763],
                    [-81.73875500000001, 41.48855],
                    [-81.633652, 41.540458],
                    [-81.488682733409, 41.6344639278209],
                    [-81.466038, 41.649148],
                    [-81.388632, 41.707144],
                    [-81.286925, 41.760243],
                    [-81.184368, 41.786671],
                    [-81.05192, 41.839557],
                    [-81.0022694065175, 41.8491724664376],
                    [-81.00363100000001, 41.715137],
                    [-81.003125, 41.501685],
                    [-81.003319, 41.34786],
                    [-81.391694, 41.348272],
                    [-81.450331, 41.349148],
                    [-81.517658, 41.350154],
                    [-81.598052, 41.351157],
                    [-81.565139, 41.277758],
                    [-81.59054200000001, 41.27755400000001],
                    [-81.670199, 41.277256],
                    [-81.68495, 41.277146],
                    [-81.68699, 41.135956],
                    [-81.688491, 40.98859],
                    [-81.977381, 40.989961],
                    [-82.129334, 40.991807],
                    [-82.173359, 40.992046],
                    [-82.171492, 41.063537000000004],
                    [-82.336496, 41.065761],
                    [-82.34038, 41.20074100000001],
                    [-82.340324, 41.211282],
                    [-82.342337, 41.284244],
                    [-82.345048, 41.353479],
                    [-82.345195, 41.357165],
                    [-82.3480192068912, 41.42726330873451],
                ],
            ],
        },
        properties: {
            CSAFP: '184',
            CBSAFP: '17460',
            AFFGEOID: '310M600US17460',
            GEOID: '17460',
            NAME: 'Cleveland-Elyria, OH',
            NAMELSAD: 'Cleveland-Elyria, OH Metro Area',
            LSAD: 'M1',
            ALAND: 5176805786,
            AWATER: 5128428315,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-113.798441, 43.019407032899, -111.044052705118, 44.234126],
            type: 'Polygon',
            coordinates: [
                [
                    [-113.76117, 43.621194],
                    [-113.453648, 43.768189],
                    [-113.413358, 43.816847],
                    [-113.360825, 43.796833],
                    [-113.45398500000002, 43.873908],
                    [-113.457816, 44.059528],
                    [-113.416272, 44.059338],
                    [-113.416525, 44.233364],
                    [-113.320609, 44.233164],
                    [-112.99509100000002, 44.234126],
                    [-112.99432300000001, 43.972362],
                    [-112.695242, 43.971941],
                    [-112.694744, 44.058464],
                    [-112.155935, 44.05778],
                    [-112.15637, 43.971247],
                    [-111.97701600000002, 43.970909],
                    [-111.976849, 43.927385],
                    [-111.983527, 43.753806],
                    [-111.934357, 43.76616],
                    [-111.717356, 43.655326],
                    [-111.626043, 43.626756],
                    [-111.565974, 43.622037],
                    [-111.399765, 43.621976],
                    [-111.243839, 43.621853],
                    [-111.207561, 43.543857],
                    [-111.04536056097, 43.5010514511122],
                    [-111.044617, 43.31572],
                    [-111.044143, 43.072364],
                    [-111.044052705118, 43.019407032899],
                    [-111.58948900000001, 43.020052],
                    [-111.588049, 43.281841],
                    [-111.822494, 43.282286],
                    [-111.822475, 43.366035],
                    [-112.043821, 43.366708],
                    [-112.043672, 43.410143],
                    [-112.103298, 43.425057],
                    [-112.520024, 43.42517],
                    [-112.519861, 43.626623],
                    [-112.639238, 43.626574],
                    [-112.697122, 43.62314],
                    [-112.698891, 43.540052],
                    [-112.816278, 43.535949],
                    [-112.816704, 43.449164],
                    [-112.936835, 43.449386],
                    [-112.947016, 43.284821],
                    [-113.007318, 43.284778],
                    [-113.361958, 43.284678],
                    [-113.36191, 43.36716],
                    [-113.63523, 43.367546],
                    [-113.634325, 43.497474],
                    [-113.701153, 43.584553],
                    [-113.798441, 43.569217],
                    [-113.76117, 43.621194],
                ],
            ],
        },
        properties: {
            CSAFP: '292',
            CBSAFP: '26820',
            AFFGEOID: '310M600US26820',
            GEOID: '26820',
            NAME: 'Idaho Falls, ID',
            NAMELSAD: 'Idaho Falls, ID Metro Area',
            LSAD: 'M1',
            ALAND: 13457961751,
            AWATER: 125285841,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.25395, 44.511069, -84.847309, 45.21074200000001],
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [-86.138095, 45.043038],
                        [-86.04443, 45.159582],
                        [-85.989412, 45.151069],
                        [-85.954021, 45.119281],
                        [-85.976883, 45.06266],
                        [-86.081487, 44.990096],
                        [-86.154824, 45.002394],
                        [-86.138095, 45.043038],
                    ],
                ],
                [
                    [
                        [-86.248474, 44.699046],
                        [-86.160268, 44.728189],
                        [-86.089186, 44.741496],
                        [-86.0784981880303, 44.7783307476609],
                        [-86.065966, 44.821522],
                        [-86.058862, 44.911012],
                        [-85.980219, 44.906136000000004],
                        [-85.9316, 44.968788],
                        [-85.854304, 44.938147],
                        [-85.780439, 44.977932],
                        [-85.746444, 45.051229],
                        [-85.681096, 45.092693],
                        [-85.633124, 45.170899],
                        [-85.551072, 45.21074200000001],
                        [-85.531461, 45.177247],
                        [-85.56613, 45.043633],
                        [-85.5551427027088, 45.0270345441369],
                        [-85.520034, 44.973996],
                        [-85.475204, 44.991053],
                        [-85.4314154750083, 45.0166494527177],
                        [-85.473841, 44.859449],
                        [-85.381983, 44.859568],
                        [-85.332836, 44.812372],
                        [-85.327696, 44.850707],
                        [-84.847309, 44.858037],
                        [-84.850929, 44.511069],
                        [-85.334, 44.512303],
                        [-85.817802, 44.512969],
                        [-86.2370208650165, 44.5182985861447],
                        [-86.220697, 44.566742],
                        [-86.25395, 44.64808],
                        [-86.248474, 44.699046],
                    ],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '45900',
            AFFGEOID: '310M600US45900',
            GEOID: '45900',
            NAME: 'Traverse City, MI',
            NAMELSAD: 'Traverse City, MI Micro Area',
            LSAD: 'M2',
            ALAND: 4379610578,
            AWATER: 7440142407,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.562732, 30.752363000000003, -97.070057, 31.711019000000004],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.562732, 31.230443],
                    [-98.281557, 31.401450999999998],
                    [-98.27107, 31.416397999999997],
                    [-98.180006, 31.463717],
                    [-97.766431, 31.672461000000002],
                    [-97.689834, 31.711019000000004],
                    [-97.60523, 31.587762],
                    [-97.418606, 31.320202000000002],
                    [-97.343426, 31.244215000000004],
                    [-97.278113, 31.279799],
                    [-97.070057, 30.986031],
                    [-97.25895900000002, 30.889579],
                    [-97.315485, 30.752363000000003],
                    [-97.624051, 30.870004000000005],
                    [-97.8281, 30.904413999999996],
                    [-97.840365, 30.929318],
                    [-97.91168900000001, 31.035009000000002],
                    [-98.439711, 31.029647],
                    [-98.527504, 31.091171],
                    [-98.51034100000001, 31.155666000000004],
                    [-98.562732, 31.230443],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '28660',
            AFFGEOID: '310M600US28660',
            GEOID: '28660',
            NAME: 'Killeen-Temple, TX',
            NAMELSAD: 'Killeen-Temple, TX Metro Area',
            LSAD: 'M1',
            ALAND: 7300157414,
            AWATER: 108968552,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.067364, 37.638376, -87.017453, 38.246077],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.0594711307875, 37.8666875964907],
                    [-88.04086100000002, 37.89176700000001],
                    [-88.016311, 37.961574],
                    [-88.030884, 38.030713],
                    [-87.98877, 38.055591],
                    [-87.96221, 38.100054],
                    [-87.927468, 38.151946],
                    [-87.975819, 38.197834],
                    [-87.9702008408039, 38.2302710583858],
                    [-87.799169, 38.22858],
                    [-87.799677, 38.201944],
                    [-87.688374, 38.168419],
                    [-87.467284, 38.165403],
                    [-87.31703, 38.201753],
                    [-87.316893, 38.246077],
                    [-87.298388, 38.231671],
                    [-87.073067, 38.232596],
                    [-87.01749, 38.20358],
                    [-87.017453, 38.118301],
                    [-87.072332, 38.11828],
                    [-87.268003, 37.924766],
                    [-87.270387407739, 37.87542282659],
                    [-87.304057452442, 37.8934325491223],
                    [-87.323402, 37.819239],
                    [-87.271608, 37.78012],
                    [-87.394093, 37.723995],
                    [-87.408547, 37.683563],
                    [-87.472811, 37.664869],
                    [-87.495331, 37.647547],
                    [-87.734545, 37.638376],
                    [-87.761995, 37.762365],
                    [-87.815093, 37.762423],
                    [-87.9253933817098, 37.8995914771373],
                    [-87.938128, 37.870651],
                    [-87.903804, 37.817762],
                    [-87.935861, 37.789703],
                    [-87.970262, 37.781856],
                    [-88.02803, 37.799224],
                    [-88.067364, 37.856051],
                    [-88.0594711307875, 37.8666875964907],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '21780',
            AFFGEOID: '310M600US21780',
            GEOID: '21780',
            NAME: 'Evansville, IN-KY',
            NAMELSAD: 'Evansville, IN-KY Metro Area',
            LSAD: 'M1',
            ALAND: 3791627449,
            AWATER: 127235950,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-122.42190399999998, 38.021622, -119.87728700000001, 39.3164750119585],
            type: 'Polygon',
            coordinates: [
                [
                    [-122.403941, 38.925289],
                    [-122.340172, 38.924246],
                    [-121.835488, 38.924481],
                    [-121.81481400000001, 38.876582],
                    [-121.72314900000002, 38.851306],
                    [-121.673454, 38.743035],
                    [-121.602894, 38.735838],
                    [-121.55986800000001, 38.736302],
                    [-121.484396, 38.734598],
                    [-121.469356, 38.925992],
                    [-121.41439899999999, 38.926214],
                    [-121.414779, 38.996452000000005],
                    [-121.365643, 39.031743],
                    [-121.33213500000001, 39.042021],
                    [-121.279533, 39.03461800000001],
                    [-121.137979, 39.03791100000001],
                    [-121.067546, 39.005371],
                    [-120.90899600000002, 39.170173],
                    [-120.72789299999998, 39.288081],
                    [-120.55460000000001, 39.315596],
                    [-120.32237800000001, 39.316427],
                    [-120.036657, 39.316334],
                    [-120.004795512304, 39.3164750119585],
                    [-120.00514200000002, 39.291258],
                    [-120.003364028833, 39.1656267640688],
                    [-120.00261484291302, 39.11268937409581],
                    [-120.001975250867, 39.0674958744683],
                    [-120.00101400000001, 38.999574],
                    [-119.90431500000001, 38.933324],
                    [-119.87728700000001, 38.870193],
                    [-119.96494800000002, 38.775986],
                    [-120.07239200000001, 38.702767],
                    [-120.098322, 38.709029],
                    [-120.14008400000002, 38.638374],
                    [-120.212787, 38.629371],
                    [-120.30172100000001, 38.549109],
                    [-120.510596, 38.511467],
                    [-120.62760400000002, 38.503143],
                    [-120.813554, 38.562193],
                    [-121.027507, 38.508292],
                    [-121.027084, 38.300252],
                    [-121.22353700000001, 38.244463],
                    [-121.28226699999999, 38.249836],
                    [-121.398193, 38.227427],
                    [-121.448142, 38.255008],
                    [-121.472138, 38.259659],
                    [-121.580022, 38.094414],
                    [-121.673065, 38.093518],
                    [-121.737824, 38.02663],
                    [-121.80127000000002, 38.021622],
                    [-121.862462, 38.06603],
                    [-121.860477, 38.070607],
                    [-121.843228, 38.076655],
                    [-121.827916, 38.066223],
                    [-121.796395, 38.060847],
                    [-121.74316400000001, 38.087229],
                    [-121.71213000000002, 38.08552],
                    [-121.685645, 38.159644],
                    [-121.615413, 38.195696],
                    [-121.59327300000001, 38.313089],
                    [-121.693697, 38.313732],
                    [-121.707976, 38.535399],
                    [-121.940285, 38.533384],
                    [-122.01167299999999, 38.488991],
                    [-122.10328100000001, 38.513348],
                    [-122.16830100000001, 38.655296],
                    [-122.224206, 38.699984],
                    [-122.287998, 38.839931],
                    [-122.39505599999998, 38.864245],
                    [-122.42190399999998, 38.90377],
                    [-122.403941, 38.925289],
                ],
            ],
        },
        properties: {
            CSAFP: '472',
            CBSAFP: '40900',
            AFFGEOID: '310M600US40900',
            GEOID: '40900',
            NAME: 'Sacramento-Roseville-Folsom, CA',
            NAMELSAD: 'Sacramento-Roseville-Folsom, CA Metro Area',
            LSAD: 'M1',
            ALAND: 13195840653,
            AWATER: 547993783,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-123.01153300000001, 37.1051413867181, -121.471925, 38.321209],
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [-122.518088, 37.576138],
                        [-122.496786, 37.612136],
                        [-122.49678400000002, 37.686433],
                        [-122.500678019083, 37.7081325680179],
                        [-122.50560125134501, 37.7355674652407],
                        [-122.51198299999999, 37.77113],
                        [-122.465396, 37.800879],
                        [-122.39813900000001, 37.80563],
                        [-122.385323, 37.790724],
                        [-122.376462, 37.738558],
                        [-122.356784, 37.729505],
                        [-122.36174900000002, 37.71501],
                        [-122.38982687658802, 37.708331],
                        [-122.39319000000002, 37.707531],
                        [-122.360219, 37.592501],
                        [-122.24437199999998, 37.55814],
                        [-122.16844900000001, 37.504143],
                        [-122.129199702224, 37.52132199859081],
                        [-122.111998, 37.528851],
                        [-122.14439600000001, 37.581866],
                        [-122.15290499999999, 37.640771],
                        [-122.162802507492, 37.6672729813177],
                        [-122.163049, 37.667933],
                        [-122.21377399999999, 37.698695],
                        [-122.24981, 37.726406],
                        [-122.252452, 37.755129],
                        [-122.312974, 37.777244],
                        [-122.333711, 37.80979700000001],
                        [-122.30393099999999, 37.830087],
                        [-122.32871428740701, 37.8938323463208],
                        [-122.33453, 37.908791],
                        [-122.378709, 37.905191],
                        [-122.425258, 37.95567200000001],
                        [-122.367582, 37.978168],
                        [-122.36889100000002, 38.007948],
                        [-122.34280385218801, 38.0092538777817],
                        [-122.321706, 38.01031],
                        [-122.26286099999999, 38.051473],
                        [-122.26932005491699, 38.0603744975312],
                        [-122.14312000000001, 38.031761],
                        [-122.09573299999998, 38.048355],
                        [-122.05038600000002, 38.060198],
                        [-121.87823499999999, 38.049318],
                        [-121.862462, 38.06603],
                        [-121.80127000000002, 38.021622],
                        [-121.737824, 38.02663],
                        [-121.673065, 38.093518],
                        [-121.580022, 38.094414],
                        [-121.53416699999998, 37.849023],
                        [-121.55699700000001, 37.816488],
                        [-121.556959, 37.743051],
                        [-121.556655, 37.542732],
                        [-121.471925, 37.481783],
                        [-121.47264800000002, 37.48217],
                        [-121.85576200000001, 37.484537],
                        [-121.92504100000001, 37.454186],
                        [-122.05967299999999, 37.464087],
                        [-122.08147300000002, 37.477838],
                        [-122.144933, 37.4582],
                        [-122.19040200000002, 37.431472],
                        [-122.19216200000001, 37.319094],
                        [-122.15227800000001, 37.286055000000005],
                        [-122.15277399999998, 37.215444],
                        [-122.31768200000002, 37.186945],
                        [-122.29431019191301, 37.1051413867181],
                        [-122.322971, 37.11546],
                        [-122.344029, 37.144099],
                        [-122.397065, 37.187249],
                        [-122.418452, 37.248521],
                        [-122.40559014390101, 37.3149650377439],
                        [-122.401323, 37.337009],
                        [-122.40925799999998, 37.374805],
                        [-122.443687, 37.435941],
                        [-122.445987, 37.461541],
                        [-122.493789, 37.492341],
                        [-122.516689, 37.52134],
                        [-122.518088, 37.576138],
                    ],
                ],
                [
                    [
                        [-123.01153300000001, 38.003438],
                        [-122.960889, 38.112962],
                        [-122.953629, 38.17567],
                        [-122.987149, 38.237538],
                        [-122.98631900000001, 38.273164],
                        [-123.003146477056, 38.2957052387145],
                        [-122.911319, 38.321209],
                        [-122.7399, 38.207018],
                        [-122.56509299999999, 38.182217],
                        [-122.50570700000002, 38.115526],
                        [-122.488499639259, 38.1090944743082],
                        [-122.491283, 38.108087],
                        [-122.49946500000001, 38.032165],
                        [-122.452995, 37.996167],
                        [-122.488665, 37.966714],
                        [-122.48637500000001, 37.921881000000006],
                        [-122.448413, 37.89341],
                        [-122.41847, 37.852721],
                        [-122.48348300000002, 37.826728],
                        [-122.53728499999998, 37.830328],
                        [-122.60129000000002, 37.875126],
                        [-122.678474, 37.906604],
                        [-122.70264, 37.89382],
                        [-122.754606, 37.93552700000001],
                        [-122.79740499999998, 37.976657],
                        [-122.856573, 38.01671700000001],
                        [-122.93971100000002, 38.031908],
                        [-122.97438999999999, 37.992429],
                        [-123.01153300000001, 38.003438],
                    ],
                ],
            ],
        },
        properties: {
            CSAFP: '488',
            CBSAFP: '41860',
            AFFGEOID: '310M600US41860',
            GEOID: '41860',
            NAME: 'San Francisco-Oakland-Berkeley, CA',
            NAMELSAD: 'San Francisco-Oakland-Berkeley, CA Metro Area',
            LSAD: 'M1',
            ALAND: 6398174799,
            AWATER: 2475529965,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.640435, 38.901623, -86.939981, 40.1480353967457],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.640435, 39.166727],
                    [-87.577029, 39.211123],
                    [-87.593486, 39.247452],
                    [-87.594745845406, 39.259383616483],
                    [-87.600397, 39.312904],
                    [-87.578331, 39.340343],
                    [-87.531646, 39.347888],
                    [-87.531624, 39.469378000000006],
                    [-87.5316666819743, 39.4771109927429],
                    [-87.532385282791, 39.6073049442215],
                    [-87.532703, 39.664868],
                    [-87.5324544938843, 39.8829991187823],
                    [-87.532308, 40.011587],
                    [-87.531021612428, 40.1480353967457],
                    [-87.49041, 40.127406],
                    [-87.406667, 40.127508],
                    [-87.440179, 39.961176],
                    [-87.420221, 39.952452],
                    [-87.091006, 39.953009],
                    [-87.091542, 39.86726500000001],
                    [-87.009477, 39.866705],
                    [-87.013062, 39.604787],
                    [-87.014533, 39.473572],
                    [-86.939981, 39.473345],
                    [-86.942473, 39.342043],
                    [-87.053646, 39.342621],
                    [-87.054578, 39.168086],
                    [-87.240737, 39.171773],
                    [-87.241027, 38.907874],
                    [-87.26025700000001, 38.901623],
                    [-87.5287176636979, 38.9059437862892],
                    [-87.52764500000002, 38.907688],
                    [-87.529496, 38.971925],
                    [-87.579117, 39.001607],
                    [-87.572588, 39.057286],
                    [-87.625379, 39.101806],
                    [-87.6383674685664, 39.1578118692748],
                    [-87.640435, 39.166727],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '45460',
            AFFGEOID: '310M600US45460',
            GEOID: '45460',
            NAME: 'Terre Haute, IN',
            NAMELSAD: 'Terre Haute, IN Metro Area',
            LSAD: 'M1',
            ALAND: 4946822637,
            AWATER: 64269393,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.059464, 26.597995, -97.2229914075318, 27.635932],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.059464, 27.635862],
                    [-97.94214600000001, 27.635932],
                    [-97.840536, 27.558473],
                    [-97.325216, 27.560896],
                    [-97.2229914075318, 27.5766073164796],
                    [-97.257325, 27.510644],
                    [-97.296057, 27.427175],
                    [-97.33612200000002, 27.317818],
                    [-97.3468508769557, 27.2779588322095],
                    [-97.358469, 27.234796],
                    [-97.378697, 27.060044],
                    [-97.366872, 26.885581],
                    [-97.32275, 26.701746],
                    [-97.2875411166858, 26.6003398814733],
                    [-97.861688, 26.597995],
                    [-97.957405, 26.611769],
                    [-97.985303, 26.615809000000002],
                    [-97.985494, 26.780917],
                    [-97.985887, 27.209308000000004],
                    [-97.98607600000001, 27.260494000000005],
                    [-98.05807800000001, 27.260981],
                    [-98.059464, 27.635862],
                ],
            ],
        },
        properties: {
            CSAFP: '204',
            CBSAFP: '28780',
            AFFGEOID: '310M600US28780',
            GEOID: '28780',
            NAME: 'Kingsville, TX',
            NAMELSAD: 'Kingsville, TX Micro Area',
            LSAD: 'M2',
            ALAND: 6060230287,
            AWATER: 1802968969,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.034924, 33.506168, -88.472714, 33.812374],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.034498, 33.73943],
                    [-88.931604, 33.754312],
                    [-88.931176, 33.812374],
                    [-88.719979, 33.81133],
                    [-88.71962600000002, 33.680253],
                    [-88.501915, 33.679948],
                    [-88.490978, 33.673726],
                    [-88.491087, 33.673214],
                    [-88.49142, 33.67272],
                    [-88.49156, 33.672522],
                    [-88.49179, 33.672195],
                    [-88.492211, 33.671795],
                    [-88.492484, 33.671543],
                    [-88.492906, 33.671248],
                    [-88.493181, 33.671248],
                    [-88.493437, 33.671452],
                    [-88.493554, 33.671542],
                    [-88.493721, 33.671697],
                    [-88.493881, 33.671854],
                    [-88.494217, 33.672333],
                    [-88.49447, 33.672814],
                    [-88.494698, 33.673106],
                    [-88.494976, 33.67341900000001],
                    [-88.495228, 33.673669],
                    [-88.495262, 33.673696],
                    [-88.495782, 33.674107],
                    [-88.496161, 33.674525],
                    [-88.496583, 33.674777],
                    [-88.496904, 33.674958],
                    [-88.497142, 33.675087],
                    [-88.497793, 33.675273],
                    [-88.498369, 33.675313],
                    [-88.49889300000001, 33.67529],
                    [-88.49922, 33.675234],
                    [-88.49937200000001, 33.67522],
                    [-88.499725, 33.675191],
                    [-88.499803, 33.675143],
                    [-88.49993100000002, 33.675067000000006],
                    [-88.499987, 33.67503],
                    [-88.500043, 33.674993],
                    [-88.500465, 33.674698],
                    [-88.501129, 33.674113],
                    [-88.501191, 33.674059],
                    [-88.501439, 33.673634],
                    [-88.501594, 33.673353],
                    [-88.501837, 33.672479],
                    [-88.502121, 33.671301],
                    [-88.502292, 33.670323],
                    [-88.502386, 33.669196],
                    [-88.502407, 33.668947],
                    [-88.502394, 33.66888],
                    [-88.502207, 33.668139],
                    [-88.50200700000002, 33.667265],
                    [-88.501928, 33.666188],
                    [-88.502126, 33.665847],
                    [-88.502378, 33.665685],
                    [-88.503169, 33.665457],
                    [-88.503687, 33.665481],
                    [-88.505731, 33.664641],
                    [-88.506533, 33.664047],
                    [-88.507481, 33.663166],
                    [-88.508841, 33.662196],
                    [-88.509407, 33.66196],
                    [-88.510085, 33.661816],
                    [-88.511344, 33.661691],
                    [-88.512048, 33.661528],
                    [-88.512655, 33.661235],
                    [-88.513104, 33.660697],
                    [-88.513339, 33.659311],
                    [-88.513515, 33.657958],
                    [-88.51357400000002, 33.657013],
                    [-88.513608, 33.656652],
                    [-88.513698, 33.655701],
                    [-88.513938, 33.654485],
                    [-88.514421, 33.653632],
                    [-88.514482, 33.65303],
                    [-88.514315, 33.651805],
                    [-88.513987, 33.650857],
                    [-88.513955, 33.650611],
                    [-88.513933, 33.650438],
                    [-88.513917, 33.650209],
                    [-88.472714, 33.524451],
                    [-88.67125, 33.506168],
                    [-88.671526, 33.565135],
                    [-88.770596, 33.565212],
                    [-89.018613, 33.562114],
                    [-89.018343, 33.625119],
                    [-89.034924, 33.654712],
                    [-89.034498, 33.73943],
                ],
            ],
        },
        properties: {
            CSAFP: '200',
            CBSAFP: '48500',
            AFFGEOID: '310M600US48500',
            GEOID: '48500',
            NAME: 'West Point, MS',
            NAMELSAD: 'West Point, MS Micro Area',
            LSAD: 'M2',
            ALAND: 1062082196,
            AWATER: 15376919,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-157.310748, 20.504738, -155.985413, 21.225684],
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [-156.69989, 20.920629],
                        [-156.680905, 20.980262],
                        [-156.61958100000004, 21.027793],
                        [-156.562773, 21.016167],
                        [-156.518707, 20.954662],
                        [-156.481055, 20.898199],
                        [-156.403304, 20.915826],
                        [-156.332817, 20.94645],
                        [-156.242555, 20.937838],
                        [-156.19471, 20.891975],
                        [-156.132669, 20.861369],
                        [-156.059788, 20.81054],
                        [-156.003532, 20.795545],
                        [-155.985413, 20.744245],
                        [-156.00187, 20.698064],
                        [-156.043786, 20.664902000000005],
                        [-156.12989800000003, 20.627523],
                        [-156.210258, 20.628518],
                        [-156.284391, 20.596488],
                        [-156.377633, 20.578427000000005],
                        [-156.431872, 20.598143],
                        [-156.443673, 20.656018],
                        [-156.458438, 20.736676],
                        [-156.462242, 20.753952],
                        [-156.473562, 20.790756],
                        [-156.506026, 20.799463],
                        [-156.537752, 20.778408],
                        [-156.554617, 20.786096],
                        [-156.631794, 20.82124],
                        [-156.687804, 20.89072],
                        [-156.69989, 20.920629],
                    ],
                ],
                [
                    [
                        [-156.670469, 20.559909],
                        [-156.610734, 20.59377],
                        [-156.56714, 20.604895],
                        [-156.54303400000003, 20.580115],
                        [-156.539643, 20.527644],
                        [-156.586238, 20.511711],
                        [-156.668809, 20.504738],
                        [-156.702265, 20.532451],
                        [-156.670469, 20.559909],
                    ],
                ],
                [
                    [
                        [-157.05913, 20.913407],
                        [-157.010001, 20.929757],
                        [-156.937529, 20.925274],
                        [-156.873125, 20.894679],
                        [-156.837047, 20.863575],
                        [-156.808469, 20.820396],
                        [-156.838321, 20.764575],
                        [-156.890295, 20.744855],
                        [-156.909081, 20.739533],
                        [-156.96789, 20.73508],
                        [-156.990678, 20.775902],
                        [-156.991834, 20.826603],
                        [-157.010911, 20.854476],
                        [-157.059663, 20.884634],
                        [-157.05913, 20.913407],
                    ],
                ],
                [
                    [
                        [-157.27722, 21.158431000000004],
                        [-157.249695, 21.184401],
                        [-157.26069, 21.225684],
                        [-157.202125, 21.219298],
                        [-157.128207, 21.201488],
                        [-157.039987, 21.190909],
                        [-157.014267869299, 21.2006942662583],
                        [-157.014612, 21.182349],
                        [-156.973643, 21.17524],
                        [-156.942135, 21.160173],
                        [-156.918072, 21.129479],
                        [-156.900241, 21.131625],
                        [-156.917859561199, 21.1690213462811],
                        [-156.841592, 21.167926],
                        [-156.742231, 21.176214000000005],
                        [-156.709106, 21.158655],
                        [-156.739342, 21.111336000000005],
                        [-156.80220000000003, 21.067095],
                        [-156.877137, 21.0493],
                        [-156.953872, 21.066128],
                        [-157.02617, 21.089015],
                        [-157.08066, 21.101976],
                        [-157.171606, 21.090701],
                        [-157.252534, 21.08767],
                        [-157.310748, 21.101627],
                        [-157.27722, 21.158431000000004],
                    ],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '27980',
            AFFGEOID: '310M600US27980',
            GEOID: '27980',
            NAME: 'Kahului-Wailuku-Lahaina, HI',
            NAMELSAD: 'Kahului-Wailuku-Lahaina, HI Metro Area',
            LSAD: 'M1',
            ALAND: 3008348851,
            AWATER: 3203294213,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.062172, 31.504056000000002, -95.258859, 32.084475],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.052786, 32.005895],
                    [-95.428512, 32.084475],
                    [-95.44644900000002, 31.998517000000003],
                    [-95.423832, 31.932009000000004],
                    [-95.446747, 31.843116],
                    [-95.412908, 31.835157000000002],
                    [-95.396531, 31.765265999999997],
                    [-95.275849, 31.657229],
                    [-95.258859, 31.609959000000003],
                    [-95.273203, 31.592886],
                    [-95.65176400000001, 31.541791],
                    [-95.739279, 31.504056000000002],
                    [-95.710112, 31.615587000000005],
                    [-95.736026, 31.653956000000004],
                    [-95.7873, 31.618385],
                    [-95.79408100000002, 31.66031],
                    [-95.861262, 31.687451],
                    [-95.875937, 31.755503],
                    [-95.980568, 31.784561000000004],
                    [-96.022491, 31.957549],
                    [-96.062172, 31.956340000000004],
                    [-96.052786, 32.005895],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '37300',
            AFFGEOID: '310M600US37300',
            GEOID: '37300',
            NAME: 'Palestine, TX',
            NAMELSAD: 'Palestine, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2752164826,
            AWATER: 39845046,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-99.4608257991343, 26.5696605553736, -98.95423, 27.319174],
            type: 'Polygon',
            coordinates: [
                [
                    [-99.4608257991343, 27.2622401970428],
                    [-99.370621, 27.319174],
                    [-99.33361, 27.273223],
                    [-98.954669, 27.269397000000005],
                    [-98.95423, 26.785694],
                    [-99.011112, 26.675023],
                    [-99.1768160923811, 26.5696605553736],
                    [-99.200522, 26.656443],
                    [-99.208907, 26.724761],
                    [-99.242444, 26.788262],
                    [-99.268613, 26.843213],
                    [-99.3289, 26.879761],
                    [-99.361144, 26.928921],
                    [-99.387367, 26.982399],
                    [-99.44697000000001, 27.026026],
                    [-99.442123, 27.106839],
                    [-99.429984, 27.159149],
                    [-99.445238, 27.223341],
                    [-99.4608257991343, 27.2622401970428],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '49820',
            AFFGEOID: '310M600US49820',
            GEOID: '49820',
            NAME: 'Zapata, TX',
            NAMELSAD: 'Zapata, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2585876042,
            AWATER: 154371160,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-106.377173188247, 32.000464602835, -104.84774, 33.390633],
            type: 'Polygon',
            coordinates: [
                [
                    [-106.376585, 32.95805000000001],
                    [-106.340709, 32.958382],
                    [-106.340515, 33.052777],
                    [-106.34571, 33.390595],
                    [-105.727171, 33.390633],
                    [-105.725957, 33.304834],
                    [-105.317376, 33.305977],
                    [-105.316968, 33.132303],
                    [-105.316047, 32.9621],
                    [-105.35401, 32.518776],
                    [-104.851524, 32.520541],
                    [-104.84774, 32.000464602835],
                    [-104.91835699428, 32.0004720730735],
                    [-105.153994, 32.00049700000001],
                    [-105.750527, 32.002206],
                    [-105.997971151664, 32.001974590699],
                    [-106.200699, 32.001785],
                    [-106.377173188247, 32.00124010103751],
                    [-106.376585, 32.95805000000001],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '10460',
            AFFGEOID: '310M600US10460',
            GEOID: '10460',
            NAME: 'Alamogordo, NM',
            NAMELSAD: 'Alamogordo, NM Micro Area',
            LSAD: 'M2',
            ALAND: 17126455954,
            AWATER: 36944199,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-119.329722, 40.526136, -117.018203216741, 42.00038],
            type: 'Polygon',
            coordinates: [
                [
                    [-119.329722, 40.97385],
                    [-119.305713, 41.234542],
                    [-119.323955, 41.414519],
                    [-119.32418412043899, 41.9938756277933],
                    [-119.20828, 41.993177],
                    [-119.00102199999999, 41.993793],
                    [-118.77586899999999, 41.992692],
                    [-118.501002, 41.995446],
                    [-118.19736921592802, 41.9969940811635],
                    [-118.19718900000001, 41.996995],
                    [-117.87346700000002, 41.998335],
                    [-117.623731, 41.998467],
                    [-117.403613, 41.99929],
                    [-117.197798, 42.00038],
                    [-117.026197, 41.99989],
                    [-117.018203216741, 41.9998398503691],
                    [-117.01841900000001, 41.000254],
                    [-117.018664, 40.643035],
                    [-117.24678800000001, 40.642445],
                    [-117.300597, 40.526136],
                    [-117.30116799999999, 40.682348],
                    [-117.644399, 40.684343],
                    [-117.64422, 40.857824],
                    [-118.43819, 40.855696],
                    [-118.78673799999999, 40.855929],
                    [-118.78670499999998, 40.96085],
                    [-119.309598, 40.960378],
                    [-119.329722, 40.97385],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '49080',
            AFFGEOID: '310M600US49080',
            GEOID: '49080',
            NAME: 'Winnemucca, NV',
            NAMELSAD: 'Winnemucca, NV Micro Area',
            LSAD: 'M2',
            ALAND: 24969535441,
            AWATER: 44194837,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.537599, 34.457776, -79.849536, 36.05881],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.535403, 35.568137],
                    [-80.960025, 35.54702],
                    [-80.933483, 35.591329],
                    [-80.92754, 35.622978],
                    [-80.951241, 35.641413],
                    [-80.966305, 35.670313],
                    [-80.982037, 35.68249200000001],
                    [-80.99444500000001, 35.70211],
                    [-81.005321, 35.710209],
                    [-81.061328, 35.710641],
                    [-81.109507, 35.776594],
                    [-81.041339, 35.857855],
                    [-81.00554100000001, 35.958287],
                    [-81.028833, 36.045652],
                    [-80.975675, 36.05881],
                    [-80.881591, 36.055718],
                    [-80.693197, 36.05126],
                    [-80.707824, 35.852961],
                    [-80.69169300000001, 35.863494],
                    [-80.458923, 35.742758],
                    [-80.422899, 35.725869],
                    [-80.334509, 35.720471],
                    [-80.333317, 35.683563],
                    [-80.20899, 35.581495],
                    [-80.183264, 35.504141],
                    [-80.295421, 35.50292],
                    [-80.49751, 35.201839],
                    [-80.504985, 35.184998],
                    [-80.356982, 35.16494],
                    [-80.276829, 35.195722],
                    [-80.172819, 35.147847],
                    [-80.112644, 35.19388200000001],
                    [-80.075365, 35.143081],
                    [-79.992941, 35.08559],
                    [-79.923972, 35.11543],
                    [-79.877197, 35.03531],
                    [-79.849536, 34.900006],
                    [-79.9246842531364, 34.8078290197515],
                    [-79.927398038772, 34.807862590631],
                    [-80.077223, 34.809716],
                    [-80.3208325711298, 34.813620821544],
                    [-80.561657, 34.817481],
                    [-80.5617085493824, 34.8174824003365],
                    [-80.488532, 34.704584],
                    [-80.408373, 34.614765],
                    [-80.550552, 34.560116],
                    [-80.527821, 34.504003],
                    [-80.739966, 34.479957],
                    [-80.743345, 34.539916],
                    [-80.879227, 34.457776],
                    [-80.898722, 34.486694],
                    [-80.877491, 34.543146],
                    [-81.422706, 34.572029],
                    [-81.415894, 34.633589],
                    [-81.482351, 34.744398],
                    [-81.456304, 34.792521],
                    [-81.478454, 34.821509],
                    [-81.457257, 34.83928],
                    [-81.49210800000002, 34.948956],
                    [-81.487016, 35.034853],
                    [-81.408816, 35.047077],
                    [-81.3676041320777, 35.1640921256134],
                    [-81.3280909892918, 35.1622859155574],
                    [-81.321729, 35.260449],
                    [-81.356682, 35.330604],
                    [-81.4556, 35.419811],
                    [-81.515009, 35.557782],
                    [-81.537599, 35.564228],
                    [-81.535403, 35.568137],
                ],
            ],
        },
        properties: {
            CSAFP: '172',
            CBSAFP: '16740',
            AFFGEOID: '310M600US16740',
            GEOID: '16740',
            NAME: 'Charlotte-Concord-Gastonia, NC-SC',
            NAMELSAD: 'Charlotte-Concord-Gastonia, NC-SC Metro Area',
            LSAD: 'M1',
            ALAND: 14497255567,
            AWATER: 301809706,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.34008499213931, 32.480918, -87.065738, 33.609833],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.317135, 33.184123],
                    [-88.3044428135104, 33.28832],
                    [-88.2745164707837, 33.5340014777852],
                    [-87.946519, 33.524065],
                    [-87.840683, 33.524839],
                    [-87.666661, 33.521667],
                    [-87.631718, 33.609833],
                    [-87.423701, 33.602096],
                    [-87.37104000000001, 33.587065],
                    [-87.318532, 33.587393],
                    [-87.318237, 33.514166],
                    [-87.26692300000002, 33.512929],
                    [-87.341698, 33.470501],
                    [-87.194841, 33.343069],
                    [-87.179754, 33.32801],
                    [-87.179638, 33.321112],
                    [-87.162175, 33.321012],
                    [-87.162007, 33.306587],
                    [-87.127142, 33.306013],
                    [-87.126756, 33.290844],
                    [-87.087497, 33.290675],
                    [-87.087546, 33.279666],
                    [-87.074602, 33.279682],
                    [-87.074634, 33.276018],
                    [-87.065754, 33.275937],
                    [-87.065738, 33.246907],
                    [-87.199153, 33.196554],
                    [-87.199317, 33.130657],
                    [-87.281945, 33.13306],
                    [-87.318539, 33.006179],
                    [-87.421936, 33.003379],
                    [-87.4212, 32.874508],
                    [-87.472174, 32.83062],
                    [-87.473915, 32.655867],
                    [-87.525198, 32.655713],
                    [-87.524485, 32.482028],
                    [-87.728744, 32.480918],
                    [-87.728521, 32.524532],
                    [-87.812559, 32.52456],
                    [-87.824977, 32.544789],
                    [-87.85342, 32.532086],
                    [-87.898355, 32.592321],
                    [-87.840858, 32.605036],
                    [-87.928689, 32.632284],
                    [-88.053729, 32.593052],
                    [-88.132685, 32.832853],
                    [-88.207316, 32.924782],
                    [-88.175329, 32.932093],
                    [-88.171852, 32.99586],
                    [-88.34008499213931, 32.991264337875],
                    [-88.317135, 33.184123],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '46220',
            AFFGEOID: '310M600US46220',
            GEOID: '46220',
            NAME: 'Tuscaloosa, AL',
            NAMELSAD: 'Tuscaloosa, AL Metro Area',
            LSAD: 'M1',
            ALAND: 9047524568,
            AWATER: 167143708,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.158189, 42.033107926811006, -82.415937, 43.32704900000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.158189, 42.776639],
                    [-83.922516, 42.780821],
                    [-83.686493, 42.783263],
                    [-83.689384, 42.871263],
                    [-83.453364, 42.880432],
                    [-83.460733, 43.223131],
                    [-83.460708, 43.232791],
                    [-83.351709, 43.235193],
                    [-83.354418, 43.322257],
                    [-83.120396, 43.32704900000001],
                    [-83.119027, 43.28368],
                    [-83.00043, 43.284913],
                    [-82.996257, 43.15409900000001],
                    [-82.5060424049551, 43.1688273713115],
                    [-82.486042, 43.102486],
                    [-82.415937, 43.005555],
                    [-82.428603, 42.952001],
                    [-82.469912, 42.887459],
                    [-82.467483, 42.76191],
                    [-82.509935, 42.637294],
                    [-82.583996, 42.554041],
                    [-82.679059, 42.52221],
                    [-82.706371, 42.621107],
                    [-82.639701, 42.661233],
                    [-82.707885, 42.675497],
                    [-82.801022, 42.629545],
                    [-82.766004, 42.600051],
                    [-82.755927, 42.564415],
                    [-82.859316, 42.541935],
                    [-82.8594470293087, 42.540853518224],
                    [-82.860210200131, 42.5345545050925],
                    [-82.8675319449814, 42.474122730693104],
                    [-82.8703322691976, 42.4510095842051],
                    [-82.870347, 42.450888],
                    [-82.92397, 42.352068],
                    [-82.9496720986456, 42.3442642212205],
                    [-82.988619, 42.332439],
                    [-83.096521, 42.290138],
                    [-83.0978584962561, 42.2860113652487],
                    [-83.1156828414025, 42.2310171461909],
                    [-83.133923, 42.17474],
                    [-83.133511, 42.088143],
                    [-83.185526, 42.052243],
                    [-83.1949523116895, 42.033107926811006],
                    [-83.284155, 42.089952],
                    [-83.539396, 42.085598000000005],
                    [-83.542102, 42.174344],
                    [-83.543728, 42.262462],
                    [-83.547548, 42.349217],
                    [-83.551907, 42.435166],
                    [-83.664808, 42.431179],
                    [-83.94465, 42.424149],
                    [-84.131136, 42.424567],
                    [-84.140713, 42.424611],
                    [-84.158189, 42.776639],
                ],
            ],
        },
        properties: {
            CSAFP: '220',
            CBSAFP: '19820',
            AFFGEOID: '310M600US19820',
            GEOID: '19820',
            NAME: 'Detroit-Warren-Dearborn, MI',
            NAMELSAD: 'Detroit-Warren-Dearborn, MI Metro Area',
            LSAD: 'M1',
            ALAND: 10080965984,
            AWATER: 889342680,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-72.98015, 43.128807, -71.361965, 44.4035682627764],
            type: 'Polygon',
            coordinates: [
                [
                    [-72.98015, 43.882924],
                    [-72.876556, 43.902765],
                    [-72.865283, 43.861656],
                    [-72.791043, 43.962244],
                    [-72.742241, 44.029637],
                    [-72.682874, 44.01281],
                    [-72.629096, 44.087836],
                    [-72.586558, 44.16026],
                    [-72.443376, 44.1288],
                    [-72.41919700000001, 44.220781],
                    [-72.36888, 44.203888],
                    [-72.305201, 44.183087],
                    [-72.174365, 44.192661],
                    [-72.053859587702, 44.1599265291283],
                    [-72.061338, 44.184951],
                    [-72.05399, 44.246926],
                    [-72.046302, 44.291983],
                    [-72.002314, 44.324871],
                    [-71.945163, 44.337744],
                    [-71.875863, 44.33737],
                    [-71.8378392792652, 44.3477512592393],
                    [-71.818838, 44.352939],
                    [-71.778613, 44.399799],
                    [-71.7631925213134, 44.4035682627764],
                    [-71.696885, 44.332118],
                    [-71.587004, 44.304759],
                    [-71.575819, 44.254596],
                    [-71.443338, 44.249172],
                    [-71.415898, 44.212169],
                    [-71.38163, 44.097874],
                    [-71.361965, 43.915938],
                    [-71.564603, 43.893172],
                    [-71.560621, 43.875045],
                    [-71.543805, 43.796043],
                    [-71.531658, 43.760979],
                    [-71.521466, 43.697992],
                    [-71.581403, 43.691949],
                    [-71.648346, 43.684511],
                    [-71.651153, 43.621119],
                    [-71.727573, 43.563411],
                    [-71.81757, 43.544459],
                    [-71.864599, 43.598063],
                    [-71.892112, 43.574618],
                    [-71.93531, 43.52888],
                    [-72.011363, 43.436871],
                    [-72.053752, 43.441097],
                    [-72.09014600000002, 43.322553],
                    [-72.009074, 43.187882],
                    [-72.035544, 43.180283],
                    [-72.038149, 43.128807],
                    [-72.178497, 43.131866],
                    [-72.156661, 43.181968],
                    [-72.372045, 43.132118],
                    [-72.431632, 43.135635],
                    [-72.4503959392159, 43.1612135169064],
                    [-72.440563, 43.215254],
                    [-72.4336262531276, 43.2328655889508],
                    [-72.68544, 43.22258],
                    [-72.819619, 43.256006],
                    [-72.819895, 43.299372],
                    [-72.868647, 43.300529],
                    [-72.852384, 43.376279],
                    [-72.762356, 43.37141400000001],
                    [-72.724223, 43.468174],
                    [-72.771371, 43.4788],
                    [-72.75794800000001, 43.587424],
                    [-72.699795, 43.684577],
                    [-72.819985, 43.716697],
                    [-72.803928, 43.740973000000004],
                    [-72.819262, 43.746313],
                    [-72.782856, 43.804007],
                    [-72.791174, 43.824136],
                    [-72.830665, 43.83912],
                    [-72.838341, 43.829254],
                    [-72.873871, 43.839628],
                    [-72.883951, 43.85442],
                    [-72.959744, 43.826077],
                    [-72.98015, 43.882924],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '30100',
            AFFGEOID: '310M600US30100',
            GEOID: '30100',
            NAME: 'Lebanon, NH-VT',
            NAMELSAD: 'Lebanon, NH-VT Micro Area',
            LSAD: 'M2',
            ALAND: 10107756528,
            AWATER: 174767407,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.62198, 28.824735512192103, -94.3541225449137, 30.630284],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.62198, 30.044283000000004],
                    [-96.292849, 30.096150000000005],
                    [-96.160472, 30.053409000000002],
                    [-96.146052, 30.070224],
                    [-96.191782, 30.13842],
                    [-96.15901000000001, 30.206613],
                    [-96.093165, 30.225187],
                    [-96.069319, 30.229964],
                    [-95.804306, 30.245570000000004],
                    [-95.83024, 30.630284],
                    [-95.598971, 30.509002000000002],
                    [-95.359156, 30.504369000000004],
                    [-95.308395, 30.405749999999998],
                    [-95.203813, 30.352094999999995],
                    [-95.226139, 30.319539999999996],
                    [-95.165897, 30.344976000000003],
                    [-94.85252000000001, 30.482907],
                    [-94.849414, 30.493552999999995],
                    [-94.777341, 30.489001999999996],
                    [-94.732732, 30.490066],
                    [-94.596338, 30.112100999999996],
                    [-94.44491, 30.113015000000004],
                    [-94.442235, 29.889538],
                    [-94.357976, 29.887464],
                    [-94.3541225449137, 29.562102820174797],
                    [-94.370816, 29.555645988295],
                    [-94.500807, 29.505366999999996],
                    [-94.594853, 29.467903000000003],
                    [-94.670389, 29.430780000000002],
                    [-94.731047, 29.369141],
                    [-94.72253, 29.331446],
                    [-94.803695, 29.279236999999995],
                    [-95.026219, 29.148064000000005],
                    [-95.1150505055983, 29.075552022478703],
                    [-95.125134, 29.067321000000003],
                    [-95.191391, 29.02309],
                    [-95.297147, 28.934072999999998],
                    [-95.38239, 28.866348],
                    [-95.439594, 28.859022],
                    [-95.507037396917, 28.824735512192103],
                    [-95.567708, 28.829758000000005],
                    [-95.67955, 28.965734],
                    [-95.76939300000001, 28.971109000000002],
                    [-95.770446, 29.066209999999998],
                    [-95.84631, 29.107799],
                    [-95.874028, 29.229702],
                    [-95.847656, 29.26259],
                    [-95.94681, 29.333433],
                    [-95.97343, 29.411445000000004],
                    [-96.059627, 29.473329],
                    [-96.044596, 29.562238],
                    [-96.08891200000001, 29.601658],
                    [-96.112582, 29.623617],
                    [-96.175422, 29.633806],
                    [-96.259226, 29.668912],
                    [-96.343247, 29.829697],
                    [-96.413283, 29.824985],
                    [-96.535705, 29.907326],
                    [-96.569844, 29.961516000000003],
                    [-96.62198, 30.044283000000004],
                ],
            ],
        },
        properties: {
            CSAFP: '288',
            CBSAFP: '26420',
            AFFGEOID: '310M600US26420',
            GEOID: '26420',
            NAME: 'Houston-The Woodlands-Sugar Land, TX',
            NAMELSAD: 'Houston-The Woodlands-Sugar Land, TX Metro Area',
            LSAD: 'M1',
            ALAND: 21416017452,
            AWATER: 3043403498,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-74.761435, 41.1428915543507, -73.487314, 42.078778],
            type: 'Polygon',
            coordinates: [
                [
                    [-74.761435, 41.490245],
                    [-74.475619, 41.503953],
                    [-74.367055, 41.590977],
                    [-74.264292, 41.632629],
                    [-74.053685, 41.58061000000001],
                    [-73.953307, 41.589977],
                    [-73.941081, 41.735993],
                    [-73.964413, 41.913148],
                    [-73.929626, 42.078778],
                    [-73.71093, 42.005488],
                    [-73.527072, 41.97798],
                    [-73.4896799420321, 42.0537977095867],
                    [-73.487314, 42.049638],
                    [-73.505008, 41.823773],
                    [-73.5179187906382, 41.66672053071],
                    [-73.520017, 41.641197],
                    [-73.5296777143013, 41.5271610211283],
                    [-73.580153, 41.526382],
                    [-73.673285, 41.514701],
                    [-73.933775, 41.488279],
                    [-73.981486, 41.438905],
                    [-73.947294, 41.394765],
                    [-73.980754, 41.32542],
                    [-74.2344924405545, 41.1428915543507],
                    [-74.301994, 41.172594],
                    [-74.3670382614474, 41.2042114724438],
                    [-74.457584, 41.248225],
                    [-74.694914, 41.357423],
                    [-74.734893, 41.425818],
                    [-74.7562718347252, 41.4276274285434],
                    [-74.761435, 41.490245],
                ],
            ],
        },
        properties: {
            CSAFP: '408',
            CBSAFP: '39100',
            AFFGEOID: '310M600US39100',
            GEOID: '39100',
            NAME: 'Poughkeepsie-Newburgh-Middletown, NY',
            NAMELSAD: 'Poughkeepsie-Newburgh-Middletown, NY Metro Area',
            LSAD: 'M1',
            ALAND: 4163866583,
            AWATER: 144865207,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-115.086155, 42.625254, -113.007318, 43.992949],
            type: 'Polygon',
            coordinates: [
                [
                    [-115.083071, 43.604399],
                    [-115.03383399999998, 43.618636],
                    [-115.04234000000001, 43.745582],
                    [-114.98925800000002, 43.858563],
                    [-114.996687, 43.914407],
                    [-114.971351, 43.938308],
                    [-114.873372, 43.992949],
                    [-114.813873, 43.992941],
                    [-114.81379000000001, 43.927772],
                    [-114.681168, 43.927705],
                    [-114.56235000000001, 43.844368],
                    [-114.507778, 43.89909],
                    [-114.35929300000001, 43.874177],
                    [-114.292484, 43.887062],
                    [-114.275725, 43.831138],
                    [-114.070019, 43.734696],
                    [-114.02971800000002, 43.773361],
                    [-113.97447, 43.708186],
                    [-113.813355, 43.600059],
                    [-113.798441, 43.569217],
                    [-113.701153, 43.584553],
                    [-113.634325, 43.497474],
                    [-113.63523, 43.367546],
                    [-113.36191, 43.36716],
                    [-113.361958, 43.284678],
                    [-113.007318, 43.284778],
                    [-113.007712, 43.110933],
                    [-113.24347800000001, 43.110946],
                    [-113.236943, 42.762212],
                    [-113.17887700000001, 42.762355],
                    [-113.178633, 42.67546],
                    [-113.236968, 42.625254],
                    [-113.3044, 42.628749],
                    [-113.373117, 42.687638],
                    [-113.47217700000002, 42.669352],
                    [-113.472155, 42.849201],
                    [-113.413138, 42.849169],
                    [-113.41386700000001, 43.199859],
                    [-113.714642, 43.199783],
                    [-114.374962, 43.19964],
                    [-114.59460500000002, 43.198345],
                    [-115.086155, 43.198064],
                    [-115.083071, 43.604399],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '25200',
            AFFGEOID: '310M600US25200',
            GEOID: '25200',
            NAME: 'Hailey, ID',
            NAMELSAD: 'Hailey, ID Micro Area',
            LSAD: 'M2',
            ALAND: 9613963534,
            AWATER: 56204670,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.61589, 30.9962018900015, -86.6882409642461, 31.261293000000002],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.61589, 31.244458000000005],
                    [-87.559287, 31.227291000000005],
                    [-87.427455, 31.260386],
                    [-86.763961, 31.261293000000002],
                    [-86.772519, 31.202243],
                    [-86.700282, 31.192217],
                    [-86.700251, 31.008901000000005],
                    [-86.6882409642461, 30.9962018900015],
                    [-86.7856919760059, 30.9969829935168],
                    [-86.831979, 30.997354],
                    [-86.927851, 30.997678000000004],
                    [-87.16264400000001, 30.999026000000004],
                    [-87.163645308459, 30.999021835748],
                    [-87.312206, 30.998403999999997],
                    [-87.425791, 30.998057999999997],
                    [-87.519533, 30.997552000000002],
                    [-87.5988289462621, 30.997422176905296],
                    [-87.61537, 31.000181],
                    [-87.61589, 31.244458000000005],
                ],
            ],
        },
        properties: {
            CSAFP: '426',
            CBSAFP: '12120',
            AFFGEOID: '310M600US12120',
            GEOID: '12120',
            NAME: 'Atmore, AL',
            NAMELSAD: 'Atmore, AL Micro Area',
            LSAD: 'M2',
            ALAND: 2448595161,
            AWATER: 20024887,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-118.232214, 41.9969940811635, -116.452068, 44.444573],
            type: 'Polygon',
            coordinates: [
                [
                    [-118.232214, 44.256129],
                    [-118.152456, 44.299509],
                    [-117.971787, 44.443849],
                    [-117.590849, 44.444573],
                    [-117.486744, 44.387246],
                    [-117.48701399999999, 44.300317],
                    [-117.211995506953, 44.2964510959383],
                    [-117.21697400000001, 44.288357],
                    [-117.17034200000002, 44.25889],
                    [-117.121037, 44.27758500000001],
                    [-117.059352, 44.237244],
                    [-116.97195800000001, 44.235677],
                    [-116.96549799999998, 44.194126],
                    [-116.90275199999999, 44.179467],
                    [-116.895931, 44.154295],
                    [-116.89785467711499, 44.1526663953914],
                    [-116.452068, 44.151465],
                    [-116.452551, 44.065414],
                    [-116.532117, 44.066024],
                    [-116.57222400000002, 43.980485],
                    [-116.712501, 43.981326],
                    [-116.712672, 43.807161],
                    [-116.85315400000002, 43.79271],
                    [-116.95452000000002, 43.836487],
                    [-116.98554503849, 43.8811848528138],
                    [-117.02357699999999, 43.823811],
                    [-117.02566381044602, 43.680293635954],
                    [-117.02688900000001, 43.596033],
                    [-117.02665199999998, 43.025128],
                    [-117.026253, 42.807447],
                    [-117.026551, 42.378557],
                    [-117.026197, 41.99989],
                    [-117.197798, 42.00038],
                    [-117.403613, 41.99929],
                    [-117.623731, 41.998467],
                    [-117.87346700000002, 41.998335],
                    [-118.19718900000001, 41.996995],
                    [-118.19736921592802, 41.9969940811635],
                    [-118.195311, 42.267119],
                    [-118.214698, 42.275891],
                    [-118.216832, 42.914703],
                    [-118.2321, 43.770829],
                    [-118.227645, 44.039862],
                    [-118.228843, 44.21255],
                    [-118.232214, 44.256129],
                ],
            ],
        },
        properties: {
            CSAFP: '147',
            CBSAFP: '36620',
            AFFGEOID: '310M600US36620',
            GEOID: '36620',
            NAME: 'Ontario, OR-ID',
            NAMELSAD: 'Ontario, OR-ID Micro Area',
            LSAD: 'M2',
            ALAND: 26662900545,
            AWATER: 117869479,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.571534, 37.510416, -80.748757, 38.263514],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.571534, 37.927707],
                    [-81.456632, 37.987307],
                    [-81.438531, 37.968008],
                    [-81.380829, 37.969109],
                    [-81.329265, 38.182481],
                    [-81.231636, 38.263514],
                    [-81.070465, 38.210103],
                    [-80.96942, 38.215685],
                    [-80.882187, 38.103457],
                    [-80.769866, 37.98527],
                    [-80.810866, 37.949141],
                    [-80.748757, 37.865097],
                    [-80.806316, 37.868915],
                    [-80.94509, 37.819121],
                    [-80.87772900000002, 37.685603],
                    [-80.947017, 37.715449],
                    [-81.068774, 37.639823],
                    [-81.09463, 37.588658],
                    [-81.198869, 37.575533],
                    [-81.222121, 37.510416],
                    [-81.315123, 37.588414],
                    [-81.458027, 37.784211],
                    [-81.514228, 37.791211],
                    [-81.571534, 37.927707],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '13220',
            AFFGEOID: '310M600US13220',
            GEOID: '13220',
            NAME: 'Beckley, WV',
            NAMELSAD: 'Beckley, WV Metro Area',
            LSAD: 'M1',
            ALAND: 3281441361,
            AWATER: 27772680,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.7511059451205, 38.4188492084548, -87.098771, 38.907874],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.74104, 38.435576000000005],
                    [-87.714047, 38.47988],
                    [-87.654166, 38.511911],
                    [-87.660732, 38.541092],
                    [-87.6483567630745, 38.5666287160576],
                    [-87.637752, 38.588512],
                    [-87.62012, 38.639489],
                    [-87.545538, 38.677613],
                    [-87.498948, 38.757774],
                    [-87.521681, 38.826576],
                    [-87.5352570508085, 38.85249146496741],
                    [-87.54737, 38.875614],
                    [-87.5287176636979, 38.9059437862892],
                    [-87.26025700000001, 38.901623],
                    [-87.241027, 38.907874],
                    [-87.129882, 38.907315],
                    [-87.098771, 38.903974],
                    [-87.213267, 38.807977],
                    [-87.241463, 38.817476],
                    [-87.238551, 38.743235],
                    [-87.278553, 38.691343],
                    [-87.242097, 38.54493],
                    [-87.288884, 38.51156],
                    [-87.351833, 38.542247],
                    [-87.462884, 38.532727],
                    [-87.494169, 38.494622],
                    [-87.7511059451205, 38.4188492084548],
                    [-87.74104, 38.435576000000005],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '47180',
            AFFGEOID: '310M600US47180',
            GEOID: '47180',
            NAME: 'Vincennes, IN',
            NAMELSAD: 'Vincennes, IN Micro Area',
            LSAD: 'M2',
            ALAND: 1335984776,
            AWATER: 20892977,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.151036, 33.858221, -86.45302, 34.313799],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.111992, 33.992385],
                    [-87.109911, 34.299299],
                    [-87.110111, 34.313799],
                    [-86.581936, 34.304694],
                    [-86.477525, 34.302758],
                    [-86.45302, 34.259317],
                    [-86.518927, 34.252321],
                    [-86.56385, 34.170493],
                    [-86.573969, 34.165436],
                    [-86.587614, 34.13833100000001],
                    [-86.604311, 34.125112],
                    [-86.601147, 34.11904],
                    [-86.692474, 34.092405],
                    [-86.685365, 34.05914],
                    [-86.793914, 33.952059],
                    [-86.924387, 33.909222],
                    [-86.963358, 33.858221],
                    [-87.091836, 33.890093],
                    [-87.151036, 33.993225],
                    [-87.111992, 33.992385],
                ],
            ],
        },
        properties: {
            CSAFP: '142',
            CBSAFP: '18980',
            AFFGEOID: '310M600US18980',
            GEOID: '18980',
            NAME: 'Cullman, AL',
            NAMELSAD: 'Cullman, AL Micro Area',
            LSAD: 'M2',
            ALAND: 1902965973,
            AWATER: 52529944,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.09654100000002, 46.157045, -91.020737, 48.631493],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.088548716516, 48.6268109196626],
                    [-92.954876, 48.631493],
                    [-92.894687, 48.594915],
                    [-92.728046, 48.53929],
                    [-92.634931, 48.542873],
                    [-92.631117, 48.508252],
                    [-92.657101, 48.466915],
                    [-92.575636, 48.440827000000006],
                    [-92.51491, 48.448313],
                    [-92.456325, 48.414204],
                    [-92.469948, 48.351836],
                    [-92.416285, 48.295463],
                    [-92.36917400000002, 48.220268],
                    [-92.314665, 48.240527],
                    [-92.295668, 48.278118],
                    [-92.295412, 48.323957],
                    [-92.26228, 48.354933],
                    [-92.162161, 48.363279],
                    [-92.055228, 48.359213],
                    [-92.000133, 48.321355],
                    [-91.980772, 48.247801],
                    [-91.89347, 48.237699],
                    [-91.7973140618676, 48.2057860320392],
                    [-91.781182, 48.200432],
                    [-91.71493100000002, 48.19913],
                    [-91.692366, 48.11933],
                    [-91.559272, 48.10826800000001],
                    [-91.542512, 48.053268],
                    [-91.465499, 48.06677],
                    [-91.336578, 48.069627000000004],
                    [-91.250112, 48.084087],
                    [-91.156107, 48.140475],
                    [-91.03555, 48.18945900000001],
                    [-91.0326551809553, 48.1905357779605],
                    [-91.020737, 47.887995],
                    [-91.023124, 47.464964],
                    [-91.146958, 47.381464],
                    [-91.262512, 47.27929],
                    [-91.387021, 47.187293],
                    [-91.456965, 47.139156],
                    [-91.573817, 47.089917],
                    [-91.644564, 47.026491],
                    [-91.737098, 46.982853],
                    [-91.79436010587841, 46.9425241508697],
                    [-91.806851, 46.933727],
                    [-91.906483, 46.891236],
                    [-92.013405, 46.833727],
                    [-92.06208800000002, 46.804038000000006],
                    [-92.01529, 46.706469],
                    [-91.961889, 46.682539],
                    [-91.886963, 46.690211],
                    [-91.820027, 46.690176],
                    [-91.645502, 46.734733],
                    [-91.574291, 46.757488],
                    [-91.5513415356564, 46.7574752934595],
                    [-91.551936, 46.748111],
                    [-91.551282, 46.157045],
                    [-92.049636, 46.157597],
                    [-92.2938306085787, 46.15732130649601],
                    [-92.293619, 46.244043],
                    [-92.2927598024283, 46.41721950288],
                    [-93.054847, 46.419269],
                    [-93.055844, 46.579055000000004],
                    [-93.062678, 46.59331],
                    [-93.064867, 46.677801],
                    [-93.061332, 46.76655],
                    [-93.056761, 47.026406],
                    [-93.06963600000002, 47.400174],
                    [-93.061497, 47.720406],
                    [-93.08090600000001, 47.891864],
                    [-93.09654100000002, 48.067803],
                    [-93.088548716516, 48.6268109196626],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '20260',
            AFFGEOID: '310M600US20260',
            GEOID: '20260',
            NAME: 'Duluth, MN-WI',
            NAMELSAD: 'Duluth, MN-WI Metro Area',
            LSAD: 'M1',
            ALAND: 27253589829,
            AWATER: 4360591273,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.269359, 35.761939, -84.680633, 36.170433],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.269359, 35.793388],
                    [-85.248435, 35.798748],
                    [-85.242698, 35.91304],
                    [-85.264206, 35.979154],
                    [-85.246362, 36.100698],
                    [-85.15275000000001, 36.099544],
                    [-85.100213, 36.138561],
                    [-85.029461, 36.170433],
                    [-84.907753, 36.156293],
                    [-84.80546000000001, 36.090501],
                    [-84.720727, 35.994914],
                    [-84.680633, 35.908454],
                    [-84.729634, 35.858382],
                    [-84.781901, 35.825],
                    [-84.80163, 35.823066],
                    [-84.916062, 35.761939],
                    [-84.928536, 35.768549],
                    [-85.254062, 35.765611],
                    [-85.269359, 35.793388],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '18900',
            AFFGEOID: '310M600US18900',
            GEOID: '18900',
            NAME: 'Crossville, TN',
            NAMELSAD: 'Crossville, TN Micro Area',
            LSAD: 'M2',
            ALAND: 1764575266,
            AWATER: 9098322,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.563589, 41.9936893594868, -75.35993107687021, 42.415702],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.563589, 42.152463],
                    [-76.538349, 42.281755],
                    [-76.41619900000002, 42.262976],
                    [-76.415305, 42.318368],
                    [-76.250149, 42.296676],
                    [-76.253359, 42.407568],
                    [-76.13018100000001, 42.410337],
                    [-76.021374, 42.409008],
                    [-75.86402, 42.415702],
                    [-75.836502, 42.244283],
                    [-75.638299, 42.248686],
                    [-75.63711, 42.195628],
                    [-75.483638, 42.195183],
                    [-75.418421, 42.195032],
                    [-75.402239, 42.010699],
                    [-75.35993107687021, 41.9936893594868],
                    [-75.477144, 41.999407],
                    [-75.4831402165408, 41.9993981778443],
                    [-75.5531194388128, 41.999295218322],
                    [-75.870677, 41.998828],
                    [-76.10584, 41.998858],
                    [-76.1455189947821, 41.998866463308],
                    [-76.462155, 41.998934],
                    [-76.473030885808, 41.999072381007004],
                    [-76.5576987221493, 42.0001496652537],
                    [-76.563589, 42.152463],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '13780',
            AFFGEOID: '310M600US13780',
            GEOID: '13780',
            NAME: 'Binghamton, NY',
            NAMELSAD: 'Binghamton, NY Metro Area',
            LSAD: 'M1',
            ALAND: 3171194835,
            AWATER: 36154556,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-102.090415, 33.824675, -101.56358, 34.313132],
            type: 'Polygon',
            coordinates: [
                [
                    [-102.090415, 34.313132],
                    [-101.99802, 34.313039],
                    [-101.564856, 34.31249300000001],
                    [-101.564563, 34.074228],
                    [-101.56358, 33.830451],
                    [-102.085733, 33.824675],
                    [-102.090415, 34.313132],
                ],
            ],
        },
        properties: {
            CSAFP: '352',
            CBSAFP: '38380',
            AFFGEOID: '310M600US38380',
            GEOID: '38380',
            NAME: 'Plainview, TX',
            NAMELSAD: 'Plainview, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2602109423,
            AWATER: 246678,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.3398959708503, 39.53017, -94.203595, 40.130096],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.3398959708503, 40.0000288571054],
                    [-95.30829000000001, 39.999998],
                    [-95.231114, 39.943784],
                    [-95.142445, 39.89542],
                    [-95.081534, 39.86171800000001],
                    [-95.018743, 39.897372],
                    [-94.9933742397144, 39.8985652599923],
                    [-95.010542, 40.046761000000004],
                    [-95.06071, 40.069866],
                    [-95.04308, 40.130096],
                    [-94.605942, 40.126127],
                    [-94.605377, 40.039217],
                    [-94.2188, 40.03488500000001],
                    [-94.203595, 40.025048],
                    [-94.205844, 39.788954],
                    [-94.205961, 39.745841],
                    [-94.602257, 39.747195],
                    [-94.601215, 39.53017],
                    [-95.091419402824, 39.53325788521],
                    [-95.113557, 39.553941],
                    [-95.076688, 39.576764],
                    [-95.047165, 39.595117],
                    [-95.04405075257041, 39.6136682968209],
                    [-95.11518900000002, 39.652549],
                    [-95.33974000000002, 39.65298],
                    [-95.3398959708503, 40.0000288571054],
                ],
            ],
        },
        properties: {
            CSAFP: '312',
            CBSAFP: '41140',
            AFFGEOID: '310M600US41140',
            GEOID: '41140',
            NAME: 'St. Joseph, MO-KS',
            NAMELSAD: 'St. Joseph, MO-KS Metro Area',
            LSAD: 'M1',
            ALAND: 4288289854,
            AWATER: 51605107,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-110.053179, 42.261356, -107.501425, 44.008022],
            type: 'Polygon',
            coordinates: [
                [
                    [-110.053179, 44.008022],
                    [-109.990073, 43.949066],
                    [-109.858754, 43.957988],
                    [-109.81657500000001, 43.880534],
                    [-109.843455, 43.81986400000001],
                    [-109.7899, 43.803252],
                    [-109.66589300000001, 43.845726],
                    [-109.641808, 43.922785],
                    [-109.542332, 43.961352],
                    [-109.442361, 43.930434],
                    [-109.312949, 43.813293],
                    [-109.171448, 43.699198],
                    [-109.171638, 43.619764],
                    [-108.934141, 43.619163],
                    [-108.814276, 43.605255],
                    [-108.814276, 43.575555],
                    [-108.694672, 43.575755],
                    [-108.695072, 43.532255],
                    [-108.576167, 43.532556],
                    [-108.576167, 43.503256],
                    [-108.456962, 43.503156],
                    [-108.456973, 43.474279],
                    [-108.337861, 43.459765],
                    [-108.156285, 43.470628],
                    [-107.595137, 43.472279],
                    [-107.595105, 43.501397],
                    [-107.534897, 43.501362],
                    [-107.517031, 43.472659],
                    [-107.502327, 43.12806],
                    [-107.501425, 42.781458],
                    [-107.543526, 42.781558000000004],
                    [-107.542058, 42.44184],
                    [-107.522923, 42.43435600000001],
                    [-107.522722, 42.261756],
                    [-107.639128, 42.261356],
                    [-108.756031, 42.262875],
                    [-109.043864, 42.263684],
                    [-109.043801, 42.433644],
                    [-109.072851, 42.693514],
                    [-109.134466, 42.694289],
                    [-109.132315, 42.737485],
                    [-109.323552, 42.863868],
                    [-109.397757, 42.939198],
                    [-109.502407, 43.010511],
                    [-109.552579, 43.012538],
                    [-109.560451, 43.083538],
                    [-109.651451, 43.167036],
                    [-109.692716, 43.26503],
                    [-109.694942, 43.366152],
                    [-109.754462, 43.365905],
                    [-109.754717, 43.4632],
                    [-110.05157, 43.464783],
                    [-110.053179, 44.008022],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '40180',
            AFFGEOID: '310M600US40180',
            GEOID: '40180',
            NAME: 'Riverton, WY',
            NAMELSAD: 'Riverton, WY Micro Area',
            LSAD: 'M2',
            ALAND: 23785388835,
            AWATER: 212768588,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-77.307312, 38.7127503012383, -75.7481548142541, 39.7216462556279],
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [-76.361727, 38.939175],
                        [-76.322296, 39.006375],
                        [-76.301847, 39.039651],
                        [-76.2650366404087, 39.0285509553488],
                        [-76.210991, 38.99258],
                        [-76.156944, 39.094982],
                        [-76.04763, 39.162666],
                        [-76.01029200000002, 39.239517],
                        [-75.866315, 39.260859],
                        [-75.7564352155685, 39.2466875371253],
                        [-75.7481548142541, 39.1431317309597],
                        [-75.834948, 39.108437],
                        [-75.94093300000002, 38.976295],
                        [-75.94933500000002, 38.918333],
                        [-76.09733300000002, 38.944102],
                        [-76.103512, 38.882308],
                        [-76.196868759948, 38.8557419634208],
                        [-76.205063, 38.892726],
                        [-76.203638, 38.928382],
                        [-76.250868, 38.92825],
                        [-76.317947, 38.911312],
                        [-76.334019, 38.860238],
                        [-76.376202, 38.850461],
                        [-76.361727, 38.939175],
                    ],
                ],
                [
                    [
                        [-77.2170237552374, 39.7202172483854],
                        [-76.9993180263332, 39.7200676723526],
                        [-76.991062, 39.720062],
                        [-76.7870971997576, 39.7210481461113],
                        [-76.715771, 39.721393],
                        [-76.5694491257313, 39.72145857075271],
                        [-76.41898, 39.721526000000004],
                        [-76.2396837411146, 39.7216419899246],
                        [-76.233485, 39.721646],
                        [-76.2332793471166, 39.7216462556279],
                        [-76.212676, 39.686405],
                        [-76.094472, 39.56781],
                        [-76.041976, 39.494228],
                        [-76.0376462883158, 39.4526423823093],
                        [-76.060931, 39.452208],
                        [-76.146373, 39.40531],
                        [-76.224161, 39.35278],
                        [-76.296609, 39.301137],
                        [-76.3254183419569, 39.2729050860827],
                        [-76.349994, 39.248822],
                        [-76.395509, 39.231702000000006],
                        [-76.425281, 39.205708],
                        [-76.463483, 39.205908],
                        [-76.498384, 39.204808],
                        [-76.5040085909181, 39.199286249126],
                        [-76.525785, 39.177908],
                        [-76.42868100000001, 39.131709],
                        [-76.42186, 39.081442],
                        [-76.420394, 39.04207],
                        [-76.39408, 39.011311000000006],
                        [-76.448981, 38.982811],
                        [-76.471281, 38.956512],
                        [-76.45028, 38.941113],
                        [-76.46938, 38.907613],
                        [-76.4739761636555, 38.9026933786299],
                        [-76.49068, 38.884814],
                        [-76.516944, 38.851157],
                        [-76.489878, 38.838715],
                        [-76.526979, 38.787016],
                        [-76.558743, 38.756352],
                        [-76.526655, 38.72443],
                        [-76.5270925473942, 38.7127503012383],
                        [-76.615902, 38.720804],
                        [-76.642749, 38.769281],
                        [-76.686358, 38.748475],
                        [-76.710639, 38.815694],
                        [-76.669969, 38.90652],
                        [-76.751258, 39.034714],
                        [-76.790741, 39.045722],
                        [-76.831424, 39.069034],
                        [-76.840362, 39.103142],
                        [-76.888505, 39.130967],
                        [-76.958517, 39.134023],
                        [-76.973666, 39.162209],
                        [-77.070112, 39.254104],
                        [-77.134466, 39.276834],
                        [-77.18693700000001, 39.339265],
                        [-77.168801, 39.353502],
                        [-77.16808, 39.353957],
                        [-77.107711, 39.494556],
                        [-77.198024, 39.57543],
                        [-77.307312, 39.61887],
                        [-77.2170237552374, 39.7202172483854],
                    ],
                ],
            ],
        },
        properties: {
            CSAFP: '548',
            CBSAFP: '12580',
            AFFGEOID: '310M600US12580',
            GEOID: '12580',
            NAME: 'Baltimore-Columbia-Towson, MD',
            NAMELSAD: 'Baltimore-Columbia-Towson, MD Metro Area',
            LSAD: 'M1',
            ALAND: 6737881408,
            AWATER: 1304104452,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-123.540922011634, 38.1090944743082, -122.349564, 38.852438],
            type: 'Polygon',
            coordinates: [
                [
                    [-123.540922011634, 38.7676562160393],
                    [-123.36823600000001, 38.777066],
                    [-123.136253, 38.809136],
                    [-123.080997, 38.852438],
                    [-122.82159200000001, 38.85014],
                    [-122.77248, 38.82153],
                    [-122.62739600000002, 38.667506],
                    [-122.631832, 38.569367],
                    [-122.60265900000002, 38.557496],
                    [-122.54389300000001, 38.519966],
                    [-122.349564, 38.193972],
                    [-122.40678600000001, 38.155632],
                    [-122.397581230624, 38.1420035972533],
                    [-122.488499639259, 38.1090944743082],
                    [-122.50570700000002, 38.115526],
                    [-122.56509299999999, 38.182217],
                    [-122.7399, 38.207018],
                    [-122.911319, 38.321209],
                    [-123.003146477056, 38.2957052387145],
                    [-123.004122, 38.297012],
                    [-123.05350400000002, 38.299385],
                    [-123.068437, 38.33521],
                    [-123.085572, 38.390525],
                    [-123.166428, 38.474947],
                    [-123.249797, 38.511045],
                    [-123.33189900000002, 38.565542],
                    [-123.34961200000001, 38.596805],
                    [-123.441774, 38.699744],
                    [-123.514784, 38.741966],
                    [-123.540922011634, 38.7676562160393],
                ],
            ],
        },
        properties: {
            CSAFP: '488',
            CBSAFP: '42220',
            AFFGEOID: '310M600US42220',
            GEOID: '42220',
            NAME: 'Santa Rosa-Petaluma, CA',
            NAMELSAD: 'Santa Rosa-Petaluma, CA Metro Area',
            LSAD: 'M1',
            ALAND: 4080864785,
            AWATER: 498081645,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-95.187103, 38.026017, -93.477233, 39.788954],
            type: 'Polygon',
            coordinates: [
                [
                    [-95.187103, 39.044109],
                    [-95.180125, 39.128889],
                    [-95.180891, 39.419218],
                    [-94.997852, 39.418858],
                    [-94.9657474186335, 39.421681744656404],
                    [-94.982144, 39.440552],
                    [-95.049845, 39.494415],
                    [-95.091419402824, 39.53325788521],
                    [-94.601215, 39.53017],
                    [-94.602257, 39.747195],
                    [-94.205961, 39.745841],
                    [-94.205844, 39.788954],
                    [-93.758992, 39.784674],
                    [-93.758357, 39.611407],
                    [-93.759183, 39.524558],
                    [-93.758463, 39.207021],
                    [-93.650526, 39.248214],
                    [-93.49196000000002, 39.223461],
                    [-93.477233, 39.292796],
                    [-93.496377, 38.942837],
                    [-93.497278, 38.92843],
                    [-93.834719, 38.937909],
                    [-94.114175, 38.917304],
                    [-94.119096, 38.833167],
                    [-94.119664, 38.568712000000005],
                    [-94.06431700000002, 38.56738],
                    [-94.06782, 38.466016],
                    [-94.065713, 38.447087],
                    [-94.075182, 38.21544],
                    [-94.049895, 38.213985],
                    [-94.058285, 38.036627],
                    [-94.135238, 38.058118],
                    [-94.237787, 38.026017],
                    [-94.6139299610557, 38.0600529598965],
                    [-94.6141002487054, 38.0370565833813],
                    [-95.077876, 38.037706],
                    [-95.065831, 38.389945],
                    [-95.065772, 38.694903],
                    [-95.056412, 38.738587],
                    [-95.056258, 38.98212],
                    [-95.186189, 38.964542],
                    [-95.187103, 39.044109],
                ],
            ],
        },
        properties: {
            CSAFP: '312',
            CBSAFP: '28140',
            AFFGEOID: '310M600US28140',
            GEOID: '28140',
            NAME: 'Kansas City, MO-KS',
            NAMELSAD: 'Kansas City, MO-KS Metro Area',
            LSAD: 'M1',
            ALAND: 18794256913,
            AWATER: 301313287,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.979898, 39.007994, -78.0331834609342, 39.5443822410837],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.850906, 39.330866],
                    [-78.767447, 39.447238],
                    [-78.6550361715467, 39.5443822410837],
                    [-78.590654, 39.530192],
                    [-78.4682695950029, 39.5262242704717],
                    [-78.450004, 39.50674],
                    [-78.347087, 39.466012],
                    [-78.2291295342185, 39.3906638872041],
                    [-78.18737, 39.363989],
                    [-78.0331852353666, 39.2646248420052],
                    [-78.0331834609342, 39.2646236984747],
                    [-78.10979400000001, 39.095822],
                    [-78.151614, 39.036629],
                    [-78.163871, 39.017928],
                    [-78.31408, 39.007994],
                    [-78.336003, 39.10177100000001],
                    [-78.391624, 39.101897],
                    [-78.454136, 39.027797],
                    [-78.532266186557, 39.0527645240444],
                    [-78.508132, 39.08863],
                    [-78.561282, 39.131444],
                    [-78.623555, 39.118509],
                    [-78.979898, 39.237624],
                    [-78.850906, 39.330866],
                ],
            ],
        },
        properties: {
            CSAFP: '548',
            CBSAFP: '49020',
            AFFGEOID: '310M600US49020',
            GEOID: '49020',
            NAME: 'Winchester, VA-WV',
            NAMELSAD: 'Winchester, VA-WV Metro Area',
            LSAD: 'M1',
            ALAND: 2752456889,
            AWATER: 16817754,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.2842383817656, 32.0346, -80.434303, 32.753058],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.2842383817656, 32.547110946285],
                    [-81.014625, 32.753058],
                    [-80.902448, 32.621561],
                    [-80.869705, 32.660935],
                    [-80.826309, 32.704321],
                    [-80.67115, 32.671642],
                    [-80.659751, 32.612683],
                    [-80.551504, 32.565769],
                    [-80.4691107737594, 32.4891261935517],
                    [-80.4713620677876, 32.4850391667245],
                    [-80.4722931246951, 32.4833489145801],
                    [-80.484617, 32.460976],
                    [-80.457502, 32.410264],
                    [-80.434303, 32.375193],
                    [-80.45519200000001, 32.326458],
                    [-80.539429, 32.287024],
                    [-80.596394, 32.273549],
                    [-80.644794, 32.291497],
                    [-80.714601, 32.325656],
                    [-80.766038, 32.292608],
                    [-80.726967, 32.265706],
                    [-80.669166, 32.216783],
                    [-80.721463, 32.160427],
                    [-80.812503, 32.109746],
                    [-80.858735, 32.09958100000001],
                    [-80.8674276818916, 32.07849],
                    [-80.885517, 32.0346],
                    [-80.943226, 32.057824],
                    [-81.006745, 32.101152],
                    [-81.038265, 32.084469],
                    [-81.113334, 32.113205],
                    [-81.119361, 32.177142],
                    [-81.1475951704554, 32.2271694465971],
                    [-81.15353100000002, 32.237687],
                    [-81.128034, 32.276297],
                    [-81.133032, 32.334794],
                    [-81.1734737944861, 32.3849027808867],
                    [-81.194931, 32.411489],
                    [-81.194829, 32.465086],
                    [-81.274927, 32.544158],
                    [-81.2842383817656, 32.547110946285],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '25940',
            AFFGEOID: '310M600US25940',
            GEOID: '25940',
            NAME: 'Hilton Head Island-Bluffton, SC',
            NAMELSAD: 'Hilton Head Island-Bluffton, SC Metro Area',
            LSAD: 'M1',
            ALAND: 3188805665,
            AWATER: 1020083416,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.946043, 41.162405, -91.366221, 41.861851],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.946043, 41.510749],
                    [-91.82923100000001, 41.511457],
                    [-91.831379, 41.861851],
                    [-91.366221, 41.860069],
                    [-91.366448, 41.598373],
                    [-91.368521, 41.423178],
                    [-91.483995, 41.423848],
                    [-91.485717, 41.162405],
                    [-91.716042, 41.162808],
                    [-91.945571, 41.163578],
                    [-91.946043, 41.510749],
                ],
            ],
        },
        properties: {
            CSAFP: '168',
            CBSAFP: '26980',
            AFFGEOID: '310M600US26980',
            GEOID: '26980',
            NAME: 'Iowa City, IA',
            NAMELSAD: 'Iowa City, IA Metro Area',
            LSAD: 'M1',
            ALAND: 3060914331,
            AWATER: 31409881,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.429825, 42.5000259669515, -89.006117, 43.643083],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.429825, 43.20094],
                    [-90.350513, 43.209786],
                    [-90.31113, 43.206677],
                    [-90.260077, 43.197689000000004],
                    [-90.193814, 43.164464],
                    [-90.05866, 43.145291],
                    [-90.000123, 43.194624],
                    [-89.83813500000001, 43.206057],
                    [-89.731738, 43.252203],
                    [-89.72046300000001, 43.29308400000001],
                    [-89.677613, 43.361197],
                    [-89.60073, 43.380666],
                    [-89.599357, 43.558041],
                    [-89.732238, 43.571826],
                    [-89.785809, 43.641049],
                    [-89.784901, 43.641051],
                    [-89.599547, 43.642636],
                    [-89.245437, 43.643083],
                    [-89.12717, 43.631724],
                    [-89.006848, 43.633045],
                    [-89.006117, 43.458759],
                    [-89.009139, 43.28483],
                    [-89.008832, 43.197724],
                    [-89.013582, 42.84763],
                    [-89.369128, 42.845036],
                    [-89.3657986076635, 42.5000259669515],
                    [-89.4014168110406, 42.5004419310737],
                    [-89.493216, 42.501514],
                    [-89.8375948863256, 42.5049103527915],
                    [-89.83841, 42.813799],
                    [-90.426902, 42.812862],
                    [-90.429825, 43.20094],
                ],
            ],
        },
        properties: {
            CSAFP: '357',
            CBSAFP: '31540',
            AFFGEOID: '310M600US31540',
            GEOID: '31540',
            NAME: 'Madison, WI',
            NAMELSAD: 'Madison, WI Metro Area',
            LSAD: 'M1',
            ALAND: 8569702439,
            AWATER: 200235647,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.013851, 31.033875000000002, -83.509961, 31.334966],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.013851, 31.102436],
                    [-84.002968, 31.113173],
                    [-83.99943, 31.334966],
                    [-83.759086, 31.332225000000005],
                    [-83.714134, 31.318365000000004],
                    [-83.65409, 31.330660999999996],
                    [-83.512607, 31.327405],
                    [-83.509961, 31.205972],
                    [-83.574857, 31.078135],
                    [-83.573959, 31.033875000000002],
                    [-83.736158, 31.037678999999997],
                    [-84.003876, 31.041582],
                    [-84.003627, 31.077290000000005],
                    [-84.013851, 31.102436],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '34220',
            AFFGEOID: '310M600US34220',
            GEOID: '34220',
            NAME: 'Moultrie, GA',
            NAMELSAD: 'Moultrie, GA Micro Area',
            LSAD: 'M2',
            ALAND: 1416812867,
            AWATER: 24875649,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-107.439386, 39.918671, -106.626555, 41.0028424812322],
            type: 'Polygon',
            coordinates: [
                [
                    [-107.428813, 40.542207],
                    [-107.314153, 40.600281],
                    [-107.31779575979, 41.0028424812322],
                    [-106.860377948665, 41.0007186292371],
                    [-106.852349, 40.925183],
                    [-106.82503, 40.932119],
                    [-106.73934100000001, 40.870843],
                    [-106.691669, 40.888192],
                    [-106.654541, 40.849117],
                    [-106.636922, 40.789489],
                    [-106.706149, 40.616597],
                    [-106.653113, 40.494732000000006],
                    [-106.652112, 40.445231],
                    [-106.652857, 40.346187],
                    [-106.632257, 40.341559],
                    [-106.637031, 40.002106],
                    [-106.626569, 39.924785],
                    [-106.626555, 39.918671],
                    [-106.656536, 39.919961],
                    [-107.033968, 39.918913],
                    [-107.03205, 40.002939],
                    [-107.037363, 40.091538],
                    [-107.038097, 40.225357],
                    [-107.439386, 40.223379],
                    [-107.428813, 40.542207],
                ],
            ],
        },
        properties: {
            CSAFP: '525',
            CBSAFP: '44460',
            AFFGEOID: '310M600US44460',
            GEOID: '44460',
            NAME: 'Steamboat Springs, CO',
            NAMELSAD: 'Steamboat Springs, CO Micro Area',
            LSAD: 'M2',
            ALAND: 6117602801,
            AWATER: 15831744,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-118.400807, 47.259272, -117.04015975186302, 49.000843],
            type: 'Polygon',
            coordinates: [
                [
                    [-118.400807, 48.028682],
                    [-118.275476, 48.040567],
                    [-118.233198, 48.065285],
                    [-118.24860600000001, 48.134255],
                    [-118.19129799999999, 48.159888],
                    [-118.1945, 48.212889],
                    [-118.141477, 48.260155],
                    [-118.175602, 48.319492],
                    [-118.206507, 48.470296],
                    [-118.149709, 48.520568],
                    [-118.10496, 48.65350300000001],
                    [-118.131002, 48.777653],
                    [-118.195508, 48.840725],
                    [-118.221669, 48.937098],
                    [-118.19737505245901, 49.0004067514245],
                    [-118.002046, 49.000437],
                    [-117.607323, 49.000843],
                    [-117.429968, 49.0003068698256],
                    [-117.430377, 48.923168],
                    [-117.49895700000002, 48.923159],
                    [-117.498496, 48.8369],
                    [-117.56385800000001, 48.836671],
                    [-117.563811, 48.742961],
                    [-117.62942099999998, 48.742634],
                    [-117.629241, 48.655852],
                    [-117.49997499999999, 48.65618700000001],
                    [-117.50237100000001, 48.134708],
                    [-117.43762900000002, 48.134688],
                    [-117.43699300000002, 48.047207],
                    [-117.04121445346101, 48.0454651038028],
                    [-117.04130644645902, 47.9774559200268],
                    [-117.041634, 47.7353],
                    [-117.04049037556798, 47.366028886698],
                    [-117.04015975186302, 47.259272],
                    [-117.823629, 47.26022],
                    [-117.820947, 47.825775],
                    [-117.881473, 47.84749],
                    [-118.078182, 47.800062000000004],
                    [-118.212323, 47.945493],
                    [-118.246103, 47.943604],
                    [-118.34093799999998, 47.894316],
                    [-118.34873999999999, 47.971782],
                    [-118.400807, 48.028682],
                ],
            ],
        },
        properties: {
            CSAFP: '518',
            CBSAFP: '44060',
            AFFGEOID: '310M600US44060',
            GEOID: '44060',
            NAME: 'Spokane-Spokane Valley, WA',
            NAMELSAD: 'Spokane-Spokane Valley, WA Metro Area',
            LSAD: 'M1',
            ALAND: 10984976321,
            AWATER: 206349948,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-117.01841900000001, 39.161463, -114.03990100000001, 41.999909],
            type: 'Polygon',
            coordinates: [
                [
                    [-117.018203216741, 41.9998398503691],
                    [-116.625947, 41.997379],
                    [-116.332763, 41.997283],
                    [-115.870181, 41.996766],
                    [-115.62591399999998, 41.997415],
                    [-115.31387700000002, 41.996103],
                    [-115.038109752434, 41.9986341156765],
                    [-114.89921000000001, 41.999909],
                    [-114.598267, 41.994511],
                    [-114.281855, 41.994214],
                    [-114.28179946722, 41.9942138857579],
                    [-114.04172300000002, 41.99372],
                    [-114.03990100000001, 41.753781],
                    [-114.040231, 41.49169],
                    [-114.041447, 41.207752],
                    [-114.042145, 40.999926],
                    [-114.042145146235, 40.9998965772633],
                    [-114.043505, 40.726292],
                    [-114.045577, 40.495801],
                    [-114.046178, 40.398313],
                    [-114.046372817373, 40.1169358330122],
                    [-114.251633, 40.11669],
                    [-115.263729, 40.122543],
                    [-115.834969, 40.127842],
                    [-115.83530100000002, 40.017371],
                    [-115.79486399999999, 39.853104],
                    [-115.833101, 39.729412],
                    [-115.813476, 39.593521],
                    [-115.87588599999998, 39.465718],
                    [-115.90707100000002, 39.161848],
                    [-116.600946, 39.161463],
                    [-116.59285499999999, 39.374928],
                    [-116.599986, 40.072307],
                    [-116.594214, 40.320251],
                    [-116.596815, 40.94646],
                    [-116.586996, 40.964776],
                    [-116.58678600000002, 41.000398],
                    [-117.01841900000001, 41.000254],
                    [-117.018203216741, 41.9998398503691],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '21220',
            AFFGEOID: '310M600US21220',
            GEOID: '21220',
            NAME: 'Elko, NV',
            NAMELSAD: 'Elko, NV Micro Area',
            LSAD: 'M2',
            ALAND: 55294001731,
            AWATER: 85843260,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-111.649129, 39.899654, -110.000711, 41.2516269562514],
            type: 'Polygon',
            coordinates: [
                [
                    [-111.641052, 40.798925],
                    [-111.527985, 40.785362],
                    [-111.479555, 40.858288],
                    [-111.524895, 40.953842],
                    [-111.592981, 40.993015],
                    [-111.50751, 41.063234],
                    [-111.380452, 41.087248],
                    [-111.366604, 41.130018],
                    [-111.264974, 41.144044],
                    [-111.232824, 41.142881],
                    [-111.046636983126, 41.2516269562514],
                    [-111.04672300000001, 40.997959],
                    [-110.539819, 40.996346],
                    [-110.121639, 40.997101],
                    [-110.048482995685, 40.9972972928506],
                    [-110.000716736441, 40.9974254597082],
                    [-110.000711, 40.813678],
                    [-110.137819, 40.810913000000006],
                    [-110.292692, 40.833343],
                    [-110.37896200000002, 40.78718],
                    [-110.561377, 40.758365],
                    [-110.625737, 40.769471],
                    [-110.656482, 40.740275],
                    [-110.75073300000001, 40.747706],
                    [-110.822478, 40.710461],
                    [-110.89198, 40.727123000000006],
                    [-110.90197400000001, 40.678162],
                    [-110.891655, 39.899654],
                    [-111.082455, 39.900155],
                    [-111.083061, 39.943198],
                    [-111.205772, 40.047368],
                    [-111.249339, 40.055217],
                    [-111.225507, 40.167324],
                    [-111.278762, 40.258793],
                    [-111.31483, 40.287566],
                    [-111.426482, 40.286906],
                    [-111.480337, 40.362169],
                    [-111.555235, 40.362696],
                    [-111.617505, 40.438018],
                    [-111.575028, 40.486526],
                    [-111.593879, 40.577063],
                    [-111.553266, 40.609313],
                    [-111.604411, 40.666523],
                    [-111.606221, 40.712856],
                    [-111.649129, 40.773443],
                    [-111.641052, 40.798925],
                ],
            ],
        },
        properties: {
            CSAFP: '482',
            CBSAFP: '25720',
            AFFGEOID: '310M600US25720',
            GEOID: '25720',
            NAME: 'Heber, UT',
            NAMELSAD: 'Heber, UT Micro Area',
            LSAD: 'M2',
            ALAND: 7893229099,
            AWATER: 105745494,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-110.064581, 44.999623, -107.466131, 46.496014],
            type: 'Polygon',
            coordinates: [
                [
                    [-110.064581, 45.348755],
                    [-109.931984, 45.348755],
                    [-109.932025, 45.522456],
                    [-109.808841, 45.52247],
                    [-109.808793, 45.565925],
                    [-109.68538500000001, 45.56561],
                    [-109.68538500000001, 45.60902200000001],
                    [-109.56207, 45.609119],
                    [-109.56390700000001, 45.783471],
                    [-109.548822, 45.870749],
                    [-109.505963, 45.870218],
                    [-109.506604, 45.959112],
                    [-109.423026, 45.960011],
                    [-109.417158, 46.044759],
                    [-109.416227, 46.132371],
                    [-108.924589, 46.13237],
                    [-108.779733, 46.132457],
                    [-108.404795, 46.132952],
                    [-108.40485600000001, 46.23596],
                    [-108.320812, 46.26399700000001],
                    [-108.02936300000002, 46.264055],
                    [-108.011948, 46.336813],
                    [-107.928547, 46.395284],
                    [-107.782087, 46.395182],
                    [-107.781214, 46.496014],
                    [-107.738548, 46.481806],
                    [-107.61241100000001, 46.351557],
                    [-107.466131, 46.177774],
                    [-107.511534, 46.043403],
                    [-107.674454, 46.04361000000001],
                    [-107.674468, 45.985759],
                    [-107.798753, 45.98568],
                    [-107.798821, 45.956723],
                    [-107.881873, 45.928149],
                    [-108.047352, 45.899479],
                    [-108.047559, 45.783476],
                    [-108.069752, 45.518535],
                    [-108.193205, 45.518538],
                    [-108.193191, 45.489526],
                    [-108.316245, 45.489503],
                    [-108.316374, 45.46050900000001],
                    [-108.645325, 45.464073],
                    [-108.682223, 45.433132],
                    [-108.69962, 45.348946],
                    [-108.699616, 45.217709],
                    [-108.098054, 45.220517],
                    [-108.21751800000001, 45.09411500000001],
                    [-108.258603, 45.00818],
                    [-108.248525185308, 45.0006298274175],
                    [-108.500679, 44.999691],
                    [-108.62149349473, 44.9996763710215],
                    [-109.062262, 44.999623],
                    [-109.103445, 45.005904],
                    [-109.574321, 45.002631],
                    [-109.798483772435, 45.002920308285],
                    [-109.798673, 45.167339],
                    [-109.81223300000002, 45.172129],
                    [-110.064518, 45.172129],
                    [-110.064581, 45.348755],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '13740',
            AFFGEOID: '310M600US13740',
            GEOID: '13740',
            NAME: 'Billings, MT',
            NAMELSAD: 'Billings, MT Metro Area',
            LSAD: 'M1',
            ALAND: 16777930243,
            AWATER: 98890305,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.4682695950029, 39.2646248420052, -77.4691450036804, 39.7226582929265],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.460951, 39.525987],
                    [-78.438179, 39.563524],
                    [-78.382959, 39.622246],
                    [-78.33278802149911, 39.6285275964988],
                    [-78.313325, 39.692474],
                    [-78.3428335185744, 39.7226582929265],
                    [-78.342593, 39.722658],
                    [-78.0989706961667, 39.7224661944281],
                    [-78.075861, 39.722448],
                    [-77.768644, 39.721538],
                    [-77.469274, 39.720229],
                    [-77.4691450036804, 39.72022900225],
                    [-77.570182, 39.619998],
                    [-77.656177, 39.368397],
                    [-77.6776957620787, 39.317940817088],
                    [-77.719519, 39.321314],
                    [-77.74593, 39.353221],
                    [-77.740012, 39.401694],
                    [-77.798201, 39.475719],
                    [-77.8109438962622, 39.5007391665665],
                    [-77.84714, 39.446557],
                    [-77.930868, 39.381170000000004],
                    [-77.966953, 39.367164],
                    [-77.988179, 39.320084],
                    [-78.035454, 39.278224],
                    [-78.0331852353666, 39.2646248420052],
                    [-78.18737, 39.363989],
                    [-78.2291295342185, 39.3906638872041],
                    [-78.347087, 39.466012],
                    [-78.450004, 39.50674],
                    [-78.4682695950029, 39.5262242704717],
                    [-78.460951, 39.525987],
                ],
            ],
        },
        properties: {
            CSAFP: '548',
            CBSAFP: '25180',
            AFFGEOID: '310M600US25180',
            GEOID: '25180',
            NAME: 'Hagerstown-Martinsburg, MD-WV',
            NAMELSAD: 'Hagerstown-Martinsburg, MD-WV Metro Area',
            LSAD: 'M1',
            ALAND: 2610718284,
            AWATER: 27283136,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.853668, 39.2109608092037, -81.036791, 39.645976],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.844863, 39.450216],
                    [-81.823158, 39.494072],
                    [-81.708527, 39.480775],
                    [-81.712706, 39.585107],
                    [-81.588185, 39.586973],
                    [-81.473471, 39.583318],
                    [-81.47316, 39.645976],
                    [-81.394934, 39.601547],
                    [-81.28307500000001, 39.605796],
                    [-81.036791, 39.572118],
                    [-81.0373650419871, 39.5380557124726],
                    [-81.07595, 39.50966],
                    [-81.1212738561078, 39.4576974138893],
                    [-81.128533, 39.449375],
                    [-81.185946, 39.43073100000001],
                    [-81.249088, 39.389992],
                    [-81.347567, 39.34577],
                    [-81.3703898088263, 39.34870067240341],
                    [-81.393794, 39.351706],
                    [-81.412706, 39.394618],
                    [-81.456143, 39.409274],
                    [-81.503189, 39.373242000000005],
                    [-81.559647, 39.330774],
                    [-81.565247, 39.276175],
                    [-81.613896, 39.275339],
                    [-81.678331, 39.273755],
                    [-81.711628, 39.219228],
                    [-81.7214681391725, 39.2109608092037],
                    [-81.721808, 39.269597],
                    [-81.818361, 39.272581],
                    [-81.853668, 39.318165],
                    [-81.844863, 39.450216],
                ],
            ],
        },
        properties: {
            CSAFP: '425',
            CBSAFP: '31930',
            AFFGEOID: '310M600US31930',
            GEOID: '31930',
            NAME: 'Marietta, OH',
            NAMELSAD: 'Marietta, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1636805226,
            AWATER: 20784952,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.089764, 28.129846, -97.375579, 28.719648000000003],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.005252, 28.690239000000002],
                    [-97.915106, 28.719648000000003],
                    [-97.77853, 28.668027000000002],
                    [-97.696572, 28.541537],
                    [-97.558496, 28.514054],
                    [-97.551369, 28.450441],
                    [-97.375579, 28.388684],
                    [-97.541071, 28.164669],
                    [-97.568482, 28.129846],
                    [-97.817716, 28.176848],
                    [-97.808774, 28.183415],
                    [-98.089764, 28.662979000000004],
                    [-98.005252, 28.690239000000002],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '13300',
            AFFGEOID: '310M600US13300',
            GEOID: '13300',
            NAME: 'Beeville, TX',
            NAMELSAD: 'Beeville, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2279809487,
            AWATER: 246409,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.582683, 38.474547, -81.772843, 39.035079],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.575583, 38.844478],
                    [-82.455179, 38.844577],
                    [-82.435309, 39.035079],
                    [-82.322874, 39.027674],
                    [-82.095465, 39.002778],
                    [-82.0988666505543, 38.9608792844933],
                    [-82.089065, 38.975978],
                    [-82.041563, 39.017878],
                    [-82.007062, 39.029578],
                    [-81.941829, 38.993295],
                    [-81.89847, 38.929603],
                    [-81.910606, 38.87597],
                    [-81.79126500000001, 38.733104],
                    [-81.772843, 38.680843],
                    [-81.955791, 38.657355],
                    [-82.064562, 38.607985],
                    [-82.055127, 38.474547],
                    [-82.218967, 38.591683],
                    [-82.27427, 38.593683],
                    [-82.2821333440281, 38.57986079640351],
                    [-82.361674, 38.585182],
                    [-82.354468, 38.67607],
                    [-82.472478, 38.682279],
                    [-82.48357900000002, 38.772278],
                    [-82.582683, 38.779077],
                    [-82.575583, 38.844478],
                ],
            ],
        },
        properties: {
            CSAFP: '170',
            CBSAFP: '38580',
            AFFGEOID: '310M600US38580',
            GEOID: '38580',
            NAME: 'Point Pleasant, WV-OH',
            NAMELSAD: 'Point Pleasant, WV-OH Micro Area',
            LSAD: 'M2',
            ALAND: 2323989370,
            AWATER: 48211686,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.434045, 31.920308000000002, -84.286246, 32.881788],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.434045, 32.40984],
                    [-85.330143, 32.410842],
                    [-85.333843, 32.468639],
                    [-85.059294, 32.472909],
                    [-85.0011308391237, 32.5101547883978],
                    [-85.0071, 32.523868],
                    [-85.069848, 32.583146],
                    [-85.0760723726048, 32.6080674751571],
                    [-85.088533, 32.657958],
                    [-85.11425, 32.730447],
                    [-85.1245332227506, 32.7516296067514],
                    [-85.160963, 32.826672],
                    [-85.1844, 32.861317],
                    [-85.1861174017892, 32.8701382699716],
                    [-84.881702, 32.868761],
                    [-84.861768, 32.872495],
                    [-84.751338, 32.8692],
                    [-84.700538, 32.84464],
                    [-84.570669, 32.845179],
                    [-84.506888, 32.881788],
                    [-84.380398, 32.779176],
                    [-84.286246, 32.747626],
                    [-84.33794, 32.720647],
                    [-84.40886600000002, 32.561923],
                    [-84.444353, 32.562083],
                    [-84.453154, 32.534336],
                    [-84.392316, 32.414046],
                    [-84.389927, 32.297237],
                    [-84.429451, 32.232151],
                    [-84.430218, 32.166257],
                    [-84.431214, 32.134058],
                    [-84.527117, 32.134556],
                    [-84.649319, 32.232953],
                    [-84.63112, 32.184054],
                    [-84.65582, 31.920308000000002],
                    [-84.675788, 31.928680000000004],
                    [-84.69173, 31.920443000000002],
                    [-84.810118, 31.931840000000005],
                    [-84.907006, 31.924465],
                    [-84.974067, 31.950209000000005],
                    [-85.042029, 31.975658000000003],
                    [-85.0635905429847, 31.991857000000003],
                    [-85.0514111279128, 32.062256083622],
                    [-85.185067, 32.061708],
                    [-85.257834, 32.14793100000001],
                    [-85.410241, 32.146651],
                    [-85.427441, 32.146551],
                    [-85.433543, 32.234648],
                    [-85.434045, 32.40984],
                ],
            ],
        },
        properties: {
            CSAFP: '194',
            CBSAFP: '17980',
            AFFGEOID: '310M600US17980',
            GEOID: '17980',
            NAME: 'Columbus, GA-AL',
            NAMELSAD: 'Columbus, GA-AL Metro Area',
            LSAD: 'M1',
            ALAND: 7215898005,
            AWATER: 82370077,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.104444, 34.384578, -84.644432, 34.634128],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.069159, 34.587184],
                    [-85.050446, 34.622482000000005],
                    [-84.92611900000001, 34.616164],
                    [-84.913456, 34.634128],
                    [-84.863793, 34.608597],
                    [-84.715205, 34.622474],
                    [-84.654523, 34.583187],
                    [-84.654366, 34.548946],
                    [-84.644432, 34.455789],
                    [-84.653232, 34.41259],
                    [-84.988793, 34.384578],
                    [-85.005775, 34.392446],
                    [-85.104444, 34.404278],
                    [-85.069159, 34.587184],
                ],
            ],
        },
        properties: {
            CSAFP: '174',
            CBSAFP: '15660',
            AFFGEOID: '310M600US15660',
            GEOID: '15660',
            NAME: 'Calhoun, GA',
            NAMELSAD: 'Calhoun, GA Micro Area',
            LSAD: 'M2',
            ALAND: 923182600,
            AWATER: 5729235,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-110.75071, 33.567874, -109.826964, 37.003197],
            type: 'Polygon',
            coordinates: [
                [
                    [-110.75069, 37.003197],
                    [-110.47019, 36.997997],
                    [-110.000677, 36.997968],
                    [-110.000674, 35.662659],
                    [-109.826964, 35.66263],
                    [-109.843762, 35.517905],
                    [-109.844624, 34.822562],
                    [-109.85050000000001, 34.647442],
                    [-109.865231, 34.559689],
                    [-109.848226, 34.473389],
                    [-109.859165, 34.125766],
                    [-109.859274, 33.777683],
                    [-109.891766, 33.777777],
                    [-109.891676, 33.567874],
                    [-109.903421, 33.57511600000001],
                    [-110.000649, 33.57693],
                    [-110.00066000000001, 34.000046],
                    [-110.750672, 34.000048],
                    [-110.750678, 34.263314],
                    [-110.75071, 35.034206],
                    [-110.75069, 37.003197],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '43320',
            AFFGEOID: '310M600US43320',
            GEOID: '43320',
            NAME: 'Show Low, AZ',
            NAMELSAD: 'Show Low, AZ Micro Area',
            LSAD: 'M2',
            ALAND: 25769070671,
            AWATER: 24116169,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.587224, 34.423807, -89.183944, 35.652316],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.5748549582232, 34.6316539794501],
                    [-90.550158, 34.663445],
                    [-90.546053, 34.702076],
                    [-90.505494, 34.764568],
                    [-90.473527, 34.788835],
                    [-90.463795, 34.834923],
                    [-90.4079823819208, 34.835265981566],
                    [-90.408539, 34.904021],
                    [-90.40043800000001, 35.148231],
                    [-90.503031, 35.144215],
                    [-90.506526, 35.17875],
                    [-90.502392, 35.441647],
                    [-90.286926, 35.438439],
                    [-90.140114, 35.436771],
                    [-90.11250400000002, 35.410153],
                    [-90.070549, 35.423291],
                    [-90.045306, 35.415435],
                    [-90.022064, 35.457375],
                    [-90.045805, 35.496533],
                    [-90.037615, 35.550329],
                    [-89.958498, 35.541703],
                    [-89.944754, 35.560308],
                    [-89.909698, 35.537955],
                    [-89.79520000000001, 35.63529],
                    [-89.62345800000001, 35.652316],
                    [-89.502118, 35.580621],
                    [-89.469862, 35.546566],
                    [-89.474171, 35.403039],
                    [-89.375071, 35.399936000000004],
                    [-89.347491, 35.400338],
                    [-89.183944, 35.397126],
                    [-89.19828790149501, 34.9944505301151],
                    [-89.35267939184841, 34.9939961417459],
                    [-89.352705, 34.844432000000005],
                    [-89.299227, 34.844415],
                    [-89.298334, 34.582926],
                    [-89.245645, 34.583234],
                    [-89.246133, 34.495633],
                    [-89.32582, 34.525974],
                    [-89.424459, 34.519129],
                    [-89.476642, 34.554168],
                    [-89.668448, 34.554367],
                    [-89.721341, 34.554274],
                    [-90.198631, 34.554425],
                    [-90.198536, 34.511088],
                    [-90.304317, 34.509621],
                    [-90.301851, 34.424546],
                    [-90.399498, 34.423807],
                    [-90.5694607451505, 34.5243442788972],
                    [-90.569347, 34.524867],
                    [-90.549244, 34.568101],
                    [-90.587224, 34.615732],
                    [-90.5748549582232, 34.6316539794501],
                ],
            ],
        },
        properties: {
            CSAFP: '368',
            CBSAFP: '32820',
            AFFGEOID: '310M600US32820',
            GEOID: '32820',
            NAME: 'Memphis, TN-MS-AR',
            NAMELSAD: 'Memphis, TN-MS-AR Metro Area',
            LSAD: 'M1',
            ALAND: 11849791559,
            AWATER: 301987186,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.214284, 26.958919, -80.677427, 27.643238],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.140472, 27.601302],
                    [-81.142164, 27.643238],
                    [-80.87315, 27.642288000000004],
                    [-80.778047, 27.643195],
                    [-80.777166, 27.558733],
                    [-80.67982100000002, 27.55847],
                    [-80.677862, 27.205988],
                    [-80.677427, 27.121619000000003],
                    [-80.885639, 26.958919],
                    [-80.878517, 27.168829],
                    [-80.940676, 27.205939],
                    [-80.971144, 27.217787],
                    [-81.034429, 27.35916],
                    [-81.09845, 27.379108],
                    [-81.214284, 27.528709],
                    [-81.140472, 27.601302],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '36380',
            AFFGEOID: '310M600US36380',
            GEOID: '36380',
            NAME: 'Okeechobee, FL',
            NAMELSAD: 'Okeechobee, FL Micro Area',
            LSAD: 'M2',
            ALAND: 1992126184,
            AWATER: 317924583,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.131224, 30.037455, -92.59112900000001, 30.488218],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.131155, 30.423591],
                    [-93.065525, 30.438677],
                    [-92.775737, 30.437617],
                    [-92.773981, 30.488218],
                    [-92.630321, 30.488012],
                    [-92.63202500000001, 30.481181999999997],
                    [-92.594586, 30.420339000000002],
                    [-92.63097500000002, 30.374585],
                    [-92.59112900000001, 30.182365],
                    [-92.625484, 30.09233],
                    [-92.637841, 30.072174],
                    [-92.737217, 30.037455],
                    [-92.757328, 30.039446999999996],
                    [-92.996624, 30.038481],
                    [-92.996479, 30.082593],
                    [-92.887222, 30.083455000000004],
                    [-92.893247, 30.156509999999997],
                    [-92.9963, 30.156955000000004],
                    [-93.030728, 30.260564],
                    [-93.030959, 30.379389999999997],
                    [-93.131224, 30.403058000000005],
                    [-93.131155, 30.423591],
                ],
            ],
        },
        properties: {
            CSAFP: '324',
            CBSAFP: '27660',
            AFFGEOID: '310M600US27660',
            GEOID: '27660',
            NAME: 'Jennings, LA',
            NAMELSAD: 'Jennings, LA Micro Area',
            LSAD: 'M2',
            ALAND: 1687430142,
            AWATER: 18666917,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.421388, 39.194906, -79.47666200000002, 39.72135000000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.421388, 39.721189],
                    [-80.075947, 39.72135000000001],
                    [-79.9160173560378, 39.7210559335188],
                    [-79.763774, 39.720776],
                    [-79.47666200000002, 39.721078],
                    [-79.482366, 39.531689],
                    [-79.484372, 39.3443],
                    [-79.486873, 39.205961],
                    [-79.487175, 39.194906],
                    [-79.687283, 39.271398],
                    [-79.809729, 39.230526],
                    [-79.871969, 39.294518],
                    [-79.895536, 39.299584],
                    [-79.894688, 39.435588],
                    [-79.938878, 39.452465],
                    [-80.153532, 39.610426],
                    [-80.347466, 39.611665],
                    [-80.39581, 39.637347000000005],
                    [-80.407408, 39.717752],
                    [-80.421388, 39.721189],
                ],
            ],
        },
        properties: {
            CSAFP: '390',
            CBSAFP: '34060',
            AFFGEOID: '310M600US34060',
            GEOID: '34060',
            NAME: 'Morgantown, WV',
            NAMELSAD: 'Morgantown, WV Metro Area',
            LSAD: 'M1',
            ALAND: 2613053221,
            AWATER: 21659664,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.525643, 37.51531, -83.7221, 37.91754600000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.46203100000001, 37.803431],
                    [-84.476283, 37.853131],
                    [-84.43572, 37.847202],
                    [-84.386165, 37.84549],
                    [-84.337393, 37.892007],
                    [-84.272406, 37.91754600000001],
                    [-84.261183, 37.875986],
                    [-84.080337, 37.853066],
                    [-84.075107, 37.82327200000001],
                    [-84.001967, 37.837592],
                    [-83.967452, 37.847632],
                    [-83.906251, 37.759363],
                    [-83.777592, 37.756776],
                    [-83.7221, 37.71642],
                    [-83.822121, 37.665578],
                    [-83.90557, 37.542856],
                    [-83.965244, 37.581421],
                    [-84.090364, 37.566227],
                    [-84.171836, 37.557549],
                    [-84.199489, 37.522858],
                    [-84.268112, 37.51531],
                    [-84.347805, 37.53906400000001],
                    [-84.450201, 37.697898],
                    [-84.525643, 37.769497],
                    [-84.46203100000001, 37.803431],
                ],
            ],
        },
        properties: {
            CSAFP: '336',
            CBSAFP: '40080',
            AFFGEOID: '310M600US40080',
            GEOID: '40080',
            NAME: 'Richmond-Berea, KY',
            NAMELSAD: 'Richmond-Berea, KY Micro Area',
            LSAD: 'M2',
            ALAND: 1788258315,
            AWATER: 21804272,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.108509, 32.078354000000004, -90.553821, 32.618858],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.108509, 32.20815000000001],
                    [-90.991227, 32.214662],
                    [-90.947834, 32.283486],
                    [-90.92117, 32.342073],
                    [-90.986672, 32.35176],
                    [-90.96598600000002, 32.424806],
                    [-91.052907, 32.438442],
                    [-91.06051600000002, 32.512361],
                    [-91.011275, 32.516596],
                    [-91.0487604277637, 32.5727970404214],
                    [-90.964043, 32.575605],
                    [-90.963381, 32.411185],
                    [-90.861364, 32.442115],
                    [-90.860566, 32.575173],
                    [-90.728344, 32.566265],
                    [-90.720102, 32.618858],
                    [-90.655527, 32.589771],
                    [-90.654563, 32.546042],
                    [-90.553821, 32.507574],
                    [-90.636162, 32.374498],
                    [-90.705175, 32.346788],
                    [-90.728622, 32.225119],
                    [-90.942802, 32.078354000000004],
                    [-91.039472318907, 32.1079681426653],
                    [-91.108509, 32.20815000000001],
                ],
            ],
        },
        properties: {
            CSAFP: '298',
            CBSAFP: '46980',
            AFFGEOID: '310M600US46980',
            GEOID: '46980',
            NAME: 'Vicksburg, MS',
            NAMELSAD: 'Vicksburg, MS Micro Area',
            LSAD: 'M2',
            ALAND: 1524286572,
            AWATER: 77759819,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-73.424977, 43.290551, -72.699795, 43.85442],
            type: 'Polygon',
            coordinates: [
                [
                    [-73.414546, 43.658209],
                    [-73.393723, 43.6992],
                    [-73.3611071516843, 43.7532334572839],
                    [-73.206322, 43.766775],
                    [-73.218956, 43.837291],
                    [-73.02993800000002, 43.846716],
                    [-73.01709, 43.80486],
                    [-72.959744, 43.826077],
                    [-72.883951, 43.85442],
                    [-72.873871, 43.839628],
                    [-72.838341, 43.829254],
                    [-72.830665, 43.83912],
                    [-72.791174, 43.824136],
                    [-72.782856, 43.804007],
                    [-72.819262, 43.746313],
                    [-72.803928, 43.740973000000004],
                    [-72.819985, 43.716697],
                    [-72.699795, 43.684577],
                    [-72.75794800000001, 43.587424],
                    [-72.771371, 43.4788],
                    [-72.724223, 43.468174],
                    [-72.762356, 43.37141400000001],
                    [-72.852384, 43.376279],
                    [-72.868647, 43.300529],
                    [-72.975885, 43.290551],
                    [-72.98759, 43.305005],
                    [-73.2553649410914, 43.3145364556694],
                    [-73.252832, 43.363493],
                    [-73.242042, 43.534925],
                    [-73.292113, 43.584509],
                    [-73.327702, 43.625913],
                    [-73.395767, 43.568087],
                    [-73.424977, 43.598775],
                    [-73.414546, 43.658209],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '40860',
            AFFGEOID: '310M600US40860',
            GEOID: '40860',
            NAME: 'Rutland, VT',
            NAMELSAD: 'Rutland, VT Micro Area',
            LSAD: 'M2',
            ALAND: 2410114035,
            AWATER: 38760900,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.6872324838884, 37.293933, -88.70675900000002, 37.955343],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.6872324838884, 37.7964280387079],
                    [-89.66444800000001, 37.829273],
                    [-89.595073, 37.955343],
                    [-89.177597, 37.950311],
                    [-89.150834, 37.950196],
                    [-89.151176, 37.861999],
                    [-88.70675900000002, 37.863457],
                    [-88.708546, 37.599277],
                    [-88.708546, 37.599059],
                    [-88.710738, 37.337081],
                    [-88.905071, 37.335743],
                    [-88.928757, 37.302848],
                    [-89.04469100000001, 37.293933],
                    [-89.044799, 37.329717],
                    [-89.04140100000001, 37.596576],
                    [-89.153617, 37.600237],
                    [-89.453087, 37.59987],
                    [-89.4977459260392, 37.5699859139282],
                    [-89.49405100000001, 37.580116],
                    [-89.506563, 37.62505],
                    [-89.521948, 37.696475],
                    [-89.591289, 37.723599],
                    [-89.667993, 37.759484],
                    [-89.6872324838884, 37.7964280387079],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '16060',
            AFFGEOID: '310M600US16060',
            GEOID: '16060',
            NAME: 'Carbondale-Marion, IL',
            NAMELSAD: 'Carbondale-Marion, IL Metro Area',
            LSAD: 'M1',
            ALAND: 3490051886,
            AWATER: 123597413,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-74.985271, 39.288558, -74.2910153361548, 39.729775],
            type: 'Polygon',
            coordinates: [
                [
                    [-74.911882, 39.578462],
                    [-74.87726, 39.608352],
                    [-74.800087, 39.67486400000001],
                    [-74.736216, 39.729775],
                    [-74.723856, 39.722063],
                    [-74.654801, 39.626846],
                    [-74.475664, 39.55146],
                    [-74.41739300000002, 39.557255],
                    [-74.380796, 39.498537],
                    [-74.2910153361548, 39.5083697974978],
                    [-74.29158500000001, 39.507705],
                    [-74.304343, 39.471445],
                    [-74.36699, 39.402017],
                    [-74.412692, 39.360816],
                    [-74.521797, 39.313816],
                    [-74.5407878555825, 39.3000254911844],
                    [-74.653206, 39.288558],
                    [-74.861294, 39.323815],
                    [-74.856015, 39.423958],
                    [-74.985271, 39.514967],
                    [-74.911882, 39.578462],
                ],
            ],
        },
        properties: {
            CSAFP: '428',
            CBSAFP: '12100',
            AFFGEOID: '310M600US12100',
            GEOID: '12100',
            NAME: 'Atlantic City-Hammonton, NJ',
            NAMELSAD: 'Atlantic City-Hammonton, NJ Metro Area',
            LSAD: 'M1',
            ALAND: 1438775279,
            AWATER: 301270067,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.859624, 27.612972, -82.054349, 28.694858999999997],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.859624, 28.174135],
                    [-82.764103, 28.244345000000003],
                    [-82.73146, 28.325075],
                    [-82.697433, 28.420166],
                    [-82.6908136882946, 28.4333048573934],
                    [-82.665055, 28.484434],
                    [-82.656694, 28.544814],
                    [-82.668149, 28.622411],
                    [-82.6687096341287, 28.694077261821104],
                    [-82.418353, 28.694858999999997],
                    [-82.417684, 28.665897],
                    [-82.263052, 28.667634],
                    [-82.274823, 28.656198000000003],
                    [-82.208184, 28.572054],
                    [-82.054469, 28.521367],
                    [-82.054694, 28.478397],
                    [-82.055819, 28.312794],
                    [-82.106206, 28.25924],
                    [-82.105853, 28.17165],
                    [-82.056261, 28.171592],
                    [-82.055258, 27.927085],
                    [-82.054874, 27.907726],
                    [-82.054349, 27.646382],
                    [-82.5528872070636, 27.6454475963655],
                    [-82.514265, 27.705588],
                    [-82.477638, 27.723004],
                    [-82.43198, 27.768092],
                    [-82.448786, 27.810036],
                    [-82.47344674878921, 27.817585625527304],
                    [-82.489849, 27.822607],
                    [-82.553946, 27.848462],
                    [-82.5663805212513, 27.8363382177134],
                    [-82.586519, 27.816703],
                    [-82.622723, 27.779868],
                    [-82.62502, 27.732706000000004],
                    [-82.652521, 27.700307],
                    [-82.705017, 27.62531],
                    [-82.733076, 27.612972],
                    [-82.738467, 27.678504],
                    [-82.746223, 27.731306],
                    [-82.790224, 27.791603],
                    [-82.846526, 27.854301],
                    [-82.84088200000001, 27.937162],
                    [-82.8283313947752, 28.019031534892203],
                    [-82.828163, 28.02013],
                    [-82.850881, 28.102451],
                    [-82.8593849560329, 28.1721750746045],
                    [-82.859624, 28.174135],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '45300',
            AFFGEOID: '310M600US45300',
            GEOID: '45300',
            NAME: 'Tampa-St. Petersburg-Clearwater, FL',
            NAMELSAD: 'Tampa-St. Petersburg-Clearwater, FL Metro Area',
            LSAD: 'M1',
            ALAND: 6515645098,
            AWATER: 3649151233,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.7995592762003, 30.268823267328404, -85.84442100000001, 30.9969829935168],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.7856919760059, 30.9969829935168],
                    [-86.6882409642461, 30.9962018900015],
                    [-86.563494, 30.995201999999995],
                    [-86.3886446620004, 30.9945282152752],
                    [-86.364974, 30.994437],
                    [-86.1872480489813, 30.9940667234622],
                    [-86.0350381049506, 30.993749607302302],
                    [-86.037591, 30.704572],
                    [-85.84442100000001, 30.70313],
                    [-85.91529700000001, 30.636427],
                    [-85.859769, 30.490134],
                    [-85.992736, 30.38932],
                    [-85.9959431575134, 30.268823267328404],
                    [-85.999937, 30.27078],
                    [-86.089963, 30.303569],
                    [-86.222561, 30.343585000000004],
                    [-86.3973810669074, 30.377495563699902],
                    [-86.412076, 30.380346],
                    [-86.632953, 30.396299000000003],
                    [-86.7995592762003, 30.384563878226402],
                    [-86.7856919760059, 30.9969829935168],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '18880',
            AFFGEOID: '310M600US18880',
            GEOID: '18880',
            NAME: 'Crestview-Fort Walton Beach-Destin, FL',
            NAMELSAD: 'Crestview-Fort Walton Beach-Destin, FL Metro Area',
            LSAD: 'M1',
            ALAND: 5097500586,
            AWATER: 1781864498,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.29811, 38.473039, -83.673018, 39.729179],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.29811, 39.525476],
                    [-85.036087, 39.526213],
                    [-85.034575, 39.71476400000001],
                    [-85.022528, 39.729179],
                    [-84.8141281379328, 39.7266165116193],
                    [-84.814129, 39.726556],
                    [-84.8157059432555, 39.5677216640274],
                    [-84.47892700000001, 39.56879],
                    [-84.479213, 39.591024],
                    [-84.365232, 39.589493],
                    [-84.286152, 39.589581],
                    [-84.11417400000002, 39.57823],
                    [-83.976987, 39.569402],
                    [-84.007454, 39.255112],
                    [-83.989921, 39.254096],
                    [-83.865679, 39.247333],
                    [-83.872214, 39.021304],
                    [-83.673018, 39.02043],
                    [-83.7058575510284, 38.6380366259831],
                    [-83.77216, 38.65815],
                    [-83.78362, 38.695641],
                    [-83.834015, 38.716008],
                    [-83.852085, 38.751433],
                    [-83.9043750861048, 38.7672838752678],
                    [-83.991605, 38.593701],
                    [-84.083746, 38.608594],
                    [-84.162031, 38.554125],
                    [-84.215625, 38.554976],
                    [-84.205346, 38.582039],
                    [-84.400321, 38.560603],
                    [-84.44855600000001, 38.529422],
                    [-84.476299, 38.542723],
                    [-84.485295, 38.508552],
                    [-84.557375, 38.492917],
                    [-84.580539, 38.473039],
                    [-84.774322, 38.618594],
                    [-84.785788, 38.720459],
                    [-84.811845, 38.702307],
                    [-84.934536, 38.66213],
                    [-84.962671, 38.679586],
                    [-85.0210522365665, 38.7585272930799],
                    [-84.962535, 38.778035],
                    [-84.856904, 38.790224],
                    [-84.812877, 38.786087],
                    [-84.803247, 38.850723],
                    [-84.7987011365099, 38.8592249335163],
                    [-84.786406, 38.88222],
                    [-84.830472, 38.897256],
                    [-84.8643271879881, 38.9137941412077],
                    [-84.870623, 38.900906],
                    [-85.140312, 38.903194],
                    [-85.135832, 38.929578],
                    [-85.132508, 38.948055],
                    [-85.065574, 39.307232],
                    [-85.217883, 39.308474000000004],
                    [-85.29654, 39.268291],
                    [-85.297575, 39.453275],
                    [-85.29811, 39.525476],
                ],
            ],
        },
        properties: {
            CSAFP: '178',
            CBSAFP: '17140',
            AFFGEOID: '310M600US17140',
            GEOID: '17140',
            NAME: 'Cincinnati, OH-KY-IN',
            NAMELSAD: 'Cincinnati, OH-KY-IN Metro Area',
            LSAD: 'M1',
            ALAND: 11775271676,
            AWATER: 218882150,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.008298, 33.466006, -80.28588, 34.614765],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.98991000000001, 33.997101],
                    [-81.870084, 34.135357],
                    [-81.860571, 34.179373],
                    [-81.731614, 34.187613],
                    [-81.568511, 34.099687],
                    [-81.502477, 34.07388],
                    [-81.471972, 34.07655200000001],
                    [-81.394124, 34.071488],
                    [-81.43717200000002, 34.129963],
                    [-81.33896100000001, 34.197587],
                    [-81.316911, 34.239458],
                    [-81.342297, 34.266928],
                    [-81.423241, 34.494379],
                    [-81.427545, 34.529482],
                    [-81.422706, 34.572029],
                    [-80.877491, 34.543146],
                    [-80.898722, 34.486694],
                    [-80.879227, 34.457776],
                    [-80.743345, 34.539916],
                    [-80.739966, 34.479957],
                    [-80.527821, 34.504003],
                    [-80.550552, 34.560116],
                    [-80.408373, 34.614765],
                    [-80.32855300000001, 34.501349],
                    [-80.28588, 34.394071],
                    [-80.288596, 34.366207],
                    [-80.288692, 34.364139],
                    [-80.353031, 34.30333],
                    [-80.437359, 34.284502],
                    [-80.479857, 34.168696],
                    [-80.58011, 34.099949],
                    [-80.618542, 34.109176000000005],
                    [-80.616186, 34.099605],
                    [-80.635712, 34.084886],
                    [-80.601036, 34.03593],
                    [-80.633545, 33.85268],
                    [-80.615676, 33.814514],
                    [-80.598288, 33.804476],
                    [-80.605638, 33.757476],
                    [-80.620107, 33.743237],
                    [-80.627531, 33.731212],
                    [-80.534085, 33.643911],
                    [-80.540514, 33.629032],
                    [-80.496273, 33.55848100000001],
                    [-80.552272, 33.565414],
                    [-80.61657500000001, 33.466006],
                    [-80.80763100000001, 33.604081],
                    [-80.93979, 33.608798],
                    [-80.98421, 33.644797],
                    [-81.042854, 33.707131],
                    [-81.08663300000002, 33.695409],
                    [-81.187271, 33.652937],
                    [-81.415308, 33.732161],
                    [-81.571487, 33.877318],
                    [-81.65175900000001, 33.81451],
                    [-81.836803, 33.866501],
                    [-81.894595, 33.975415],
                    [-82.008298, 33.961646],
                    [-81.98991000000001, 33.997101],
                ],
            ],
        },
        properties: {
            CSAFP: '192',
            CBSAFP: '17900',
            AFFGEOID: '310M600US17900',
            GEOID: '17900',
            NAME: 'Columbia, SC',
            NAMELSAD: 'Columbia, SC Metro Area',
            LSAD: 'M1',
            ALAND: 9590883519,
            AWATER: 338705662,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-118.944479511459, 32.800595, -117.412987, 34.823301],
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [-118.593969, 33.467198],
                        [-118.484785, 33.487483],
                        [-118.370323, 33.409285],
                        [-118.286261, 33.351463],
                        [-118.325244, 33.299075],
                        [-118.37476800000002, 33.320065],
                        [-118.46536799999998, 33.326056],
                        [-118.482609, 33.369914],
                        [-118.563442, 33.434381],
                        [-118.593969, 33.467198],
                    ],
                ],
                [
                    [
                        [-118.594033, 33.035951],
                        [-118.540069, 32.980933],
                        [-118.446771, 32.895424],
                        [-118.353504, 32.821962],
                        [-118.425634, 32.800595],
                        [-118.487908, 32.84459],
                        [-118.581513, 32.931672],
                        [-118.64157800000001, 33.017129],
                        [-118.594033, 33.035951],
                    ],
                ],
                [
                    [
                        [-118.940965, 34.07483],
                        [-118.85647300000001, 34.126765000000006],
                        [-118.788889, 34.168214],
                        [-118.723374, 34.167861],
                        [-118.693834, 34.168557],
                        [-118.667944, 34.199166],
                        [-118.667742, 34.236694],
                        [-118.633461, 34.269522],
                        [-118.652285, 34.323392],
                        [-118.738618, 34.498969],
                        [-118.88040500000001, 34.788655],
                        [-118.89463399999998, 34.817972],
                        [-118.326281, 34.819726],
                        [-117.774368, 34.823301],
                        [-117.66745899999998, 34.822528],
                        [-117.65523499999999, 34.397222],
                        [-117.646374, 34.28917],
                        [-117.677155, 34.167377],
                        [-117.693563, 34.121627],
                        [-117.704356, 34.09529],
                        [-117.71117, 34.079536],
                        [-117.744342, 34.019852],
                        [-117.767654, 34.019386],
                        [-117.802539, 33.975551],
                        [-117.783252, 33.946261],
                        [-117.67301899999998, 33.871039],
                        [-117.67505300000002, 33.868725],
                        [-117.53399900000001, 33.710355],
                        [-117.412987, 33.659045],
                        [-117.457937, 33.602189],
                        [-117.51021000000001, 33.533999],
                        [-117.50972199999998, 33.505019],
                        [-117.50861400000001, 33.469614],
                        [-117.57848000000001, 33.453927],
                        [-117.596188685737, 33.3869649943199],
                        [-117.64558199999999, 33.440728],
                        [-117.71534900000002, 33.460556],
                        [-117.72648600000001, 33.483427],
                        [-117.732258297614, 33.4879550239786],
                        [-117.814188, 33.552224],
                        [-117.840289, 33.573523],
                        [-117.92709100000002, 33.605521],
                        [-118.000593, 33.654319],
                        [-118.08889600000002, 33.729817],
                        [-118.115076722882, 33.743803322895],
                        [-118.13269800000002, 33.753217],
                        [-118.1837, 33.736118],
                        [-118.231926185034, 33.7152954681899],
                        [-118.258687, 33.703741],
                        [-118.31720500000002, 33.712818],
                        [-118.333295024316, 33.721184383577],
                        [-118.354705, 33.732317],
                        [-118.396606, 33.735917],
                        [-118.42840699999999, 33.774715],
                        [-118.394307, 33.804315],
                        [-118.41270800000001, 33.883913],
                        [-118.46061100000001, 33.969111],
                        [-118.51951399999999, 34.027509],
                        [-118.603572, 34.039048],
                        [-118.67936600000002, 34.033255],
                        [-118.74495200000001, 34.032103],
                        [-118.805114, 34.001239],
                        [-118.85465300000001, 34.034215],
                        [-118.944479511459, 34.046738953351],
                        [-118.940965, 34.07483],
                    ],
                ],
            ],
        },
        properties: {
            CSAFP: '348',
            CBSAFP: '31080',
            AFFGEOID: '310M600US31080',
            GEOID: '31080',
            NAME: 'Los Angeles-Long Beach-Anaheim, CA',
            NAMELSAD: 'Los Angeles-Long Beach-Anaheim, CA Metro Area',
            LSAD: 'M1',
            ALAND: 12566985125,
            AWATER: 2193754834,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.3995487854742, 41.16678, -82.6905671606649, 41.732647],
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [-82.863342, 41.693693],
                        [-82.82572, 41.72281],
                        [-82.782719, 41.694003],
                        [-82.788876, 41.643047],
                        [-82.842099, 41.628323],
                        [-82.863342, 41.693693],
                    ],
                ],
                [
                    [
                        [-84.3995487854742, 41.7059213574056],
                        [-84.3604190413973, 41.7069558826335],
                        [-84.134417, 41.712931],
                        [-83.8803904653533, 41.7201944903196],
                        [-83.7631498513652, 41.7235468017856],
                        [-83.763038, 41.72355],
                        [-83.5855418421088, 41.7287720285128],
                        [-83.453832, 41.732647],
                        [-83.409531, 41.691247],
                        [-83.326825, 41.701562],
                        [-83.23166000000002, 41.644218],
                        [-83.1638214175228, 41.624130312974],
                        [-83.066593, 41.59534000000001],
                        [-83.028072, 41.555656000000006],
                        [-82.934369, 41.514353],
                        [-82.859531, 41.576371],
                        [-82.834101, 41.587587],
                        [-82.717878, 41.54193],
                        [-82.6905671606649, 41.4967057954382],
                        [-82.73044, 41.479343],
                        [-82.95222400000002, 41.458239],
                        [-83.067459, 41.457169],
                        [-83.338196, 41.457508],
                        [-83.414626, 41.500228],
                        [-83.419843, 41.254003],
                        [-83.421054, 41.16678],
                        [-83.881149, 41.167824],
                        [-83.883234, 41.41450300000001],
                        [-83.882943, 41.487543],
                        [-84.341902, 41.485519],
                        [-84.342051, 41.514107],
                        [-84.380735, 41.513942],
                        [-84.381312, 41.61673],
                        [-84.3995487854742, 41.7059213574056],
                    ],
                ],
            ],
        },
        properties: {
            CSAFP: '534',
            CBSAFP: '45780',
            AFFGEOID: '310M600US45780',
            GEOID: '45780',
            NAME: 'Toledo, OH',
            NAMELSAD: 'Toledo, OH Metro Area',
            LSAD: 'M1',
            ALAND: 4187949479,
            AWATER: 1532455676,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.202628, 32.452198, -83.226536, 33.202629],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.124332, 32.800935],
                    [-84.12427500000001, 32.849562],
                    [-84.12334, 32.932184],
                    [-84.05417500000001, 32.931497],
                    [-84.041498, 33.202629],
                    [-83.842615, 33.196773],
                    [-83.822261, 33.180238],
                    [-83.816048, 33.131816],
                    [-83.545876, 33.171944],
                    [-83.444375, 33.184942],
                    [-83.42909, 33.185352],
                    [-83.414997, 33.112833],
                    [-83.357685, 32.926141],
                    [-83.40699, 32.89822],
                    [-83.226536, 32.584201],
                    [-83.412055, 32.502091],
                    [-83.497923, 32.452198],
                    [-83.561058, 32.658275],
                    [-83.597656, 32.664338],
                    [-83.666134, 32.674518],
                    [-83.701152, 32.692169],
                    [-83.70109, 32.691598],
                    [-83.766787, 32.692622],
                    [-84.003363, 32.529927],
                    [-84.062735, 32.578432],
                    [-84.105924, 32.685049],
                    [-84.202628, 32.690018],
                    [-84.124332, 32.800935],
                ],
            ],
        },
        properties: {
            CSAFP: '356',
            CBSAFP: '31420',
            AFFGEOID: '310M600US31420',
            GEOID: '31420',
            NAME: 'Macon-Bibb County, GA',
            NAMELSAD: 'Macon-Bibb County, GA Metro Area',
            LSAD: 'M1',
            ALAND: 4464076850,
            AWATER: 36496465,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.475646, 33.704417, -91.421985, 34.492809],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.471609, 34.057098],
                    [-92.336695, 34.059477],
                    [-92.233303, 34.062367],
                    [-92.212827, 34.230399],
                    [-92.208042, 34.492809],
                    [-92.030254, 34.489539],
                    [-91.974805, 34.488721],
                    [-91.70525, 34.482731],
                    [-91.709864, 34.307765],
                    [-91.711675, 34.233864000000004],
                    [-91.536896, 34.22329],
                    [-91.514374, 34.142293],
                    [-91.451931, 34.133865],
                    [-91.445074, 34.080577],
                    [-91.438148, 34.027735],
                    [-91.421985, 34.011715],
                    [-91.548656, 33.976815],
                    [-91.557656, 33.783322],
                    [-91.663738, 33.792246],
                    [-91.975866, 33.791726],
                    [-91.97588, 33.704417],
                    [-92.330918, 33.707745],
                    [-92.341936, 33.742983],
                    [-92.334249, 33.795065],
                    [-92.400485, 33.913161],
                    [-92.475646, 33.972706],
                    [-92.471609, 34.057098],
                ],
            ],
        },
        properties: {
            CSAFP: '340',
            CBSAFP: '38220',
            AFFGEOID: '310M600US38220',
            GEOID: '38220',
            NAME: 'Pine Bluff, AR',
            NAMELSAD: 'Pine Bluff, AR Metro Area',
            LSAD: 'M1',
            ALAND: 5257008024,
            AWATER: 142326550,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.99509000000002, 39.522104000000006, -89.217473, 40.15487],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.99509000000002, 40.108908],
                    [-89.969618, 40.142358],
                    [-89.749196, 40.126852],
                    [-89.638262, 40.15487],
                    [-89.601688, 40.122378],
                    [-89.579128, 40.092415],
                    [-89.578289, 39.976127],
                    [-89.483826, 39.976733],
                    [-89.404984, 39.918187],
                    [-89.217846, 39.91699],
                    [-89.217473, 39.813666],
                    [-89.246532, 39.826085],
                    [-89.303122, 39.775813],
                    [-89.425535, 39.747676],
                    [-89.425051, 39.683696],
                    [-89.478923, 39.683964],
                    [-89.478473, 39.640842],
                    [-89.535028, 39.641138],
                    [-89.534998, 39.612226],
                    [-89.533655, 39.524592],
                    [-89.701661, 39.523316],
                    [-89.926037, 39.522104000000006],
                    [-89.993539, 39.785393],
                    [-89.994405, 39.87286],
                    [-89.994506, 39.901925],
                    [-89.99509000000002, 40.108908],
                ],
            ],
        },
        properties: {
            CSAFP: '522',
            CBSAFP: '44100',
            AFFGEOID: '310M600US44100',
            GEOID: '44100',
            NAME: 'Springfield, IL',
            NAMELSAD: 'Springfield, IL Metro Area',
            LSAD: 'M1',
            ALAND: 3063043204,
            AWATER: 25317286,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.079498, 43.846935, -91.2876556273671, 44.1972265195522],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.079236, 44.192966],
                    [-91.85449997529771, 44.1972265195522],
                    [-91.817302, 44.164235],
                    [-91.719097, 44.12885300000001],
                    [-91.647873, 44.064109],
                    [-91.573283, 44.026901],
                    [-91.5592155630397, 44.0242093165812],
                    [-91.440536, 44.001501],
                    [-91.4235686998173, 43.9842968803226],
                    [-91.357426, 43.917231],
                    [-91.29100200000002, 43.852733],
                    [-91.2876556273671, 43.847065],
                    [-91.730424, 43.846935],
                    [-92.079141, 43.847126],
                    [-92.079498, 44.106721],
                    [-92.079236, 44.192966],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '49100',
            AFFGEOID: '310M600US49100',
            GEOID: '49100',
            NAME: 'Winona, MN',
            NAMELSAD: 'Winona, MN Micro Area',
            LSAD: 'M2',
            ALAND: 1621791138,
            AWATER: 40051870,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.436916, 40.555001, -82.126196, 41.065761],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.436916, 41.065379],
                    [-82.336496, 41.065761],
                    [-82.171492, 41.063537000000004],
                    [-82.173359, 40.992046],
                    [-82.129334, 40.991807],
                    [-82.126196, 40.668357],
                    [-82.221118, 40.667356],
                    [-82.220268, 40.568187],
                    [-82.336962, 40.555001],
                    [-82.338483, 40.638502],
                    [-82.339211, 40.726683],
                    [-82.420752, 40.82333],
                    [-82.43285200000001, 40.992944],
                    [-82.436916, 41.065379],
                ],
            ],
        },
        properties: {
            CSAFP: '360',
            CBSAFP: '11740',
            AFFGEOID: '310M600US11740',
            GEOID: '11740',
            NAME: 'Ashland, OH',
            NAMELSAD: 'Ashland, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1095530837,
            AWATER: 9966470,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.095096, 41.201859, -78.236817, 41.631481],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.06352, 41.453207],
                    [-78.958957, 41.437061],
                    [-78.956138, 41.623334],
                    [-78.483815, 41.631481],
                    [-78.420498, 41.601874],
                    [-78.420028, 41.405046],
                    [-78.253959, 41.404721],
                    [-78.236817, 41.230475],
                    [-78.64666600000001, 41.253778],
                    [-78.710248, 41.201859],
                    [-78.73528, 41.262915],
                    [-78.971904, 41.376565],
                    [-79.095096, 41.340658],
                    [-79.06352, 41.453207],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '41260',
            AFFGEOID: '310M600US41260',
            GEOID: '41260',
            NAME: 'St. Marys, PA',
            NAMELSAD: 'St. Marys, PA Micro Area',
            LSAD: 'M2',
            ALAND: 2141664702,
            AWATER: 12791578,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-99.8420638841464, 34.3253735743726, -99.052511, 34.84954],
            type: 'Polygon',
            coordinates: [
                [
                    [-99.8420638841464, 34.5069327688814],
                    [-99.666512, 34.507121],
                    [-99.666849, 34.724555],
                    [-99.423201, 34.72502],
                    [-99.405821, 34.813038],
                    [-99.24646200000001, 34.822114],
                    [-99.14457, 34.84954],
                    [-99.149114, 34.702352],
                    [-99.052511, 34.701557],
                    [-99.10315, 34.637950000000004],
                    [-99.16332, 34.58803600000001],
                    [-99.237922, 34.426657],
                    [-99.2216079008455, 34.3253735743726],
                    [-99.27534, 34.386599],
                    [-99.350407, 34.437083],
                    [-99.394956, 34.442099],
                    [-99.420432, 34.380464],
                    [-99.470969, 34.396471],
                    [-99.4750221370384, 34.3968709897905],
                    [-99.58448, 34.407673],
                    [-99.600026, 34.374688],
                    [-99.696462, 34.381036],
                    [-99.764882, 34.435266],
                    [-99.818186, 34.48784],
                    [-99.8420638841464, 34.5069327688814],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '11060',
            AFFGEOID: '310M600US11060',
            GEOID: '11060',
            NAME: 'Altus, OK',
            NAMELSAD: 'Altus, OK Micro Area',
            LSAD: 'M2',
            ALAND: 2078860507,
            AWATER: 4114418,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.306384, 42.610817000000004, -87.766675, 42.843323],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.306384, 42.842095],
                    [-88.069924, 42.843323],
                    [-87.821162938034, 42.8422735467354],
                    [-87.766675, 42.784896],
                    [-87.785074, 42.700819],
                    [-87.8020150551096, 42.6683110611767],
                    [-88.188306, 42.669709],
                    [-88.188173, 42.611456],
                    [-88.305891, 42.610817000000004],
                    [-88.306384, 42.842095],
                ],
            ],
        },
        properties: {
            CSAFP: '376',
            CBSAFP: '39540',
            AFFGEOID: '310M600US39540',
            GEOID: '39540',
            NAME: 'Racine, WI',
            NAMELSAD: 'Racine, WI Metro Area',
            LSAD: 'M1',
            ALAND: 861537609,
            AWATER: 1190103013,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.223814, 44.584746, -88.242687, 45.118027],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.223814, 45.029246],
                    [-88.98168, 45.028917],
                    [-88.982184, 45.118027],
                    [-88.641159, 45.117346],
                    [-88.483732, 45.116887],
                    [-88.489149, 44.855445],
                    [-88.24821300000002, 44.852739],
                    [-88.242687, 44.679631],
                    [-88.25226, 44.667427],
                    [-88.245204, 44.584746],
                    [-88.606083, 44.590515],
                    [-88.605159, 44.678331],
                    [-89.223745, 44.681365],
                    [-89.223814, 45.029246],
                ],
            ],
        },
        properties: {
            CSAFP: '267',
            CBSAFP: '43020',
            AFFGEOID: '310M600US43020',
            GEOID: '43020',
            NAME: 'Shawano, WI',
            NAMELSAD: 'Shawano, WI Micro Area',
            LSAD: 'M2',
            ALAND: 3239659315,
            AWATER: 60905538,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-108.22934300000001, 31.783599000000002, -107.296792996712, 32.605449],
            type: 'Polygon',
            coordinates: [
                [
                    [-108.22934300000001, 32.516837],
                    [-107.923766, 32.517416],
                    [-107.92399700000001, 32.604379],
                    [-107.608485, 32.605449],
                    [-107.299631, 32.60537],
                    [-107.296792996712, 31.783625337106702],
                    [-107.422246, 31.783599000000002],
                    [-108.208394, 31.783599000000002],
                    [-108.217134, 31.820475000000002],
                    [-108.217143, 31.864139],
                    [-108.216964, 32.079863],
                    [-108.22934300000001, 32.516837],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '19700',
            AFFGEOID: '310M600US19700',
            GEOID: '19700',
            NAME: 'Deming, NM',
            NAMELSAD: 'Deming, NM Micro Area',
            LSAD: 'M2',
            ALAND: 7679776933,
            AWATER: 433660,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-77.913844, 40.365228, -77.287941, 40.846937],
            type: 'Polygon',
            coordinates: [
                [
                    [-77.913844, 40.398679],
                    [-77.816513, 40.500074],
                    [-77.841827, 40.550035],
                    [-77.68708, 40.67675],
                    [-77.681334, 40.729758],
                    [-77.652574, 40.744921],
                    [-77.36418, 40.846937],
                    [-77.356628, 40.807334],
                    [-77.354097, 40.701667],
                    [-77.287941, 40.693595],
                    [-77.490021, 40.614792],
                    [-77.497506, 40.58174],
                    [-77.75212, 40.378545],
                    [-77.856914, 40.365228],
                    [-77.913844, 40.398679],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '30380',
            AFFGEOID: '310M600US30380',
            GEOID: '30380',
            NAME: 'Lewistown, PA',
            NAMELSAD: 'Lewistown, PA Micro Area',
            LSAD: 'M2',
            ALAND: 1064602254,
            AWATER: 9507394,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.136787, 33.676727, -89.506872, 33.897957],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.136491, 33.809319],
                    [-89.92629400000001, 33.823854],
                    [-89.926099, 33.897957],
                    [-89.76905, 33.867334],
                    [-89.507353, 33.86746900000001],
                    [-89.507135, 33.721819],
                    [-89.506872, 33.677605],
                    [-89.78689, 33.676727],
                    [-90.127775, 33.676956],
                    [-90.136787, 33.721648],
                    [-90.136491, 33.809319],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '24980',
            AFFGEOID: '310M600US24980',
            GEOID: '24980',
            NAME: 'Grenada, MS',
            NAMELSAD: 'Grenada, MS Micro Area',
            LSAD: 'M2',
            ALAND: 1093291104,
            AWATER: 70659566,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.905778, 36.867133, -84.289076, 37.355729],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.901112, 37.116297],
                    [-84.72071200000002, 37.238328],
                    [-84.556461, 37.355729],
                    [-84.502932, 37.328801],
                    [-84.303191, 37.166118],
                    [-84.289076, 37.15174],
                    [-84.303016, 37.038396],
                    [-84.358024, 36.9594],
                    [-84.416816, 36.94692],
                    [-84.578158, 36.867133],
                    [-84.679165, 36.980914],
                    [-84.770172, 36.957735],
                    [-84.835712, 36.997612],
                    [-84.905778, 37.047187],
                    [-84.901112, 37.116297],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '43700',
            AFFGEOID: '310M600US43700',
            GEOID: '43700',
            NAME: 'Somerset, KY',
            NAMELSAD: 'Somerset, KY Micro Area',
            LSAD: 'M2',
            ALAND: 1705275603,
            AWATER: 48545539,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-77.471085, 39.7200676723526, -76.954932, 40.069945],
            type: 'Polygon',
            coordinates: [
                [
                    [-77.471085, 39.944077],
                    [-77.40360600000001, 39.994478],
                    [-77.177403, 40.037434],
                    [-77.137425, 40.069945],
                    [-77.018648, 40.013947],
                    [-76.966202, 39.935192],
                    [-76.954932, 39.85685],
                    [-77.000312, 39.831219],
                    [-76.9993180263332, 39.7200676723526],
                    [-77.2170237552374, 39.7202172483854],
                    [-77.23995, 39.720233],
                    [-77.4591384979573, 39.7202291767892],
                    [-77.471085, 39.944077],
                ],
            ],
        },
        properties: {
            CSAFP: '276',
            CBSAFP: '23900',
            AFFGEOID: '310M600US23900',
            GEOID: '23900',
            NAME: 'Gettysburg, PA',
            NAMELSAD: 'Gettysburg, PA Metro Area',
            LSAD: 'M1',
            ALAND: 1343400759,
            AWATER: 7771555,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.194084, 41.264209, -84.8037035726169, 41.5301378133882],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.194084, 41.526437],
                    [-84.8049581562396, 41.5301378133882],
                    [-84.8042532576115, 41.4260497115499],
                    [-84.804133, 41.408292],
                    [-84.8037035726169, 41.2712583305706],
                    [-85.192094, 41.264209],
                    [-85.194084, 41.526437],
                ],
            ],
        },
        properties: {
            CSAFP: '258',
            CBSAFP: '12140',
            AFFGEOID: '310M600US12140',
            GEOID: '12140',
            NAME: 'Auburn, IN',
            NAMELSAD: 'Auburn, IN Micro Area',
            LSAD: 'M2',
            ALAND: 939731961,
            AWATER: 2657419,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.312404, 43.145291, -89.599357, 43.641049],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.312404, 43.640988],
                    [-89.785809, 43.641049],
                    [-89.732238, 43.571826],
                    [-89.599357, 43.558041],
                    [-89.60073, 43.380666],
                    [-89.677613, 43.361197],
                    [-89.72046300000001, 43.29308400000001],
                    [-89.731738, 43.252203],
                    [-89.83813500000001, 43.206057],
                    [-90.000123, 43.194624],
                    [-90.05866, 43.145291],
                    [-90.193814, 43.164464],
                    [-90.191938, 43.380083],
                    [-90.191964, 43.554996],
                    [-90.311069, 43.553991],
                    [-90.312404, 43.640988],
                ],
            ],
        },
        properties: {
            CSAFP: '357',
            CBSAFP: '12660',
            AFFGEOID: '310M600US12660',
            GEOID: '12660',
            NAME: 'Baraboo, WI',
            NAMELSAD: 'Baraboo, WI Micro Area',
            LSAD: 'M2',
            ALAND: 2153686013,
            AWATER: 45692969,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.4597924354137, 29.622432, -81.2104095965538, 30.827437000000003],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.45958100000001, 30.584264],
                    [-82.4189841121785, 30.5809166697315],
                    [-82.218607, 30.564395],
                    [-82.229427, 30.520814000000005],
                    [-82.200965, 30.474427],
                    [-82.210318, 30.424576999999996],
                    [-82.180043, 30.36861],
                    [-82.143306, 30.363378],
                    [-82.09470900000001, 30.360766],
                    [-82.0509829816809, 30.3683677759449],
                    [-82.04076600000002, 30.370144000000003],
                    [-82.042011, 30.403253],
                    [-82.028232, 30.447384999999997],
                    [-82.018381, 30.531175999999995],
                    [-82.01572800000001, 30.601698000000003],
                    [-82.049529, 30.655544000000003],
                    [-82.041834, 30.692374000000004],
                    [-82.032667, 30.750674],
                    [-81.994992, 30.786073999999996],
                    [-81.943187, 30.827437000000003],
                    [-81.9059784394064, 30.821407341501995],
                    [-81.902354, 30.82082],
                    [-81.868624, 30.792756],
                    [-81.808543, 30.790015999999998],
                    [-81.763384, 30.773821],
                    [-81.732238, 30.749635],
                    [-81.668283, 30.744644],
                    [-81.633273, 30.729602999999997],
                    [-81.56171, 30.715597],
                    [-81.52828100000002, 30.723359],
                    [-81.507218, 30.722936000000004],
                    [-81.444124, 30.709713999999998],
                    [-81.42742, 30.69802],
                    [-81.443099, 30.600938],
                    [-81.434064, 30.522569000000004],
                    [-81.4289547376433, 30.5061830741652],
                    [-81.42601, 30.496739000000005],
                    [-81.410809, 30.482039000000004],
                    [-81.39640700000001, 30.340040000000002],
                    [-81.37437612802331, 30.252931],
                    [-81.288955, 29.915180000000003],
                    [-81.270442, 29.883106],
                    [-81.2619334605748, 29.822123486676105],
                    [-81.256711, 29.784693],
                    [-81.2104095965538, 29.6706408057043],
                    [-81.324059, 29.62561],
                    [-81.52366, 29.622432],
                    [-81.524804, 29.721586000000002],
                    [-81.52523, 29.759497000000003],
                    [-81.581207, 29.840175999999996],
                    [-81.81243, 29.83649],
                    [-81.90926, 29.793559999999996],
                    [-81.939427, 29.747497000000003],
                    [-82.049244, 29.71867],
                    [-82.046112, 29.747129000000005],
                    [-82.049425, 30.143136],
                    [-82.14257800000001, 30.143117],
                    [-82.458364, 30.136449],
                    [-82.458352, 30.164595000000002],
                    [-82.4597924354137, 30.5842765283062],
                    [-82.45958100000001, 30.584264],
                ],
            ],
        },
        properties: {
            CSAFP: '300',
            CBSAFP: '27260',
            AFFGEOID: '310M600US27260',
            GEOID: '27260',
            NAME: 'Jacksonville, FL',
            NAMELSAD: 'Jacksonville, FL Metro Area',
            LSAD: 'M1',
            ALAND: 8292632119,
            AWATER: 1286263467,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.565748, 26.253069, -80.87971, 26.958919],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.565748, 26.769601],
                    [-80.945319, 26.769099],
                    [-80.885639, 26.958919],
                    [-80.881285, 26.333806],
                    [-80.87971, 26.259308],
                    [-81.268236, 26.253069],
                    [-81.27176800000001, 26.517069],
                    [-81.563763, 26.513331],
                    [-81.565103, 26.600333],
                    [-81.565748, 26.769601],
                ],
            ],
        },
        properties: {
            CSAFP: '163',
            CBSAFP: '17500',
            AFFGEOID: '310M600US17500',
            GEOID: '17500',
            NAME: 'Clewiston, FL',
            NAMELSAD: 'Clewiston, FL Micro Area',
            LSAD: 'M2',
            ALAND: 2993896406,
            AWATER: 86578552,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-75.649412, 40.814746, -74.9923859951822, 41.252101],
            type: 'Polygon',
            coordinates: [
                [
                    [-75.600736, 41.161497],
                    [-75.543054, 41.224051],
                    [-75.505607, 41.232539],
                    [-75.359184, 41.239206],
                    [-75.129721, 41.252101],
                    [-75.156507, 41.150341],
                    [-74.9923859951822, 41.0930282469934],
                    [-75.01527100000001, 41.061215],
                    [-75.0523586008006, 41.0326279973019],
                    [-75.070532, 41.01862],
                    [-75.133086, 40.98017900000001],
                    [-75.1232539442085, 40.9653055343877],
                    [-75.284039, 40.86604],
                    [-75.474193, 40.814746],
                    [-75.517953, 40.878192],
                    [-75.57459, 40.95132300000001],
                    [-75.49599, 40.987181],
                    [-75.649412, 41.122304],
                    [-75.600736, 41.161497],
                ],
            ],
        },
        properties: {
            CSAFP: '408',
            CBSAFP: '20700',
            AFFGEOID: '310M600US20700',
            GEOID: '20700',
            NAME: 'East Stroudsburg, PA',
            NAMELSAD: 'East Stroudsburg, PA Metro Area',
            LSAD: 'M1',
            ALAND: 1575634620,
            AWATER: 22676290,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.118894, 34.200006, -85.5130442292733, 34.8604354744776],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.118894, 34.403845],
                    [-86.057712, 34.475994],
                    [-86.01559400000001, 34.481639],
                    [-85.785471, 34.624584],
                    [-85.5828123337853, 34.8604354744776],
                    [-85.561424, 34.750079],
                    [-85.5344052578154, 34.6237903384358],
                    [-85.526894818161, 34.5886856927335],
                    [-85.5130442292733, 34.5239464713237],
                    [-85.559282, 34.502255],
                    [-85.627512, 34.39592],
                    [-85.774165, 34.259094],
                    [-85.843801, 34.244595],
                    [-85.843617, 34.200006],
                    [-86.106086, 34.200756],
                    [-86.118894, 34.403845],
                ],
            ],
        },
        properties: {
            CSAFP: '497',
            CBSAFP: '22840',
            AFFGEOID: '310M600US22840',
            GEOID: '22840',
            NAME: 'Fort Payne, AL',
            NAMELSAD: 'Fort Payne, AL Micro Area',
            LSAD: 'M2',
            ALAND: 2012620262,
            AWATER: 4107136,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.397374, 40.643069, -83.880063, 40.920429],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.397374, 40.815941],
                    [-84.340531, 40.859099],
                    [-84.109586, 40.860994],
                    [-84.109516, 40.90473],
                    [-83.880423, 40.920429],
                    [-83.880063, 40.819919],
                    [-83.880194, 40.64469],
                    [-84.107787, 40.643069],
                    [-84.222799, 40.685957],
                    [-84.396778, 40.684926000000004],
                    [-84.397374, 40.815941],
                ],
            ],
        },
        properties: {
            CSAFP: '338',
            CBSAFP: '30620',
            AFFGEOID: '310M600US30620',
            GEOID: '30620',
            NAME: 'Lima, OH',
            NAMELSAD: 'Lima, OH Metro Area',
            LSAD: 'M1',
            ALAND: 1042587389,
            AWATER: 11152061,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.407203, 34.389172, -92.79318900000001, 34.773242],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.40224500000001, 34.685231],
                    [-93.394402, 34.743393],
                    [-93.394487, 34.77242],
                    [-93.287785, 34.773242],
                    [-93.075178, 34.771892],
                    [-92.969317, 34.772263],
                    [-92.979978, 34.637249],
                    [-92.875394, 34.635196],
                    [-92.808028, 34.590406],
                    [-92.79318900000001, 34.503573],
                    [-92.915064, 34.506132],
                    [-92.948085, 34.389172],
                    [-93.407203, 34.397447],
                    [-93.40224500000001, 34.685231],
                ],
            ],
        },
        properties: {
            CSAFP: '284',
            CBSAFP: '26300',
            AFFGEOID: '310M600US26300',
            GEOID: '26300',
            NAME: 'Hot Springs, AR',
            NAMELSAD: 'Hot Springs, AR Metro Area',
            LSAD: 'M1',
            ALAND: 1754999327,
            AWATER: 147452177,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.635712, 33.443716, -79.87562, 34.168696],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.616186, 34.099605],
                    [-80.618542, 34.109176000000005],
                    [-80.58011, 34.099949],
                    [-80.479857, 34.168696],
                    [-80.481318, 34.112622],
                    [-80.321721, 34.080356],
                    [-80.228344, 33.995178],
                    [-80.18042600000001, 33.952083],
                    [-80.000594, 34.047877],
                    [-79.903658, 34.002245],
                    [-79.893517, 33.988017],
                    [-79.974601, 33.946527],
                    [-79.945948, 33.885997],
                    [-79.87562, 33.885245],
                    [-79.915305, 33.818648],
                    [-79.995638, 33.767906],
                    [-79.974382, 33.72159],
                    [-80.101697, 33.496891],
                    [-80.150003, 33.448463],
                    [-80.222267, 33.443716],
                    [-80.393145, 33.445339],
                    [-80.496273, 33.55848100000001],
                    [-80.540514, 33.629032],
                    [-80.534085, 33.643911],
                    [-80.627531, 33.731212],
                    [-80.620107, 33.743237],
                    [-80.605638, 33.757476],
                    [-80.598288, 33.804476],
                    [-80.615676, 33.814514],
                    [-80.633545, 33.85268],
                    [-80.601036, 34.03593],
                    [-80.635712, 34.084886],
                    [-80.616186, 34.099605],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '44940',
            AFFGEOID: '310M600US44940',
            GEOID: '44940',
            NAME: 'Sumter, SC',
            NAMELSAD: 'Sumter, SC Metro Area',
            LSAD: 'M1',
            ALAND: 3295201852,
            AWATER: 273144019,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-121.52307, 46.040858, -119.86582899999999, 47.088731],
            type: 'Polygon',
            coordinates: [
                [
                    [-121.456447, 46.923577],
                    [-121.37996100000001, 47.087248],
                    [-121.379682, 47.087495],
                    [-121.280716, 47.088731],
                    [-121.09005400000001, 46.991007],
                    [-121.02662, 46.911308000000005],
                    [-120.634562, 46.91213],
                    [-120.63398100000002, 46.825776],
                    [-120.50860500000002, 46.824862],
                    [-120.51, 46.737946],
                    [-119.973036, 46.737126],
                    [-119.904046, 46.638101],
                    [-119.874042, 46.628283],
                    [-119.86582899999999, 46.040858],
                    [-120.801295, 46.041014000000004],
                    [-121.52232100000002, 46.044006],
                    [-121.522324, 46.388224],
                    [-121.393759, 46.39004],
                    [-121.45013999999999, 46.503526],
                    [-121.353562, 46.711506],
                    [-121.455218, 46.783797],
                    [-121.52307, 46.872783],
                    [-121.456447, 46.923577],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '49420',
            AFFGEOID: '310M600US49420',
            GEOID: '49420',
            NAME: 'Yakima, WA',
            NAMELSAD: 'Yakima, WA Metro Area',
            LSAD: 'M1',
            ALAND: 11122734368,
            AWATER: 41188783,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-102.09624, 46.284414, -100.075229, 47.327685],
            type: 'Polygon',
            coordinates: [
                [
                    [-102.095904, 46.981329],
                    [-101.764392, 46.981306],
                    [-101.764647, 47.241518],
                    [-101.257633, 47.241343],
                    [-101.257031, 47.264895],
                    [-101.034623, 47.286393],
                    [-100.977629, 47.249992],
                    [-100.96544, 47.157134],
                    [-100.74937, 47.157245],
                    [-100.749334, 47.327685],
                    [-100.67321, 47.327646],
                    [-100.113261, 47.327262],
                    [-100.075229, 46.981282],
                    [-100.081198, 46.633396],
                    [-100.662015, 46.6346],
                    [-100.561035, 46.587847],
                    [-100.590199, 46.42789400000001],
                    [-100.72126100000001, 46.390084],
                    [-100.88455200000001, 46.409096000000005],
                    [-101.025691, 46.284414],
                    [-101.04852700000002, 46.37145],
                    [-101.298989, 46.371404],
                    [-101.298823, 46.630431],
                    [-101.718462, 46.631283],
                    [-101.718228, 46.717834],
                    [-102.09624, 46.717772],
                    [-102.095904, 46.981329],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '13900',
            AFFGEOID: '310M600US13900',
            GEOID: '13900',
            NAME: 'Bismarck, ND',
            NAMELSAD: 'Bismarck, ND Metro Area',
            LSAD: 'M1',
            ALAND: 11087702752,
            AWATER: 165098564,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.073861, 40.56824, -84.80211900000002, 40.922568713921],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.073861, 40.917823],
                    [-84.8026702061534, 40.922568713921],
                    [-84.80211900000002, 40.728163],
                    [-84.8021190322638, 40.7281459368726],
                    [-84.8024138785794, 40.5722128806467],
                    [-85.068479, 40.56824],
                    [-85.073861, 40.917823],
                ],
            ],
        },
        properties: {
            CSAFP: '258',
            CBSAFP: '19540',
            AFFGEOID: '310M600US19540',
            GEOID: '19540',
            NAME: 'Decatur, IN',
            NAMELSAD: 'Decatur, IN Micro Area',
            LSAD: 'M2',
            ALAND: 877815496,
            AWATER: 2693924,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.314471, 39.059057, -91.409033, 39.347795],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.310028, 39.347795],
                    [-91.874651, 39.337794],
                    [-91.717968, 39.339122],
                    [-91.718467, 39.324505],
                    [-91.438235, 39.318716],
                    [-91.409033, 39.142542],
                    [-91.629598, 39.147853],
                    [-91.633998, 39.059057],
                    [-92.110387, 39.064204],
                    [-92.10437400000002, 39.239809],
                    [-92.314471, 39.246454],
                    [-92.310028, 39.347795],
                ],
            ],
        },
        properties: {
            CSAFP: '190',
            CBSAFP: '33020',
            AFFGEOID: '310M600US33020',
            GEOID: '33020',
            NAME: 'Mexico, MO',
            NAMELSAD: 'Mexico, MO Micro Area',
            LSAD: 'M2',
            ALAND: 1792918159,
            AWATER: 11843439,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-74.9281105690914, 40.138254, -74.484962, 40.424156],
            type: 'Polygon',
            coordinates: [
                [
                    [-74.9281105690914, 40.3398284476893],
                    [-74.856262, 40.346696],
                    [-74.748147, 40.424156],
                    [-74.722061, 40.375205],
                    [-74.622682, 40.384336],
                    [-74.62024, 40.373749],
                    [-74.622462, 40.3248],
                    [-74.484962, 40.253325],
                    [-74.543268, 40.216692],
                    [-74.614576, 40.182383],
                    [-74.58783500000001, 40.138254],
                    [-74.710099, 40.181283],
                    [-74.710782, 40.168045],
                    [-74.704177, 40.168479],
                    [-74.706269, 40.161793],
                    [-74.71847, 40.150029],
                    [-74.7233792971515, 40.1528955462317],
                    [-74.721604, 40.15381],
                    [-74.7484976572578, 40.184908614104],
                    [-74.760605, 40.198909],
                    [-74.823907, 40.241508],
                    [-74.856508, 40.277407],
                    [-74.90331, 40.315607],
                    [-74.9281105690914, 40.3398284476893],
                ],
            ],
        },
        properties: {
            CSAFP: '408',
            CBSAFP: '45940',
            AFFGEOID: '310M600US45940',
            GEOID: '45940',
            NAME: 'Trenton-Princeton, NJ',
            NAMELSAD: 'Trenton-Princeton, NJ Metro Area',
            LSAD: 'M1',
            ALAND: 581395408,
            AWATER: 11337168,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.87146, 38.036503, -76.320136, 38.512949],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.87146, 38.389937],
                    [-76.76829, 38.512949],
                    [-76.674118, 38.499622],
                    [-76.608373, 38.424445],
                    [-76.501475, 38.372497],
                    [-76.453453, 38.305057],
                    [-76.400194624698, 38.3198719439614],
                    [-76.402894, 38.311402],
                    [-76.374481, 38.296348],
                    [-76.392668, 38.23966300000001],
                    [-76.353516, 38.178135],
                    [-76.320136, 38.138339],
                    [-76.330794, 38.099331],
                    [-76.322093, 38.036503],
                    [-76.37179000000002, 38.079565],
                    [-76.430425, 38.119383],
                    [-76.481036, 38.115873],
                    [-76.54038, 38.152991],
                    [-76.590637, 38.214212],
                    [-76.673462, 38.234401],
                    [-76.740055, 38.235227],
                    [-76.80594900000001, 38.252275],
                    [-76.8270361292819, 38.2583001006141],
                    [-76.87146, 38.389937],
                ],
            ],
        },
        properties: {
            CSAFP: '548',
            CBSAFP: '15680',
            AFFGEOID: '310M600US15680',
            GEOID: '15680',
            NAME: 'California-Lexington Park, MD',
            NAMELSAD: 'California-Lexington Park, MD Metro Area',
            LSAD: 'M1',
            ALAND: 928809940,
            AWATER: 1050592561,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-121.34705395600402, 34.897576, -119.47249399999998, 35.7951862038146],
            type: 'Polygon',
            coordinates: [
                [
                    [-121.34705395600402, 35.7951862038146],
                    [-120.75065, 35.791131],
                    [-120.21397899999998, 35.789276],
                    [-120.19436900000001, 35.789363],
                    [-120.193988, 35.614389],
                    [-120.08618, 35.614397],
                    [-120.086766, 35.526432],
                    [-119.91365899999998, 35.439262],
                    [-119.880045, 35.439133],
                    [-119.880356, 35.351124],
                    [-119.80920999999998, 35.35094],
                    [-119.809407, 35.263693],
                    [-119.666585, 35.262635],
                    [-119.66730400000002, 35.174982],
                    [-119.553819, 35.180123],
                    [-119.561752, 35.087831],
                    [-119.472552, 35.076669],
                    [-119.47249399999998, 34.901378],
                    [-119.535757, 34.897576],
                    [-119.672963, 34.973375],
                    [-119.74556600000001, 34.973676],
                    [-119.928419, 35.059794],
                    [-119.980122, 35.057573],
                    [-120.08208400000001, 35.114678],
                    [-120.167616, 35.075297],
                    [-120.188383, 35.030378000000006],
                    [-120.33454799999998, 35.006315],
                    [-120.30182699999999, 34.905571],
                    [-120.435473, 34.986759],
                    [-120.496222, 34.993155],
                    [-120.65030521184002, 34.9751663022687],
                    [-120.63356999999999, 35.033085],
                    [-120.62958300000001, 35.078362],
                    [-120.63578699999998, 35.123805],
                    [-120.67507399999998, 35.153061],
                    [-120.714185, 35.175998],
                    [-120.75608599999998, 35.160459],
                    [-120.846674, 35.204429],
                    [-120.89679, 35.247877],
                    [-120.87956999999999, 35.294184],
                    [-120.862133, 35.360763],
                    [-120.884757, 35.430196],
                    [-120.95586299999998, 35.453743],
                    [-121.003359, 35.46071],
                    [-121.11424, 35.57172],
                    [-121.16671199999999, 35.635399],
                    [-121.272322, 35.666711],
                    [-121.314632, 35.71331],
                    [-121.332449, 35.783106],
                    [-121.34705395600402, 35.7951862038146],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '42020',
            AFFGEOID: '310M600US42020',
            GEOID: '42020',
            NAME: 'San Luis Obispo-Paso Robles, CA',
            NAMELSAD: 'San Luis Obispo-Paso Robles, CA Metro Area',
            LSAD: 'M1',
            ALAND: 8549176813,
            AWATER: 815614291,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.800477, 29.828473000000002, -82.458352, 30.5978892906418],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.800477, 29.932126],
                    [-82.761519, 29.965516999999995],
                    [-82.793975, 29.986202],
                    [-82.794594, 30.337024000000003],
                    [-82.788503, 30.324013],
                    [-82.738443, 30.325783],
                    [-82.689922, 30.333109],
                    [-82.647198, 30.394797000000004],
                    [-82.726583, 30.559485999999996],
                    [-82.68952979847282, 30.5978892906418],
                    [-82.58400518641952, 30.5916365790876],
                    [-82.4597924354137, 30.5842765283062],
                    [-82.458352, 30.164595000000002],
                    [-82.458364, 30.136449],
                    [-82.575991, 29.990856999999995],
                    [-82.529705, 29.940880999999997],
                    [-82.641008, 29.828473000000002],
                    [-82.658554, 29.830143999999997],
                    [-82.768445, 29.897007000000002],
                    [-82.770379, 29.928109],
                    [-82.800477, 29.932126],
                ],
            ],
        },
        properties: {
            CSAFP: '264',
            CBSAFP: '29380',
            AFFGEOID: '310M600US29380',
            GEOID: '29380',
            NAME: 'Lake City, FL',
            NAMELSAD: 'Lake City, FL Micro Area',
            LSAD: 'M2',
            ALAND: 2065197126,
            AWATER: 10207158,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.341347, 37.8430541504629, -81.696348, 38.849073],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.341347, 38.319521],
                    [-83.23068, 38.339286],
                    [-83.237181, 38.426246],
                    [-83.166419, 38.503965],
                    [-83.116177, 38.539251],
                    [-83.024075, 38.683306],
                    [-83.0303349312311, 38.7168676766561],
                    [-83.011816, 38.730057],
                    [-82.943147, 38.74328],
                    [-82.889193, 38.756076],
                    [-82.8822845498777, 38.741607822612],
                    [-82.871192, 38.718377],
                    [-82.869592, 38.678177],
                    [-82.851314, 38.604334],
                    [-82.8115423846504, 38.57236958956191],
                    [-82.740383, 38.597167],
                    [-82.763895, 38.679974],
                    [-82.706823, 38.677495],
                    [-82.662886, 38.747977],
                    [-82.650039, 38.849073],
                    [-82.575583, 38.844478],
                    [-82.582683, 38.779077],
                    [-82.48357900000002, 38.772278],
                    [-82.472478, 38.682279],
                    [-82.354468, 38.67607],
                    [-82.361674, 38.585182],
                    [-82.2821333440281, 38.57986079640351],
                    [-82.27427, 38.593683],
                    [-82.218967, 38.591683],
                    [-82.055127, 38.474547],
                    [-82.064562, 38.607985],
                    [-81.955791, 38.657355],
                    [-81.772843, 38.680843],
                    [-81.73987700000002, 38.675321],
                    [-81.696348, 38.626427],
                    [-81.750547, 38.591014],
                    [-81.723066, 38.467772],
                    [-81.900222, 38.398572],
                    [-81.915197, 38.325597],
                    [-81.997406, 38.266605],
                    [-82.048589, 38.290138],
                    [-82.047128, 38.374432],
                    [-82.220797, 38.310411],
                    [-82.264849, 38.229199],
                    [-82.283316, 38.141936],
                    [-82.192314, 38.046314],
                    [-82.306391, 37.94364],
                    [-82.403743, 37.862894],
                    [-82.3984648162589, 37.8430541504629],
                    [-82.41869, 37.872375],
                    [-82.487556, 37.916975],
                    [-82.479420123223, 37.9385625246981],
                    [-82.464987, 37.976859000000005],
                    [-82.549407, 38.063063],
                    [-82.626182, 38.134835],
                    [-82.59886400000002, 38.201007],
                    [-82.5846938141256, 38.2405129933697],
                    [-82.614304, 38.266078],
                    [-82.794206, 38.243772],
                    [-82.8232, 38.208264],
                    [-82.924805, 38.175114],
                    [-83.053081, 38.189545],
                    [-83.177781, 38.266963],
                    [-83.244306, 38.19238],
                    [-83.30114, 38.209703],
                    [-83.341347, 38.319521],
                ],
            ],
        },
        properties: {
            CSAFP: '170',
            CBSAFP: '26580',
            AFFGEOID: '310M600US26580',
            GEOID: '26580',
            NAME: 'Huntington-Ashland, WV-KY-OH',
            NAMELSAD: 'Huntington-Ashland, WV-KY-OH Metro Area',
            LSAD: 'M1',
            ALAND: 6474778867,
            AWATER: 94403680,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.5545758543455, 30.859362000000004, -92.82387, 31.364491],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.530936, 30.924534],
                    [-93.549841, 30.967118],
                    [-93.539526, 31.008498],
                    [-93.531219, 31.051678000000003],
                    [-93.540278, 31.128868],
                    [-93.535097, 31.185614000000005],
                    [-93.5524973075023, 31.184820023446804],
                    [-93.535414, 31.276402000000004],
                    [-93.442554, 31.276716],
                    [-93.390495, 31.364147],
                    [-93.236643, 31.364491],
                    [-92.991988, 31.360473000000002],
                    [-92.98146, 31.346864],
                    [-92.964258, 31.332388],
                    [-92.879715, 31.317439],
                    [-92.829525, 31.252055],
                    [-92.82387, 30.892071],
                    [-92.838273, 30.877183999999996],
                    [-92.978673, 30.878136],
                    [-93.426486, 30.882698],
                    [-93.478425, 30.859362000000004],
                    [-93.5545758543455, 30.877469501861103],
                    [-93.530936, 30.924534],
                ],
            ],
        },
        properties: {
            CSAFP: '217',
            CBSAFP: '22860',
            AFFGEOID: '310M600US22860',
            GEOID: '22860',
            NAME: 'Fort Polk South, LA',
            NAMELSAD: 'Fort Polk South, LA Micro Area',
            LSAD: 'M2',
            ALAND: 3436185697,
            AWATER: 35140841,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.773922, 41.7235468017856, -83.1949523116895, 42.089952],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.773922, 42.08243],
                    [-83.539396, 42.085598000000005],
                    [-83.284155, 42.089952],
                    [-83.1949523116895, 42.033107926811006],
                    [-83.216897, 41.988561],
                    [-83.269521, 41.939042],
                    [-83.326024, 41.924961],
                    [-83.341557, 41.879956],
                    [-83.39622, 41.852965],
                    [-83.441668, 41.808646],
                    [-83.424076, 41.740738],
                    [-83.453832, 41.732647],
                    [-83.5855418421088, 41.7287720285128],
                    [-83.763038, 41.72355],
                    [-83.7631498513652, 41.7235468017856],
                    [-83.773922, 42.08243],
                ],
            ],
        },
        properties: {
            CSAFP: '220',
            CBSAFP: '33780',
            AFFGEOID: '310M600US33780',
            GEOID: '33780',
            NAME: 'Monroe, MI',
            NAMELSAD: 'Monroe, MI Metro Area',
            LSAD: 'M1',
            ALAND: 1422818203,
            AWATER: 338203448,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.9446168883705, 33.397826, -96.3796608259521, 33.947219],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.9446168883705, 33.945013250454],
                    [-96.934343303133, 33.9455889292528],
                    [-96.905253, 33.947219],
                    [-96.897194, 33.902954],
                    [-96.850593, 33.847211],
                    [-96.794276, 33.868886],
                    [-96.776766, 33.841976],
                    [-96.71242200000002, 33.831633],
                    [-96.682103, 33.876645],
                    [-96.659896, 33.916666],
                    [-96.5946743073771, 33.8830186573983],
                    [-96.590112, 33.880665],
                    [-96.592926, 33.830916],
                    [-96.572937, 33.819098],
                    [-96.523863, 33.818114],
                    [-96.502286, 33.77346],
                    [-96.436455, 33.78005],
                    [-96.403507, 33.746289],
                    [-96.3796608259521, 33.715530908566],
                    [-96.384602, 33.397826],
                    [-96.83411, 33.405498],
                    [-96.85369, 33.413613],
                    [-96.94386, 33.41641],
                    [-96.9446168883705, 33.945013250454],
                ],
            ],
        },
        properties: {
            CSAFP: '206',
            CBSAFP: '43300',
            AFFGEOID: '310M600US43300',
            GEOID: '43300',
            NAME: 'Sherman-Denison, TX',
            NAMELSAD: 'Sherman-Denison, TX Metro Area',
            LSAD: 'M1',
            ALAND: 2416016690,
            AWATER: 120091746,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-117.802539, 33.425932, -114.139055, 35.8096292126029],
            type: 'Polygon',
            coordinates: [
                [
                    [-117.802539, 33.975551],
                    [-117.767654, 34.019386],
                    [-117.744342, 34.019852],
                    [-117.71117, 34.079536],
                    [-117.704356, 34.09529],
                    [-117.693563, 34.121627],
                    [-117.677155, 34.167377],
                    [-117.646374, 34.28917],
                    [-117.65523499999999, 34.397222],
                    [-117.66745899999998, 34.822528],
                    [-117.632151, 34.822271],
                    [-117.630076, 35.564254],
                    [-117.61651800000001, 35.680687],
                    [-117.65213600000001, 35.709858],
                    [-117.634283, 35.79726],
                    [-117.25101500000001, 35.795004],
                    [-116.277263, 35.793394],
                    [-115.735891, 35.79362],
                    [-115.648032019625, 35.8096292126029],
                    [-115.647683, 35.809358],
                    [-115.404537, 35.617605],
                    [-115.303743, 35.538207],
                    [-115.160068, 35.424129],
                    [-115.043812, 35.332012],
                    [-114.80424899999998, 35.139689],
                    [-114.63348700000002, 35.001857],
                    [-114.629769, 34.94304],
                    [-114.63438199999999, 34.87289],
                    [-114.576452, 34.8153],
                    [-114.490971, 34.724848],
                    [-114.465246, 34.691202],
                    [-114.422382, 34.580711],
                    [-114.378223, 34.516521],
                    [-114.378852, 34.450376],
                    [-114.335372, 34.450038],
                    [-114.26431700000002, 34.401329],
                    [-114.172845, 34.344979],
                    [-114.14093, 34.305919],
                    [-114.14081715128, 34.3031275138817],
                    [-114.139055, 34.259538],
                    [-114.17805, 34.23996900000001],
                    [-114.229715, 34.186928],
                    [-114.292806, 34.166725],
                    [-114.348052, 34.134458],
                    [-114.405941, 34.11154],
                    [-114.428026, 34.092787],
                    [-114.43009113852, 34.0789314062795],
                    [-114.43550400000001, 34.042615],
                    [-114.454807, 34.010968],
                    [-114.509568, 33.957264],
                    [-114.534987, 33.928499],
                    [-114.508708, 33.90064],
                    [-114.505638, 33.864276],
                    [-114.520465, 33.827778],
                    [-114.504863, 33.760465],
                    [-114.496565, 33.719155],
                    [-114.504993, 33.693022],
                    [-114.525201, 33.661583],
                    [-114.529186, 33.60665],
                    [-114.524599, 33.552231],
                    [-114.59728300000002, 33.490653],
                    [-114.629146518189, 33.43354499999981],
                    [-114.830833, 33.430094],
                    [-115.467506, 33.426992],
                    [-116.08516499999999, 33.425932],
                    [-116.821826, 33.426873],
                    [-117.03086099999999, 33.426931],
                    [-117.08902400000001, 33.430286],
                    [-117.141574, 33.43261],
                    [-117.241271, 33.431993],
                    [-117.24166800000002, 33.448928],
                    [-117.250495, 33.451629],
                    [-117.25930799999999, 33.454513],
                    [-117.364272, 33.505025],
                    [-117.50972199999998, 33.505019],
                    [-117.51021000000001, 33.533999],
                    [-117.457937, 33.602189],
                    [-117.412987, 33.659045],
                    [-117.53399900000001, 33.710355],
                    [-117.67505300000002, 33.868725],
                    [-117.67301899999998, 33.871039],
                    [-117.783252, 33.946261],
                    [-117.802539, 33.975551],
                ],
            ],
        },
        properties: {
            CSAFP: '348',
            CBSAFP: '40140',
            AFFGEOID: '310M600US40140',
            GEOID: '40140',
            NAME: 'Riverside-San Bernardino-Ontario, CA',
            NAMELSAD: 'Riverside-San Bernardino-Ontario, CA Metro Area',
            LSAD: 'M1',
            ALAND: 70648050368,
            AWATER: 339387581,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.006551, 36.004966, -77.066707, 36.546164000000005],
            type: 'Polygon',
            coordinates: [
                [
                    [-77.911963, 36.382446],
                    [-77.899524, 36.504259],
                    [-77.8997741360467, 36.5448507330424],
                    [-77.7671039699267, 36.5454424093355],
                    [-77.749706, 36.54552],
                    [-77.2987701888118, 36.5460390108545],
                    [-77.190175, 36.546164000000005],
                    [-77.1643226037182, 36.5461528198479],
                    [-77.066707, 36.49709],
                    [-77.122959, 36.471218],
                    [-77.208468, 36.246578],
                    [-77.29825, 36.207708],
                    [-77.291519, 36.168342],
                    [-77.230275, 36.104999],
                    [-77.327569, 36.071361],
                    [-77.340038, 36.063998],
                    [-77.402614, 36.004966],
                    [-77.517646, 36.036775],
                    [-77.5407, 36.096085],
                    [-77.697956, 36.15315],
                    [-77.720742, 36.133884],
                    [-77.8864, 36.143994],
                    [-78.006551, 36.202633],
                    [-77.911963, 36.382446],
                ],
            ],
        },
        properties: {
            CSAFP: '468',
            CBSAFP: '40260',
            AFFGEOID: '310M600US40260',
            GEOID: '40260',
            NAME: 'Roanoke Rapids, NC',
            NAMELSAD: 'Roanoke Rapids, NC Micro Area',
            LSAD: 'M2',
            ALAND: 3264522272,
            AWATER: 52794265,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-120.541696, 36.769687, -119.02236300000001, 37.777986],
            type: 'Polygon',
            coordinates: [
                [
                    [-120.47669200000001, 37.09639],
                    [-120.227286, 37.16340000000001],
                    [-120.10638500000002, 37.167153],
                    [-120.052055, 37.183108],
                    [-119.76180900000001, 37.417114],
                    [-119.65119099999998, 37.417832],
                    [-119.65117200000002, 37.461351],
                    [-119.58422000000002, 37.494696],
                    [-119.583585, 37.560335],
                    [-119.30899500000001, 37.777986],
                    [-119.26897899999999, 37.73923],
                    [-119.124312, 37.733944],
                    [-119.02236300000001, 37.585737],
                    [-119.286658, 37.374943],
                    [-119.33518899999999, 37.310774],
                    [-119.33057300000002, 37.205791],
                    [-119.36275000000002, 37.167201],
                    [-119.43305999999998, 37.161516],
                    [-119.470844, 37.110548],
                    [-119.506733, 37.150554],
                    [-119.567234, 37.115946],
                    [-119.56297899999998, 37.064095],
                    [-119.60490800000001, 37.071016],
                    [-119.621116, 37.026605],
                    [-119.64748900000001, 37.043775],
                    [-119.65937700000002, 37.038938],
                    [-119.658611, 37.01333],
                    [-119.690779, 37.011987],
                    [-119.705356, 36.999794],
                    [-119.71318199999999, 36.991638],
                    [-119.81442100000001, 36.850138],
                    [-119.932338, 36.843323],
                    [-120.02777499999999, 36.814505],
                    [-120.057776, 36.82286],
                    [-120.22917900000002, 36.769687],
                    [-120.37025599999998, 36.78494],
                    [-120.45624600000001, 36.862863],
                    [-120.450341, 36.911172],
                    [-120.541696, 37.044505],
                    [-120.47669200000001, 37.09639],
                ],
            ],
        },
        properties: {
            CSAFP: '260',
            CBSAFP: '31460',
            AFFGEOID: '310M600US31460',
            GEOID: '31460',
            NAME: 'Madera, CA',
            NAMELSAD: 'Madera, CA Metro Area',
            LSAD: 'M1',
            ALAND: 5534591552,
            AWATER: 42271836,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.119748, 35.020682, -91.347257, 35.534311],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.111535, 35.362633],
                    [-91.852222, 35.35838400000001],
                    [-91.850032, 35.43654300000001],
                    [-91.795997, 35.45561],
                    [-91.793577, 35.534078],
                    [-91.745063, 35.534311],
                    [-91.582094, 35.530332],
                    [-91.584454, 35.442309],
                    [-91.347257, 35.439106],
                    [-91.358087, 35.40898],
                    [-91.438448, 35.368895],
                    [-91.42869200000001, 35.293141000000006],
                    [-91.382991, 35.248373],
                    [-91.389815, 35.199988],
                    [-91.458753, 35.155016],
                    [-91.465269, 35.089457],
                    [-91.58400500000002, 35.091584],
                    [-91.608725, 35.020682],
                    [-91.70111800000001, 35.062986],
                    [-91.802509, 35.030698],
                    [-91.902557, 35.027687],
                    [-92.013056, 35.076317],
                    [-92.119748, 35.06656],
                    [-92.111535, 35.362633],
                ],
            ],
        },
        properties: {
            CSAFP: '340',
            CBSAFP: '42620',
            AFFGEOID: '310M600US42620',
            GEOID: '42620',
            NAME: 'Searcy, AR',
            NAMELSAD: 'Searcy, AR Micro Area',
            LSAD: 'M2',
            ALAND: 2678251420,
            AWATER: 20554640,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-98.826144, 34.064269, -98.088978, 34.85534],
            type: 'Polygon',
            coordinates: [
                [
                    [-98.826008, 34.594413],
                    [-98.826041, 34.855122],
                    [-98.61981600000001, 34.85534],
                    [-98.09312700000001, 34.854988],
                    [-98.088978, 34.837657],
                    [-98.089097, 34.681163],
                    [-98.141799, 34.681158],
                    [-98.142148, 34.506994],
                    [-98.139293, 34.489279],
                    [-98.139227, 34.289824],
                    [-98.1384860257464, 34.1412060197111],
                    [-98.16912, 34.114171],
                    [-98.225282, 34.127245],
                    [-98.31875, 34.146421],
                    [-98.364023, 34.157109],
                    [-98.398441, 34.128456],
                    [-98.414426, 34.085074],
                    [-98.423533, 34.0819494760059],
                    [-98.475066, 34.064269],
                    [-98.5282, 34.094961],
                    [-98.577136, 34.148962],
                    [-98.6102002179226, 34.1561768671247],
                    [-98.60966, 34.33372],
                    [-98.662004, 34.333431],
                    [-98.661956, 34.405948],
                    [-98.661982, 34.50746],
                    [-98.826144, 34.50737],
                    [-98.826008, 34.594413],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '30020',
            AFFGEOID: '310M600US30020',
            GEOID: '30020',
            NAME: 'Lawton, OK',
            NAMELSAD: 'Lawton, OK Metro Area',
            LSAD: 'M1',
            ALAND: 4408054159,
            AWATER: 61648154,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.339076, 33.572664, -77.8854550755211, 34.371711],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.339076, 33.969722],
                    [-79.302341, 34.032219],
                    [-79.247574, 34.057269],
                    [-79.207787, 34.153877],
                    [-79.126681, 34.235362],
                    [-79.128165, 34.253652],
                    [-79.085709, 34.295276],
                    [-79.0712542010482, 34.2993130268419],
                    [-79.0712436122253, 34.2993039510346],
                    [-79.071169, 34.299240000000005],
                    [-78.81171, 34.081006],
                    [-78.6508712805333, 33.9450557205328],
                    [-78.640688, 34.009854],
                    [-78.557052, 34.061849],
                    [-78.545218, 34.145747],
                    [-78.515289, 34.165865],
                    [-78.422921, 34.139494],
                    [-78.374295, 34.204893],
                    [-78.254622, 34.216305],
                    [-78.16235, 34.357007],
                    [-78.137302, 34.371711],
                    [-78.029922, 34.331768],
                    [-77.956605, 34.234491],
                    [-77.8854550755211, 34.038240984853],
                    [-77.915536, 33.971723],
                    [-77.9348271350857, 33.9205485057973],
                    [-77.960172, 33.853315],
                    [-78.006765, 33.858704],
                    [-78.018689, 33.888289],
                    [-78.136952, 33.912178],
                    [-78.276147, 33.912364],
                    [-78.383964, 33.901946],
                    [-78.541087, 33.851112],
                    [-78.67226, 33.817587],
                    [-78.772737, 33.768511],
                    [-78.862931, 33.705654],
                    [-78.938076, 33.639826],
                    [-78.9951309899024, 33.572664],
                    [-79.101074, 33.572969],
                    [-79.137683, 33.66413],
                    [-79.190264, 33.705787],
                    [-79.260283, 33.814854],
                    [-79.256049, 33.872058],
                    [-79.312372, 33.945001],
                    [-79.339076, 33.969722],
                ],
            ],
        },
        properties: {
            CSAFP: '396',
            CBSAFP: '34820',
            AFFGEOID: '310M600US34820',
            GEOID: '34820',
            NAME: 'Myrtle Beach-Conway-North Myrtle Beach, SC-NC',
            NAMELSAD: 'Myrtle Beach-Conway-North Myrtle Beach, SC-NC Metro Area',
            LSAD: 'M1',
            ALAND: 5136967773,
            AWATER: 831060158,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-74.763303, 42.77297900000001, -74.083883, 43.047423],
            type: 'Polygon',
            coordinates: [
                [
                    [-74.74380500000001, 43.030741],
                    [-74.759895, 43.047423],
                    [-74.542367, 42.985530000000004],
                    [-74.488844, 42.985118],
                    [-74.097467, 42.982934],
                    [-74.096897, 42.979378000000004],
                    [-74.09298, 42.955868],
                    [-74.083883, 42.897354],
                    [-74.263314, 42.796534],
                    [-74.289446, 42.783805],
                    [-74.411384, 42.77649],
                    [-74.454911, 42.77297900000001],
                    [-74.648298, 42.829558],
                    [-74.763303, 42.863237],
                    [-74.74380500000001, 43.030741],
                ],
            ],
        },
        properties: {
            CSAFP: '104',
            CBSAFP: '11220',
            AFFGEOID: '310M600US11220',
            GEOID: '11220',
            NAME: 'Amsterdam, NY',
            NAMELSAD: 'Amsterdam, NY Micro Area',
            LSAD: 'M2',
            ALAND: 1044051406,
            AWATER: 18614750,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-106.058364, 36.013014, -105.200117, 36.995875],
            type: 'Polygon',
            coordinates: [
                [
                    [-106.038312, 36.325661],
                    [-105.9598, 36.354789],
                    [-105.938754, 36.462402],
                    [-105.981088, 36.713445],
                    [-105.97188900000002, 36.886247],
                    [-106.006316410798, 36.9953866288173],
                    [-105.997472, 36.995417],
                    [-105.718469683991, 36.995845927301],
                    [-105.716471, 36.995849],
                    [-105.533922, 36.995875],
                    [-105.41931, 36.995856],
                    [-105.251296, 36.995605],
                    [-105.220505200781, 36.9955632364865],
                    [-105.200117, 36.915628],
                    [-105.229372, 36.84771],
                    [-105.230632, 36.723784],
                    [-105.311842, 36.724471],
                    [-105.370665, 36.680687],
                    [-105.351385, 36.636905],
                    [-105.37774500000002, 36.552096],
                    [-105.318718, 36.491693],
                    [-105.356826, 36.449504],
                    [-105.327301, 36.40543],
                    [-105.341264, 36.261863],
                    [-105.409677, 36.236814],
                    [-105.41999200000001, 36.131704],
                    [-105.53038000000001, 36.013014],
                    [-105.735883, 36.100133],
                    [-105.856947, 36.230434],
                    [-106.058364, 36.296978],
                    [-106.038312, 36.325661],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '45340',
            AFFGEOID: '310M600US45340',
            GEOID: '45340',
            NAME: 'Taos, NM',
            NAMELSAD: 'Taos, NM Micro Area',
            LSAD: 'M2',
            ALAND: 5704119617,
            AWATER: 3383128,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.52728, 34.9870308587481, -85.863319, 35.70379],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.525306, 35.354718],
                    [-86.422338, 35.318846],
                    [-86.25759, 35.412476],
                    [-86.234575, 35.45276],
                    [-86.24516500000001, 35.631913],
                    [-86.245406, 35.688899],
                    [-86.207147, 35.70379],
                    [-86.08889000000002, 35.646823],
                    [-85.985062, 35.660108],
                    [-85.948192, 35.573422],
                    [-85.876962, 35.524095],
                    [-85.869478, 35.380435],
                    [-85.913593, 35.289189],
                    [-85.873025, 35.223362],
                    [-85.863319, 35.21106],
                    [-85.8639461118069, 34.9870308587481],
                    [-86.311274, 34.991098],
                    [-86.31876076546511, 34.99107858044281],
                    [-86.31816800000001, 35.126986],
                    [-86.36389, 35.123209],
                    [-86.392192, 35.235381],
                    [-86.52728, 35.311536],
                    [-86.525306, 35.354718],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '46100',
            AFFGEOID: '310M600US46100',
            GEOID: '46100',
            NAME: 'Tullahoma-Manchester, TN',
            NAMELSAD: 'Tullahoma-Manchester, TN Micro Area',
            LSAD: 'M2',
            ALAND: 2881836942,
            AWATER: 72619550,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.590107, 36.020019, -76.022607, 36.510677],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.590107, 36.238862],
                    [-76.55964600000001, 36.351056],
                    [-76.453711, 36.378092],
                    [-76.491405, 36.468648],
                    [-76.491336, 36.510677],
                    [-76.437286, 36.467846],
                    [-76.231329, 36.37208],
                    [-76.216211, 36.298302],
                    [-76.12458, 36.263963],
                    [-76.02939, 36.158819],
                    [-76.022607, 36.096725],
                    [-76.058217, 36.080593],
                    [-76.157953, 36.059517],
                    [-76.186705, 36.047725],
                    [-76.358393, 36.020019],
                    [-76.396794, 36.026443],
                    [-76.402957, 36.072867],
                    [-76.572077, 36.109463],
                    [-76.590107, 36.238862],
                ],
            ],
        },
        properties: {
            CSAFP: '545',
            CBSAFP: '21020',
            AFFGEOID: '310M600US21020',
            GEOID: '21020',
            NAME: 'Elizabeth City, NC',
            NAMELSAD: 'Elizabeth City, NC Micro Area',
            LSAD: 'M2',
            ALAND: 1227784239,
            AWATER: 373506546,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.8622815631063, 25.840117, -97.145567, 26.411177679957504],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.861875, 26.120295],
                    [-97.861875, 26.348156],
                    [-97.506936, 26.312702],
                    [-97.446987, 26.324947],
                    [-97.386505, 26.410437],
                    [-97.2272884780129, 26.411177679957504],
                    [-97.196935, 26.305865],
                    [-97.158798, 26.082660000000004],
                    [-97.151922, 26.017653000000003],
                    [-97.145567, 25.971132],
                    [-97.156608, 25.949022],
                    [-97.206945, 25.960899],
                    [-97.277163, 25.935438],
                    [-97.338346, 25.923125],
                    [-97.365976, 25.902447],
                    [-97.360082, 25.868874],
                    [-97.372864, 25.840117],
                    [-97.422636, 25.840378],
                    [-97.454727, 25.879337],
                    [-97.496861, 25.880058],
                    [-97.542957, 25.920035],
                    [-97.582565, 25.937857],
                    [-97.644011, 26.006614],
                    [-97.697069, 26.023455],
                    [-97.758838, 26.032131],
                    [-97.795291, 26.055218],
                    [-97.8622815631063, 26.0577468284402],
                    [-97.861875, 26.120295],
                ],
            ],
        },
        properties: {
            CSAFP: '154',
            CBSAFP: '15180',
            AFFGEOID: '310M600US15180',
            GEOID: '15180',
            NAME: 'Brownsville-Harlingen, TX',
            NAMELSAD: 'Brownsville-Harlingen, TX Metro Area',
            LSAD: 'M1',
            ALAND: 2309530382,
            AWATER: 996407283,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.683839, 38.5862856131089, -85.2017606225486, 38.913803],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.683839, 38.815406],
                    [-85.628855, 38.817583],
                    [-85.537431, 38.88278],
                    [-85.5382, 38.912217],
                    [-85.444897, 38.912998],
                    [-85.203166, 38.913803],
                    [-85.2017606225486, 38.6974407918463],
                    [-85.238665, 38.722494],
                    [-85.275454, 38.741172],
                    [-85.33264073375142, 38.7348167543477],
                    [-85.340953, 38.733893],
                    [-85.400481, 38.73598],
                    [-85.448862, 38.71336800000001],
                    [-85.438742, 38.659319],
                    [-85.43617000000002, 38.598292],
                    [-85.4314160707522, 38.5862856131089],
                    [-85.496666, 38.606736],
                    [-85.56998, 38.606155],
                    [-85.57093, 38.678706],
                    [-85.683338, 38.736731],
                    [-85.683839, 38.815406],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '31500',
            AFFGEOID: '310M600US31500',
            GEOID: '31500',
            NAME: 'Madison, IN',
            NAMELSAD: 'Madison, IN Micro Area',
            LSAD: 'M2',
            ALAND: 934084282,
            AWATER: 6112619,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.4636253046849, 30.318933, -87.88729000000001, 31.699302999999997],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.4636253046849, 31.6979423713502],
                    [-88.088288, 31.699302999999997],
                    [-88.078711, 31.669618],
                    [-88.089388, 31.658747000000005],
                    [-88.072234, 31.5945],
                    [-88.051449, 31.583504],
                    [-88.027693, 31.584251],
                    [-88.031277, 31.560493999999995],
                    [-87.970377, 31.530546],
                    [-87.942514, 31.526719],
                    [-87.92444, 31.503021],
                    [-87.906143, 31.491752],
                    [-87.935106, 31.442398],
                    [-87.88729000000001, 31.392197],
                    [-87.908068, 31.323041],
                    [-87.959619, 31.316206],
                    [-87.946588, 31.19293],
                    [-87.972869, 31.162694],
                    [-87.940121, 31.147237],
                    [-87.976222, 31.089534999999998],
                    [-87.941152, 31.048161],
                    [-87.965187, 30.967784000000005],
                    [-87.92429, 30.935405000000003],
                    [-87.979688, 30.888993],
                    [-87.944546, 30.827046000000003],
                    [-88.026319, 30.753358],
                    [-88.008396, 30.684955999999996],
                    [-88.061998, 30.644891000000005],
                    [-88.064898, 30.588292000000003],
                    [-88.081617, 30.546317],
                    [-88.103768, 30.500903],
                    [-88.105699, 30.401865],
                    [-88.136173, 30.320728999999996],
                    [-88.195664, 30.321242000000005],
                    [-88.257764, 30.318933],
                    [-88.311608, 30.368908],
                    [-88.36402200000002, 30.388006],
                    [-88.395023, 30.369424999999996],
                    [-88.403931, 30.543359000000002],
                    [-88.41227, 30.731770999999995],
                    [-88.4124674313592, 30.735597459215303],
                    [-88.4260209637871, 30.998281357430297],
                    [-88.432007, 31.114298],
                    [-88.44866, 31.421277000000003],
                    [-88.4494460690171, 31.4358368612776],
                    [-88.459478, 31.621652000000005],
                    [-88.4636253046849, 31.6979423713502],
                ],
            ],
        },
        properties: {
            CSAFP: '380',
            CBSAFP: '33660',
            AFFGEOID: '310M600US33660',
            GEOID: '33660',
            NAME: 'Mobile, AL',
            NAMELSAD: 'Mobile, AL Metro Area',
            LSAD: 'M1',
            ALAND: 5981777091,
            AWATER: 1095671201,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.732704, 35.895846, -83.082312, 36.413186],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.714704, 36.173783],
                    [-83.667408, 36.344381],
                    [-83.658007, 36.331982],
                    [-83.53250400000002, 36.353385],
                    [-83.3868, 36.413186],
                    [-83.304197, 36.388189],
                    [-83.280595, 36.394689],
                    [-83.288896, 36.378789],
                    [-83.255292, 36.289092],
                    [-83.16259, 36.345292],
                    [-83.082312, 36.242296],
                    [-83.14219, 36.18739],
                    [-83.166085, 36.18089400000001],
                    [-83.18738600000002, 36.174995],
                    [-83.175098, 36.127357],
                    [-83.245787, 36.124194],
                    [-83.234585, 36.085094],
                    [-83.259059, 35.999843],
                    [-83.276677, 36.007652],
                    [-83.296358, 35.904879],
                    [-83.310782, 35.895846],
                    [-83.474901, 35.922893],
                    [-83.673297, 36.038486],
                    [-83.70925, 36.093234],
                    [-83.66746, 36.080587],
                    [-83.699405, 36.109469],
                    [-83.732704, 36.164583],
                    [-83.714704, 36.173783],
                ],
            ],
        },
        properties: {
            CSAFP: '315',
            CBSAFP: '34100',
            AFFGEOID: '310M600US34100',
            GEOID: '34100',
            NAME: 'Morristown, TN',
            NAMELSAD: 'Morristown, TN Metro Area',
            LSAD: 'M1',
            ALAND: 1856628361,
            AWATER: 196091670,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-117.47998799999999, 45.8510454354829, -116.368801, 46.631245],
            type: 'Polygon',
            coordinates: [
                [
                    [-117.47998799999999, 46.121994],
                    [-117.41948200000002, 46.121956],
                    [-117.419334, 46.383139],
                    [-117.228796, 46.411306],
                    [-117.22812, 46.462245],
                    [-117.208628, 46.423707],
                    [-117.036645748421, 46.4261004826554],
                    [-117.039771, 46.471779],
                    [-117.03977765556202, 46.5417089920381],
                    [-116.719085, 46.542874],
                    [-116.61174000000001, 46.631245],
                    [-116.456098, 46.629097],
                    [-116.45166199999998, 46.490736],
                    [-116.369122, 46.466923],
                    [-116.368801, 46.343217],
                    [-116.71349599999999, 46.325527],
                    [-116.70181900000001, 45.996381],
                    [-116.757042, 45.942103],
                    [-116.750235, 45.869287],
                    [-116.79920453250101, 45.8510454354829],
                    [-116.859795, 45.907264],
                    [-116.88684299999998, 45.958617],
                    [-116.915989, 45.995413],
                    [-117.35392799999998, 45.996349],
                    [-117.479937263654, 45.9975667668422],
                    [-117.47998799999999, 46.121994],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '30300',
            AFFGEOID: '310M600US30300',
            GEOID: '30300',
            NAME: 'Lewiston, ID-WA',
            NAMELSAD: 'Lewiston, ID-WA Metro Area',
            LSAD: 'M1',
            ALAND: 3844567740,
            AWATER: 32544611,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-121.464234, 46.737126, -119.92743600000001, 47.597288],
            type: 'Polygon',
            coordinates: [
                [
                    [-121.464234, 47.354416],
                    [-121.410878, 47.424539],
                    [-121.17225, 47.590054],
                    [-121.11356899999998, 47.597288],
                    [-121.07496999999998, 47.550891],
                    [-120.99657800000001, 47.518545],
                    [-120.917936, 47.429367],
                    [-120.80253300000001, 47.422626],
                    [-120.664368, 47.343305],
                    [-120.5305, 47.334328],
                    [-120.389197, 47.260935],
                    [-120.09446000000001, 47.262159],
                    [-120.075969, 47.225898],
                    [-120.007074, 47.220133],
                    [-120.00206500000002, 47.126153],
                    [-120.042866, 47.073453],
                    [-119.96612900000001, 46.943757],
                    [-119.92743600000001, 46.798445],
                    [-119.973036, 46.737126],
                    [-120.51, 46.737946],
                    [-120.50860500000002, 46.824862],
                    [-120.63398100000002, 46.825776],
                    [-120.634562, 46.91213],
                    [-121.02662, 46.911308000000005],
                    [-121.09005400000001, 46.991007],
                    [-121.280716, 47.088731],
                    [-121.379682, 47.087495],
                    [-121.29763900000002, 47.148557],
                    [-121.36513799999999, 47.224563],
                    [-121.34120299999998, 47.281261],
                    [-121.42749200000002, 47.289075],
                    [-121.464234, 47.354416],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '21260',
            AFFGEOID: '310M600US21260',
            GEOID: '21260',
            NAME: 'Ellensburg, WA',
            NAMELSAD: 'Ellensburg, WA Micro Area',
            LSAD: 'M2',
            ALAND: 5949926850,
            AWATER: 92486168,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.670196, 39.377416, -83.252435, 39.716876],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.65333, 39.716876],
                    [-83.252435, 39.695438],
                    [-83.266737, 39.516249],
                    [-83.372714, 39.377416],
                    [-83.590878, 39.378736],
                    [-83.578194, 39.524033],
                    [-83.585523, 39.545109],
                    [-83.670196, 39.550254],
                    [-83.65333, 39.716876],
                ],
            ],
        },
        properties: {
            CSAFP: '198',
            CBSAFP: '47920',
            AFFGEOID: '310M600US47920',
            GEOID: '47920',
            NAME: 'Washington Court House, OH',
            NAMELSAD: 'Washington Court House, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1052469885,
            AWATER: 1694038,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.501556, 38.433933, -95.180125, 39.653001],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.501556, 38.869704],
                    [-96.501166, 39.043666],
                    [-96.390796, 39.043257],
                    [-96.389046, 39.172878],
                    [-96.331396, 39.158885],
                    [-96.233671, 39.212279],
                    [-96.083557, 39.193504],
                    [-96.03906, 39.126527],
                    [-96.03566500000001, 39.144083],
                    [-96.03557300000001, 39.21652],
                    [-96.035952, 39.5661],
                    [-95.789101, 39.565916],
                    [-95.788941, 39.653001],
                    [-95.564126, 39.652872],
                    [-95.570351, 39.41905],
                    [-95.180891, 39.419218],
                    [-95.180125, 39.128889],
                    [-95.187103, 39.044109],
                    [-95.285425, 39.032162],
                    [-95.419439, 39.067205],
                    [-95.500254, 39.056656],
                    [-95.500724, 38.869815],
                    [-95.50073800000001, 38.738808],
                    [-95.508042, 38.695268],
                    [-95.508267, 38.433933],
                    [-95.950282, 38.434105],
                    [-95.949654, 38.695385],
                    [-95.945924, 38.739112],
                    [-96.35261300000002, 38.739021],
                    [-96.389749, 38.738984],
                    [-96.390398, 38.825858],
                    [-96.50139700000001, 38.826188],
                    [-96.501556, 38.869704],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '45820',
            AFFGEOID: '310M600US45820',
            GEOID: '45820',
            NAME: 'Topeka, KS',
            NAMELSAD: 'Topeka, KS Metro Area',
            LSAD: 'M1',
            ALAND: 8372689083,
            AWATER: 148255515,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-103.94877800000002, 33.570117, -103.043516, 34.605058],
            type: 'Polygon',
            coordinates: [
                [
                    [-103.94877800000002, 34.605058],
                    [-103.738166, 34.60467],
                    [-103.740066, 34.303056],
                    [-103.45982, 34.303261],
                    [-103.043835830165, 34.302648],
                    [-103.043563920791, 34.1128343897554],
                    [-103.043516, 34.079382],
                    [-103.047346, 33.824675],
                    [-103.05261, 33.570599],
                    [-103.052610516564, 33.5705747764422],
                    [-103.510129, 33.570117],
                    [-103.509759, 33.657262],
                    [-103.718333, 33.65621],
                    [-103.716885, 33.819558],
                    [-103.841933, 33.8192],
                    [-103.841691, 34.081909],
                    [-103.946019, 34.082463],
                    [-103.94877800000002, 34.605058],
                ],
            ],
        },
        properties: {
            CSAFP: '188',
            CBSAFP: '38780',
            AFFGEOID: '310M600US38780',
            GEOID: '38780',
            NAME: 'Portales, NM',
            NAMELSAD: 'Portales, NM Micro Area',
            LSAD: 'M2',
            ALAND: 6335242836,
            AWATER: 18679734,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-114.047783, 39.314561, -110.857647, 40.577063],
            type: 'Polygon',
            coordinates: [
                [
                    [-114.047268153077, 39.9060981137562],
                    [-113.24849800000001, 39.904714],
                    [-112.341988, 39.904458],
                    [-112.332296, 39.952789],
                    [-112.269882, 39.938947],
                    [-112.1798, 40.011656],
                    [-112.14685600000001, 40.177817000000005],
                    [-112.179693, 40.23224],
                    [-112.175533, 40.335837],
                    [-112.212254, 40.459114],
                    [-112.172862, 40.467065],
                    [-111.98213600000001, 40.414217],
                    [-111.913313, 40.452523],
                    [-111.60617, 40.564623],
                    [-111.593879, 40.577063],
                    [-111.575028, 40.486526],
                    [-111.617505, 40.438018],
                    [-111.555235, 40.362696],
                    [-111.480337, 40.362169],
                    [-111.426482, 40.286906],
                    [-111.31483, 40.287566],
                    [-111.278762, 40.258793],
                    [-111.225507, 40.167324],
                    [-111.249339, 40.055217],
                    [-111.205772, 40.047368],
                    [-111.083061, 39.943198],
                    [-111.082455, 39.900155],
                    [-110.891655, 39.899654],
                    [-110.857647, 39.899707],
                    [-110.85778000000002, 39.81328500000001],
                    [-111.247496, 39.813028],
                    [-111.641944, 39.812869],
                    [-111.584858, 39.784235],
                    [-111.584754, 39.740141],
                    [-111.717482, 39.69456],
                    [-111.713224, 39.460294],
                    [-111.747389, 39.41696],
                    [-111.747044, 39.38275],
                    [-111.92238900000001, 39.381591],
                    [-112.016003, 39.314561],
                    [-112.188922, 39.329392],
                    [-112.212045, 39.553987],
                    [-112.354467, 39.553684],
                    [-112.462419, 39.552451],
                    [-113.426363, 39.552466],
                    [-113.81574300000001, 39.552644],
                    [-113.839771, 39.543839],
                    [-114.047181412926, 39.5427436022297],
                    [-114.047783, 39.79416],
                    [-114.047268153077, 39.9060981137562],
                ],
            ],
        },
        properties: {
            CSAFP: '482',
            CBSAFP: '39340',
            AFFGEOID: '310M600US39340',
            GEOID: '39340',
            NAME: 'Provo-Orem, UT',
            NAMELSAD: 'Provo-Orem, UT Metro Area',
            LSAD: 'M1',
            ALAND: 13974951447,
            AWATER: 399509938,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-113.085517, 45.624854, -112.190269, 46.184221],
            type: 'Polygon',
            coordinates: [
                [
                    [-113.085517, 45.860648],
                    [-112.923224, 45.934595],
                    [-112.938955, 45.993258],
                    [-112.852763, 46.048434],
                    [-112.777717, 46.048313],
                    [-112.77712, 46.135826],
                    [-112.681004, 46.135672],
                    [-112.577106, 46.178981],
                    [-112.50519100000001, 46.184221],
                    [-112.51999700000002, 46.136394],
                    [-112.404349, 45.888049],
                    [-112.414212, 45.836684],
                    [-112.190269, 45.748503],
                    [-112.46181600000001, 45.742584],
                    [-112.498432, 45.759765],
                    [-112.549534, 45.684317],
                    [-112.687054, 45.624854],
                    [-112.759339, 45.748209],
                    [-112.988358, 45.808476],
                    [-113.085517, 45.860648],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '15580',
            AFFGEOID: '310M600US15580',
            GEOID: '15580',
            NAME: 'Butte-Silver Bow, MT',
            NAMELSAD: 'Butte-Silver Bow, MT Micro Area',
            LSAD: 'M2',
            ALAND: 1859568271,
            AWATER: 1518178,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-75.359184, 39.498537, -71.856214, 41.603764019902705],
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [-72.018926, 41.274114],
                        [-71.926802, 41.290122000000004],
                        [-71.917281, 41.251333],
                        [-72.034754, 41.234818],
                        [-72.018926, 41.274114],
                    ],
                ],
                [
                    [
                        [-75.311143, 41.331439],
                        [-75.350648, 41.367694],
                        [-75.265712, 41.372382],
                        [-75.0461986059117, 41.603764019902705],
                        [-75.043879, 41.575094],
                        [-74.982463, 41.496467],
                        [-74.890358, 41.455324],
                        [-74.799546, 41.43129],
                        [-74.7562718347252, 41.4276274285434],
                        [-74.734893, 41.425818],
                        [-74.694914, 41.357423],
                        [-74.457584, 41.248225],
                        [-74.3670382614474, 41.2042114724438],
                        [-74.301994, 41.172594],
                        [-74.2344924405545, 41.1428915543507],
                        [-73.980754, 41.32542],
                        [-73.947294, 41.394765],
                        [-73.981486, 41.438905],
                        [-73.933775, 41.488279],
                        [-73.673285, 41.514701],
                        [-73.580153, 41.526382],
                        [-73.5296777143013, 41.5271610211283],
                        [-73.536969, 41.441094],
                        [-73.5431473462655, 41.3767706682974],
                        [-73.5431832079963, 41.3763973085168],
                        [-73.5433065806917, 41.3751128640277],
                        [-73.5441352908263, 41.36648508653171],
                        [-73.550961, 41.295422],
                        [-73.482709, 41.21276],
                        [-73.69593580216961, 41.1152554569041],
                        [-73.727775, 41.100696],
                        [-73.659533, 41.017857],
                        [-73.659362, 41.004034],
                        [-73.6573740092614, 40.9855248838592],
                        [-73.657336, 40.985171],
                        [-73.697974, 40.939598],
                        [-73.756776, 40.912599],
                        [-73.766276, 40.881099],
                        [-73.7411972449486, 40.8758545928374],
                        [-73.713674, 40.870099],
                        [-73.654372, 40.878199],
                        [-73.617571, 40.897898],
                        [-73.499941, 40.918166],
                        [-73.4973510386263, 40.9231822732944],
                        [-73.485365, 40.946397],
                        [-73.436664, 40.934897],
                        [-73.392862, 40.955297],
                        [-73.33136, 40.929597],
                        [-73.2358274066785, 40.9066897675323],
                        [-73.229285, 40.905121],
                        [-73.148994, 40.928898],
                        [-73.144673, 40.955842],
                        [-73.110368, 40.971938],
                        [-73.040445, 40.964498],
                        [-72.859831, 40.966088],
                        [-72.708069, 40.977851],
                        [-72.585327, 40.997587],
                        [-72.504305, 41.043329],
                        [-72.445242, 41.086116],
                        [-72.389809, 41.108304],
                        [-72.35412300000002, 41.139952],
                        [-72.291109, 41.155874],
                        [-72.189163, 41.193549],
                        [-72.182033, 41.17834500000001],
                        [-72.254704, 41.110852],
                        [-72.283093, 41.067874],
                        [-72.217476, 41.040611],
                        [-72.162898, 41.053187],
                        [-72.126704, 41.115139],
                        [-72.08420700000002, 41.101524],
                        [-72.095711, 41.05402],
                        [-72.051928, 41.020506],
                        [-71.959595, 41.071237],
                        [-71.919385, 41.08051700000001],
                        [-71.856214, 41.070598000000004],
                        [-71.936977, 41.006137],
                        [-72.097369, 40.95888],
                        [-72.298727, 40.903151],
                        [-72.39585, 40.86666],
                        [-72.757176, 40.764371],
                        [-72.923214, 40.713282],
                        [-73.012545, 40.679651],
                        [-73.1460808692108, 40.6464079681013],
                        [-73.20844, 40.630884],
                        [-73.306396, 40.620756],
                        [-73.351465, 40.6305],
                        [-73.4241151206597, 40.6132119188686],
                        [-73.4848683943471, 40.598754853760205],
                        [-73.507325, 40.593411],
                        [-73.640902, 40.582823],
                        [-73.7506217216563, 40.5893197671277],
                        [-73.774928, 40.590759],
                        [-73.8254940011311, 40.5761495789939],
                        [-73.940591, 40.542896],
                        [-73.9737917702446, 40.56085470251791],
                        [-73.991346, 40.57035],
                        [-74.0365576544541, 40.5889884137416],
                        [-74.0379705239158, 40.5895708663395],
                        [-74.057316, 40.597546],
                        [-74.112585, 40.547603],
                        [-74.199923, 40.511729],
                        [-74.260611, 40.502436],
                        [-74.261889, 40.464706],
                        [-74.2246524660147, 40.44866248948651],
                        [-74.206188, 40.440707],
                        [-74.157094, 40.447571],
                        [-74.108295, 40.443788],
                        [-74.047884, 40.418908],
                        [-74.019334, 40.471244],
                        [-73.997943, 40.476668000000004],
                        [-73.976982, 40.408508],
                        [-73.971381, 40.34801],
                        [-73.981681, 40.279411],
                        [-74.0010207944346, 40.2169665919779],
                        [-74.030181, 40.122814],
                        [-74.0349623822635, 40.1025843059482],
                        [-74.06413500000001, 39.979157],
                        [-74.077247, 39.910991],
                        [-74.090945, 39.799978],
                        [-74.0996189143252, 39.7637843614958],
                        [-74.101443, 39.756173],
                        [-74.190974, 39.625118],
                        [-74.2910153361548, 39.5083697974978],
                        [-74.380796, 39.498537],
                        [-74.41739300000002, 39.557255],
                        [-74.39812, 39.576575],
                        [-74.389708, 39.773283],
                        [-74.434223, 39.855157],
                        [-74.553105, 40.07913],
                        [-74.58783500000001, 40.138254],
                        [-74.614576, 40.182383],
                        [-74.543268, 40.216692],
                        [-74.484962, 40.253325],
                        [-74.622462, 40.3248],
                        [-74.62024, 40.373749],
                        [-74.622682, 40.384336],
                        [-74.722061, 40.375205],
                        [-74.748147, 40.424156],
                        [-74.856262, 40.346696],
                        [-74.9281105690914, 40.3398284476893],
                        [-74.946006, 40.35730600000001],
                        [-74.969597, 40.39977],
                        [-75.024775, 40.403455],
                        [-75.056102, 40.416066],
                        [-75.070568, 40.455165],
                        [-75.062227, 40.481391],
                        [-75.078503, 40.548296],
                        [-75.136748, 40.575731],
                        [-75.186737, 40.569406],
                        [-75.1881998781252, 40.5926133666486],
                        [-75.145908, 40.62866],
                        [-74.983388, 40.705372],
                        [-74.96804700000001, 40.711324],
                        [-74.889819, 40.787729],
                        [-74.850513, 40.803956],
                        [-74.769425, 40.910934],
                        [-74.791392, 40.931349],
                        [-74.962665, 41.090527],
                        [-74.9830420742478, 41.1060175588568],
                        [-74.9923859951822, 41.0930282469934],
                        [-75.156507, 41.150341],
                        [-75.129721, 41.252101],
                        [-75.359184, 41.239206],
                        [-75.311143, 41.331439],
                    ],
                ],
            ],
        },
        properties: {
            CSAFP: '408',
            CBSAFP: '35620',
            AFFGEOID: '310M600US35620',
            GEOID: '35620',
            NAME: 'New York-Newark-Jersey City, NY-NJ-PA',
            NAMELSAD: 'New York-Newark-Jersey City, NY-NJ-PA Metro Area',
            LSAD: 'M1',
            ALAND: 17312408463,
            AWATER: 6547062154,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-117.04130644645902, 47.890142, -116.03856582987301, 48.847402],
            type: 'Polygon',
            coordinates: [
                [
                    [-117.04121445346101, 48.0454651038028],
                    [-117.041107, 48.12490400000001],
                    [-117.03528900000002, 48.422732],
                    [-117.03367100000001, 48.656902],
                    [-117.032939182117, 48.84666792120151],
                    [-116.78517399999998, 48.847402],
                    [-116.78487800000002, 48.500472],
                    [-116.04915652064902, 48.5020424817581],
                    [-116.049155, 48.481247],
                    [-116.048948, 48.309847],
                    [-116.048929247776, 48.2161279479271],
                    [-116.048911, 48.12493],
                    [-116.04915300000002, 47.999923],
                    [-116.03856582987301, 47.9846342564417],
                    [-116.120163, 48.03344800000001],
                    [-116.28223600000001, 48.059386],
                    [-116.329601, 48.017722000000006],
                    [-116.329538, 47.890393],
                    [-116.505104, 47.890142],
                    [-116.504329, 47.991386],
                    [-116.63411400000001, 47.977073],
                    [-117.04130644645902, 47.9774559200268],
                    [-117.04121445346101, 48.0454651038028],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '41760',
            AFFGEOID: '310M600US41760',
            GEOID: '41760',
            NAME: 'Sandpoint, ID',
            NAMELSAD: 'Sandpoint, ID Micro Area',
            LSAD: 'M2',
            ALAND: 4489020246,
            AWATER: 478920605,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-75.8612711928347, 44.0504, -74.525683, 45.014683],
            type: 'Polygon',
            coordinates: [
                [
                    [-75.834126, 44.422433],
                    [-75.807778, 44.471644],
                    [-75.76623, 44.515851],
                    [-75.567413, 44.658709],
                    [-75.423943, 44.756329],
                    [-75.333744, 44.806378],
                    [-75.255517, 44.857651],
                    [-75.142958, 44.900237],
                    [-75.066245, 44.930174],
                    [-75.005155, 44.958402],
                    [-74.992756, 44.977449],
                    [-74.90795600000001, 44.983359],
                    [-74.834669, 45.014683],
                    [-74.74464, 44.990577],
                    [-74.725813431619, 44.9917926265259],
                    [-74.6447393198257, 44.9970275604167],
                    [-74.525683, 44.170636],
                    [-74.535156, 44.09925],
                    [-74.854171, 44.070089],
                    [-75.062779, 44.0504],
                    [-75.167806, 44.095939],
                    [-75.446277, 44.21773],
                    [-75.8612711928347, 44.4051920135031],
                    [-75.834126, 44.422433],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '36300',
            AFFGEOID: '310M600US36300',
            GEOID: '36300',
            NAME: 'Ogdensburg-Massena, NY',
            NAMELSAD: 'Ogdensburg-Massena, NY Micro Area',
            LSAD: 'M2',
            ALAND: 6939601205,
            AWATER: 367206795,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.168087, 36.915544, -88.193519, 37.421987],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.168087, 37.074218],
                    [-89.099047, 37.140967],
                    [-89.058036, 37.188767],
                    [-89.000968, 37.224401],
                    [-88.9335100599741, 37.2275116098343],
                    [-88.931745, 37.227593],
                    [-88.92789354943082, 37.226353966835],
                    [-88.928757, 37.302848],
                    [-88.905071, 37.335743],
                    [-88.710738, 37.337081],
                    [-88.49033, 37.159462000000005],
                    [-88.4904810043425, 37.06795610648561],
                    [-88.48380327698, 37.0680802698954],
                    [-88.476127, 37.068223],
                    [-88.444605, 37.098601],
                    [-88.424776, 37.149901],
                    [-88.471753, 37.220155],
                    [-88.51466100000002, 37.29094800000001],
                    [-88.486947, 37.339596],
                    [-88.465861, 37.400547],
                    [-88.418594, 37.421987],
                    [-88.4161335687515, 37.4212865145119],
                    [-88.358476490182, 37.4048715275665],
                    [-88.35084, 37.397099],
                    [-88.318134, 37.398007],
                    [-88.21898, 37.273145],
                    [-88.193519, 37.147256],
                    [-88.231145, 37.081575],
                    [-88.209221, 36.999976],
                    [-88.240371, 36.981911],
                    [-88.339292, 37.061216],
                    [-88.482957, 37.022566],
                    [-88.485254, 37.001109],
                    [-88.484103, 36.942096],
                    [-88.812897, 36.946864],
                    [-88.815952, 36.9541],
                    [-89.080505, 36.915544],
                    [-89.1031354483266, 36.9447609003223],
                    [-89.09884300000002, 36.95785],
                    [-89.132915, 36.982057],
                    [-89.128899, 37.017908],
                    [-89.1666202120197, 37.0721103437999],
                    [-89.168087, 37.074218],
                ],
            ],
        },
        properties: {
            CSAFP: '424',
            CBSAFP: '37140',
            AFFGEOID: '310M600US37140',
            GEOID: '37140',
            NAME: 'Paducah, KY-IL',
            NAMELSAD: 'Paducah, KY-IL Micro Area',
            LSAD: 'M2',
            ALAND: 2709312045,
            AWATER: 207430810,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.640315, 28.963291, -95.847656, 29.633806],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.349217, 29.406052000000003],
                    [-96.307672, 29.514523],
                    [-96.175422, 29.633806],
                    [-96.112582, 29.623617],
                    [-96.08891200000001, 29.601658],
                    [-96.044596, 29.562238],
                    [-96.059627, 29.473329],
                    [-95.97343, 29.411445000000004],
                    [-95.94681, 29.333433],
                    [-95.847656, 29.26259],
                    [-95.874028, 29.229702],
                    [-95.965196, 29.146865000000002],
                    [-96.309178, 28.963291],
                    [-96.640315, 29.247804],
                    [-96.349217, 29.406052000000003],
                ],
            ],
        },
        properties: {
            CSAFP: '288',
            CBSAFP: '20900',
            AFFGEOID: '310M600US20900',
            GEOID: '20900',
            NAME: 'El Campo, TX',
            NAMELSAD: 'El Campo, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2813095080,
            AWATER: 21310003,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-94.043083, 31.8442765572456, -93.355452, 33.019457],
            type: 'Polygon',
            coordinates: [
                [
                    [-94.0430515276176, 32.6930299766656],
                    [-94.043026, 32.797476],
                    [-94.04300262156332, 32.881088978924],
                    [-94.04296400000001, 33.019219],
                    [-93.8145980610549, 33.0193890113342],
                    [-93.8071252110518, 33.0193945746385],
                    [-93.723273, 33.019457],
                    [-93.5209925078522, 33.0187422898925],
                    [-93.530405, 32.975397],
                    [-93.448039, 32.866321],
                    [-93.473797, 32.783172],
                    [-93.44486700000002, 32.761395],
                    [-93.44115, 32.410468],
                    [-93.372722, 32.41019],
                    [-93.42338, 32.397740000000006],
                    [-93.402923, 32.368727],
                    [-93.428644, 32.23527300000001],
                    [-93.469441, 32.237181],
                    [-93.61469, 32.237526],
                    [-93.535963, 32.193126],
                    [-93.539553, 32.102167],
                    [-93.441506, 31.989180000000005],
                    [-93.355452, 31.932222],
                    [-93.419781, 31.934058],
                    [-93.441171, 31.845233000000004],
                    [-93.8782518953866, 31.8442765572456],
                    [-93.909557, 31.893144000000003],
                    [-93.977461, 31.926419],
                    [-94.029428406596, 31.979686964168003],
                    [-94.041833, 31.992402000000002],
                    [-94.042681, 32.137956],
                    [-94.0426959391654, 32.1960649748209],
                    [-94.042739, 32.363559],
                    [-94.0427882328053, 32.3922831932812],
                    [-94.043083, 32.564261],
                    [-94.0430515276176, 32.6930299766656],
                ],
            ],
        },
        properties: {
            CSAFP: '508',
            CBSAFP: '43340',
            AFFGEOID: '310M600US43340',
            GEOID: '43340',
            NAME: 'Shreveport-Bossier City, LA',
            NAMELSAD: 'Shreveport-Bossier City, LA Metro Area',
            LSAD: 'M1',
            ALAND: 6722151630,
            AWATER: 267040058,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.256085, 34.038240984853, -77.5152165445848, 34.721795],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.256085, 34.399469],
                    [-78.176501, 34.465356],
                    [-78.255468, 34.508614],
                    [-78.254441, 34.553595],
                    [-78.113995, 34.706876],
                    [-78.114007, 34.721795],
                    [-77.981195, 34.713983],
                    [-77.680127, 34.720595],
                    [-77.576165, 34.47660700000001],
                    [-77.5152165445848, 34.4373849694266],
                    [-77.635034, 34.359555],
                    [-77.7135131704588, 34.2902471866868],
                    [-77.764022, 34.245641],
                    [-77.829209, 34.162618],
                    [-77.8854550755211, 34.038240984853],
                    [-77.956605, 34.234491],
                    [-78.029922, 34.331768],
                    [-78.137302, 34.371711],
                    [-78.16235, 34.357007],
                    [-78.202532, 34.353306],
                    [-78.256085, 34.399469],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '48900',
            AFFGEOID: '310M600US48900',
            GEOID: '48900',
            NAME: 'Wilmington, NC',
            NAMELSAD: 'Wilmington, NC Metro Area',
            LSAD: 'M1',
            ALAND: 2754582846,
            AWATER: 516637306,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-107.624283, 35.930742, -105.53038000000001, 37.000139],
            type: 'Polygon',
            coordinates: [
                [
                    [-107.618181, 36.804267],
                    [-107.597983, 36.834302],
                    [-107.451445, 36.904036],
                    [-107.457159, 36.954964],
                    [-107.420913, 37.000005],
                    [-106.877292, 37.000139],
                    [-106.86979600000001, 36.992426],
                    [-106.476233588828, 36.9937741005469],
                    [-106.343139, 36.99423],
                    [-106.006316410798, 36.9953866288173],
                    [-105.97188900000002, 36.886247],
                    [-105.981088, 36.713445],
                    [-105.938754, 36.462402],
                    [-105.9598, 36.354789],
                    [-106.038312, 36.325661],
                    [-106.058364, 36.296978],
                    [-105.856947, 36.230434],
                    [-105.735883, 36.100133],
                    [-105.53038000000001, 36.013014],
                    [-105.568045, 35.973796],
                    [-105.717477, 35.978741],
                    [-105.716808, 36.00232],
                    [-106.071153, 36.001573],
                    [-106.05434600000001, 35.930783],
                    [-106.245649, 35.930742],
                    [-106.248161, 35.966327],
                    [-106.247164, 36.001434],
                    [-106.885317, 35.998969],
                    [-106.885053, 36.21657],
                    [-107.624283, 36.219809],
                    [-107.618181, 36.804267],
                ],
            ],
        },
        properties: {
            CSAFP: '106',
            CBSAFP: '21580',
            AFFGEOID: '310M600US21580',
            GEOID: '21580',
            NAME: 'Española, NM',
            NAMELSAD: 'Española, NM Micro Area',
            LSAD: 'M2',
            ALAND: 15179593238,
            AWATER: 91463246,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-107.911522788162, 44.556777, -106.009676, 45.0015440046114],
            type: 'Polygon',
            coordinates: [
                [
                    [-107.911522788162, 45.0015440046114],
                    [-107.351441, 45.001407],
                    [-106.888773, 44.995885],
                    [-106.263715092465, 44.9937884330015],
                    [-106.263586, 44.993788],
                    [-106.024880029032, 44.9975841235561],
                    [-106.009676, 44.563981],
                    [-106.407433, 44.561893],
                    [-106.428897, 44.556777],
                    [-107.36962100000001, 44.559837],
                    [-107.615891, 44.701297000000004],
                    [-107.750649, 44.722592],
                    [-107.832319, 44.790161],
                    [-107.88166200000002, 44.98546],
                    [-107.911522788162, 45.0015440046114],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '43260',
            AFFGEOID: '310M600US43260',
            GEOID: '43260',
            NAME: 'Sheridan, WY',
            NAMELSAD: 'Sheridan, WY Micro Area',
            LSAD: 'M2',
            ALAND: 6535475075,
            AWATER: 7986330,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.16674, 36.722722, -85.445337, 37.182427],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.113512, 37.061072],
                    [-86.074267, 37.053285],
                    [-86.05644300000002, 37.167208],
                    [-85.906336, 37.148377],
                    [-85.744221, 37.169813],
                    [-85.686482, 37.182427],
                    [-85.632313, 37.125056],
                    [-85.526881, 37.10945],
                    [-85.50362900000002, 37.00768],
                    [-85.445337, 36.957226],
                    [-85.45183, 36.938138],
                    [-85.560719, 36.850857],
                    [-85.601624, 36.872196],
                    [-85.596214, 36.818039],
                    [-85.613014, 36.832717],
                    [-85.739255, 36.841476],
                    [-85.77228600000001, 36.83634],
                    [-85.97691, 36.722722],
                    [-86.069957, 36.805783],
                    [-86.06531, 36.899128],
                    [-86.106421, 36.865064],
                    [-86.16674, 36.934015],
                    [-86.113512, 37.061072],
                ],
            ],
        },
        properties: {
            CSAFP: '150',
            CBSAFP: '23980',
            AFFGEOID: '310M600US23980',
            GEOID: '23980',
            NAME: 'Glasgow, KY',
            NAMELSAD: 'Glasgow, KY Micro Area',
            LSAD: 'M2',
            ALAND: 2013005568,
            AWATER: 36166062,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.37015200000002, 33.842198, -85.738975, 34.200756],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.332394, 34.040499],
                    [-86.303516, 34.099073],
                    [-86.206107, 34.17265],
                    [-86.1089, 34.186311],
                    [-86.106086, 34.200756],
                    [-85.843617, 34.200006],
                    [-85.841468, 34.111096],
                    [-85.797651, 34.105657],
                    [-85.738975, 33.96846],
                    [-85.740968, 33.935301],
                    [-85.846174, 33.95726],
                    [-85.96917, 33.914598],
                    [-86.065272, 33.842198],
                    [-86.142717, 33.899391],
                    [-86.199164, 33.988918],
                    [-86.290127, 33.983719],
                    [-86.32562200000001, 33.940147],
                    [-86.37015200000002, 33.93977],
                    [-86.332394, 34.040499],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '23460',
            AFFGEOID: '310M600US23460',
            GEOID: '23460',
            NAME: 'Gadsden, AL',
            NAMELSAD: 'Gadsden, AL Metro Area',
            LSAD: 'M1',
            ALAND: 1385916768,
            AWATER: 35022972,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.027479, 35.1856163621922, -75.458659, 36.2320803070508],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.008348, 35.895802],
                    [-75.973152, 35.976643],
                    [-75.840046, 36.028834],
                    [-75.76628, 36.056956],
                    [-75.7706514625597, 36.2320803070508],
                    [-75.71831, 36.113674],
                    [-75.658537, 36.02043],
                    [-75.569794, 35.863301],
                    [-75.51901, 35.769087],
                    [-75.496086, 35.728515],
                    [-75.458659, 35.596597],
                    [-75.486771, 35.391652],
                    [-75.533627, 35.225825],
                    [-75.635493, 35.22026],
                    [-75.7495614239622, 35.1856163621922],
                    [-75.814021, 35.500182],
                    [-75.901342, 35.668952],
                    [-76.027479, 35.668849],
                    [-76.008348, 35.895802],
                ],
            ],
        },
        properties: {
            CSAFP: '545',
            CBSAFP: '28620',
            AFFGEOID: '310M600US28620',
            GEOID: '28620',
            NAME: 'Kill Devil Hills, NC',
            NAMELSAD: 'Kill Devil Hills, NC Micro Area',
            LSAD: 'M2',
            ALAND: 992558307,
            AWATER: 3000513520,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-66.606989, 17.982765902919702, -65.564931, 18.488639],
            type: 'Polygon',
            coordinates: [
                [
                    [-66.590785, 18.33806],
                    [-66.588181, 18.389408],
                    [-66.579868, 18.467957],
                    [-66.5862547756768, 18.4879522919662],
                    [-66.5342564453186, 18.4794853710095],
                    [-66.470292, 18.46907],
                    [-66.4389500073989, 18.4814929092627],
                    [-66.420921, 18.488639],
                    [-66.3495033226913, 18.4792143103093],
                    [-66.315477, 18.474724],
                    [-66.3150242736902, 18.4747411913405],
                    [-66.258015, 18.476906],
                    [-66.1958822114694, 18.4706537645082],
                    [-66.186722, 18.469732],
                    [-66.137959, 18.473892],
                    [-66.1292603454387, 18.4721745898571],
                    [-66.03944, 18.454441],
                    [-66.0343218474795, 18.4550699067989],
                    [-65.9928037546563, 18.4601715544638],
                    [-65.99079000000002, 18.460419],
                    [-65.904988, 18.450926],
                    [-65.831476, 18.426849],
                    [-65.827737477559, 18.4255624895091],
                    [-65.771695, 18.406277],
                    [-65.7417964652894, 18.3981817451377],
                    [-65.718618, 18.391906],
                    [-65.6613516940984, 18.3890389313634],
                    [-65.624022, 18.38717],
                    [-65.586232, 18.393383],
                    [-65.565949, 18.358333],
                    [-65.564931, 18.325042],
                    [-65.624871, 18.31067],
                    [-65.613949666955, 18.2938153422042],
                    [-65.588317, 18.254257000000003],
                    [-65.599065, 18.212961],
                    [-65.635281, 18.199975],
                    [-65.6599257449176, 18.1915732067306],
                    [-65.695856, 18.179324],
                    [-65.7333570957557, 18.1657704722157],
                    [-65.758728, 18.156601],
                    [-65.777584, 18.129239],
                    [-65.8029066863755, 18.071186],
                    [-65.809174, 18.056818],
                    [-65.833142, 18.024218],
                    [-65.8509185557852, 18.0119664368788],
                    [-65.884937, 17.988521],
                    [-65.9149360834884, 17.982765902919702],
                    [-65.9819, 18.03484],
                    [-65.979951, 18.038897],
                    [-65.985578, 18.04521],
                    [-65.985187, 18.050462],
                    [-65.98912, 18.056918],
                    [-65.988523, 18.058941],
                    [-66.010245, 18.075565000000005],
                    [-66.051788, 18.109878],
                    [-66.053146, 18.107293],
                    [-66.129031, 18.088637],
                    [-66.138987, 18.036917],
                    [-66.166145, 18.050063],
                    [-66.202038, 18.046113],
                    [-66.22449, 18.095339],
                    [-66.257527, 18.075884],
                    [-66.320913, 18.126005],
                    [-66.314848, 18.152088],
                    [-66.323333, 18.165272],
                    [-66.373768, 18.173694],
                    [-66.410062, 18.16299],
                    [-66.42346, 18.172071000000003],
                    [-66.44456, 18.176674],
                    [-66.470545, 18.177829],
                    [-66.521899, 18.151954],
                    [-66.54722, 18.15313],
                    [-66.543133, 18.163098],
                    [-66.531023, 18.180137],
                    [-66.56662, 18.298549],
                    [-66.606989, 18.328186],
                    [-66.590785, 18.33806],
                ],
            ],
        },
        properties: {
            CSAFP: '490',
            CBSAFP: '41980',
            AFFGEOID: '310M600US41980',
            GEOID: '41980',
            NAME: 'San Juan-Bayamón-Caguas, PR',
            NAMELSAD: 'San Juan-Bayamón-Caguas, PR Metro Area',
            LSAD: 'M1',
            ALAND: 3724955835,
            AWATER: 1457992026,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.941279, 40.736541, -86.930079, 42.669709],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.939732, 42.15232],
                    [-88.705585, 42.153537],
                    [-88.7074208350474, 42.4935873247155],
                    [-88.70738, 42.493587],
                    [-88.506912, 42.494883],
                    [-88.304692, 42.4956081727515],
                    [-88.305891, 42.610817000000004],
                    [-88.188173, 42.611456],
                    [-88.188306, 42.669709],
                    [-87.8020150551096, 42.6683110611767],
                    [-87.81467400000001, 42.64402],
                    [-87.813273, 42.57922],
                    [-87.800477, 42.49192],
                    [-87.80337, 42.420621],
                    [-87.820858, 42.361584],
                    [-87.834769, 42.301522],
                    [-87.83337777156702, 42.2977737051545],
                    [-87.8006461090443, 42.209586949469],
                    [-87.800066, 42.208024],
                    [-87.7592491186898, 42.1522561642711],
                    [-87.7416899291898, 42.1282651594679],
                    [-87.741662, 42.128227],
                    [-87.7413552334043, 42.1279554347716],
                    [-87.68927562032832, 42.0818519403908],
                    [-87.682359, 42.075729],
                    [-87.668982, 42.029142],
                    [-87.634367533887, 41.9329102403255],
                    [-87.624982113481, 41.9068178107036],
                    [-87.624052, 41.904232],
                    [-87.612291, 41.893335],
                    [-87.613556, 41.88448],
                    [-87.61629300000001, 41.870929],
                    [-87.60945, 41.84523300000001],
                    [-87.5883759425147, 41.8110340761664],
                    [-87.5816026607332, 41.8000424127],
                    [-87.560646, 41.766034],
                    [-87.530745, 41.748235],
                    [-87.524141, 41.72399],
                    [-87.524044, 41.708335],
                    [-87.470742, 41.672835],
                    [-87.415816, 41.68818300000001],
                    [-87.365439, 41.629536],
                    [-87.261536, 41.62033600000001],
                    [-87.2227999491184, 41.62888983896],
                    [-87.125835, 41.650302],
                    [-87.027888, 41.674661],
                    [-86.9328490284944, 41.7164969379022],
                    [-86.930079, 41.236798],
                    [-86.93017, 41.172318],
                    [-86.930759, 40.91241800000001],
                    [-86.987438, 40.912177],
                    [-86.986438, 40.837921],
                    [-87.099793, 40.837607],
                    [-87.09839, 40.736541],
                    [-87.267298, 40.736582],
                    [-87.5261364904237, 40.7368850814229],
                    [-87.526014, 40.895582],
                    [-87.526463234368, 41.0103365222041],
                    [-87.52652, 41.024837],
                    [-87.5266481580824, 41.1660899445116],
                    [-87.5267678862275, 41.298051602479205],
                    [-88.01391900000002, 41.292447],
                    [-88.01181, 41.20550800000001],
                    [-88.244144, 41.20156600000001],
                    [-88.2414, 41.128981],
                    [-88.251501, 41.114218],
                    [-88.58624, 41.108293],
                    [-88.595961, 41.457129],
                    [-88.60224, 41.631389],
                    [-88.712444, 41.630491],
                    [-88.818233, 41.631351],
                    [-88.938679, 41.628316],
                    [-88.941279, 41.891752],
                    [-88.939732, 42.15232],
                ],
            ],
        },
        properties: {
            CSAFP: '176',
            CBSAFP: '16980',
            AFFGEOID: '310M600US16980',
            GEOID: '16980',
            NAME: 'Chicago-Naperville-Elgin, IL-IN-WI',
            NAMELSAD: 'Chicago-Naperville-Elgin, IL-IN-WI Metro Area',
            LSAD: 'M1',
            ALAND: 18634680277,
            AWATER: 6173586973,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-94.261131, 44.195836, -92.135202, 46.24641200000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-94.261131, 45.283941],
                    [-94.09272, 45.342467],
                    [-94.047327, 45.422861],
                    [-94.141989, 45.493243],
                    [-94.149467, 45.559066],
                    [-93.759435, 45.559646],
                    [-93.763948, 45.822143],
                    [-93.810321, 45.98308],
                    [-93.810362, 46.156585],
                    [-93.810802, 46.234295],
                    [-93.796281, 46.243199],
                    [-93.430648, 46.24641200000001],
                    [-93.431835, 46.153881],
                    [-93.432491, 45.981464],
                    [-93.51808300000002, 45.981721],
                    [-93.513683, 45.73431800000001],
                    [-93.141793, 45.730657],
                    [-92.8407421066255, 45.7293967344697],
                    [-92.869689, 45.715142],
                    [-92.886697124409, 45.644148],
                    [-92.887929, 45.639006],
                    [-92.881136, 45.573409],
                    [-92.801503, 45.562854],
                    [-92.756906, 45.557499],
                    [-92.728023, 45.525652],
                    [-92.686793, 45.472271],
                    [-92.646768, 45.437929],
                    [-92.658486, 45.396058],
                    [-92.698967, 45.336374],
                    [-92.7482676746859, 45.2960593865098],
                    [-92.761868, 45.284938],
                    [-92.7660914086737, 45.2100217024227],
                    [-92.156465, 45.209554],
                    [-92.136113, 45.121388],
                    [-92.136348, 44.857848],
                    [-92.135202, 44.684374],
                    [-92.1353, 44.53958],
                    [-92.3169331334058, 44.5392760122027],
                    [-92.361518, 44.558935000000005],
                    [-92.39928100000002, 44.558292],
                    [-92.54928, 44.577704],
                    [-92.618025, 44.61287000000001],
                    [-92.69649100000001, 44.689436],
                    [-92.7316238513032, 44.7149227852596],
                    [-92.732043, 44.629493],
                    [-92.792584, 44.629722],
                    [-92.7926, 44.542971],
                    [-93.039344, 44.515350000000005],
                    [-93.039485, 44.471871],
                    [-93.281686, 44.471998],
                    [-93.281521, 44.543957],
                    [-93.357704, 44.544006],
                    [-93.52432700000001, 44.543618],
                    [-93.525231, 44.19613400000001],
                    [-93.767971, 44.195836],
                    [-93.768031, 44.239384],
                    [-94.011868, 44.23952],
                    [-94.025252, 44.25687500000001],
                    [-93.932685, 44.344885],
                    [-93.961661, 44.364084],
                    [-93.955259, 44.374],
                    [-93.963128, 44.381589],
                    [-93.953264, 44.38367],
                    [-93.950696, 44.40677500000001],
                    [-93.94092, 44.412347],
                    [-93.92789200000001, 44.44541],
                    [-93.92955, 44.456716],
                    [-93.885426, 44.518929],
                    [-93.910405, 44.54316300000001],
                    [-93.902479, 44.587647],
                    [-93.768039, 44.640303],
                    [-93.768176, 44.67352],
                    [-93.888831, 44.673409],
                    [-93.88863600000002, 44.717044],
                    [-94.010494, 44.717464],
                    [-94.012236, 44.978712],
                    [-94.256074, 44.979465],
                    [-94.256751, 45.239272],
                    [-94.261131, 45.283941],
                ],
            ],
        },
        properties: {
            CSAFP: '378',
            CBSAFP: '33460',
            AFFGEOID: '310M600US33460',
            GEOID: '33460',
            NAME: 'Minneapolis-St. Paul-Bloomington, MN-WI',
            NAMELSAD: 'Minneapolis-St. Paul-Bloomington, MN-WI Metro Area',
            LSAD: 'M1',
            ALAND: 18253426746,
            AWATER: 1223318923,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.512158, 37.88169, -78.749396, 38.4797415574873],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.438103, 38.216235],
                    [-79.308495, 38.382963],
                    [-79.3112960655144, 38.418454601455],
                    [-79.297758, 38.416438],
                    [-79.23162000000002, 38.474041],
                    [-79.2284230027078, 38.4797415574873],
                    [-78.913046, 38.303645],
                    [-78.888258, 38.303691],
                    [-78.749396, 38.20664800000001],
                    [-78.781078, 38.080757],
                    [-78.838874, 38.04737],
                    [-78.902282, 37.951402],
                    [-79.005129, 37.88169],
                    [-79.062454, 37.9176],
                    [-79.15739, 37.891003],
                    [-79.183978, 37.914194],
                    [-79.482668, 38.086054],
                    [-79.436678, 38.1628],
                    [-79.512158, 38.180419],
                    [-79.438103, 38.216235],
                ],
            ],
        },
        properties: {
            CSAFP: '277',
            CBSAFP: '44420',
            AFFGEOID: '310M600US44420',
            GEOID: '44420',
            NAME: 'Staunton, VA',
            NAMELSAD: 'Staunton, VA Metro Area',
            LSAD: 'M1',
            ALAND: 2595074273,
            AWATER: 10049475,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-117.596188685737, 32.534156, -116.08109000000002, 33.505025],
            type: 'Polygon',
            coordinates: [
                [
                    [-117.596188685737, 33.3869649943199],
                    [-117.57848000000001, 33.453927],
                    [-117.50861400000001, 33.469614],
                    [-117.50972199999998, 33.505019],
                    [-117.364272, 33.505025],
                    [-117.25930799999999, 33.454513],
                    [-117.250495, 33.451629],
                    [-117.24166800000002, 33.448928],
                    [-117.241271, 33.431993],
                    [-117.141574, 33.43261],
                    [-117.08902400000001, 33.430286],
                    [-117.03086099999999, 33.426931],
                    [-116.821826, 33.426873],
                    [-116.08516499999999, 33.425932],
                    [-116.08109000000002, 33.074833],
                    [-116.105634, 32.72157],
                    [-116.10617152617601, 32.6185787329199],
                    [-116.540643, 32.583747],
                    [-116.62705000000001, 32.576261],
                    [-116.85715400000001, 32.557459],
                    [-117.12486200000001, 32.534156],
                    [-117.13203895573798, 32.5856011873821],
                    [-117.13666400000001, 32.618754],
                    [-117.16886600000001, 32.671952],
                    [-117.177325778289, 32.6770758949612],
                    [-117.19676700000001, 32.688851],
                    [-117.24606900000002, 32.669352],
                    [-117.255169, 32.700051],
                    [-117.25496999999999, 32.786948],
                    [-117.28097100000001, 32.822247],
                    [-117.28217, 32.839547],
                    [-117.27386999999999, 32.851447],
                    [-117.26290499999999, 32.849349],
                    [-117.25617, 32.859447],
                    [-117.25616053514901, 32.8596735941017],
                    [-117.25447, 32.900146],
                    [-117.272139427178, 32.9755245825488],
                    [-117.28077, 33.012343],
                    [-117.315278, 33.093504],
                    [-117.362572, 33.168437],
                    [-117.44558300000001, 33.268517],
                    [-117.54769300000001, 33.365491],
                    [-117.59588000000001, 33.386629],
                    [-117.596188685737, 33.3869649943199],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '41740',
            AFFGEOID: '310M600US41740',
            GEOID: '41740',
            NAME: 'San Diego-Chula Vista-Carlsbad, CA',
            NAMELSAD: 'San Diego-Chula Vista-Carlsbad, CA Metro Area',
            LSAD: 'M1',
            ALAND: 10904515125,
            AWATER: 817257158,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-74.967274, 38.928519, -74.5407878555825, 39.323815],
            type: 'Polygon',
            coordinates: [
                [
                    [-74.955363, 39.001262000000004],
                    [-74.903664, 39.087437],
                    [-74.885914, 39.143627],
                    [-74.905181, 39.17494500000001],
                    [-74.9151558055627, 39.1766975035188],
                    [-74.926347, 39.209012],
                    [-74.861294, 39.323815],
                    [-74.653206, 39.288558],
                    [-74.5407878555825, 39.3000254911844],
                    [-74.581008, 39.27081900000001],
                    [-74.646595, 39.212002],
                    [-74.67143, 39.179802],
                    [-74.714341, 39.119804],
                    [-74.705876, 39.102937],
                    [-74.778777, 39.023073],
                    [-74.80791700000002, 38.985948],
                    [-74.86445800000001, 38.94041],
                    [-74.933571, 38.928519],
                    [-74.967274, 38.93341300000001],
                    [-74.955363, 39.001262000000004],
                ],
            ],
        },
        properties: {
            CSAFP: '428',
            CBSAFP: '36140',
            AFFGEOID: '310M600US36140',
            GEOID: '36140',
            NAME: 'Ocean City, NJ',
            NAMELSAD: 'Ocean City, NJ Metro Area',
            LSAD: 'M1',
            ALAND: 651437935,
            AWATER: 955149083,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-104.150494, 40.000856, -103.465377, 40.524389],
            type: 'Polygon',
            coordinates: [
                [
                    [-104.150494, 40.35024500000001],
                    [-104.147714, 40.524389],
                    [-103.581844, 40.523383],
                    [-103.4672, 40.523253],
                    [-103.465377, 40.436346],
                    [-103.47199, 40.001502],
                    [-103.705701, 40.001366],
                    [-104.150332, 40.000856],
                    [-104.150494, 40.35024500000001],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '22820',
            AFFGEOID: '310M600US22820',
            GEOID: '22820',
            NAME: 'Fort Morgan, CO',
            NAMELSAD: 'Fort Morgan, CO Micro Area',
            LSAD: 'M2',
            ALAND: 3316441488,
            AWATER: 34663098,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.319957, 40.902313, -75.440658, 41.651825],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.310261, 41.310198],
                    [-76.282799, 41.377244],
                    [-76.228131, 41.511137],
                    [-76.220073, 41.541278],
                    [-76.197998, 41.647823],
                    [-76.115172, 41.651825],
                    [-76.097526, 41.644231],
                    [-75.719887, 41.642263],
                    [-75.462411, 41.641587],
                    [-75.440658, 41.258125],
                    [-75.505607, 41.232539],
                    [-75.543054, 41.224051],
                    [-75.600736, 41.161497],
                    [-75.649412, 41.122304],
                    [-75.692669, 41.131257],
                    [-75.767284, 41.093113],
                    [-75.732183, 41.008124],
                    [-75.985994, 40.917439],
                    [-75.997636, 40.913259],
                    [-76.028006, 40.902313],
                    [-76.207827, 40.94974],
                    [-76.228975, 41.138466],
                    [-76.277639, 41.131804],
                    [-76.319957, 41.211255],
                    [-76.310261, 41.310198],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '42540',
            AFFGEOID: '310M600US42540',
            GEOID: '42540',
            NAME: 'Scranton--Wilkes-Barre, PA',
            NAMELSAD: 'Scranton--Wilkes-Barre, PA Metro Area',
            LSAD: 'M1',
            ALAND: 4522152236,
            AWATER: 78052814,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-94.978128, 31.224753999999997, -94.300622, 31.845555000000004],
            type: 'Polygon',
            coordinates: [
                [
                    [-94.937532, 31.845555000000004],
                    [-94.452513, 31.844113],
                    [-94.432715, 31.82221],
                    [-94.399091, 31.653595999999997],
                    [-94.311042, 31.589252000000002],
                    [-94.300622, 31.526458],
                    [-94.33119, 31.362966],
                    [-94.339773, 31.240820000000003],
                    [-94.324461, 31.230165],
                    [-94.326616, 31.224753999999997],
                    [-94.396017, 31.302356000000003],
                    [-94.449422, 31.321337],
                    [-94.49587400000001, 31.405728000000003],
                    [-94.53136, 31.398540000000004],
                    [-94.545121, 31.431855],
                    [-94.82287, 31.483978],
                    [-94.865857, 31.526915999999996],
                    [-94.924219, 31.579487],
                    [-94.978128, 31.793294],
                    [-94.937532, 31.845555000000004],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '34860',
            AFFGEOID: '310M600US34860',
            GEOID: '34860',
            NAME: 'Nacogdoches, TX',
            NAMELSAD: 'Nacogdoches, TX Micro Area',
            LSAD: 'M2',
            ALAND: 2450966944,
            AWATER: 89731149,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.73977, 43.891511, -88.041794, 44.590605000000004],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.736802, 44.590605000000004],
                    [-88.606083, 44.590515],
                    [-88.245204, 44.584746],
                    [-88.190465, 44.586799],
                    [-88.192611, 44.241947],
                    [-88.04324, 44.241018],
                    [-88.041794, 43.891692],
                    [-88.162274, 43.891511],
                    [-88.161654, 43.937683],
                    [-88.404187, 43.9382],
                    [-88.40407, 44.244102],
                    [-88.524791, 44.244028],
                    [-88.73977, 44.243303],
                    [-88.736802, 44.590605000000004],
                ],
            ],
        },
        properties: {
            CSAFP: '118',
            CBSAFP: '11540',
            AFFGEOID: '310M600US11540',
            GEOID: '11540',
            NAME: 'Appleton, WI',
            NAMELSAD: 'Appleton, WI Metro Area',
            LSAD: 'M1',
            ALAND: 2475529498,
            AWATER: 222422628,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-92.59522, 35.974007, -92.15030626100702, 36.498165682126],
            type: 'Polygon',
            coordinates: [
                [
                    [-92.59522, 36.354922],
                    [-92.533104, 36.386557],
                    [-92.5291361751611, 36.498165682126],
                    [-92.350277, 36.497787],
                    [-92.15030626100702, 36.4981403333038],
                    [-92.158941, 36.278518],
                    [-92.15693100000001, 36.261221],
                    [-92.159928, 36.173792000000006],
                    [-92.215212, 36.13397],
                    [-92.19564, 36.133009],
                    [-92.197449, 36.059239],
                    [-92.305109, 36.061087],
                    [-92.307215, 35.974007],
                    [-92.414396, 35.975798],
                    [-92.411563, 36.062757],
                    [-92.411862, 36.162183],
                    [-92.480417, 36.199417],
                    [-92.473163, 36.2568],
                    [-92.54297, 36.263058],
                    [-92.59522, 36.354922],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '34260',
            AFFGEOID: '310M600US34260',
            GEOID: '34260',
            NAME: 'Mountain Home, AR',
            NAMELSAD: 'Mountain Home, AR Micro Area',
            LSAD: 'M2',
            ALAND: 1434660641,
            AWATER: 84907080,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.280566, 45.935245, -96.265366, 46.6357466727167],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.279948, 46.629348],
                    [-96.816308, 46.630185],
                    [-96.7897865130498, 46.6357466727167],
                    [-96.78578618259851, 46.6295908492609],
                    [-96.281196, 46.630779],
                    [-96.28, 46.28222],
                    [-96.265366, 46.10861],
                    [-96.26614, 46.02161],
                    [-96.572697494194, 46.0218916713577],
                    [-96.574264, 46.016545],
                    [-96.563672, 45.935245],
                    [-97.082093, 45.935842],
                    [-97.22829100000001, 45.9356565956461],
                    [-97.259632, 45.997185],
                    [-97.25934, 46.272786],
                    [-97.280566, 46.282393],
                    [-97.279948, 46.629348],
                ],
            ],
        },
        properties: {
            CSAFP: '244',
            CBSAFP: '47420',
            AFFGEOID: '310M600US47420',
            GEOID: '47420',
            NAME: 'Wahpeton, ND-MN',
            NAMELSAD: 'Wahpeton, ND-MN Micro Area',
            LSAD: 'M2',
            ALAND: 5663558855,
            AWATER: 25515714,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.144388, 38.473878, -88.695165, 38.826299],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.143866, 38.503089],
                    [-89.138393, 38.73633100000001],
                    [-89.138138, 38.824209],
                    [-88.695165, 38.826299],
                    [-88.698459, 38.606062],
                    [-88.698961, 38.474914000000005],
                    [-89.144388, 38.473878],
                    [-89.143866, 38.503089],
                ],
            ],
        },
        properties: {
            CSAFP: '476',
            CBSAFP: '16460',
            AFFGEOID: '310M600US16460',
            GEOID: '16460',
            NAME: 'Centralia, IL',
            NAMELSAD: 'Centralia, IL Micro Area',
            LSAD: 'M2',
            ALAND: 1482865616,
            AWATER: 9234118,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-81.650026, 40.4247, -80.861994, 40.988717],
            type: 'Polygon',
            coordinates: [
                [
                    [-81.650026, 40.667774],
                    [-81.647991, 40.825776],
                    [-81.647735, 40.914016],
                    [-81.420363, 40.906502],
                    [-81.393249, 40.988531],
                    [-81.357529, 40.988717],
                    [-81.195679, 40.988205],
                    [-81.183849, 40.988208],
                    [-81.086312, 40.988031],
                    [-81.086679, 40.901609],
                    [-81.086686, 40.898858],
                    [-81.087289, 40.727816],
                    [-80.917172, 40.726934],
                    [-80.918125, 40.643464],
                    [-80.861994, 40.599404],
                    [-80.863567, 40.555411],
                    [-80.92092, 40.556315],
                    [-80.92456600000001, 40.467975],
                    [-80.941911, 40.4247],
                    [-81.212309, 40.433457],
                    [-81.237687, 40.432461],
                    [-81.269951, 40.433486],
                    [-81.268434, 40.476714],
                    [-81.264744, 40.565042],
                    [-81.321659, 40.56664800000001],
                    [-81.317739, 40.651579],
                    [-81.459019, 40.66692],
                    [-81.64911, 40.634525],
                    [-81.650026, 40.667774],
                ],
            ],
        },
        properties: {
            CSAFP: '184',
            CBSAFP: '15940',
            AFFGEOID: '310M600US15940',
            GEOID: '15940',
            NAME: 'Canton-Massillon, OH',
            NAMELSAD: 'Canton-Massillon, OH Metro Area',
            LSAD: 'M1',
            ALAND: 2511970922,
            AWATER: 24829507,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-119.874042, 45.8382266026357, -118.21024300000002, 46.73883300000001],
            type: 'Polygon',
            coordinates: [
                [
                    [-119.874042, 46.628283],
                    [-119.61847900000001, 46.647583],
                    [-119.51138799999998, 46.727687],
                    [-119.45319000000002, 46.679243],
                    [-119.36916499999998, 46.67817900000001],
                    [-119.36942899999998, 46.737695],
                    [-118.358881, 46.73662300000001],
                    [-118.21024300000002, 46.73883300000001],
                    [-118.24912200000001, 46.734138],
                    [-118.21566000000001, 46.588924],
                    [-118.228635, 46.593362],
                    [-118.471646, 46.597262],
                    [-118.615592, 46.504543],
                    [-118.63382600000001, 46.414022],
                    [-118.746199, 46.359512],
                    [-118.76689799999998, 46.297916],
                    [-118.86073300000001, 46.252455],
                    [-119.023417, 46.216656],
                    [-119.04149799999999, 46.192667],
                    [-118.974664, 46.139523],
                    [-118.941323, 46.027528],
                    [-118.987227539565, 45.9998002135237],
                    [-119.061462, 45.958527],
                    [-119.12612, 45.932859],
                    [-119.25715, 45.939926],
                    [-119.36439600000001, 45.921605],
                    [-119.432138558816, 45.9132091442339],
                    [-119.48782900000002, 45.906307],
                    [-119.571584, 45.925456],
                    [-119.600549, 45.919581],
                    [-119.66987700000001, 45.856867],
                    [-119.80265499999999, 45.84753],
                    [-119.868153889391, 45.8382266026357],
                    [-119.86582899999999, 46.040858],
                    [-119.874042, 46.628283],
                ],
            ],
        },
        properties: {
            CSAFP: '313',
            CBSAFP: '28420',
            AFFGEOID: '310M600US28420',
            GEOID: '28420',
            NAME: 'Kennewick-Richland, WA',
            NAMELSAD: 'Kennewick-Richland, WA Metro Area',
            LSAD: 'M1',
            ALAND: 7619028337,
            AWATER: 214802393,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.873025, 34.581799, -84.946339, 35.567422],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.873025, 35.223362],
                    [-85.740113, 35.215295],
                    [-85.709418, 35.289251],
                    [-85.558551, 35.319292],
                    [-85.485622, 35.406307],
                    [-85.607326, 35.531651],
                    [-85.557535, 35.532977],
                    [-85.459856, 35.54685],
                    [-85.423804, 35.567422],
                    [-85.436147, 35.541365],
                    [-85.403298, 35.49228],
                    [-85.225877, 35.354276],
                    [-85.168302, 35.455614],
                    [-85.151468, 35.4452],
                    [-85.131094, 35.458004],
                    [-85.102158, 35.428442],
                    [-85.016661, 35.409101],
                    [-85.030369, 35.379122],
                    [-84.946339, 35.287717],
                    [-85.026643, 35.136171],
                    [-85.00847800000001, 35.09269],
                    [-84.961146, 35.015175],
                    [-84.9769738065923, 34.9872198373549],
                    [-84.9798540252315, 34.9872056139756],
                    [-85.061648, 34.819478000000004],
                    [-85.144789, 34.767639],
                    [-85.168851, 34.720279],
                    [-85.050847, 34.71998],
                    [-85.050446, 34.622482000000005],
                    [-85.069159, 34.587184],
                    [-85.107748, 34.587483],
                    [-85.360191, 34.581799],
                    [-85.526894818161, 34.5886856927335],
                    [-85.5344052578154, 34.6237903384358],
                    [-85.561424, 34.750079],
                    [-85.5828123337853, 34.8604354744776],
                    [-85.595165, 34.924171],
                    [-85.605165, 34.984678],
                    [-85.8639461118069, 34.9870308587481],
                    [-85.863319, 35.21106],
                    [-85.873025, 35.223362],
                ],
            ],
        },
        properties: {
            CSAFP: '174',
            CBSAFP: '16860',
            AFFGEOID: '310M600US16860',
            GEOID: '16860',
            NAME: 'Chattanooga, TN-GA',
            NAMELSAD: 'Chattanooga, TN-GA Metro Area',
            LSAD: 'M1',
            ALAND: 5409949984,
            AWATER: 126699774,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.681596, 34.431712, -83.3400391067846, 34.822993],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.652194, 34.822993],
                    [-83.586897, 34.819991],
                    [-83.534904, 34.740013],
                    [-83.431465, 34.747272],
                    [-83.3496085109133, 34.7170105826273],
                    [-83.3400391067846, 34.6863277104121],
                    [-83.365044, 34.675308],
                    [-83.45843, 34.483933],
                    [-83.537538, 34.491725],
                    [-83.615218, 34.431712],
                    [-83.666415, 34.503602],
                    [-83.616615, 34.54163],
                    [-83.662819, 34.659801],
                    [-83.623296, 34.732194],
                    [-83.681596, 34.800882],
                    [-83.652194, 34.822993],
                ],
            ],
        },
        properties: {
            CSAFP: '122',
            CBSAFP: '18460',
            AFFGEOID: '310M600US18460',
            GEOID: '18460',
            NAME: 'Cornelia, GA',
            NAMELSAD: 'Cornelia, GA Micro Area',
            LSAD: 'M2',
            ALAND: 717083984,
            AWATER: 5669790,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.25730500000002, 40.061568, -77.681334, 40.743681],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.25730500000002, 40.297883],
                    [-78.130081, 40.48199],
                    [-78.19708300000002, 40.558444],
                    [-78.166371, 40.617948],
                    [-78.22197, 40.674539],
                    [-78.116521, 40.739127],
                    [-78.085263, 40.73092],
                    [-77.94425, 40.691557],
                    [-77.824506, 40.743681],
                    [-77.681334, 40.729758],
                    [-77.68708, 40.67675],
                    [-77.841827, 40.550035],
                    [-77.816513, 40.500074],
                    [-77.913844, 40.398679],
                    [-77.856914, 40.365228],
                    [-77.75212, 40.378545],
                    [-77.70303, 40.263576],
                    [-77.737933, 40.237079],
                    [-77.864489, 40.061568],
                    [-77.92556, 40.103977],
                    [-78.134863, 40.165131],
                    [-78.236302, 40.224559],
                    [-78.25730500000002, 40.297883],
                ],
            ],
        },
        properties: {
            CSAFP: '107',
            CBSAFP: '26500',
            AFFGEOID: '310M600US26500',
            GEOID: '26500',
            NAME: 'Huntingdon, PA',
            NAMELSAD: 'Huntingdon, PA Micro Area',
            LSAD: 'M2',
            ALAND: 2265337347,
            AWATER: 37851956,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.9913881531823, 34.203148, -81.643552, 35.202483],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.978252, 34.492579],
                    [-82.840009, 34.623311],
                    [-82.855588, 34.72527],
                    [-82.900458, 34.741449],
                    [-82.903309, 34.935044],
                    [-82.943604, 34.988154],
                    [-82.897559231248, 35.0560052045865],
                    [-82.897499, 35.056021],
                    [-82.783283, 35.0856],
                    [-82.7617976610408, 35.0818284636457],
                    [-82.746431, 35.079131],
                    [-82.68604, 35.124545],
                    [-82.5777151484413, 35.1464753478475],
                    [-82.53256, 35.155617],
                    [-82.455609, 35.177425],
                    [-82.411301, 35.202483],
                    [-82.3531752729195, 35.1987141296007],
                    [-82.295354, 35.19496500000001],
                    [-82.215996376597, 35.1932531051427],
                    [-82.226416, 34.850439],
                    [-82.165175, 34.796537],
                    [-82.145587, 34.785208000000004],
                    [-82.004644, 34.662349],
                    [-81.863039, 34.580211],
                    [-81.851865, 34.594775],
                    [-81.677321, 34.557972],
                    [-81.643552, 34.533652],
                    [-81.703198, 34.452631],
                    [-81.73710300000002, 34.430544],
                    [-81.782627, 34.365089],
                    [-81.89533100000001, 34.300497],
                    [-81.945019, 34.203148],
                    [-82.08367, 34.299240000000005],
                    [-82.168857, 34.330151],
                    [-82.246337, 34.409676],
                    [-82.31136800000002, 34.46762],
                    [-82.313871, 34.484335],
                    [-82.7359800166139, 34.215462648637],
                    [-82.744982, 34.244861],
                    [-82.7746287310121, 34.2883668182547],
                    [-82.78030800000002, 34.296701],
                    [-82.82342, 34.358872],
                    [-82.841997, 34.399766],
                    [-82.873831, 34.471508],
                    [-82.925766, 34.481802],
                    [-82.9913881531823, 34.4729806272358],
                    [-82.978252, 34.492579],
                ],
            ],
        },
        properties: {
            CSAFP: '273',
            CBSAFP: '24860',
            AFFGEOID: '310M600US24860',
            GEOID: '24860',
            NAME: 'Greenville-Anderson, SC',
            NAMELSAD: 'Greenville-Anderson, SC Metro Area',
            LSAD: 'M1',
            ALAND: 7017831645,
            AWATER: 200537621,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-83.956319, 36.582581, -83.4609546474638, 36.955786],
            type: 'Polygon',
            coordinates: [
                [
                    [-83.956319, 36.645433],
                    [-83.877503, 36.687259],
                    [-83.798248, 36.785197],
                    [-83.566142, 36.931525],
                    [-83.589521, 36.955786],
                    [-83.509083, 36.938509],
                    [-83.489813, 36.895413],
                    [-83.500891, 36.733254],
                    [-83.4609546474638, 36.6661310362733],
                    [-83.527112, 36.665985],
                    [-83.614513, 36.633983],
                    [-83.675413, 36.600814],
                    [-83.690714, 36.582581],
                    [-83.894421, 36.586481],
                    [-83.9307607839439, 36.5876942581124],
                    [-83.956319, 36.645433],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '33180',
            AFFGEOID: '310M600US33180',
            GEOID: '33180',
            NAME: 'Middlesborough, KY',
            NAMELSAD: 'Middlesborough, KY Micro Area',
            LSAD: 'M2',
            ALAND: 929999513,
            AWATER: 5288008,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.72162, 39.7216419899246, -75.873375, 40.315971],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.697664, 40.156342],
                    [-76.566339, 40.196644],
                    [-76.307578, 40.255066],
                    [-76.151215, 40.315971],
                    [-76.091764, 40.277692],
                    [-75.873375, 40.137107],
                    [-75.942997, 40.109266],
                    [-75.93587000000001, 40.037466],
                    [-75.994629, 39.952429],
                    [-75.991644, 39.868713],
                    [-76.101791, 39.724271],
                    [-76.1356974269902, 39.7217675506067],
                    [-76.2332793471166, 39.7216462556279],
                    [-76.233485, 39.721646],
                    [-76.2396837411146, 39.7216419899246],
                    [-76.47358, 39.947352],
                    [-76.51719000000001, 40.018],
                    [-76.534524, 40.051348],
                    [-76.644508, 40.058069],
                    [-76.663825, 40.063227],
                    [-76.72162, 40.12007],
                    [-76.697664, 40.156342],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '29540',
            AFFGEOID: '310M600US29540',
            GEOID: '29540',
            NAME: 'Lancaster, PA',
            NAMELSAD: 'Lancaster, PA Metro Area',
            LSAD: 'M1',
            ALAND: 2444751632,
            AWATER: 103368819,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-91.950812, 40.193088, -90.904216, 40.813544],
            type: 'Polygon',
            coordinates: [
                [
                    [-91.949747, 40.300832],
                    [-91.9431174596857, 40.606060586354],
                    [-91.939292, 40.60615],
                    [-91.729115, 40.61364],
                    [-91.7166549876819, 40.603740139296],
                    [-91.718787, 40.813544],
                    [-91.409453, 40.812905],
                    [-91.208185, 40.71358],
                    [-91.1182229383975, 40.6995346508191],
                    [-91.12082, 40.672777],
                    [-91.1854606087043, 40.6381118126235],
                    [-90.904216, 40.639201],
                    [-90.90975600000002, 40.284394000000006],
                    [-90.911969, 40.193088],
                    [-91.5062615151283, 40.2001550699717],
                    [-91.4969574903395, 40.2487038763728],
                    [-91.950812, 40.25721],
                    [-91.949747, 40.300832],
                ],
            ],
        },
        properties: {
            CSAFP: '161',
            CBSAFP: '22800',
            AFFGEOID: '310M600US22800',
            GEOID: '22800',
            NAME: 'Fort Madison-Keokuk, IA-IL-MO',
            NAMELSAD: 'Fort Madison-Keokuk, IA-IL-MO Micro Area',
            LSAD: 'M2',
            ALAND: 4703171981,
            AWATER: 127526103,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.2332793471166, 39.291594, -74.389708, 40.6090569611221],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.2332793471166, 39.7216462556279],
                    [-76.1356974269902, 39.7217675506067],
                    [-76.101791, 39.724271],
                    [-75.991644, 39.868713],
                    [-75.994629, 39.952429],
                    [-75.93587000000001, 40.037466],
                    [-75.942997, 40.109266],
                    [-75.873375, 40.137107],
                    [-75.696782, 40.241863],
                    [-75.559753, 40.409723],
                    [-75.529694, 40.446995],
                    [-75.484057, 40.418451],
                    [-75.409733, 40.487984],
                    [-75.333514, 40.537057],
                    [-75.258151, 40.582009],
                    [-75.1892364013559, 40.6090569611221],
                    [-75.1881998781252, 40.5926133666486],
                    [-75.186737, 40.569406],
                    [-75.136748, 40.575731],
                    [-75.078503, 40.548296],
                    [-75.062227, 40.481391],
                    [-75.070568, 40.455165],
                    [-75.056102, 40.416066],
                    [-75.024775, 40.403455],
                    [-74.969597, 40.39977],
                    [-74.946006, 40.35730600000001],
                    [-74.9281105690914, 40.3398284476893],
                    [-74.90331, 40.315607],
                    [-74.856508, 40.277407],
                    [-74.823907, 40.241508],
                    [-74.760605, 40.198909],
                    [-74.7484976572578, 40.184908614104],
                    [-74.721604, 40.15381],
                    [-74.7233792971515, 40.1528955462317],
                    [-74.71847, 40.150029],
                    [-74.706269, 40.161793],
                    [-74.704177, 40.168479],
                    [-74.710782, 40.168045],
                    [-74.710099, 40.181283],
                    [-74.58783500000001, 40.138254],
                    [-74.553105, 40.07913],
                    [-74.434223, 39.855157],
                    [-74.389708, 39.773283],
                    [-74.39812, 39.576575],
                    [-74.41739300000002, 39.557255],
                    [-74.475664, 39.55146],
                    [-74.654801, 39.626846],
                    [-74.723856, 39.722063],
                    [-74.736216, 39.729775],
                    [-74.800087, 39.67486400000001],
                    [-74.87726, 39.608352],
                    [-74.911882, 39.578462],
                    [-74.985271, 39.514967],
                    [-75.061854, 39.568746],
                    [-75.080309, 39.460648],
                    [-75.23843400000001, 39.559039],
                    [-75.368213, 39.456479],
                    [-75.4171070774881, 39.3889106867137],
                    [-75.442393, 39.402291],
                    [-75.465212, 39.43893],
                    [-75.536431, 39.460559],
                    [-75.528088, 39.498114],
                    [-75.593068, 39.479186],
                    [-75.57183, 39.438897],
                    [-75.521682, 39.387871],
                    [-75.5056428576184, 39.3703942225828],
                    [-75.584765, 39.30864400000001],
                    [-75.619631, 39.310058],
                    [-75.651159, 39.291594],
                    [-75.714901, 39.299366],
                    [-75.7604414164505, 39.2967896211001],
                    [-75.766895, 39.377499],
                    [-75.7669046067092, 39.3776515935512],
                    [-75.981506, 39.36684],
                    [-76.0614988219557, 39.3877481141151],
                    [-76.040962, 39.394237],
                    [-76.00688, 39.414527],
                    [-76.012312, 39.453115],
                    [-76.0376462883158, 39.4526423823093],
                    [-76.041976, 39.494228],
                    [-76.094472, 39.56781],
                    [-76.212676, 39.686405],
                    [-76.2332793471166, 39.7216462556279],
                ],
            ],
        },
        properties: {
            CSAFP: '428',
            CBSAFP: '37980',
            AFFGEOID: '310M600US37980',
            GEOID: '37980',
            NAME: 'Philadelphia-Camden-Wilmington, PA-NJ-DE-MD',
            NAMELSAD: 'Philadelphia-Camden-Wilmington, PA-NJ-DE-MD Metro Area',
            LSAD: 'M1',
            ALAND: 11922245235,
            AWATER: 691935697,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-93.496377, 38.928244, -92.849224, 39.401787],
            type: 'Polygon',
            coordinates: [
                [
                    [-93.477233, 39.292796],
                    [-93.399133, 39.226437],
                    [-93.333337, 39.250012],
                    [-93.332252, 39.309606],
                    [-93.230079, 39.327877],
                    [-93.200708, 39.401787],
                    [-93.104485, 39.383656],
                    [-93.072915, 39.33552],
                    [-92.959801, 39.312526],
                    [-92.911669, 39.223946000000005],
                    [-92.849224, 39.226218],
                    [-92.94385, 39.121738],
                    [-92.934569, 39.064547],
                    [-93.048471, 38.972418],
                    [-93.050453, 38.928244],
                    [-93.496377, 38.942837],
                    [-93.477233, 39.292796],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '32180',
            AFFGEOID: '310M600US32180',
            GEOID: '32180',
            NAME: 'Marshall, MO',
            NAMELSAD: 'Marshall, MO Micro Area',
            LSAD: 'M2',
            ALAND: 1956761965,
            AWATER: 29542462,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-114.047268153077, 39.904458, -111.553266, 41.076888],
            type: 'Polygon',
            coordinates: [
                [
                    [-114.046386, 40.097896],
                    [-114.046372817373, 40.1169358330122],
                    [-114.046178, 40.398313],
                    [-114.045577, 40.495801],
                    [-114.043505, 40.726292],
                    [-114.042145146235, 40.9998965772633],
                    [-112.79935900000001, 40.999939],
                    [-112.493515, 41.076888],
                    [-112.260216, 40.769093],
                    [-112.00656600000002, 40.921846],
                    [-111.958672, 40.921822000000006],
                    [-111.946289, 40.82179],
                    [-111.738744, 40.860998],
                    [-111.663996, 40.850194],
                    [-111.641052, 40.798925],
                    [-111.649129, 40.773443],
                    [-111.606221, 40.712856],
                    [-111.604411, 40.666523],
                    [-111.553266, 40.609313],
                    [-111.593879, 40.577063],
                    [-111.60617, 40.564623],
                    [-111.913313, 40.452523],
                    [-111.98213600000001, 40.414217],
                    [-112.172862, 40.467065],
                    [-112.212254, 40.459114],
                    [-112.175533, 40.335837],
                    [-112.179693, 40.23224],
                    [-112.14685600000001, 40.177817000000005],
                    [-112.1798, 40.011656],
                    [-112.269882, 39.938947],
                    [-112.332296, 39.952789],
                    [-112.341988, 39.904458],
                    [-113.24849800000001, 39.904714],
                    [-114.047268153077, 39.9060981137562],
                    [-114.046386, 40.097896],
                ],
            ],
        },
        properties: {
            CSAFP: '482',
            CBSAFP: '41620',
            AFFGEOID: '310M600US41620',
            GEOID: '41620',
            NAME: 'Salt Lake City, UT',
            NAMELSAD: 'Salt Lake City, UT Metro Area',
            LSAD: 'M1',
            ALAND: 19901509937,
            AWATER: 1055969622,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.733095, 35.880934, -89.154464, 36.212659],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.692437, 36.020507],
                    [-89.680029, 36.082494],
                    [-89.64302, 36.10362],
                    [-89.592102, 36.135637],
                    [-89.623804, 36.183128],
                    [-89.6276414416754, 36.185460316606004],
                    [-89.488283, 36.187544],
                    [-89.482971, 36.212659],
                    [-89.154464, 36.204678],
                    [-89.163406, 36.026044],
                    [-89.206297, 36.011814],
                    [-89.188844, 35.99976],
                    [-89.280188, 35.886416],
                    [-89.342829, 35.880934],
                    [-89.398186, 35.941369],
                    [-89.631345, 35.924133],
                    [-89.6491186188564, 35.9047155762998],
                    [-89.652279, 35.921462],
                    [-89.686924, 35.947716],
                    [-89.733095, 36.000608],
                    [-89.692437, 36.020507],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '20540',
            AFFGEOID: '310M600US20540',
            GEOID: '20540',
            NAME: 'Dyersburg, TN',
            NAMELSAD: 'Dyersburg, TN Micro Area',
            LSAD: 'M2',
            ALAND: 1326982067,
            AWATER: 35839863,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.567198, 30.234337, -90.160636, 31.000360547908905],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.5671954006585, 30.999945024399896],
                    [-90.5475744252331, 30.999982101791595],
                    [-90.3473046214524, 31.000360547908905],
                    [-90.346806, 30.905628],
                    [-90.317005, 30.89116],
                    [-90.255749, 30.712443999999998],
                    [-90.243384, 30.644831000000003],
                    [-90.244305, 30.503592000000005],
                    [-90.243649, 30.361580999999997],
                    [-90.160636, 30.234337],
                    [-90.320464, 30.298985],
                    [-90.485826, 30.276992],
                    [-90.47376, 30.345246],
                    [-90.546118, 30.430283000000003],
                    [-90.5669, 30.52613],
                    [-90.567126, 30.650014999999996],
                    [-90.567198, 30.824806],
                    [-90.5671954006585, 30.999945024399896],
                ],
            ],
        },
        properties: {
            CSAFP: '406',
            CBSAFP: '25220',
            AFFGEOID: '310M600US25220',
            GEOID: '25220',
            NAME: 'Hammond, LA',
            NAMELSAD: 'Hammond, LA Metro Area',
            LSAD: 'M1',
            ALAND: 2049124443,
            AWATER: 136690438,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-123.038404, 47.403355, -122.42962500000002, 47.9510041672091],
            type: 'Polygon',
            coordinates: [
                [
                    [-122.950621, 47.606308],
                    [-122.783237, 47.672754],
                    [-122.72197100000001, 47.787665],
                    [-122.59553500000001, 47.882298],
                    [-122.63410286528, 47.9230352997388],
                    [-122.61670100000002, 47.925139],
                    [-122.573745864315, 47.9510041672091],
                    [-122.501809, 47.929713],
                    [-122.42962500000002, 47.828098],
                    [-122.43809300000001, 47.777814],
                    [-122.445026, 47.542713],
                    [-122.53699300000001, 47.403355],
                    [-122.801199, 47.403578],
                    [-122.801012, 47.520406],
                    [-123.038404, 47.520236],
                    [-122.950621, 47.606308],
                ],
            ],
        },
        properties: {
            CSAFP: '500',
            CBSAFP: '14740',
            AFFGEOID: '310M600US14740',
            GEOID: '14740',
            NAME: 'Bremerton-Silverdale-Port Orchard, WA',
            NAMELSAD: 'Bremerton-Silverdale-Port Orchard, WA Metro Area',
            LSAD: 'M1',
            ALAND: 1023308791,
            AWATER: 442187072,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-99.60313, 28.612658, -97.633176, 30.138954000000002],
            type: 'Polygon',
            coordinates: [
                [
                    [-99.602776, 29.907679000000005],
                    [-99.176988, 29.895063],
                    [-98.917725, 29.781398],
                    [-98.920147, 30.13829],
                    [-98.587897, 30.138954000000002],
                    [-98.414018, 29.937557000000005],
                    [-98.2976, 30.037994],
                    [-98.12127, 29.912844000000003],
                    [-98.030523, 29.848539],
                    [-98.01518, 29.801485],
                    [-97.999271, 29.752444],
                    [-97.943564, 29.806914000000003],
                    [-97.875259, 29.858208],
                    [-97.799396, 29.75226],
                    [-97.780917, 29.759258],
                    [-97.768322, 29.718883],
                    [-97.633176, 29.649863],
                    [-97.664987, 29.60772],
                    [-97.840383, 29.376791],
                    [-97.85878200000002, 29.352889999999995],
                    [-97.728443, 29.221748000000005],
                    [-97.995302, 29.026104000000004],
                    [-98.190991, 28.882333],
                    [-98.098315, 28.786949],
                    [-98.335031, 28.612658],
                    [-98.33504700000002, 28.648275000000005],
                    [-98.800848, 28.647306],
                    [-98.800841, 28.647487],
                    [-98.80490000000002, 29.090434000000002],
                    [-99.41388, 29.091335],
                    [-99.411817, 29.627514],
                    [-99.60313, 29.627181],
                    [-99.602776, 29.907679000000005],
                ],
            ],
        },
        properties: {
            CSAFP: '484',
            CBSAFP: '41700',
            AFFGEOID: '310M600US41700',
            GEOID: '41700',
            NAME: 'San Antonio-New Braunfels, TX',
            NAMELSAD: 'San Antonio-New Braunfels, TX Metro Area',
            LSAD: 'M1',
            ALAND: 18941072991,
            AWATER: 149365695,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-113.334359, 33.882469, -111.463758, 35.528217],
            type: 'Polygon',
            coordinates: [
                [
                    [-113.334105, 35.508416],
                    [-113.334161, 35.528217],
                    [-113.176327, 35.507222],
                    [-113.147635, 35.47898],
                    [-112.976935, 35.392803],
                    [-112.953209, 35.352902],
                    [-112.735084, 35.305021],
                    [-112.694667, 35.311651],
                    [-112.54177, 35.236418],
                    [-112.442389, 35.25917],
                    [-112.442289, 35.149238],
                    [-112.33579100000001, 35.148234],
                    [-112.33453600000001, 34.973024],
                    [-112.008116, 34.975867],
                    [-111.777623, 34.981001],
                    [-111.778265, 34.803304],
                    [-111.552106, 34.8025],
                    [-111.55465300000002, 34.471703],
                    [-111.577285, 34.420375],
                    [-111.659644, 34.386251],
                    [-111.674381, 34.306172000000004],
                    [-111.720505, 34.160354],
                    [-111.477243, 34.150938],
                    [-111.463758, 34.072436],
                    [-111.49498800000002, 33.99995],
                    [-111.726277, 34.000045],
                    [-112.164355, 34.048165],
                    [-112.161128, 34.017717],
                    [-112.229231, 33.954186],
                    [-112.276168, 33.882469],
                    [-112.743951, 33.999898],
                    [-113.333313, 34.000039],
                    [-113.333422, 34.317913000000004],
                    [-113.334359, 34.561314],
                    [-113.334105, 35.508416],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '39150',
            AFFGEOID: '310M600US39150',
            GEOID: '39150',
            NAME: 'Prescott Valley-Prescott, AZ',
            NAMELSAD: 'Prescott Valley-Prescott, AZ Metro Area',
            LSAD: 'M1',
            ALAND: 21038299574,
            AWATER: 11356180,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.603086, 31.318365000000004, -83.649384, 31.967456000000002],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.599776, 31.920171],
                    [-84.45331, 31.919224000000003],
                    [-84.443807, 31.967456000000002],
                    [-84.357615, 31.885156000000002],
                    [-84.338245, 31.873591000000005],
                    [-84.338143, 31.916190000000004],
                    [-83.922494, 31.909653000000002],
                    [-83.939437, 31.847929],
                    [-83.845323, 31.831190000000003],
                    [-83.802723, 31.803579],
                    [-83.796846, 31.622929000000003],
                    [-83.718774, 31.622028000000004],
                    [-83.649384, 31.567977],
                    [-83.650566, 31.490338],
                    [-83.665777, 31.485135],
                    [-83.651764, 31.433918000000002],
                    [-83.65409, 31.330660999999996],
                    [-83.714134, 31.318365000000004],
                    [-83.759086, 31.332225000000005],
                    [-83.99943, 31.334966],
                    [-83.997796, 31.443753],
                    [-84.11864, 31.442478999999995],
                    [-84.14112400000002, 31.440179],
                    [-84.211593, 31.435856000000005],
                    [-84.429876, 31.436661],
                    [-84.412521, 31.45693],
                    [-84.454923, 31.548331],
                    [-84.450398, 31.621868],
                    [-84.476994, 31.621167],
                    [-84.546854, 31.621199],
                    [-84.535434, 31.676493999999998],
                    [-84.603086, 31.772001],
                    [-84.599776, 31.920171],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '10500',
            AFFGEOID: '310M600US10500',
            GEOID: '10500',
            NAME: 'Albany, GA',
            NAMELSAD: 'Albany, GA Metro Area',
            LSAD: 'M1',
            ALAND: 4120747960,
            AWATER: 46086555,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.2739282786714, 42.7675438719157, -84.836889, 43.46813],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.2739282786714, 43.1183679687078],
                    [-85.907435, 43.118911],
                    [-85.908388, 43.20592],
                    [-85.790662, 43.205167],
                    [-85.790448, 43.293003],
                    [-85.562538, 43.294271],
                    [-85.562348, 43.46813],
                    [-85.084996, 43.46619],
                    [-84.845962, 43.466158],
                    [-84.836889, 43.118851],
                    [-84.837085, 42.770479],
                    [-85.074245, 42.770784],
                    [-85.309626, 42.769878],
                    [-85.545564, 42.768139],
                    [-85.782498, 42.7682],
                    [-86.126702, 42.768402],
                    [-86.137046, 42.768342],
                    [-86.2085385029097, 42.7675438719157],
                    [-86.214138, 42.883555],
                    [-86.226305, 42.988284],
                    [-86.254646, 43.083409],
                    [-86.2739282786714, 43.1183679687078],
                ],
            ],
        },
        properties: {
            CSAFP: '266',
            CBSAFP: '24340',
            AFFGEOID: '310M600US24340',
            GEOID: '24340',
            NAME: 'Grand Rapids-Kentwood, MI',
            NAMELSAD: 'Grand Rapids-Kentwood, MI Metro Area',
            LSAD: 'M1',
            ALAND: 6964733301,
            AWATER: 2887959319,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-124.725839, 47.866822, -122.896971, 48.390691],
            type: 'Polygon',
            coordinates: [
                [
                    [-124.725839, 48.386012],
                    [-124.653243, 48.390691],
                    [-124.546259, 48.353594],
                    [-124.38087400000002, 48.284699],
                    [-124.250882, 48.264773],
                    [-124.10177300000001, 48.216883],
                    [-124.05073399999999, 48.177747],
                    [-123.880068, 48.160621],
                    [-123.728736, 48.1628],
                    [-123.672445, 48.162715],
                    [-123.551131, 48.151382],
                    [-123.44197200000002, 48.124259],
                    [-123.314578, 48.11372500000001],
                    [-123.239129, 48.118217],
                    [-123.144783, 48.175943000000004],
                    [-123.06621, 48.120469],
                    [-123.004128, 48.090516],
                    [-122.94611900000001, 48.098552],
                    [-122.91068675572498, 48.1097997439328],
                    [-122.896971, 48.065155],
                    [-122.94918, 48.06515],
                    [-122.949167, 47.866822],
                    [-123.50591699999998, 47.866897],
                    [-124.613108656052, 47.8805724417486],
                    [-124.625512, 47.887963],
                    [-124.67242700000001, 47.964414],
                    [-124.685393, 48.049238],
                    [-124.687101, 48.098657],
                    [-124.721725, 48.153185],
                    [-124.690389, 48.219745],
                    [-124.669265, 48.296353],
                    [-124.725839, 48.386012],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '38820',
            AFFGEOID: '310M600US38820',
            GEOID: '38820',
            NAME: 'Port Angeles, WA',
            NAMELSAD: 'Port Angeles, WA Micro Area',
            LSAD: 'M2',
            ALAND: 4503412739,
            AWATER: 2409309960,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.30658, 35.146315, -77.806248, 35.595877],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.30658, 35.287604],
                    [-78.153243, 35.350671],
                    [-78.15491, 35.41781],
                    [-78.064784, 35.585263],
                    [-78.048572, 35.595877],
                    [-77.873751, 35.586241],
                    [-77.822511, 35.585383],
                    [-77.826744, 35.42409],
                    [-77.806248, 35.368754],
                    [-77.818068, 35.357366],
                    [-77.834249, 35.177846],
                    [-77.892199, 35.146315],
                    [-78.044451, 35.192535],
                    [-78.16342, 35.189716],
                    [-78.173532, 35.207348],
                    [-78.24621700000002, 35.225996],
                    [-78.30658, 35.287604],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '24140',
            AFFGEOID: '310M600US24140',
            GEOID: '24140',
            NAME: 'Goldsboro, NC',
            NAMELSAD: 'Goldsboro, NC Metro Area',
            LSAD: 'M1',
            ALAND: 1434651139,
            AWATER: 9842326,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-85.2378731773596, 45.915919, -83.480639, 46.772845000000004],
            type: 'Polygon',
            coordinates: [
                [
                    [-85.2378731773596, 46.7557027812565],
                    [-85.173042, 46.763634],
                    [-84.964652, 46.772845000000004],
                    [-85.028291, 46.675125],
                    [-85.027374, 46.553756],
                    [-84.969464, 46.476290000000006],
                    [-84.849767, 46.460245],
                    [-84.678423, 46.487694],
                    [-84.60794500000002, 46.456747],
                    [-84.493401, 46.440313],
                    [-84.461827, 46.466566],
                    [-84.420274, 46.501077],
                    [-84.293016, 46.492803],
                    [-84.193729, 46.53992],
                    [-84.117925, 46.51761900000001],
                    [-84.125026, 46.470143],
                    [-84.138906, 46.37222100000001],
                    [-84.097766, 46.256512],
                    [-84.108089, 46.241238],
                    [-84.114941, 46.174114],
                    [-84.026536, 46.131648],
                    [-83.974012, 46.081552],
                    [-83.882303, 46.042065],
                    [-83.815826, 46.108529],
                    [-83.719791, 46.101031],
                    [-83.598612, 46.090085],
                    [-83.480639, 45.996164],
                    [-83.526347, 45.918636],
                    [-83.583052, 45.915919],
                    [-83.65766, 45.945463],
                    [-83.80104, 45.937582],
                    [-83.910838, 45.965613],
                    [-84.080071, 45.970822],
                    [-84.1146065625188, 45.9679083747954],
                    [-84.239332, 45.984914],
                    [-84.238884, 46.071457],
                    [-84.36309, 46.071574],
                    [-84.363503, 46.157984],
                    [-85.112513, 46.158263],
                    [-85.113329, 46.245722],
                    [-85.237839, 46.24543],
                    [-85.2378731773596, 46.7557027812565],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '42300',
            AFFGEOID: '310M600US42300',
            GEOID: '42300',
            NAME: 'Sault Ste. Marie, MI',
            NAMELSAD: 'Sault Ste. Marie, MI Micro Area',
            LSAD: 'M2',
            ALAND: 4036515583,
            AWATER: 2952489040,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.519891, 40.8513367786854, -80.098294, 41.128165],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.519891, 40.906661],
                    [-80.5196437725473, 40.98739265400581],
                    [-80.5192220860809, 41.1250935667958],
                    [-80.257933, 41.128165],
                    [-80.147303, 41.113917],
                    [-80.098294, 41.071054],
                    [-80.166472, 41.000928],
                    [-80.15853, 40.855096],
                    [-80.5197051125992, 40.8513367786854],
                    [-80.5198697076862, 40.9003239342489],
                    [-80.519891, 40.906661],
                ],
            ],
        },
        properties: {
            CSAFP: '430',
            CBSAFP: '35260',
            AFFGEOID: '310M600US35260',
            GEOID: '35260',
            NAME: 'New Castle, PA',
            NAMELSAD: 'New Castle, PA Micro Area',
            LSAD: 'M2',
            ALAND: 925571230,
            AWATER: 11444141,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-96.932452, 34.505396, -96.406135, 34.964364],
            type: 'Polygon',
            coordinates: [
                [
                    [-96.932436, 34.854496],
                    [-96.930586, 34.964364],
                    [-96.8854, 34.926251],
                    [-96.813452, 34.944649],
                    [-96.775681, 34.899958],
                    [-96.727591, 34.857182],
                    [-96.710934, 34.911715],
                    [-96.577089, 34.916584],
                    [-96.528587, 34.869586],
                    [-96.490786, 34.910585],
                    [-96.406135, 34.934191],
                    [-96.406483, 34.767328],
                    [-96.406357, 34.680023],
                    [-96.512171, 34.680065],
                    [-96.51378700000001, 34.505396],
                    [-96.827343, 34.506062],
                    [-96.82714400000002, 34.59322],
                    [-96.932423, 34.593261],
                    [-96.932452, 34.636812],
                    [-96.932436, 34.854496],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '10220',
            AFFGEOID: '310M600US10220',
            GEOID: '10220',
            NAME: 'Ada, OK',
            NAMELSAD: 'Ada, OK Micro Area',
            LSAD: 'M2',
            ALAND: 1865856327,
            AWATER: 12574651,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-94.787126, 46.156585, -93.77393, 47.477972],
            type: 'Polygon',
            coordinates: [
                [
                    [-94.787126, 46.803943],
                    [-94.659942, 46.803716],
                    [-94.670214, 47.410151],
                    [-94.416387, 47.41183000000001],
                    [-94.416279, 47.444828],
                    [-94.327531, 47.415844],
                    [-94.095115, 47.477972],
                    [-94.027858, 47.408633],
                    [-94.042287, 47.373112],
                    [-93.911505, 47.297868],
                    [-93.888213, 47.318856],
                    [-93.77401, 47.30506],
                    [-93.80882, 47.232622],
                    [-93.77393, 47.211335],
                    [-93.775257, 47.030418],
                    [-93.776024, 46.802891],
                    [-93.777905, 46.589671],
                    [-93.810407, 46.250948],
                    [-93.796281, 46.243199],
                    [-93.810802, 46.234295],
                    [-93.810362, 46.156585],
                    [-94.374592, 46.157093],
                    [-94.388922, 46.238166],
                    [-94.341679, 46.277705],
                    [-94.426529, 46.332336],
                    [-94.534724, 46.302797],
                    [-94.653462, 46.348678],
                    [-94.717126, 46.351368],
                    [-94.731442, 46.368605],
                    [-94.779121, 46.394215],
                    [-94.787126, 46.803943],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '14660',
            AFFGEOID: '310M600US14660',
            GEOID: '14660',
            NAME: 'Brainerd, MN',
            NAMELSAD: 'Brainerd, MN Micro Area',
            LSAD: 'M2',
            ALAND: 7821324097,
            AWATER: 1426317310,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-87.566798, 35.417021, -85.780148, 36.652445],
            type: 'Polygon',
            coordinates: [
                [
                    [-87.566798, 36.177857],
                    [-87.523627, 36.24889],
                    [-87.513533, 36.334713],
                    [-87.303129, 36.312442],
                    [-87.286501, 36.321933],
                    [-87.281241, 36.352794],
                    [-87.120443, 36.45546],
                    [-87.150371, 36.566824],
                    [-87.1150035566884, 36.6441420446512],
                    [-87.0608263443929, 36.6447708848516],
                    [-86.813037, 36.647647],
                    [-86.7632909641085, 36.6487206745018],
                    [-86.606394, 36.652107],
                    [-86.5620676518653, 36.6407466739073],
                    [-86.551292, 36.637985],
                    [-86.507771, 36.652445],
                    [-86.4114961989664, 36.6482404228482],
                    [-86.205565269856, 36.6392468703288],
                    [-86.081944, 36.633848],
                    [-85.975713174144, 36.6286377186817],
                    [-85.873857, 36.623642],
                    [-85.7885565436139, 36.6217118590347],
                    [-85.797033, 36.610272],
                    [-85.813885, 36.499473],
                    [-85.828026, 36.411836],
                    [-85.82766, 36.255272],
                    [-85.780148, 36.238011],
                    [-85.809168, 36.206598],
                    [-85.80741500000002, 36.131582],
                    [-85.864024, 36.093809],
                    [-85.910118, 36.116925],
                    [-86.059706, 36.086024],
                    [-86.063413, 36.070052],
                    [-86.014849, 35.96106],
                    [-85.88921, 35.857543],
                    [-85.885156, 35.839658],
                    [-85.997426, 35.711955],
                    [-85.985062, 35.660108],
                    [-86.08889000000002, 35.646823],
                    [-86.207147, 35.70379],
                    [-86.245406, 35.688899],
                    [-86.24516500000001, 35.631913],
                    [-86.34194, 35.664259],
                    [-86.402726, 35.618571],
                    [-86.543116, 35.697617],
                    [-86.639436, 35.6859],
                    [-86.686193, 35.710051],
                    [-86.751651, 35.696447],
                    [-86.782016, 35.706595],
                    [-86.849573, 35.525515],
                    [-86.960804, 35.417021],
                    [-87.129112, 35.456019],
                    [-87.20657800000001, 35.433207],
                    [-87.227932, 35.429332],
                    [-87.294534, 35.444676],
                    [-87.269432, 35.505851],
                    [-87.29719, 35.548422],
                    [-87.352305, 35.584621],
                    [-87.339232, 35.65911],
                    [-87.25561, 35.70051600000001],
                    [-87.215099, 35.850651],
                    [-87.201426, 35.940579],
                    [-87.204242, 35.959186],
                    [-87.53453, 35.993074],
                    [-87.552783, 36.016704],
                    [-87.566798, 36.177857],
                ],
            ],
        },
        properties: {
            CSAFP: '400',
            CBSAFP: '34980',
            AFFGEOID: '310M600US34980',
            GEOID: '34980',
            NAME: 'Nashville-Davidson--Murfreesboro--Franklin, TN',
            NAMELSAD: 'Nashville-Davidson--Murfreesboro--Franklin, TN Metro Area',
            LSAD: 'M1',
            ALAND: 14735597901,
            AWATER: 197082981,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.321274, 37.81179, -84.870482, 38.783399],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.321274, 38.147418],
                    [-86.253137, 38.2919],
                    [-86.25504500000001, 38.422685],
                    [-86.308674, 38.422875],
                    [-86.308701, 38.688101],
                    [-86.310058, 38.733131],
                    [-86.275281, 38.763795],
                    [-86.091705, 38.783399],
                    [-85.909677, 38.757112],
                    [-85.888334, 38.734443],
                    [-85.885643, 38.575412],
                    [-85.860996, 38.575907],
                    [-85.847893, 38.561269],
                    [-85.793584, 38.604814],
                    [-85.719718, 38.60554],
                    [-85.56998, 38.606155],
                    [-85.496666, 38.606736],
                    [-85.4314160707522, 38.5862856131089],
                    [-85.4156, 38.546341],
                    [-85.432981047742, 38.5241121702949],
                    [-85.378743, 38.518822],
                    [-85.314006, 38.492592],
                    [-85.279627, 38.496268],
                    [-85.16827, 38.585448],
                    [-85.074583, 38.596838],
                    [-84.977166, 38.512989],
                    [-84.963265, 38.437358],
                    [-84.91223, 38.459844],
                    [-84.870482, 38.356751],
                    [-84.906256, 38.374836],
                    [-84.997667, 38.335586],
                    [-84.956959, 38.332271],
                    [-84.998898, 38.274979],
                    [-85.023711, 38.129052],
                    [-85.047783, 38.072341],
                    [-85.101646, 38.03727],
                    [-85.169359, 37.997576],
                    [-85.167834, 37.971809],
                    [-85.399559, 37.939929],
                    [-85.489202, 37.990654],
                    [-85.576259, 37.885129],
                    [-85.680648, 37.814878],
                    [-85.737777, 37.81179],
                    [-85.838198, 37.879284],
                    [-85.854871, 37.945385],
                    [-85.938321, 37.998756],
                    [-85.9517264381006, 38.0149416294697],
                    [-85.976028, 38.00356],
                    [-85.9973516597543, 37.9912256022991],
                    [-86.033386, 37.970382],
                    [-86.095766, 38.00893],
                    [-86.206439, 38.021876],
                    [-86.27865600000001, 38.098509],
                    [-86.321274, 38.147418],
                ],
            ],
        },
        properties: {
            CSAFP: '350',
            CBSAFP: '31140',
            AFFGEOID: '310M600US31140',
            GEOID: '31140',
            NAME: 'Louisville/Jefferson County, KY-IN',
            NAMELSAD: 'Louisville/Jefferson County, KY-IN Metro Area',
            LSAD: 'M1',
            ALAND: 8383771619,
            AWATER: 141191581,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.417558, 39.7214393586401, -78.658305, 40.284861],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.417558, 39.853776],
                    [-79.293884, 40.040446],
                    [-79.183619, 40.110686],
                    [-79.056053, 40.284861],
                    [-78.927109, 40.284733],
                    [-78.903879, 40.275246],
                    [-78.884669, 40.244575],
                    [-78.658305, 40.243235000000006],
                    [-78.76320600000001, 40.057005],
                    [-78.757241, 39.823484],
                    [-78.8082995296216, 39.7230709562769],
                    [-78.9284161298889, 39.7229985874409],
                    [-79.045576, 39.722928],
                    [-79.3924584050224, 39.7214393586401],
                    [-79.409959, 39.734978],
                    [-79.356757, 39.781477],
                    [-79.417558, 39.853776],
                ],
            ],
        },
        properties: {
            CSAFP: '306',
            CBSAFP: '43740',
            AFFGEOID: '310M600US43740',
            GEOID: '43740',
            NAME: 'Somerset, PA',
            NAMELSAD: 'Somerset, PA Micro Area',
            LSAD: 'M2',
            ALAND: 2784357763,
            AWATER: 17118618,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-73.727775, 40.985171, -73.067851, 41.66672053071],
            type: 'Polygon',
            coordinates: [
                [
                    [-73.69593580216961, 41.1152554569041],
                    [-73.482709, 41.21276],
                    [-73.550961, 41.295422],
                    [-73.5441352908263, 41.36648508653171],
                    [-73.5433065806917, 41.3751128640277],
                    [-73.5431832079963, 41.3763973085168],
                    [-73.5431473462655, 41.3767706682974],
                    [-73.536969, 41.441094],
                    [-73.5296777143013, 41.5271610211283],
                    [-73.520017, 41.641197],
                    [-73.5179187906382, 41.66672053071],
                    [-73.506923, 41.665802],
                    [-73.441466, 41.491294],
                    [-73.386964, 41.514794],
                    [-73.310473, 41.468815],
                    [-73.29776, 41.448276],
                    [-73.207688, 41.420544],
                    [-73.183269, 41.391711],
                    [-73.147555, 41.367696],
                    [-73.115754, 41.332196],
                    [-73.067851, 41.300996000000005],
                    [-73.086852, 41.276596],
                    [-73.086752, 41.25459600000001],
                    [-73.122353, 41.179797],
                    [-73.1011738216445, 41.1637262711643],
                    [-73.108352, 41.153718],
                    [-73.130253, 41.146797],
                    [-73.1728353651274, 41.1534422791124],
                    [-73.202656, 41.158096],
                    [-73.262358, 41.117496],
                    [-73.2698175797685, 41.1166768900433],
                    [-73.297214389349, 41.1136685437012],
                    [-73.33066, 41.109996],
                    [-73.372296, 41.10402],
                    [-73.354231, 41.085639],
                    [-73.387227, 41.05824700000001],
                    [-73.422165, 41.047562],
                    [-73.468239, 41.051347],
                    [-73.516903, 41.038738],
                    [-73.561968, 41.016797],
                    [-73.595699, 41.015995],
                    [-73.657336, 40.985171],
                    [-73.6573740092614, 40.9855248838592],
                    [-73.659362, 41.004034],
                    [-73.659533, 41.017857],
                    [-73.727775, 41.100696],
                    [-73.69593580216961, 41.1152554569041],
                ],
            ],
        },
        properties: {
            CSAFP: '408',
            CBSAFP: '14860',
            AFFGEOID: '310M600US14860',
            GEOID: '14860',
            NAME: 'Bridgeport-Stamford-Norwalk, CT',
            NAMELSAD: 'Bridgeport-Stamford-Norwalk, CT Metro Area',
            LSAD: 'M1',
            ALAND: 1618664029,
            AWATER: 549280913,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-78.23503900000001, 36.707658, -76.646794, 38.007806],
            type: 'Polygon',
            coordinates: [
                [
                    [-78.23503900000001, 37.368102],
                    [-78.18500200000001, 37.444552],
                    [-78.132048, 37.454686],
                    [-78.089466, 37.639415],
                    [-78.072174, 37.657385],
                    [-78.093798, 37.70945],
                    [-78.159326, 37.748528],
                    [-78.147714, 37.773949],
                    [-78.06331, 37.905108],
                    [-78.051141, 37.901688],
                    [-77.990819, 37.857522],
                    [-77.907178, 37.821887],
                    [-77.881807, 37.757211],
                    [-77.79681200000002, 37.730608],
                    [-77.759713, 37.81148],
                    [-77.687469, 38.007806],
                    [-77.642518, 37.990688],
                    [-77.48417, 37.883369],
                    [-77.397317, 37.777289],
                    [-77.345223, 37.789285],
                    [-77.241044, 37.909908],
                    [-77.181418, 37.891654],
                    [-77.167284, 37.96390400000001],
                    [-77.070115, 37.96477000000001],
                    [-77.042643, 37.872665000000005],
                    [-76.945212, 37.828174],
                    [-76.943221, 37.782955],
                    [-76.80197, 37.794727],
                    [-76.751072, 37.728605],
                    [-76.693584, 37.627876],
                    [-76.654076, 37.603174],
                    [-76.646794, 37.481154],
                    [-76.712305, 37.431309],
                    [-76.741984, 37.467076],
                    [-76.891798, 37.432632],
                    [-76.904471, 37.377665],
                    [-76.874437, 37.365427],
                    [-76.883156, 37.223057],
                    [-76.921569, 37.22534],
                    [-76.972439, 37.248501],
                    [-77.012301, 37.232883],
                    [-77.155415, 37.112034],
                    [-76.965139, 37.054308],
                    [-76.953546, 36.94445],
                    [-77.430127, 36.707658],
                    [-77.489999, 36.721009],
                    [-77.458678, 36.862963],
                    [-77.51036, 36.845082],
                    [-77.619029, 36.877844],
                    [-77.658036, 36.894514],
                    [-77.839834, 36.995912],
                    [-77.889813, 36.988818],
                    [-77.900338, 37.143882],
                    [-77.795926, 37.192592],
                    [-77.82625, 37.202028],
                    [-78.231234, 37.29622],
                    [-78.23503900000001, 37.368102],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '40060',
            AFFGEOID: '310M600US40060',
            GEOID: '40060',
            NAME: 'Richmond, VA',
            NAMELSAD: 'Richmond, VA Metro Area',
            LSAD: 'M1',
            ALAND: 11303664171,
            AWATER: 330849539,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.6999320828408, 30.180753, -88.395023, 30.910788000000004],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.6999320828408, 30.454036880786003],
                    [-89.647072, 30.502738000000004],
                    [-89.54451, 30.516862],
                    [-89.544232, 30.648367999999998],
                    [-89.341446, 30.64772],
                    [-89.340806, 30.909912000000002],
                    [-89.137967, 30.909877],
                    [-88.885038, 30.910788000000004],
                    [-88.884534, 30.735591],
                    [-88.834081, 30.734235999999996],
                    [-88.4124674313592, 30.735597459215303],
                    [-88.41227, 30.731770999999995],
                    [-88.403931, 30.543359000000002],
                    [-88.395023, 30.369424999999996],
                    [-88.409927, 30.342115],
                    [-88.446495, 30.347753000000004],
                    [-88.471875, 30.32002],
                    [-88.522494, 30.340092],
                    [-88.581929, 30.331063],
                    [-88.613006, 30.353964],
                    [-88.66382, 30.362099],
                    [-88.700587, 30.343689],
                    [-88.746945, 30.347622],
                    [-88.8003435152035, 30.357264173094904],
                    [-88.818762, 30.36059],
                    [-88.89393, 30.393398],
                    [-88.971233, 30.390798],
                    [-89.083237, 30.368097000000002],
                    [-89.18684, 30.331197000000003],
                    [-89.294444, 30.307596000000004],
                    [-89.3070237608445, 30.303994780244103],
                    [-89.344746, 30.293195999999995],
                    [-89.424624, 30.245391],
                    [-89.447465, 30.205097999999996],
                    [-89.475824, 30.191561],
                    [-89.524504, 30.180753],
                    [-89.607655, 30.217096],
                    [-89.634208, 30.308256000000004],
                    [-89.678514, 30.414012000000003],
                    [-89.6999320828408, 30.454036880786003],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '25060',
            AFFGEOID: '310M600US25060',
            GEOID: '25060',
            NAME: 'Gulfport-Biloxi, MS',
            NAMELSAD: 'Gulfport-Biloxi, MS Metro Area',
            LSAD: 'M1',
            ALAND: 5739194892,
            AWATER: 2105708206,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-122.852917, 46.728455, -120.907425, 48.298967],
            type: 'Polygon',
            coordinates: [
                [
                    [-122.852917, 47.256462],
                    [-122.801199, 47.403578],
                    [-122.53699300000001, 47.403355],
                    [-122.445026, 47.542713],
                    [-122.43809300000001, 47.777814],
                    [-122.42962500000002, 47.828098],
                    [-122.398047, 47.861617],
                    [-122.333348, 47.933217],
                    [-122.35028899999999, 48.080312],
                    [-122.417909, 48.170622],
                    [-122.39297700000002, 48.23743],
                    [-122.45859600000001, 48.297703],
                    [-122.20127400000001, 48.297634],
                    [-121.57750100000001, 48.298967],
                    [-121.00165799999999, 48.296009],
                    [-120.907425, 48.163184],
                    [-121.015942, 48.074795],
                    [-121.15326000000002, 48.039693],
                    [-121.11818300000002, 47.997103],
                    [-121.18077600000001, 47.898769],
                    [-121.07068500000001, 47.82661600000001],
                    [-121.119179, 47.779933],
                    [-121.066005, 47.713603],
                    [-121.121584, 47.685213],
                    [-121.11356899999998, 47.597288],
                    [-121.17225, 47.590054],
                    [-121.410878, 47.424539],
                    [-121.464234, 47.354416],
                    [-121.42749200000002, 47.289075],
                    [-121.34120299999998, 47.281261],
                    [-121.36513799999999, 47.224563],
                    [-121.29763900000002, 47.148557],
                    [-121.379682, 47.087495],
                    [-121.37996100000001, 47.087248],
                    [-121.456447, 46.923577],
                    [-121.52307, 46.872783],
                    [-121.455218, 46.783797],
                    [-121.75859300000002, 46.783791],
                    [-121.84188999999999, 46.728455],
                    [-122.20311500000001, 46.763061],
                    [-122.26514200000001, 46.76530300000001],
                    [-122.30357600000002, 46.828117],
                    [-122.49067400000001, 46.867263],
                    [-122.494116, 46.905218],
                    [-122.56043600000001, 46.933457],
                    [-122.659863, 47.003225],
                    [-122.69217799999998, 47.099206],
                    [-122.759505, 47.141315000000006],
                    [-122.820576, 47.19422],
                    [-122.852917, 47.256462],
                ],
            ],
        },
        properties: {
            CSAFP: '500',
            CBSAFP: '42660',
            AFFGEOID: '310M600US42660',
            GEOID: '42660',
            NAME: 'Seattle-Tacoma-Bellevue, WA',
            NAMELSAD: 'Seattle-Tacoma-Bellevue, WA Metro Area',
            LSAD: 'M1',
            ALAND: 15203404290,
            AWATER: 1135963942,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-79.762122, 41.99825500000001, -79.060777, 42.569965],
            type: 'Polygon',
            coordinates: [
                [
                    [-79.761951, 42.26986],
                    [-79.627484, 42.324686],
                    [-79.453533, 42.411157],
                    [-79.381943, 42.466491],
                    [-79.283364, 42.511228],
                    [-79.193232, 42.545881],
                    [-79.138569, 42.564462],
                    [-79.1359444040215, 42.5691787099736],
                    [-79.107778, 42.569965],
                    [-79.060777, 42.537853],
                    [-79.0612581876299, 41.9988381187249],
                    [-79.472472, 41.99825500000001],
                    [-79.6108357749867, 41.9985199041084],
                    [-79.761313, 41.998808],
                    [-79.762122, 42.131246],
                    [-79.761951, 42.26986],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '27460',
            AFFGEOID: '310M600US27460',
            GEOID: '27460',
            NAME: 'Jamestown-Dunkirk-Fredonia, NY',
            NAMELSAD: 'Jamestown-Dunkirk-Fredonia, NY Micro Area',
            LSAD: 'M2',
            ALAND: 2746454150,
            AWATER: 1139025723,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-90.36871800000002, 35.995812, -89.95878600000002, 36.630656],
            type: 'Polygon',
            coordinates: [
                [
                    [-90.339343, 36.047112000000006],
                    [-90.294492, 36.112949],
                    [-90.23558500000001, 36.139474],
                    [-90.220425, 36.184764],
                    [-90.1891019136628, 36.1989984552544],
                    [-90.155928, 36.214074],
                    [-90.11492200000002, 36.265595],
                    [-90.06398000000002, 36.303038],
                    [-90.063526, 36.356911],
                    [-90.066136, 36.386272],
                    [-90.131038, 36.415069],
                    [-90.141399, 36.459874],
                    [-90.153871, 36.495344],
                    [-90.2207490539154, 36.4959375921945],
                    [-90.209954, 36.542034],
                    [-90.148398, 36.630656],
                    [-89.95878600000002, 36.629331],
                    [-89.96807, 36.40173],
                    [-89.96131, 36.388803],
                    [-89.95937463191622, 35.9990141141563],
                    [-90.103842, 35.998143],
                    [-90.2890769140827, 35.9965128689775],
                    [-90.36871800000002, 35.995812],
                    [-90.339343, 36.047112000000006],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '28380',
            AFFGEOID: '310M600US28380',
            GEOID: '28380',
            NAME: 'Kennett, MO',
            NAMELSAD: 'Kennett, MO Micro Area',
            LSAD: 'M2',
            ALAND: 1403538813,
            AWATER: 13708531,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-84.907753, 35.4578994395799, -83.650957, 36.592741697267],
            type: 'Polygon',
            coordinates: [
                [
                    [-84.875842, 36.285092000000006],
                    [-84.797861, 36.296894],
                    [-84.700785, 36.370824],
                    [-84.573741, 36.229977],
                    [-84.441034, 36.163478],
                    [-84.403633, 36.174778],
                    [-84.372731, 36.216778],
                    [-84.329927, 36.285086],
                    [-84.349939, 36.466292],
                    [-84.330434, 36.50948],
                    [-84.266877, 36.511698],
                    [-84.251132, 36.53418],
                    [-84.2613212015047, 36.592741697267],
                    [-84.227332, 36.592181],
                    [-84.2271939619983, 36.5921795123551],
                    [-83.98784200000001, 36.5896],
                    [-83.98761095068281, 36.5895922860725],
                    [-83.984634, 36.560482],
                    [-83.95696, 36.501555],
                    [-83.943139, 36.473564],
                    [-83.922618, 36.427779],
                    [-83.905638, 36.419979],
                    [-83.805515, 36.435879],
                    [-83.73301, 36.34498],
                    [-83.667408, 36.344381],
                    [-83.714704, 36.173783],
                    [-83.732704, 36.164583],
                    [-83.699405, 36.109469],
                    [-83.66746, 36.080587],
                    [-83.70925, 36.093234],
                    [-83.673297, 36.038486],
                    [-83.650957, 35.986368],
                    [-83.794275, 35.887125],
                    [-83.70525, 35.71158],
                    [-83.662814, 35.690618],
                    [-83.6629119015893, 35.5677997932083],
                    [-83.771736, 35.562118],
                    [-83.848502, 35.519259],
                    [-83.916801, 35.473612],
                    [-83.9532313801504, 35.4600208895767],
                    [-83.9589178345639, 35.4578994395799],
                    [-83.93854000000002, 35.487773],
                    [-83.993815, 35.550001],
                    [-84.08156100000001, 35.541231],
                    [-84.188319, 35.610549],
                    [-84.290765, 35.654925],
                    [-84.50427400000001, 35.665866],
                    [-84.52585, 35.624186],
                    [-84.566934, 35.629773],
                    [-84.584472, 35.644424],
                    [-84.619865, 35.644651],
                    [-84.61903100000002, 35.70788000000001],
                    [-84.723853, 35.75289600000001],
                    [-84.754472, 35.75439],
                    [-84.781901, 35.825],
                    [-84.729634, 35.858382],
                    [-84.680633, 35.908454],
                    [-84.720727, 35.994914],
                    [-84.80546000000001, 36.090501],
                    [-84.907753, 36.156293],
                    [-84.875842, 36.285092000000006],
                ],
            ],
        },
        properties: {
            CSAFP: '315',
            CBSAFP: '28940',
            AFFGEOID: '310M600US28940',
            GEOID: '28940',
            NAME: 'Knoxville, TN',
            NAMELSAD: 'Knoxville, TN Metro Area',
            LSAD: 'M1',
            ALAND: 8340663749,
            AWATER: 329103116,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-97.77853, 28.388684, -96.643442, 29.104046000000004],
            type: 'Polygon',
            coordinates: [
                [
                    [-97.77853, 28.668027000000002],
                    [-97.574639, 28.8133],
                    [-97.41734, 28.925228],
                    [-97.305916, 28.864037],
                    [-96.976378, 29.104046000000004],
                    [-96.938587, 29.063244],
                    [-96.832103, 29.021299],
                    [-96.792133, 28.918803],
                    [-96.711514, 28.851411],
                    [-96.643442, 28.712105000000005],
                    [-96.853757, 28.635199],
                    [-96.930367, 28.586728],
                    [-96.890951, 28.507178],
                    [-96.915284, 28.485797],
                    [-97.048529, 28.549976],
                    [-97.160751, 28.553475],
                    [-97.375579, 28.388684],
                    [-97.551369, 28.450441],
                    [-97.558496, 28.514054],
                    [-97.696572, 28.541537],
                    [-97.77853, 28.668027000000002],
                ],
            ],
        },
        properties: {
            CSAFP: '544',
            CBSAFP: '47020',
            AFFGEOID: '310M600US47020',
            GEOID: '47020',
            NAME: 'Victoria, TX',
            NAMELSAD: 'Victoria, TX Metro Area',
            LSAD: 'M1',
            ALAND: 4491343496,
            AWATER: 36475959,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-101.166753, 44.103694, -99.610311, 44.783737],
            type: 'Polygon',
            coordinates: [
                [
                    [-101.155188, 44.343147],
                    [-101.154516, 44.689947000000004],
                    [-101.136838, 44.747126],
                    [-101.022611, 44.724153],
                    [-100.910312, 44.783737],
                    [-100.717644, 44.77093],
                    [-100.613805, 44.707135],
                    [-100.620897, 44.566698],
                    [-100.526498, 44.547422],
                    [-99.676398, 44.548111],
                    [-99.66371, 44.539099],
                    [-99.663508, 44.217517],
                    [-99.695752, 44.172375],
                    [-99.610311, 44.148887],
                    [-99.612249, 44.103694],
                    [-99.768681, 44.135743000000005],
                    [-99.884485, 44.129582],
                    [-99.938217, 44.195195],
                    [-100.367247, 44.194621000000005],
                    [-100.367207, 44.168694],
                    [-101.046916, 44.168484],
                    [-101.166753, 44.168085],
                    [-101.155188, 44.343147],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '38180',
            AFFGEOID: '310M600US38180',
            GEOID: '38180',
            NAME: 'Pierre, SD',
            NAMELSAD: 'Pierre, SD Micro Area',
            LSAD: 'M2',
            ALAND: 5661606952,
            AWATER: 341706912,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-86.2769940445315, 42.4193103159263, -85.543191, 42.768402],
            type: 'Polygon',
            coordinates: [
                [
                    [-86.240642, 42.54],
                    [-86.2286948010023, 42.6295118547012],
                    [-86.226638, 42.644922],
                    [-86.208309, 42.762789],
                    [-86.2085385029097, 42.7675438719157],
                    [-86.137046, 42.768342],
                    [-86.126702, 42.768402],
                    [-85.782498, 42.7682],
                    [-85.545564, 42.768139],
                    [-85.543191, 42.421433],
                    [-85.764697, 42.420715],
                    [-86.2769940445315, 42.4193103159263],
                    [-86.240642, 42.54],
                ],
            ],
        },
        properties: {
            CSAFP: '266',
            CBSAFP: '26090',
            AFFGEOID: '310M600US26090',
            GEOID: '26090',
            NAME: 'Holland, MI',
            NAMELSAD: 'Holland, MI Micro Area',
            LSAD: 'M2',
            ALAND: 2137395679,
            AWATER: 2610802947,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-73.07213, 41.998316, -72.135029, 42.7394516843506],
            type: 'Polygon',
            coordinates: [
                [
                    [-73.07213, 42.143548],
                    [-73.000278, 42.312597],
                    [-73.029398, 42.308568],
                    [-73.064288, 42.339732],
                    [-73.065772, 42.389111],
                    [-73.011683, 42.379683],
                    [-72.975409, 42.556037],
                    [-72.951039, 42.641006],
                    [-72.995686, 42.673649],
                    [-72.9489339463746, 42.7394516843506],
                    [-72.9301066166812, 42.7390651376842],
                    [-72.9272134553146, 42.739005737764],
                    [-72.8642918548454, 42.7377138851592],
                    [-72.809113, 42.736581],
                    [-72.5167111827822, 42.7284676698292],
                    [-72.458519, 42.726853],
                    [-72.4511950518227, 42.7266508854936],
                    [-72.4120300647523, 42.7255700733633],
                    [-72.2830340925109, 42.722010250624],
                    [-72.227752, 42.615867],
                    [-72.271352, 42.547227],
                    [-72.244868, 42.513439],
                    [-72.315088, 42.398856],
                    [-72.314253, 42.343688],
                    [-72.288559, 42.352078],
                    [-72.210795, 42.31138],
                    [-72.211079, 42.251262],
                    [-72.221218, 42.245252],
                    [-72.263924, 42.183831],
                    [-72.199348, 42.153707],
                    [-72.141886, 42.161],
                    [-72.135029, 42.146994],
                    [-72.1357314619467, 42.0291419299416],
                    [-72.198845978132, 42.030103893282],
                    [-72.317148, 42.031907],
                    [-72.3974762551959, 42.0328161911358],
                    [-72.5091793362983, 42.0340804965901],
                    [-72.528131, 42.034295],
                    [-72.607933, 42.030795],
                    [-72.735496, 42.036399],
                    [-72.766739, 42.002995],
                    [-72.7747593160786, 42.0021291041803],
                    [-72.810078, 41.998316],
                    [-72.847142, 42.036894],
                    [-72.999549, 42.038653],
                    [-73.0087627816307, 42.0389034162467],
                    [-73.053397149764, 42.0401165089147],
                    [-73.07213, 42.143548],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '44140',
            AFFGEOID: '310M600US44140',
            GEOID: '44140',
            NAME: 'Springfield, MA',
            NAMELSAD: 'Springfield, MA Metro Area',
            LSAD: 'M1',
            ALAND: 4774508900,
            AWATER: 156642729,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-76.6172137325776, 42.723844, -75.247963, 43.707018],
            type: 'Polygon',
            coordinates: [
                [
                    [-76.6172137325776, 43.4201755544656],
                    [-76.515882, 43.471136],
                    [-76.417581, 43.521285],
                    [-76.370942837669, 43.5256270615303],
                    [-76.368849, 43.525822],
                    [-76.319701, 43.512275],
                    [-76.235834, 43.529256],
                    [-76.203473, 43.574978],
                    [-76.196596, 43.649761],
                    [-76.2014825473857, 43.6802859602237],
                    [-76.022003, 43.66814300000001],
                    [-76.025087, 43.707018],
                    [-75.773819, 43.688778],
                    [-75.75641600000002, 43.470416],
                    [-75.814627, 43.483578],
                    [-75.886756, 43.325021],
                    [-75.884275, 43.155562],
                    [-75.723929, 43.162596],
                    [-75.552774, 43.037554],
                    [-75.542803, 42.931774],
                    [-75.44417300000002, 42.933089],
                    [-75.437167, 42.863319],
                    [-75.247963, 42.871604],
                    [-75.251416, 42.784565],
                    [-75.295877, 42.744106],
                    [-75.428668, 42.745421],
                    [-75.889832, 42.723844],
                    [-75.896079, 42.790964],
                    [-76.274673, 42.771257],
                    [-76.356974, 42.84945],
                    [-76.450738, 42.84576],
                    [-76.491941, 43.004964],
                    [-76.479224, 43.227519],
                    [-76.605012, 43.25357],
                    [-76.6172137325776, 43.4201755544656],
                ],
            ],
        },
        properties: {
            CSAFP: '532',
            CBSAFP: '45060',
            AFFGEOID: '310M600US45060',
            GEOID: '45060',
            NAME: 'Syracuse, NY',
            NAMELSAD: 'Syracuse, NY Metro Area',
            LSAD: 'M1',
            ALAND: 6176711803,
            AWATER: 1020680182,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-88.680066, 44.240461, -87.3754915502194, 45.378683],
            type: 'Polygon',
            coordinates: [
                [
                    [-88.677996, 45.378683],
                    [-88.428102, 45.37701],
                    [-88.305811, 45.37461],
                    [-88.309063, 45.20158],
                    [-88.186355, 45.197974],
                    [-88.189247, 45.111844],
                    [-88.118879, 45.110197],
                    [-88.12115200000001, 45.023581],
                    [-87.944436, 44.993057],
                    [-87.7626384909711, 44.9627521717603],
                    [-87.812989, 44.954013],
                    [-87.843433, 44.924355],
                    [-87.838359, 44.87398700000001],
                    [-87.854681, 44.857771],
                    [-87.904484, 44.818723],
                    [-87.941453, 44.75608],
                    [-87.983494, 44.720196],
                    [-87.9975734064425, 44.6776639390448],
                    [-88.002085, 44.664035],
                    [-87.998716, 44.609288],
                    [-88.041202, 44.57258100000001],
                    [-88.005518, 44.539216],
                    [-87.943801, 44.529693],
                    [-87.898888, 44.574135],
                    [-87.866884, 44.608434],
                    [-87.77516000000001, 44.639281],
                    [-87.76130767888512, 44.6536977497349],
                    [-87.748409, 44.667122],
                    [-87.7375708608663, 44.6770118161559],
                    [-87.3754915502194, 44.675512109805],
                    [-87.401629, 44.631191],
                    [-87.446963, 44.586274],
                    [-87.498662, 44.460686],
                    [-87.5433267854761, 44.3275128561299],
                    [-87.766026, 44.327184],
                    [-87.887526, 44.327589],
                    [-87.888087, 44.240461],
                    [-88.04324, 44.241018],
                    [-88.192611, 44.241947],
                    [-88.190465, 44.586799],
                    [-88.245204, 44.584746],
                    [-88.25226, 44.667427],
                    [-88.242687, 44.679631],
                    [-88.24821300000002, 44.852739],
                    [-88.489149, 44.855445],
                    [-88.483732, 45.116887],
                    [-88.641159, 45.117346],
                    [-88.680066, 45.204994],
                    [-88.677996, 45.378683],
                ],
            ],
        },
        properties: {
            CSAFP: '267',
            CBSAFP: '24580',
            AFFGEOID: '310M600US24580',
            GEOID: '24580',
            NAME: 'Green Bay, WI',
            NAMELSAD: 'Green Bay, WI Metro Area',
            LSAD: 'M1',
            ALAND: 4843347536,
            AWATER: 2535185676,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-120.19436900000001, 34.788655, -117.61651800000001, 35.798359],
            type: 'Polygon',
            coordinates: [
                [
                    [-120.19436900000001, 35.789363],
                    [-119.53812000000002, 35.789898],
                    [-119.053437, 35.790774],
                    [-118.80244000000002, 35.790166],
                    [-118.058925, 35.791474],
                    [-118.00809400000001, 35.788935],
                    [-117.924406, 35.798359],
                    [-117.634283, 35.79726],
                    [-117.65213600000001, 35.709858],
                    [-117.61651800000001, 35.680687],
                    [-117.630076, 35.564254],
                    [-117.632151, 34.822271],
                    [-117.66745899999998, 34.822528],
                    [-117.774368, 34.823301],
                    [-118.326281, 34.819726],
                    [-118.89463399999998, 34.817972],
                    [-118.88040500000001, 34.788655],
                    [-118.977926, 34.811714],
                    [-119.242534, 34.814323],
                    [-119.276435, 34.872481],
                    [-119.44369100000002, 34.901171],
                    [-119.47249399999998, 34.901378],
                    [-119.472552, 35.076669],
                    [-119.561752, 35.087831],
                    [-119.553819, 35.180123],
                    [-119.66730400000002, 35.174982],
                    [-119.666585, 35.262635],
                    [-119.809407, 35.263693],
                    [-119.80920999999998, 35.35094],
                    [-119.880356, 35.351124],
                    [-119.880045, 35.439133],
                    [-119.91365899999998, 35.439262],
                    [-120.086766, 35.526432],
                    [-120.08618, 35.614397],
                    [-120.193988, 35.614389],
                    [-120.19436900000001, 35.789363],
                ],
            ],
        },
        properties: {
            CSAFP: '',
            CBSAFP: '12540',
            AFFGEOID: '310M600US12540',
            GEOID: '12540',
            NAME: 'Bakersfield, CA',
            NAMELSAD: 'Bakersfield, CA Metro Area',
            LSAD: 'M1',
            ALAND: 21068632654,
            AWATER: 78405068,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-80.5193967266905, 41.480396, -79.612071, 41.850328],
            type: 'Polygon',
            coordinates: [
                [
                    [-80.5193967266905, 41.8495629905593],
                    [-79.612071, 41.850328],
                    [-79.613147, 41.623863],
                    [-79.82911100000001, 41.62207000000001],
                    [-79.999779, 41.490049],
                    [-80.125369, 41.480396],
                    [-80.5191652733637, 41.4891096844823],
                    [-80.5191767503534, 41.499924051206],
                    [-80.519357, 41.669767],
                    [-80.5193967266905, 41.8495629905593],
                ],
            ],
        },
        properties: {
            CSAFP: '240',
            CBSAFP: '32740',
            AFFGEOID: '310M600US32740',
            GEOID: '32740',
            NAME: 'Meadville, PA',
            NAMELSAD: 'Meadville, PA Micro Area',
            LSAD: 'M2',
            ALAND: 2621988043,
            AWATER: 65389924,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-113.335057, 32.50139, -110.448487, 34.048165],
            type: 'Polygon',
            coordinates: [
                [
                    [-113.335057, 33.566543],
                    [-113.333313, 34.000039],
                    [-112.743951, 33.999898],
                    [-112.276168, 33.882469],
                    [-112.229231, 33.954186],
                    [-112.161128, 34.017717],
                    [-112.164355, 34.048165],
                    [-111.726277, 34.000045],
                    [-111.49498800000002, 33.99995],
                    [-111.445079, 34.001176],
                    [-111.450904, 33.919877],
                    [-111.372317, 33.823924],
                    [-111.389444, 33.776663000000006],
                    [-111.28649, 33.657375],
                    [-111.222055, 33.602446],
                    [-111.153354, 33.677606],
                    [-111.039976, 33.466019],
                    [-110.974706, 33.342095],
                    [-110.955497, 33.279139],
                    [-110.780689, 32.983673],
                    [-110.725583, 33.069896],
                    [-110.632529, 33.142771],
                    [-110.593459, 33.133926],
                    [-110.44902100000002, 33.194526],
                    [-110.448487, 32.534517],
                    [-110.451522, 32.513995],
                    [-110.842071, 32.513865],
                    [-110.85872, 32.511223],
                    [-111.157493, 32.511255],
                    [-111.351577, 32.50139],
                    [-111.792536, 32.506644],
                    [-112.203624, 32.506721],
                    [-112.625204, 32.50535500000001],
                    [-113.333894, 32.505188],
                    [-113.333709, 32.767851],
                    [-113.335048, 33.377469],
                    [-113.335057, 33.566543],
                ],
            ],
        },
        properties: {
            CSAFP: '429',
            CBSAFP: '38060',
            AFFGEOID: '310M600US38060',
            GEOID: '38060',
            NAME: 'Phoenix-Mesa-Chandler, AZ',
            NAMELSAD: 'Phoenix-Mesa-Chandler, AZ Metro Area',
            LSAD: 'M1',
            ALAND: 37732674686,
            AWATER: 82920891,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-82.233974, 39.755572, -81.691104, 40.16688],
            type: 'Polygon',
            coordinates: [
                [
                    [-82.198772, 39.95014],
                    [-82.194786, 40.020694],
                    [-82.187105, 40.16688],
                    [-81.71627600000001, 40.15216600000001],
                    [-81.728611, 39.931728],
                    [-81.691104, 39.930756],
                    [-81.694146, 39.842636],
                    [-81.697442, 39.755572],
                    [-82.076639, 39.77096],
                    [-82.072934, 39.816227],
                    [-82.170047, 39.82075],
                    [-82.162385, 39.909371],
                    [-82.233974, 39.91326],
                    [-82.198772, 39.95014],
                ],
            ],
        },
        properties: {
            CSAFP: '198',
            CBSAFP: '49780',
            AFFGEOID: '310M600US49780',
            GEOID: '49780',
            NAME: 'Zanesville, OH',
            NAMELSAD: 'Zanesville, OH Micro Area',
            LSAD: 'M2',
            ALAND: 1721165826,
            AWATER: 20823924,
        },
    },
    {
        type: 'Feature',
        geometry: {
            bbox: [-89.369128, 42.4941373692957, -88.7765924457953, 42.84763],
            type: 'Polygon',
            coordinates: [
                [
                    [-89.369128, 42.845036],
                    [-89.013582, 42.84763],
                    [-88.918672, 42.846121],
                    [-88.777076, 42.842694],
                    [-88.777143, 42.833856],
                    [-88.7765924457953, 42.4941373692957],
                    [-88.94038486037802, 42.4954398274712],
                    [-88.9925602268173, 42.4958547198992],
                    [-89.042898, 42.496255],
                    [-89.3657986076635, 42.5000259669515],
                    [-89.369128, 42.845036],
                ],
            ],
        },
        properties: {
            CSAFP: '357',
            CBSAFP: '27500',
            AFFGEOID: '310M600US27500',
            GEOID: '27500',
            NAME: 'Janesville-Beloit, WI',
            NAMELSAD: 'Janesville-Beloit, WI Metro Area',
            LSAD: 'M1',
            ALAND: 1860044984,
            AWATER: 20695798,
        },
    },
];
